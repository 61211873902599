import React from 'react';
import PropTypes from 'prop-types';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';

// material-ui
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// custom imports
import { ImageCardWithActions } from 'components/cards';
import MainCard from 'components/MainCard';

// utils
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';
// import { oneLineAddress } from 'utils';

// assets
// import PhoneIcon from '@mui/icons-material/Phone';
// import HomeIcon from '@mui/icons-material/Home';

const skeletonCount = [1, 2, 3, 4, 5, 6, 7];

const StudentListCardSkeleton = () => {
  return (
    <React.Fragment>
      {skeletonCount.map((skeleton, index) => {
        return (
          <MainCard content={false} key={index} sx={{ padding: 2, mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Skeleton variant='circular' width={75} height={75} />
              </Grid>
              <Grid item xs={9}>
                <Typography component='div' variant='h2' width='60%'>
                  <Skeleton />
                </Typography>
                <Typography component='div' variant='body1' width='80%'>
                  <Skeleton />
                </Typography>
                <Typography component='div' variant='body1' width='90%'>
                  <Skeleton />
                </Typography>
              </Grid>
            </Grid>
          </MainCard>
        );
      })}
    </React.Fragment>
  );
};

function StudentListCard({ isLoading, studentsData, handleEdit, handleDelete, apiLoadingStudent }) {
  return (
    <MainCard contentSX={{ bgcolor: 'primary.main', height: '798px' }}>
      <Typography
        variant='h2'
        component='div'
        color='primary.contrastText'
        sx={{ fontWeight: 300, fontStyle: 'italic' }}
        gutterBottom
      >
        Students
      </Typography>
      <Scrollbars
        autoHeight
        autoHide
        autoHeightMax={710}
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        {isLoading && <StudentListCardSkeleton />}
        {!isLoading && (
          <Grid container spacing={3}>
            {studentsData?.map((studentData, index) => {
              // {/* let code = studentData?.parent_id.slice(0, studentData?.parent_id.length - 10); */}
              //let formatted_phone_number = formatPhoneNumber(studentData?.parent_id);
              return (
                <Grid item xs={12} key={index}>
                  <ImageCardWithActions
                    key={studentData.id}
                    id={studentData.id}
                    name={studentData.first_name + ' ' + studentData.last_name}
                    avatar_url={studentData.profile_image?.url}
                    listItemOne={
                      studentData?.phone_number?.country_code + studentData?.phone_number?.number
                    }
                    listItemTwo={studentData?.addresses[0].address}
                    // listItemTwo={'jkj'}
                    onDeleteClick={handleDelete}
                    onEditClick={handleEdit}
                    apiLoadingStudent={apiLoadingStudent}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Scrollbars>
    </MainCard>
  );
}

StudentListCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  studentData: PropTypes.array.isRequired,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default StudentListCard;
