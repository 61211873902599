import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// const LightTooltip = withStyles((theme) => ({
//   arrow: {
//     color: theme.palette.text.primary,
//   },
//   tooltip: {
//     backgroundColor: theme.palette.text.secondary,
//     color: theme.palette.common.black,
//     boxShadow: theme.shadows[1],
//     fontSize: 12,
//     padding: '8px 8px',
//     marginTop: 5,
//     maxWidth: 250,
//   },
// }))(Tooltip);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export default LightTooltip;
