import React from 'react';

// Material UI
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

// Project Import
import MainCard from 'components/MainCard';
import moment from 'moment';
import { DATE_OCCURRENCE } from './formInitialValuesDriverShifts';

// Icons
import LoopIcon from '@mui/icons-material/Loop';
import NotesIcon from '@mui/icons-material/Notes';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { SpotBusDateTime } from 'utils/spotbus-date-time';
export default function ShiftCard({
  data,
  setEditedDriverShiftFormData,
  setIsUpdate,
  setOpenDriverShiftsForm,
  setDriverShiftDeleteAlertOpen,
  setOccurrenceType,
  setShiftStartDate,
}) {
  /**
   * Sets the occurrence type, edited driver shift form data, update status,
   * and opens the driver shifts form.
   *
   * @return {undefined} No return value
   */
  const handleEdit = () => {
    setShiftStartDate(data.startDate);
    setOccurrenceType(data.dateOccurrence);
    setEditedDriverShiftFormData(data);
    setIsUpdate(true);
    setOpenDriverShiftsForm(true);
  };

  /**
   * Sets the driver shift delete alert open state to true and sets the edited driver shift form data.
   *
   * @return {undefined} No return value.
   */
  const handleDelete = () => {
    setDriverShiftDeleteAlertOpen(true);
    setEditedDriverShiftFormData(data);
  };

  const isRecurrence =
    data.dateOccurrence === DATE_OCCURRENCE.WEEKLY ||
    data.dateOccurrence === DATE_OCCURRENCE.MONTHLY;

  const localTimeStartDate = new SpotBusDateTime(data.startDate).toLocalizeTimezone();
  const localTimeEndDate = new SpotBusDateTime(data.endDate).toLocalizeTimezone();
  return (
    <MainCard content={false} sx={{ p: 3 }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        width={'100%'}
      >
        <Stack direction='row' alignItems='center' width={'33%'}>
          <Stack sx={{ mr: 2 }}>{isRecurrence ? <LoopIcon /> : <NotesIcon />}</Stack>
          <Typography variant='h5' sx={{ textTransform: 'capitalize' }}>
            {data.shiftTitle}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='center' alignItems='center' mt={2} width={'33%'}>
          <Stack sx={{ mr: 2 }}>
            <DateRangeIcon />
          </Stack>
          <Typography variant='h6'>
            {moment(localTimeStartDate, 'MM/DD/YYYY').format('DD MMM YYYY')}
          </Typography>
          <Typography variant='h6' sx={{ mx: 1 }}>
            -
          </Typography>
          <Typography variant='h6'>
            {moment(localTimeEndDate, 'MM/DD/YYYY').format('DD MMM YYYY')}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
          width={'33%'}
        >
          <EditIcon color='secondary' onClick={handleEdit} />
          <DeleteIcon color='error' onClick={handleDelete} />
        </Stack>
      </Stack>
    </MainCard>
  );
}
