import React from 'react';

// third party
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

// material ui imports
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import PublishIcon from '@mui/icons-material/GetApp';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import RefreshIcon from '@mui/icons-material/Refresh';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

// project imports
//For  File selecting  via drag-drop or manually
import RenderSelectFile from './RenderSelectFile';
import RenderValidatorResult from './RenderValidatorResult';
import RenderFinalUpload from './RenderFinalUpload'; // For Showing progress bar
import RenderCompleted from './RenderCompleted'; // For showing Number of items successfull imported.

// Custom Components
import CancelButton from 'components/Buttons/CancelButton';
import ActionButton from 'components/Buttons/ActionButton';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,hsl(44,99%,50%) 0%,hsl(47,100%,45%) 50%,hsl(47,98%,45%) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,hsl(44,99%,50%) 0%,hsl(47,100%,45%) 50%,hsl(47,98%,45%) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 95deg,hsl(44,99%,50%) 0%,hsl(47,100%,45%) 50%,hsl(47,98%,45%) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 95deg,hsl(44,99%,50%) 0%,hsl(47,100%,45%) 50%,hsl(47,98%,45%) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PublishIcon />,
    2: <SpellcheckIcon />,
    3: <RefreshIcon />,
    4: <VerifiedUserIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // width: '99%',
    minheight: '80vh',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  back: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  successButton: {
    marginRight: theme.spacing(1),
    backgroundColor: '#5cb85c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5cb85c',
    },
  },
}));

function getSteps() {
  return ['Choose File', 'Validation', 'Upload Progress', 'Completed'];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <RenderSelectFile {...props} />;
    case 1:
      return <RenderValidatorResult {...props} />;
    case 2:
      return <RenderFinalUpload {...props} />;
    case 3:
      return (
        <RenderCompleted
          history={props.history}
          totalStudents={props.data && props.data.total_items}
        />
      );
    default:
      return 'Unknown step';
  }
}

export default function DriverStepperHeader(props) {
  const classes = useStyles();
  const activeStep = props.activeStep;
  const setActiveStep = props.setActiveStep;
  const steps = getSteps();
  let navigate = useNavigate();
  const theme = useTheme();

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleFileUpload = () => {
    props.handleFileUpload();
  };

  function getLeftButtons() {
    // if (activeStep === 1) {
    //   return (
    //     <CancelButton size='medium' isIcon={true} onClick={() => props.setAbortAlertValue(true)}>
    //       Cancel
    //     </CancelButton>
    //     // <Button
    //     //   onClick={() => props.setAbortAlertValue(true)}
    //     //   className={classes.back}
    //     //   variant='contained'
    //     // >
    //     //   Cancel
    //     // </Button>
    //   );
    // }
    if (activeStep === 2) {
      return (
        <CancelButton
          disabled={activeStep === 0}
          onClick={() => props.setAbortAlertValue(true)}
          size='medium'
          isIcon={true}
        >
          Cancel
        </CancelButton>
        // Code Depricated
        // <Button
        //   disabled={activeStep === 0}
        //   onClick={() => props.setAbortAlertValue(true)}
        //   className={classes.back}
        //   variant='contained'
        // >
        //   Cancel
        // </Button>
      );
    }
    return '';
  }

  const handleCompletedOnGuidedWorkflow = () => {
    setActiveStep(0);
    props.setSelectedFile('');
  };

  function getRightButtons() {
    if (activeStep === 0) {
      return (
        <Grid
          container
          // spacing={3}
        >
          <Grid item>
            <ActionButton
              loaderButton
              isLoading={props.isButtonLoading}
              startIcon={<UploadFileIcon />}
              id='upload_file_button'
              onClick={() => props.selectedFile && handleFileUpload()}
              disabled={props.selectedFile == '' || props.selectedFile == undefined ? true : false}
            >
              UPLOAD FILE
            </ActionButton>
            {/* Old Code Depricated */}
            {/* <LoadingButton
              loading={props.isButtonLoading}
              loadingPosition='start'
              startIcon={<UploadFileIcon />}
              id='upload_file_button'
              variant='contained'
              color='primary'
              onClick={() => props.selectedFile && handleFileUpload()}
              className={classes.button}
              // disabled={props.selectedFile == '' || props.selectedFile == undefined ? true : false}
            >
              UPLOAD FILE
            </LoadingButton> */}
          </Grid>
        </Grid>
      );
    }
    if (activeStep === 1) {
      return (
        <Grid item>
          <ActionButton
            isIcon={true}
            startIcon={<UploadFileIcon />}
            id='revised_button'
            onClick={() => props.setAbortAlertValue(true)}
          >
            UPLOAD REVISED FILE
          </ActionButton>
        </Grid>
        // <Button
        //   id='revised_button'
        //   variant='contained'
        //   color='primary'
        //   onClick={() => props.setAbortAlertValue(true)}
        //   // variant='contained'
        // >
        //   Upload Revised File
        // </Button>
      );
    }
    if (activeStep === 3) {
      return (
        // navigation added
        <ActionButton
          id='driver_done_button'
          isIcon={true}
          color='success'
          startIcon={<CheckCircleIcon />}
          onClick={(e) => {
            props.isFromGuided ? handleCompletedOnGuidedWorkflow() : navigate('/drivers');
          }}
        >
          DONE
        </ActionButton>
        // <Button
        //   id='driver_done_button'
        //   className={classes.successButton}
        //   variant='contained'
        //   onClick={(e) => {
        //     props.isFromGuided ? handleCompletedOnGuidedWorkflow() : props.history.push('/drivers');
        //   }}
        //   // variant='contained'
        // >
        //   Done
        // </Button>
      );
    }
    return '';
  }

  function renderBottomButtons() {
    return (
      <Grid container justifyContent='space-between'>
        <Grid item>{getLeftButtons()}</Grid>
        <Grid item>{getRightButtons()}</Grid>
      </Grid>
    );
  }

  return (
    // <div className={classes.root}>
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ mb: 7 }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} sx={{ mb: 7 }}>
        {getStepContent(activeStep, props)}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          position: props.isFromGuided && 'relative',
          top: props.isFromGuided && activeStep === 1 ? '3px' : '177px',
        }}
      >
        {renderBottomButtons()}
      </Grid>
    </Grid>
    // </div>
  );
}
