import React, { forwardRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

// Material Ui
import Chip from '@mui/material/Chip';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import PropTypes from 'prop-types';
import { MenuItem, FormControl, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import Skeleton from '@mui/material/Skeleton';

export default function Priority({
  label,
  isIcon,
  priorityArray,
  functionOfSelect,
  isEditable,
  handleEditClick,
  minWidth,
  isSubmitting,
  isLoading,
  ...props
}) {
  // States for Edit Hover
  const labelText = label.toLowerCase();
  const [isHovered, setIsHovered] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [value, setValues] = useState(null);
  const handleChange = (event) => {
    setValues(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setValues(labelText);
  }, [labelText]);

  // function for Icon Chnage
  function functionIconChange(labelText) {
    switch (labelText) {
      case priorityArray[0].toLowerCase():
        return <ErrorOutlineOutlinedIcon fontSize='small' />;
      case priorityArray[1].toLowerCase():
        return <AdjustOutlinedIcon fontSize='small' />;
      case priorityArray[2].toLowerCase():
        return <ArrowCircleDownIcon fontSize='small' />;
      default:
        return <LowPriorityIcon fontSize='small' />;
    }
  }

  function functionColorChangei(labelText) {
    switch (labelText) {
      case priorityArray[0].toLowerCase():
        return 'error';
      case priorityArray[1].toLowerCase():
        return 'success';
      case priorityArray[2].toLowerCase():
        return 'info';
      default:
        return 'secondary';
    }
  }

  const skeletonCall = () => {
    return <Skeleton variant='rectangular' width={80} height={20} />;
  };

  function ChipComponent({ labelText }) {
    return isLoading ? (
      skeletonCall()
    ) : (
      <Chip
        icon={isIcon && functionIconChange(labelText)}
        label={labelText}
        sx={{
          textTransform: 'capitalize',
        }}
        color={functionColorChangei(labelText)}
        variant='outlined'
        disabled={isSubmitting}
        {...props}
      />
    );
  }

  return isEditable ? (
    <FormControl
      variant='standard'
      onClick={(e) => e.stopPropagation()}
      sx={{
        minWidth: minWidth,
      }}
      onMouseEnter={() => setIsHovered(false)}
      onMouseLeave={() => setIsHovered(true)}
    >
      {isLoading ? (
        skeletonCall()
      ) : (
        <Select
          id='priority'
          sx={{ alignItems: 'center' }}
          select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          variant='standard'
          size='small'
          IconComponent={forwardRef((props, ref) => {
            return null;
          })}
          disableUnderline
          endAdornment={
            <InputAdornment position='start'>
              <EditIcon
                onClick={handleOpen}
                sx={{ fontSize: '18px' }}
                style={{
                  display: isHovered ? 'none' : '',
                  position: 'absolute',
                  right: '6px',
                  cursor: 'pointer',
                }}
              />
            </InputAdornment>
          }
        >
          {priorityArray.map((item, index) => (
            <MenuItem
              value={item.toLowerCase()}
              sx={{ pl: 2, justifyContent: 'flex-start' }}
              key={index}
              onClick={() => handleEditClick(item)}
              onMouseLeave={() => setIsHovered(true)}
            >
              <ChipComponent labelText={item.toLowerCase()} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  ) : (
    <ChipComponent labelText={labelText} />
  );
}

Priority.defaultProps = {
  variant: 'outlined',
  isIcon: true,
  size: 'small',
  isEditable: false,
  priorityArray: ['High', 'Medium', 'Low'],
  minWidth: 'auto',
  handleEditClick: function () {
    return;
  },
  label: 'Priority',
  isSubmitting: false,
  isLoading: false,
};
Priority.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  isIcon: PropTypes.bool,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
  handleEditClick: PropTypes.func,
  priorityArray: PropTypes.oneOf(['High', 'Medium', 'Low']),
  minWidth: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

// Readme.md

// All Default props are set

// We can pass all default chips props

// We need to import as below

// When isEditable false that is default function
{
  /* <Priority label={'low'} />; */
}

// When is EdiTable is True Then

// handleEditClick will get active we have to pass callback function to get the selected value
{
  /* <Priority
  label={'low'}
  handleEditClick={(item) => console.log(item, 'HANDLECLICKDATA')}
  isEditable={true}
/>; */
}
