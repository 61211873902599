import { defaultCountry } from 'utils';

export default function formInitialValuesForUser(isEdit, selectedUserData) {
  const phoneCountryCode = selectedUserData?.phone_number.substring(
    0,
    selectedUserData?.phone_number.length - 10,
  );
  return {
    first_name: isEdit ? selectedUserData.first_name : '',
    last_name: isEdit ? selectedUserData.last_name : '',
    phone_country_code: isEdit
      ? { code: 'US', label: 'United States', phone: phoneCountryCode }
      : defaultCountry(),
    phone_number: isEdit
      ? selectedUserData.phone_number.substring(selectedUserData.phone_number.length - 10)
      : '',
    email: isEdit ? selectedUserData.email : '',
    gender: isEdit ? selectedUserData.gender : '',
  };
}
