import { API_ID } from 'clients';

import axios from 'axios';

const API_URL = 'https://uxd2g0rdm6.execute-api.us-east-1.amazonaws.com';

const API_LIST_ROUTES = '/api/embed/routes/';

const API_LIST_ROUTE_DETAILS = '/api/embed/route-stops/';

const API_SETTINGS_INFO = '/api/embed/settings/';

const API_LIST_ROUTE_TRIPS = '/api/embed/route-trips/';
// School API
const API_SCHOOL_LIST = '/api/embed/schools';

export async function getUnAuthoriseRoutes(schoolId, userName, sessionId) {
  try {
    const response = await axios.get(API_URL + API_LIST_ROUTES + schoolId, {
      params: {
        org_username: userName,
        decode_polyline: true,
      },
      headers: {
        SessionId: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUnAuthoriseRoutesDetails(routeId, sessionId) {
  try {
    const response = await axios.get(API_URL + API_LIST_ROUTE_DETAILS + routeId, {
      headers: {
        Sessionid: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUnAuthoriseSettingsDetails(userName, sessionId, token) {
  try {
    const response = await axios.get(API_URL + API_SETTINGS_INFO + token, {
      params: {
        org_username: userName,
      },
      headers: {
        SessionId: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUnAuthoriseRouteTrips(schoolId, date, userName, sessionId) {
  const [dd, mm, yy] = date.split('/');
  try {
    const response = await axios.get(API_URL + API_LIST_ROUTE_TRIPS + schoolId, {
      params: {
        dd: mm,
        mm: dd,
        yy: 20 + yy,
        org_username: userName,
      },
      headers: {
        SessionId: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUnAuthorizedSchoolDetails(userName, sessionId, token) {
  try {
    const response = await axios.get(API_URL + API_SCHOOL_LIST, {
      params: {
        token: token,
        org_username: userName,
      },
      headers: {
        SessionId: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getPublicSchool(schoolId, userName, sessionId) {
  
  try {
    const response = await axios.get(API_URL + API_SCHOOL_LIST + '/' + schoolId, {
      params: {
        org_username: userName,
      },
      headers: {
        SessionId: sessionId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
