// third party
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

// mui
import { InputAdornment, Paper, Typography, Stack } from '@mui/material';
import Link from '@mui/material/Link';

// components
import { ActionButton } from 'components/Buttons';

// project imports
import OTPInput from './OTPInput';

// assets
import EmailIcon from '@mui/icons-material/Email';

// email adornment
const emailIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

function SignUpConfirmation({ setAuthPage, loading, confirmSignUp, resendSignUpOTP }) {
  const formInitialValues = () => {
    return {
      verifyCode: '',
    };
  };

  const handleReturnToSignIn = () => {
    setAuthPage('logIn');
  };

  const validationSchema = yup.object({
    verifyCode: yup
      .string()
      .trim('')
      .required('Verification is required')
      .min(6, 'Verification code must be 6 characters'),
  });

  const handleSubmit = (values) => {
    confirmSignUp(values.verifyCode);
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='signIn'>
          <Paper
            sx={{
              p: 5,
              width: 400,
              borderRadius: 4,
              boxShadow: 10,
            }}
          >
            <Typography sx={{ mt: 4, mb: 6 }} variant='h3'>
              Email verification
            </Typography>
            <Typography sx={{ mb: 6 }} variant='body1'>
              Before you start using SpotBus you need to confirm your email address. A verification
              code has been sent to your email address, please enter the code below.
            </Typography>
            <Stack spacing={3} sx={{ mb: 6 }}>
              <Field
                fullWidth
                component={OTPInput}
                label='Enter OTP'
                name='verifyCode'
                id='verifyCode'
                InputProps={emailIcon}
                disabled={false}
                helperText={'Enter Verification code'}
                otpLength={['', '', '', '', '', '']}
              />
            </Stack>

            <ActionButton
              disabled={!formikProps.dirty}
              fullWidth
              sx={{ mb: 6 }}
              id='save'
              size={'large'}
              type='submit'
              startIcon={false}
              variant='contained'
              isLoading={loading}
            >
              Confirm Email Address
            </ActionButton>
            <ActionButton
              fullWidth
              variant='outlined'
              sx={{ mb: 4 }}
              size={'large'}
              startIcon={false}
              onClick={resendSignUpOTP}
            >
              Resend code
            </ActionButton>
            <Typography sx={{ mb: 4 }} align='center' variant='body2'>
              <Link component='button' variant='body2' onClick={handleReturnToSignIn}>
                Return to sign in
              </Link>
            </Typography>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default SignUpConfirmation;
