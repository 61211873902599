import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';

import { DriversValidationListView } from './DriverValidationListView';
import { DriversValidationHeader } from './DriversValidationHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxHeight: window.innerHeight * 0.55,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#cccccc',
      borderRadius: '10px',
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));

export default function RenderValidatorResult(props) {
  const classes = useStyles();

  return (
    <>
      {props.data && props.data.validation_result && (
        <div>
          <div>
            <Paper className={classes.paper}>
              <DriversValidationHeader
                data={props.data}
                totalDriversData={props.totalDriversData}
                driverValidationFilter={props.driverValidationFilter}
                setDriverValidationFilter={props.setDriverValidationFilter}
              />
            </Paper>
          </div>
          <div className={classes.root}>
            <DriversValidationListView data={props.data} />
          </div>
        </div>
      )}
    </>
  );
}
