import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MainCard from 'components/MainCard';
import NoRecordDisplay from 'components/NoRecordDisplay';
import { TableHeaderNameWithIcon } from 'components/Table';
import { CustomTooltip } from 'components/tooltips';
import {
  ActiveChip,
  FailureChip,
  SuccessChip,
} from 'containers/layouts/Vehicle/Components/CustomComponents';
import SpotBusDateTime from 'models/spotBusDateTime';

export default function ShiftTable({ loader, data, nextToken, nextResultSet }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);
  const emptyArray = new Array(20).fill(0);

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table sx={{ minWidth: 755 }} aria-labelledby='tableTitle' aria-label='enhanced table'>
          <TableHead>
            <TableRow>
              <TableHeaderNameWithIcon
                id='date'
                icon={<CalendarTodayIcon color='primary' size='small' />}
                label='Date'
              />
              <TableHeaderNameWithIcon
                id='scheduleClockIn'
                icon={<AccessTimeIcon color='primary' size='small' />}
                label='Scheduled clock in'
                justifyContent={'center'}
              />
              <TableHeaderNameWithIcon
                id='actualClockIn'
                icon={<TimerOutlinedIcon color='primary' size='small' />}
                label='Actual clock in '
                justifyContent={'center'}
              />
              <TableHeaderNameWithIcon
                id='scheduleClockOut'
                icon={<AccessTimeIcon color='primary' size='small' />}
                label='Scheduled clock out'
                justifyContent={'center'}
              />
              <TableHeaderNameWithIcon
                id='actualClockIn'
                icon={<TimerOffOutlinedIcon color='primary' size='small' />}
                label='Actual clock out'
                justifyContent={'center'}
              />
              <TableHeaderNameWithIcon
                id='status'
                icon={<AutorenewOutlinedIcon color='primary' size='small' />}
                label='Status'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* In case data is loading show the skeleton*/}
            {loader &&
              emptyArray.map((el, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={6}>
                    <Skeleton animation='wave' variant='text' />
                  </TableCell>
                </TableRow>
              ))}
            {/* Case when there is no shifts info */}
            {!loader && data?.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align='center'
                  id='no_records'
                  style={{ borderColor: '#fff' }}
                >
                  <NoRecordDisplay />
                </TableCell>
              </TableRow>
            )}
            {/* Showing driver shifts if available */}
            {!loader &&
              data?.length > 0 &&
              data?.map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align='left' id={'date_shift' + index}>
                      {row.date ? SpotBusDateTime.fromISOString(row.date).toLocalizeDate() : '--'}
                    </TableCell>
                    <TableCell align='center' id={'clock_in' + index}>
                      {row.scheduled_clock_in
                        ? SpotBusDateTime.fromISOString(row.scheduled_clock_in).toLocalizeTime()
                        : '--'}
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} align='center' id={'clock_out' + index}>
                      <CustomTooltip title={row.clock_in_message ? row.clock_in_message : '--'}>
                        {row.clock_in_at
                          ? SpotBusDateTime.fromISOString(row.clock_in_at).toLocalizeTime()
                          : '--'}
                      </CustomTooltip>
                    </TableCell>
                    <TableCell align='center' id={'clock_out' + index}>
                      {row.scheduled_clock_out
                        ? SpotBusDateTime.fromISOString(row.scheduled_clock_out).toLocalizeTime()
                        : '--'}
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} align='center' id={'clock_out' + index}>
                      <CustomTooltip title={row.clock_out_message ? row.clock_out_message : '--'}>
                        {row.clock_out_at
                          ? SpotBusDateTime.fromISOString(row.clock_out_at).toLocalizeTime()
                          : '--'}
                      </CustomTooltip>
                    </TableCell>
                    <TableCell align='left' id={'clock_out' + index}>
                      {row.status === 'COMPLETED' ? (
                        <SuccessChip
                          variant='outlined'
                          size='small'
                          label='Completed'
                        ></SuccessChip>
                      ) : row.status === 'ACTIVE' ? (
                        <ActiveChip variant='outlined' size='small' label='Active'></ActiveChip>
                      ) : (
                        <FailureChip
                          variant='outlined'
                          size='small'
                          label='Cancelled'
                        ></FailureChip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
}
