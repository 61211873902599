import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Chip from '@mui/material/Chip';

// assets
import DoneIcon from '@mui/icons-material/Done';
import { Tooltip } from '@mui/material';
function ClickableChip(props) {
  const {
    size,
    color,
    icon,
    label,
    _handleChipClick,
    id,
    disabled = false,
    tooltip = false,
    tooltipText = 'ToolTip',
  } = props;
  const { value } = props.field;

  const _Chip = (
    <Chip
      deleteIcon={value ? <DoneIcon /> : icon}
      label={label}
      id={id}
      size={size}
      variant={value ? 'default' : 'outlined'}
      color={value ? color : 'default'}
      onClick={(event) => _handleChipClick(props, !value)}
      onDelete={() => _handleChipClick(props, !value)}
      disabled={disabled}
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltipText}>
        {_Chip}
      </Tooltip>
    );
  }
  return _Chip;
}

ClickableChip.defaultProps = {
  size: 'medium',
  color: 'primary',
  icon: true,
};

ClickableChip.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  color: PropTypes.string,
  icon: PropTypes.bool,
  label: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  id: PropTypes.string,
};

export default ClickableChip;

// how to use it
/*
1. Import ClickableChip from "/path"
2. use in Jsx
<Field
  fullWidth
  component={ClickableChip}
  label='Email Communication'
  name='has_email'
  icon={<EmailIcon />}
  _handleChipClick={_handleChipClick}
/>
 */
