import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Skeleton from '@mui/material/Skeleton';

// Global component
import { DefaultButton } from 'components/Buttons/index';
import { CustomTooltip } from 'components/tooltips';

function QuickViewActionItem({
  id,
  buttonText,
  buttonName, // ! deprecating soon
  description, // ! deprecating soon
  disabled,
  handleClick, // ! deprecating soon
  handleOnClick,
  isCalculating,
  isIcon,
  isLoading,
  isSubmitting,
  label,
  startIcon,
  textVariant,
  tooltipContent,
  variant,
  ...props
}) {
  /*
** a custom component for QuickViewActionItem 
* Props => 
*    1.) buttonText       =  it is required prop to render in button it can be string only,
*    2.) handleOnClick    =  it is a function and work on button on click
*    3.) id               =  not required can be used in testing
*    4.) isLoading        =  for showing skeleton while loading is true (get API call) on full place
*    5.) isSubmitting     =  for showing skeleton while submitting is true (create or update API call)
*    5.) isCalculating    =  for showing skeleton while some calculation taking place so skeleton on button
*    6.) label            =  it is required prop to render left side always use string
*    7.) startIcon        =  developer have to pass startIcon to show in start
*    8.) disabled         =  boolean to show button as disabled
*    9.) textVariant      =  text variant on button text
*    10.)tooltipContent   =  tooltip content shown on button
    
* How to use => (use case)

<QuickViewActionItem
  id='parent_account_status'
  buttonText={'buttonText'}
  disabled={false}
  handleOnClick={console.log('no call back function')}
  isLoading={false}
  isCalculating={false} // !for button skeleton
  label={'label'}
  startIcon={<InfoIcon />}
  size={'small'}
  tooltipContent={<Grid>{'tooltip content'}</Grid>}
/>


*/
  return (
    <React.Fragment>
      {!isLoading ? (
        <Grid container justifyContent='space-between' alignItems='center' id={id}>
          <Grid>
            <CustomTooltip title={label ?? description}>
              <Typography align='left' variant={textVariant} noWrap={true}>
                {label ?? description}
              </Typography>
            </CustomTooltip>
          </Grid>
          {isCalculating ? (
            <Skeleton variant='body1' width={'40%'} />
          ) : (
            <DefaultButton
              id={id}
              isIcon={isIcon}
              startIcon={startIcon}
              disabled={disabled || isSubmitting || isLoading}
              onClick={handleClick ?? handleOnClick}
              tooltipContent={tooltipContent}
              variant={variant}
              {...props}
            >
              {buttonName ?? buttonText}
            </DefaultButton>
          )}
        </Grid>
      ) : (
        <Skeleton variant='body1' width={'100%'} />
      )}
    </React.Fragment>
  );
}

QuickViewActionItem.defaultProps = {
  disabled: false,
  variant: 'outlined',
  startIcon: <InfoIcon />,
  textVariant: 'body1',
  isIcon: true,
};

QuickViewActionItem.propTypes = {
  id: PropTypes.string,
  buttonName: PropTypes.string.isRequired, // ! deprecating soon new prop buttonText
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired, // ! deprecating soon new prop label
  disabled: PropTypes.bool,
  handleClick: PropTypes.func, // ! deprecating soon new prop handleOnClick
  handleOnClick: PropTypes.func,
  isIcon: PropTypes.bool,
  isCalculating: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  textVariant: PropTypes.string,
};

export default QuickViewActionItem;
