import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme, makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import SpotBusLogo from 'components/SpotBusLogo';

// assets
import { RxHamburgerMenu } from 'react-icons/rx';

// context and custom hooks
import { useDrawer } from 'contexts';

const useStyles = makeStyles({
  media: {
    height: 30,
    marginLeft: 20,
  },
});

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = () => {
  const { drawerOpen, handleDrawerToggle } = useDrawer();
  const theme = useTheme();
  const classes = useStyles();

  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <DrawerHeaderStyled theme={theme} open={drawerOpen}>
      {!matchDownMD && (
        <IconButton aria-label='toggle_drawer' onClick={handleDrawerToggle}>
          <RxHamburgerMenu style={{ color: theme.palette.text.primary }} />
        </IconButton>
      )}
      <SpotBusLogo className={classes.media} />
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
