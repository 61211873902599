import React from 'react';

// material ui
import { Grid, Typography, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dividerMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function DriverStatus({
  statusColor,
  userStatus,
  enabled,
  selectedDriverData,
  toggleDriverStatusHandler,
  dataLoading,
}) {
  const classes = useStyles();

  const theme = useTheme();
  const USER_STATUS_TO_COLOR = {
    UNCONFIRMED: '#32a852',
    CONFIRMED: '#a86d32',
    ARCHIVED: '#a85d32',
    COMPROMISED: '#32a852',
    UNKNOWN: '#a86d32',
    RESET_REQUIRED: theme.palette.primary.main,
    FORCE_CHANGE_PASSWORD: theme.palette.primary.main,
  };

  const getColorByUserStatus = (user_status) => {
    return USER_STATUS_TO_COLOR[user_status];
  };

  return (
    <Grid container>
      <Typography color='textSecondary' display='inline' xs='auto' sx={{ mx: 1 }}>
        Status :{' '}
      </Typography>
      {!dataLoading ? (
        <Chip
          id='chip_pending'
          variant='outlined'
          size='small'
          label={userStatus}
          style={{
            borderColor: getColorByUserStatus(statusColor),
            color: getColorByUserStatus(statusColor),
          }}
        ></Chip>
      ) : (
        <Skeleton variant='text' width={'100%'} />
      )}
    </Grid>
  );
}
