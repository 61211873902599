import React from 'react';
import PropTypes from 'prop-types';

// assets
import AddIcon from '@mui/icons-material/Add';

// project imports
import DefaultButton from './DefaultButton';
import LightTooltip from 'containers/layouts/Utils/LightTooltip';

//? ============================== ACTION BUTTON  ============================== //
function ActionButton({
  children,
  loaderButton,
  isLoading,
  loadingPosition,
  variant,
  isIcon,
  startIcon,
  ...props
}) {
  /** 
  *  * Usage guide:
  * this is Global Button components for use any where in project 
    how to use
    1. import ActionButton  from "/path"

    2. use in JSX components

  ? 3. It's using <LoadingButton/> Components for more info (" https://mui.com/material-ui/api/loading-button/#demos ")
        <ActionButton
         isLoading={props.exportLoader}       //this support boolean value if it's false the circle loading is not show
         startIcon={<FileUploadIcon />}       //It's a icon support MUI icon
         onClick={props.handleExportStudents} // it's a function
       >
         Export Student                       // it's a node or text 
       </ActionButton>                       
    * @returns Action Button global component 
*/

  return (
    <DefaultButton
      loaderButton={loaderButton}
      loading={isLoading}
      loadingPosition={loadingPosition}
      variant={variant}
      startIcon={isIcon && startIcon}
      {...props}
    >
      {children}
    </DefaultButton>
  );
}

//? ________________________ Action Button Default and Initial Values ________________________
ActionButton.defaultProps = {
  children: 'Download',
  isLoading: false,
  isIcon: true,
  loaderButton: true,
  loadingPosition: 'start',
  variant: 'contained',
  startIcon: <AddIcon />,
};

//? ________________________ Action Button Prop Validation ________________________
ActionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isLoading: PropTypes.bool,
  isIcon: PropTypes.bool,
  loaderButton: PropTypes.bool.isRequired,
  loadingPosition: PropTypes.oneOf(['start', 'end']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  startIcon: PropTypes.node,
};

export default ActionButton;
