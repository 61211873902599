// third-party
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

// mui
import { InputAdornment, Paper, Typography, Stack, Link } from '@mui/material';

// components
import { ActionButton } from 'components/Buttons';

// assets
import EmailIcon from '@mui/icons-material/Email';

// email icon adornment
const emailIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

function ForgotPassword({ setAuthPage, forgotPassword, loading }) {
  const formInitialValues = () => {
    return {
      emailUser: '',
    };
  };

  const validationSchema = yup.object({
    emailUser: yup
      .string()
      .email('Email is not valid')
      .trim('')
      .required('Email address is required'),
  });

  const handleSubmit = (values) => {
    forgotPassword(values.emailUser);
  };

  const handleAuthForm = () => {
    setAuthPage('logIn');
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='signIn'>
          <Paper
            sx={{
              p: 5,
              width: 400,
              borderRadius: 4,
              boxShadow: 10,
            }}
          >
            <Typography variant='h3' sx={{ mb: 6 }}>
              Reset your password
            </Typography>
            <Typography sx={{ mb: 6 }} variant='body1'>
              Enter the email address associated with your account and we'll send you a email to
              reset your password.
            </Typography>

            <Stack spacing={10}>
              <Field
                fullWidth
                hiddenLabel
                variant='filled'
                component={TextField}
                placeholder='Email'
                name='emailUser'
                id='emailUser'
                InputProps={emailIcon}
                disabled={false}
                helperText=' '
              />
            </Stack>

            <ActionButton
              disabled={!formikProps.dirty}
              fullWidth
              sx={{ mt: 10 }}
              id='save'
              size={'large'}
              type='submit'
              startIcon={false}
              variant='contained'
              isLoading={loading}
            >
              Continue
            </ActionButton>
            <Typography sx={{ mt: 6, mb: 4 }} align='center' variant='body2'>
              <Link component='button' variant='body2' onClick={handleAuthForm}>
                Return to sign in
              </Link>
            </Typography>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default ForgotPassword;
