import React from 'react';

// material-ui
import Chip from '@mui/material/Chip';

import { useFormikContext } from 'formik';

export default function ChipFormik({
  field,
  variant,
  form,
  helperText,
  meta,
  label,
  size = 'medium',
  ...other
}) {
  const { name, value } = field;
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();
  const handleChipClick = (chipValue, value) => {
    if (value) {
      setFieldValue(name, false);
    } else {
      setFieldValue(name, true);
    }
    setFieldTouched(name, true, false);
  };
  return (
    <Chip
      // icon={icon}

      label={label}
      size={size}
      variant={!value ? 'outlined' : ''}
      color={'primary'}
      sx={{ borderRadius: 1, py: 3.9 }}
      onClick={(event) => handleChipClick(true, value)}
      {...other}
    />
  );
}
