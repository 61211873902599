// third-party
import { merge } from 'lodash';

// project import
import Badge from './Badge';
import CardContent from './CardContent';
import Link from './Link';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return merge(
        Badge(theme),
        CardContent(),
        Link(),
    );
}
