import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const CustomSelect = ({ field, form, label, options, helperText, variant, size }) => {
  return (
    <FormControl
      variant='standard'
      sx={{ width: '100%' }}
      error={form.errors[field.name] && form.touched[field.name]}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        variant={variant}
        {...field}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        size={size}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {form.errors[field.name] && form.touched[field.name] ? (
        <ErrorMessage name={field.name} component={FormHelperText} />
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
