import React from 'react';
import * as Yup from 'yup';

// Material UI
import { InputAdornment, Stack } from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Formik
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

// Utils
import { isDateNotPrevious, isSecondDateGreaterThanFirst, isValidDate } from 'pages/Sessions/Utils';

// Custom Components
import DateSelectorFormik from 'components/Formik/DateSelectorFormik';
import { DefaultButton } from 'components/Buttons';
import { validationSchemaForVacations } from 'pages/Sessions/FormModel/validationSchema';
import { initialValuesOfVacations } from 'pages/Sessions/FormModel/formInitialValues';

export default function VacationsForm({
  variant,
  selectedVacation,
  _handleSubmitVacation,
  editVacation,
  sessionData,
  sessionStartDate,
  sessionEndDate,
}) {
  return (
    <Formik
      initialValues={initialValuesOfVacations(selectedVacation)}
      validationSchema={validationSchemaForVacations()}
      onSubmit={_handleSubmitVacation}
      enableReinitialize
    >
      {(formikProps) => (
        <Form id='vacation'>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
            <Field
              fullWidth
              component={TextField}
              //   disabled={sessionData?.status === 'active'}
              name={`name`}
              placeholder='Vacation Name'
              helperText='Enter your vacation details'
              variant={variant}
              label='Vacation name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SubjectIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Field
              fullWidth={true}
              name={`start_date`}
              component={DateSelectorFormik}
              variant={variant}
              disabled={sessionData?.status === 'active'}
              minDate={sessionStartDate ? sessionStartDate : new Date()}
              maxDate={sessionEndDate ? sessionEndDate : new Date()}
              label='Date'
              helperText={'Select start date*'}
            />

            <Field
              fullWidth={true}
              name={`end_date`}
              helperText={'Select end date'}
              component={DateSelectorFormik}
              variant={variant}
              label='Date'
              minDate={sessionStartDate ? sessionStartDate : new Date()}
              maxDate={sessionEndDate ? sessionEndDate : new Date()}
              disabled={sessionData?.status === 'active'}
            />
            <Stack sx={{ pb: 5 }}>
              <DefaultButton
                startIcon={editVacation ? <EditIcon /> : <AddIcon />}
                form={'vacation'}
                type='submit'
                disabled={sessionData?.status === 'active'}
              >
                {editVacation ? 'UPDATE' : 'ADD'}
              </DefaultButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
