import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Copyright from './Copyright';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { Auth, Hub } from 'aws-amplify';
import NameTextField from '../../../components/Form/NameTextField';
import { isNotBlank } from '../../../components/Form/Validators';
import EmailTextField from '../../../components/Form/EmailTextField';
import PasswordTextField from '../../../components/Form/PasswordTextField';
import PhoneNumberTextField from '../../../components/Form/PhoneNumberTextField';
import { Snackbar, Backdrop, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import VerifyEmailByCode from './VerifyEmailByCode';
import { Link } from 'aws-amplify-react';
import LOGGER from '../../../Logger';
import { getGuidedWorkflow } from 'clients/SpotBus';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  media: {
    margin: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ERR_MESSAGES = {
  UsernameExistsException: 'User already registered with the given email address.',
  InvalidParameterException: 'Please correct invalid fields in form and try again.',
};

export default function SignUp(props) {
  LOGGER.info('Page: Sign Up with User: ' + JSON.stringify(props.user));

  function getDefaultUserAttributeVal(att, defaultValue) {
    if (props.user == undefined) {
      return defaultValue;
    }

    let userAtts = props.user.challengeParam.userAttributes;
    return userAtts != undefined && userAtts[att] != undefined ? userAtts[att] : defaultValue;
  }

  const [gender, setGender] = React.useState(getDefaultUserAttributeVal('gender', 'female'));
  const [username, setUsername] = React.useState(getDefaultUserAttributeVal('email', undefined));
  const [phoneNumber, setPhoneNumber] = React.useState(
    getDefaultUserAttributeVal('phone_number', undefined),
  );
  const [password, setPassword] = React.useState(undefined);
  const [confirmPassword, setConfirmPassword] = React.useState(undefined);
  const [firstName, setFirstName] = React.useState(
    getDefaultUserAttributeVal('given_name', undefined),
  );
  const [lastName, setLastName] = React.useState(
    getDefaultUserAttributeVal('family_name', undefined),
  );
  const [organization, setOrganization] = React.useState(
    getDefaultUserAttributeVal('organization', undefined),
  );
  const [address, setAddress] = React.useState(getDefaultUserAttributeVal('address', undefined));
  const [error, setError] = React.useState('');

  // Validation Status flags
  const [isUsernameValidated, setUsernameValidated] = React.useState(false);
  const [isPhoneNumberValidated, setPhoneNumberValidated] = React.useState(false);
  const [isPasswordValidated, setPasswordValidated] = React.useState(false);
  const [isConfirmPasswordValidated, setConfirmPasswordValidated] = React.useState(false);
  const [isFirstNameValidated, setFirstNameValidated] = React.useState(false);
  const [isLastNameValidated, setLastNameValidated] = React.useState(false);
  const [isOrganizationValidated, setOrganizationValidated] = React.useState(false);
  const [isAddressValidated, setAddressValidated] = React.useState(false);

  //Control Flags
  const [isLoading, setLoading] = React.useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = React.useState(false);
  const [signUpSuccessful, setSignUpSuccessful] = React.useState(false);
  const [verifyEmailCode, setVerifyEmailCode] = React.useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  function signUpClicked(event) {
    event.preventDefault();
    console.log('Signing Up user with email: ' + { username } + ' firstName: ' + { firstName });
    setLoading(true);
    try {
      const { user } = Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
          name: username,
          phone_number: phoneNumber,
          given_name: firstName,
          family_name: lastName,
          gender: gender,
          picture: '',
          birthdate: '',
          address: address,
          'custom:user_type': 'superAdmin'
        },
      }).then(
        (response) => {
          console.log('Response: ' + JSON.stringify(response));
          setVerifyEmailCode(true);
          setLoading(false);
        },
        (error) => {
          console.log('err: ' + JSON.stringify(error));

          if (error.code == 'UsernameExistsException') {
            setError(ERR_MESSAGES[error.code]);
            setShowErrorSnackBar(true);
          }

          if (error.code == 'InvalidParameterException') {
            setError(error.message);
            setShowErrorSnackBar(true);
          }
          setLoading(false);
        },
      );
    } catch (error) {
      console.log('error signing up:', error);
    }
  }

  async function signInUser() {
    try {
      const user = await Auth.signIn(username, password);
      try {
        let res = await getGuidedWorkflow();
        console.log('Guided workflow result', res);
        props.handleGuidedWorkflowDataUpdate(res);
      } catch (error) {
        console.log('error in get guided workflow', error);
      }
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const changeVerifiedStatus = () => {
    setTimeout(() => {
      signInUser();
    }, 3000);
  };

  function renderSignUpSuccess() {
    return (
      <React.Fragment>
        <Typography component='h1' variant='h5' className={classes.title}>
          Thanks for registering with SpotBus.
        </Typography>
        <Typography variant='subtitle1' gutterBottom className={classes.subtitle} align='left'>
          Redirecting...
        </Typography>
      </React.Fragment>
    );
  }

  function showSignIn() {
    Hub.dispatch('authPage', {
      event: 'SIGN_IN',
      data: { color: 'blue' },
      message: 'Redirect to sign IN.',
    });
  }
  function renderSignUpForm() {
    return (
      <React.Fragment>
        <Typography component='h1' variant='h5' className={classes.title}>
          Register your transportation company:
        </Typography>
        <form
          className={classes.form}
          // ref="form"
          onSubmit={signUpClicked}
          disabled={isLoading}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Snackbar
                open={showErrorSnackBar}
                autoHideDuration={6000}
                onClose={(e) => {
                  setShowErrorSnackBar(false);
                }}
              >
                <MuiAlert
                  elevation={6}
                  variant='filled'
                  onClose={(e) => {
                    setShowErrorSnackBar(false);
                  }}
                  severity='error'
                >
                  {error}
                </MuiAlert>
              </Snackbar>
            </Grid>

            <Grid item xs={12} sm={6}>
              <NameTextField
                autoComplete='fname'
                name='firstName'
                variant='outlined'
                required
                fullWidth
                id='firstName'
                label='First Name'
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                autoFocus
                validationRules={[
                  {
                    function: isNotBlank,
                    errorMessage: 'First Name is Required.',
                  },
                ]}
                validationstatushandler={setFirstNameValidated}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NameTextField
                variant='outlined'
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='lastName'
                autoComplete='lname'
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                validationRules={[
                  {
                    function: isNotBlank,
                    errorMessage: 'Last Name is Required.',
                  },
                ]}
                validationstatushandler={setLastNameValidated}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <EmailTextField
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                validationstatushandler={setUsernameValidated}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                autoComplete='current-password'
                validationstatushandler={setPasswordValidated}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <PasswordTextField
                name='confirmpassword'
                label='Confirm Password'
                id='confirmpassword'
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                value={confirmPassword}
                validationRules={[
                  {
                    function: (value) => {
                      if (value !== password) {
                        return false;
                      }
                      return true;
                    },
                    errorMessage: 'Confirm Password should match with the password.',
                  },
                ]}
                validationstatushandler={setConfirmPasswordValidated}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel component='legend'>Gender</FormLabel>
              <RadioGroup
                aria-label='gender'
                name='gender1'
                value={gender}
                onChange={handleChange}
                disabled={isLoading}
                row
              >
                <FormControlLabel
                  value='female'
                  control={<Radio />}
                  label='Female'
                  disabled={isLoading}
                />
                <FormControlLabel
                  value='male'
                  control={<Radio />}
                  label='Male'
                  disabled={isLoading}
                />
                <FormControlLabel
                  value='other'
                  control={<Radio />}
                  label='Rather not specified'
                  disabled={isLoading}
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <PhoneNumberTextField
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
                validationstatushandler={setPhoneNumberValidated}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NameTextField
                variant='outlined'
                required
                fullWidth
                name='organization'
                label='Organization'
                type='organization'
                id='organization'
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
                value={organization}
                validationRules={[
                  {
                    function: isNotBlank,
                    errorMessage: 'Organization is Required.',
                  },
                ]}
                validationstatushandler={setOrganizationValidated}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <NameTextField
                variant='outlined'
                required
                fullWidth
                name='address'
                label='Address'
                type='address'
                id='address'
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
                validationRules={[
                  {
                    function: isNotBlank,
                    errorMessage: 'Address is Required.',
                  },
                ]}
                validationstatushandler={setAddressValidated}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </form>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={signUpClicked}
          disabled={
            !(
              isUsernameValidated &&
              isPhoneNumberValidated &&
              isPasswordValidated &&
              isConfirmPasswordValidated &&
              isFirstNameValidated &&
              isLastNameValidated &&
              isOrganizationValidated &&
              isAddressValidated
            ) || isLoading
          }
        >
          Sign Up
        </Button>

        <Grid container>
          <Grid item>
            <Link onClick={showSignIn} variant='body2' style={{ color: '#000' }}>
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function renderVerifyEmailCode() {
    return (
      <VerifyEmailByCode
        username={username}
        onSuccess={(message) => {
          setSignUpSuccessful(true);
          changeVerifiedStatus();
        }}
        {...props}
      />
    );
  }
  return (
    <Container component='main' maxWidth='xs'>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <CssBaseline />
      <div className={classes.paper}>
        <CardMedia
          className={classes.media}
          src={require('../../../assets/static/images/SpotBus_Logo.png')}
          component='img'
        />

        {signUpSuccessful
          ? renderSignUpSuccess()
          : verifyEmailCode
          ? renderVerifyEmailCode()
          : renderSignUpForm()}
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}