import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Tooltip } from '@mui/material';
import { getIcon, getLabel } from '../TripReport/ChipType';

export default function CategoryAccordion({ categoryName, chipType, details, index }) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            {categoryName} <Tooltip title={getLabel(chipType)}>{getIcon(chipType)}</Tooltip>
          </Grid>
        </AccordionSummary>
        <Divider />
        {details}
      </Accordion>
      <Divider />
    </div>
  );
}
