import moment from 'moment';
/**
 * Generates events from the given data for a specific month.
 *
 * @param {Array} data - The data to generate events from.
 * @return {Array} An array of events generated from the data.
 */
export const generateEventsFromDataMonth = (data) => {
  const events = [];

  data.forEach((entry) => {
    const startDate = moment(entry.startDate, 'MM/DD/YYYY');
    const endDate = moment(entry.endDate, 'MM/DD/YYYY');

    let currentDate = startDate.clone();

    while (currentDate <= endDate) {
      const currentDay = moment(currentDate, 'MM/DD/YYYY').format('DD');

      for (let i = 0; i < entry.monthlyDate.length; i++) {
        const valueDay = `${entry.monthlyDate[i].startDateMonthly}`;
        const singleDay = valueDay.length === 1 ? `0${valueDay}` : valueDay;

        const startTime = moment(entry.monthlyDate[i].clockIn, 'HH:mm:ss');
        const endTime = moment(entry.monthlyDate[i].clockOut, 'HH:mm:ss');

        const startDateTime = currentDate.clone().set({
          hour: startTime.hours(),
          minute: startTime.minutes(),
          second: startTime.seconds(),
        });

        const endDateTime = currentDate.clone().set({
          hour: endTime.hours(),
          minute: endTime.minutes(),
          second: endTime.seconds(),
        });

        if (currentDay === singleDay) {
          events.push({
            shiftID: entry.shiftID,
            title: entry.shiftTitle,
            start: startDateTime.toDate(),
            end: endDateTime.toDate(),
            dateOccurrence: entry.dateOccurrence,
          });
        }
      }

      currentDate.add(1, 'days');
    }
  });

  return events;
};

/**
 * Generates events from the given data.
 *
 * @param {Array} data - The data to generate events from.
 * @return {Array} An array of events generated from the data.
 */
export const generateEventsFromData = (data) => {
  const events = [];

  data.forEach((entry) => {
    const startDate = moment(entry.startDate, 'MM/DD/YYYY');
    const endDate = moment(entry.endDate, 'MM/DD/YYYY');

    let currentDate = startDate.clone();

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.format('dddd').toLowerCase();
      const startTimeKey = `${dayOfWeek}StartTime`;
      const endTimeKey = `${dayOfWeek}EndTime`;

      if (entry[dayOfWeek + 'CheckBox']) {
        const startTime = moment(entry[startTimeKey], 'HH:mm:ss');
        const endTime = moment(entry[endTimeKey], 'HH:mm:ss');

        const startDateTime = currentDate.clone().set({
          hour: startTime.hours(),
          minute: startTime.minutes(),
          second: startTime.seconds(),
        });

        const endDateTime = currentDate.clone().set({
          hour: endTime.hours(),
          minute: endTime.minutes(),
          second: endTime.seconds(),
        });

        events.push({
          shiftID: entry.shiftID,
          title: entry.shiftTitle,
          start: startDateTime.toDate(),
          end: endDateTime.toDate(),
          dateOccurrence: entry.dateOccurrence,
        });
      }

      currentDate.add(1, 'days');
    }
  });

  return events;
};

export const generateEventsFromAdhoc = (data) => {
  const events = [];

  data.forEach((entry) => {
    const startDate = moment(entry.startDate, 'MM/DD/YYYY');
    const endDate = moment(entry.endDate, 'MM/DD/YYYY');

    const startTime = moment(entry.adhocStartTime, 'HH:mm:ss');
    const endTime = moment(entry.adhocEndTime, 'HH:mm:ss');

    const startDateTime = startDate.clone().set({
      hour: startTime.hours(),
      minute: startTime.minutes(),
      second: startTime.seconds(),
    });

    const endDateTime = endDate.clone().set({
      hour: endTime.hours(),
      minute: endTime.minutes(),
      second: endTime.seconds(),
    });

    events.push({
      shiftID: entry.shiftID,
      title: entry.shiftTitle,
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      dateOccurrence: entry.dateOccurrence,
    });
  });

  return events;
};

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

export function getCurrentTime() {
  return moment().format('HH:mm:ss');
}

export function getCurrentTimePlus30Minutes() {
  return moment().add(30, 'minutes');
}
