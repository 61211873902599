import React from 'react';

// material-ui
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// project imports
import { SuccessChip, FailureChip } from './helpers';

export function GetStatusAndLastUpdatedTime({ status, lastUpdatedTime, isDataLoading }) {
  return (
    <Grid container spacing={3}>
      {!isDataLoading ? (
        <Grid item xs={5}>
          <Typography display='inline' color='textPrimary'>
            Status :
          </Typography>
          {status === 'Active' ? (
            <SuccessChip variant='outlined' size='small' label='Active'></SuccessChip>
          ) : (
            <FailureChip variant='outlined' size='small' label={status}></FailureChip>
          )}
        </Grid>
      ) : (
        <Grid item xs={5}>
          <Skeleton variant='text' />
        </Grid>
      )}
      <Grid item xs={7}>
        {!isDataLoading ? (
          <Typography color='textPrimary' align='right'>
            {lastUpdatedTime}
          </Typography>
        ) : (
          <Skeleton variant='text' />
        )}
      </Grid>
    </Grid>
  );
}
