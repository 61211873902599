// assets

// icons filled
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import MapIcon from '@mui/icons-material/Map';
import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { PiSteeringWheelBold, PiSteeringWheelFill, PiStudentBold, PiStudentFill } from 'react-icons/pi';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BusAlertIcon from '@mui/icons-material/BusAlert'; // outlined
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import BusAlertOutlinedIcon from '@mui/icons-material/BusAlertOutlined'; // ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const items = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'drawerGroup',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icons: {
        filled: DashboardIcon,
        outlined: DashboardOutlinedIcon,
      },
      breadcrumbs: false,
      isDrawerHidden: false,
    },
    // {
    //   id: 'ai-assistant',
    //   title: 'AI Assistant',
    //   type: 'item',
    //   url: '/ai-assistant',
    //   icons: {
    //     filled: ChatIcon,
    //     outlined: ChatOutlinedIcon,
    //   },
    //   breadcrumbs: true,
    //   isDrawerHidden: false,
    // },
    {
      id: 'live_view',
      title: 'Live View',
      type: 'item',
      url: '/live-view',
      icons: {
        filled: LiveTvOutlinedIcon,
        outlined: LiveTvOutlinedIcon,
      },
      breadcrumbs: false,
      isDrawerHidden: false,
    },
    {
      id: 'incident',
      title: 'Incident Management',
      type: 'collapse',
      url: '/incident',
      open: true,
      icons: {
        filled: NotificationsActiveIcon,
        outlined: NotificationsActiveOutlinedIcon,
      },
      children: [
        {
          id: 'incident_table',
          title: 'Incidents',
          type: 'item',
          url: '/incident',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'incident_details',
          title: 'Configuration',
          type: 'item',
          url: '/incident/configuration',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
      ],
    },
    {
      id: 'trips',
      title: 'Trips',
      type: 'item',
      url: '/trips',
      icons: {
        filled: DirectionsBusIcon,
        outlined: DirectionsBusFilledOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
    },
    {
      id: 'trip-inspection',
      title: 'Trip Inspection',
      type: 'collapse',
      url: '/trip-inspection',
      icons: {
        filled: BusAlertIcon,
        outlined: BusAlertOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
      children: [
        {
          id: 'trip_inspection_reports',
          title: 'Reports',
          type: 'item',
          url: '/trip-inspection/reports',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'pre_trip-form',
          title: 'Pre Trip Form',
          type: 'item',
          url: '/trip-inspection/forms/pre-trip',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'post_trip_form',
          title: 'Post Trip Form',
          type: 'item',
          url: '/trip-inspection/forms/post-trip',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'trip_inspection-report_details',
          title: 'Trip Inspection Details',
          type: 'item',
          url: '/trip-inspection/reports/:id',
          breadcrumbs: false,
          isDrawerHidden: true,
        },
      ],
    },

    // {
    //   id: 'breakdowns',
    //   title: 'Breakdowns',
    //   type: 'item',
    //   url: '/breakdowns',
    //   icons: {
    //     filled: ErrorIcon,
    //     outlined: ErrorOutlineOutlinedIcon,
    //   },
    //   breadcrumbs: true,
    //   isDrawerHidden: false,
    // },
    {
      id: 'students',
      title: 'Students',
      type: 'collapse',
      url: '/students',
      icons: {
        filled: PiStudentFill,
        outlined: PiStudentBold,
      },
      open: true,
      children: [
        {
          id: 'list_students',
          title: 'List Students',
          type: 'item',
          url: '/students',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'bulk_import_students',
          title: 'Import Students',
          type: 'item',
          url: '/students/import',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'student_attendance_list',
          title: 'Attendance Requests',
          type: 'item',
          url: '/students/attendance-list',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
      ],
    },
    {
      id: 'routes',
      title: 'Routes',
      type: 'item',
      url: '/routes',
      icons: {
        filled: MapIcon,
        outlined: MapOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
    },
    {
      id: 'route_details',
      title: 'Route Details',
      type: 'item',
      url: '/routes/:id',
      breadcrumbs: true,
      isDrawerHidden: true,
    },
    {
      id: 'drivers',
      title: 'Drivers',
      type: 'collapse',
      icons: {
        filled: PiSteeringWheelFill,
        outlined: PiSteeringWheelBold,
      },
      url: '/drivers',
      open: true,
      children: [
        {
          id: 'list_drivers',
          title: 'List Drivers',
          type: 'item',
          url: '/drivers',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'bulk_import_drivers',
          title: 'Import Drivers',
          type: 'item',
          url: '/drivers/import',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'drivers_app',
          title: 'Drivers App',
          type: 'item',
          url: '/drivers/app',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
      ],
    },
    {
      id: 'vehicles',
      title: 'Vehicles',
      type: 'item',
      url: '/vehicles',
      icons: {
        filled: AirportShuttleIcon,
        outlined: AirportShuttleOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
    },
    {
      id: 'devices',
      title: 'Devices',
      type: 'item',
      url: '/devices',
      icons: {
        filled: DevicesIcon,
        outlined: DevicesOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
      children: [
        {
          id: 'list_vehicles',
          title: 'Vehicles List',
          type: 'item',
          url: '/vehicles',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'list_devices',
          title: 'Devices',
          type: 'item',
          url: '/vehicles/devices',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
      ],
    },
    {
      id: 'students_attendance',
      title: 'Student Attendance',
      type: 'item',
      url: '/students/attendance/:id/:id',
      breadcrumbs: true,
      isDrawerHidden: true,
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'item',
      url: '/schools',
      icons: {
        filled: SchoolIcon,
        outlined: SchoolOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
    },
    {
      id: 'parents',
      title: 'Parents',
      type: 'item',
      url: '/parents',
      icons: {
        filled: SupervisorAccountIcon,
        outlined: SupervisorAccountOutlinedIcon,
      },
      breadcrumbs: true,
      isDrawerHidden: false,
    },
    // {
    //   id: 'forms',
    //   title: 'Forms',
    //   type: 'collapse',
    //   url: '/forms',
    //   icons: {
    //     filled: ListAltIcon,
    //     outlined: ListAltOutlinedIcon,
    //   },
    //   open: true,
    //   children: [
    //     {
    //       id: 'form_manage',
    //       title: 'Manage Forms',
    //       type: 'item',
    //       url: '/forms',
    //       breadcrumbs: true,
    //       isDrawerHidden: false,
    //     },
    //     {
    //       id: 'form_requests',
    //       title: 'Form Responses',
    //       type: 'item',
    //       url: '/forms/responses',
    //       breadcrumbs: true,
    //       isDrawerHidden: false,
    //     },
    //   ],
    // },
    {
      id: 'communication',
      title: 'Communications',
      type: 'collapse',
      url: '/communications',
      icons: {
        filled: ContactsIcon,
        outlined: ContactsOutlinedIcon,
      },
      open: true,
      children: [
        {
          id: 'historical_communications',
          title: 'Sent',
          type: 'item',
          url: '/communications',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'create_communication',
          title: 'Compose',
          type: 'item',
          url: '/communications/create',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'edit_communication',
          title: 'Edit Communication',
          type: 'item',
          url: '/communications/edit/:id',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
        {
          id: 'communication_templates',
          title: 'Templates',
          type: 'item',
          url: '/communications/templates',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
        {
          id: 'communication_templates_create',
          title: 'Create Communication Template',
          type: 'item',
          url: '/communications/templates/create',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
        {
          id: 'communication_templates_update',
          title: 'Edit Communication Template',
          type: 'item',
          url: '/communications/templates/edit/:id',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
        {
          id: 'template_library',
          title: 'Communication Templates library',
          type: 'item',
          url: '/communications/templates/library',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
        {
          id: 'organization_template',
          title: 'Organization Communication Templates',
          type: 'item',
          url: '/communications/templates/organization',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
        {
          id: 'historical_communication_details',
          title: 'Historical Communication Details',
          type: 'item',
          url: '/communication/:communication_id',
          breadcrumbs: true,
          isDrawerHidden: true,
        },

        {
          id: 'canvas_communication_template',
          title: 'Canvas Communication Template',
          type: 'item',
          url: '/communications/create/canvas/:id',
          breadcrumbs: true,
          isDrawerHidden: true,
        },
      ],
    },
    {
      id: 'create_session',
      title: 'Create Session',
      type: 'item',
      url: '/sessions/create',
      breadcrumbs: true,
      isDrawerHidden: true,
    },
    {
      id: 'update_session',
      title: 'Update Session',
      type: 'item',
      url: '/sessions/edit/:id',
      breadcrumbs: true,
      isDrawerHidden: true,
    },
    {
      id: 'Schools-website',
      title: 'Schools Website',
      type: 'collapse',
      url: '/public-route-dashboard',
      icons: {
        filled: DisplaySettingsIcon,
        outlined: DisplaySettingsOutlinedIcon,
      },
      open: false,
      children: [
        {
          id: 'public-route-dashboard',
          title: 'Public Route Dashboard',
          type: 'item',
          url: '/public-route-dashboard',
          breadcrumbs: true,
          isDrawerHidden: false,
        },
      ],
    },
    // {
    //   id: 'reports',
    //   title: 'Reports',
    //   type: 'item',
    //   url: '/reports',
    //   icons: {
    //     filled: AssessmentIcon,
    //     outlined: AssessmentOutlinedIcon,
    //   },
    //   breadcrumbs: true,
    //   isDrawerHidden: false,
    // },
  ],
};

export default items;
