import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const xAxis = [
  'Address',
  'Primary Pick Up Location',
  'Primary Drop Off Location',
  'Secondary Pick Up Location',
  'Secondary Drop Off Location',
  'Special Need Pick Up',
  'Special Need Drop Off',
];
const yAxis = ['1', '2', '3', '4', '5', '6'];

const address = [
  { value: 'a' },
  { value: 'b' },
  { value: 'c' },
  { value: 'd' },
  { value: 'e' },
  { value: 'f' },
];

const SchoolLocation = ({
  handleClickOpen,
  setEdit,
  handleOpenDelete,
  multipleAddress,
  setEditLocationData,
  setLocationMapping,
  locationMapping,
  setPrimaryDrop,
  primaryDrop,
  setPrimaryPick,
  primaryPick,
  formikProps,
}) => {
  const [secondPick, setSecondPick] = useState('');
  const [secondDrop, setSecondDrop] = useState('');
  const [specialPick, setSpecialPick] = useState('');
  const [specialDrop, setSpecialDrop] = useState('');

  const handlePrimaryPick = (event) => {
    setPrimaryPick(event.target.value);
    setLocationMapping({
      primary_pickup: event.target.value,
      primary_drop: primaryDrop,
      secondary_pickup: secondPick,
      secondary_drop: secondDrop,
      special_pickup: specialPick,
      special_drop: specialDrop,
    });
  };
  const handlePrimaryDrop = (event) => {
    setPrimaryDrop(event.target.value);
    setLocationMapping({
      primary_pickup: primaryPick,
      primary_drop: event.target.value,
      secondary_pickup: secondPick,
      secondary_drop: secondDrop,
      special_pickup: specialPick,
      special_drop: specialDrop,
    });
  };
  const handleSecondPick = (event) => {
    setSecondPick(event.target.value);
    setLocationMapping({
      primary_pickup: primaryPick,
      primary_drop: primaryDrop,
      secondary_pickup: event.target.value,
      secondary_drop: secondDrop,
      special_pickup: specialPick,
      special_drop: specialDrop,
    });
  };
  const handleSecondDrop = (event) => {
    setSecondDrop(event.target.value);
    setLocationMapping({
      primary_pickup: primaryPick,
      primary_drop: primaryDrop,
      secondary_pickup: secondPick,
      secondary_drop: event.target.value,
      special_pickup: specialPick,
      special_drop: specialDrop,
    });
  };
  const handleSpecialPick = (event) => {
    setSpecialPick(event.target.value);
    setLocationMapping({
      primary_pickup: primaryPick,
      primary_drop: primaryDrop,
      secondary_pickup: secondPick,
      secondary_drop: secondDrop,
      special_pickup: event.target.value,
      special_drop: specialDrop,
    });
  };
  const handleSpecialDrop = (event) => {
    setSpecialDrop(event.target.value);
    setLocationMapping({
      primary_pickup: primaryPick,
      primary_drop: primaryDrop,
      secondary_pickup: secondPick,
      secondary_drop: secondDrop,
      special_pickup: specialPick,
      special_drop: event.target.value,
    });
  };

  const handleEdit = (data) => {
    setEditLocationData(data);
    // setEdit(true);
    handleClickOpen();
  };

  const handleDelete = (data) => {
    setEditLocationData(data);
    handleOpenDelete();
  };

  useEffect(() => {
    if (locationMapping !== undefined) {
      setPrimaryPick(locationMapping?.primary_pickup ?? multipleAddress[0]?.id);
      setPrimaryDrop(locationMapping?.primary_drop ?? multipleAddress[0]?.id);
      setSecondPick(locationMapping?.secondary_pickup);
      setSecondDrop(locationMapping?.secondary_drop);
      setSpecialPick(locationMapping?.special_pickup);
      setSpecialDrop(locationMapping?.special_drop);
    }
  }, [locationMapping]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {xAxis.map((xItem, index) => (
              <TableCell key={index} align='center'>
                {xItem}
              </TableCell>
            ))}
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {multipleAddress?.map((add, addIndex) => (
            <TableRow>
              <TableCell align='center'>
                {addIndex === 0 ? formikProps.values.school_name : add.address.entity}
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={primaryPick === add.id}
                  onChange={handlePrimaryPick}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={primaryDrop === add.id}
                  onChange={handlePrimaryDrop}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={secondPick === add.id}
                  onChange={handleSecondPick}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={secondDrop === add.id}
                  onChange={handleSecondDrop}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={specialPick === add.id}
                  onChange={handleSpecialPick}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell align='center'>
                <Radio
                  key={addIndex}
                  checked={specialDrop === add.id}
                  onChange={handleSpecialDrop}
                  value={add.id}
                  inputProps={{ 'aria-label': add.id }}
                />
              </TableCell>

              <TableCell>
                {addIndex === 0 ? null : (
                  <IconButton onClick={() => handleEdit(add)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {addIndex === 0 ? null : (
                  <IconButton onClick={() => handleDelete(add)}>
                    <DeleteIcon color='error' />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SchoolLocation;
