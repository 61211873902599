import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import PublishIcon from '@mui/icons-material/Publish';





const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border : "3px" ,
      borderStyle: "solid" , 
      borderColor: 'primary.main',
      borderRadius: '16px',

      // border: '4px solid primary.main',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
 
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function ButtonBases({title, onClickHandler ,imageForButtonBackGround }) { 
  const image = {
    url: imageForButtonBackGround,
    title: 'Breakfast',
    width: '100%',
  };
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300 ,  width: '100%' }}>
      <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: image.width,
        }}
        onClick={onClickHandler}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className='MuiImageBackdrop-root' />

      
        <Image>
        {/* <PublishIcon /> */}
        
          <Typography
            component='span'
            variant='h4'
            color='inherit'
            sx={{
              position: 'relative',
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >  

           
            
            {title}
            <ImageMarked className='MuiImageMarked-root' />
          </Typography>
        </Image>
      </ImageButton>
    </Box>
  );
}





// // const ImageButton = styled(ButtonBase)(({ theme }) => ({
// //   position: 'relative',
// //   height: 200,
// //   [theme.breakpoints.down('sm')]: {
// //     width: '100% !important', // Overrides inline-style
// //     height: 100,
// //   },
// //   '&:hover, &.Mui-focusVisible': {
// //     zIndex: 1,
// //     '& .MuiImageBackdrop-root': {
// //       opacity: 0.15,
// //     },
// //     '& .MuiImageMarked-root': {
// //       opacity: 0,
// //     },
// //     '& .MuiTypography-root': {
// //       border : "3px" ,
// //       borderStyle: "solid" , 
// //       borderColor: 'primary.main',
// //       borderRadius: '16px',

// //       // border: '4px solid primary.main',
// //     },
// //   },
// // }));

// // const ImageSrc = styled('span')({
// //   position: 'absolute',
// //   left: 0,
// //   right: 0,
// //   top: 0,
// //   bottom: 0,
// //   backgroundSize: 'cover',
// //   backgroundPosition: 'center 40%',
// // });

// // const Image = styled('span')(({ theme }) => ({
// //   position: 'absolute',
// //   left: 0,
// //   right: 0,
// //   top: 0,
// //   bottom: 0,
// //   display: 'flex',
// //   alignItems: 'center',
// //   justifyContent: 'center',
// //   color: theme.palette.common.white,
 
// // }));

// // const ImageBackdrop = styled('span')(({ theme }) => ({
// //   position: 'absolute',
// //   left: 0,
// //   right: 0,
// //   top: 0,
// //   bottom: 0,
// //   backgroundColor: theme.palette.common.black,
// //   opacity: 0.4,
// //   transition: theme.transitions.create('opacity'),
// // }));

// // const ImageMarked = styled('span')(({ theme }) => ({
// //   height: 3,
// //   width: 18,
// //   backgroundColor: theme.palette.common.white,
// //   position: 'absolute',
// //   bottom: -2,
// //   left: 'calc(50% - 9px)',
// //   transition: theme.transitions.create('opacity'),
// // }));





// export default function ButtonBases({title, onClickHandler ,imageForButtonBackGround }) { 
 

//   const styling = {
//     display: 'flex', height : "200px" , 
//     flexDirection: 'column' , minWidth: 300 , 
//      border: 5  , justifyContent: 'center',    
//      borderColor: 'primary.main' , width: '100%' ,
//       alignItems :"center" ,
//       "&:hover" : {
//         borderRadius: '50%',
//       }
//   }

//   const styleIcon = {
//     fontSize : '100px', 
//     color  :  "primary.main",
//   }

//   return (


//     <Box  sx={styling}   onClick={onClickHandler}   > 

//      <PublishIcon  sx={styleIcon}/>
//      <Typography variant="h4" > {title}  </Typography>
//     </Box>


//   );
// }
