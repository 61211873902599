import React from 'react';
import TextField from '@mui/material/TextField';
import ValidatorComponent from './ValidatorComponent';
import { isNotBlank } from './Validators';

let MIN_LENGTH = 6

export default class VerificationCodeTextField extends ValidatorComponent {

    constructor(props){
        super(props)
        
    }

    isValidPhoneNumber(value){
        
        let re = /^\d+$/
        if (!re.test(value)) return false;
        
        return true
    }

    valdiateLength(value){
        
        return value.length >= MIN_LENGTH
    }


    getDefaultValidationRules(){
        return [
            {
                function: isNotBlank,
                errorMessage: "Verification Code field is required."
            },
            {
                function: this.isValidPhoneNumber,
                errorMessage: "Invalid Verification Code."
            },
            {
                function: this.valdiateLength,
                errorMessage: "Invalid Verification Code."
            }
        ]
    }

    render(){
        
        return (
            <TextField
                variant="outlined"
                required
                fullWidth
                name="verificationcode"
                label="Verification Code"
                type="verificationcode"
                id="verificationcode"
                {...this.props}
                onChange={(e) => { 
                    this.validate(e.target.value);
                    this.props.onChange(e)
                 }}
                error={this.state.errorMessage != undefined || this.props.error}
                helperText={this.state.errorMessage || this.props.helperText}
            />
        );
    }
}