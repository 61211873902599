import React, { useCallback } from 'react';

// material ui
import { Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// third party
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#e5b300';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#bdbdbd';
};

const getBgColor = (props) => {
  if (props.isDragAccept) {
    return '#ffecb3';
  }
  if (props.isDragReject) {
    return '#ffcdd2';
  }
  if (props.isDragActive) {
    return '#ffecb3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  border-radius: 0px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => getBgColor(props)};
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  min-height: 20vh;
  min-width: 20vw;
  max-height: 20vh;
  max-width: 20vw;
  height: 20vh;
  width: 20vw;
`;

export default function MyDropzone(props) {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile.length === 1) {
      props.handleDragImage(acceptedFile, props.imageType);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
    maxFiles: 1,
  });

  return (
    <div id={props.imageType} className={classes.container}>
      {!props.disabled ? (
        <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
          <input {...getInputProps()} disabled={props.disabled} />
          {props.selectedFile ? (
            <Tooltip title={props.tooltipTitle}>
              <img src={props.selectedFile} alt={props.imageType} className={classes.img} />
            </Tooltip>
          ) : (
            <Typography style={{ paddingLeft: '5%', paddingRight: '5%' , align : "center" }}  >
              {props.selectedFile === undefined ? props.text : ''}
            </Typography>
          )}
        </Container>
      ) : (
        <Tooltip title={'Disabled'}>
          <Container>
            <Typography style={{ paddingLeft: '5%', paddingRight: '5%' ,align : "right" }}   >
              {props.selectedFile === undefined ? props.text : ''}
            </Typography>
          </Container>
        </Tooltip>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  image: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
}));
