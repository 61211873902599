import React from 'react';
import { Typography } from '@mui/material';

export function AdditionalSubHeading({ subTitle, color = 'text' }) {
  return (
    <Typography color={color} sx={{ fontSize: 20, fontWeight: 600 }} gutterBottom>
      {subTitle}
    </Typography>
  );
}
