import React, { useState, useEffect } from 'react';

// material ui
import { Grid } from '@mui/material';

// third party
import { useSnackbar } from 'notistack';

import * as yup from 'yup';

// project imports
import {
  deleteSchool,
  registerSchool,
  uploadSchoolLogo,
  updateSchool,
  updateGuidedWorkFlow,
} from 'clients/SpotBus';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import AddSchool from './AddSchool';
import SchoolList from '../../List/SchoolList/index';
import MainCard from 'components/MainCard';
import BackForward from '../../BackForward';
import { searchAddress } from './Nominatim';
import SchoolsForm from './SchoolsForm';
import AddLocation from './AddLocation';
import DeleteAlert from 'components/alert/DeleteAlert';

export default function SchoolForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Image variables
  const [previewImage, setPreviewImage] = useState();
  const [imageData, setImageData] = useState(undefined);
  const [imageValidation, setImageValidation] = useState(false);
  const [editLocationData, setEditLocationData] = useState();

  const [buttonLoader, setButtonLoader] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSchoolData, setSelectedSchoolData] = useState();

  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [editData, setEditData] = useState('');

  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [openDeleteSessionAlert, setOpenDeleteSessionAlert] = React.useState(false);

  const [multipleAddress, setMultipleAddress] = React.useState([]);
  const [locationMapping, setLocationMapping] = React.useState();

  const [primaryPick, setPrimaryPick] = useState('');
  const [primaryDrop, setPrimaryDrop] = useState('');

  const [addAddress, setAddAddress] = useState(false);
  const [schoolAddress, setschoolAddress] = useState();
  const [defaultPosition, setDefaultPosition] = useState([42.26955505106097, -71.61306262023574]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setAddAddress(false);
  };

  // const [updatedGuidedWorkflowData, setUpdatedGuidedWorkflowData] = useState(undefined);
  // const [proceedButtonLoader, setProceedButtonLoader] = useState(false);

  const schoolTypes = [
    { name: 'Public', value: 'public' },
    { name: 'Private', value: 'private' },
    { name: 'Special School', value: 'specialSchool' },
  ];

  // function for selecting or drag and drop logo in drop zone
  const handleDragImage = (acceptedFile) => {
    let imageFile = acceptedFile['0'].size;
    let imageSize = Math.round(imageFile / 1024);

    if (acceptedFile.length < 1) {
      return;
    } else if (acceptedFile.length === 1) {
      if (imageSize <= 3072) {
        setImageData(acceptedFile[0]);
        setPreviewImage(URL.createObjectURL(acceptedFile[0]));
      } else {
        setImageData(undefined);
        setPreviewImage(undefined);
        enqueueSnackbar('Logo must be less than 3MB', {
          variant: 'error',
        });
      }
    }
  };

  const registerSchoolWithImage = (values) => {
    return new Promise((resolve, reject) => {
      setButtonLoader(true);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imageData);
      fileReader.onload = (e) => {
        uploadSchoolLogo(e.target.result).then(
          (result) => {
            registerNewSchool(result, values);
            resolve();
          },
          (error) => {
            console.log(error);
            enqueueSnackbar(getErrorMessage(error), {
              variant: 'error',
            });
            reject();
          },
        );
      };
    });
  };

  const updateSchoolWithImage = (schoolData, values) => {
    setButtonLoader(true);
    if (imageData) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imageData);
        fileReader.onload = (e) => {
          uploadSchoolLogo(e.target.result).then(
            (result) => {
              updateSelectedSchool(result, schoolData, values);
              resolve();
            },
            (error) => {
              console.log(error);
              enqueueSnackbar(getErrorMessage(error), {
                variant: 'error',
              });
              reject();
            },
          );
        };
      });
    } else {
      updateSelectedSchool(schoolData.image, schoolData, values);
    }
  };

  const updateSelectedSchool = (imageResponse, schoolData, values) => {
    props.setloadingData(true);
    let dummyData = {
      school_id: schoolData.school_id,
      school_name: values.school_name,
      abbreviation: values.abbreviation,
      school_type: values.school_type,
      time_zone: values.time_zone,
      address: {
        entity: values.school_name,
        apt_plot: values.apt_plot,
        street: values.street,
        city: values.city,
        state: values.state,
        zip_code: values.zip_code,
        country: values.country,
      },
      geo_location: {
        latitude: multipleAddress[0].geo_location.latitude,
        longitude: multipleAddress[0].geo_location.longitude,
      },
      locations: multipleAddress,
      locations_mapping: locationMapping,
      image: imageResponse,
      start_timing: [
        readableTime(values.start_timing[0]) + ':00',
        readableTime(values.start_timing[1]) + ':00',
      ],
      end_timing: [
        readableTime(values.end_timing[0]) + ':00',
        readableTime(values.end_timing[1]) + ':00',
      ],
      policies: {
        acknowledgementForStateCompliance: values.acknowledgementForStateCompliance,
      },
      created_timestamp: schoolData.created_timestamp,
      created_by: schoolData.created_by,
      last_updated_timestamp: schoolData.last_updated_timestamp,
      last_updated_by: schoolData.last_updated_by,
      transportation_admin_username: schoolData.transportation_admin_username,
      organization_admin_username: schoolData.organization_admin_username,
      level: values.level,
    };
    // console.log('update school dummy data', JSON.stringify(dummyData, null, 2));
    // api call here for saving data to server
    return new Promise((resolve, reject) => {
      updateSchool(dummyData, schoolData.school_id).then(
        (result) => {
          props.getAllSchools();
          enqueueSnackbar('School updated successfully', {
            variant: 'success',
          });
          setButtonLoader(false);
          props.setloadingData(false);
          handleStatesOnSave();
          resolve();
        },
        (error) => {
          props.setloadingData(false);
          console.log('Error update school: ' + JSON.stringify(error));
          var errorMessage = 'Something went wrong please try again later.';
          if (
            error !== undefined &&
            error['data'] !== undefined &&
            error['data']['Message'] !== undefined
          ) {
            errorMessage = error['data']['Message'];
          }
          setButtonLoader(false);
          enqueueSnackbar(getErrorMessage(errorMessage), {
            variant: 'error',
          });
          reject();
        },
      );
    });
  };

  const handleStatesOnSave = () => {
    setschoolAddress();
    setIsEdit(false);
    setPreviewImage(undefined);
    setImageData(undefined);
    setImageValidation(false);
    setSelectedSchoolData();
    setProceed(false);
    setMultipleAddress([]);
    setLocationMapping();
  };

  const registerNewSchool = (imageResponse, values) => {
    props.setloadingData(true);
    let dummyData = {
      school_name: values.school_name,
      abbreviation: values.abbreviation,
      school_type: values.school_type,
      time_zone: values.time_zone,
      address: {
        entity: values.school_name,
        apt_plot: values.apt_plot,
        street: values.street,
        city: values.city,
        state: values.state,
        zip_code: values.zip_code,
        country: values.country,
      },
      geo_location: {
        latitude: multipleAddress[0].geo_location.latitude,
        longitude: multipleAddress[0].geo_location.longitude,
      },
      locations: multipleAddress,
      locations_mapping: locationMapping,
      image: imageResponse,
      start_timing: [
        readableTime(values.start_timing[0]) + ':00',
        readableTime(values.start_timing[1]) + ':00',
      ],
      end_timing: [
        readableTime(values.end_timing[0]) + ':00',
        readableTime(values.end_timing[1]) + ':00',
      ],
      policies: {
        acknowledgementForStateCompliance: values.acknowledgementForStateCompliance,
      },
      level: values.level,
    };
    // console.log('register school dummy data', JSON.stringify(dummyData, null, 2));
    // api call here for saving data to server
    return new Promise((resolve, reject) => {
      registerSchool(dummyData, 'True').then(
        (result) => {
          props.getAllSchools();
          enqueueSnackbar('School registered successfully', {
            variant: 'success',
          });
          setButtonLoader(false);
          handleStatesOnSave();
          props.setloadingData(false);
          handleGUidedWorkFlowOnRegisterSchool(result);
          resolve();
        },
        (error) => {
          props.setloadingData(false);
          console.log('Error register school: ' + JSON.stringify(error));
          setButtonLoader(false);
          var errorMessage = 'Something went wrong please try again later.';
          if (
            error !== undefined &&
            error['data'] !== undefined &&
            error['data']['Message'] !== undefined
          ) {
            errorMessage = error['data']['Message'];
          }
          enqueueSnackbar(getErrorMessage(errorMessage), {
            variant: 'error',
          });
          reject();
        },
      );
    });
  };

  const getIndex = (req) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'CONFIGURE_SCHOOLS') {
        return i;
      }
    }
  };

  const handleGUidedWorkFlowOnRegisterSchool = async (data) => {
    let req = data.guided_info;
    let idx = getIndex(data.guided_info);
    for (let i = 0; i < req.steps_graph[idx].data.length; i++) {
      if (!req.steps_graph[idx].data[i].school_id) {
        req.steps_graph[idx].data.splice(i, 1);
        i = i - 1;
      }
    }
    let newStudentData = {
      name: 'ADD_STUDENTS',
      school_id: data.other_info.school_id,
      min_items_required: 1,
      available_items_count: 0,
      is_completed: false,
    };
    let newRouteData = {
      name: 'ADD_ROUTES',
      school_id: data.other_info.school_id,
      min_items_required: 1,
      available_items_count: 0,
      is_completed: false,
    };
    req.steps_graph[idx].data.push(newStudentData, newRouteData);
    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataAfterDeleteingFromConfigureSchoolStep = (schoolId, req) => {
    let idx = getIndex(req);
    for (let i = 0; i < req.steps_graph[idx].data.length; i++) {
      if (req.steps_graph[idx].data[i].school_id === schoolId) {
        req.steps_graph[idx].data.splice(i, 1);
        i = i - 1;
      }
    }
    return req;
  };

  const handleGUidedWorkFlowOnDeleteSchool = async (schoolId, data) => {
    let newReqBody = getDataAfterDeleteingFromConfigureSchoolStep(schoolId, data.guided_info);
    try {
      const result = await updateGuidedWorkFlow(newReqBody);
      props.handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGuidedWorkFlowOnProceed = () => {
    props.setActiveStep(1);
  };

  const handleDeleteSchool = async (schoolId) => {
    setDeleteButtonLoader(true);

    try {
      const result = await deleteSchool(schoolId, 'True');
      enqueueSnackbar('School Successfully deleted', {
        variant: 'success',
      });
      handleGUidedWorkFlowOnDeleteSchool(schoolId, result);
      props.getAllSchools();
      setOpenDeleteAlert(false);
      setDeleteButtonLoader(false);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDeleteButtonLoader(false);
    }
  };

  const handleGetAddress = async (searchTerm) => {
    try {
      let response = await searchAddress(searchTerm);
      setAddress(response.data);
      console.log('Search API', response);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleEdit = (school) => {
    setIsEdit(true);
    setEditData(school);
    setProceed(false);

    setImageData(undefined);
    setPreviewImage(school.image?.url);
    setImageValidation(false);
    setSelectedSchoolData(school);
  };

  const readableTime = (seconds) => {
    var sec = Number(seconds);
    var h = Math.floor(sec / 3600);
    var m = Math.floor((sec % 3600) / 60);
    var s = Math.floor((sec % 3600) % 60);

    var hh = h < 1 ? 12 : h == 1 || h < 10 ? '0' + h : h;
    var mm = m >= 0 && m < 10 ? '0' + m : m;
    var ss = s >= 0 && s < 10 ? '0' + s : s;

    return hh + ':' + mm;
  };

  const getEpochTime = (time) => {
    let t = time.split(':');
    let epochTime = t[0] * 3600 + t[1] * 60 + t[1] * 1;
    return epochTime;
  };

  const handleAddAddress = (
    id,
    entity,
    aptPlot,
    street,
    city,
    state,
    zipCode,
    country,
    location,
  ) => {
    if (editLocationData) {
      setMultipleAddress((prevData) =>
        prevData.map((item) =>
          item.id === id
            ? {
                id: id,
                address: {
                  entity: entity,
                  apt_plot: aptPlot,
                  street: street,
                  city: city,
                  state: state,
                  zip_code: zipCode,
                  country: country,
                },
                geo_location: {
                  latitude: location[0],
                  longitude: location[1],
                },
              }
            : item,
        ),
      );
    } else {
      const newAddress = {
        id: id,
        address: {
          entity: entity,
          apt_plot: aptPlot,
          street: street,
          city: city,
          state: state,
          zip_code: zipCode,
          country: country,
        },
        geo_location: {
          latitude: location[0].toString(),
          longitude: location[1].toString(),
        },
      };
      if (addAddress && !schoolAddress) {
        setDefaultPosition(location);
        setschoolAddress(newAddress);
        setMultipleAddress((item) => [...item, newAddress]);
      } else if (addAddress && edit) {
        setDefaultPosition(location);
        setschoolAddress(newAddress);
      } else {
        setMultipleAddress((item) => [...item, newAddress]);
      }
    }
  };

  const handleDeleteAddress = (id) => {
    setMultipleAddress((prevData) => prevData.filter((item) => item.id !== id));
    setEditLocationData();
    if (primaryPick === id) {
      setPrimaryPick(multipleAddress[0].id);
    }
    if (primaryDrop === id) {
      setPrimaryDrop(multipleAddress[0].id);
    }
  };

  useEffect(() => {
    return closeSnackbar();
  }, []);

  const formInitialValues = () => {
    return {
      school_name: isEdit ? editData.school_name : '',
      abbreviation: isEdit ? editData.abbreviation : '',
      school_type: isEdit ? editData.school_type : '',
      level: isEdit ? editData.level : '',
      time_zone: isEdit ? editData.time_zone : '',
      entity: isEdit ? editData.address.entity : '',
      apt_plot: isEdit ? editData.address.apt_plot : '',
      street: isEdit ? editData.address.street : '',
      city: isEdit ? editData.address.city : '',
      state: isEdit ? editData.address.state : '',
      zip_code: isEdit ? editData.address.zip_code : '',
      country: isEdit ? editData.address.country : '',
      start_timing: isEdit
        ? [
            getEpochTime(editData.start_timing[0], 'AM'),
            getEpochTime(editData.start_timing[1], 'AM'),
          ]
        : [21600, 28800],
      end_timing: isEdit
        ? [getEpochTime(editData.end_timing[0], 'PM'), getEpochTime(editData.end_timing[1], 'PM')]
        : [21600, 28800],
      adj_vehicle_capacity: isEdit ? editData.adj_vehicle_capacity : '',
      acknowledgementForStateCompliance: isEdit
        ? editData.policies.acknowledgementForStateCompliance
        : false,
    };
  };

  const validationSchema = yup.object({
    school_name: yup
      .string()
      .required('School name is required')
      .trim()
      .min(3, 'School name must be at least 3 characters'),
    abbreviation: yup
      .string()
      .required('Abbreviation is required')
      .trim()
      .max(3, 'Abbreviation max 3 characters'),
    school_type: yup
      .string()
      .required('School Type is required')
      .trim()
      .min(3, 'School Type must be selected'),

    apt_plot: yup
      .string()
      .required('Apt plot is required')
      .trim()
      .min(1, 'Apt plot must required'),
    street: yup
      .string()
      .required('Street is required')
      .trim()
      .min(3, 'Street must required'),
    city: yup
      .string()
      .required('City is required')
      .trim()
      .min(3, 'City must required'),
    state: yup
      .string()
      .required('State is required')
      .trim()
      .min(2, 'State must required'),
    zip_code: yup
      .string()
      .required('Zip code is required')
      .trim()
      .min(3, 'Zip code must required'),
    country: yup
      .string()
      .required('Country is required')
      .trim()
      .min(3, 'Country must required'),
    level: yup
      .string()
      .required('School level is required')
      .trim()
      .min(3, 'School level must be required'),
    time_zone: yup
      .string()
      .required('Time zone is required')
      .trim()
      .min(2, 'Time zone must required'),
    start_timing: yup.number().required('Start time is required'),
    end_timing: yup.number().required('Start end is required'),
    acknowledgementForStateCompliance: yup
      .bool()
      .test('Term and conditions must required', (value) => {
        if (value === true) {
          return true;
        } else {
          return false;
        }
      }),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (multipleAddress.length !== 0 && proceed && previewImage) {
      if (isEdit === true) {
        updateSchoolWithImage(selectedSchoolData, values);
      } else {
        registerSchoolWithImage(values);
        resetForm();
      }
    }
  };

  const handleOpenDelete = () => {
    setOpenDeleteSessionAlert(true);
  };

  const handleDelete = () => {
    setOpenDeleteSessionAlert(false);
    handleDeleteAddress(editLocationData.id);
  };

  const handleAddress = () => {
    setAddAddress(true);
    setEditLocationData();
    handleClickOpen();
    if (schoolAddress) {
      setEdit(true);
    }
  };

  const editDataSchool = () => {
    if (selectedSchoolData != undefined) {
      setPrimaryPick('IQR151');
      setPrimaryDrop('IQR151');
      const newAddress = {
        id: 'IQR151',
        address: {
          entity: selectedSchoolData.address.entity,
          apt_plot: selectedSchoolData.address.apt_plot,
          street: selectedSchoolData.address.street,
          city: selectedSchoolData.address.city,
          state: selectedSchoolData.address.state,
          zip_code: selectedSchoolData.address.zip_code,
          country: selectedSchoolData.address.country,
        },
        geo_location: {
          latitude: selectedSchoolData.geo_location.latitude,
          longitude: selectedSchoolData.geo_location.longitude,
        },
      };
      setMultipleAddress((item) => [newAddress, ...item]);
      setschoolAddress(newAddress);
    }
    if (selectedSchoolData !== undefined) {
      setMultipleAddress(selectedSchoolData.locations);
      setLocationMapping(selectedSchoolData.locations_mapping);
    }
  };

  useEffect(() => {
    editDataSchool();
  }, [selectedSchoolData]);

  useEffect(() => {
    if (multipleAddress.length === 1) {
      setLocationMapping({
        primary_pickup: multipleAddress[0].id,
        primary_drop: multipleAddress[0].id,
        secondary_pickup: locationMapping?.secondary_pickup,
        secondary_drop: locationMapping?.secondary_drop,
        special_pickup: locationMapping?.special_pickup,
        special_drop: locationMapping?.special_drop,
      });
    }
  }, [multipleAddress]);

  return (
    <Grid sx={{ mx: '2vw' }}>
      <MainCard>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <SchoolsForm
              formInitialValues={formInitialValues}
              validationSchema={validationSchema}
              handleSubmit={handleSubmit}
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              imageData={imageData}
              setImageData={setImageData}
              imageValidation={imageValidation}
              setImageValidation={setImageValidation}
              handleDragImage={handleDragImage}
              proceed={proceed}
              setProceed={setProceed}
              handleClickOpen={handleClickOpen}
              handleOpenDelete={handleOpenDelete}
              setEdit={setEdit}
              multipleAddress={multipleAddress}
              setEditLocationData={setEditLocationData}
              setLocationMapping={setLocationMapping}
              locationMapping={locationMapping}
              setPrimaryDrop={setPrimaryDrop}
              primaryDrop={primaryDrop}
              setPrimaryPick={setPrimaryPick}
              primaryPick={primaryPick}
              buttonLoader={buttonLoader}
              handleAddress={handleAddress}
              schoolAddress={schoolAddress}
            />
            <BackForward
              disableButton={props.guidedWorkflowData?.steps_graph[0].data[0].is_completed}
              hideBack={true}
              {...props}
            />
          </Grid>
          <Grid item xs={4}>
            <SchoolList
              guidedSchools={props.guidedSchools}
              handleEdit={handleEdit}
              handleDeleteSchool={handleDeleteSchool}
              deleteButtonLoader={deleteButtonLoader}
              openDeleteAlert={openDeleteAlert}
              setOpenDeleteAlert={setOpenDeleteAlert}
              loadingData={props.loadingData}
            />
          </Grid>
        </Grid>
      </MainCard>
      <AddLocation
        editLocationData={addAddress ? schoolAddress : editLocationData}
        open={open}
        handleClose={handleClose}
        edit={edit}
        handleGetAddress={handleGetAddress}
        address={address}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={addAddress ? schoolAddress : selectedAddress}
        handleAddAddress={handleAddAddress}
        proceed={proceed}
        handleSubmit={handleSubmit}
        setEditLocationData={setEditLocationData}
        formName={addAddress ? 'Address' : 'Location'}
        defaultPosition={defaultPosition}
        addAddress={addAddress}
      />
      <DeleteAlert
        section={'School Location'}
        open={openDeleteSessionAlert}
        setOpen={() => setOpenDeleteSessionAlert(true)}
        setClose={() => setOpenDeleteSessionAlert(false)}
        handleDelete={handleDelete}
      />
    </Grid>
  );
}
