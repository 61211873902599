import L from 'leaflet';
import { TripStatus, TripStop } from '../models/trip/TripStatus';
import TimeUtils from './TimeUtils';
import { StopMarker } from 'containers/layouts/LiveView/Components/Maps/MarkerSvg';
import { DestinationMarker } from 'containers/layouts/LiveView/Components/Maps/DestinationSvg';
import { SourceMarker } from 'containers/layouts/LiveView/Components/Maps/SourceSvg';

const LeafIcon = L.Icon.extend({
  options: {
    iconSize: [28, 28],
    iconAnchor: [17, 16], //[left/right, top/bottom]
    popupAnchor: [0, -26], //[left/right, top/bottom]
  },
});

var LeafIcon1 = L.Icon.extend({
  options: {
    iconSize: [55, 55],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
  },
});

const STATUS = {
  COVERED: 'Covered',
  BREAKDOWN: 'Breakdown',
};

/**
 * Provides utility methods related to trip.
 */
export default class TripUtils {
  static DEFAULT_COLOR = 'secondary';

  /**
   * Retrieves the color associated with a trip status.
   *
   * @param {string} status - The trip status.
   * @returns {string} The color associated with the trip status.
   */
  static getTripStatusColor(status) {
    switch (status) {
      case TripStatus.COMPLETED:
        return 'success';

      case TripStatus.ON_ROAD:
        return 'primary';

      case TripStatus.BREAKDOWN:
        return 'error';

      case TripStatus.SCHEDULED:
        return 'secondary';
      default:
        return this.DEFAULT_COLOR;
    }
  }

  /**
   * Retrieves the color associated with a trip status.
   *
   * @param {string} status - The trip status.
   * @param {object} theme - Theme object instance of MUI
   * @returns {string} The color associated with the trip status.
   */
  static getTripStatusColorWithTheme(status, theme) {
    switch (status) {
      case TripStatus.COMPLETED:
        return theme.palette.success.main;

      case TripStatus.ON_ROAD:
        return theme.palette.primary.main;

      case TripStatus.BREAKDOWN:
        return theme.palette.error.main;

      case TripStatus.InProgress:
        return theme.palette.primary.main;

      default:
        return this.DEFAULT_COLOR;
    }
  }

  /**
   * Retrieves the Leaflet icon URL based on the trip status.
   *
   * @param {Object} trip - The trip object containing status information.
   * @returns {L.Icon} The Leaflet icon URL based on the trip status.
   */
  static getTripStatusMapIconUrl(trip) {
    let iconUrl;

    switch (trip.status) {
      case TripStatus.BREAKDOWN:
        iconUrl = '/static/images/icons/sb-bus-bk-icon.png';
        break;
      case TripStatus.COMPLETED:
        iconUrl = '/static/images/icons/sb-bus-completed-icon.png';
        break;
      default:
        iconUrl = '/static/images/icons/sb-bus-onroad-icon.png';
        break;
    }

    return new LeafIcon({
      iconUrl: iconUrl,
    });
  }

  static getTripStatusMapIcon(status) {
    let iconUrl;

    switch (status) {
      case TripStatus.BREAKDOWN:
        iconUrl = '/static/images/icons/sb-bus-bk-icon.png';
        break;
      case TripStatus.COMPLETED:
        iconUrl = '/static/images/icons/sb-bus-completed-icon.png';
        break;
      default:
        iconUrl = '/static/images/icons/sb-bus-onroad-icon.png';
        break;
    }

    return new LeafIcon({
      iconUrl: iconUrl,
    });
  }

  /**
   * Retrieves the text associated with a trip status.
   *
   * @param {string} status - The trip status.
   * @returns {string} The text associated with the trip status.
   */
  static getTripStatusText(status) {
    switch (status) {
      case TripStatus.COMPLETED:
        return 'Completed';

      case TripStatus.ON_ROAD:
        return 'On Road';

      case TripStatus.BREAKDOWN:
        return 'Breakdown';

      case TripStatus.SCHEDULED:
        return 'Scheduled';

      case TripStatus.InProgress:
        return 'In progress';
      default:
        return 'Pending';
    }
  }

  static getTripStartTime(trip) {
    if (trip.status === TripStatus.SCHEDULED) {
      return TimeUtils.formatTimeFromString(trip.route_info.scheduled_start_time);
    } else {
      return TimeUtils.formatSecondsToTime(trip.trip_start_time?.timestamp);
    }
  }

  /**
   * Retrieves the text associated with a trip stop images.
   *
   * @param {string} route_stop_id - The trip stop Indentifier.
   * @returns {string} Associated stop image.
   */
  static getStopImageUrl(route_stop_id, color) {
    switch (route_stop_id) {
      case TripStop.DESTINATION:
        return DestinationMarker(color);
      case TripStop.SOURCE:
        return SourceMarker(color);
      default:
        return StopMarker(color);
    }
  }

  static getStopID(stopId, index) {
    switch (stopId) {
      case TripStop.DESTINATION:
        return 'Destination';
      case TripStop.SOURCE:
        return 'Source';
      default:
        return `Stop ${Number(index)}`;
    }
  }

  static getSchoolImage() {
    return new LeafIcon1({
      iconUrl: '/static/images/icons/outline_school.png',
    });
  }
}

export const ROUTE_TYPE = {
  AM: 'AM',
  PM: 'PM',
};

//Trip Type
export const TripType = [
  { name: 'AM', value: 'AM' },
  { name: 'PM', value: 'PM' },
];

export const coveredStops = (stops = []) => {
  if (!stops || stops?.length === 0) return 0;
  return stops.filter((stop) => stop?.status === STATUS.COVERED)?.length || 0;
};

// Get Label as per Status
export const getTripStatusLabel = (tripData, status) => {
  const tripStatus = tripData ? tripData?.tripStatus : status;

  if (!tripStatus) {
    return 'Invalid Status';
  }

  const scheduleTime = tripData?.travelMetrics?.scheduledArrivalTime;
  const estimatedTime = tripData?.travelMetrics?.estimatedArrivalTime;

  switch (tripStatus) {
    case TripStatus.COMPLETED:
      return 'Completed';
    case TripStatus.BREAKDOWN:
      return 'Breakdown';
    case TripStatus.ON_ROAD:
      return 'On Road';
    case TripStatus.SCHEDULED:
      return 'Scheduled';
    case TripStatus.IN_PROGRESS:
      return 'In Progress';
    case TripStatus.PAUSED:
      return 'Paused';
    case TripStatus.ON_TIME:
      if (!estimatedTime) {
        return 'On Time';
      }
      return `${TimeUtils.convertTo12HourFormat(estimatedTime)}`;
    case TripStatus.RUNNING_LATE:
      if (!estimatedTime) {
        return 'Running Late';
      }
      return `${TimeUtils.convertTo12HourFormat(estimatedTime)}`;
    case TripStatus.ARRIVING_EARLY:
      if (!estimatedTime) {
        return 'Arriving Early';
      }
      return `${TimeUtils.convertTo12HourFormat(estimatedTime)}`;
    case TripStatus.COVERED:
      return 'Covered';

    default:
      return 'Invalid Status';
  }
};
