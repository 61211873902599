import { BREADCRUMBS_DATA_RESET, BREADCRUMBS_DATA_SUCCESS } from "reduxConstants/breadcrumbsConstants";
import store from "reduxStore";

const saveBreadcumbs = (data) => async (dispatch) => {
    dispatch({
        type: BREADCRUMBS_DATA_SUCCESS,
        payload: data,
    });
    localStorage.setItem("breadcrumbsData", JSON.stringify(data))
}

// It's an extra function made so that we don't have to call useDispatch on every page where we wants to use redux for breadcrumbs
export const saveBreadcumbsData = (data) => {
    store.dispatch(saveBreadcumbs(data))
}


const resetBreadcumbs = () => async (dispatch) => {
    dispatch({
        type: BREADCRUMBS_DATA_RESET
    });
}

// It's an extra function made so that we don't have to call useDispatch on every page where we wants to use redux for breadcrumbs
export const resetBreadcumbsData = (location, breadcrumbsData, index) => {

// It is checking, where we have clicked, on Id field or on Link field 
    // eslint-disable-next-line default-case
    switch (true) {
        case breadcrumbsData && breadcrumbsData.pathname.includes(location.pathname):
            if (index) {

                let content = []
                for (let i = 0; i <= index; i++)
                    content.push(breadcrumbsData.content[i])

                breadcrumbsData.content = content
                saveBreadcumbsData(breadcrumbsData)
            }
            break;

        case breadcrumbsData && !breadcrumbsData.pathname.includes(location.pathname):
            store.dispatch(resetBreadcumbs());
            break;
    }

}