import React, { useState, useEffect } from 'react';

// material-ui imports
import { useTheme } from '@mui/material/styles';
import { styled, alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';

// components
import MainCard from 'components/MainCard';
import NoRecordDisplay from 'components/NoRecordDisplay';

export default function CustomTable(props) {
  const theme = useTheme();

  const {
    routes,
    page,
    filledRows,
    searched,
    dataLoading,
    setFilledRows,
    setSearched,
    tableHeadingData,
    tableCellData,
    searchVariable,
    selectedRows,
    icon,
    tableTitle,
    filterMenu,
    setPage,
    settingData,
    setRunOnce,
    runOnce,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const emptyArray = new Array(10).fill(0);

  useEffect(() => {
    setFilledRows(routes);
  }, [routes]);

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value);
    const filteredRows = routes.filter((row) => {
      var sourceString = searchVariable(row);
      if (sourceString.includes(searchedVal.target.value.toLowerCase())) {
        return sourceString.includes(searchedVal.target.value.toLowerCase());
      }
    });
    setFilledRows([...filteredRows]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    // setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, filledRows.length - props.page * rowsPerPage);

  // const getSchool = (schoolId) => {
  //   const filteredArray = props.schools.filter((obj) => obj.school_id === schoolId);
  //   return filteredArray[0].school_name;
  // };

  useEffect(() => {
    if (!runOnce) {
      if (
        settingData?.settings.route_settings.show_vehicle_details === false &&
        tableHeadingData[4].title === 'Vehicles'
      ) {
        tableHeadingData.splice(4, 1);
      }
      if (
        settingData?.settings.route_settings.show_driver_details === false &&
        tableHeadingData[3].title === 'Drivers'
      ) {
        tableHeadingData.splice(3, 1);
      }
      setRunOnce(true);
    }
  }, []);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: theme.shape.borderRadius * 4,
      marginTop: theme.spacing(1),
      minWidth: 200,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
      <Grid container justifyContent='flex-end' alignItems='center'>
        {/* <Grid sx={{ pl: 2 }} container xs={8} alignItems='center'>
          {icon}
          <Typography variant='h4'>{tableTitle}</Typography>
        </Grid> */}
        <Grid sx={{ pr: 2 }} justifyContent='flex-end' container xs={4} alignItems='flex-end'>
          <TextField
            label='Search'
            value={searched}
            variant='standard'
            onChange={requestSearch}
            id='search'
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton size='large'>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            size='small'
            onClick={handleOpenUserMenu}
            startIcon={<FilterListIcon />}
            variant='outlined'
            color='secondary'
            sx={{ mr: 2, ml: 3 }}
          >
            Filters
          </Button>
          <StyledMenu
            id='menu-appbar'
            anchorEl={anchorElUser}
            keepMounted
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {filterMenu}
          </StyledMenu>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {tableHeadingData.map((ele, index) => (
                <TableCell align='center' key={index}>
                  <Grid container justifyContent={'center'}>
                    {ele.icon}
                    {ele.title}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoading &&
              emptyArray.map((el, index) => (
                <TableRow key={index}>
                  <TableCell align='center' colSpan={7}>
                    <Skeleton animation='wave' variant='text' />
                  </TableCell>
                </TableRow>
              ))}
            {!dataLoading && filledRows.length > 0 ? (
              (rowsPerPage > 0
                ? filledRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filledRows
              ).map((row, index) => (
                <TableRow
                  hover
                  key={row.route_id}
                  onClick={() => {
                    selectedRows(row);
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      props.selectedRouteRowId === row.route_id
                        ? theme.palette.background.default
                        : '',
                  }}
                >
                  {tableCellData(row, index)}
                </TableRow>
              ))
            ) : !dataLoading && filledRows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align='center'
                  id='no_records'
                  sx={{ borderColor: 'transparent' }}
                >
                  <NoRecordDisplay />
                </TableCell>
              </TableRow>
            ) : null}
            {!dataLoading && emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component='div'
        rowsPerPage={rowsPerPage}
        page={page}
        count={props.hasMore - (page + 1) * 20 > 0 ? filledRows.length + 1 : filledRows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from }) => `${from}-${Math.min((page + 1) * 20, filledRows.length)}`}
      />
    </React.Fragment>
  );
}
