import React, { useState, useEffect } from 'react';
// mui
import { Grid } from '@mui/material';

// Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// third-party imports
import { useSnackbar } from 'notistack';

// query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../Utils/ErrorHandling';

// helper & components
import { queryKey } from 'react-query/queryKey';
import DeviceDetails from './Components/devices/DeviceDetails';
import AddEditDevices from './Components/devices/AddEditDevices';
import DeleteAlert from 'components/alert/DeleteAlert';
import { DefaultButton } from 'components/Buttons';
import DeviceQrCode from './Components/devices/DeviceQrCode';

// api
import { deleteDevice, getAllDevices } from 'clients/vehicle';
import { tableHeadingData, tablerowDataFunction } from './Components/Tables/tableHelper';
import DeviceTable from './Components/Tables/DeviceTable';

// Custom API Hook
import { useVehicles } from 'hooks/api/useVehicles';

const initialValuesOfQrCode = {
  device: null,
  vehicle: null,
};

const Devices = (props) => {
  // state
  const [filledRows, setFilledRows] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState('');

  const [selectedDevicesData, setSelectedDevicesData] = useState(null);

  const [devicesSerial, setDevicesSerial] = useState(0);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  // const [selectedDeviceRowId, setSelectedDeviceRowId] = useState(null);

  // for handling Pagination
  const [page, setPage] = useState(0);

  //state for disabling post put and delete requests by user
  const [onlyGetRequestAllowed, setOnlyGetRequestAllowed] = useState(false);

  // Add vehicle form state variables
  const [openAddFormDialog, setOpenAddFormDialog] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // QR Code States
  const [openQrCodeDialogue, setQrCodeDialogue] = useState(false);
  const [qrCodeInfo, setQrCodeInfo] = useState(initialValuesOfQrCode);

  const queryClient = useQueryClient();

  // Fetching Vehicle Data
  const { getVehicleData, isLoadingVehicles } = useVehicles();

  // This array we are sending to search function as prop
  const tableRowData = [];

  const {
    data: getDeviceData,
    isLoading,
    // isRefetching: isRefetchingStudents,
    // refetch: refetchStudents,
  } = useQuery({
    queryKey: [queryKey.DEVICE.ALL_DEVICES],
    queryFn: () => handleGetDevicesList(),
    onSuccess: (newData, _, context) => {},
    /**
     * Handles the error that occurred during the execution of the function.
     *
     * @param {Error} error - The error that occurred.
     * @return {void}
     */
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
  });

  const deleteDeviceMutation = useMutation({
    mutationFn: (body) => {
      return deleteDevice(body.id);
    },
    onSuccess: () => {
      enqueueSnackbar('Device deleted successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries([queryKey.DEVICE.ALL_DEVICES]);
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
  });

  // open delete alert
  const handleDeleteDevice = async () => {
    await deleteDeviceMutation.mutateAsync(selectedDevicesData);
    setOpenDeleteAlert(false);
  };

  const handleGetDevicesList = async () => {
    try {
      const data = await getAllDevices();
      setSelectedDevicesData(data[0] || null);
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const handleRowClick = (e, data) => {
    setSelectedDevicesData(data);
  };
  const handleDeleteClick = () => {
    setOpenDeleteAlert(true);
  };
  const handleEditClick = () => {
    setOpenAddFormDialog(true);
    setIsEditing(true);
  };

  const handleAddDevice = () => {
    setOpenAddFormDialog(true);
    setIsEditing(false);
  };

  /**
   * Filters the fields of an object and returns a new object containing only the specified fields.
   * @param {Object} obj - The input object to filter.
   * @param {Array} fieldsToKeep - An array of field names to keep in the filtered object.
   * @returns {Object} - A new object containing only the specified fields from the input object.
   */
  function filterFields(obj, fieldsToKeep) {
    const newObj = {};
    fieldsToKeep.forEach((field) => {
      if (obj.hasOwnProperty(field)) {
        newObj[field] = obj[field];
      }
    });
    return newObj;
  }

  /**
   * Combines device and vehicle information based on selected devices data and vehicle data.
   * @param {Array} getVehicleData - An array of vehicle data objects.
   * @param {Object} selectedDevicesData - Selected device data object.
   * @returns {Object} - An object containing device and vehicle information.
   */

  const combineDeviceAndVehicleInfo = (getVehicleData, selectedDevicesData) => {
    const selectedVehicleInfo = getVehicleData?.find((ele) => {
      if (ele?.devices && ele?.devices?.length > 0) {
        const driverApp = ele?.devices?.find((ele) => ele.capability === 'driverapp');
        const deviceID = Boolean(driverApp) ? driverApp?.device_id : null;
        return deviceID === selectedDevicesData.device_id;
      }
      return null;
    });
    const fieldsToKeepForDevice = ['organization_admin_username', 'status', 'id', 'device_id'];
    const fieldsToKeepForVehicle = [
      'licence_plate_number',
      'vehicle_id',
      'organization_admin_username',
      'status',
    ];
    return {
      device: Boolean(selectedDevicesData)
        ? filterFields(selectedDevicesData, fieldsToKeepForDevice)
        : null,
      vehicle: Boolean(selectedVehicleInfo)
        ? filterFields(selectedVehicleInfo, fieldsToKeepForVehicle)
        : null,
    };
  };

  const handleCloseQrCodeDialog = () => {
    setQrCodeDialogue(false);
  };

  const handleOpenQrCodeDialog = (selectedData) => {
    const qrInfo = combineDeviceAndVehicleInfo(getVehicleData, selectedData);
    setQrCodeInfo(qrInfo);
    setQrCodeDialogue(true);
  };

  useEffect(() => {
    // Update the selected device data when the data in the queryClient changes
    if (getDeviceData && getDeviceData.length > 0) {
      setSelectedDevicesData(getDeviceData[0]);
      setFilledRows(getDeviceData);
    } else {
      setSelectedDevicesData(null);
      setFilledRows([]);
    }
  }, [getDeviceData]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid container direction='row' justifyContent='flex-end' spacing={3} mt={1}>
          <Grid item>
            <DefaultButton
              id='device-add-button'
              variant='contained'
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddDevice}
              //   disabled={onlyGetRequestAllowed}
            >
              Add Device
            </DefaultButton>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <DeviceTable
            mainTableData={getDeviceData ? getDeviceData : []}
            filledRows={filledRows}
            setFilledRows={setFilledRows}
            searchedTerm={searchedTerm}
            setSearchedTerm={setSearchedTerm}
            isDataLoading={isLoading}
            selectedSectionData={selectedDevicesData}
            handleRowClick={handleRowClick}
            isFromSearch={false}
            tableHeadingData={tableHeadingData}
            idForBackgroundSelection={'id'}
            tablerowDataFunction={tablerowDataFunction}
            page={page}
            setPage={setPage}
            handleOpenQrCodeDialog={handleOpenQrCodeDialog}
          />
        </Grid>
        <Grid item xs={4}>
          <DeviceDetails
            selectedDevicesData={selectedDevicesData}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            onlyGetRequestAllowed={onlyGetRequestAllowed}
            isDataLoading={isLoading}
            filledRows={filledRows}
            devicesSerial={devicesSerial}
          />
        </Grid>
      </Grid>

      <DeviceQrCode
        openQrCodeDialogue={openQrCodeDialogue}
        handleCloseQrCodeDialog={handleCloseQrCodeDialog}
        qrCodeInfo={qrCodeInfo}
      />

      <AddEditDevices
        selectedDevicesData={selectedDevicesData}
        openDialog={openAddFormDialog}
        closeDialog={() => setOpenAddFormDialog(false)}
        setIsDataLoading={isLoading}
        isEditing={isEditing}
        {...props}
      />
      <DeleteAlert
        deleteItemName={'Device'}
        open={openDeleteAlert}
        setOpen={() => setOpenDeleteAlert(true)}
        setClose={() => setOpenDeleteAlert(false)}
        handleDelete={handleDeleteDevice}
        buttonLoader={deleteDeviceMutation.isLoading}
      />

      {/* other popups */}
    </React.Fragment>
  );
};

export default Devices;
