import React, { useState, useEffect } from 'react';

// MUI Imports
import { Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

// Components
import IconWithText from 'components/IconWithText';
import SettingsIcon from '@mui/icons-material/Settings';

import { helperTexts } from './helper';
import {
  getListPublicRouteDashboard,
  getTogglePublicDashboardStatus,
  updatePublicRouteDashboardConfig,
} from 'clients/SpotBus';
import { SchoolLevelDashboard } from './components/SchoolLevelDashboard';
import { CopyLinkAndViewLink } from './components/CopyLinkAndView';
import { SwitchWithText } from 'components/SwitchWithText';
import { SettingConfigPopup } from './components/SettingConfigPopup';
import { PublicRouteCard } from './components/PublicRouteCard';
import Loader from 'components/Loader';
import SkeletonLoadingPubDash from './components/SkeletonLoadingPubDash';
/**
 * Renders a dashboard for a selected school's route information. Allows the user to toggle
 * the display of the dashboard and copy the URL for embedding the dashboard in an iframe.
 *
 * @param {object} props - The props object containing schoolsData and username.
 * @return {JSX.Element} The JSX component for the PublicRouteDashboard.
 */

const PublicRouteDashboard = (props) => {
  const { schoolsData: mainSchoolsData } = props;

  const [openDistrictDialog, setOpenDistrictDialog] = useState(false);
  // loading status
  const [loading, setLoading] = useState(true);

  const [schoolsData, setSchoolsData] = useState([]);

  const [switchDistrict, setSwitchDistrict] = useState(false);

  const [districtData, setDistrictData] = useState({});

  // popupDialogState
  const [routeDetail, setRouteDetail] = useState(false);
  const [routeDriver, setRouteDriver] = useState(false);
  const [routeVehicle, setRouteVehicle] = useState(false);

  const [tripDetail, setTripDetail] = useState(false);
  const [tripDriver, setTripDriver] = useState(false);
  const [tripVehicle, setTripVehicle] = useState(false);

  const handleClickOpenDistrictDialog = () => {
    setOpenDistrictDialog(true);
  };

  /**
   * Retrieves the public list asynchronously.
   *
   * @return {Promise<void>} - A promise that resolves when the list is retrieved.
   */
  const getPublicList = async () => {
    setLoading(true);
    try {
      const response = await getListPublicRouteDashboard();
      const district = response.find((item) => item.id === 'district');
      if (Boolean(district)) {
        setDistrictData(district);
        setSwitchDistrict(district.status === 'active' ? true : false);
      }
      const routeDetails = district?.settings?.route_settings;
      const routeTripsDetails = district?.settings?.route_trips_settings;
      // setting all state for toggle
      setRouteDetail(routeDetails?.enabled);
      setRouteDriver(routeDetails?.show_driver_details);
      setRouteVehicle(routeDetails?.show_vehicle_details);

      // trips
      setTripDetail(routeTripsDetails?.enabled);
      setTripDriver(routeTripsDetails?.show_driver_details);
      setTripVehicle(routeTripsDetails?.show_vehicle_details);

      const activeSchoolLinks = mainSchoolsData.map((school) => {
        const isPresentInList = response.find((item) => item.id === school.school_id);

        if (isPresentInList) {
          return { ...school, ...isPresentInList };
        }
        return school;
      });
      setSchoolsData(activeSchoolLinks);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleToggleDistrict = async (data) => {
    const { id } = data;
    // calling toggle api
    setSwitchDistrict((pre) => !pre);
    try {
      getTogglePublicDashboardStatus(id);
    } catch (error) {
      setSwitchDistrict((pre) => !pre);
      console.log(error);
    }
  };

  const requestBodyMain = {
    id: districtData.id,
    token: districtData.token,
    status: districtData.status,
    expires_on: districtData.expires_on,
    allowed_domains: [],
    created_by: districtData.created_by,
    creation_timestamp: districtData.creation_timestamp,
    updated_by: districtData.updated_by,
    updation_timestamp: districtData.updation_timestamp,
    organization_admin_username: districtData.organization_admin_username,
    settings: { ...districtData.settings },
  };
  const handleRouteDetail = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          enabled: !routeDetail,
        },
      },
    };
    setRouteDetail((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setRouteDetail((pre) => !pre);
    }
  };

  const handleRouteDriver = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          show_driver_details: !routeDriver,
        },
      },
    };
    setRouteDriver((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setRouteDriver((pre) => !pre);
    }
  };
  const handleRouteVehicle = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          show_vehicle_details: !routeVehicle,
        },
      },
    };
    setRouteVehicle((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setRouteVehicle((pre) => !pre);
    }
  };

  const handleTripDetail = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          enabled: !tripDetail,
        },
      },
    };
    setTripDetail((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setTripDetail((pre) => !pre);
    }
  };
  const handleTripDriver = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          show_driver_details: !tripDriver,
        },
      },
    };
    setTripDriver((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setTripDriver((pre) => !pre);
    }
  };
  const handleTripVehicle = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          show_vehicle_details: !tripVehicle,
        },
      },
    };
    setTripVehicle((pre) => !pre);
    setDistrictData((pre) => {
      return { ...pre, ...requestBody };
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, districtData.id);
    } catch (error) {
      console.log(error);
      setTripVehicle((pre) => !pre);
    }
  };

  const logo = '';

  useEffect(() => {
    getPublicList();
  }, [mainSchoolsData]);

  if (loading) {
    return <SkeletonLoadingPubDash />;
  }
  // getting data from local storage
  const currentSessionId = localStorage.getItem('currentSessionId');

  const mainUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PROD_URL;
  const url = `${mainUrl}publicDashboard/${districtData?.organization_admin_username}/${currentSessionId}/${districtData?.id}?token=${districtData?.token}`;

  return (
    <MainCard>
      <Stack>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={1}
          color={'text.secondary'}
        >
          {helperTexts.publicRouteMainHelperText}
        </Typography>
      </Stack>

      {/* __ District Level Public Route__ */}
      <Stack mt={10}>
        <Typography variant='h4' mb={1}>
          Generate District Level Public Route Dashboard
        </Typography>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={1}
          color={'text.secondary'}
        >
          {helperTexts.GenerateDistrictLevelPublicRouteDashboardHelperText}
        </Typography>
        <PublicRouteCard color={switchDistrict ? 'primary.main' : 'secondary.main'}>
          <Stack>
            <SwitchWithText
              label='District Level Public Route Dashboard'
              checked={switchDistrict}
              onChange={() => handleToggleDistrict(districtData)}
            />
            <IconWithText title='Embed URL' titleProps={{ variant: 'subtitle2' }} icon={LinkIcon} />
          </Stack>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={5}>
            <CopyLinkAndViewLink
              disabled={!switchDistrict}
              url={url}
              id='public-route-dashboard-district-url'
            />
            <IconButton
              size='small'
              onClick={handleClickOpenDistrictDialog}
              disabled={!switchDistrict}
            >
              <SettingsIcon fontSize='small' />
            </IconButton>
          </Stack>
        </PublicRouteCard>

        <SettingConfigPopup
          open={openDistrictDialog}
          handleClose={() => setOpenDistrictDialog(false)}
          schoolName='District Level Public Route Dashboard'
          logo={logo}
          routeDetail={routeDetail}
          routeDriver={routeDriver}
          routeVehicle={routeVehicle}
          handleRouteDetail={handleRouteDetail}
          handleRouteDriver={handleRouteDriver}
          handleRouteVehicle={handleRouteVehicle}
          // trip
          tripDetail={tripDetail}
          tripDriver={tripDriver}
          tripVehicle={tripVehicle}
          handleTripDetail={handleTripDetail}
          handleTripDriver={handleTripDriver}
          handleTripVehicle={handleTripVehicle}
        />
      </Stack>

      {/* __ School Level Public Route__ */}
      <Stack mt={10}>
        <Typography variant='h4' mb={1}>
          Generate School Level Public Route Dashboard
        </Typography>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={1}
          color={'text.secondary'}
        >
          {helperTexts.GenerateSchoolLevelPublicRouteDashboardHelperText}
        </Typography>
        <Stack direction={'row'} flexWrap={'wrap'}>
          {schoolsData.length > 0 &&
            schoolsData.map((school) => {
              return <SchoolLevelDashboard school={school} setSchoolsData={setSchoolsData} />;
            })}
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default PublicRouteDashboard;
