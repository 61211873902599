import React, { useState, useEffect } from 'react';

// material ui
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';

// components
import MainCard from 'components/MainCard';

// assets
import SearchIcon from '@mui/icons-material/Search';

// project imports
import { formatPhoneNumber } from '../../../Utils/FormatPhoneNumber';
import NoRecordDisplay from '../../../../../components/NoRecordDisplay';
import { searchSchool } from '../../../../../actions/searchActions';

// third party
import { useDispatch, useSelector } from 'react-redux';
import { TableCellCustom } from 'containers/layouts/Students/Components/CustomComponents/TableCellCustom';
// icons
import BadgeIcon from '@mui/icons-material/Badge';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
const useStyles = makeStyles((theme) => ({
  dividerMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  main: {
    height: '82vh',
    padding: theme.spacing(10),
    marginTop: theme.spacing(25),
  },
  image: {
    height: theme.spacing(50),
    width: theme.spacing(60),
  },
  title: {
    fontSize: theme.spacing(30),
  },
  text: {
    fontSize: theme.spacing(5),
    paddingBottom: theme.spacing(50),
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  search: {
    marginRight: theme.spacing(3),
  },
}));

export default function DriverTable(props) {
  const classes = useStyles();
  const { driverData, page, filledRows, searched, isLoading } = props;
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const emptyArray = new Array(20).fill(0);

  // for handling pagination from search section
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.searchReducer);
  const { filters, starting_after, total_results } = useSelector(
    (state) => state.searchReducer.driverDataOptions,
  );

  const handleChangePage = (event, newPage) => {
    console.log('Handle Change called');
    if (newPage > page && filledRows.length / (newPage * 20) === 1) {
      if (props.isFromSearch) {
        // first argument search term , second valid options, fourth for updating dynmaice reducer state fifth for deciding for filter action or pagination action
        dispatch(
          searchSchool(
            '',
            'drivers',
            query,
            filters,
            'driverData',
            'pagination',
            starting_after + 20,
          ),
        );
      }
    }
    props.setPage(newPage);
  };

  useEffect(() => {
    if (filledRows.length > 0) {
      props.setPage(0);
      props.setSelectedDriverData(filledRows[0]);
      props.setSelectedDriverRowId(filledRows[0].id);
    }
  }, [searched]);

  useEffect(() => {
    props.setFilledRows([...driverData]);
  }, [driverData]);

  useEffect(() => {
    //  For handling issue from search pagination if we are on second page and search again or apply any filter then it will be on second page  so that's why
    props.setPage(0);
  }, [query, total_results]);

  const requestSearch = (searchedVal) => {
    props.setSearched(searchedVal.target.value);
    const filteredRows = driverData.filter((row) => {
      var sourceString =
        row.first_name.toLowerCase() +
        row.last_name.toLowerCase() +
        row.phone_number.toLowerCase() +
        row.email.toLowerCase();
      if (sourceString.includes(searchedVal.target.value.toLowerCase())) {
        return sourceString.includes(searchedVal.target.value.toLowerCase());
      }
    });
    props.setFilledRows([...filteredRows]);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filledRows.length - page * rowsPerPage);

  return (
    <MainCard content={false}>
      {!props.isFromSearch && (
        <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
          <Grid item xs={3} className={classes.search}>
            <TextField
              label='Search'
              value={searched}
              onChange={requestSearch}
              fullWidth='true'
              id='driverSearch'
              variant='standard'
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton size='large'>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCellCustom
                label='Name'
                icon={<PersonIcon color='primary' fontSize='small' />}
                id='first_name'
              />
              <TableCellCustom
                label='Phone Number'
                icon={<LocalPhoneIcon color='primary' fontSize='small' />}
                id='phone_number'
              />
              <TableCellCustom
                label='Email'
                icon={<EmailIcon color='primary' fontSize='small' />}
                id='email-driver'
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dataLoading &&
              emptyArray.map((el, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={4}>
                    <Skeleton animation='wave' variant='text' />
                  </TableCell>
                </TableRow>
              ))}
            {!props.dataLoading && filledRows.length > 0 ? (
              (rowsPerPage > 0
                ? filledRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filledRows
              ).map((row, index) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => props.handleRowClick(event, row)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      props.selectedDriverData?.driver_username === row?.driver_username
                        ? 'background.default'
                        : 'inherit',
                  }}
                >
                  <TableCell align='left' id={'first_name_' + index}>
                    {row.first_name} {row.last_name}
                  </TableCell>
                  <TableCell align='left' id={'phone_number_' + index}>
                    {formatPhoneNumber(row.phone_number)}
                  </TableCell>
                  <TableCell align='left' id={'email' + index}>
                    {row.email}
                  </TableCell>
                </TableRow>
              ))
            ) : !props.dataLoading && filledRows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align='center'
                  id='no_records'
                  style={{ borderColor: 'transparent' }}
                >
                  {props.dataLoading ? '' : <NoRecordDisplay />}
                </TableCell>
              </TableRow>
            ) : null}
            {!props.dataLoading && emptyRows > 0 && (
              <TableRow style={{ height: 70 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component='div'
        rowsPerPage={rowsPerPage}
        page={page}
        count={
          !props.isFromSearch || total_results - (page + 1) * 20 <= 0
            ? filledRows.length
            : filledRows.length + 1
        }
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from }) => `${from}-${Math.min((page + 1) * 20, filledRows.length)}`}
      />
    </MainCard>
  );
}
