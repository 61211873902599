import React, { createContext, useContext, useState } from 'react';

// creating drawer contexts
const DrawerContext = createContext({
  drawerOpen: true,
  handleDrawerToggle: () => {},
});

// custom hooks to access drawer
export function useDrawer() {
  return useContext(DrawerContext);
}

// theme provider context logic
export function DrawerProvider({ children }) {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = (open) => {
    if (typeof open === 'boolean') {
      setDrawerOpen(open);
      return;
    }
    setDrawerOpen((prevState) => !prevState);
  };

  return (
    <DrawerContext.Provider value={{ drawerOpen, handleDrawerToggle }}>
      {children}
    </DrawerContext.Provider>
  );
}
