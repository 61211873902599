import React from 'react';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRCode from 'react-qr-code';
import { CustomizedDialog } from 'components/dialog/CustomizedDialog';
import { Box } from '@mui/material';

/**
 * Device QR Code Component.
 * @param {Object} props - Component props.
 * @param {boolean} props.openQrCodeDialogue - Whether the QR code dialog is open.
 * @param {Function} props.handleCloseQrCodeDialog - Function to handle closing the QR code dialog.
 * @param {Object} props.qrCodeInfo - Information to be encoded in the QR code.
 * @returns {JSX.Element} - Device QR Code component.
 */
const DeviceQrCode = ({
  openQrCodeDialogue = false,
  handleCloseQrCodeDialog = () => {},
  qrCodeInfo = {},
}) => {
  return (
    <>
      <CustomizedDialog
        openDialog={openQrCodeDialogue}
        handleCloseDialog={handleCloseQrCodeDialog}
        title={'Device QR Code'}
        icon={<QrCode2Icon />}
        maxWidth='xs'
      >
        <Box sx={{ p: 3 }}>
          <QRCode
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={JSON.stringify(qrCodeInfo)}
          />
        </Box>
      </CustomizedDialog>
    </>
  );
};

export default DeviceQrCode;
