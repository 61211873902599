import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// mui
import { Paper, Typography, Grid } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

// components
import MainCard from 'components/MainCard';

// helper function
import { formatAMPMString } from 'containers/layouts/RecentActivity/helper';

const getStopTimelineDotText = (stop) => {
  const style = {
    width: '25px',
    height: '25px',
    padding: '1px',
    // borderRadius: '50%',
    // boxShadow: '0 0px 1px 0 rgba(0, 0, 0, 0.16)',
    margin: '3px 0px',
  };
  switch (stop.route_stop_id) {
    case 'source':
      return <img style={style} src={'/static/images/icons/flag.png'} alt='flag' />;
    case 'destination':
      return <img style={style} src={'/static/images/icons/startIcon.png'} alt='flag' />;
    default:
      return stop.index;
  }
};

const getStopTimelineDotContentHeading = (stop) => {
  switch (stop.route_stop_id) {
    case 'source':
      return 'Source';
    case 'destination':
      return 'Destination';
    default:
      return `Stop: ${stop.index}`;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timelineDot: {
    padding: '1px 8px',
  },
  textField: {
    width: '14ch',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  address: {
    marginTop: theme.spacing(2),
  },
}));

export function RenderRoutesStopsInfo(props) {
  const classes = useStyles();

  if (props.localRouteGraph == undefined) {
    return <React.Fragment />;
  }
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          mt: 2,
          flex: 0.12,
        },
      }}
    >
      {props.localRouteGraph.map((routeStop, index) => (
        <TimelineItem>
          <TimelineOppositeContent color='textSecondary'>
            {formatAMPMString(routeStop.registered_arrival_time)}
            {/* {routeStop.registered_arrival_time} */}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot
              sx={{
                fontSize: 12,
                fontWeight: 600,
                width: 30,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              color='secondary'
            >
              {getStopTimelineDotText(routeStop)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <MainCard>
              <Typography variant='h5' id={'detail_stop_num_' + index}>
                {getStopTimelineDotContentHeading(routeStop)}
              </Typography>
              <Grid item>
                <Typography variant='h6' className={classes.address}>
                  Address
                </Typography>
                <Typography>{routeStop.address}</Typography>
                <Typography className={classes.address} variant='h6'>
                  Geo Location
                  <Typography id={'detail_latitude_' + index}>
                    Latitude : {routeStop.geo_location.latitude}
                  </Typography>
                  <Typography id={'detail_longitude_' + index}>
                    Longitude : {routeStop.geo_location.longitude}
                  </Typography>
                </Typography>
              </Grid>
            </MainCard>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
