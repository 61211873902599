import React from 'react';
import { Divider, Grid, Typography, Stack, Skeleton } from '@mui/material';
import { QuickViewActionsTitleV2 } from 'components/quickViews';
import { QuickViewItemCard } from 'components/cards';

const QuickViewLoader = () => {
  return (
    <div>
      <Stack>
        <div>
          {' '}
          <Grid container>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <QuickViewActionsTitleV2 isLoading={true}>
                <Typography variant='h3'>something</Typography>
              </QuickViewActionsTitleV2>
            </Grid>
            <Grid item xs={12} sx={{ my: 3 }}>
              <Divider light />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={12} sx={{ mt: 3, mb: 1 }}>
              <Divider light />
            </Grid>
          </Grid>
        </div>
      </Stack>
      <div>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
        </QuickViewItemCard>
        <QuickViewItemCard label='' isDataLoading={true} icon={<Skeleton />}>
          <Skeleton />
        </QuickViewItemCard>
      </div>
    </div>
  );
};

export default QuickViewLoader;
