// assets
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';

const chipItems = [
  { label: 'Not Required', icon: <AccessibilityIcon />, value: false },
  { label: 'Required', icon: <AccessibleForwardIcon />, value: true },
];

const getDefaultSelectedChip = () => chipItems[0].value;

export { chipItems, getDefaultSelectedChip };

export function capitalizeFirstLetter(str) {
  // Check if the input string is empty or null
  if (!str) {
    return str;
  }

  // Capitalize the first letter and concatenate the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}
