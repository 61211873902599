import React from 'react';
import PropTypes from 'prop-types';

// material ui
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// assets
import PanToolIcon from '@mui/icons-material/PanTool';

const GlobalCustomTooltip = styled(({ className, textTransform, width, height, ...props }) => (
  <Tooltip
    {...props}
    height={height}
    textTransform={textTransform}
    width={width}
    classes={{ popper: className }}
  />
))(({ theme, height, width, textTransform }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    height: height,
    width: width,
    textTransform: textTransform,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
}));

export default function CustomTooltip({ children, width, textTransform, arrow, title, ...props }) {
  return (
    <GlobalCustomTooltip
      {...props}
      title={title}
      width={width}
      textTransform={textTransform}
      arrow={arrow}
    >
      {children}
    </GlobalCustomTooltip>
  );
}

CustomTooltip.defaultProps = {
  height: 'auto',
  width: 'auto',
  textTransform: 'none',
  title: 'title',
  children: <PanToolIcon />,
  arrow: true,
};
CustomTooltip.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  textTransform: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  arrow: PropTypes.bool,
};

// Readme.md

// We can use the customtool tip as show below

// <CustomTooltip title='Heading chat'>
// <Button>Custom Chat Tool Tip Check</Button>
// </CustomTooltip>

// Props We can pass
// height: 'auto', - Height of tool tip
// width: 'auto', - Width of tool tip
// textTransform: - textTransform of tool tip content
// title: 'title', - title of tool tip
// children: children to tool tip
// arrow: we can hide and show arrow
