import React, { forwardRef } from 'react';

// third-party
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { Link } from 'react-router-dom';
import { MenuItem } from '@mui/material';

// project import
import { activeItem } from 'actions/menuActions';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const DrawerMenuItem = ({ item, handleMenuClose }) => {
  const dispatch = useDispatch();
  const isSelected = document.location.pathname === item.url;

  const itemHandler = (id) => {
    // Dispatcher not in use
    dispatch(activeItem({ openItem: [id] }));
    handleMenuClose();
  };

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };

  return (
    <MenuItem
      {...listItemProps}
      key={item.id}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
    >
      {item.title}
    </MenuItem>
  );
};

DrawerMenuItem.propTypes = {
  item: PropTypes.object,
  handleMenuClose: PropTypes.func,
};

export default DrawerMenuItem;
