import { createContext, useContext, useState } from 'react';

// creating theme contexts
const ColorModeContext = createContext({
  isDarkMode: false,
  toggleThemeColorMode: () => {},
});

// custom hooks to access theme
export function useColorMode() {
  return useContext(ColorModeContext);
}

// theme provider context logic
export function ColorModeProvider({ children }) {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const toggleThemeColorMode = () => {
    setIsDarkTheme((prevState) => !prevState);
  };

  // Theme change as per browser disabled.
  // useEffect(() => {
  //   const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
  //   setIsDarkTheme(mediaQueryList.matches);

  //   const handleChange = (e) => {
  //     setIsDarkTheme(e.matches);
  //   };
  //   mediaQueryList.addEventListener('change', handleChange);
  // }, []);

  return (
    <ColorModeContext.Provider value={{ isDarkTheme, toggleThemeColorMode }}>
      {console.log('Context refreshed')}
      {children}
    </ColorModeContext.Provider>
  );
}
