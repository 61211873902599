import L from 'leaflet';

export const DestinationSvg = (color) => {
  const Marker = new L.DivIcon({
    iconSize: [28, 28],
    iconAnchor: [17, 46],
    popupAnchor: [1, -46],
    // iconSize: [5, 5],
    // iconAnchor: [5, 0], //[left/right, top/bottom]
    // popupAnchor: [12, 2], //[left/right, top/bottom]
    className: 'student-single-marker',
    html: `
    <svg width=${28} height=${28} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m42.048 40.22 -5.625 -11.25c-0.282 -0.563 -0.705 -0.845 -1.266 -0.845H19.688v5.625c0 0.845 -0.563 1.407 -1.407 1.407s-1.405 -0.563 -1.405 -1.407V19.407c2.955 -1.407 5.063 -0.282 7.736 1.125 1.97 0.982 4.077 1.97 6.47 1.97 1.407 0 3.095 -0.42 4.782 -1.407a1.479 1.479 0 0 0 0.702 -1.266V6.747a1.476 1.476 0 0 0 -0.702 -1.265 1.278 1.278 0 0 0 -1.407 0c-3.232 1.97 -5.625 0.702 -8.438 -0.702 -3.095 -1.545 -6.611 -3.375 -11.25 -0.702 -0.42 0.42 -0.705 0.845 -0.705 1.266v22.782H9.845c-0.563 0 -0.982 0.282 -1.266 0.845l-5.622 11.247c-0.282 0.423 -0.143 0.982 0 1.407 0.143 0.42 0.845 0.563 1.266 0.563h36.563a1.688 1.688 0 0 0 1.266 -0.702c0.282 -0.423 0.139 -0.845 0 -1.266Z" fill="${color}"/>
    </svg>`, // SVG image wrapped in a div
  });
  return Marker;
};

// NEW DESTINATION MAKRER
export const DestinationMarker = (color) => {
  const Marker = new L.DivIcon({
    iconSize: [28, 28],
    iconAnchor: [17, 46],
    popupAnchor: [1, -46],
    className: 'student-single-marker',
    html: `
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6847_52583)">
    <circle cx="18.5" cy="14.5" r="14.5" fill="white"/>
    <mask id="mask0_6847_52583" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="9" y="5" width="19" height="19">
    <rect x="9.94922" y="5.94922" width="17.8462" height="17.8462" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_6847_52583)">
    <path d="M18.8731 20.0746C17.1257 18.7857 15.8213 17.534 14.96 16.3195C14.0986 15.105 13.668 13.9152 13.668 12.7503C13.668 11.8703 13.826 11.0989 14.142 10.4358C14.458 9.7728 14.8639 9.21821 15.3596 8.77206C15.8554 8.3259 16.4131 7.99129 17.0327 7.76821C17.6524 7.54513 18.2658 7.43359 18.8731 7.43359C19.4804 7.43359 20.0938 7.54513 20.7135 7.76821C21.3331 7.99129 21.8908 8.3259 22.3866 8.77206C22.8823 9.21821 23.2882 9.7728 23.6042 10.4358C23.9202 11.0989 24.0782 11.8703 24.0782 12.7503C24.0782 13.9152 23.6476 15.105 22.7862 16.3195C21.9249 17.534 20.6205 18.7857 18.8731 20.0746ZM18.8731 14.1259C19.2821 14.1259 19.6322 13.9803 19.9234 13.689C20.2147 13.3978 20.3603 13.0477 20.3603 12.6387C20.3603 12.2297 20.2147 11.8796 19.9234 11.5884C19.6322 11.2972 19.2821 11.1515 18.8731 11.1515C18.4641 11.1515 18.114 11.2972 17.8228 11.5884C17.5315 11.8796 17.3859 12.2297 17.3859 12.6387C17.3859 13.0477 17.5315 13.3978 17.8228 13.689C18.114 13.9803 18.4641 14.1259 18.8731 14.1259ZM13.668 22.3054V20.8182H24.0782V22.3054H13.668Z" fill="${color}"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_d_6847_52583" x="0" y="0" width="37" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6847_52583"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6847_52583" result="shape"/>
    </filter>
    </defs>
    </svg>
`, // SVG image wrapped in a div
  });
  return Marker;
};
