/**
 * Class representing trip statuses.
 */
export class TripStatus {
  static BREAKDOWN = 'BreakDown';
  static COMPLETED = 'Completed';
  static ON_ROAD = 'OnRoad';
  static SCHEDULED = 'Scheduled';
  static ON_TIME = 'OnTime';
  static ARRIVING_EARLY = 'ArrivingEarly';
  static RUNNING_LATE = 'RunningLate';
  static COVERED = 'Covered';
  static IN_PROGRESS = 'InProgress';
  static PENDING = 'pending';
  static PAUSED = 'Paused';
}

/**
 * Class representing trip Stop identifier.
 */
export class TripStop {
  static DESTINATION = 'destination';
  static SOURCE = 'source';
}

export const tripStatusOrder = [
  TripStatus.ON_ROAD,
  TripStatus.ON_TIME,
  TripStatus.ARRIVING_EARLY,
  TripStatus.RUNNING_LATE,
  TripStatus.COVERED,
  TripStatus.IN_PROGRESS,
  TripStatus.SCHEDULED,
  TripStatus.PENDING,
  TripStatus.COMPLETED,
  TripStatus.BREAKDOWN,
  TripStatus.PAUSED,
];

export const routeType = {
  AM: 'AM',
  PM: 'PM',
};

export const routeTypeList = [
  { label: routeType.AM, value: routeType.AM },
  { label: routeType.PM, value: routeType.PM },
];
