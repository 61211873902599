import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/styles';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
} from '@mui/material';
import Icon from '@mui/material/Icon';

// contexts and custom hooks
import { useDrawer } from 'contexts';

// project imports
import NavItem from './NavItem';
import DrawerMenuItem from './DrawerMenuItem';

// assets
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  const { drawerOpen } = useDrawer();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = () => {
    if (drawerOpen) {
      setOpen((prevProp) => !prevProp);
    } else {
      setAnchorEl(anchorEl ? null : document.getElementById(`drawer-menu-${menu.id}`));
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const pathSegments = document.location.pathname.split('/').filter((segment) => segment !== '');
  const menuPathSegments = menu.url.split('/').filter((segment) => segment !== '');

  // Get the first path segment after the root URL
  const isSelected = pathSegments[0] === menuPathSegments[0];

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return !item.isDrawerHidden && <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        );
    }
  });

  const Ion = isSelected ? menu.icons?.filled : menu.icons?.outlined;
  const menuIcon = menu.icons ? (
    <Icon sx={{ color: isSelected ? 'primary.main' : 'text.primary' }}>
      <Ion />
    </Icon>
  ) : (
    <FiberManualRecordTwoToneIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  useEffect(() => {
    if (menu.open) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        selected={!drawerOpen && isSelected}
        sx={{
          mr: 2,
          py: 0.8,
          borderTopRightRadius: theme.shape.borderRadius * 3,
          borderBottomRightRadius: theme.shape.borderRadius * 3,
          borderLeft: `4px solid ${theme.palette.hover.paper}`,
          '&:hover': {
            borderLeft: `4px solid ${theme.palette.hover.default}`,
          },
          '&.Mui-selected': {
            borderLeft: `4px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <ListItemIcon>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography id={`drawer-menu-${menu.id}`} variant='body1' color='inherit'>
              {menu.title}
            </Typography>
          }
        />
        {open ? (
          <ExpandMore stroke={1.5} size='1rem' sx={{ marginTop: 'auto', marginBottom: 'auto' }} />
        ) : (
          <ExpandLess stroke={1.5} size='1rem' sx={{ marginTop: 'auto', marginBottom: 'auto' }} />
        )}
      </ListItemButton>
      {drawerOpen ? (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List>{menus}</List>
        </Collapse>
      ) : (
        menu.children &&
        menu.children.length > 0 && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {menu.children.map((item) => {
              // check dynamic url
              const filterDynamic = item?.url
                ?.split('/')
                ?.find((segment) => segment?.includes(':'));
              if (filterDynamic) return null;
              return <DrawerMenuItem item={item} handleMenuClose={handleMenuClose} />;
            })}
          </Menu>
        )
      )}
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
