export const longDateFormatter = (epochTime, seconds = false) => {
  if (!epochTime) return '--';
  
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  let timestamp = 0;
  timestamp = new Date(epochTime);
  if (seconds === true) {
    timestamp = new Date(epochTime * 1000);
  }
  return timestamp.toLocaleDateString('en-US', options);
};

export const getLocaleTime = (epochTime, seconds = true) => {
  let timestamp = new Date(epochTime * 1000);
  if (!seconds) {
    timestamp = new Date(epochTime);
  }
  return timestamp.toLocaleTimeString('en-US');
};
