import React, { useState, useMemo } from 'react';

// third-party imports
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerContent from './DrawerContent';
import DrawerHeader from './DrawerHeader/index';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from 'constants';

// contexts and custom hooks
import { useDrawer } from 'contexts';

// ============================== DASHBOARD - DEFAULT ============================== //

const MainDrawer = () => {
  const theme = useTheme();
  // let location = useLocation();
  const { drawerOpen, handleDrawerToggle } = useDrawer();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? window.document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader />, []);

  return (
    <Box component='nav' sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label='mailbox folders'>
      {!matchDownMD ? (
        <MiniDrawerStyled variant='permanent' open={drawerOpen}>
          {drawerHeader}
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            // Duration for hide animation in ms.
            autoHideDuration={200}
          >
            {drawerContent}
          </Scrollbars>
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant='temporary'
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerHeader}
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            // Duration for hide animation in ms.
            autoHideDuration={200}
          >
            {drawerContent}
          </Scrollbars>
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  window: PropTypes.object,
};

export default MainDrawer;
