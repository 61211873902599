import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '82vh',
    padding: theme.spacing(10),
  },
  image: {
    height: theme.spacing(100),
    width: theme.spacing(100),
  },
  title: {
    fontSize: theme.spacing(30),
  },
  text: {
    fontSize: theme.spacing(5),
  },
  account: {
    fontSize: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

export default function NoMatchPage(props) {
  const classes = useStyles();
  const accountType = localStorage.getItem('loggedUserRoleType');

  function getUserRole(role_type) {
    switch (role_type) {
      case 'ROLE_ADMIN':
        return 'Administrator';
      case 'ROLE_SCHOOL_LEVEL_ACCESS':
        return 'School Level Access';
      case 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS':
        return 'School Level Read Only Access';
      case 'ROLE_SUPER_ADMIN':
        return 'Super Administrator';
    }
  }
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={3}
      className={classes.main}
    >
      <Grid item xs={6}>
        <img
          alt='Not found image'
          id='not-found-page'
          className={classes.image}
          src='/static/images/spotbus.png'
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='h1' className={classes.title}>
          404
        </Typography>
        <Typography variant='body1' className={classes.text} gutterBottom>
          The page you are looking for does not exist or you are not authorized contact admin for
          permissions.
        </Typography>
        <Typography variant='body1' color='textSecondary' className={classes.account} gutterBottom>
          Access level : {getUserRole(accountType)}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          size='large'
          className={classes.button}
          disableElevation
          onClick={() =>
            props.history.push({
              pathname: '/',
            })
          }
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
}
