import * as React from 'react';

//material ui
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AssignmentIcon from '@mui/icons-material/Assignment';

//project imports
import MainCard from 'components/MainCard';
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';
import { CardHeading } from 'components/Headers/CardHeading';

export default function UserCard(props) {
  const ShowRoleType = (role) => {
    switch (role) {
      case 'ROLE_SUPER_ADMIN':
        return 'Full Access/Admin';
      case 'ROLE_ADMIN':
        return 'Full Access/Admin';
      case 'ROLE_SCHOOL_LEVEL_ACCESS':
        return 'School Level Access';
      case 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS':
        return 'School Level Read Only Access';
      default:
        break;
    }
  };
  return (
    <Grid mt={4}>
      <MainCard>
        <Grid container xs={12} sx={{ mb: -4 }}>
          <Grid container>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CardHeading title={props.user.first_name + ' ' + props.user.last_name} />
              </Grid>
              <Grid item xs={6} sx={{ pl: 1 }}>
                <Grid container>
                  <Grid item xs={2}>
                    <PhoneIcon color='secondary' />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant='body1' color='secondary.main'>
                      {formatPhoneNumber(props.user.phone_number)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={2}>
                    <EmailIcon color='secondary' />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant='body1' color='secondary.main' sx={{ pl: 1 }} noWrap>
                      {props.user.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <Grid item xs={12} sx={{ pl: 1 }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <AssignmentIcon color='secondary' />
                      </Grid>
                      <Grid item xs={11} sx={{ pl: 3 }}>
                        <Typography variant='body1' color='secondary.main'>
                          {ShowRoleType(props.user.user_permissions.role_type)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'flex-end', borderWidth: '2px' }}
                >
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    onClick={() => props.handleEdit(props.user)}
                    disabled={props.loadingData}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    color='error'
                    onClick={() => {
                      props.setOpen();
                      props.handleOnDeleteClick(props.user);
                      // props.setDeleteSchoolId(props.school.school_id);
                    }}
                    disabled={props.loadingData}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
