import React from 'react';

// material ui
import { Grid, Typography, Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// project imports
import UserInformation from './UserInformation';
import GeneratePermission from './GeneratePermission';
import { Heading } from 'components/Headers/Heading';
import BasicUserForm from './BasicUserForm';
import { SubHeading } from 'components/Headers';

// assets
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SaveIcon from '@mui/icons-material/Save';

export default function AddUser(props) {
  // const phoneRegExp = /^\d{10}$/;

  const handleSubmit = (e, formikProps) => {
    e.preventDefault();
    formikProps.handleSubmit();
    if (
      // props.firstName &&
      // props.lastName &&
      // phoneRegExp.test(props.phoneNumber) &&
      // props.email &&
      // props.gender &&
      Boolean(formikProps.values.first_name) &&
      Boolean(formikProps.values.last_name) &&
      Boolean(formikProps.values.phone_country_code) &&
      Boolean(formikProps.values.email) &&
      Boolean(formikProps.values.gender) &&
      props.permissionType &&
      props.permittedSchools?.length > 0
    ) {
      if (props.isEdit) {
        props.handleUpdateUserPermissions();
      } else {
        props.handleUserFormSubmit(formikProps);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, props.formikProps)} id='userForm'>
      <Grid sx={{ pl: 2 }}>
        <Heading title={props.isEdit ? 'Update Users' : 'Add Users'} />
      </Grid>
      <Box sx={{ minHeight: 640 }}>
        <Grid container direction='row' xs={12} justifyContent='space-between' sx={{ px: 3 }}>
          {/* <UserInformation phoneRegExp={phoneRegExp} {...props} /> */}
          <Grid item xs={12}>
            <SubHeading subTitle={'Basic Information'} color={'primary'} />
          </Grid>
          <Grid item xs={12}>
            <BasicUserForm
              formikProps={props.formikProps}
              loader={Boolean(props.buttonLoader) ? props.buttonLoader : props.isEdit}
            />
          </Grid>
          <GeneratePermission {...props} />
        </Grid>
      </Box>
      <Grid container sx={{ p: 2 }}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} container justifyContent='flex-end' spacing={3}>
          <Grid item></Grid>
          <Grid item>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={props.buttonLoader}
              loadingPosition='start'
              onClick={() => {
                props.setProceed(true);
              }}
              startIcon={<SaveIcon />}
            >
              <Typography sx={{ paddingLeft: props.buttonLoader ? 6 : 0 }}>
                {props.isEdit ? 'UPDATE USER' : 'ADD USER'}
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
