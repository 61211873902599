import React from 'react';
import PropTypes from 'prop-types';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Typography,
  ListSubheader,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function MyListSubheader(props) {
  return <ListSubheader {...props} />;
}

MyListSubheader.muiSkipListHighlight = true;

/**
 * Renders a SelectField Formik component with the provided data, field, form, label, Icon, async, helperText, selectFieldProps, and mainContainerProps. It also handles rendering of error messages and async data loading icons.
 *
 * @param {Object} data - The data for the select field
 * @param {Object} field - The field object
 * @param {Object} form - The form object
 * @param {string} label - The label for the select field
 * @param {JSX.Element} Icon - The icon element to be rendered
 * @param {boolean} async - Flag to indicate if the data is being loaded asynchronously
 * @param {string} helperText - The helper text for the select field
 * @param {Object} selectFieldProps - Additional props for the select field
 * @param {Object} mainContainerProps - Additional props for the main container (default is an empty object)
 * @return {JSX.Element} The rendered SelectField component
 * 
 * 
 * @example   
   <Field
      component={SelectField}
      label='your label'
      name={@name}
      Icon={MUI_ICON}
      data={[{label: 'Vehicle 1', value: 1, color: 'optional', subheader: 'optional'}, {label: 'Vehicle 2', value: 2}...]}
      async={@async}
      id={@id}
      helperText='helper text'
      selectFieldProps={@selectFieldProps}
    />
 */
function SelectField({
  data,
  field,
  form,
  label,
  Icon,
  async,
  helperText,
  selectFieldProps,
  mainContainerProps = {},
  ...rest
}) {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const error = errors[name];
  const isError = touched[name] && error && true;

  const isAsyncDataLoading = async && !data;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    } else {
      return <FormHelperText>{helperText}</FormHelperText>;
    }
  }

  const iconRender = () => {
    if (Icon) {
      if (isAsyncDataLoading) {
        return <CircularProgress size={22} sx={{ mr: 1.5, color: 'secondary.dark' }} />;
      }
      return <Icon sx={{ mr: 1.5, color: 'secondary.dark' }} />;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <FormControl error={isError} disabled={isAsyncDataLoading || isSubmitting} {...rest}>
        <InputLabel>{label}</InputLabel>
        <Select
          {...field}
          label={label}
          startAdornment={<Stack height={'auto'}>{iconRender() || null}</Stack>}
          {...selectFieldProps}
        >
          {data?.map((item, index) => {
            if (item?.subheader) {
              return <MyListSubheader>{item?.subheader}</MyListSubheader>;
            }
            return (
              <MenuItem key={index} id={index} value={item.value}>
                <Stack>
                  {/*  color optional */}
                  <Typography component={'span'} color={item?.color || 'inherit'}>
                    {item.label}
                  </Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
        {_renderHelperText()}
      </FormControl>
    </Box>
  );
}

SelectField.defaultProps = {
  async: false,
};

SelectField.propTypes = {
  data: PropTypes.oneOfType([undefined, PropTypes.array]).isRequired,
};

export default SelectField;
