import React from 'react';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Typography, Grid } from '@mui/material';

// timeline material UI imports
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

import { getPresentStudents, getAbsentStudents } from './RenderStudentInfo';
import { getTimewithSeconds, getDateAccordingTimeZone } from '../../Utils/ZoneHandling';
import { isoToLocalTime } from 'utils/spotbus-date-time';

const getStopHeading = (stopInfo) => {
  switch (stopInfo.route_stop_id) {
    case 'source':
      return 'SOURCE';

    case 'destination':
      return 'DESTINATION';

    default:
      return `STOP: ${stopInfo.index}`;
  }
};

// styles define
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timelineDot: {
    padding: '0px 6px',
  },
  textField: {
    width: '14ch',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  address: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  studentm: {
    marginTop: theme.spacing(1),
  },
  status: {
    position: 'absolute',
    bottom: '15px',
    right: '5px',
  },
  flex: {
    display: 'flex',
  },
  key: {
    fontWeight: '500',
  },
  relative: {
    position: 'relative',
  },
}));

export function RenderStopsInfo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const STATUS_COLOR = {
    ArrivingEarly: '#5cb85c',
    OnTime: 'primary',
    RunningLate: theme.palette.error.main,
    Pending: 'secondary',
  };

  // Old Function to show time
  function getTripTime(reg, timestamp, zone) {
    const actualTime = getTimewithSeconds(getDateAccordingTimeZone(timestamp * 1000, zone));
    if (reg == actualTime) {
      return (
        <Typography variant='subtitle2' color='textPrimary'>
          {reg}
        </Typography>
      );
    }
    return (
      <>
        <Typography
          variant='subtitle2'
          color='textPrimary'
          style={{ textDecoration: 'line-through' }}
        >
          {reg}
        </Typography>
        <Typography variant='subtitle2' color='textPrimary'>
          {actualTime}
        </Typography>
      </>
    );
  }

  // New stop timing according to the according to the schema
  function getTripStopTime(scheduledArrivalTime, actualArrivalTime) {
    const scheduledArrivalLocalTime = isoToLocalTime(scheduledArrivalTime);
    const actualArrivalLocalTime = isoToLocalTime(actualArrivalTime);

    function compareDates(date1String, date2String) {
      if (!date1String || !date2String) {
        return false;
      }
      const date1 = new Date(date1String);
      const date2 = new Date(date2String);
      return date1 < date2;
    }

    if (!compareDates(scheduledArrivalTime, actualArrivalTime)) {
      return (
        <Typography variant='subtitle2' color='textPrimary' noWrap>
          {scheduledArrivalLocalTime}
        </Typography>
      );
    }
    return (
      <>
        <Typography
          variant='subtitle2'
          color='textPrimary'
          style={{ textDecoration: 'line-through' }}
        >
          {scheduledArrivalLocalTime}
        </Typography>
        <Typography variant='subtitle2' color='textPrimary'>
          {actualArrivalLocalTime}
        </Typography>
      </>
    );
  }

  function getStatusColor(status) {
    if (status in STATUS_COLOR) {
      return STATUS_COLOR[status];
    }
    return 'secondary';
  }
  function getETA(timestamp) {
    return getTimewithSeconds(getDateAccordingTimeZone(timestamp * 1000, props.timeZone));
  }
  console.log(props.localRouteGraph);
  if (props.localRouteGraph == undefined) {
    return <React.Fragment />;
  }

  const getStopTimelineDotText = (stop) => {
    const style = {
      width: '25px',
      height: '25px',
      padding: '1px',
      // borderRadius: '50%',
      // boxShadow: '0 0px 1px 0 rgba(0, 0, 0, 0.16)',
      margin: '3px 0px',
    };
    switch (stop.route_stop_id) {
      case 'source':
        return <img style={style} src={'/static/images/icons/flag.png'} alt='flag' />;
      case 'destination':
        return <img style={style} src={'/static/images/icons/startIcon.png'} alt='flag' />;
      default:
        return stop.index;
    }
  };

  return (
    <Timeline align='left'>
      {props.localRouteGraph.map((routeStop, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent style={{ flex: 0.01 }} id={'time_' + index}>
            {/* {getLocalTime(routeStop?.stop_travel_metrics?.scheduled_arrival_time)} */}
            {routeStop?.stop_travel_metrics &&
              getTripStopTime(
                routeStop?.stop_travel_metrics?.scheduled_arrival_time,
                routeStop?.stop_travel_metrics?.actual_arrival_time,
              )}
            {/* {props.timeZone
              ? getTripTime(
                  routeStop.registered_arrival_time,
                  routeStop?.planned_arrival_time?.timestamp,
                  props.timeZone,
                )
              : '09:11'} */}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                fontSize: 12,
                fontWeight: 600,
                width: 30,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              color='secondary'
              id={'stopNum_' + index}
            >
              {getStopTimelineDotText(routeStop)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={1} className={classes.paper}>
              <Grid container direction='row' spacing={3} justifyContent='space-between'>
                <Grid item>
                  <Typography variant='subtitle2' id={'stop_' + index}>
                    {getStopHeading(routeStop)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <div className={classes.address}>
                    <div className={classes.flex}>
                      <LocationOnIcon color='primary' fontSize='small' className={classes.icon} />
                      <Typography variant='h6' color='primary' gutterBottom>
                        Address
                      </Typography>
                    </div>
                    <Typography id={'aptPlot_' + index}>{routeStop.address}</Typography>
                  </div>
                  <Divider sx={{ width: '90%' }} />
                  <div className={classes.address}>
                    <div className={classes.flex}>
                      <MyLocationIcon fontSize='small' className={classes.icon} />
                      <Typography variant='h6' color='primary' gutterBottom>
                        Geo Location
                      </Typography>
                    </div>
                    <Typography id={'latitude_' + index}>
                      <span className={classes.key}> Latitude : </span>
                      {parseFloat(routeStop.geo_location.latitude).toFixed(6)}
                    </Typography>
                    <Typography id={'longitude_' + index}>
                      <span className={classes.key}> Longitude : </span>
                      {parseFloat(routeStop.geo_location.longitude).toFixed(6)}
                    </Typography>
                    {routeStop.route_stop_id !== 'source' &&
                      routeStop.route_stop_id !== 'destination' && (
                        <Typography id={'cost_' + index}>
                          <span className={classes.key}> Cost : </span>
                          {routeStop.summary === undefined ? '--' : routeStop?.summary.cost}
                        </Typography>
                      )}
                    {routeStop.route_stop_id !== 'source' &&
                      routeStop.route_stop_id !== 'destination' && (
                        <Typography id={'length' + index}>
                          <span className={classes.key}> Distance from prev stop : </span>
                          {routeStop.summary === undefined ? '--' : routeStop.summary.length}
                        </Typography>
                      )}
                    {routeStop.route_stop_id !== 'source' && (
                      <Typography id={'ETA_' + index}>
                        <span className={classes.key}> {'ETA : '}</span>
                        {routeStop.eta === undefined ? null : getETA(routeStop.eta.timestamp)}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={5} className={classes.relative}>
                  <Grid container spacing={3} direction='column'>
                    {routeStop.route_stop_id !== 'source' &&
                      routeStop.route_stop_id !== 'destination' && (
                        <Grid container direction='row' spacing={3} className={classes.studentm}>
                          <Grid item xs={12}>
                            <Typography variant='subtitle2' gutterBottom>
                              Present Students
                            </Typography>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} style={{ cursor: 'pointer' }}>
                            {getPresentStudents(props.childsAttendanceInfo, index, props.history)}
                          </Grid>
                        </Grid>
                      )}
                    {routeStop.route_stop_id !== 'source' &&
                      routeStop.route_stop_id !== 'destination' && (
                        <Grid container direction='row' spacing={3} className={classes.studentm}>
                          <Grid item xs={12}>
                            <Typography variant='subtitle2' gutterBottom>
                              Absent Students
                            </Typography>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} style={{ cursor: 'pointer' }}>
                            {getAbsentStudents(props.childsAttendanceInfo, index)}
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <div className={classes.status}>
                        <Chip
                          icon={<AirportShuttleIcon />}
                          color='primary'
                          label={routeStop.status ? routeStop.status : 'Pending'}
                          style={{
                            backgroundColor: getStatusColor(routeStop.status),
                            fontWeight: '500',
                            color: '#fff',
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
