/**
 * Formats a phone number by removing non-digit characters and applying a specific formatting pattern.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @return {string} The formatted phone number.
 *
 * @example
 *
 * formatPhoneNumber('1234567890');
 * Output: '123-456-7890'
 *
 * formatPhoneNumber('+11234567890');
 * Output: '+1 (123) 456-7890'
 */
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '--';
  }
  // Remove non-digit characters from the input
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Extract the country code and remaining digits
  let countryCode = '';
  let remainingDigits = digitsOnly;

  if (digitsOnly.length >= 2) {
    countryCode = '+' + digitsOnly.substring(0, digitsOnly.length - 10);
    remainingDigits = digitsOnly.substring(digitsOnly.length - 10);
  }

  // Define the formatting pattern
  const pattern = countryCode.length > 1 ? countryCode + ' (###) ###-####' : '###-###-####';

  // Format the remaining digits using the pattern
  let formattedNumber = '';
  let digitIndex = 0;

  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] === '#') {
      formattedNumber += remainingDigits[digitIndex] || '';
      digitIndex++;
    } else {
      formattedNumber += pattern[i];
    }
  }

  return formattedNumber;
}
