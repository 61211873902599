import { API } from 'aws-amplify';
import { API_ID } from 'clients';

//Communications
const API_SESSIONS = '/dashboard/sessions';

export function createSession(requestBody) {
  console.log('[API][REQUEST] Create session: ', requestBody);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_SESSIONS, {
      body: requestBody,
    })
      .then((result) => {
        console.log('[API][RESPONSE] Create session: ', result);
        resolve(result);
      })
      .catch((error) => {
        console.error('[API][ERROR] Create session: ', error.response);
        reject(error.response);
      });
  });
}

export function getSession(sessionId) {
  return new Promise((resolve, reject) => {
    console.log('[API][REQUEST] Get session ', sessionId);

    API.get(API_ID, API_SESSIONS + '/' + sessionId)
      .then((result) => {
        console.log('[API][RESPONSE] Get session: ', result);
        resolve(result);
      })
      .catch((error) => {
        console.error('[API][ERROR] Get session: ', error.response);
        reject(error.response);
      });
  });
}

export function updateSession(sessionId, requestBody) {
  console.log('[API][REQUEST] Update session: ', sessionId, 'with payload', requestBody);
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_SESSIONS + '/' + sessionId, {
      body: requestBody,
    })
      .then((result) => {
        console.log('[API][RESPONSE] Update session: ', result);
        resolve(result);
      })
      .catch((error) => {
        console.error('[API][ERROR] Update session: ', error.response);
        reject(error.response);
      });
  });
}

export function deleteSession(sessionId) {
  return new Promise((resolve, reject) => {
    console.log('[API][REQUEST] Delete session ', sessionId);

    API.del(API_ID, API_SESSIONS + '/' + sessionId)
      .then((result) => {
        console.log('[API][RESPONSE] Delete session: ', result);
        resolve(result);
      })
      .catch((error) => {
        console.error('[API][ERROR] Delete session: ', error.response);
        reject(error.response);
      });
  });
}

export function getSessionList() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_SESSIONS)
      .then((response) => {
        console.log('Session List', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Session Error', error);
        reject(error.response);
      });
  });
}