import React, { useState } from 'react';

// material ui
import { Grid, Typography, Card, CardContent } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';

// project imports
import SchoolCard from './SchoolCard';
import DeleteSchoolAlert from '../../DeleteSchoolAlert';
import NoRegisteredSchool from './NoRegisteredSchool';
import MainCard from 'components/MainCard';

export default function SchoolList(props) {
  const theme = useTheme();
  const [deleteSchoolId, setDeleteSchoolId] = useState(false);
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7];

  return (
    <Card
      sx={{
        height: '100%',
        // mx: 4,
        zIndex: 2,
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? 'secondary.lighter' : 'secondary.darker',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <CardContent>
        {/* <MainCard sx={{ backgroundColor: theme.palette.primary.main }}> */}
        <Grid container>
          <Typography
            variant='h2'
            sx={{ py: 1, pl: 2, color: 'common.white', fontWeight: 300, mb: -4 }}
          >
            Schools
          </Typography>
          <Typography
            variant='h5'
            sx={{
              pt: 4,
              pl: 2,
              color: 'common.white',
              fontWeight: 400,
              fontStyle: 'italic',
            }}
          >
            {`(${props.guidedSchools?.length ? props.guidedSchools?.length : '- '} school${
              props.guidedSchools?.length > 1 ? 's are' : ' is'
            } registered)`}
          </Typography>
        </Grid>
        <Scrollbars
          style={{ height: 811 }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          disableHorizontalScrolling
        >
          <Grid sx={{ mt: 4 }}>
            {!props.guidedSchools ? (
              <Grid>
                {skeletonCount.map((skeleton, index) => {
                  return (
                    <MainCard content={false} key={index} sx={{ padding: 2, mb: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Skeleton variant='circular' width={100} height={100} />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography component='div' variant='h2' width='60%'>
                            <Skeleton />
                          </Typography>
                          <Typography component='div' variant='body1' width='80%'>
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </Grid>
                    </MainCard>
                  );
                })}
              </Grid>
            ) : props.guidedSchools?.length === 0 ? (
              <NoRegisteredSchool />
            ) : (
              props.guidedSchools?.map((school) => (
                <SchoolCard
                  school={school}
                  key={school.school_id}
                  handleEdit={props.handleEdit}
                  setOpen={() => props.setOpenDeleteAlert(true)}
                  setDeleteSchoolId={setDeleteSchoolId}
                  loadingData={props.loadingData}
                />
              ))
            )}
          </Grid>
        </Scrollbars>
        {/* </MainCard> */}
      </CardContent>
      <DeleteSchoolAlert
        open={props.openDeleteAlert}
        setOpen={() => props.setOpenDeleteAlert(true)}
        setClose={() => props.setOpenDeleteAlert(false)}
        handleDeleteSchool={props.handleDeleteSchool}
        buttonLoader={props.deleteButtonLoader}
        deleteSchoolId={deleteSchoolId}
      />
    </Card>
  );
}
