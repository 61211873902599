import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
function ShiftSelect({
  data,
  field,
  form,
  label,
  Icon,
  async,
  helperText,
  selectFieldProps,
  mainContainerProps = {},
  ...rest
}) {
  const { name, inputProps } = field;
  const { touched, errors, isSubmitting } = form;

  const error = errors[name];
  const isError = touched[name] && error && true;

  const isAsyncDataLoading = async && data === undefined;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    } else {
      return <FormHelperText>{helperText}</FormHelperText>;
    }
  }

  return (
    <FormControl error={isError} disabled={isAsyncDataLoading || isSubmitting} {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        label={label}
        startAdornment={Icon ? <Icon sx={{ mr: 2 }} position='start' /> : null}
        {...selectFieldProps}
      >
        {data?.map((item, index) => (
          <MenuItem key={index} id={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

ShiftSelect.defaultProps = {
  async: false,
};

ShiftSelect.propTypes = {
  data: PropTypes.oneOfType([undefined, PropTypes.array]).isRequired,
};

export default ShiftSelect;
