import React, { useState } from 'react';

// MUI Imports
import { Stack, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// Icons
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

/**
 * CopyLinkAndViewLink is a function that renders a component for copying and viewing a link.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - disabled: A boolean indicating whether the component is disabled.
 *   - url: A string representing the URL to be displayed.
 *   - id: A string representing the ID of the component.
 * @return {JSX.Element} - The JSX element representing the component.
 */
export const CopyLinkAndViewLink = ({
  disabled,
  url = 'localhost:3000/publicDashboard/526erdg55se',
  id = '',
}) => {
  const [showLink, setShowLink] = useState(true);
  // copy url const copy url
  const [copyUrl, setCopyUrl] = useState(false);

  const copyText = (id) => {
    setCopyUrl(true);
    const inputElement = document.getElementById(id);

    const textToCopy = inputElement.textContent;
    setTimeout(() => {
      if (textToCopy) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            console.log('Text copied to clipboard:', textToCopy);
          })
          .catch((error) => {
            console.error('Error copying text to clipboard:', error);
          });
      }
    }, 10);

    setTimeout(() => {
      setCopyUrl(false);
    }, 1000);
  };
  const handleShowLink = () => {
    setShowLink((prev) => !prev);
  };
  return (
    <Stack
      id='CopyLinkAndViewLink'
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      // width={'100%'}
      backgroundColor={'secondary.light'}
      py={0.5}
      borderRadius={'2px'}
      overflow={'hidden'}
    >
      <Typography
        px={2}
        color={'text.secondary'}
        variant='subtitle2'
        sx={{ maxWidth: '180px', filter: showLink ? 'blur(4px)' : 'none', whiteSpace: 'nowrap' }}
        overflow={'hidden'}
        id={id}
      >
        {url}
      </Typography>
      <Stack direction={'row'} alignItems={'center'}>
        <Tooltip title={`${copyUrl ? 'Copied' : 'Copy to clipboard'}`}>
          <IconButton size='small' disabled={disabled} onClick={() => copyText(id)}>
            <ContentCopyIcon fontSize='small' />
          </IconButton>
        </Tooltip>
        <IconButton size='small' onClick={() => handleShowLink()} disabled={disabled}>
          {showLink ? (
            <VisibilityOffIcon fontSize='small' />
          ) : (
            <RemoveRedEyeIcon fontSize='small' />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};
