import React from 'react';

import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';

const FailedChip = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: theme.palette.error.main,
  },
}))(Chip);

const FailedChipOutlined = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}))(Chip);

const PassedChip = withStyles((theme) => ({
  root: {
    color: '#5cb85c',
    borderColor: '#5cb85c',
  },
}))(Chip);

export function getErrorFields(data) {
  // console.log('From Helper Function');
  // console.log(data);
  if (data.is_valid == true) {
    return (
      <Typography display='inline' style={{ fontWeight: '500' }}>
        {data.value.toString()}
      </Typography>
    );
  }
  return (
    <>
      <Typography display='inline' style={{ color: 'red', fontWeight: '500', marginRight: 5 }}>
        {data.value.toString()}
      </Typography>
      <FailedChip
        sx={{
          height: 'auto',
          padding: 1,
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }}
        size='small'
        label={data.message}
        id='failedChip'
      />
    </>
  );
}

export function getValidatedName(first_name, last_name) {
  if (first_name.is_valid === true && last_name.is_valid === true) {
    return <PassedChip variant='outlined' label='Passed' size='small' />;
  }
  return <FailedChip label='Failed' size='small' />;
}

export function getChildValidationStatus(data) {
  if (data === false) {
    return <FailedChip label='Failed' />;
  }
  return <PassedChip variant='outlined' label='Passed' />;
}

// export function getValidationFailedDrivers(drivers) {
//   let total_drivers = drivers.validation_result.length;
//   let failed_drivers = 0;
//   for (let i = 0; i < total_drivers; i = i + 1) {
//     if (drivers.validation_result[i].is_valid === false) {
//       failed_drivers = failed_drivers + 1;
//     }
//   }
//   return failed_drivers;
// }

export function getValidationFailedDrivers(data) {
  const driverList = data?.validation_result;
  const filterFailedDriver = driverList?.filter((item) => item.is_valid === false);
  return filterFailedDriver.length;
}

// export function getValidationPassedDrivers(drivers) {
//   let total_drivers = drivers.validation_result.length;
//   let passed_drivers = 0;
//   for (let i = 0; i < total_drivers; i = i + 1) {
//     if (drivers.validation_result[i].is_valid === true) {
//       passed_drivers = passed_drivers + 1;
//     }
//   }
//   return passed_drivers;
// }

export function getValidationPassedDrivers(data) {
  const driverList = data?.validation_result;
  const filterPassedDriver = driverList?.filter((item) => item.is_valid === true);
  return filterPassedDriver.length;
}

export function getCorrectValidationStatus(status) {
  switch (status) {
    case 'VALIDATION_FAILED':
      return <FailedChipOutlined variant='outlined' label='Verification Failed' />;

    case 'PENDING':
      return <Chip variant='outlined' label='Validation Pending' color='primary' />;

    default:
      return status;
      break;
  }
}

export function getTotalDrivers(drivers) {
  return drivers.validation_result.length;
}
