import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Stack, Typography } from '@mui/material';

// third-party imports
import { Autocomplete } from 'formik-mui';
import { Field } from 'formik';

// project imports
import { languages } from 'utils';
import { inputVariant } from 'constants';

const RenderOptions = ({ props, option }) => {
  return (
    <Box component='li' {...props}>
      <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
        <img
          loading='lazy'
          width='20'
          src={`https://flagcdn.com/w20/${option.code.split('-')[1].toLowerCase()}.png`} // Fetch flag from flagcdn.com based on language code
          srcSet={`https://flagcdn.com/w40/${option.code.split('-')[1].toLowerCase()}.png 2x`} // Fetch flag from flagcdn.com based on language code
          alt=''
        />
        <Typography>{option.name}</Typography>
      </Stack>
    </Box>
  );
};

const LanguageSelector = (props) => {
  const {
    name,
    label,
    touched,
    errors,
    id,
    languageId,
    helperTextContent = '',
    disabled = false,
    textFieldProps = {},
  } = props;

  return (
    <Field
      name={name}
      component={Autocomplete}
      id={languageId}
      disabled={disabled}
      options={languages}
      getOptionLabel={(option) => `${option.name}`}
      renderOption={(props, option) => <RenderOptions props={props} option={option} />}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          name={name}
          label={label}
          error={touched[name] && !!errors[name]}
          helperText={Boolean(errors[name]) ? errors[name] : helperTextContent}
          // variant={inputVariant}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          size='small'
          {...textFieldProps}
        />
      )}
    />
  );
};

LanguageSelector.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

export default LanguageSelector;
