import { Grid, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CountCard({ title, count, dataLoading }) {
  const theme = useTheme();

  return (
    <Grid
      item
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 5,
        p: 4,
        pl: 8,
        pr: 8,
        boxShadow: 5,
        minWidth: 282,
      }}
    >
      <Typography align='center' fontSize={20} variant='body1'>
        {title}
      </Typography>
      {dataLoading ? (
        <Skeleton variant='rectangular' width={210} height={47} />
      ) : (
        <Typography fontSize={30} align='center' variant='body1'>
          {count}
        </Typography>
      )}
    </Grid>
  );
}
