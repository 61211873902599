export function getErrorMessage(error) {
  console.log('ERROR- ', error);
  // Handle the case in which error is undefined
  if (!error) {
    return 'Something went wrong, please try again later.';
  }

  if (
    error.status >= 400 &&
    error.status < 500 &&
    error.data != undefined &&
    error.data.Message != undefined
  ) {
    return error.data.Message;
  }
  if (error.message != undefined) {
    return error.message;
  }
  if (error.status == 500 || error.response == undefined || error.response.data != undefined) {
    return 'Something went wrong, please try again later.';
  }
  return error.response.data.Message;
}
