import React from 'react';

// third party
import { Field, ErrorMessage } from 'formik';

// mui
import { FormControl, FormHelperText, Chip, Grid, Typography } from '@mui/material';

// components
import MainCard from 'components/MainCard';

// assets
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import PersonIcon from '@mui/icons-material/Person';

const ChipsWithFormik = ({ field, form }) => {
  const handleChipClick = (value) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <FormControl sx={{ width: '100%' }} error={form.errors[field.name] && form.touched[field.name]}>
      <MainCard content={false} sx={{ padding: 3 }}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} xs={12}>
          <Grid item xs={5}>
            <Typography variant='h5' sx={{ mr: 2 }}>
              Gender
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'space-between'}>
              <Chip
                label='Male'
                icon={<ManIcon />}
                onClick={() => handleChipClick('male')}
                color={'primary'}
                variant={field.value === 'male' ? 'contained' : 'outlined'}
                clickable
              />
              <Chip
                label='Female'
                icon={<WomanIcon />}
                onClick={() => handleChipClick('female')}
                color={'primary'}
                variant={field.value === 'female' ? 'contained' : 'outlined'}
                clickable
              />
              <Chip
                label='Unspecified'
                icon={<PersonIcon />}
                onClick={() => handleChipClick('notSpecific')}
                color={'primary'}
                variant={field.value === 'notSpecific' ? 'contained' : 'outlined'}
                clickable
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      {form.errors[field.name] && form.touched[field.name] ? null : (
        <FormHelperText> </FormHelperText>
      )}
      <ErrorMessage name={field.name} component={FormHelperText} />
    </FormControl>
  );
};

const GenderChip = ({ name, ...rest }) => {
  return <Field name={name} component={ChipsWithFormik} {...rest} />;
};

export default GenderChip;
