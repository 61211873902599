import { useEffect } from 'react';

// third party imports
import { useNavigate } from 'react-router-dom';
// command - bar import
import { init } from 'commandbar';

// to get latest version of command bar
init('0a19d1f9');

const UseCommandBar = (userId) => {
  const loggedInUserId = userId?.toString() + 'dashboard'; // example
  window.CommandBar.boot(loggedInUserId);

  const navigate = useNavigate();

  useEffect(() => {
    function router(url) {
      navigate(url);
    }

    window.CommandBar.addRouter(router);
  }, [navigate]);
};

export default UseCommandBar;
