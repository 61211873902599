import React, { useState } from 'react';
import { Formik } from 'formik';
import { formInitialValues } from './formModel/formikInitialValue';
import { validationSchemaDevices } from './formModel/validationSchema';
import { Grid, Stack } from '@mui/material';
import { NextButton, SaveButton } from 'components/Buttons';
import DeviceBasic from './form/DeviceBasic';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registerDevice, updateDevice } from 'clients/vehicle';
import { queryKey } from 'react-query/queryKey';
import { req_factory_device } from './formModel/apiBody';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
const AddEditDevicesForm = ({ isEditing, closeDialog, ...props }) => {
  const { selectedDevicesData } = props;
  const [activeStep, setActiveStep] = useState(0);
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const currentValidationSchema = validationSchemaDevices[activeStep];
  const isLastStep = activeStep === 0;
  // const initialStudentData = formInitialValues();

  const _renderStepContent = (step, formikProps, props) => {
    switch (step) {
      case 0:
        return <DeviceBasic formikProps={formikProps} {...props} />;
      default:
        return <div>Not Found</div>;
    }
  };

  const registerDeviceMutation = useMutation({
    mutationFn: (body) => {
      return registerDevice(body.request_schema);
    },
    onSuccess: () => {
      enqueueSnackbar('Device registered successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries([queryKey.DEVICE.ALL_DEVICES]);
    },
    onError: (error) => {
      // enqueueSnackbar(getErrorMessage(error), {
      //   variant: 'error',
      // });
    },
  });
  const updateDeviceMutation = useMutation({
    mutationFn: (body) => {
      return updateDevice(body.request_schema);
    },
    onSuccess: () => {
      enqueueSnackbar('Device updated successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries([queryKey.DEVICE.ALL_DEVICES]);
    },
    onError: (error) => {
      // enqueueSnackbar(getErrorMessage(error), {
      //   variant: 'error',
      // });
    },
  });

  const _handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const { driverapp, gps_tracking, attendance } = values;
    if (driverapp || gps_tracking || attendance) {
      if (!isEditing) {
        // register
        const request_schema = req_factory_device({ values });
        try {
          await registerDeviceMutation.mutateAsync({ request_schema });
          actions.setSubmitting(false);
          closeDialog();
        } catch (error) {
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
          });
        }
      } else {
        const request_schema = req_factory_device({ values, isEditing, selectedDevicesData });
        try {
          await updateDeviceMutation.mutateAsync({ request_schema });
          actions.setSubmitting(false);
          closeDialog();
        } catch (error) {
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
          });
        }

      }
    }
    // actions.setSubmitting(true);
    else {
      actions.setSubmitting(false);
    }
  };
  const handleResetForm = (resetForm) => {
    resetForm();
  };
  const handleValidate = (values, actions) => {};
  return (
    <div>
      <Formik
        initialValues={formInitialValues({ isEditing, selectedDevicesData })}
        validationSchema={currentValidationSchema}
        validate={handleValidate}
        onSubmit={_handleSubmit}
        enableReinitialize
        resetForm={handleResetForm}
      >
        {(formikProps) => {
          return (
            <div>
              {/* Stepper use for future */}
              <Grid item xs={12}>
                {_renderStepContent(activeStep, formikProps, props)}
              </Grid>
              <Stack direction='row' justifyContent={'flex-end'} sx={{ pt: 2 }}>
                {isLastStep ? (
                  <SaveButton
                    id='save_device'
                    loading={formikProps.isSubmitting}
                    size={'medium'}
                    type='submit'
                    sx={{ width: 'min-content' }}
                    onClick={formikProps.submitForm}
                  >
                    {!isEditing ? 'Save' : 'Update'}
                  </SaveButton>
                ) : (
                  <NextButton id='next' size={'medium'} type='submit'>
                    Next
                  </NextButton>
                )}
              </Stack>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEditDevicesForm;
