import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  FILTER_LIST_REQUEST,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_FAIL,
  SEARCH_INPUT_VALUE_CHANGE,
  UPDATE_DATA,
} from '../reduxConstants/searchConstants';
import { getSearchData, getSearchFilter } from '../clients/SpotBus';
import { getErrorMessage } from '../containers/layouts/Utils/ErrorHandling';

export const searchSchool =
  (
    headerSearchTerm,
    category,
    query,
    filters,
    reducerKey,
    isFilterOrPagination = 'filter',
    starting_after = 0,
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCH_REQUEST });
      if (!headerSearchTerm && isFilterOrPagination === 'filter') {
        console.log('Filter Called--', category, query, filters);
        const filterCall = await getSearchData(category, query, filters);
        console.log('School Filter Call', filterCall);
        dispatch({ type: SEARCH_SUCCESS, payload: filterCall, isFilter: true, reducerKey });
      } else if (!headerSearchTerm && isFilterOrPagination === 'pagination') {
        console.log('Paginated Called--', category, query, filters);
        const filterCall = await getSearchData(category, query, filters, starting_after);
        console.log('School Filter Call', filterCall);
        dispatch({ type: SEARCH_SUCCESS, payload: filterCall, isPagination: true, reducerKey });
      } else if (headerSearchTerm) {
        const schoolCall = getSearchData('schools', headerSearchTerm, {});
        const driverCall = getSearchData('drivers', headerSearchTerm, {});
        const routesCall = getSearchData('routes', headerSearchTerm, {});
        const vehiclesCall = getSearchData('vehicles', headerSearchTerm, {});
        const childsCall = getSearchData('childs', headerSearchTerm, {});
        const tripsCall = getSearchData('trips', headerSearchTerm, {});

        const results = await Promise.all([
          schoolCall,
          driverCall,
          routesCall,
          vehiclesCall,
          childsCall,
          tripsCall,
        ]);

        dispatch({ type: SEARCH_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log('Error from getting search Data', error);
      dispatch({ type: SEARCH_FAIL, payload: getErrorMessage(error) });
    }
  };

export const callGetSearchFilter = (headerSearchTerm) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_LIST_REQUEST });
    const tripFilters = getSearchFilter('trips', headerSearchTerm);
    const schoolFilters = getSearchFilter('schools', headerSearchTerm);
    const studentFilters = getSearchFilter('childs', headerSearchTerm);
    const routeFilters = getSearchFilter('routes', headerSearchTerm);
    const driverFilters = getSearchFilter('drivers', headerSearchTerm);
    const vehicleFilters = getSearchFilter('vehicles', headerSearchTerm);
    const results = await Promise.all([
      tripFilters,
      schoolFilters,
      studentFilters,
      routeFilters,
      driverFilters,
      vehicleFilters,
    ]);
    console.log('Search filters result', results);
    dispatch({ type: FILTER_LIST_SUCCESS, payload: results });
  } catch (error) {
    console.log('Error from getting search Data', error);
    dispatch({ type: FILTER_LIST_FAIL, payload: getErrorMessage(error) });
  }
};

export const handleSearchInputValue = (value) => {
  return { type: SEARCH_INPUT_VALUE_CHANGE, payload: value };
};

  export const handleUpdateData = (reducerKey, data) => {
  return {
    type: UPDATE_DATA,
    payload: { updatedData: data },
    reducerKey: reducerKey
  };
};
