import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import SchoolIcon from '@mui/icons-material/School';

// project imports
import { stringAvatar } from 'utils';

// ============================== SCHOOL INFO CARD = CARD ============================== //

function SchoolInfoCard({ name, abbreviation, address, src, onClick, completedSchool }) {
  const theme = useTheme();

  const fallbackImage = !src ? stringAvatar(name) : '';
  const carBgColor = completedSchool ? 'success.main' : 'primary.main';
  const rightCardBorder = completedSchool ? theme.palette.success.main : theme.palette.primary.main;

  return (
    <Card
      sx={{
        display: 'flex',
        borderRight: `6px solid ${rightCardBorder}`,
        transition: `${theme.transitions.create(['border-right', 'transform'])}`,
        '&:hover': {
          borderRight: `16px solid ${rightCardBorder}`,
        },
      }}
    >
      {/* <CardMedia component='img' sx={{ width: 140, height: 120, ...fallbackImage }} src={src} /> */}
      <Card sx={{ width: 140, height: 120, bgcolor: carBgColor }}>
        <Grid>
          <Typography
            variant='h4'
            sx={{
              color: 'white',
              pt: 9,
              pl: 8,
              'z-index': '1500',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            component='div'
          >
            {abbreviation}
          </Typography>
        </Grid>

        <Grid>
          <SchoolIcon
            sx={{
              fontSize: '100px',
              ml: -8,
              mt: -5,
              'z-index': '1100',
              color: 'white',
              opacity: 0.7,
            }}
          />
        </Grid>
      </Card>
      <CardActionArea onClick={onClick}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component='div' variant='subtitle1' color='text.secondary'>
              {name}
            </Typography>
            <Typography component='div' variant='h4'>
              {abbreviation} {'   '}
              {completedSchool && <Chip color='success' size='small' label='Completed' />}
            </Typography>
            <Typography variant='body2' color='text.secondary' component='div'>
              {address}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
}

SchoolInfoCard.propTypes = {};

export default SchoolInfoCard;
