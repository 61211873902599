import React, { Component } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Container from '@mui/material/Container';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Auth } from 'aws-amplify';
import VerificationCodeTextField from '../../../components/Form/VerificationCodeTextField';

const useStyles = createStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
      margin: theme.spacing(5)
  },
}));

const ERR_MESSAGES = {
  "UserNotConfirmedException": "Account confirmation pending, please contact support@spotbus.us",
  "InvalidParameterException": "Please correct invalid fields in form and try again.",
  "LimitExceededException": "Limit exceeded exception, please try again later.",
  "ELSE": "Something went wrong, please try again later."
}


class VerifyEmailByCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      verificationCode: undefined,
      isVerificationCodeValidated:false,
      isLoading: false,
      showErrorSnackBar: false,
      error: undefined,
      showConfirmationCode: false
    }

    this.onVerifyEmailClicked.bind(this)
  }

  getErrorMessage(errorCode){
      if (errorCode in ERR_MESSAGES){
          return ERR_MESSAGES[errorCode]
      }

      return ERR_MESSAGES["ELSE"]
  }

  onVerifyEmailClicked(event){
    event.preventDefault(); 
    this.setState({
        isLoading: true
      })

    Auth.confirmSignUp(this.props.username, this.state.verificationCode)
        .then(data => {
            this.setState({
                isLoading: false,
                showConfirmationCode: true,
                successMessage: "Password recovery code sent to your registered email address.",
                showSuccessSnackBar: true
              })
            this.props.onSuccess()
        })
        .catch(err => {
            this.setState({
                error: this.getErrorMessage(err.code),
                isLoading: false,
                showErrorSnackBar:true
              })
            
        })
    
  }
  render() {
      const classes = this.props.classes
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>

            <Typography component="h1" variant="h5" align={"left"}>
              Verify your email address
            </Typography>
            
            <form className={classes.form} noValidate onSubmit={(event) => {event.preventDefault(); }}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Snackbar 
                    open={this.state.showErrorSnackBar} 
                    autoHideDuration={6000} 
                    onClose={(e) => {this.setState({showErrorSnackBar: false})}}>
                        <MuiAlert  
                          elevation={6} 
                          variant="filled" 
                          onClose={(e) => {this.setState({showErrorSnackBar: false})}} 
                          severity="error">
                            {this.state.error}
                        </MuiAlert>
                  </Snackbar>
                  </Grid>

                  <Grid item xs={12}>

                    <Snackbar 
                        open={this.state.showSuccessSnackBar} 
                        autoHideDuration={6000} 
                        onClose={(e) => {this.setState({showSuccessSnackBar: false})}}>
                            <MuiAlert  
                            elevation={6} 
                            variant="filled" 
                            onClose={(e) => {this.setState({showSuccessSnackBar: false})}} 
                            severity="success">
                                {this.state.successMessage}
                            </MuiAlert>
                    </Snackbar>
                  </Grid>
    
                  <Grid item xs={12} sm={12}>
                      <VerificationCodeTextField
                        onChange={(e) => {this.setState({verificationCode: e.target.value})}}
                        value={this.state.verificationCode}
                        id="verificationcode"
                        name="verificationcode"
                        validationstatushandler={(st) => {this.setState({isVerificationCodeValidated: st})}}
                        disabled={this.state.isLoading}
                      />
    
                  </Grid>
              </Grid>
              
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!(this.state.isVerificationCodeValidated) || this.state.isLoading}
                onClick={this.onVerifyEmailClicked.bind(this) }
              >
                Verify Email Address
              </Button>
    
            </form>
          </div>
        </Container>
      );
    }

}


export default withStyles(useStyles)(VerifyEmailByCode);