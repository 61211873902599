import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';

// assets
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// ============================== Reset Button ============================== //

function ResetButton({ children, ...props }) {
  return (
    <Button {...props} startIcon={<RestartAltIcon />}>
      {children.toUpperCase()}
    </Button>
  );
}

ResetButton.defaultProps = {
  variant: 'outlined',
  size: 'large',
  color: 'primary',
};
ResetButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string,
};

export default ResetButton;
