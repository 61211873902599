const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function getData(timestamp) {
  const date = new Date(timestamp * 1000);
  const [month, day, year] = [months[date.getMonth()], date.getDate(), date.getFullYear()];
  return `${day} ${month} ${year}`;
}

export function formatAMPM(timestamp) {
  const date = new Date(timestamp * 1000);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function formatAMPMString(timeString) {
  if (typeof timeString !== 'string') {
    return '--';
  }
  
  const [hours = 0, minutes = 0, seconds = 0] = timeString.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  const ampm = date.getHours() >= 12 ? 'pm' : 'am';
  let formattedHours = date.getHours() % 12;
  formattedHours = formattedHours ? formattedHours : 12;
  const formattedMinutes = date
    .getMinutes()
    .toString()
    .padStart(2, '0');
  const strTime = formattedHours + ':' + formattedMinutes + ' ' + ampm;
  return strTime;
}
