import React from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

// project imports
import { drawerWidth } from 'constants';
import AppRouter from 'routes';
import ScrollTop from 'components/ScrollTop';
import Breadcrumbs from 'components/BreadcrumbsNew';
import menuItems from 'menus/DrawerMenu/index';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

// ============================== CONTENT - DEFAULT ============================== //

function MainContent(props) {
  const theme = useTheme();
  const drawerOpen = props.drawerOpen;

  console.log(menuItems)

  return (
    <Main open={drawerOpen} sx={{overflow: 'auto', colorScheme: theme.palette.mode}}>
      <DrawerHeader />
      <ScrollTop>
        <Breadcrumbs navigation={menuItems} title titleBottom card={false} divider={false} />
        <AppRouter {...props} />
      </ScrollTop>
      <Backdrop
        sx={{ zIndex: theme.zIndex.drawer + 100, color: '#fff' }}
        open={props.isLoading}
        onClick={() => props.setIsLoading(false)}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {/**
       * Water mark temporary commented
       */}
      {/* <Box pt={4}>
        <Copyright />
      </Box> */}
    </Main>
  );
}

export default MainContent;
