import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
/**
 * Renders an icon with text.
 *
 * @param {Object} iconProps - Props for the icon component.
 * @param {Object} titleProps - Props for the title component.
 * @param {React.ComponentType} icon - Icon component to render.
 * @param {string} title - Title to render.
 * @param {Object} props - Props for the Stack component.
 * @return {JSX.Element} A JSX element.
 */
const IconWithText = ({
  icon = LinkIcon,
  iconProps = {},
  titleProps = {},
  title = '--',
  toolTipTitle = '',
  ...props
}) => {
  const Icon = icon || LinkIcon;
  return (
    <Tooltip title={toolTipTitle}>
      <Stack direction='row' alignItems={'center'} {...props}>
        <Icon color='primary' {...iconProps} />
        <Typography sx={{ ml: 1 }} {...titleProps}>
          {title}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default IconWithText;
