import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

// assets
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Badge button styling
const badgeButtonSx = {
  backgroundColor: 'background.paper',
  border: '2px solid',
  borderColor: 'primary',
  '&:hover': {
    backgroundColor: 'background.paper',
  },
};

// Badge content component
const BadgeContent = () => {
  return (
    <label htmlFor='button-image'>
      <input accept='image/*' disabled style={{ display: 'none' }} id='button-image' type='file' />
      <IconButton
        color='primary'
        aria-label='upload picture'
        component='span'
        id='uploadImage'
        size='small'
        sx={badgeButtonSx}
      >
        {/* {values[name] ? <EditIcon /> :  */}
        <PhotoCameraIcon fontSize='small' />
        {/* } */}
      </IconButton>
    </label>
  );
};

// ============================== CUSTOM IMAGE COMPONENT (Uses formik)  ============================== //

function CustomImageInput(props) {
  // props extraction

  // generate preview image
  const generatePreviewImage = (imageFile) => {
    if (imageFile && typeof imageFile === 'object') {
      let tempURL = URL.createObjectURL(imageFile);
      return tempURL;
    }
    return imageFile;
  };
  const theme = useTheme();

  // Avatar styling
  const avatarSx = {
    width: '70px',
    height: '70px',
    border: '2px solid' + theme.palette.primary.main,
    boxShadow: '10px',
  };
  return (
    <FormControl sx={{ alignItems: 'center' }}>
      <Badge
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<BadgeContent />}
      >
        <Avatar sx={avatarSx} variant={props.avatarVariant} />
      </Badge>
    </FormControl>
  );
}

CustomImageInput.defaultProps = {
  avatarVariant: 'circular',
};

CustomImageInput.propTypes = {
  avatarVariant: PropTypes.oneOf(['circular', 'square', 'rounded']),
  field: PropTypes.object,
  form: PropTypes.object,
};

{
  /*
* TODO: Component can further be extended to support more props few examples
  Avatar fallback cases
  Avatar shape and badge improvements
  Delete image functionality either on hover on badge button click 
*/
}

export default CustomImageInput;
