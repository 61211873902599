import React from "react";

import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Chip from "@mui/material/Chip";

const FailedChip = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: theme.palette.error.main,
    },
}))(Chip);

const FailedChipOutlined = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}))(Chip);

const PassedChip = withStyles((theme) => ({
    root: {
        color: "#5cb85c",
        borderColor: "#5cb85c",
    },
}))(Chip);


export function getErrorFields(data) {
    if (data.is_valid == true) {
        return (
            <Typography display="inline" style={{fontWeight: '500'}}>{data.value.toString()}</Typography>
        ) 
    }
    return (
        <>
            <Typography display="inline" style={{color: 'red', fontWeight: '500', marginRight: 5}}>
                {data.value.toString()}
            </Typography>
            <FailedChip size="small" id='failedChip' label={data.message} />
        </>
    );
}

export function getValidatedName(first_name, last_name) {
    if (first_name.is_valid === true && last_name.is_valid === true) {
        return <PassedChip variant="outlined" label="Passed" size="small" />;
    }
    return <FailedChip label="Failed" size="small" />;
}

export function getChildValidationStatus(data) {
    // const fields = [
    //     'first_name',
    //     'last_name',
    //     'parent_id',
    //     'school_id',
    //     'route_id_am',
    //     'stop_id_am',
    //     'route_id_pm',
    //     'stop_id_pm',
    //     'special_needs',
    //     'fathers_first_name',
    //     'fathers_last_name',
    //     'mothers_first_name',
    //     'mothers_last_name',
    //     'driver_notes',
    //     'school_notes',
    // ]
    
    // for (let i = 0; i < fields.length; i = i + 1) {
    //         if(data[fields[i]].is_valid === false) {
    //             return <FailedChip label="Failed" size="small" />
    //             break;
    //         }
    // }
    if (data === false) {
        return <FailedChip label="Failed"  />
    }
    return <PassedChip variant="outlined" label="Passed" />
}

export function getValidationFailedStudents(students) {
    let total_students = students.validation_result.length
    let failed_Students = 0;
    for (let i = 0; i < total_students; i = i+1) {
        if (students.validation_result[i].is_valid === false) {
            failed_Students = failed_Students + 1
        }
    }
    return failed_Students
}

export function getValidationPassedStudents(students) {
    let total_students = students.validation_result.length
    let passed_Students = 0;
    for (let i = 0; i < total_students; i = i+1) {
        if (students.validation_result[i].is_valid === true) {
            passed_Students = passed_Students + 1
        }
    }
    return passed_Students
}

export function getCorrectValidationStatus(status) {
    switch (status) {
        case 'VALIDATION_FAILED':
            return <FailedChipOutlined variant="outlined" label="Verification Failed" />
    
        case 'PENDING':
            return <Chip variant="outlined" label="Validation Pending" color="primary" />
    
        default:
            return status
            break;
    }
}

export function getTotalStudents(students) {
    return students.validation_result.length
}
