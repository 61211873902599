export const TRIP_INSPECTION_TYPE = {
  PRE_TRIP: 'PRE_TRIP',
  POST_TRIP: 'POST_TRIP',
};

export const TRIP_INSPECTION_TYPE_LABEL = {
  PRE_TRIP: 'Pre Trip Inspection',
  POST_TRIP: 'Post Trip Inspection',
};

export const TRIP_INSPECTION_REPORT_STATUS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN PROGRESS',
  CRITICAL_FAILED: 'CRITICAL FAILURE',
};

export const TRIP_INSPECTION_REPORT_STATUS_LABEL = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  CRITICAL_FAILED: 'Critical Failure',
};

export const TRIP_INSPECTION_REPORT_FILTER_TYPE = {
  VEHICLE_ID: 'vehicle_id',
  STATUS: 'status',
  SUBMITTED_BY: 'submitted_by',
  INSPECTION_TYPE: 'type',
};

export const TRIP_INSPECTION_REPORT_STATUS_LIST = [
  {
    label: TRIP_INSPECTION_REPORT_STATUS_LABEL.PASSED,
    value: TRIP_INSPECTION_REPORT_STATUS.PASSED,
  },
  {
    label: TRIP_INSPECTION_REPORT_STATUS_LABEL.FAILED,
    value: TRIP_INSPECTION_REPORT_STATUS.FAILED,
  },
  {
    label: TRIP_INSPECTION_REPORT_STATUS_LABEL.PENDING,
    value: TRIP_INSPECTION_REPORT_STATUS.PENDING,
  },
  {
    label: TRIP_INSPECTION_REPORT_STATUS_LABEL.IN_PROGRESS,
    value: TRIP_INSPECTION_REPORT_STATUS.IN_PROGRESS,
  },
  {
    label: TRIP_INSPECTION_REPORT_STATUS_LABEL.CRITICAL_FAILED,
    value: TRIP_INSPECTION_REPORT_STATUS.CRITICAL_FAILED,
  },
];

export const TRIP_INSPECTION_TYPE_LIST = [
  {
    label: TRIP_INSPECTION_TYPE_LABEL.PRE_TRIP,
    value: TRIP_INSPECTION_TYPE.PRE_TRIP,
  },
  {
    label: TRIP_INSPECTION_TYPE_LABEL.POST_TRIP,
    value: TRIP_INSPECTION_TYPE.POST_TRIP,
  },
];

/**
 * Retrieves the label corresponding to a trip inspection type value.
 * @param {string} typeValue - The value representing the trip inspection type.
 * @returns {string} - The label corresponding to the trip inspection type value.
 */
export function getInspectionTypeLabel(typeValue) {
  switch (typeValue) {
    case TRIP_INSPECTION_TYPE.PRE_TRIP:
      return TRIP_INSPECTION_TYPE_LABEL.PRE_TRIP;
    case TRIP_INSPECTION_TYPE.POST_TRIP:
      return TRIP_INSPECTION_TYPE_LABEL.POST_TRIP;
    default:
      return '--';
  }
}
/**
 * Retrieves the label corresponding to an inspection status value.
 * @param {string} statusValue - The value representing the inspection status.
 * @returns {string} - The label corresponding to the inspection status value.
 */
export function getInspectionStatusLabel(statusValue) {
  switch (statusValue) {
    case TRIP_INSPECTION_REPORT_STATUS.PASSED:
      return TRIP_INSPECTION_REPORT_STATUS_LABEL.PASSED;
    case TRIP_INSPECTION_REPORT_STATUS.FAILED:
      return TRIP_INSPECTION_REPORT_STATUS_LABEL.FAILED;
    case TRIP_INSPECTION_REPORT_STATUS.CRITICAL_FAILED:
      return TRIP_INSPECTION_REPORT_STATUS_LABEL.CRITICAL_FAILED;
    case TRIP_INSPECTION_REPORT_STATUS.IN_PROGRESS:
      return TRIP_INSPECTION_REPORT_STATUS_LABEL.IN_PROGRESS;
    case TRIP_INSPECTION_REPORT_STATUS.PENDING:
      return TRIP_INSPECTION_REPORT_STATUS_LABEL.PENDING;
    default:
      return '--';
  }
}

export function getInspectionStatusColor(statusValue) {
  switch (statusValue) {
    case TRIP_INSPECTION_REPORT_STATUS.FAILED:
      return 'error';
    case TRIP_INSPECTION_REPORT_STATUS.CRITICAL_FAILED:
      return 'error';
    case TRIP_INSPECTION_REPORT_STATUS.PENDING:
      return 'secondary';
    case TRIP_INSPECTION_REPORT_STATUS.IN_PROGRESS:
      return 'secondary';
    case TRIP_INSPECTION_REPORT_STATUS.PASSED:
      return 'success';
    default:
      return 'secondary';
  }
}

/**
 * Compares the time taken with the allowed time.
 * @param {number|string} timeTaken - The time taken (in format "HH:MM" or "HH" as number or string).
 * @param {number|string} allowedTime - The allowed time (in format "HH:MM" or "HH" as number or string).
 * @returns {string} - Returns "error" if the allowed time is greater, otherwise returns an empty string.
 */
export function compareTimeTakenAndAllowed(timeTaken, allowedTime) {
  // Convert timeTaken and allowedTime to strings
  const timeTakenStr = String(timeTaken);
  const allowedTimeStr = String(allowedTime);

  // Split timeTaken and allowedTime into hours and minutes
  const [hoursTaken] = timeTakenStr.split(':').map(Number);
  const [hoursAllowed] = allowedTimeStr.split(':').map(Number);

  // Compare totalTimeTaken with totalTimeAllowed
  if (hoursTaken > hoursAllowed) {
    return 'error';
  } else {
    return '';
  }
}

/**
 * Retrieves the license plate number of a vehicle based on its ID.
 * @param {Array} vehicleData - An array of objects containing vehicle information.
 * @param {string} vehicleID - The ID of the vehicle whose license plate number is to be retrieved.
 * @returns {string} - The license plate number of the vehicle if found, otherwise "--".
 */
export function getVehiclePlateName(vehicleData, vehicleID) {
  // Check if vehicleData array is empty or undefined
  if (!vehicleData || vehicleData.length === 0) {
    return '--'; // Return "--" if vehicleData is empty or undefined
  }

  // Find the vehicle object with the specified ID
  const findSelectedVehicle = vehicleData.find((ele) => ele.vehicle_id === vehicleID);

  // Check if the selected vehicle is found
  if (!findSelectedVehicle) {
    return '--'; // Return "--" if selected vehicle is not found
  }

  // Return the license plate number of the found vehicle
  return findSelectedVehicle.licence_plate_number;
}

/**
 * Retrieves the name of a driver based on their ID.
 * @param {Array} driverData - An array of objects containing driver information.
 * @param {string} driverId - The ID of the driver whose name is to be retrieved.
 * @returns {string|null} - The name of the driver if found, otherwise null.
 */
export function getDriverNameByDriverID(driverData, driverId) {
  // Check if driverData array is empty or undefined
  if (!driverData || driverData.length === 0) {
    return '--'; // Return null if driverData is empty or undefined
  }

  // Find the driver object with the specified ID
  const findSelectedDriverData = driverData.find((ele) => ele.driver_username === driverId);

  // Check if the selected driver is found
  if (!findSelectedDriverData) {
    return '--'; // Return null if selected driver is not found
  }

  // Return the name of the found driver
  return `${findSelectedDriverData.first_name} ${findSelectedDriverData.last_name}`;
}

export function convertResponsesStaticsToString(data) {
  let result = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let formattedKey;
      switch (key) {
        case TRIP_INSPECTION_REPORT_STATUS.PASS:
          formattedKey = TRIP_INSPECTION_REPORT_STATUS_LABEL.PASS;
          break;
        case TRIP_INSPECTION_REPORT_STATUS.FAIL:
          formattedKey = TRIP_INSPECTION_REPORT_STATUS_LABEL.FAIL;
          break;
        case TRIP_INSPECTION_REPORT_STATUS.AT_RISK:
          formattedKey = TRIP_INSPECTION_REPORT_STATUS_LABEL.AT_RISK;
          break;
        case TRIP_INSPECTION_REPORT_STATUS.NOT_APPLICABLE:
          formattedKey = TRIP_INSPECTION_REPORT_STATUS_LABEL.NOT_APPLICABLE;
          break;
        default:
          formattedKey = key;
      }
      result.push(`${formattedKey}: ${data[key]}`);
    }
  }
  return result.join(', ');
}

/**
 * Returns the initials of a given name.
 *
 * @param {string} name - The full name from which to extract initials.
 * @returns {string} The initials extracted from the name. If the name is falsy, returns '--'.
 */
export default function getInitials(name) {
  if (!name) {
    return '--';
  }
  const words = name.split(' ');

  let initials = '';

  for (let i = 0; i < words.length; i++) {
    // Get the first letter of each word and convert it to uppercase
    initials += words[i].charAt(0).toUpperCase();
  }

  // Return the initials
  return initials;
}
