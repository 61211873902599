import { useQuery } from '@tanstack/react-query';
import { getInspectionReportImage } from 'clients/SpotBus';
// third-party imports
import { useSnackbar } from 'notistack';
import { queryKey } from 'react-query/queryKey';

/**
 * Custom React hook to fetch image data for a given image URL and inspection report ID.
 *
 * @param {Object} options - Options for the image query.
 * @param {string} options.customQueryKey - Custom query key for useQuery hook.
 * @param {string} options.image_url - The URL of the image to fetch.
 * @param {string} options.inspectionReportID - The ID of the inspection report associated with the image.
 * @returns {Object} An object containing the fetched image URL and loading state.
 */

export const useImageQuery = ({
  customQueryKey = null,
  image_url = '',
  inspectionReportID = '',
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Use custom query key if provided, otherwise use the default one
  const queryKeyArray = customQueryKey || [queryKey.DEFAULT_IMAGE, image_url];

  // Use useQuery hook to fetch data and handle errors
  const { data: getImageUrl, isLoading: isLoadingImage } = useQuery({
    queryKey: queryKeyArray,
    queryFn: () => getInspectionReportImage(inspectionReportID, image_url),
    onError: (error) => {
      console.error('Error while getting image url', error);
    },
  });

  // Return the necessary data or functions
  return {
    getImageUrl,
    isLoadingImage,
  };
};
