export function getGreeting() {
  const time = new Date().getHours();
  let greeting;
  if (time < 12) {
    greeting = 'Good Morning';
  } else if (time >= 12 && time <= 17) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }
  return greeting;
}
