import { countries, defaultCountry } from 'utils';

export const formInitialValues = ({ isEditing, selectedDevicesData }) => {
  const getCountryObject = (code) => {
    if (!code) {
      return defaultCountry();
    }
    return countries.find((country) => country?.phone === code);
  };

  return {
    device_type: isEditing ? selectedDevicesData.device_type : '',
    device_model: isEditing ? selectedDevicesData.device_model : '',
    device_id: isEditing ? selectedDevicesData.device_id : '',
    manufacturer: isEditing ? selectedDevicesData.manufacturer : '',
    carrier: isEditing ? selectedDevicesData.carrier : '',
    sim_number: isEditing ? selectedDevicesData.sim_number : '',
    device_phone_code: isEditing
      ? getCountryObject(selectedDevicesData?.phone_number?.country_code)
      : defaultCountry(),
    device_phone_number: isEditing ? selectedDevicesData?.phone_number?.number : '',
    driverapp: isEditing ? selectedDevicesData.capabilities?.driverapp : false,
    gps_tracking: isEditing ? selectedDevicesData.capabilities?.gps_tracking : false,
    attendance: isEditing ? selectedDevicesData.capabilities?.attendance : false,
    status: isEditing ? (selectedDevicesData.status === 'active' ? true : false) : false,
  };
};
