import React, { useState, useEffect } from 'react';

// third-party
import { useSelector } from 'react-redux';

// material-ui
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

// APIs
import {
  getAccountInformation,
  getAllSchoolsPerAdminTransportationUsername,
} from 'clients/SpotBus';

// project imports
import MainNavbar from './Header';
import MainContent from './Content';
import MainDrawer from 'layouts/Drawer';
import * as Constants from 'constants';
import avatarMenuItems from 'menus/avatarmenu';
import Help from 'containers/layouts/Help/Help';
import UseCommandBar from 'UseCommandBar';
// third party imports
import { useNavigate } from 'react-router-dom';

// contexts and custom hooks
import { DrawerProvider } from 'contexts';

// ============================== LAYOUT - MAIN FILE ============================== //

export default function IMLayout(props) {
  const navigate = useNavigate();
  const sessionId = localStorage.getItem('currentSessionId');
  const { currentSessionData } = useSelector((state) => state.globals);

  // Drawer state variables
  const [open, setOpen] = React.useState(true);
  const [helpOpen, setHelpOpen] = React.useState(false);

  // Temp state for controlling map rendering of google or open Street;
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [accountInformation, setAccountInformation] = useState('');

  // School state
  const [schoolsData, setSchoolsData] = useState([]);

  //Weather City
  const [defaultWeatherCity, setDefaultWeatherCity] = useState('London');
  const [selectedWeatherCity, setSelectedWeatherCity] = React.useState();

  // Route Planning Variables
  const [isRoutePlanningInProgress, setIsRoutePlanningInProgress] = useState(false);
  const [routePlanningProgress, setRoutePlanningProgress] = useState(0);

  // Global loader from search for skeleton
  const [isDataLoading, setIsDataLoading] = useState(false);

  // Controlling global backdrop or loader
  const [isLoading, setIsLoading] = useState(false);

  // session change
  const [reRenderMainContent, setReRenderMainContent] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  async function getLoggedInUserAccountInformation() {
    const response = await getAccountInformation();
    localStorage.setItem('loggedUserRoleType', response['user_permissions']['role_type']);
    setAccountInformation(response);
  }

  useEffect(() => {
    const temp = localStorage.getItem('isPremiumUser');
    if (temp === 'true') {
      localStorage.setItem('isPremiumUser', 'true');
      setIsPremiumUser(true);
    } else {
      localStorage.setItem('isPremiumUser', 'false');
    }
    getLoggedInUserAccountInformation();
    return () => {
      setAccountInformation('');
    };
  }, []);

  useEffect(() => {
    setReRenderMainContent(false);
    setTimeout(() => {
      setReRenderMainContent(true);
    }, 10);
  }, [sessionId]);

  const handleOpenDrawer = () => {
    setHelpOpen(!helpOpen);
  };

  const handleChange = () => {
    const temp = localStorage.getItem('isPremiumUser');
    if (temp === 'true') {
      localStorage.setItem('isPremiumUser', 'false');
      setIsPremiumUser(false);
    } else {
      localStorage.setItem('isPremiumUser', 'true');
      setIsPremiumUser(true);
    }
  };

  async function loadSchools() {
    setIsLoading(true);
    try {
      const result = await getAllSchoolsPerAdminTransportationUsername(props.username);
      setSchoolsData(result);
      // Setting default city for weather widget
      setDefaultWeatherCity(result?.[0].address.city);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadSchools();

    // removing command bar
    // ? Uncomment this return statement to use command bar
    // return () => {
    //   // window.CommandBar.shutdown();
    // };
  }, []);

  // calling command bar
  // ? Uncomment this statement to use command bar
  // UseCommandBar(accountInformation?.dashboard_user_username);

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <DrawerProvider>
          <CssBaseline />
          <MainNavbar
            isPremiumUser={isPremiumUser}
            setIsPremiumUser={handleChange}
            appTitle={Constants.APP_TITLE}
            avatarMenuItems={avatarMenuItems}
            toggleDrawer={handleDrawerToggle}
            drawerOpen={open}
            defaultWeatherCity={defaultWeatherCity}
            selectedWeatherCity={selectedWeatherCity}
            accountInformation={accountInformation}
            {...props}
          />

          <MainDrawer open={open} handleDrawerToggle={handleDrawerToggle} />

          {reRenderMainContent && (
            <MainContent
              isPremiumUser={isPremiumUser}
              setIsPremiumUser={handleChange}
              drawerOpen={open}
              username={props.username}
              schoolsData={schoolsData}
              helpOpen={helpOpen}
              setHelpOpen={handleOpenDrawer}
              setSelectedWeatherCity={(city) => setSelectedWeatherCity(city)}
              isRoutePlanningInProgress={isRoutePlanningInProgress}
              setIsRoutePlanningInProgress={setIsRoutePlanningInProgress}
              routePlanningProgress={routePlanningProgress}
              setRoutePlanningProgress={setRoutePlanningProgress}
              accountInformation={accountInformation}
              isDataLoading={isDataLoading}
              setIsDataLoading={setIsDataLoading}
              isLoading={isLoading}
              setIsLoading={(value) => setIsLoading(value)}
              {...props}
            />
          )}
        </DrawerProvider>
      </Box>
      <Help helpOpen={helpOpen} setHelpOpen={handleOpenDrawer} />
    </>
  );
}
