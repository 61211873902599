import styled, { css, createGlobalStyle } from 'styled-components';

export const Button = styled.button`
  background-color: ${(props) => props._color};
  border: none;
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  z-index: 9999999;
  cursor: pointer;
  position: fixed;

  bottom: 10px;
  right: 31px;

  ${(props) =>
    props.widget_pos == 'left' &&
    css`
      right: 340px;
    `}

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.99;
    transform: scale(1.1);
    transition: 0.1s all ease-in-out;
  }
  transition: 0.1s all ease-in-out;

  &:active {
    opacity: 1;
    transform: scale(1);
    transition: 0.1s all ease-in-out;
  }
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
`;

export const MainChatAppContainer = styled.div`
  position: fixed;
  bottom: 70px;
  right: 35px;
  border-radius: 25px;
  z-index: 9999999;
 

  ${(props) =>
    props.widget_pos === 'left' &&
    css`
      right: 20px;
    `}
    
  display: flex;
  transition: 0.2s all ease-in-out;
  
  ${(props) =>
    props.open &&
    css`
      transform: scale(1);
    `}
  
  ${(props) =>
    !props.open &&
    css`
      transform: scale(0);
    `}

  ${(props) =>
    props.theme === 'dark' &&
    css`
      background-color: #333;
      color: #fff;
    `}
  
  ${(props) =>
    props.theme === 'light' &&
    css`
      background-color: #fff;
      color: #333;
    `}
`;

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    /* Additional body styles here */
  }
`;
