import React from 'react';

// material ui
import { Grid } from '@mui/material';

// project imports

import MainCard from 'components/MainCard';
import { Heading } from 'components/Headers/Heading';
import { Sessions } from 'pages/Sessions';
import BackForward from '../BackForward';
import { updateGuidedWorkFlow } from 'clients/SpotBus';

export default function SessionForm(props) {
  // getting index for update guided workflow data on register or delete
  const getIndex = (req) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'ADD_SESSIONS') {
        return i;
      }
    }
  };

  // update guided workflow data on register or delete
  const handleUpdateGuidedWorkflowData = async (count) => {
    let req = props.guidedWorkflowData;
    let i = getIndex(props.guidedWorkflowData);
    req.steps_graph[i].data[0].available_items_count =
      req.steps_graph[i].data[0].available_items_count + count;
    if (
      req.steps_graph[i].data[0].min_items_required <=
      req.steps_graph[i].data[0].available_items_count
    ) {
      req.steps_graph[i].data[0].is_completed = true;
    } else {
      req.steps_graph[i].data[0].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid sx={{ mx: '2vw' }}>
      <MainCard>
        <Grid container justifyContent={'space-between'} spacing={3}>
          <Grid item xs={12}>
            <Grid sx={{ pl: 2 }}>
              <Heading title={'Session'} />
            </Grid>
            <Grid sx={{ minHeight: 688 }}>
              <Sessions
                schoolsData={props.schools !== undefined ? props.schools : []}
                guidedForm={true}
                sessionCardData={
                  props?.guidedSessions === undefined ? false : props.guidedSessions[0]
                }
                isEditGuidedSession={props?.guidedSessions === undefined ? false : true}
                getSession={props.getAllSessions}
                handleUpdateGuidedWorkflowData={handleUpdateGuidedWorkflowData}
              />
            </Grid>
            <Grid>
              <BackForward
                disableButton={props.guidedWorkflowData?.steps_graph[2].data[0].is_completed}
                {...props}
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
