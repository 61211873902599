import { Heading } from 'components/Headers/Heading';
import React from 'react';
import Grid from '@mui/material/Grid';
import GpTwoBackForwordBtn from '../../GpTwoBackForwordBtn';

export default function RouteForm(props) {
  return (
    <Grid sx={{ mx: '1vw' }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid sx={{ mb: 2 }}>
            {props.editRouteForm === true ? (
              <Heading title={'Edit Route'} />
            ) : (
              <Heading title={'Add Route'} />
            )}
          </Grid>
          <Grid>
            <GpTwoBackForwordBtn
              nextButtonTitle={'SECOND PHASE'}
              isFromSection={'Routes'}
              setCsActiveStep={props.setCsActiveStep}
              setRenderPageGPTwo={props.setSelectedSchoolId}
              renderPageGPTwo={props.selectedSchoolId}
              setGlobalBtnC={props.setGloablBtnRoutes}
              globalBtnC={props.globalBtnRoutes}
              guidedApi={props.guidedRoutes}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
