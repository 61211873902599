import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';

import { getErrorFields, getChildValidationStatus } from './HelperFunctions';

// Project Import
import NoRecordDisplay from 'components/NoRecordDisplay';
import MainCard from 'components/MainCard';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  typoMargin: {
    marginLeft: theme.spacing(3),
  },
  cardHeader: {
    position: 'sticky',
  },
  weight: {
    fontWeight: '500',
  },
}));

export function DriversValidationListView(props) {
  const classes = useStyles();
  // student details column controller
  const cOne = 5;
  const cTwo = 7;
  // console.log('From validation List view');
  // console.log(props);
  return (
    <div>
      <Paper className={clsx(classes.paper, classes.cardHeader)}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={1}>
            <Typography variant='h6' gutterBottom>
              S.No
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h6' gutterBottom>
              Driver Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant='h6' gutterBottom>
              Status
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {props.data.validation_result.length === 0 ? (
        <MainCard>
          <NoRecordDisplay paddingTop={5} />
        </MainCard>
      ) : (
        props.data.validation_result.map((driver, index) => (
          <Paper className={classes.paper} key={index}>
            <Grid container spacing={3} justifyContent='center'>
              <Grid item xs={1}>
                <Typography gutterBottom>{index + 1}</Typography>
              </Grid>
              <Grid item xs={10} className={clsx(classes.column, classes.helper)}>
                {/* <Typography variant="h6" gutterBottom>
                                Driver Details
                            </Typography> */}
                <Grid container direction='column'>
                  <Grid container direction='row' className={classes.divider} spacing={5}>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={cOne}>
                          <Typography>First Name :</Typography>
                        </Grid>
                        <Grid item xs={cTwo} id='firstName'>
                          {getErrorFields(driver.fields.first_name)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={cOne}>
                          <Typography>Last Name :</Typography>
                        </Grid>
                        <Grid item xs={cTwo} id='lastName'>
                          {getErrorFields(driver.fields.last_name)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={cOne}>
                          <Typography>Phone Number :</Typography>
                        </Grid>
                        <Grid item xs={cTwo} id='phoneNumber'>
                          {getErrorFields(driver.fields.phone_number)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Divider className={classes.divider} variant="middle" /> */}
                  <Grid container direction='row' className={classes.divider} spacing={5}>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={cOne}>
                          <Typography>Email Id :</Typography>
                        </Grid>
                        <Grid item xs={cTwo} id='email'>
                          {getErrorFields(driver.fields.email)}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={cOne}>
                          <Typography>Gender :</Typography>
                        </Grid>
                        <Grid item xs={cTwo} id='gender'>
                          {getErrorFields(driver.fields.gender)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} className={clsx(classes.column, classes.helper)} id='isValid'>
                {/* <Typography variant="h6" gutterBottom>
                                Status
                            </Typography> */}
                {getChildValidationStatus(driver.is_valid)}
              </Grid>
            </Grid>
          </Paper>
        ))
      )}
    </div>
  );
}
