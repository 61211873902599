// assets
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// icons
const icons = {
  SettingsIcon,
  PeopleAltIcon,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const navbarItems = {
  id: 'navbar-dashboard',
  title: 'Navbar',
  type: 'headerGroup',
  children: [
    {
      id: 'account_settings',
      title: 'Account Settings',
      type: 'item',
      url: '/account-settings',
      icon: icons.SettingsIcon,
      breadcrumbs: true,
    },
    {
      id: 'manage_users',
      title: 'Manage Users',
      type: 'item',
      url: '/users',
      icon: icons.PeopleAltIcon,
      breadcrumbs: true,
    },
  ],
};

export default navbarItems;
