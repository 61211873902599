import React from 'react';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    // margin: theme.spacing(1),
    width: '100%',
    height: theme.spacing(66),
  },
}));

export default function RenderCompleted(props) {
  const classes = useStyles();
  // console.log('From Render Completed ');
  // console.log(props.totalStudents);

  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent='center'>
        <Grid item>
          <Typography variant='h6' id='uploadingSuccessfull'>
            {props.totalStudents} Drivers Uploaded Successfully
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
