import React from 'react';

import RouteIcon from '@mui/icons-material/Route';
import { Stack, TableCell } from '@mui/material';

export default function TableHeaderNameWithIcon({
  id = '',
  icon = <RouteIcon />,
  label = '',
  align = 'left',
  justifyContent = 'left',
  textSx = {},
}) {
  return (
    <TableCell align={'center'} id={id}>
      <Stack direction='row' justifyContent={justifyContent} alignItems={'center'} sx={textSx}>
        <Stack mr={1}>{icon}</Stack>
        {label}
      </Stack>
    </TableCell>
  );
}
