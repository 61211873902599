import React from 'react';

import { Stack, Divider, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function SchoolInfoCard({ schoolName, icon, handleOnClick, noData = false }) {
  const theme = useTheme();

  return (
    <>
      <Grid
        direction='row'
        justifyContent='flex-start'
        // alignItems='center'
        onClick={handleOnClick}
        container
        sx={{
          border: '1px solid',
          borderColor: 'black',
          borderRadius: '3px',
          width: '100%',
          boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.70)',
          cursor: !noData ? 'pointer' : 'auto',
          '&:hover': {
            backgroundColor: !noData ? theme.palette.hover.default : null, // Change this to the desired color
          },
        }}
      >
        <Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{ p: 2 }}>
          {icon}
          <Divider sx={{ pl: 3, mr: 3 }} orientation='vertical' flexItem />
          <Stack>
            <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>
              {schoolName}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
}
