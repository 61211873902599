import React from 'react';

// material ui
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// project imports
import {
  getValidationFailedDrivers,
  getValidationPassedDrivers,
  getCorrectValidationStatus,
  getTotalDrivers,
} from './HelperFunctions';

const useStyles = makeStyles((theme) => ({
  typoMargin: {
    marginLeft: theme.spacing(3),
  },
}));

export function DriversValidationHeader(props) {
  const classes = useStyles();
  const itemSize = 2;
  const bulk_import_drivers_filter_options = [
    {
      name: 'Show failed drivers only',
      value: 'failed_drivers',
    },
    {
      name: 'Show passed drivers only',
      value: 'passed_drivers',
    },
    {
      name: 'Show all drivers',
      value: 'all_drivers',
    },
  ];

  const handleChange = (event) => {
    props.setDriverValidationFilter(event.target.value);
  };
  return (
    <Grid container spacing={3} alignItems='center'>
      <Grid item xs={3}>
        <Typography display='inline' variant='h6'>
          Status :
        </Typography>
        <Typography
          className={classes.typoMargin}
          display='inline'
          variant='h6'
          id='status'
        >
          {getCorrectValidationStatus(props.data.status)}
        </Typography>
      </Grid>
      <Grid item xs={itemSize}>
        <Typography display='inline' variant='h6'>
          Total Drivers :
        </Typography>
        <Typography
          className={classes.typoMargin}
          display='inline'
          variant='h6'
          id='totalDrivers'
        >
          {getTotalDrivers(props.totalDriversData)}
        </Typography>
      </Grid>
      <Grid item xs={itemSize}>
        <Typography display='inline' variant='h6'>
          Passed Drivers :
        </Typography>
        <Typography
          className={classes.typoMargin}
          display='inline'
          variant='h6'
          id='passedDrivers'
        >
          {getValidationPassedDrivers(props.totalDriversData)}
        </Typography>
      </Grid>
      <Grid item xs={itemSize}>
        <Typography display='inline' variant='h6'>
          Failed Drivers :
        </Typography>
        <Typography
          className={classes.typoMargin}
          display='inline'
          variant='h6'
          id='failedDrivers'
        >
          {getValidationFailedDrivers(props.totalDriversData)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            select
            id='bulk-import-filter'
            label='Filter List'
            value={props.driverValidationFilter}
            onChange={handleChange}
            variant='standard'
          >
            {bulk_import_drivers_filter_options.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </TextField>
          <FormHelperText></FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
