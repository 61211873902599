import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function ClampLines(props) {
  // I used React Hooks to create a variable in state to manage if the text should be truncated or not.
  const [truncate, setToggleTruncate] = React.useState(true);
  const [show, setShow] = React.useState(true);

  const myRef = useRef();

  React.useEffect(() => {
    if (!myRef.current) {
      setShow(false);
      return;
    }

    const isOverflowing = myRef.current.scrollHeight > myRef.current.clientHeight;
    if (!isOverflowing) {
      setShow(false);
    }
  }, []);

  // This function returns the correct style to the above div.
  function calculateTextStyle() {
    return truncate
      ? {
          ...props.contentSX,
          maxWidth: '100%',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: props.lines,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      : null;
  }

  // This function toggles the state variable 'truncate', thereby expanding and truncating the text every time the user clicks the div.
  function toggleTruncate(event) {
    event.stopPropagation();
    setToggleTruncate(!truncate);
  }

  function getEllipsis(text) {
    return (
      <Typography
        variant='h6'
        id='card_description'
        onClick={(event) => toggleTruncate(event)}
        sx={props.ellipsisSX}
      >
        {text}
      </Typography>
    );
  }

  return (
    <>
      <Typography id='card_description' sx={calculateTextStyle()} ref={myRef}>
        {props.text}
      </Typography>
      {show && (truncate ? getEllipsis(props.moreText) : getEllipsis(props.lessText))}
    </>
  );
}

ClampLines.propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number.isRequired,
  ellipsis: PropTypes.string,
  moreText: PropTypes.string,
  lessText: PropTypes.string,
  contentSX: PropTypes.object,
  ellipsisSX: PropTypes.object,
};

ClampLines.defaultProps = {
  text: '...',
  lines: 3,
  ellipsis: '...',
  moreText: 'Read more',
  lessText: 'Read less',
  ellipsisSX: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

/**
 * !How to use
 
 text: It is the content of clamplines
  lines: How many lines you wants to show ex: lines={5},
  ellipsis: ending tag at the end of text ex: ellipsis="...",
  moreText: Read More tag ex: moreText="Read More",
  lessText: Read Less tag ex: lessText="Read Less",
  contentSX: It is the sx provided to text field,
  ellipsisSX: this sx will be provided to Read More Tag and Read Less tag,

 * ! Calling with minimum requirements
 * <ClampLines text={description} lines={5} />
 */
