import React, { useCallback, useEffect, useState } from 'react';
import ChatBot from 'react-simple-chatbot';

import { botImgStatic, generateLighterColor, uri } from './helper';
import SponsorPortal from './SponsorPortal';
import {
  CustomHeader,
  avatarStyle,
  containerStyle,
  contentStyle,
  footerStyle,
  inputStyle,
  submitButtonStyle,
  userBubbleStyle,
} from './style/chatbot.styled';
import { RobotIcon } from './RobotIcon';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

const StyledChatBot = styled(ChatBot)`
  background: ${(props) => (props.theme === 'dark' ? '#333' : '#fff')};
  z-index: 9999999;
  position: relative;
  border-radius: 26px;
  .rsc-content .rsc-cs {
    /* Custom styles for the chat bubble */
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    width: 90%;
    box-shadow: none;
  }
  .rsc-content .rsc-ts-user .rsc-ts-bubble {
    background-color: ${(props) => props.color} !important;
  }
  .rsc-content .rsc-cs .rsc-loading {
    display: none;
  }
  .rsc-content .rsc-ts-bot {
    display: flex;
    align-items: center !important;
  }
  .rsc-content .rsc-ts-user {
    align-items: center;
  }
  .rsc-content .rsc-ts-user .rsc-ts-bubble {
    margin: 0px !important;
  }
`;

/**
 * Renders a chatbot component with customized styling.
 *
 * @param {Object} styleData - The style data for customizing the chatbot.
 * @return {JSX.Element} The rendered chatbot component.
 */
const Chatbot = ({ styleData, token, userid }) => {
  const {
    chatbot_pic, //not getting
    color, // ?
    company_logo, // not getting
    company_name,
    // icon, // discussion
    input_placeholder,
    // name, // what is diff name and company_name
    powered_by_branding,
    sub_heading, // ?
    // support_email, // ?
    // widget_pos, // ?
    theme,
    welcome_msg,
  } = styleData;
  const themes = useTheme();

  let botImg = chatbot_pic?.url ? chatbot_pic?.url : chatbot_pic || botImgStatic;

  const companyImg = company_logo?.url ? company_logo?.url : company_logo || botImgStatic;

  const wel_message = welcome_msg === '' ? ' ' : welcome_msg;

  const assistantTheme = theme === 'system' ? themes.palette.mode : theme;

  const [key, setKey] = useState(wel_message);
  const [botApiData, setBotApiData] = useState(null);

  useEffect(() => {
    setKey(wel_message);
  }, [wel_message]);

  useEffect(() => {
    setKey(chatbot_pic?.url ?? chatbot_pic?.name);
  }, [chatbot_pic]);

  const handleSubmit = useCallback(
    async ({ useInput, setLoading, setResult, triggerNextStep, result }) => {
      setLoading(true);
      const withTaredId = {
        message: useInput,
        thread_id: result?.thread_id,
      };
      const withoutTaredId = {
        message: useInput,
      };
      const data = result?.thread_id ? withTaredId : withoutTaredId;
      try {
        const url =
          'http://spotbus-ai-assistant-alb-2060885239.us-east-1.elb.amazonaws.com/assistant/chat';
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        const responseData = await response.json();

        setResult(responseData);
        setBotApiData(responseData);
        setLoading(false);
        triggerNextStep();
      } catch (error) {
        setResult('Spotbus AI is currently unavailable. Please try again later.');
        setLoading(false);
        triggerNextStep();
        console.error(error);
      }
    },
    [setBotApiData],
  );

  function _steps(params) {
    const steps = [
      {
        id: '1',
        message: wel_message,
        trigger: 'useInput',
      },
      {
        id: 'useInput',
        user: true,
        trigger: 'triggerBot',
        validator: (value) => {
          if (value === '') {
            return 'Please enter your message';
          }
          return true;
        },
      },
      {
        id: 'triggerBot',
        component: (
          <CustomBotReply
            handleSubmit={handleSubmit}
            styleData={styleData}
            botApiData={botApiData}
            botImg={botImg}
          />
        ),
        waitAction: true,
        trigger: 'useInput',
      },
    ];

    return steps;
  }

  // generate preview image
  const generatePreviewImage = (imageFile) => {
    if (imageFile && typeof imageFile === 'object') {
      let tempURL = URL.createObjectURL(imageFile);
      return tempURL;
    }
    return imageFile;
  };

  return (
    <div>
      <StyledChatBot
        color={generateLighterColor(color, 0.5)}
        theme={assistantTheme}
        placeholder={input_placeholder}
        bubbleStyle={userBubbleStyle(assistantTheme)}
        avatarStyle={avatarStyle}
        contentStyle={contentStyle(assistantTheme)}
        inputStyle={inputStyle(assistantTheme)}
        botAvatar={generatePreviewImage(botImg)}
        submitButtonStyle={submitButtonStyle(assistantTheme, color)}
        footerStyle={footerStyle(assistantTheme)}
        style={containerStyle}
        customDelay={0}
        headerComponent={
          <CustomHeader color={color}>
            <h3>{company_name}</h3>
            <div>
              <img src={generatePreviewImage(companyImg)} alt='' />
            </div>
            <p>{sub_heading}</p>
          </CustomHeader>
        }
        steps={_steps()}
        key={key}
      />
      {<SponsorPortal styleData={styleData} theme={assistantTheme} />}
    </div>
  );
};

export default Chatbot;

/**
 * Renders a custom bot reply component.
 *
 * @param {Object} props - The props object containing the necessary data.
 * @param {Array} props.steps - The steps array.
 * @param {Function} props.triggerNextStep - The function to trigger the next step.
 * @param {Function} props.handleSubmit - The function to handle form submission.
 * @param {Object} props.styleData - The object containing the style data.
 * @param {string} props.styleData.theme - The theme of the component.
 * @return {JSX.Element} The custom bot reply component.
 */
const CustomBotReply = (props) => {
  const { steps, triggerNextStep, handleSubmit, styleData, botApiData, botImg } = props;
  const { theme } = styleData;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(botApiData);

  const useInput = steps.useInput.value;
  console.log(props, steps, useInput, 'props');

  useEffect(() => {
    handleSubmit({ useInput, setLoading, setResult, result, triggerNextStep });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generatePreviewImage = (imageFile) => {
    if (imageFile && typeof imageFile === 'object') {
      let tempURL = URL.createObjectURL(imageFile);
      return tempURL;
    }
    return imageFile;
  };

  return (
    <div
      style={{
        backgroundColor: theme === 'dark' ? '#333' : '#fff',
        boxShadow: '0px 0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginLeft: '5px',
          minWidth: '35px',
          maxWidth: '35px',
          height: '35px',
        }}
      >
        <img width='100%' height='auto' src={generatePreviewImage(botImg)} alt='' />
      </div>
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        {loading ? (
          <div
            style={{
              fontSize: '14px',
              padding: '10px',
              backgroundColor: theme === 'dark' ? '#333' : '#fff',
              color: theme === 'dark' ? '#f0f0f0' : '#333',
              boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)',
              borderRadius: '10px',
              margin: '0px',
            }}
          >
            <div className='loader'>
              <div className='dot dot1'></div>
              <div className='dot dot2'></div>
              <div className='dot dot3'></div>
            </div>
          </div>
        ) : (
          <p
            style={{
              fontSize: '12px',
              padding: '10px',
              backgroundColor: theme === 'dark' ? '#333' : '#fff',
              color: theme === 'dark' ? '#e8e8e8' : '#333333',
              boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)',
              borderRadius: '10px',
              margin: '0px',
            }}
          >
            {result?.output_message}
          </p>
        )}
      </div>
    </div>
  );
};
