import React from 'react';

// MUI Imports
import Switch from '@mui/material/Switch';
import { FormHelperText, Stack, Typography } from '@mui/material';

// Components

/**
 * Renders a switch component with a label and helper text.
 *
 * @param {string} label - The text label for the switch.
 * @param {boolean} checked - The value of the switch.
 * @param {function} onChange - The function called when the switch is toggled.
 * @param {string} helperText - The helper text displayed below the switch.
 * @param {object} props - Additional props to be spread on the Stack component.
 * @return {JSX.Element} - The JSX element representing the SwitchWithText component.
 */
export const SwitchFormik = ({
  label = '',
  checked = false,
  helperText = '',
  field,
  form,
  headingSx,
  size = 'small',
  disabled = false,
  ...props
}) => {
  const { setFieldValue, errors, touched } = form;
  const { name, value } = field;

  let isError = touched[name] && errors[name];
  const handleChange = (event) => {
    const { checked } = event.target;
    setFieldValue(name, checked);
  };

  /**
   * Returns a FormHelperText component with the specified helper text and error state.
   *
   * @return {JSX.Element} A FormHelperText component.
   * @example
   * // Returns a FormHelperText component with the specified helper text and error state.
   * const helperText = 'This is a helper text.';
   * const isError = true;
   * const helperComponent = helperAndError(helperText, isError);
   * console.log(helperComponent); // <FormHelperText error>This is a helper text.</FormHelperText>
   */
  const helperAndError = () => {
    if (!isError) {
      return <FormHelperText>{helperText}</FormHelperText>;
    } else {
      return <FormHelperText error> {helperText}</FormHelperText>;
    }
  };

  return (
    <Stack {...props}>
      <Stack direction='row' alignItems='center' {...headingSx}>
        <Typography variant='h6'>{label}</Typography>
        <Switch
          checked={value}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          size={size}
          disabled={disabled}
        />
      </Stack>
      <Stack ml={2}>{helperAndError()}</Stack>
    </Stack>
  );
};
