import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import LoadingButton from '@mui/lab/LoadingButton';

// assets
import SaveIcon from '@mui/icons-material/Save';

// ============================== STUDENT FORM - MAIN  ============================== //

function SaveButton({ children, ...props }) {
  return (
    <LoadingButton {...props} startIcon={<SaveIcon />} loadingPosition='start'>
      {children.toUpperCase()}
    </LoadingButton>
  );
}

SaveButton.defaultProps = {
  variant: 'contained',
  size: 'large',
  color: 'primary',
};
SaveButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string,
};

export default SaveButton;
