import React, { useEffect, useState } from 'react';
// mui
import { InputLabel, MenuItem, Select, Stack, FormHelperText } from '@mui/material';

// icons
import { getAllDevices } from 'clients/vehicle';
// icons
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PersonIcon from '@mui/icons-material/Person';
import { ClassificationHeading } from 'components/Headers';
const DeviceAttach = ({ formikProps, selectedData, setSelectedData, ...props }) => {
  // apiData
  const [deviceData, setDeviceData] = useState(null);

  const _getAllDevices = async () => {
    try {
      const data = await getAllDevices();
      const filteredData = {
        gps_tracker: data.filter(
          (item) => item.device_type === 'gps_tracker' || item.device_type === 'tablet',
        ),
        rfid: data.filter((item) => item.device_type === 'rfid' || item.device_type === 'tablet'),
        tablet: data.filter((item) => item.device_type === 'tablet'),
      };
      setDeviceData(filteredData);
    } catch (e) {
      console.log(e);
      setDeviceData(null);
    }
  };

  const handleChangeTab = (event) => {
    const value = event.target.value;
    setSelectedData((pre) => {
      return pre.map((items, index) => {
        if (index === 0) {
          return {
            capability: value ? 'driverapp' : '',
            device_id: value,
          };
        }
        return items;
      });
    });
  };

  const handleChangeAttendance = (event) => {
    const value = event.target.value;
    setSelectedData((pre) => {
      return pre.map((items, index) => {
        if (index === 1) {
          return {
            capability: value ? 'gps_tracking' : '',
            device_id: value,
          };
        }
        return items;
      });
    });
  };
  const handleChangeGPS = (event) => {
    const value = event.target.value;
    setSelectedData((pre) => {
      return pre.map((items, index) => {
        if (index === 2) {
          return {
            capability: value ? 'gps_tracking' : '',
            device_id: value,
          };
        }
        return items;
      });
    });
  };

  useEffect(() => {
    _getAllDevices();
  }, []);

  return (
    <Stack mt={2}>
      {Heading}

      <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
        <Stack width={'33%'}>
          <Stack>
            <InputLabel>Driver app</InputLabel>
            <Select
              value={selectedData[0]?.device_id || ''}
              label='Select driver app'
              onChange={handleChangeTab}
              variant='standard'
              startAdornment={<DirectionsBusIcon />}
            >
              <MenuItem value={''} sx={{ fontStyle: 'italic' }}>
                None
              </MenuItem>
              {deviceData &&
                deviceData.tablet?.map((item) => (
                  <MenuItem key={item.id} value={item.device_id}>
                    {item.device_id + '-' + item.device_type}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Select driver app</FormHelperText>
          </Stack>
        </Stack>

        <Stack width={'33%'}>
          <Stack>
            <InputLabel>GPS tracking</InputLabel>
            <Select
              value={selectedData[1]?.device_id || ''}
              label='GPS Tracking'
              onChange={handleChangeAttendance}
              variant='standard'
              startAdornment={<GpsFixedIcon />}
            >
              <MenuItem value={''} sx={{ fontStyle: 'italic' }}>
                None
              </MenuItem>
              {deviceData &&
                deviceData.gps_tracker?.map((item) => (
                  <MenuItem key={item.id} value={item.device_id}>
                    {item.device_id + '-' + item.device_type}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Select GPS tracking</FormHelperText>
          </Stack>
        </Stack>

        <Stack width={'33%'}>
          <Stack>
            <InputLabel id='gps'>Student attendance</InputLabel>
            <Select
              value={selectedData[2]?.device_id || ''}
              id='gps'
              label='Student Attendance'
              onChange={handleChangeGPS}
              variant='standard'
              startAdornment={<PersonIcon />}
            >
              <MenuItem value={''} sx={{ fontStyle: 'italic' }}>
                None
              </MenuItem>
              {deviceData &&
                deviceData.rfid?.map((item) => (
                  <MenuItem key={item.id} value={item.device_id}>
                    {item.device_id + '-' + item.device_type}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Select Student attendance</FormHelperText>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DeviceAttach;
const Heading = (
  <>
    <Stack spacing={2}>
      <ClassificationHeading>Device Integration</ClassificationHeading>
    </Stack>
  </>
);
