import React from 'react';

// material-ui
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Skeleton from '@mui/material/Skeleton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(Button);

export function VehicleIdAndButtons({
  vehicleId,
  handleVehicleEdit,
  handleVehicleDelete,
  onlyGetRequestAllowed,
  isDataLoading,
}) {
  return (
    <Grid container alignItems='center' spacing={3}>
      <Grid item xs={6}>
        {!isDataLoading ? (
          <Typography variant='h2' color='textPrimary'>
            Vehicle {vehicleId}
          </Typography>
        ) : (
          <Skeleton variant='h1' />
        )}
      </Grid>
      <Grid item xs={6}>
        {!isDataLoading ? (
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <Button
                color='secondary'
                id='vehicleEdit'
                startIcon={<EditOutlinedIcon />}
                onClick={handleVehicleEdit}
                disabled={onlyGetRequestAllowed}
                sx={{color: 'secondary.dark'}}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <ErrorButton
                startIcon={<DeleteOutlinedIcon />}
                onClick={handleVehicleDelete}
                disabled={onlyGetRequestAllowed}
                id='vehicleDelete'
              >
                Delete
              </ErrorButton>
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant='text' />
        )}
      </Grid>
    </Grid>
  );
}
