import { useQuery } from '@tanstack/react-query';
import { getVehiclesList } from 'clients/SpotBus';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
// third-party imports
import { useSnackbar } from 'notistack';
import { queryKey } from 'react-query/queryKey';

/**
 * Generate a custom hook to fetch vehicle data and handle errors.
 *
 * @param {Object} options - Options for customizing the behavior of the hook
 * @param {boolean} options.includeInactive - Flag to include inactive vehicles
 * @return {Object} Object containing vehicle data and loading state
 * @example
 *  const { getVehicleData, isLoadingVehicles } = useVehicles({ includeInactive: true });
 */
export const useVehicles = ({ customQueryKey = null } = {}) => {
  const { enqueueSnackbar } = useSnackbar();

  // Use custom query key if provided, otherwise use the default one
  const queryKeyArray = customQueryKey || [queryKey.VEHICLES.ALL_VEHICLES];

  // Use useQuery hook to fetch data and handle errors
  const { data: getVehicleData, isLoading: isLoadingVehicles } = useQuery({
    queryKey: queryKeyArray,
    queryFn: () => getVehiclesList(),
    /**
     * Handles the error that occurred during the execution of the function.
     *
     * @param {Error} error - The error that occurred.
     * @return {void}
     */
    onError: (error) => {
      // Handle errors and display a snack bar notification
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
  });

  // Return the necessary data or functions
  return {
    getVehicleData,
    isLoadingVehicles,
  };
};
