import React, { useCallback } from 'react';

// material ui
import { Typography, Tooltip, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material/styles';

// third party imports
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getBgColor = (props) => {
  if (props.isDragAccept) {
    return '#ffecb3';
  }
  if (props.isDragReject) {
    return '#ffcdd2';
  }
  if (props.isDragActive) {
    return '#ffecb3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  border-radius: 50%;
  background-color: ${(props) => getBgColor(props)};
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  min-height: 120px;
  min-width: 120px;
  max-height: 120px;
  max-width: 120px;
  height: 120px;
  width: 120px;
`;

const OuterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 50%;
  border-color: black;
  border-style: dotted;
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  min-height: 140px;
  min-width: 140px;
  max-height: 140px;
  max-width: 140px;
  height: 140px;
  width: 140px;
`;

export default function LogoDragAndDrop(props) {
  const classes = useStyles();
  const theme = useTheme();

  const onDrop = useCallback((acceptedFile) => {
    if (acceptedFile.length === 1) {
      props.handleDragImage(acceptedFile);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
    maxFiles: 1,
  });

  return (
    <div className={classes.container}>
      <OuterContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <Container>
          <input {...getInputProps()} />
          {props.selectedFile ? (
            <Tooltip title={props.tooltipTitle}>
              <img src={props.selectedFile} alt={'logo'} className={classes.img} />
            </Tooltip>
          ) : (
            <Grid container direction='column' alignItems='center'>
              <UploadFileIcon sx={{ fontSize: '42px', color: theme.palette.secondary.dark }} />
              <Typography style={{ textAlign: 'center', padding: '5%', fontSize: '14px' }}>
                {props.selectedFile === undefined ? props.text : ''}
              </Typography>
            </Grid>
          )}
        </Container>
      </OuterContainer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  image: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    borderRadius: '50%',
  },
}));
