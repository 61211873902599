import React from 'react';
import PropTypes from 'prop-types';

// third-party imports
import { Field } from 'formik';
import { TextField } from 'formik-mui';

// material-ui
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';

// project imports
import { chipItems } from '../FormModel/studentFormModel';
import { inputVariant } from 'constants';

// custom components
import { CustomImageInput, CountrySelect, CustomChipArray, SelectField } from 'components/Formik';
import { ClassificationHeading } from 'components/Headers';

// assets
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';

import EmailIcon from '@mui/icons-material/Email';

import LanguageSelector from 'components/Formik/LanguageSelector';
import { Skeleton, Stack } from '@mui/material';
import DatePickerField from 'components/Formik/DateSelectorFormik';
import { inputVariantStudent } from 'constants';
import { PiGenderIntersexBold } from 'react-icons/pi';
import SchoolIcon from '@mui/icons-material/School';

const gradeInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <SchoolIcon />
    </InputAdornment>
  ),
};

const fatherInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const phoneInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PhoneAndroidIcon />
    </InputAdornment>
  ),
};

const emailInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};
const personIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const STUDENT_GRAD = [
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'LKG',
    value: 'LKG',
  },
  {
    label: 'UKG',
    value: 'UKG',
  },
  {
    label: 'Grade 1',
    value: '1',
  },
  {
    label: 'Grade 2',
    value: '2',
  },
  {
    label: 'Grade 3',
    value: '3',
  },
  {
    label: 'Grade 4',
    value: '4',
  },
  {
    label: 'Grade 5',
    value: '5',
  },
  {
    label: 'Grade 6',
    value: '6',
  },
  {
    label: 'Grade 7',
    value: '7',
  },
  {
    label: 'Grade 8',
    value: '8',
  },
  {
    label: 'Grade 9',
    value: '9',
  },
  {
    label: 'Grade 10',
    value: '10',
  },
  {
    label: 'Grade 11',
    value: '11',
  },
  {
    label: 'Grade 12',
    value: '12',
  },
];

// ============================== BASIC DETAILS FORM - FIRST STEP  ============================== //

function BasicForm({ formikProps, ...props }) {
  const { imageLoader } = props;
  const theme = useTheme();
  const handiCapLevel = () => {
    if (!formikProps.values.special_needs) {
      return <Stack width={'300px'}></Stack>;
    }
    return (
      <>
        <Field
          fullWidth
          component={SelectField}
          label='Select Special needs level'
          name='special_needs_level'
          variant={inputVariant}
          Icon={false}
          data={[
            { label: '01', value: '01' },
            { label: '02', value: '02' },
            { label: '03', value: '03' },
            { label: '04', value: '04' },
            { label: '05', value: '05' },
          ]}
          // helperText='Select Special needs level'
          async={false}
          id='special_needs_level'
          selectFieldProps={{
            variant: 'standard',
            size: 'small',
            marginTop: '10px',
          }}
          sx={{ width: '300px' }}
        />
      </>
    );
  };
  const GenderIcon = () => (
    <PiGenderIntersexBold
      size={'24px'}
      color={theme.palette.secondary.dark}
      style={{
        marginRight: '10px',
      }}
    />
  );
  return (
    <Grid container direction='row' spacing={3}>
      {/* Basic details */}
      <Grid item xs={12}>
        <ClassificationHeading>Student Information</ClassificationHeading>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={3} direction={'row'}>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={TextField}
              label='First Name *'
              name='first_name'
              variant={inputVariantStudent}
              InputProps={personIcon}
              helperText='Enter first name'
              id='first_name'
              size='small'
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={TextField}
              label='Last Name *'
              name='last_name'
              variant={inputVariantStudent}
              InputProps={personIcon}
              id='last_name'
              helperText='Enter last name'
              size='small'
            />
          </Grid>
        </Stack>

        <Stack spacing={3} direction={'row'} mt={3}>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label='Birth Date'
              name='birth_date'
              helperText='Enter birth date'
              id='birth_date'
              size='small'
              textFieldProps={{
                variant: inputVariantStudent,
                size: 'small',
              }}
              disableFuture={true}
            />
          </Grid>

          <Grid item xs={12}>
            {/* <Field
              fullWidth
              component={TextField}
              type='number'
              label='Student Grade *'
              name='grade'
              variant={inputVariantStudent}
              InputProps={gradeInputProps}
              id='grade'
              helperText='Enter student grade'
              size='small'
            /> */}
            <Field
              size={inputVariantStudent}
              component={SelectField}
              label='Student Grade *'
              name='grade'
              Icon={SchoolIcon}
              data={STUDENT_GRAD}
              async
              id='grade'
              helperText='Enter student grade'
              selectFieldProps={{
                variant: inputVariantStudent,
                size: 'small',
              }}
              fullWidth
            />
          </Grid>
        </Stack>
        <Stack spacing={3} direction={'row'} mt={3}>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={SelectField}
              InputProps={fatherInputProps}
              label='Gender *'
              name='gender'
              variant={inputVariantStudent}
              Icon={GenderIcon}
              data={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' },
              ]}
              async={false}
              id='gender'
              helperText='Select AM route'
              selectFieldProps={{
                size: 'small',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LanguageSelector
              name='lang'
              label='Select Language *'
              id='language-select'
              languageId='language-select-autocomplete'
              helperTextContent='Select your preferred language'
              {...formikProps}
            />
          </Grid>
        </Stack>
      </Grid>
      <Grid item container xs={6} justifyContent='center' alignItems='center'>
        <Grid item xs={12} mb={2} alignItems='center' justifyContent={'center'} display={'flex'}>
          <ClassificationHeading>Upload Student Image</ClassificationHeading>
        </Grid>
        {imageLoader ? (
          <Skeleton variant='circular' width={120} height={120} />
        ) : (
          <Field component={CustomImageInput} helperText='Upload an image' name='student_image' />
        )}
      </Grid>

      {/* Contact and Special needs information */}
      <Grid item xs={12} my={2}>
        <ClassificationHeading>Contact Information</ClassificationHeading>
      </Grid>
      <Stack spacing={3} ml={3} direction={'row'} width={'100%'}>
        <Grid width={'10%'}>
          <CountrySelect
            {...formikProps}
            name='student_phone_code'
            label='Phone code'
            id='student_phone_code'
            type='phone'
            countryId='student_phone_code'
            helperTextContent='Country code'
            textFieldProps={{
              variant: 'outlined',
              size: 'small',
            }}
          />
        </Grid>
        <Grid width={'40%'}>
          <Field
            fullWidth
            component={TextField}
            label='Mobile Number'
            name='student_phone_number'
            variant={inputVariantStudent}
            InputProps={phoneInputProps}
            id='student_phone_number'
            helperText='Enter student mobile number'
            size='small'
          />
        </Grid>
        <Grid width={'50%'}>
          <Field
            fullWidth
            component={TextField}
            label='Email'
            name='student_email'
            variant={inputVariantStudent}
            InputProps={emailInputProps}
            id='student_email'
            helperText='Enter student email'
            size='small'
          />
        </Grid>
      </Stack>
      <Grid item container spacing={2} columns={16}>
        <Grid item xs={12} mb={2}>
          <ClassificationHeading>Special Need information</ClassificationHeading>
        </Grid>
        <Stack width={'100%'} ml={3}>
          <Field
            fullWidth
            component={CustomChipArray}
            label='Special Needs *'
            name='special_needs'
            chipItems={chipItems}
            helperText='Select needs'
            children={handiCapLevel()}
          />
        </Stack>
      </Grid>

      {/* Parents information */}
      {/* <Grid item xs={12} mt={2}>
        <ClassificationHeading>Parent's Information</ClassificationHeading>
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label="Father's First Name *"
          name='fathers_first_name'
          variant={inputVariant}
          InputProps={fatherInputProps}
          id='fathers_first_name'
          helperText="Enter father's first name"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label="Father's Last Name *"
          name='fathers_last_name'
          variant={inputVariant}
          InputProps={fatherInputProps}
          id='fathers_last_name'
          helperText="Enter father's last name"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label="Mother's First Name *"
          name='mothers_first_name'
          variant={inputVariant}
          InputProps={motherInputProps}
          id='mothers_first_name'
          helperText="Enter mother's first name"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label="Mother's Last Name *"
          name='mothers_last_name'
          variant={inputVariant}
          InputProps={motherInputProps}
          id='mothers_last_name'
          helperText="Enter mother's last name"
        />
      </Grid> */}
    </Grid>
  );
}

BasicForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
};

export default BasicForm;
