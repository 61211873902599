import { Stack } from '@mui/material';
import { SelectField } from 'components/Formik';
import { ClassificationHeading } from 'components/Headers';
import { Field } from 'formik';
import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { TextField } from 'formik-mui';
import { InputProps } from './ParentsAndOtherInformation';
import CheckBoxFormik from 'components/Formik/CheckBoxFormik';
import { inputVariantStudent } from 'constants';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { useTheme } from '@mui/material/styles';
const StudentProgram = () => {
  const theme = useTheme();
  const SafetyIcon = () => (
    <AiFillSafetyCertificate
      fontSize={'26px'}
      color={theme.palette.secondary.dark}
      style={{
        marginRight: '8px',
      }}
    />
  );
  return (
    <Stack spacing={3}>
      <ClassificationHeading>Student Program Information </ClassificationHeading>
      <Stack spacing={3} direction={'row'}>
        <Stack width={'50%'}>
          <Field
            fullWidth
            component={TextField}
            label='Student Program Name'
            name='student_program_name'
            id='student_program_name'
            variant={inputVariantStudent}
            InputProps={InputProps(PersonIcon)}
            helperText='Enter student program name'
            size='small'
          />
        </Stack>
        <Stack width={'50%'}>
          <Field
            fullWidth
            component={SelectField}
            label='Student Type'
            name='student_type'
            id='student_type'
            helperText='Select type'
            variant={inputVariantStudent}
            Icon={SchoolIcon}
            data={[
              { label: 'Regular', value: 'regular' },
              { label: 'Private', value: 'private' },
              { label: 'Other', value: 'other' },
            ]}
            async={false}
            selectFieldProps={{
              variant: inputVariantStudent,
              size: 'small',
            }}
            sx={{ width: '100%' }}
          />
        </Stack>
      </Stack>

      <Stack direction={'row'} spacing={3} mt={3}>
        <Stack width={'20%'}>
          <Field
            fullWidth
            component={SelectField}
            label='Neighborhood Code'
            helperText='Select neighborhood code'
            name='neighborhood_code'
            id='neighborhood_code'
            variant={inputVariantStudent}
            Icon={SafetyIcon}
            data={[
              { label: '001', value: '001' },
              { label: '002', value: '002' },
              { label: '003', value: '003' },
              { label: '004', value: '004' },
              { label: '005', value: '005' },
              { label: '006', value: '006' },
            ]}
            async={false}
            selectFieldProps={{
              variant: inputVariantStudent,
              size: 'small',
            }}
            sx={{ width: '100%' }}
          />
        </Stack>
        <Stack width={'80%'}>
          <Field
            fullWidth
            component={TextField}
            label='Neighborhood Code Description '
            name='student_neighborhood_code_description'
            id='student_neighborhood_code_description'
            variant={inputVariantStudent}
            InputProps={InputProps(DescriptionIcon)}
            helperText='Select code description'
            size='small'
          />
        </Stack>
      </Stack>
      <Notes />
      <ClassificationHeading>Special Accommodation</ClassificationHeading>
      <Stack spacing={3}>
        <Stack direction={'row'} spacing={3}>
          <Field component={CheckBoxFormik} name={'student_ac'} label='AC' />
          <Field component={CheckBoxFormik} name={'student_booster'} label='Booster' />
          <Field component={CheckBoxFormik} name={'student_car_seat'} label='Car Seat' />
          <Field component={CheckBoxFormik} name={'student_harness'} label='Harness' />
          <Field component={CheckBoxFormik} name={'student_lock'} label='Lock' />
          <Field component={CheckBoxFormik} name={'student_mclb'} label='MCLB' />
        </Stack>

        <Stack direction={'row'} spacing={3}>
          <Field component={CheckBoxFormik} name={'student_monitor'} label='Monitor' />
          <Field component={CheckBoxFormik} name={'student_unattended'} label='SCH Unattended' />
          <Field component={CheckBoxFormik} name={'student_therapy'} label='Therapy' />
          <Field component={CheckBoxFormik} name={'student_walker'} label='Walker' />
          <Field component={CheckBoxFormik} name={'student_wc'} label='WC' />
          <Field component={CheckBoxFormik} name={'student_west'} label='West' />
          <Field component={CheckBoxFormik} name={'student_m'} label='M' />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Notes = () => {
  return (
    <Stack spacing={3}>
      <ClassificationHeading>Notes</ClassificationHeading>
      <Stack direction={'row'} spacing={3} width={'100%'}>
        <Stack width={'50%'}>
          <Field
            fullWidth
            component={TextField}
            label='School Notes'
            name='school_notes'
            multiline
            rows={3}
            variant={inputVariantStudent}
            // InputProps={InputProps(NotesIcon)}
            id='school_notes'
            helperText='Enter school notes'
          />
        </Stack>
        <Stack width={'50%'}>
          <Field
            fullWidth
            component={TextField}
            label='Driver Notes'
            name='driver_notes'
            multiline
            rows={3}
            variant={inputVariantStudent}
            // InputProps={InputProps(NotesIcon)}
            id='driver_notes'
            helperText='Enter driver notes'
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StudentProgram;
