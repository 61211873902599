import React from 'react';

// material ui
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// project imports
import {
    getValidationFailedStudents,
    getValidationPassedStudents,
    getCorrectValidationStatus,
    getTotalStudents
} from './HelperFunctions';

const useStyles = makeStyles((theme) => ({
    typoMargin: {
        marginLeft: theme.spacing(3),
    },
}));

export function ChildsValidationHeader(props) {
    const classes = useStyles();
    const itemSize = 2;
    const bulk_import_students_filter_options = [
        {
            name: 'Show failed students only',
            value: 'failed_students',
        },
        {
            name: 'Show passed students only',
            value: 'passed_students',
        },
        {
            name: 'Show all Students',
            value: 'all_students',
        },
    ];

    const handleChange = (event) => {
        props.setChildValidationFilter(event.target.value);
    };
    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={3}>
                <Typography display="inline" variant="h6">
                    Status:
                </Typography>
                <Typography
                    className={classes.typoMargin}
                    display="inline"
                    variant="h6"
                    id='status'
                >
                    {getCorrectValidationStatus(props.data.status)}
                </Typography>
            </Grid>
            <Grid item xs={itemSize}>
                <Typography display="inline" variant="h6">
                    Total Students:
                </Typography>
                <Typography
                    className={classes.typoMargin}
                    display="inline"
                    variant="h6"
                    id='totalStudents'
                >
                    {getTotalStudents(props.data)}
                </Typography>
            </Grid>
            <Grid item xs={itemSize}>
                <Typography display="inline" variant="h6">
                    Passed Students:
                </Typography>
                <Typography
                    className={classes.typoMargin}
                    display="inline"
                    variant="h6"
                    id='passedStudents'
                >
                    {getValidationPassedStudents(props.data)}
                </Typography>
            </Grid>
            <Grid item xs={itemSize}>
                <Typography display="inline" variant="h6">
                    Failed Students:
                </Typography>
                <Typography
                    className={classes.typoMargin}
                    display="inline"
                    variant="h6"
                    id='failedStudent'
                >
                    {getValidationFailedStudents(props.data)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth>
                    <TextField
                        select
                        id="bulk-import-filter"
                        label='Filter List'
                        value={props.childValidationFilter}
                        onChange={handleChange}
                        variant='standard'
                    >
                        {bulk_import_students_filter_options.map((item) => (
                            <MenuItem value={item.value}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <FormHelperText></FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
}
