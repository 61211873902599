import { useMemo } from 'react';

// custom hooks & contexts
import { useColorMode } from 'contexts';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

// project imports
import componentsOverride from './overrides';
import CustomShadows from './shadows';
import Palette from './palette';
import Typography from './typography';

// ============================== DEFAULT THEME - MAIN  ============================== //

export default function IronManTheme({ children }) {
  const { isDarkTheme } = useColorMode();
  const mode = isDarkTheme ? 'dark' : 'light';

  const theme = useMemo(() => Palette(mode), [mode]);
  const themeTypography = Typography();
  const themeCustomShadows = CustomShadows(theme);

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
      customShadows: themeCustomShadows,
      spacing: 4,
      shape: {
        borderRadius: 2,
      },
    }),
    [theme, themeTypography, themeCustomShadows],
  );

  let themes = createTheme(themeOptions);
  themes = responsiveFontSizes(themes);
  // Override components
  themes.components = componentsOverride(themes);

  console.log(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
