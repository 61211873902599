import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

// Material Ui
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// Asset
import PublishIcon from '@mui/icons-material/Publish';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';

// Project Import
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import IcoButton from 'components/Buttons/IcoButton';

const getColor = (props, theme) => {
  if (props.isDragAccept) {
    return theme.palette.primary.main;
  }
  if (props.isDragReject) {
    return theme.palette.error.dark;
  }
  if (props.isDragActive) {
    return theme.palette.primary.main;
  }
  return theme.palette.secondary.main;
};

const getBgColor = (props, theme) => {
  if (props.isDragAccept) {
    return theme.palette.primary.light;
  }
  if (props.isDragReject) {
    return theme.palette.error.main;
  }
  if (props.isDragActive) {
    return theme.palette.primary.light;
  }
  return theme.palette.background.default;
};

const Container = styled('div')(({ theme, height, sx, ...props }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: getColor(props, theme),
  borderStyle: 'dashed',
  backgroundColor: getBgColor(props, theme),
  color: theme.palette.text.primary,
  outline: 'none',
  transition: 'background-color 0.24s ease-in-out',
  cursor: 'pointer',
  height: height,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DropZone({
  width,
  label,
  uploadedFile,
  setUploadedFile,
  height,
  transform,
  left,
  bottom,
  fontSize,
  sx,
  ...props
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
    const fileReader = new FileReader();
    fileReader.readAsText(acceptedFiles[0], 'UTF-8');
    fileReader.onload = (e) => {
      console.log('e.target.result', e.target.result);
    };
    if (acceptedFiles.length === 0 || acceptedFiles == undefined) {
      enqueueSnackbar('Invalid File Format, allowed file types are .CSV', {
        variant: 'error',
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: '.csv, application/vnd.ms-excel, text/csv',
    onDrop,
    maxFiles: 1,
    ...props,
  });

  const handlRemove = (e) => {
    e.stopPropagation();
    setUploadedFile('');
  };

  useEffect(() => {
    return () => {
      closeSnackbar();
    };
  }, []);

  return (
    <Card
      id='bulk_import_file'
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        position: 'relative',
        ...sx,
      }}
    >
      <Box position='absolute' sx={{ left: left, bottom: bottom, transform: transform }}>
        <UploadFileIcon color='primary' sx={{ fontSize: fontSize, opacity: 0.2 }} />
      </Box>
      <Container height={height} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} disabled />
        <PublishIcon color='primary' fontSize='large' />
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Typography>
            {uploadedFile == '' || uploadedFile == undefined ? (
              label
            ) : (
              <strong>{uploadedFile.name}</strong>
            )}
          </Typography>
          {uploadedFile == '' || uploadedFile == undefined ? null : (
            <IcoButton icon={<RemoveCircleIcon />} onClick={handlRemove} />
          )}
        </Stack>
      </Container>
    </Card>
  );
}

DropZone.defaultProps = {
  width: '70%',
  label: 'Choose a .CSV file or Drag and drop here',
  transform: 'rotate(45deg)',
  height: '180px',
  left: '-44px',
  bottom: '-36px',
  fontSize: '160px',
};
DropZone.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  transform: PropTypes.string,
  height: PropTypes.string,
  left: PropTypes.string,
  bottom: PropTypes.string,
  fontSize: PropTypes.string,
  uploadedFile: PropTypes.object.isRequired,
  setUploadedFile: PropTypes.func.isRequired,
};

// Readme.md

// How to use the component

{
  /* <DropZone uploadedFile={props.selectedFile} setUploadedFile={props.setSelectedFile} {...props} />; */
}

// Default Props

// width: '70%', -  We can change the width
// label: 'Choose a .CSV file or Drag and drop here', - Naming inside the component
// transform: 'rotate(45deg)', we can totate the icon
// height: '180px',   We can change the width
