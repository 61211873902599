import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mergeClasses } from '@mui/styles';
import { CallMissedSharp } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/MoveToInbox';


export default function PopularTopics() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        className={classes.heading}
        >
        Popular topics
      </Typography>
      <Box className={classes.box}>
        <Grid container item xs={12} className={classes.gred}>
          <Grid className={classes.iconsc} item xs={3}>
            <img
              id="app"
              style={{ height: 44, width: 44 }}
              src="/static/images/icons/app-integrations.png"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">App Integration</Typography>
            <Typography className={classes.content}>
              This works great when the changes can be isolated to a new DOM
              element. This works great when the changes.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container item xs={12} className={classes.gred}>
          <Grid className={classes.iconsc} item xs={3}>
            <img
              id="solution"
              style={{ height: 44, width: 44 }}
              src="/static/images/icons/Solutions.png"
              />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">Solutions</Typography>
            <Typography className={classes.content}>
              This works great when the changes can be isolated to a new DOM
              element. This works great when the changes.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container item xs={12} className={classes.gred}>
          <Grid className={classes.iconsc} item xs={3}>
            <img
              id="blogs"
              style={{ height: 44, width: 44 }}
              src="/static/images/icons/Blogs-&-update.png"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">Blog Update</Typography>
            <Typography className={classes.content}>
              This works great when the changes can be isolated to a new DOM
              element. This works great when the changes.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    width: 300,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  iconsc: {
    paddingLeft: 12,
  },
  heading: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  content: {
    fontSize: '11px',
    color: 'grey',
    marginBottom: 6,
  },
  gred: {
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 4,
    marginBottom: 4,
    border: 1,
    borderColor: 'grey',
    borderRadius: 8,
  },
}));