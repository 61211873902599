import React from 'react';

// material ui
import Grid from '@mui/material/Grid';

// project imports
import GpTwoBackForwordBtn from '../GpTwoBackForwordBtn';

export default function GuidedRoutePlanningWrapper(props) {
  return (
    <>
      {/* <Grid sx={{ px: 2 }}>
        <Planning isFromGuided={true} {...props} />
      </Grid> */}
      <Grid sx={{ mt: 3 }}>
        <GpTwoBackForwordBtn
          nextButtonTitle={'SECOND PHASE'}
          isFromSection={'Routes'}
          setCsActiveStep={props.setCsActiveStep}
          setRenderPageGPTwo={props.setSelectedSchoolId}
          renderPageGPTwo={props.selectedSchoolId}
          guidedApi={props.guidedRoutes}
          globalBtnC={props.globalBtnRoutes}
          setGlobalBtnC={props.setGloablBtnRoutes}
        />
      </Grid>
    </>
  );
}
