import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
export const StyledCalendar = styled(Calendar)`
  .rbc-header {
    font-size: 0.875rem; /* Adjust the font size as needed */
    background-color: ${(props) => props.theme.palette.background.default};
    padding-top: 5px; /* Add 20px padding on the top */
    padding-bottom: 5px; /* Add 30px padding on the bottom */
  }
  .iiynNm .rbc-off-range-bg {
    background-color: ${(props) => props.theme.palette.background.default};
  }
  .rbc-event {
    min-height: 32px !important;
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background: ${(props) => props.theme.palette.background.default};
    white-space: pre-wrap;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .rbc-today {
    background-color: ${(props) => props.theme.palette.primary.lighter};
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: ${(props) => props.theme.palette.primary.main};
    box-shadow: none;
  }
  .rbc-toolbar button {
    border-radius: '3px';
    background: ${(props) => props.theme.palette.background.default};
  }

  .rbc-off-range-bg {
    background: ${(props) => props.theme.palette.secondary.lighter};
  }

  .rbc-toolbar .rbc-toolbar-label {
    font-family: 'Roboto';
  }

  .rbc-event-label {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  .rbc-show-more {
    /* background-color: rgba(255, 255, 255, 0.3); */
    z-index: 4;
    font-weight: normal;
    font-size: 85%;
    height: auto;
    line-height: normal;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .cbAyUK .rbc-event-label {
    color: blue;
  }

  .rbc-toolbar-label {
    font-size: 18px; /* Adjust the font size as needed */
  }
  .rbc-off-range-bg {
    background: ${(props) => props.theme.palette.background.default};
  }
  .rbc-today {
    background-color: ${(props) => props.theme.palette.primary.main};
    .rbc-timeslot-group {
      background: ${(props) => props.theme.palette.background.default};
    }
  }
  .rbc-current {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .rbc-btn-group {
    button {
      color: ${(props) => props.theme.palette.text.primary};
      cursor: pointer;
    }
    .rbc-active {
      color: #fff;
      &:focus {
        color: #fff;
      }
    }
  }
  .rbc-show-more {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
