import { useState } from 'react';
//MUI
import { InputAdornment, Link, Paper, Typography, Stack, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

//FORMIK
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

//ICONS
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//PROJECT IMPORTS
import OTPInput from './OTPInput';
import { ActionButton } from 'components/Buttons';

const emailIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const instructions = [
  'Must be at least 8 characters long',
  'Must contain an uppercase and lowercase letter(A, z)',
  'Must contain a number',
  'Must contain a special character(!, @, $, #, etc)',
];

function Verification({ setAuthPage, forgotPasswordSubmit, loading, resendOtp }) {
  const [viewPassword, setViewPassword] = useState('password');
  const [viewConfirmPassword, setViewConfirmPassword] = useState('password');

  const formInitialValues = () => {
    return {
      verifyCode: '',
      password: '',
      confirmPassword: '',
    };
  };

  const validationSchema = yup.object({
    verifyCode: yup
      .string()
      .trim('')
      .required('Verification code is required')
      .min(6, 'Verification code must be 6 characters'),
    password: yup
      .string()
      .required('Please enter new password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Invalid password',
      )
      .trim(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords did not match')
      .trim()
      .required('Please re-enter your new password'),
  });

  const handleSubmit = (values) => {
    forgotPasswordSubmit(values.verifyCode, values.password);
  };

  const handleViewPassword = (field) => {
    if (field === 'password') {
      setViewPassword(viewPassword === 'text' ? 'password' : 'text');
    }
    if (field === 'confirmPassword') {
      setViewConfirmPassword(viewConfirmPassword === 'text' ? 'password' : 'text');
    }
  };

  const handleAuthForm = () => {
    setAuthPage('logIn');
  };

  const passwordIcon = {
    startAdornment: (
      <InputAdornment position='start'>
        <HttpsIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position='end'>
        <Tooltip
          placement='right'
          title={viewPassword === 'password' ? 'Show password' : 'Hide password'}
        >
          <IconButton onClick={() => handleViewPassword('password')} edge='end'>
            {viewPassword === 'password' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  const confirmPasswordIcon = {
    startAdornment: (
      <InputAdornment position='start'>
        <HttpsIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position='end'>
        <Tooltip
          placement='right'
          title={viewConfirmPassword === 'password' ? 'Show password' : 'Hide password'}
        >
          <IconButton onClick={() => handleViewPassword('confirmPassword')} edge='end'>
            {viewConfirmPassword === 'password' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='signIn'>
          <Paper
            sx={{
              p: 5,
              width: 400,
              borderRadius: 4,
              boxShadow: 10,
            }}
          >
            <Typography variant='h3' sx={{ mb: 6 }}>
              Reset your password
            </Typography>
            <Typography sx={{ mb: 6 }} variant='body1'>
              A verification code has been sent to your email address from SpotBus, please enter the
              code below.
            </Typography>
            <Stack spacing={3} sx={{ mb: 6 }}>
              <Field
                fullWidth
                hiddenLabel
                variant='filled'
                component={OTPInput}
                placeholder='Enter OTP'
                name='verifyCode'
                id='verifyCode'
                InputProps={emailIcon}
                disabled={false}
                helperText={'Please enter verification '}
                otpLength={['', '', '', '', '', '']}
              />
              <Field
                fullWidth
                hiddenLabel
                variant='filled'
                component={TextField}
                placeholder='New password'
                name='password'
                id='password'
                type={viewPassword}
                InputProps={passwordIcon}
                disabled={false}
                helperText=' '
              />
              <ul style={{ marginLeft: '-28px', marginBottom: 6 }}>
                {instructions.map((item, index) => (
                  <li key={index} style={{ fontSize: 12 }}>
                    {item}
                  </li>
                ))}
              </ul>
              <Field
                fullWidth
                hiddenLabel
                variant='filled'
                component={TextField}
                placeholder='Confirm new password'
                name='confirmPassword'
                id='confirmPassword'
                type={viewConfirmPassword}
                InputProps={confirmPasswordIcon}
                disabled={false}
                helperText=' '
              />
            </Stack>

            <ActionButton
              disabled={!formikProps.dirty}
              fullWidth
              sx={{ mb: 6 }}
              id='save'
              size={'large'}
              type='submit'
              startIcon={false}
              variant='contained'
              isLoading={loading}
            >
              Reset Password
            </ActionButton>
            <ActionButton
              fullWidth
              sx={{ mb: 6 }}
              size={'large'}
              startIcon={false}
              onClick={resendOtp}
              variant='outlined'
            >
              Resend OTP
            </ActionButton>
            <Typography sx={{ mb: 4 }} align='center' variant='body2'>
              <Link component='button' variant='body2' onClick={handleAuthForm}>
                Return to sign in
              </Link>
            </Typography>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default Verification;
