import React from 'react';

// material-ui
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// assets
import DeleteIcon from '@mui/icons-material/Delete';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, buttonLoader, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
          disabled={buttonLoader}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
}))(MuiDialogActions);

export default function DeleteAlert(props) {
  const handleClose = () => {
    props.setClose();
  };

  return (
    <div>
      <Dialog aria-labelledby='customized-dialog-title' open={props.open}>
        <DialogTitle
          id='customized-dialog-title'
          buttonLoader={props.buttonLoader}
          onClose={handleClose}
        >
          Attention!
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity='error'>
            <AlertTitle>
              <strong>Delete {props.section} ?</strong>
            </AlertTitle>
            Once Deleted, this action cannot be reversed.
          </Alert>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={props.buttonLoader}
            loadingPosition='start'
            onClick={() => props.handleDelete(props.deleteId)}
            startIcon={<DeleteIcon />}
            color='error'
            id='confirm_comm_delete'
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
