import L from 'leaflet';

export const SourceSvg = (color) => {
  const Marker = new L.DivIcon({
    iconSize: [28, 28],
    iconAnchor: [17, 46],
    popupAnchor: [1, -46],
    // iconSize: [5, 5],
    // iconAnchor: [5, 0], //[left/right, top/bottom]
    // popupAnchor: [8, 0], //[left/right, top/bottom]
    className: 'student-single-marker',
    html: `
    <svg width=${28} height=${28} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2419_32554)" fill="${color}">
    <path d="M37.967 30.6a1.5 1.5 0 1 0 -0.935 2.851c3.432 1.125 4.967 2.469 4.967 3.299 0 2.09 -7.778 5.25 -19.5 5.25 -11.722 0 -19.5 -3.16 -19.5 -5.25 0 -0.83 1.536 -2.174 4.967 -3.299a1.5 1.5 0 1 0 -0.934 -2.85C1.22 32.505 0 34.996 0 36.75 0 42.165 11.319 45 22.5 45s22.5 -2.834 22.5 -8.25c0 -1.753 -1.22 -4.243 -7.033 -6.149Z"/><path d="M21.564 38.672a1.501 1.501 0 0 0 1.873 0c0.549 -0.439 13.42 -10.891 13.42 -24.315a14.357 14.357 0 1 0 -28.714 0c0 13.424 12.872 23.876 13.42 24.315ZM17.357 14.357a5.143 5.143 0 1 1 10.286 0 5.143 5.143 0 0 1 -10.286 0Z"/>
    </g>
    </svg>`, // SVG image wrapped in a div
  });
  return Marker;
};

// NEW SOURCE MARKER
export const SourceMarker = (color) => {
  const Marker = new L.DivIcon({
    iconSize: [28, 28],
    iconAnchor: [17, 46],
    popupAnchor: [1, -46],
    className: 'student-single-marker',
    html: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6847_52584)">
    <circle cx="18" cy="14" r="14" fill="white"/>
    <g clip-path="url(#clip0_6847_52584)">
    <path d="M19.9946 16.7652C19.9193 16.7569 19.8431 16.7638 19.7706 16.7854C19.698 16.8071 19.6306 16.8431 19.5722 16.8914C19.5138 16.9396 19.4657 16.999 19.4307 17.0662C19.3958 17.1334 19.3746 17.2069 19.3686 17.2824C19.3614 17.3574 19.3691 17.4332 19.3912 17.5053C19.4132 17.5774 19.4493 17.6445 19.4974 17.7026C19.5454 17.7607 19.6044 17.8088 19.6711 17.8441C19.7377 17.8794 19.8107 17.9013 19.8858 17.9083C23.2938 18.2342 25.1094 19.2248 25.1094 19.8143C25.1094 20.6147 22.1316 21.8245 17.6427 21.8245C13.1539 21.8245 10.1761 20.6147 10.1761 19.8143C10.1761 19.2248 11.9917 18.2342 15.3997 17.9083C15.4748 17.9013 15.5477 17.8794 15.6144 17.8441C15.681 17.8088 15.74 17.7607 15.7881 17.7026C15.8361 17.6445 15.8722 17.5774 15.8943 17.5053C15.9164 17.4332 15.9241 17.3574 15.9168 17.2824C15.9109 17.2069 15.8899 17.1333 15.8549 17.0661C15.82 16.9989 15.7719 16.9394 15.7135 16.8911C15.6551 16.8429 15.5876 16.8068 15.515 16.7852C15.4424 16.7636 15.3662 16.7568 15.2909 16.7652C12.2626 17.0546 9.02734 18.0076 9.02734 19.8143C9.02734 21.8879 13.3614 22.9732 17.6427 22.9732C21.9241 22.9732 26.2581 21.8879 26.2581 19.8143C26.2581 18.0076 23.0229 17.0546 19.9946 16.7652Z" fill="${color}"/>
    <path d="M17.0673 13.7381V20.102C17.0673 20.2543 17.1278 20.4004 17.2355 20.5081C17.3432 20.6159 17.4893 20.6764 17.6416 20.6764C17.7939 20.6764 17.94 20.6159 18.0477 20.5081C18.1555 20.4004 18.216 20.2543 18.216 20.102V13.7381C19.222 13.5929 20.1357 13.072 20.7732 12.2803C21.4107 11.4885 21.7246 10.4847 21.6518 9.47088C21.579 8.45702 21.1249 7.50836 20.3809 6.81583C19.6368 6.12329 18.6581 5.73828 17.6416 5.73828C16.6251 5.73828 15.6464 6.12329 14.9024 6.81583C14.1583 7.50836 13.7042 8.45702 13.6314 9.47088C13.5586 10.4847 13.8726 11.4885 14.51 12.2803C15.1475 13.072 16.0612 13.5929 17.0673 13.7381ZM17.6416 7.7533C17.812 7.7533 17.9786 7.80382 18.1203 7.89849C18.2619 7.99316 18.3724 8.12771 18.4376 8.28514C18.5028 8.44256 18.5198 8.61579 18.4866 8.78291C18.4534 8.95003 18.3713 9.10355 18.2508 9.22403C18.1303 9.34452 17.9768 9.42658 17.8097 9.45982C17.6426 9.49306 17.4693 9.476 17.3119 9.41079C17.1545 9.34558 17.0199 9.23516 16.9253 9.09348C16.8306 8.9518 16.7801 8.78523 16.7801 8.61483C16.7803 8.38642 16.8712 8.16743 17.0327 8.00591C17.1942 7.84439 17.4132 7.75354 17.6416 7.7533Z" fill="${color}"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_d_6847_52584" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6847_52584"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6847_52584" result="shape"/>
    </filter>
    <clipPath id="clip0_6847_52584">
    <rect width="17.2308" height="17.2308" fill="white" transform="translate(9.02734 5.74219)"/>
    </clipPath>
    </defs>
    </svg>
    `, // SVG image wrapped in a div
  });
  return Marker;
};
