import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PublishIcon from '@mui/icons-material/Publish';
import { Grid, Paper, Typography, Button } from '@mui/material';
import styled from 'styled-components';

// Assets
import DownloadIcon from '@mui/icons-material/Download';

// Global Components
import DropZone from 'components/dropzones/DropZone';
import ActionButton from 'components/Buttons/ActionButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: theme.spacing(1),
    width: '100%',
    height: theme.spacing(66),
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    width: '70%',
  },
}));

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#e5b300';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#bdbdbd';
};

const getBgColor = (props) => {
  if (props.isDragAccept) {
    return '#ffecb3';
  }
  if (props.isDragReject) {
    return '#ffcdd2';
  }
  if (props.isDragActive) {
    return '#ffecb3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => getBgColor(props)};
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 180px;
`;

function MyDropzone(props) {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    props.setSelectedFile(acceptedFiles[0]);
    // console.log('From Render Select files');
    // console.log(acceptedFiles[0]);
    const fileReader = new FileReader();
    fileReader.readAsText(acceptedFiles[0], 'UTF-8');
    fileReader.onload = (e) => {
      console.log('e.target.result', e.target.result);
      //   setFiles(e.target.result);
    };
    if (acceptedFiles.length === 0 || acceptedFiles == undefined) {
      props.setErrorMessage('Invalid File Format, allowed file types are .CSV');
      props.setShowErrorSnackBar(true);
      setTimeout(() => props.setShowErrorSnackBar(false), 3500);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: '.csv, application/vnd.ms-excel, text/csv',
    onDrop,
    maxFiles: 1,
  });

  return (
    <div id='bulk_import_driver_file' className={classes.container}>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <PublishIcon color='primary' fontSize='large' />
        <Typography>
          {props.selectedFile == '' || props.selectedFile == undefined ? (
            'Choose a .CSV file or Drag and drop here'
          ) : (
            <strong>{props.selectedFile.name}</strong>
          )}
        </Typography>
      </Container>
    </div>
  );
}

export default function RenderSelectFile(props) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.grid}>
          {/* Code Depricated */}
          {/* <MyDropzone
                        selectedFile={props.selectedFile}
                        setSelectedFile={props.setSelectedFile}
                        {...props}
                    /> */}
          <DropZone
            uploadedFile={props.selectedFile}
            setUploadedFile={props.setSelectedFile}
            {...props}
          />
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <ActionButton
            isIcon={true}
            startIcon={<DownloadIcon />}
            onClick={() => props.templateFileDownload()}
            id='bulkImportDriver'
          >
            DOWNLOAD TEMPLATE FILE
          </ActionButton>
          {/* Code Depricated */}
          {/* <Button color='primary' variant='contained' onClick={() => props.templateFileDownload()}>
            Download Template File
          </Button> */}
        </Grid>
      </Grid>
    </Paper>
  );
}
