import { Grid } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { TileLayer, Marker, MapContainer, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';

const SchoolLocationMap = React.memo(
  ({ location, setSelectedLocation, handleGeoLocAddress, defaultPosition }) => {
    const schoolMarkerIcon = new L.Icon({
      iconUrl: '/static/images/icons/outline_school.png',
      iconSize: [55, 55],
      iconAnchor: [17, 46], //[left/right, top/bottom]
      popupAnchor: [0, -46], //[left/right, top/bottom]
    });

    function ChangeMapView({ coords, onClick }) {
      const map = useMap();
      useMapEvents({
        click(e) {
          handleGeoLocAddress(e.latlng.lng, e.latlng.lat);
          setSelectedLocation([e.latlng.lat, e.latlng.lng]);
        },
      });

      map.flyTo(coords, 17, {
        duration: 0.5,
      });

      return null;
    }

    function DraggableMarker(props) {
      const markerRef = useRef(null);

      const handleDragEnd = useCallback((event) => {
        const marker = event.target.getLatLng();
        handleGeoLocAddress(marker.lng, marker.lat);
        setSelectedLocation([marker.lat, marker.lng]);
      }, []);

      return (
        <Marker
          draggable={true}
          eventHandlers={{
            dragend: handleDragEnd,
          }}
          position={props.position}
          ref={markerRef}
          icon={schoolMarkerIcon}
        ></Marker>
      );
    }

    return (
      <Grid sx={{ width: 700 }}>
        <MapContainer
          center={location ?? defaultPosition}
          zoom={17}
          minZoom={5}
          maxZoom={17}
          scrollWheelZoom
          style={{ height: window.innerHeight * 0.5, window: window.innerWidth * 1, zIndex: 1 }}
        >
          <ChangeMapView
            coords={location ?? defaultPosition}
            position={location ?? defaultPosition}
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {location && (
            <DraggableMarker
              position={location ?? defaultPosition}
              positionLocal={location ?? defaultPosition}
              setSelectedLocation={setSelectedLocation}
            />
          )}
        </MapContainer>
      </Grid>
    );
  },
);

export default SchoolLocationMap;
