import React from 'react';
import PropTypes from 'prop-types';

// MUI imports
import Typography from '@mui/material/Typography';

//Projects Imports
import { CustomTooltip } from 'components/tooltips';

// ============================== Subheading  ============================== //
function Subheading({ children, variant, toolTipContent, enableTooltip, ...props }) {
  return (
    <CustomTooltip title={toolTipContent} sx={{ display: enableTooltip ? 'block' : 'none' }}>
      <Typography variant={variant} {...props}>
        {children}
      </Typography>
    </CustomTooltip>
  );
}

Subheading.defaultProps = {
  variant: 'h4',
  children: 'Subheading',
  toolTipContent: 'Subheading',
  enableTooltip: false,
};

Subheading.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.string,
  toolTipContent: PropTypes.string,
  enableTooltip: PropTypes.bool,
};

export default Subheading;

//USE case
{
  /* <Subheading enableTooltip={true} toolTipContent='toolTipContent'>
  Subheading
</Subheading>; */
}

//Props we can pass
// variant: change for the variant
// children: Subheading
// toolTipContent: tooltip content
// enableTooltip: to show the tooltip
