import * as React from 'react';

// material ui
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { Grid, CardMedia, Button, Divider, ListItemIcon } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

// third party imports
import { Auth } from 'aws-amplify';

// assets
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// Query
import { useQueryClient } from '@tanstack/react-query';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Topbar = (props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [anchorElUser, setAnchorElUser] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(!anchorElUser);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function onSignOutClick() {
    queryClient.clear();
    await Auth.signOut()
      .then((data) => {
        console.log(data);
        sessionStorage.clear();
        localStorage.clear();
      })
      .catch((err) => console.log(err));
  }

  return (
    <AppBar position='fixed' color='inherit' elevation={0} open={false}>
      <Container maxWidth='100vw'>
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid sx={{ display: 'flex' }}>
            <Grid item xs={4}>
              <CardMedia
                sx={{ height: 40, pl: 8.5 }}
                src={require('../../../assets/static/images/SpotBus_Logo.png')}
                component='img'
              />
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', pl: 4 }}>
              <Divider
                orientation='vertical'
                sx={{
                  backgroundColor:
                    theme.palette.mode === 'light' ? 'secondary.darker' : 'secondary.lighter',
                }}
              />
              <Typography
                variant='h3'
                sx={{
                  pl: 4,
                  pt: 1,
                  color: theme.palette.mode === 'light' ? 'secondary.darker' : 'secondary.lighter',
                  fontWeight: 400,
                }}
              >
                Account Setup
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {props.authData && (
              <Button
                size='small'
                color='secondary'
                onClick={handleOpenUserMenu}
                sx={{ borderRadius: 2 }}
              >
                <Avatar>
                  {props.authData?.attributes.given_name[0] +
                    props.authData?.attributes.family_name[0]}
                </Avatar>
                <Typography
                  variant='h4'
                  textAlign='center'
                  sx={{
                    pl: 2,
                    color:
                      theme.palette.mode === 'light' ? 'secondary.darker' : 'secondary.lighter',
                    fontWeight: 400,
                  }}
                >
                  {props.authData?.attributes.given_name +
                    ' ' +
                    props.authData?.attributes.family_name}
                </Typography>
              </Button>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'signout'} onClick={onSignOutClick}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                <Typography textAlign='center'>Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
