import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from '@mui/material';

export default function BaseSingleSelect({
  label,
  options = [],
  value,
  setValue,
  selectProps = {},
  ...props
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Stack>
      <FormControl fullWidth {...props}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} label={label} onChange={handleChange} {...selectProps}>
          <MenuItem value=''>None</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
