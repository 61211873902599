import { convertDateFormat } from 'containers/layouts/Utils/TimeHandling';
import moment from 'moment';
import { DATE_OCCURRENCE } from './formInitialValuesDriverShifts';
import { SpotBusDateTime } from 'utils/spotbus-date-time';

const localToUST = (time) => {
  return new SpotBusDateTime(time).localTimeToUTC();
};

export const requestFactory = ({ values, selectedDriverUserId }) => {
  const weeklyData = [
    {
      isChecked: values.mondayCheckBox,
      startTime: values.mondayStartTime,
      endTime: values.mondayEndTime,
      day: 'Monday',
    },
    {
      isChecked: values.tuesdayCheckBox,
      startTime: values.tuesdayStartTime,
      endTime: values.tuesdayEndTime,
      day: 'Tuesday',
    },
    {
      isChecked: values.wednesdayCheckBox,
      startTime: values.wednesdayStartTime,
      endTime: values.wednesdayEndTime,
      day: 'Wednesday',
    },
    {
      isChecked: values.thursdayCheckBox,
      startTime: values.thursdayStartTime,
      endTime: values.thursdayEndTime,
      day: 'Thursday',
    },
    {
      isChecked: values.fridayCheckBox,
      startTime: values.fridayStartTime,
      endTime: values.fridayEndTime,
      day: 'Friday',
    },
    {
      isChecked: values.saturdayCheckBox,
      startTime: values.saturdayStartTime,
      endTime: values.saturdayEndTime,
      day: 'Saturday',
    },
    {
      isChecked: values.sundayCheckBox,
      startTime: values.sundayStartTime,
      endTime: values.sundayEndTime,
      day: 'Sunday',
    },
  ];

  const startTime = localToUST(values.adhocStartTime);
  const endTime = localToUST(values.adhocEndTime);
  const nonRepetitive = {
    clock_in_time: moment(startTime, 'HH:mm:ss').format('HH:mm:ss'),
    clock_out_time: moment(endTime, 'HH:mm:ss').format('HH:mm:ss'),
    clock_in_before: values.earlyClockInTime,
  };

  const _weeklyData = weeklyData
    .filter((ele) => ele.isChecked === true)
    .map((data, index) => {
      const startTime = localToUST(data.startTime);
      const endTime = localToUST(data.endTime);
      return {
        day: data.day,
        clock_in_time: moment(startTime, 'HH:mm:ss').format('HH:mm:ss'),
        clock_out_time: moment(endTime, 'HH:mm:ss').format('HH:mm:ss'),
        clock_in_before: values.earlyClockInTime,
      };
    });
  const monthlyData =
    values.monthlyDate &&
    values.monthlyDate.map((data) => {
      const clockIn = localToUST(data.clockIn);
      const clockOut = localToUST(data.clockOut);
      return {
        date: data.startDateMonthly,
        clock_in_time: moment(clockIn, 'HH:mm:ss').format('HH:mm:ss'),
        clock_out_time: moment(clockOut, 'HH:mm:ss').format('HH:mm:ss'),
        clock_in_before: values.earlyClockInTime,
      };
    });
  const body = {
    driver_id: selectedDriverUserId, // primary key
    title: values.shiftTitle,
    date_range: {
      start_date: new SpotBusDateTime(values.startDate).localTimeToUTC(),
      end_date: new SpotBusDateTime(values.endDate).localTimeToUTC(),
    },
    recurrence_pattern: {
      recurrence_type: values.dateOccurrence,
      weekly: _weeklyData,
      monthly: monthlyData,
      nonrepetitive: nonRepetitive,
    },
  };
  if (values.dateOccurrence === DATE_OCCURRENCE.MONTHLY) {
    body.recurrence_pattern.weekly = [];
  }
  if (values.dateOccurrence === DATE_OCCURRENCE.WEEKLY) {
    body.recurrence_pattern.monthly = [];
  }
  if (values.dateOccurrence === DATE_OCCURRENCE.ADHOC) {
    body.recurrence_pattern.monthly = [];
    body.recurrence_pattern.weekly = [];
  }
  if (
    values.dateOccurrence === DATE_OCCURRENCE.MONTHLY ||
    values.dateOccurrence === DATE_OCCURRENCE.WEEKLY
  ) {
    delete body.recurrence_pattern.nonrepetitive;
  }
  return { ...body };
};

const initialValueWeekly = {
  mondayCheckBox: true,
  mondayStartTime: null,
  mondayEndTime: null,
  tuesdayCheckBox: true,
  tuesdayStartTime: null,
  tuesdayEndTime: null,
  wednesdayCheckBox: true,
  wednesdayStartTime: null,
  wednesdayEndTime: null,
  thursdayCheckBox: true,
  thursdayStartTime: null,
  thursdayEndTime: null,
  fridayCheckBox: true,
  fridayStartTime: null,
  fridayEndTime: null,
  saturdayCheckBox: false,
  saturdayStartTime: null,
  saturdayEndTime: null,
  sundayCheckBox: false,
  sundayStartTime: null,
  sundayEndTime: null,
};
export function combineDateAndTime(timeString) {
  // Parse the date using Moment.js
  const momentDate = moment();

  // Parse the time using Moment.js
  const momentTime = moment(timeString, 'HH:mm:ss');

  // Set the time part of the date
  momentDate.set({
    hour: momentTime.hours(),
    minute: momentTime.minutes(),
    second: momentTime.seconds(),
  });

  // Get the combined Date object
  const combinedDateTime = momentDate.toDate();

  return combinedDateTime;
}
/**
 * Generates events from driver shifts.
 *
 * @param {Array} events - The array of events.
 * @return {Array} The modified array of events.
 */
export const generateEventsFromDriverShifts = (events) => {
  const modifiedData = events.map((data) => {
    /**
     * Retrieves the early clock-in time based on the recurrence pattern.
     * getEarlyClockInTime. It checks the recurrence pattern of an event and returns the early clock-in time in minutes.
     * If the recurrence type is weekly,
     * it retrieves the clock-in time from the weekly array of the recurrence_pattern object.
     * Otherwise, it retrieves it from the monthly array.
     * @return {number} The early clock-in time in minutes.
     */
    const getEarlyClockInTime = () => {
      try {
        if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY) {
          return data.recurrence_pattern.weekly[0].clock_in_before;
        }
        if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY) {
          return data.recurrence_pattern.monthly[0].clock_in_before;
        }
        if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.ADHOC) {
          return data.recurrence_pattern.nonrepetitive.clock_in_before;
        }
      } catch (error) {
        return null;
      }
    };
    const monthlyDate = data.recurrence_pattern.monthly.map((ele) => {
      return {
        startDateMonthly: ele.date,
        clockIn: combineDateAndTime(ele.clock_in_time),
        clockOut: combineDateAndTime(ele.clock_out_time),
      };
    });

    const monday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Monday');
    const tuesday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Tuesday');
    const wednesday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Wednesday');
    const thursday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Thursday');
    const friday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Friday');
    const saturday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Saturday');
    const sunday = data.recurrence_pattern.weekly.find((ele) => ele.day === 'Sunday');

    if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY) {
      return {
        ...data,
        ...initialValueWeekly,
        shiftTitle: data.title,
        startDate: new Date(data.date_range.start_date),
        endDate: new Date(data.date_range.end_date),

        dateOccurrence: data.recurrence_pattern.recurrence_type,
        occurenceType:
          Boolean(data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY) ||
          Boolean(data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY)
            ? 'regular'
            : DATE_OCCURRENCE.ADHOC,
        earlyClockInTime: getEarlyClockInTime(),
        monthlyDate: monthlyDate,
        shiftID: data.id,
      };
    }

    if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.ADHOC) {
      return {
        ...data,
        ...initialValueWeekly,
        shiftTitle: data.title,
        startDate: new Date(data.date_range.start_date),
        endDate: new Date(data.date_range.end_date),

        dateOccurrence: data.recurrence_pattern.recurrence_type,
        occurenceType:
          Boolean(data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY) ||
          Boolean(data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY)
            ? 'regular'
            : DATE_OCCURRENCE.ADHOC,
        earlyClockInTime: getEarlyClockInTime(),
        adhocStartTime: combineDateAndTime(data.recurrence_pattern.nonrepetitive.clock_in_time),
        adhocEndTime: combineDateAndTime(data.recurrence_pattern.nonrepetitive.clock_out_time),
        shiftID: data.id,
      };
    }

    if (data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY) {
      return {
        ...data,
        shiftTitle: data.title,
        startDate: new Date(data.date_range.start_date),
        endDate: new Date(data.date_range.end_date),

        dateOccurrence: data.recurrence_pattern.recurrence_type,
        occurenceType:
          data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY ||
          data.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY
            ? 'regular'
            : DATE_OCCURRENCE.ADHOC,
        earlyClockInTime: getEarlyClockInTime(),
        monthlyDate: [],
        shiftID: data.id,

        mondayCheckBox: Boolean(monday),
        mondayStartTime: Boolean(monday) ? combineDateAndTime(monday.clock_in_time) : null,
        mondayEndTime: Boolean(monday) ? combineDateAndTime(monday.clock_out_time) : null,

        tuesdayCheckBox: Boolean(tuesday),
        tuesdayStartTime: Boolean(tuesday) ? combineDateAndTime(tuesday.clock_in_time) : null,
        tuesdayEndTime: Boolean(tuesday) ? combineDateAndTime(tuesday.clock_out_time) : null,

        wednesdayCheckBox: Boolean(wednesday),
        wednesdayStartTime: Boolean(wednesday) ? combineDateAndTime(wednesday.clock_in_time) : null,
        wednesdayEndTime: Boolean(wednesday) ? combineDateAndTime(wednesday.clock_out_time) : null,

        thursdayCheckBox: Boolean(thursday),
        thursdayStartTime: Boolean(thursday) ? combineDateAndTime(thursday.clock_in_time) : null,
        thursdayEndTime: Boolean(thursday) ? combineDateAndTime(thursday.clock_out_time) : null,

        fridayCheckBox: Boolean(friday),
        fridayStartTime: Boolean(friday) ? combineDateAndTime(friday.clock_in_time) : null,
        fridayEndTime: Boolean(friday) ? combineDateAndTime(friday.clock_out_time) : null,

        saturdayCheckBox: Boolean(saturday),
        saturdayStartTime: Boolean(saturday) ? combineDateAndTime(saturday.clock_in_time) : null,
        saturdayEndTime: Boolean(saturday) ? combineDateAndTime(saturday.clock_out_time) : null,

        sundayCheckBox: Boolean(sunday),
        sundayStartTime: Boolean(sunday) ? combineDateAndTime(sunday.clock_in_time) : null,
        sundayEndTime: Boolean(sunday) ? combineDateAndTime(sunday.clock_out_time) : null,
      };
    }
    return null;
  });
  return modifiedData;
};
