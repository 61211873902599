import { Stack, Typography } from '@mui/material';
import React from 'react';

export const TableHeadInsideIcoWtext = ({ icon, title }) => {
  return (
    <Stack direction='row' alignItems={'center'}>
      <Stack mr={1} color={'primary.main'}>
        {icon}
      </Stack>
      <Typography variant='caption' sx={{ fontWeight: '500' }}>
        {' '}
        {title}
      </Typography>
    </Stack>
  );
};
