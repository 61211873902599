import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

// assets
import AbcIcon from '@mui/icons-material/Abc';

// Custom components
import { CustomTooltip } from 'components/tooltips';

// ============================== Default Button ============================== //
function DefaultButton({
  children,
  isIcon,
  startIcon,
  sx,
  loaderButton,
  tooltipContent,
  placement,
  ...props
}) {
  /**
   * * Usage guide:
   * This global component is wrapper over MUI DefaultButton component, it can be used to implementation button components 
   * Support tooltips out of the box and LoadingButton, when passed as children component

   * example usage
   * default - <DefaultButton{refer MUI Button and LoadingButton documentation for all accepted props} >{children}</DefaultButton>
   * @returns <Button>{children}</Button> or <LoadingButton>{children}</LoadingButton/>
   */
  const styles = useMemo(
    () => ({ textTransform: 'capitalize', whiteSpace: 'nowrap', ...sx }),
    [sx],
  );
  const Component = useMemo(() => (loaderButton ? LoadingButton : Button), [loaderButton]);

  return tooltipContent ? (
    <CustomTooltip arrow title={tooltipContent} placement={placement}>
      <Component startIcon={isIcon && startIcon} sx={styles} {...props}>
        {children}
      </Component>
    </CustomTooltip>
  ) : (
    <>
      <Component startIcon={isIcon && startIcon} sx={styles} {...props}>
        {children}
      </Component>
    </>
  );
}

DefaultButton.defaultProps = {
  children: 'ButtonName',
  loaderButton: false,
  variant: 'text',
  size: 'medium',
  color: 'primary',
  isIcon: true,
  startIcon: <AbcIcon />,
  enableTooltip: false,
  tooltipTitle: '',
};

DefaultButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isIcon: PropTypes.bool,
  startIcon: PropTypes.node,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DefaultButton;
