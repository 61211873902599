import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import L from 'leaflet';
import { TileLayer, Polyline, Marker, Popup, MapContainer, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import '../../Utils/popup.css';
import MapChip from './MapChip';
import { getTimewithSeconds, getDateAccordingTimeZone } from '../../Utils/ZoneHandling';

// styles define
const useStyles = makeStyles((theme) => ({
  schoolTooltip: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  popupContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #37474f',
    backgroundColor: theme.palette.secondary.main,
    height: 25,
    width: 25,
    borderRadius: '50%',
  },
  popupSchool: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #37474f',
    backgroundColor: theme.palette.secondary.main,
  },
  popupText: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  popupText1: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

const stopMarkerIcon = new L.Icon({
  iconUrl: '/static/images/icons/sb-mappin-black.svg',
  iconSize: [35, 35],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const ActualStopMarkerIcon = new L.Icon({
  iconUrl: '/static/images/icons/on-time.png',
  iconSize: [10, 10],
});

const schoolMarkerIcon = new L.Icon({
  iconUrl: '/static/images/icons/outline_school.png',
  iconSize: [55, 55],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const sourceMarker = new L.Icon({
  iconUrl: '/static/images/icons/startIcon.png',
  iconSize: [35, 35],
});

const destinationMarker = new L.Icon({
  iconUrl: '/static/images/icons/flag.png',
  iconSize: [35, 35],
});

function PlotSourceDestinationMarkers(props) {
  let source =
    props.routeGraph !== undefined
      ? props.routeGraph.route_info.route_graph.find((e) => e.route_stop_id === 'source')
      : null;
  let destination =
    props.routeGraph !== undefined
      ? props.routeGraph.route_info.route_graph.find((e) => e.route_stop_id === 'destination')
      : null;

  return (
    <>
      <Marker
        key='source-marker'
        position={[Number(source.geo_location.latitude), Number(source.geo_location.longitude)]}
        icon={sourceMarker}
      >
        <Popup>{source.address}</Popup>
      </Marker>
      <Marker
        key='destination-marker'
        position={[
          Number(destination.geo_location.latitude),
          Number(destination.geo_location.longitude),
        ]}
        icon={destinationMarker}
      >
        <Popup>{destination.address}</Popup>
      </Marker>
    </>
  );
}

function getCenter(props) {
  let schoolStop =
    props.routeGraph !== undefined
      ? props.routeGraph.route_info.route_graph.find((e) => e.route_stop_id === 'source')
      : null;
  return [Number(schoolStop.geo_location.latitude), Number(schoolStop.geo_location.longitude)];
}

function ChangeMapView({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
}

function PlotSchoolMarker(props) {
  const classes = useStyles();
  let school =
    props.selectedSchool === undefined && props.routeGraph !== undefined
      ? props.routeGraph?.route_info.route_graph.find((e) => e.route_stop_id === 'source')
      : props.selectedSchool[0];

  return (
    <>
      {school === undefined ? null : (
        <Marker
          key={school?.school_id}
          position={[Number(school?.geo_location.latitude), Number(school?.geo_location.longitude)]}
          icon={schoolMarkerIcon}
        >
          <Tooltip
            direction='bottom'
            offset={[11, -2]}
            opacity={0.8}
            permanent
            className={classes.popupSchool}
          >
            <span className={classes.schoolTooltip}>{school?.address.entity}</span>
          </Tooltip>
        </Marker>
      )}
    </>
  );
}

function getETA(timestamp, timeZone) {
  return getTimewithSeconds(getDateAccordingTimeZone(timestamp * 1000, timeZone));
}

function GetStopsMarkers(props, getStatusColor) {
  const classes = useStyles();

  return props.routeGraph?.route_info.route_graph.map((routeStop, index) => {
    if (routeStop.is_school === true) return;

    return (
      <div key={index}>
        <Marker
          key={index}
          position={[
            Number(routeStop.geo_location.latitude),
            Number(routeStop.geo_location.longitude),
          ]}
          icon={stopMarkerIcon}
        >
          <Popup className='request-popup leaflet-popup-content-wrapper'>
            <span className={classes.popupText1}>
              <Typography display='inline' variant='button' gutterBottom>
                Status{' '}
              </Typography>
              {/* <Chip
                size='small'
                color='primary'
                label={routeStop.status_str}
                style={{
                  backgroundColor: getStatusColor(routeStop.status),
                  fontWeight: '500',
                  color: '#fff',
                  marginTop: -3,
                }}
              /> */}
              <Typography
                display='inline'
                variant='button'
                gutterBottom
                style={{
                  color: getStatusColor(routeStop.status),
                  fontWeight: 'bold',
                }}
              >
                {routeStop.status_str}
              </Typography>
              <br />
              <Typography display='inline' variant='button' gutterBottom>
                ETA{' '}
              </Typography>
              <Typography display='inline'>
                {routeStop.eta
                  ? getETA(routeStop.planned_arrival_time.timestamp, props.timeZone)
                  : routeStop.registered_arrival_time}
              </Typography>
            </span>
          </Popup>
          <Tooltip
            offset={[0, -11]}
            opacity={0.7}
            permanent
            direction='bottom'
            className={classes.popupContent}
          >
            <span className={classes.popupText}>{routeStop.route_stop_id}</span>
          </Tooltip>
        </Marker>
      </div>
    );
  });
}

function plotPlannedPathPolyline(props) {
  let tripPolyline = props.routeGraph.route_info.overview_polyline.decoded_points;
  const blackOptions = { color: 'black', opacity: 0.9, weight: 3 };
  return <Polyline pathOptions={blackOptions} positions={tripPolyline} />;
}

function plotActualPathPolyline(props) {
  return props.polylineData?.actual_route_path_geocodes.map((geocodes, index) => {
    return (
      <div key={index}>
        <Marker
          key={index}
          position={[Number(geocodes[0]), Number(geocodes[1])]}
          icon={ActualStopMarkerIcon}
        ></Marker>
      </div>
    );
  });
}

export function RenderOpenStreetMap(props) {
  const theme = useTheme();
  const STATUS_COLOR = {
    ArrivingEarly: '#5cb85c',
    OnTime: theme.palette.primary.main,
    RunningLate: theme.palette.error.main,
  };

  function getStatusColor(status) {
    if (status in STATUS_COLOR) {
      return STATUS_COLOR[status];
    }
    return 'secondary';
  }

  return (
    <div>
      <MapChip {...props} />
      <MapContainer
        center={getCenter(props)}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: window.innerHeight * 0.59, width: '100', zIndex: 1 }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {PlotSchoolMarker(props)}
        {GetStopsMarkers(props, getStatusColor)}
        {props.showPlannedPath && plotPlannedPathPolyline(props)}
        {props.showActualPath && plotActualPathPolyline(props)}
        {PlotSourceDestinationMarkers(props)}
        <ChangeMapView coords={getCenter(props)} />
      </MapContainer>
    </div>
  );
}
