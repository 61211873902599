import React, { forwardRef } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'actions/menuActions';

// contexts and custom hooks
import { useDrawer } from 'contexts';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { drawerOpen } = useDrawer();

  const isDark = theme.palette.mode === 'dark';

  const isSelected = document.location.pathname === item.url;

  const textIconColor = isDark
    ? 'inherit'
    : isSelected
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }));
  };

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const Icon = isSelected ? item.icons?.filled : item.icons?.outlined;
  const itemIcon = item.icons ? <Icon /> : false;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      // onClick={() => itemHandler(item.id)}
      selected={isSelected}
      id={item.id}
      sx={{
        mr: 2,
        py: 0.8,
        borderTopRightRadius: theme.shape.borderRadius * 3,
        borderBottomRightRadius: theme.shape.borderRadius * 3,
        borderLeft: `4px solid ${theme.palette.hover.paper}`,
        '&:hover': {
          borderLeft: `4px solid ${theme.palette.hover.default}`,
        },
        '&.Mui-selected': {
          borderLeft: `4px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          id={item.id}
          sx={{
            color: textIconColor,
            ...(!drawerOpen && {
              width: 36,
              height: 36,
              alignItems: 'center',
            }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      {drawerOpen && (
        <ListItemText
          sx={{ pl: level > 1 ? `${level * 28}px` : '' }}
          primary={
            <Typography sx={{ color: textIconColor, fontWeight: isSelected ? 500 : '' }}>
              {item.title}
            </Typography>
          }
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
