import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormHeader } from 'components/SectionForms';
import SchoolLocationMap from './SchoolLocationMap';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Divider, DialogActions, InputAdornment } from '@mui/material';
import { SaveButton } from 'components/Buttons';
import RoomIcon from '@mui/icons-material/Room';
import SearchIcon from '@mui/icons-material/Search';
import { reverse_geocode } from './Nominatim';

export default function AddLocation({
  editLocationData,
  open,
  handleClose,
  edit,
  handleGetAddress,
  address,
  selectedAddress,
  setSelectedAddress,
  handleAddAddress,
  setEditLocationData,
  formName,
  defaultPosition,
  addAddress,
}) {
  const [addresses, setAddresses] = useState([]);
  const [entity, setEntity] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [zipCode, setZipCode] = useState();
  const [aptPlot, setAptPlot] = useState();
  const [proceed, setProceed] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  function generateID() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomId = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters[randomIndex];
    }
    return randomId;
  }

  const handleGeoLocAddress = async (lon, lat) => {
    try {
      let response = await reverse_geocode(lon, lat);
      setAddresses({
        display_name: response.data.display_name,
      });
      setSelectedLocation([response.data.lat, response.data.lon]);
      setStreet(response.data.address.road === undefined ? '' : response.data.address.road);
      setCity(response.data.address.city === undefined ? '' : response.data.address.city);
      setState(response.data.address.state === undefined ? '' : response.data.address.state);
      setCountry(response.data.address.country === undefined ? '' : response.data.address.country);
      setZipCode(
        response.data.address.postcode === undefined ? '' : response.data.address.postcode,
      );
      setAptPlot(
        response.data.address.house_number === undefined ? '' : response.data.address.house_number,
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (editLocationData !== undefined) {
      setAddresses({
        display_name:
          editLocationData?.address?.apt_plot +
          ' ' +
          editLocationData?.address?.street +
          ' ' +
          editLocationData?.address?.city +
          ' ' +
          editLocationData?.address?.state +
          ', ' +
          editLocationData?.address?.country +
          ', ' +
          editLocationData?.address?.zip_code,
      });
      setStreet(editLocationData?.address?.street);
      setCity(editLocationData?.address?.city);
      setState(editLocationData?.address?.state);
      setCountry(editLocationData?.address?.country);
      setZipCode(editLocationData?.address?.zip_code);
      setEntity(editLocationData?.address?.entity);
      setAptPlot(editLocationData?.address?.apt_plot);
      setSelectedLocation([
        editLocationData?.geo_location?.latitude,
        editLocationData?.geo_location?.longitude,
      ]);
    } else {
      clearStates();
    }
  }, [editLocationData]);

  const clearStates = () => {
    setStreet('');
    setCity('');
    setState('');
    setCountry('');
    setZipCode('');
    setEntity('');
    setAptPlot('');
    setProceed('');
    setAddresses({ display_name: '' });
    setEditLocationData();
    setSelectedLocation();
  };

  const closeForm = () => {
    handleClose();
    clearStates();
  };

  const handleSearch = (event) => {
    const addValue = event.target.value;
    setSearchTerm(event.target.value);
    setAddresses({
      display_name: addValue,
    });
  };

  const handleSearchApi = (event) => {
    if (event.key === 'Enter') {
      handleGetAddress(searchTerm);
    }
  };

  const handleSelectedData = (event, newValue) => {
    if (newValue !== null) {
      setAddresses(newValue);
    }

    setSelectedLocation([newValue.lat, newValue.lon]);
    setStreet(newValue.address.road === undefined ? '' : newValue.address.road);
    setCity(newValue.address.city === undefined ? '' : newValue.address.city);
    setState(newValue.address.state === undefined ? '' : newValue.address.state);
    setCountry(newValue.address.country === undefined ? '' : newValue.address.country);
    setZipCode(newValue.address.postcode === undefined ? '' : newValue.address.postcode);
    setAptPlot(newValue.address.house_number === undefined ? '' : newValue.address.house_number);
  };

  const handleEntity = (event) => {
    const entityValue = event.target.value;
    setEntity(entityValue);
  };

  const handleLocation = () => {
    setProceed(true);
    const entityValid = entity.trim();
    setEntity(entityValid);
    const addValid = addresses.display_name.trim();
    setAddresses({
      display_name: addValid,
    });
    if (addAddress ? '--' : entityValid !== '' && selectedLocation && addresses.display_name) {
      // if (entity && aptPlot && street && city && state && zipCode && country && selectedLocation) {
      if (editLocationData) {
        handleAddAddress(
          editLocationData.id,
          entity,
          aptPlot,
          street,
          city,
          state,
          zipCode,
          country,
          selectedLocation,
          addresses.display_name,
        );
        handleClose();
        clearStates();
      } else {
        handleAddAddress(
          generateID(),
          entity,
          aptPlot,
          street,
          city,
          state,
          zipCode,
          country,
          selectedLocation,
          addresses.display_name,
        );
        handleClose();
        clearStates();
      }
    }
  };

  return (
    <div>
      <Dialog open={open} maxWidth='md'>
        <DialogTitle sx={{ pl: 2, pr: 0, pb: 0, pt: 0 }}>
          <FormHeader
            title={!editLocationData ? `Add ${formName}` : `Update ${formName}`}
            id={'close-school-form'}
            onClose={closeForm}
            icon={<RoomIcon color='primary' />}
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 2 }}>
          <Grid container xs={12} alignItems={'center'}>
            {addAddress ? null : (
              <Grid xs={3.7}>
                <TextField
                  label='Entity/Block Name'
                  fullWidth
                  variant='standard'
                  value={entity}
                  disabled={addAddress}
                  onChange={handleEntity}
                  error={proceed && !entity}
                  helperText={proceed && !entity ? 'Entity Required' : 'Entity Required'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RoomIcon color='secondary' />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid xs={addAddress ? 11.6 : 7.8}>
              <Autocomplete
                freeSolo
                options={address}
                value={addresses}
                id='combo-box-demo'
                fullWidth
                getOptionLabel={(option) => option.display_name}
                sx={{ mt: 2, mb: 2, ml: 3 }}
                onChange={(e, newValue) => handleSelectedData(e, newValue)}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => handleSearch(e)}
                    label='Search for Address'
                    onKeyDown={handleSearchApi}
                    variant='standard'
                    error={proceed && !addresses.display_name}
                    helperText={
                      proceed && selectedLocation != ['4', '4']
                        ? 'Search Address'
                        : 'Search Address'
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* <Grid spacing={3} container xs={12} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              <TextField
                label='Apt / Plot'
                name='apt_plot'
                error={proceed && !aptPlot}
                helperText={proceed && !aptPlot ? 'Apt/Plot Required' : 'Apt/Plot Required'}
                variant='standard'
                fullWidth
                value={aptPlot}
                onChange={(event) => {
                  setAptPlot(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Street'
                name='street'
                error={proceed && !street}
                helperText={proceed && !street ? 'Street Required' : 'Street Required'}
                variant='standard'
                fullWidth
                value={street}
                onChange={(event) => {
                  setStreet(street ? event.target.value : event.target.value.trim());
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='City'
                name='city'
                error={proceed && !city}
                helperText={proceed && !city ? 'City Required' : 'City Required'}
                variant='standard'
                fullWidth
                value={city}
                onChange={(event) => {
                  setCity(city ? event.target.value : event.target.value.trim());
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='State'
                placeholder='State initials (Max 2 char)'
                name='state'
                error={proceed && !state}
                helperText={proceed && !state ? 'State Required' : 'State Required'}
                variant='standard'
                fullWidth
                value={state}
                onChange={(event) => {
                  setState(
                    state
                      ? event.target.value.toUpperCase()
                      : event.target.value.trim().toUpperCase(),
                  );
                }}
                inputProps={{ maxLength: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Country'
                name='country'
                error={proceed && !country}
                helperText={proceed && !country ? 'Country Required' : 'Country Required'}
                variant='standard'
                fullWidth
                value={country}
                onChange={(event) => {
                  setCountry(country ? event.target.value : event.target.value.trim());
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Zip Code'
                name='zip_code'
                error={proceed && !zipCode}
                helperText={proceed && !zipCode ? 'Zip Code required' : 'Zip Code required'}
                variant='standard'
                fullWidth
                value={zipCode}
                onChange={(event) => {
                  setZipCode(zipCode ? event.target.value : event.target.value.trim());
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RoomIcon color='secondary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid> */}
          <SchoolLocationMap
            location={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            handleGeoLocAddress={handleGeoLocAddress}
            defaultPosition={defaultPosition}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container justifyContent={'space-between'}>
            <Button onClick={closeForm} size='small'>
              Cancel
            </Button>
            <SaveButton type='submit' id='submit' onClick={handleLocation} size='small'>
              {!editLocationData ? `Add ${formName}` : `Update ${formName}`}
            </SaveButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
