import React, { Component } from 'react';

// project imports
import MapAllowedRoutes from './RBAC/MapAllowedRoutes';
import AppRoutesList from './AppRoutesList';
import { getAllowedRoutes } from './RBAC/Helpers';

// ============================== App Router - Main ============================== //

class AppRouter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let allowedRoutes = getAllowedRoutes(AppRoutesList);
    console.log('In AppRouter, username: ' + this.props.username);
    return (
      <>
        <MapAllowedRoutes routes={allowedRoutes} basePath='/' isAddNotFound {...this.props} />
      </>
    );
  }
}

export default AppRouter;
