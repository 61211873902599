// project imports
import { defaultCountry, defaultLanguage } from 'utils';
import { capitalizeFirstLetter, getDefaultSelectedChip } from './studentFormModel';
import { languageDataBasedOnID } from 'containers/layouts/Utils/ArrayofNameList';

// ============================== Student Formik Form - INITIAL VALUES  ============================== //

function formInitialValues(isUpdate, studentData, imageUrl) {
  return {
    result_image: isUpdate ? studentData?.profile_image : null,
    student_image: isUpdate ? (Boolean(imageUrl) ? imageUrl?.url : '') : '',
    first_name: isUpdate ? studentData?.first_name : '',
    last_name: isUpdate ? studentData?.last_name : '',
    birth_date: isUpdate ? studentData?.birth_date || null : null,
    grade: isUpdate ? studentData?.grade : 'KG',
    gender: isUpdate ? studentData?.gender : '',
    lang: isUpdate ? languageDataBasedOnID(studentData?.lang) : defaultLanguage(),

    // Student Contact info
    student_phone_code: isUpdate ? studentData?.student_phone_code : defaultCountry(),
    student_phone_number: isUpdate ? studentData?.student_phone_number : '',
    student_email: isUpdate ? studentData?.student_email : '',

    // if special_needs is true then add special_needs
    special_needs_level: isUpdate ? studentData?.special_needs_level : '',
    special_needs: isUpdate ? studentData?.special_needs : getDefaultSelectedChip(),

    parents_id: isUpdate ? studentData?.parents_id : '',

    // student Address
    address_label: isUpdate ? studentData?.address_label : 'Default Address',
    address_route_type: isUpdate ? studentData?.address_route_type : 'am',
    address_search: isUpdate ? studentData?.address_search : '',
    latitude: isUpdate ? studentData?.latitude : '',
    longitude: isUpdate ? studentData?.longitude : '',
    address_apt_plot: isUpdate ? studentData?.address_apt_plot : '',
    address_street: isUpdate ? studentData?.address_street : '',
    address_city: isUpdate ? studentData?.address_city : '',
    address_state: isUpdate ? studentData?.address_state : '',
    address_zip_code: isUpdate ? studentData?.address_zip_code : '',
    address_country: isUpdate ? studentData?.address_country : '',

    address_label_optional: isUpdate ? studentData?.address_label_optional : 'Father Address',
    address_route_type_optional: isUpdate ? studentData?.address_route_type_optional : '',
    address_search_optional: isUpdate ? studentData?.address_search_optional : '',

    address_label_optional2: isUpdate ? studentData?.address_label_optional2 : 'Mother Address',
    address_route_type_optional2: isUpdate ? studentData?.address_route_type_optional2 : '',
    address_search_optional2: isUpdate ? studentData?.address_search_optional2 : '',

    latitude_optional: isUpdate ? studentData?.latitude_optional : '',
    longitude_optional: isUpdate ? studentData?.longitude_optional : '',
    address_apt_plot_optional: isUpdate ? studentData?.address_apt_plot_optional : '',
    address_street_optional: isUpdate ? studentData?.address_street_optional : '',
    address_city_optional: isUpdate ? studentData?.address_city_optional : '',
    address_state_optional: isUpdate ? studentData?.address_state_optional : '',
    address_zip_code_optional: isUpdate ? studentData?.address_zip_code_optional : '',
    address_country_optional: isUpdate ? studentData?.address_country_optional : '',

    latitude_optional2: isUpdate ? studentData?.latitude_optional2 : '',
    longitude_optional2: isUpdate ? studentData?.longitude_optional2 : '',
    address_apt_plot_optional2: isUpdate ? studentData?.address_apt_plot_optional2 : '',
    address_street_optional2: isUpdate ? studentData?.address_street_optional2 : '',
    address_city_optional2: isUpdate ? studentData?.address_city_optional2 : '',
    address_state_optional2: isUpdate ? studentData?.address_state_optional2 : '',
    address_zip_code_optional2: isUpdate ? studentData?.address_zip_code_optional2 : '',
    address_country_optional2: isUpdate ? studentData?.address_country_optional2 : '',

    // Student Parent info father
    father_label: isUpdate ? capitalizeFirstLetter(studentData?.father_label) : 'father',
    father_first_name: isUpdate ? studentData?.father_first_name : '',
    father_last_name: isUpdate ? studentData?.father_last_name : '',
    father_phone_code: isUpdate ? studentData?.father_phone_code : defaultCountry(),
    father_phone_number: isUpdate ? studentData?.father_phone_number : '',
    father_email: isUpdate ? studentData?.father_email : '',

    // Student Parent info mother
    mother_label: isUpdate ? capitalizeFirstLetter(studentData?.mother_label) : 'mother',
    mother_first_name: isUpdate ? studentData?.mother_first_name : '',
    mother_last_name: isUpdate ? studentData?.mother_last_name : '',
    mother_phone_code: isUpdate ? studentData?.mother_phone_code : defaultCountry(),
    mother_phone_number: isUpdate ? studentData?.mother_phone_number : '',
    mother_email: isUpdate ? studentData?.mother_email : '',

    // Student Parent info guardian
    guardian_label: isUpdate ? capitalizeFirstLetter(studentData?.guardian_label) : 'guardian',
    guardian_first_name: isUpdate ? studentData?.guardian_first_name : '',
    guardian_last_name: isUpdate ? studentData?.guardian_last_name : '',
    guardian_phone_code: isUpdate ? studentData?.guardian_phone_code : defaultCountry(),
    guardian_phone_number: isUpdate ? studentData?.guardian_phone_number : '',
    guardian_email: isUpdate ? studentData?.guardian_email : '',

    // Student Contact Route info
    am_route: isUpdate ? studentData?.route_id_am : '',
    am_stop: isUpdate ? studentData?.stop_id_am : '',
    pm_route: isUpdate ? studentData?.route_id_pm : '',
    pm_stop: isUpdate ? studentData?.stop_id_pm : '',

    // student Medical info
    medicines: isUpdate ? studentData?.medicines : [],

    // student program
    student_program_name: isUpdate ? studentData?.student_program_name : '',
    student_type: isUpdate ? studentData?.student_type : '',
    neighborhood_code: isUpdate ? studentData?.neighborhood_code : '',
    student_neighborhood_code_description: isUpdate
      ? studentData?.student_neighborhood_code_description
      : '',

    // school Notes
    school_notes: isUpdate ? studentData?.school_notes : '',
    driver_notes: isUpdate ? studentData?.driver_notes : '',

    // special needs
    student_ac: isUpdate ? studentData?.student_ac : false,
    student_booster: isUpdate ? studentData?.student_booster : false,
    student_car_seat: isUpdate ? studentData?.student_car_seat : false,
    student_harness: isUpdate ? studentData?.student_harness : false,
    student_lock: isUpdate ? studentData?.student_lock : false,
    student_mclb: isUpdate ? studentData?.student_mclb : false,
    student_monitor: isUpdate ? studentData?.student_monitor : false,
    student_unattended: isUpdate ? studentData?.student_unattended : false,
    student_therapy: isUpdate ? studentData?.student_therapy : false,
    student_walker: isUpdate ? studentData?.student_walker : false,
    student_wc: isUpdate ? studentData?.student_wc : false,
    student_west: isUpdate ? studentData?.student_west : false,
    student_m: isUpdate ? studentData?.student_m : false,
  };
}

export default formInitialValues;
