import React from 'react';

// material ui
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export default function DriverInfo({
  heading,
  details,
  dataLoading,
  id,
  headingWidth = 6,
  bodyWidth = 6,
}) {
  const GetText = ({ text }) =>
    dataLoading ? <Skeleton animation='wave' variant='rectangular' /> : text;

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      spacing={3}
      style={{ marginBottom: 6 }}
    >
      <Grid item xs={headingWidth}>
        <Typography variant='headline' color='textSecondary' style={{ fontWeight: 500 }}>
          <GetText text={heading} />
        </Typography>
      </Grid>
      <Grid item xs={bodyWidth}>
        {dataLoading ? (
          <Skeleton animation='wave' variant='rectangular' />
        ) : (
          <Grid container direction='row-reverse' alignItems='center'>
            <Grid item>
              <Typography color='textSecondary' align='right' id={id}>
                {details ? details : '---'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
