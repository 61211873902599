import { SpotBusDateTime } from 'utils/spotbus-date-time';
import { getCurrentDate } from './helper';

export const DATE_OCCURRENCE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  REGULAR: 'regular',
  ADHOC: 'nonrepetitive',
};

export const monthlyOccurrenceInitialValues = [
  {
    startDateMonthly: '',
    clockIn: null,
    clockOut: null,
  },
];

/**
 * Generates initial form values for driver shifts.
 *
 * @param {object} editedDriverShiftFormData - The edited driver shift form data.
 * @param {boolean} isUpdate - Specifies whether the form is for updating an existing driver shift.
 * @return {object} The initial form values for the driver shifts.
 */

const localTime = (date) => {
  return new SpotBusDateTime(date).toLocalizeTimezone();
};

export default function formInitialValuesDriverShifts(editedDriverShiftFormData, isUpdate) {
  const monthlyDate =
    editedDriverShiftFormData?.monthlyDate && editedDriverShiftFormData?.monthlyDate?.length > 0
      ? editedDriverShiftFormData?.monthlyDate.map((ele) => {
          return {
            ...ele,
            clockIn: localTime(ele.clockIn),
            clockOut: localTime(ele.clockOut),
          };
        })
      : [];
  return {
    shiftTitle: isUpdate ? editedDriverShiftFormData.shiftTitle : '',
    startDate: isUpdate ? localTime(editedDriverShiftFormData.startDate) : getCurrentDate(),
    endDate: isUpdate ? localTime(editedDriverShiftFormData.endDate) : getCurrentDate(),
    mondayCheckBox: isUpdate ? editedDriverShiftFormData.mondayCheckBox : true,
    mondayStartTime: isUpdate ? localTime(editedDriverShiftFormData.mondayStartTime) : null,
    mondayEndTime: isUpdate ? localTime(editedDriverShiftFormData.mondayEndTime) : null,

    tuesdayCheckBox: isUpdate ? editedDriverShiftFormData.tuesdayCheckBox : true,
    tuesdayStartTime: isUpdate ? localTime(editedDriverShiftFormData.tuesdayStartTime) : null,
    tuesdayEndTime: isUpdate ? localTime(editedDriverShiftFormData.tuesdayEndTime) : null,

    wednesdayCheckBox: isUpdate ? editedDriverShiftFormData.wednesdayCheckBox : true,
    wednesdayStartTime: isUpdate ? localTime(editedDriverShiftFormData.wednesdayStartTime) : null,
    wednesdayEndTime: isUpdate ? localTime(editedDriverShiftFormData.wednesdayEndTime) : null,

    thursdayCheckBox: isUpdate ? editedDriverShiftFormData.thursdayCheckBox : true,
    thursdayStartTime: isUpdate ? localTime(editedDriverShiftFormData.thursdayStartTime) : null,
    thursdayEndTime: isUpdate ? localTime(editedDriverShiftFormData.thursdayEndTime) : null,

    fridayCheckBox: isUpdate ? editedDriverShiftFormData.fridayCheckBox : true,
    fridayStartTime: isUpdate ? localTime(editedDriverShiftFormData.fridayStartTime) : null,
    fridayEndTime: isUpdate ? localTime(editedDriverShiftFormData.fridayEndTime) : null,

    saturdayCheckBox: isUpdate ? editedDriverShiftFormData.saturdayCheckBox : false,
    saturdayStartTime: isUpdate ? localTime(editedDriverShiftFormData.saturdayStartTime) : null,
    saturdayEndTime: isUpdate ? localTime(editedDriverShiftFormData.saturdayEndTime) : null,

    sundayCheckBox: isUpdate ? editedDriverShiftFormData.sundayCheckBox : false,
    sundayStartTime: isUpdate ? localTime(editedDriverShiftFormData.sundayStartTime) : null,
    sundayEndTime: isUpdate ? localTime(editedDriverShiftFormData.sundayEndTime) : null,

    //  Monthly Shift
    dateOccurrence: isUpdate ? editedDriverShiftFormData.dateOccurrence : DATE_OCCURRENCE.ADHOC,
    occurenceType: isUpdate ? editedDriverShiftFormData.occurenceType : DATE_OCCURRENCE.ADHOC,
    earlyClockInTime: isUpdate ? editedDriverShiftFormData.earlyClockInTime : '',
    adhocStartTime: isUpdate ? localTime(editedDriverShiftFormData.adhocStartTime) : null,
    adhocEndTime: isUpdate ? localTime(editedDriverShiftFormData.adhocEndTime) : null,
    monthlyDate: isUpdate
      ? editedDriverShiftFormData?.monthlyDate?.length > 0
        ? monthlyDate
        : monthlyOccurrenceInitialValues
      : monthlyOccurrenceInitialValues,
  };
}

export const weekFieldName = [
  {
    startTime: 'mondayStartTime',
    endTime: 'mondayEndTime',
  },
  {
    startTime: 'tuesdayStartTime',
    endTime: 'tuesdayEndTime',
  },
  {
    startTime: 'wednesdayStartTime',
    endTime: 'wednesdayEndTime',
  },
  {
    startTime: 'thursdayStartTime',
    endTime: 'thursdayEndTime',
  },
  {
    startTime: 'fridayStartTime',
    endTime: 'fridayEndTime',
  },
  {
    startTime: 'saturdayStartTime',
    endTime: 'saturdayEndTime',
  },
  {
    startTime: 'sundayStartTime',
    endTime: 'sundayEndTime',
  },
];
