import React from 'react';

import SchoolIcon from '@mui/icons-material/School';
import { Grid, Stack, Typography } from '@mui/material';
import { ClassificationHeading } from 'components/Headers';

export default function DistrictLevelInfoCard({ heading, description }) {
  return (
    <>
      <Grid item xs={12}>
        <Stack direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={3}>
          <Stack>
            <SchoolIcon />
          </Stack>
          <Stack>
            <ClassificationHeading>{heading}</ClassificationHeading>
            <Typography>{description}</Typography>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
}
