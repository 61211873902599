import React from 'react';
import { Typography } from '@mui/material';

export function CardHeading({ title, color = 'text' }) {
  return (
    <Typography
      color={color}
      sx={{ fontSize: 18, fontWeight: 600, textTransform: 'uppercase' }}
      gutterBottom
    >
      {title}
    </Typography>
  );
}
