import * as yup from 'yup';

const phoneRegExp = /^\d{10}$/;

const minAddressChar = /[a-zA-Z]{2}/;
const minNameChar = /[a-zA-Z]{3}/;

const minAlphaNum = /[a-zA-Z0-9]{1}/;

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

var Validations = {
  first_name: yup
    .string()
    .trim()
    .required('First name is required')
    .min(3, 'First name must be at least 3 characters')
    .max(50, 'First name contains maximum 50 characters')
    .matches(minNameChar, 'First name must be at least 3 characters without white space'),

  last_name: yup
    .string()
    .trim()
    .required('Last name is required')
    .min(3, 'Last name must be at least 3 characters')
    .max(50, 'Last name contains maximum 50 characters')
    .matches(minNameChar, 'Last name must be at least 3 characters without white space'),

  fathers_first_name: yup
    .string()
    .trim()
    .required("Father's first name is required")
    .min(3, "Father's first name must be at least 3 characters")
    .max(50, "Father's first name contains maximum 50 characters")
    .matches(minNameChar, "Father's first name must be at least 3 characters without white space"),

  fathers_last_name: yup
    .string()
    .trim()
    .required("Father's last name is required")
    .min(3, "Father's last name must be at least 3 characters")
    .max(50, "Father's last name contains maximum 50 characters")
    .matches(minNameChar, "Father's last name must be at least 3 characters without white space"),

  mothers_first_name: yup
    .string()
    .trim()
    .required("Mother's first name is required")
    .min(3, "Mother's first name must be at least 3 characters")
    .max(50, "Mother's first name contains maximum 50 characters")
    .matches(minNameChar, "Mother's first name must be at least 3 characters without white space"),

  mothers_last_name: yup
    .string()
    .trim()
    .required("Mother's last name is required")
    .min(3, "Mother's last name must be at least 3 characters")
    .max(50, "Mother's last name contains maximum 50 characters")
    .matches(minNameChar, "Mother's last name must be at least 3 characters without white space"),

  phone_number: yup
    .string()
    .required('Enter valid mobile number')
    .min(10, 'Invalid phone number')
    .matches(phoneRegExp, 'Invalid mobile number'),

  phone_number_not_required: yup
    .string()
    .min(10, 'Invalid phone number')
    .matches(phoneRegExp, 'Invalid mobile number'),

  gender: yup.string().required('Gender required'),

  phone_country_code: yup.mixed().required('Required field'),

  email: yup.string('Enter a valid email ID').required('Enter a valid email ID'),
  emailNotRequired: yup.string().matches(emailRegex, 'Enter a valid email ID'),

  street: yup
    .string()
    .trim()
    .required('Street is required')
    .min(2)
    .max(50)
    .matches(minAddressChar, 'Street Name must be at least 2 characters without white space'),

  city: yup
    .string()
    .trim()
    .required('City is required')
    .min(2)
    .max(50)
    .matches(minAddressChar, 'City Name must be at least 2 characters without white space'),

  state: yup
    .string()
    .trim()
    .required('State is required')
    .min(2)
    .max(50)
    .matches(minAddressChar, 'State Name must be at least 2 characters without white space'),

  country: yup
    .string()
    .trim()
    .required('Country is required')
    .min(2)
    .max(50)
    .matches(minAddressChar, 'Country Name must be at least 2 characters without white space'),

  lang: yup.mixed().required('Required field'),

  zip_code: yup
    .number()
    .typeError('Enter only numbers')
    .required('Zip Code is required'),

  apt_plot: yup
    .string()
    .trim()
    .required('Apt / Plot is required')
    .min(1)
    .max(50)
    .matches(minAlphaNum, 'APT / Plot is required'),

  am_route: yup.string(),

  am_stop: yup.string(),

  school: yup.string().required('Please select one school'),

  school_name: yup
    .string()
    .trim()
    .required('School name is required')
    .min(3, 'School name must be at least 3 characters')
    .max(50, 'School name contains maximum 100 characters'),

  school_abbreviation: yup
    .string()
    .trim()
    .required('Abbreviation is required')
    .min(1, 'Abbreviation must be at least 1 characters'),

  image: yup
    .mixed()
    .required('Please upload an image.')
    .test('fileSize', 'The file is too large, allowed maximum size 4MB.', (value) => {
      if (value instanceof Object) {
        return value && value.size <= 4194304;
      } else {
        return true;
      }
    }),

  document_title: yup
    .string()
    .trim()
    .required('Document title is required')
    .min(3, 'Document title must be at least 3 characters')
    .max(30, 'Document title contains maximum 30 characters'),

  form_heading: yup
    .string()
    .trim()
    .required('Form heading is required')
    .min(3, 'Form heading must be at least 3 characters')
    .max(30, 'Form heading contains maximum 30 characters'),

  google_form_url: yup
    .string()
    .trim()
    .required('URL required')
    .url('Must be a valid URL'),

  question: yup
    .string()
    .trim()
    .required('Question is required')
    .min(3, 'Question must be at least 3 characters')
    .max(500, 'Question contains maximum 500 characters'),

  answer: yup
    .string()
    .trim()
    .required('Answer is required')
    .min(3, 'Answer must be at least 3 characters')
    .max(5000, 'Answer contains maximum 5000 characters'),

  label: yup
    .string()
    .trim()
    .required('Label is required')
    .min(3, 'Label must be at least 3 characters')
    .max(500, 'Label contains maximum 500 characters'),

  url: yup
    .string()
    .trim()
    .required('URL is required')
    .min(3, 'URL must be at least 3 characters')
    .max(2000, 'URL contains maximum 200 characters'),

  shiftTitle: yup
    .string()
    .trim()
    .required('Shift title is required')
    .min(3, 'Driver title must be at least 3 characters')
    .max(50, 'Driver contains maximum 50 characters')
    .matches(minNameChar, 'Driver title must be at least 3 characters without white space'),

  startDate: yup
    .date()
    .typeError('Start date must be a valid date')
    .required('Start date is required'),

  endDate: yup
    .date('')
    .typeError('End date is required')
    .when(
      'startDate',
      (started, yup) => started && yup.min(started, 'End date cannot be before start date'),
    )
    .required('End Date is required'),

  startTime: yup
    .date()
    .typeError('Start time must be valid')
    .required('Start time is required'),

  allowEarlyClockInTime: yup
    .number()
    .required('Early clock in time is required')
    .max(15, 'Maximum 15 minutes is allowed'),

  birth_date: yup
    .date()
    .typeError('Date must be a valid date')
    .required('Date is required'),

  grade: yup.string().required('Grade is required'),

  special_needs_level: yup.string().required('Special needs level is required'),

  // gender: yup.string().required('Gender required'),
};

export default Validations;
