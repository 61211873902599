import React from 'react';

// material ui
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, TextField, InputAdornment } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

import GoogleMapReact from 'google-map-react';
import { SHORT_MAP_OPTIONS } from '../../../Maps/SpotBusMapOptions';
import SearchIcon from '@mui/icons-material/Search';

import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import styled from 'styled-components/macro';

// Search
import { usePlacesWidget } from 'react-google-autocomplete';
import { googleMapApi } from '../../../API/GoogleMapApi';

const useStyles = makeStyles((theme) => ({
  search: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '30px',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const GoogleMapReactWrapper = styled.div`
  height: 220px;
  width: 100%;
`;

// https://github.com/ErrorPro/react-google-autocomplete#readme
// documentation regarding used package.
// official google documentation https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete#maps_places_autocomplete-javascript

function AlertWindow(props) {
  return (
    <Collapse in={props.open}>
      <Alert
        severity='info'
        color='warning'
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              props.setOpen(false);
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
      >
        Drag marker to select exact location.
      </Alert>
    </Collapse>
  );
}

export default function DriverGoogleMap(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  //   const GoogleMapReactWrapper = styled.div`
  //   height: ${props.school ? '200px': '250px'};
  //   width: 100%;
  // `;

  const options = {
    center: {
      lat:
        props.latitude !== '' && !isNaN(parseFloat(props.latitude))
          ? parseFloat(props.latitude)
          : parseFloat(props.schoolsData[0]?.geo_location.latitude),
      lng:
        props.longitude !== '' && !isNaN(parseFloat(props.longitude))
          ? parseFloat(props.longitude)
          : parseFloat(props.schoolsData[0]?.geo_location.longitude),
    },
    zoom: 15,
  };

  const StopMarker = ({ school = false }) => {
    return (
      <>
        {school ? (
          <SchoolIcon
            variant='h4'
            text={'home marker'}
            color='primary'
            style={{ fontSize: '32px' }}
          />
        ) : (
          <HomeIcon
            variant='h4'
            text={'home marker'}
            color='primary'
            style={{ fontSize: '32px' }}
          />
        )}
      </>
    );
  };

  function onCircleInteraction(childKey, childProps, mouse) {
    props.setLatitude(mouse.lat);
    props.setLongitude(mouse.lng);
    // console.log('onCircleInteraction called with', childKey, childProps, mouse);
  }

  const handleMapClick = ({ x, y, lat, lng, event }, force) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
  };

  function SearchBar(props) {
    const handlePlaceSelected = (place) => {
      console.log('Selected Place from search: ', place);
      // let address = place.formatted_address;
      // let addressArray = place.address_components;
      // let city = getCity(addressArray);
      const latValue = String(place.geometry.location.lat());
      const lngValue = String(place.geometry.location.lng());

      props.setLatitude(latValue);
      props.setLongitude(lngValue);
      let spotBusAddressItemToGoogleAddressItem = {};
      place.address_components.forEach((x) => {
        if (x.types.includes('postal_code')) {
          spotBusAddressItemToGoogleAddressItem['zip_code'] = x.short_name;
        } else if (x.types.includes('administrative_area_level_1')) {
          spotBusAddressItemToGoogleAddressItem['state'] = x.short_name;
        } else if (x.types.includes('administrative_area_level_2')) {
          spotBusAddressItemToGoogleAddressItem['county'] = x.short_name;
        } else if (x.types.includes('route')) {
          spotBusAddressItemToGoogleAddressItem['route'] = x.short_name;
        } else if (x.types.includes('street_number')) {
          spotBusAddressItemToGoogleAddressItem['street_number'] = x.short_name;
        } else if (x.types.includes('locality')) {
          spotBusAddressItemToGoogleAddressItem['city'] = x.short_name;
        } else if (x.types.includes('floor')) {
          spotBusAddressItemToGoogleAddressItem['floor'] = x.short_name;
        } else if (x.types.includes('country')) {
          spotBusAddressItemToGoogleAddressItem['country'] = x.long_name;
        }
      });
      props.setAptPlot(spotBusAddressItemToGoogleAddressItem.street_number ?? '');
      props.setStreet(spotBusAddressItemToGoogleAddressItem.route ?? '');
      props.setCity(spotBusAddressItemToGoogleAddressItem.city ?? '');
      props.setState(spotBusAddressItemToGoogleAddressItem.state ?? '');
      props.setZipCode(spotBusAddressItemToGoogleAddressItem.zip_code ?? '');
      props.setCountry(spotBusAddressItemToGoogleAddressItem.country ?? '');

      // console.log(address, latValue, lngValue, city);
    };
    const { ref } = usePlacesWidget({
      apiKey: googleMapApi(),
      onPlaceSelected: (place) => {
        handlePlaceSelected(place);
      },
      options: {
        types: ['address'],
        componentRestrictions: { country: ['us', 'ca', 'in'] },
      },
    });

    return (
      <TextField
        id='driver_address_search'
        inputRef={ref}
        sx={{ width: '100%', mb: theme.spacing(2) }}
        // label= {`Search ${props.school ? 'school' : 'location'} on map`}
        placeholder={`Search ${props.school ? 'school' : 'location'} on map`}
        variant='standard'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon color='secondary' />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <Card>
      <CardContent>
        <SearchBar {...props} />
        {!props.school && (
          <div className={classes.alert}>
            <AlertWindow open={open} setOpen={setOpen} />
          </div>
        )}
        <GoogleMapReactWrapper>
          <GoogleMapReact
            draggable={true}
            options={SHORT_MAP_OPTIONS}
            bootstrapURLKeys={{
              key: googleMapApi(),
            }}
            onChildMouseMove={(key, childProps, mouse) =>
              onCircleInteraction(key, childProps, mouse)
            }
            center={options.center}
            defaultZoom={options.zoom}
            onClick={handleMapClick}
            yesIWantToUseGoogleMapApiInternals
          >
            {props.latitude !== undefined &&
            props.longitude !== undefined &&
            props.latitude !== '' &&
            props.longitude !== '' ? (
              <StopMarker
                lat={isNaN(parseFloat(props.latitude)) ? 40.712784 : props.latitude}
                lng={isNaN(parseFloat(props.longitude)) ? -74.005941 : props.longitude}
                school={props.school}
              />
            ) : (
              ''
            )}
          </GoogleMapReact>
        </GoogleMapReactWrapper>
      </CardContent>
    </Card>
  );
}
