import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
function SearchAutocomplete({
  searchedResults,
  searchLoading,
  searchValue,
  handleInputChangeSearch,
  handleListItemClick,
  textFieldProps = {},
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Autocomplete
        onFocus={() => setOpen(true)}
        options={searchedResults}
        loading={searchLoading}
        // onInputChange={(e, value) => handleInputChangeSearch(e, value)}
        inputValue={searchValue || ''}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        filterOptions={(options) => options}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option} onClick={(e) => handleListItemClick(e, option, props)}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleInputChangeSearch}
            value={searchValue || ''}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {searchLoading ? <CircularProgress size={20} /> : <SearchIcon />}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            {...textFieldProps}
          />
        )}
        {...props}
      />
    </div>
  );
}

export default SearchAutocomplete;
