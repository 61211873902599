import styled from 'styled-components';
import { generateLighterColor, isColorDark } from './../helper';

export const CustomHeader = styled.header`
  background-color: ${(props) => props.color || 'gray'};
  color: ${(props) => (isColorDark(props.color) ? '#e8e8e8' : '#333333')};
  border-bottom: 1px solid #cccccc;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0px 0px 5px 0px !important;
    font-size: 1.25rem;
    text-align: center;
  }

  p {
    font-size: 0.8rem;
    margin: 10px 0px 0px 0px !important;
    font-size: 12px;
  }
  div img {
    margin-top: 0.5rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const HeaderIcon = styled.div`
  background-color: #e5b300;
  margin-top: 0.5rem;
  width: ${(props) => props.width || '50px'};
  height: ${(props) => props.height || '50px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const avatarStyle = (theme, color) => {
  return {
    // backgroundColor: color ? color : "#e5b300",
    minWidth: '35px',
    maxWidth: '35px',
    height: '35px',
    borderRadius: '50%',
    color: '#333',
    padding: '0px',
    margin: '0px',
  };
};
export const contentStyle = (theme) => {
  return {
    backgroundColor: theme === 'dark' ? '#333' : '#fff',
    height: '19rem',
    borderBottom: `1px solid #ccc`,
    marginBottom: '.5rem',
    overflow: 'auto',
    padding: '.3rem',
  };
};
export const footerStyle = (theme) => {
  return {
    padding: '10px',
    backgroundColor: theme === 'dark' ? '#333' : '#fff',
    color: theme === 'dark' ? '#f0f0f0' : '#333',
    fontSize: '14px',
    textAlign: 'center',
  };
};
export const inputStyle = (theme) => {
  return {
    borderRadius: '14px',
    backgroundColor: theme === 'dark' ? '#333' : '#fff',
    color: theme === 'dark' ? '#fff' : '#333',
    padding: '1rem .6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    border: '1px solid #ccc',
    outline: 'none',
    position: 'relative',
  };
};
export const submitButtonStyle = (theme, color) => {
  return {
    // marginRight: ".95rem",
    // marginTop: "1rem",
    position: 'absolute',
    right: '17px',
    top: '17px',
    borderRadius: '50%',
    padding: '10px',
    width: '37px',
    height: '37px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fill: isColorDark(color) ? '#fff' : '#333333',
    backgroundColor: generateLighterColor(color, 0.5),
  };
};
export const containerStyle = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  color: '#333',
  boxShadow: '0px 0px 20px 6px #00000030',
  borderRadius: '25px',
  width: '23rem',
  height: '35.5rem',
};
export const userBubbleStyle = (theme) => {
  return {
    backgroundColor: theme === 'dark' ? '#333' : '#fff',
    color: theme === 'dark' ? '#e8e8e8' : '#333333',
    borderRadius: '10px',
    padding: '10px',
    fontSize: '12px',
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)',
    // width: '90%'
  };
};

/* Typography */
