import React, { useCallback, useEffect, useState } from 'react';

// api call
import {
  deleteDriver,
  driverToogleStatus,
  getDriversByTransportationAdmin,
  getDriverTimesheet,
  registerDriverAdditionalInfo,
  registerUserPermsissions,
  updateDriverAdditionalInfo,
  updateUserPermsissions,
  uploadDriverAdditionalImage,
} from '../../../clients/SpotBus';

// material ui
import { Alert, Grid, IconButton, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// third party imports
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

//project imports
import { getErrorMessage } from '../Utils/ErrorHandling';
import { formatPhoneNumber } from '../Utils/FormatPhoneNumber';
import DriverFormWrapper from './components/DriverFormWrapper';
import DeleteAlert from './components/DriverDetails/DeleteAlert';
import DriverTable from './components/Table/DriverTable';
import DriverDetails from './components/DriverDetails';
import { handleUpdateData } from '../../../actions/searchActions';
import Stack from '@mui/material/Stack';

// Calendar Libry
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Custom Component
import ActionButton from 'components/Buttons/ActionButton';
import DriverShifts from './components/DriverShifts';

import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

// Formik
// third-party imports
import { Form, Formik } from 'formik';
import formInitialValuesDriverShifts, {
  DATE_OCCURRENCE,
} from './components/DriverShifts/formInitialValuesDriverShifts';
import { validationSchemaDriverShifts } from './components/DriverShifts/validationSchemaDriverShifts';

import DriverShiftsList from './components/DriverShifts/DriverShiftsList';
import ShiftTable from './components/ShiftDetails/ShiftTable';
import { getDriverShifts, registerDriverShift, updateDriverShift } from 'clients/driver';
import {
  generateEventsFromDriverShifts,
  requestFactory,
} from './components/DriverShifts/requestFactory';
import {
  generateEventsFromAdhoc,
  generateEventsFromData,
  generateEventsFromDataMonth,
} from './components/DriverShifts/helper';
import { formFieldValues } from './components/DriverShifts/DriverShiftForm';
import ShiftHeader from './components/DriverShifts/ShiftHeader';
import ShiftCalender from './components/DriverShifts/components/ShiftCalender';
import { getDriverNameByDriverId } from 'pages/Communication/utils/utils';
import { useDashboardUsers } from 'hooks/api/useDashboardUsers';
import { useQueryClient } from '@tanstack/react-query';
import { queryKey } from 'react-query/queryKey';
import Roles from 'routes/RBAC/Roles';
import { Link, useLocation } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function Drivers(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { users, isLoadingUsers } = useDashboardUsers();
  const queryClient = useQueryClient();
  const location = useLocation();

  const [isEdited, setIsEdited] = useState(false);

  const isReadOnlyUser =
    localStorage.getItem('loggedUserRoleType') === 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // state for opening add driver form
  const [addDriverFormOpen, setAddDriverFormOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  //state for deleting driver and opening delete dailogue box
  const [openDeleteDriverAlert, setOpenDeleteDriverAlert] = useState(false);
  const [driverUserName, setDriverUserName] = useState('');

  //state for storing all driver data
  const [driverData, setDriverData] = useState([]);

  //state for storing created driver Id
  const [createdDriverID, setCreatedDriverID] = useState('');

  //state for showing driver details
  const [selectedDriverData, setSelectedDriverData] = useState(undefined);

  //state for showing driver row highlighted
  const [selectedDriverRowId, setSelectedDriverRowId] = useState(0);

  //state for driver basic information
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [email, setEmail] = useState('');
  const [height, setHeight] = useState('');
  const [gender, setGender] = useState('male');

  // state for driver home address
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [aptPlot, setAptPlot] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  // state for additional form

  const [profileImage, setProfileImage] = useState(undefined);
  const [profileImageData, setProfileImageData] = useState(undefined);
  const [imageValidation, setImageValidation] = React.useState(false);
  const [profileImageResponse, setProfileImageResponse] = useState(undefined);
  const [licenseFrontImage, setLicenseFrontImage] = useState(undefined);
  const [licenseFrontImageData, setLicenseFrontImageData] = useState(undefined);
  const [licenseFrontImageResponse, setLicenseFrontImageResponse] = useState(undefined);
  const [licenseBackImage, setLicenseBackImage] = useState(undefined);
  const [licenseBackImageData, setLicenseBackImageData] = useState(undefined);
  const [licenseBackImageResponse, setLicenseBackImageResponse] = useState(undefined);
  const [certificateImage, setCertificateImage] = useState(undefined);
  const [certificateImageData, setCertificateImageData] = useState(undefined);
  const [certificateImageResponse, setCertificateImageResponse] = useState(undefined);
  const [imagesUploaded, setImagesUploaded] = useState(false);

  const [enableCertificateForm, setEnableCertificateForm] = useState(false);
  const [certificateName, setCertificateName] = useState('');
  const [certificateAuthority, setCertificateAuthority] = useState('');
  const [licenseClass, setLicenseClass] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseIssDate, setLicenseIssDate] = useState(null);
  const [licenseExpDate, setLicenseExpDate] = useState(null);
  const [driverShift, setDriverShift] = useState([
    {
      clock_in_time: '06:00:00',
      clock_out_time: '07:00:00',
      allow_early_clock_in: false,
    },
  ]);

  const [flag, setFlag] = useState(false);
  const [loadUpdatedData, setLoadUpdatedData] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonText, setButtonText] = useState('SAVE');
  const [page, setPage] = useState(0);
  const [filledRows, setFilledRows] = React.useState(driverData);
  const [searched, setSearched] = React.useState('');
  const [dataLoading, setDataLoading] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  const [toggleloader, setToggleloader] = useState(false);

  // Driver Shifts
  const [openDriverShiftsForm, setOpenDriverShiftsForm] = useState(false);
  const [openDriverShifts, setOpenDriverShifts] = useState({ loading: false, open: false });
  const [selectedDateForCalendar, setSelectedDateForCalendar] = useState(new Date()); // State to keep track of the selected date
  const [driverShifts, setDriverShifts] = useState([]);
  const [driverShiftsFormData, setDriverShiftsFormData] = useState([]);
  const [driverShiftDeleteAlertOpen, setDriverShiftDeleteAlertOpen] = useState(false);

  // Edit DriverShift
  const [isUpdate, setIsUpdate] = useState(false);
  const [editedDriverShiftFormData, setEditedDriverShiftFormData] = useState({});

  //SHIFT DETAILS
  const [openShift, setOpenShift] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState([]);
  const [nextTokenShift, setNextTokenShift] = useState([]);

  // drivers-shift-occurrence
  const [occurrenceType, setOccurrenceType] = useState(DATE_OCCURRENCE.WEEKLY);

  // shift date validation storing date for validation
  const [shiftStartDate, setShiftStartDate] = useState(null);

  // shift's switch tabs
  const [shiftTab, setShiftTab] = React.useState(0);

  // when we change driver on select dropdown
  const [selectedDriverUserId, setSelectedDriverUserId] = useState('');

  // permissions
  const [permissionType, setPermissionType] = useState(Roles.SCHOOL_LEVEL_READ_ONLY_ACCESS);
  const [permitSchools, setPermitSchools] = useState([]);
  const [driverAppPermit, setDriverAppPermit] = useState(false);
  // addition at basic from
  const [designation, setDesignation] = useState('');
  const [roleType, setRoleType] = useState([]);
  const [enrolmentType, setEnrolmentType] = useState([]);

  const additionalInfoBasicInfoProps = {
    designation,
    setDesignation,
    roleType,
    setRoleType,
    enrolmentType,
    setEnrolmentType,
  };

  const permissionProps = {
    permissionType,
    setPermissionType,
    permitSchools,
    setPermitSchools,
    driverAppPermit,
    setDriverAppPermit,
  };
  const readPermissionProps = {
    permissionType,
    permitSchools,
    driverAppPermit,
  };

  // Material table columns schema
  const columns = [
    {
      title: 'Name',
      field: 'first_name',
      render: (rowData) => {
        return (
          <Typography variant='h5' color='primary' id='fullName'>
            {rowData.first_name + ' ' + rowData.last_name}
          </Typography>
        );
      },
      type: 'string',
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      render: (rowData) => formatPhoneNumber(rowData.phone_number),
      type: 'string',
    },
    { title: 'Email', field: 'email', type: 'string' },
  ];

  // delete driver function
  const handleDeleteDriver = async (driverId) => {
    setButtonLoader(true);
    enqueueSnackbar(`Deleting driver  ${getDriverNameByDriverId(driverData, driverId)}`);
    try {
      const result = await deleteDriver(driverId);
      console.log('Delete result is ', result);
      enqueueSnackbar('User successfully deleted', {
        variant: 'success',
      });
      setButtonLoader(false);
      setLoadUpdatedData(true);
      loadDrivers(selectedDriverRowId);
      setOpenDeleteDriverAlert(!openDeleteDriverAlert);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setButtonLoader(false);
      setLoadUpdatedData(false);
    }
  };

  // load drivers function
  const idDriver = location.pathname === '/drivers';
  const loadDrivers = (selectedRow) => {
    setDataLoading(true);
    setSelectedDriverData('');
    setSearched('');
    if (!props.isFromSearch) {
      getDriversByTransportationAdmin(props.username, true, idDriver).then(
        (result) => {
          const getSuperPermission = users.find((user) => {
            return user?.user_permissions?.role_type === Roles.SUPER_ADMIN;
          });

          const filteredDriver = result.filter((driver) => {
            return driver?.driver_username !== getSuperPermission?.user_permissions?.username;
          });

          // if you are in driver then only show the driver which driver have permission
          if (idDriver) {
            //  if true it mean you are a driver page
            const getUserHaveDriverPermission = users?.filter((user) => {
              return user?.user_permissions?.driver_app_access;
            });
            const filteredDriverBasisOfDriverPermission = result?.filter((driver) => {
              return getUserHaveDriverPermission?.some((user) => {
                return user?.user_permissions?.username === driver?.driver_username;
              });
            });

            if (
              filteredDriverBasisOfDriverPermission.length === 0 ||
              !filteredDriverBasisOfDriverPermission
            ) {
              return;
            }

            setDriverData(filteredDriverBasisOfDriverPermission);
            setNextToken(filteredDriverBasisOfDriverPermission.next_token);
            setSelectedDriverData(filteredDriverBasisOfDriverPermission[0]);
            setSelectedDriverRowId(driverData[0]);
            setFlag(false);
            setLoadUpdatedData(false);
            setDataLoading(false);
            setActiveStep(0);

            return;
          }

          let lastElement = filteredDriver.slice(-1);
          setDriverData(filteredDriver);
          setNextToken(filteredDriver.next_token);
          setSelectedDriverData(filteredDriver[0]);
          setSelectedDriverRowId(driverData[0]);
          setFlag(false);
          setLoadUpdatedData(false);
          setDataLoading(false);
          setActiveStep(0);
        },
        (error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
          });
          setLoadUpdatedData(false);
        },
      );
    }
    if (props.isFromSearch) {
      setDataLoading(true);
      setSelectedDriverRowId(props.driverData[0]);
      setSelectedDriverData(props.driverData[0]);
      setDataLoading(false);
    }
    // setDataLoading(false);
  };

  const getShiftDetails = async (driverID, nextToken = null) => {
    // Setting loading to true
    setIsDataLoading(true);
    try {
      const response = await getDriverTimesheet(driverID, nextToken);

      // Processing response W/O next token
      if (nextToken !== null) {
        setShiftData([...shiftData, ...response]);
      } else {
        setShiftData(response);
      }
      // Saving and updating next token state
      setNextTokenShift(response.next_token);
    } catch (error) {
      console.error('Get driver shifts list error: ', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    } finally {
      // Finally setting loading to false
      setIsDataLoading(false);
    }
  };

  // Toogle drivers status function
  const toggleDriverStatusHandler = async (currentDriver) => {
    setToggleloader(true);
    if (isReadOnlyUser) {
      enqueueSnackbar('Not authorized, contact admin for permissions', {
        variant: 'warning',
      });
      return;
    }
    enqueueSnackbar(
      `${currentDriver.enabled ? 'Disabling' : 'Activating'} driver with email: ${
        currentDriver.email
      }`,
    );

    try {
      const response = await driverToogleStatus(currentDriver.driver_username);
      if (Boolean(response)) {
        loadDrivers(selectedDriverRowId);
        enqueueSnackbar(
          `User with email: ${currentDriver.email} successfully ${
            currentDriver.enabled ? 'disabled' : 'activated'
          }`,
          {
            variant: 'success',
          },
        );
        setToggleloader(false);
      }
    } catch (error) {
      console.log('Error registering driver: ', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setToggleloader(false);
    }
  };

  // function for create a new driver with basic information
  // Old
  // const handleSubmitBasicForm = async () => {
  //   if (props.isFromGuided === true) {
  //     props.setloadingData(true);
  //   }
  //   const requestDummyBody = {
  //     first_name: firstName,
  //     last_name: lastName,
  //     phone_number: '+1' + phoneNum,
  //     email: email,
  //     gender: gender,
  //   };
  //   return new Promise((resolve, reject) => {
  //     registerDriverAdditionalInfo('False', requestDummyBody).then(
  //       (result) => {
  //         setDataLoading(false);
  //         setCreatedDriverID(result.driver_username);
  //         // Todo: Call create permission here ----------------------------------------

  //         console.log('Driver registered with result: ', result);

  //         if (enableCertificateForm === true) {
  //           if (certificateAuthority !== '' && certificateName !== '') {
  //             handleUploadAllImages();
  //           }
  //         } else {
  //           handleUploadAllImages();
  //         }
  //         if (props.isFromGuided === true) {
  //           props.setloadingData(false);
  //         }

  //         resolve();
  //       },
  //       (error) => {
  //         if (props.isFromGuided === true) {
  //           props.setloadingData(false);
  //         }

  //         setButtonLoader(false);
  //         console.log('Error registering driver: ', error);
  //         enqueueSnackbar(getErrorMessage(error), {
  //           variant: 'error',
  //         });
  //         setDataLoading(false);
  //         setButtonText('SAVE');
  //       },
  //     );
  //   });
  // };

  const handleSubmitBasicForm = async () => {
    if (props.isFromGuided) {
      props.setloadingData(true);
    }

    const requestDummyBody = {
      first_name: firstName,
      last_name: lastName,
      phone_number: '+1' + phoneNum,
      email: email,
      gender: gender,
    };
    try {
      const result = await registerDriverAdditionalInfo('False', requestDummyBody);

      setCreatedDriverID(result.driver_username);
      const permissionBody = {
        username: result.driver_username,
        role_type: permissionType,
        schools: permitSchools,
        driver_app_access: driverAppPermit,
      };
      await registerUserPermsissions(props.username, result.driver_username, permissionBody);
      enqueueSnackbar('User successfully created and registered', { variant: 'success' });
      queryClient.invalidateQueries([queryKey.DASHBOARD_USER.ALL_DASHBOARD_USER]);

      setDataLoading(false);
      if (enableCertificateForm) {
        if (certificateAuthority !== '' && certificateName !== '') {
          await handleUploadAllImages();
        }
      } else {
        await handleUploadAllImages();
      }
    } catch (error) {
      if (props.isFromGuided) {
        props.setloadingData(false);
      }

      setButtonLoader(false);
      console.log('Error registering driver: ', error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      setDataLoading(false);
      setButtonText('SAVE');
    } finally {
      // Optional cleanup logic (if needed)
    }
  };

  // function for update driver basic information
  const handleUpdateBasicForm = () => {
    if (props.isFromGuided === true) {
      props.setloadingData(true);
    }
    const requestDummyBody = {
      first_name: firstName,
      last_name: lastName,
      gender: gender,
    };
    if (
      firstName === selectedDriverData.first_name &&
      lastName === selectedDriverData.last_name &&
      gender === selectedDriverData.gender
    ) {
      // handleUpdateBasicForm();
      if (enableCertificateForm === true) {
        if (certificateAuthority !== '' && certificateName !== '') {
          handleUploadAllImages();
        }
      } else {
        handleUploadAllImages();
      }
    } else {
      return new Promise((resolve, reject) => {
        updateDriverAdditionalInfo(
          selectedDriverData.driver_username,
          'False',
          requestDummyBody,
        ).then(
          (result) => {
            if (props.isFromSearch) {
              handleUpdateDriverDataInSearch(result);
            } else {
              handleUpdateDriverData(result);
            }
            if (enableCertificateForm === true) {
              if (certificateAuthority !== '' && certificateName !== '') {
                handleUploadAllImages();
              }
            } else {
              handleUploadAllImages();
              loadDrivers(selectedDriverRowId);
              setAddDriverFormOpen(false);
              setImagesUploaded(false);
              setButtonLoader(false);
              setIsEdit(false);
            }
            if (props.isFromGuided === true) {
              props.setloadingData(false);
            }
            resolve();
          },
          (error) => {
            if (props.isFromGuided === true) {
              props.setloadingData(false);
            }

            console.log('Error updating basic info for driver: ', error);
            enqueueSnackbar(getErrorMessage(error), {
              variant: 'error',
            });
            // setDataLoading(false);
            setButtonText('UPDATE');
            setButtonLoader(false);
          },
        );
      });
    }
  };

  // function for selecting image im avatar
  const handleSelectImage = (event, imageType) => {
    let imageFile = event.target.files['0'].size;
    let imageSize = Math.round(imageFile / 1024);

    if (event.target.files.length < 1) {
    } else if (event.target.files.length === 1) {
      if (imageSize <= 4096) {
        if (imageType === 'profileImage') {
          setProfileImageData(event.target.files[0]);
          setProfileImage(URL.createObjectURL(event.target.files[0]));
          setImageValidation(true);
        }
      } else {
        if (imageType === 'profileImage') {
          setProfileImageData(undefined);
          setProfileImage(undefined);
          setImageValidation(false);
        }
        enqueueSnackbar('Image must be less than 4MB', {
          variant: 'error',
        });
      }
    }
  };

  // function for selecting or drag and drop image in drop zone
  const handleDragImage = (acceptedFile, imageType) => {
    let imageFile = acceptedFile['0'].size;
    let imageSize = Math.round(imageFile / 1024);

    if (acceptedFile.length < 1) {
    } else if (acceptedFile.length === 1) {
      if (imageSize <= 4096) {
        // console.log('Successfully selected', imageType);
        switch (imageType) {
          case 'licenseFrontImage':
            setLicenseFrontImageData(acceptedFile[0]);
            setLicenseFrontImage(URL.createObjectURL(acceptedFile[0]));
            break;
          case 'licenseBackImage':
            setLicenseBackImageData(acceptedFile[0]);
            setLicenseBackImage(URL.createObjectURL(acceptedFile[0]));
            break;
          case 'certificate':
            setCertificateImageData(acceptedFile[0]);
            setCertificateImage(URL.createObjectURL(acceptedFile[0]));
            break;
          default:
            return;
        }
      } else {
        switch (imageType) {
          case 'licenseFrontImage':
            setLicenseFrontImageData(undefined);
            setLicenseFrontImage(undefined);
            break;
          case 'licenseBackImage':
            setLicenseBackImageData(undefined);
            setLicenseBackImage(undefined);
            break;
          case 'certificate':
            setCertificateImageData(undefined);
            setCertificateImage(undefined);
            break;
          default:
            return;
        }
        enqueueSnackbar('Image must be less than 4MB', {
          variant: 'error',
        });
      }
    }
  };

  // function for upload image api
  const uploadImage = (subCategory, imageType) => {
    // setDataLoading(true);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (imageType === 'profileImage') {
        fileReader.readAsDataURL(profileImageData);
      } else if (imageType === 'licenseFrontImage') {
        fileReader.readAsDataURL(licenseFrontImageData);
      } else if (imageType === 'licenseBackImage') {
        fileReader.readAsDataURL(licenseBackImageData);
      } else if (imageType === 'certificate') {
        fileReader.readAsDataURL(certificateImageData);
      }
      fileReader.onload = (e) => {
        uploadDriverAdditionalImage(e.target.result, subCategory).then(
          (result) => {
            const dummyResponse = {
              organization_admin_username: result.organization_admin_username,
              category: result.category,
              sub_category: result.sub_category,
              image_id: result.image_id,
              image_version: result.image_version,
            };
            if (imageType === 'profileImage') {
              setProfileImageResponse(dummyResponse);
            } else if (imageType === 'licenseFrontImage') {
              setLicenseFrontImageResponse(dummyResponse);
            } else if (imageType === 'licenseBackImage') {
              setLicenseBackImageResponse(dummyResponse);
            } else if (imageType === 'certificate') {
              setCertificateImageResponse(dummyResponse);
            }
            resolve();
          },
          (error) => {
            console.log(error);
            enqueueSnackbar(getErrorMessage(error), {
              variant: 'error',
            });
            setButtonText(isEdit ? 'UPDATE' : 'SAVE');
            reject();
          },
        );
      };
    });
  };

  // function for calling upload image function on need
  const handleUploadAllImages = async () => {
    setImagesUploaded(false);
    if (!profileImage && !licenseFrontImage && !licenseBackImage && !certificateImage) {
      setImagesUploaded(true);
      return;
    }
    if (profileImageData !== undefined) {
      await uploadImage('profile-image', 'profileImage');
    }
    if (licenseFrontImageData !== undefined) {
      await uploadImage('license', 'licenseFrontImage');
    }
    if (licenseBackImageData !== undefined) {
      await uploadImage('license', 'licenseBackImage');
    }
    if (enableCertificateForm === true && certificateImageData !== undefined) {
      await uploadImage('certificate', 'certificate');
    }
    setImagesUploaded(true);
  };

  // function for create a new driver with additional information
  const handleSubmitAdditionalInformation = (requestBody) => {
    const modifiedRequestBody = {
      ...requestBody,
      designation: designation,
      role: roleType,
      enrollment_type: enrolmentType,
    };
    setFlag(false);
    return new Promise((resolve, reject) => {
      registerDriverAdditionalInfo('True', modifiedRequestBody).then(
        (result) => {
          // console.log('Driver additional info registered ', result);
          setDataLoading(false);
          clearAllFormStates();
          setActiveStep(0);
          enqueueSnackbar('User successfully registered', {
            variant: 'success',
          });
          if (props.isFromGuided) {
            props.setDriverCardData({});
            props.setEditGuidedDriver(undefined);
            props.getAllDrivers();
            props.handleUpdateGuidedWorkflowData();
          } else {
            loadDrivers(selectedDriverRowId);
            setAddDriverFormOpen(false);
          }
          setImagesUploaded(false);
          setButtonLoader(false);
          resolve();
        },
        (error) => {
          console.log("Error registering driver's additional info: ", error.data);
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
          });
          setDataLoading(false);
          setImagesUploaded(false);
          setLoadUpdatedData(false);
        },
      );
    });
  };

  async function handleUpdatePermission() {
    const userId = selectedDriverData?.driver_username;
    if (!isLoadingUsers || userId) {
      try {
        const userData = users.find((user) => user.dashboard_user_username === userId)
          .user_permissions;

        if (
          permissionType === userData?.role_type &&
          JSON.stringify(permitSchools) === JSON.stringify(userData?.schools) &&
          driverAppPermit === userData?.driver_app_access
        ) {
          return;
        }

        const permissionBody = {
          username: userId,
          role_type: permissionType,
          schools: permitSchools,
          driver_app_access: driverAppPermit,
        };
        await updateUserPermsissions(
          props.username,
          selectedDriverData.driver_username,
          permissionBody,
        );
        queryClient.invalidateQueries([queryKey.DASHBOARD_USER.ALL_DASHBOARD_USER]);
        enqueueSnackbar('Permission updated successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        });
      }
    }
  }

  // function for update driver with additional information
  const handleUpdateAdditionalInformation = async (requestBody) => {
    const modifiedRequestBody = {
      ...requestBody,
      designation: designation,
      role: roleType,
      enrollment_type: enrolmentType,
    };
    await handleUpdatePermission();
    setFlag(false);
    // console.log('request body for updating driver additional info', requestBody);
    return new Promise((resolve, reject) => {
      updateDriverAdditionalInfo(
        selectedDriverData.driver_username,
        'True',
        modifiedRequestBody,
      ).then(
        (result) => {
          // console.log('Driver additional info updated ', result);
          setDataLoading(false);
          clearAllFormStates();
          setActiveStep(0);
          enqueueSnackbar('User successfully Updated', {
            variant: 'success',
          });
          if (props.isFromGuided) {
            props.setDriverCardData({});
            props.setEditGuidedDriver(undefined);
            props.getAllDrivers();
          } else {
            loadDrivers(selectedDriverRowId);
            setAddDriverFormOpen(false);
          }
          setImagesUploaded(false);
          setButtonLoader(false);
          resolve();
        },
        (error) => {
          console.log("Error registering driver's additional info: ", error);
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
          });
          setDataLoading(false);
          setLoadUpdatedData(false);
          setButtonText('UPDATE');
          setImagesUploaded(false);
          setButtonLoader(false);
        },
      );
    });
  };

  const handleUpdateDriverDataInSearch = (updatedData) => {
    let data = props.driverData;
    let index = data.findIndex((driver) => {
      return driver.id === selectedDriverData.id;
    });
    data.splice(index, 1, updatedData);
    dispatch(handleUpdateData('driverData', data));
    setSelectedDriverData(updatedData);
    setIsEdited(!isEdited);
  };

  const handleUpdateDriverData = (updatedData) => {
    let data = driverData;
    let index = data.findIndex((driver) => {
      return driver.id === selectedDriverData.id;
    });
    data.splice(index, 1, updatedData);
    setDriverData(data);
    setSelectedDriverData(updatedData);
    setIsEdited(!isEdited);
  };

  // useEffect for calling handleSubmitAdditionalInformation or handleUpdateAdditionalInformation
  useEffect(() => {
    if (enableCertificateForm) {
      if (
        // profileImageResponse !== '' &&
        // licenseFrontImageResponse !== '' &&
        // licenseBackImageResponse !== '' &&
        certificateImageResponse !== '' &&
        // profileImageResponse !== undefined &&
        // licenseFrontImageResponse !== undefined &&
        // licenseBackImageResponse !== undefined &&
        certificateImageResponse !== undefined &&
        createdDriverID &&
        imagesUploaded
      ) {
        const additionalDataWithCertificate = {
          username: createdDriverID,
          license_class: licenseClass,
          license_number: licenseNumber,
          license_iss_date: licenseIssDate ? Math.round(licenseIssDate.getTime() / 1000) : null,
          license_exp_date: licenseExpDate ? Math.round(licenseExpDate.getTime() / 1000) : null,
          height: height,
          address: {
            apt_plot: aptPlot,
            street: street,
            city: city,
            state: state,
            zip_code: zipCode,
          },
          geo_location: {
            latitude: latitude,
            longitude: longitude,
          },
          // shifts: driverShift,
          certificate_name: certificateName,
          certificate_authority: certificateAuthority,
          profile_image: profileImageResponse,
          license_front_image: licenseFrontImageResponse,
          license_back_image: licenseBackImageResponse,
          certificate_image: certificateImageResponse,
        };
        if (isEdit && flag) {
          // delete additionalDataWithCertificate['username'];
          handleUpdateAdditionalInformation(additionalDataWithCertificate);
        } else if (!isEdit && flag) {
          handleSubmitAdditionalInformation(additionalDataWithCertificate);
        }
      }
    } else {
      if (createdDriverID && imagesUploaded) {
        const additionalDataWithoutCertificate = {
          username: createdDriverID,
          license_class: licenseClass,
          license_number: licenseNumber,
          license_iss_date: licenseIssDate ? Math.round(licenseIssDate.getTime() / 1000) : null,
          license_exp_date: licenseExpDate ? Math.round(licenseExpDate.getTime() / 1000) : null,
          height: height,
          address: {
            apt_plot: aptPlot,
            street: street,
            city: city,
            state: state,
            zip_code: zipCode,
          },
          geo_location: {
            latitude: latitude,
            longitude: longitude,
          },
          // shifts: driverShift,
          profile_image: profileImageResponse,
          license_front_image: licenseFrontImageResponse,
          license_back_image: licenseBackImageResponse,
        };
        if (isEdit && flag) {
          // delete additionalDataWithoutCertificate['username'];
          handleUpdateAdditionalInformation(additionalDataWithoutCertificate);
        } else if (!isEdit && flag) {
          handleSubmitAdditionalInformation(additionalDataWithoutCertificate);
        }
      }
    }
  }, [
    profileImageResponse,
    licenseFrontImageResponse,
    licenseBackImageResponse,
    certificateImageResponse,
    createdDriverID,
    flag,
  ]);

  useEffect(() => {
    if (isLoadingUsers) return;
    loadDrivers(0);
    return () => {
      closeSnackbar();
    };
  }, [props?.driverData, idDriver, isLoadingUsers]);

  // function for clearing all states so farm should be empty after adding or updating driver
  const clearAllFormStates = () => {
    console.log('clearingForm');
    setFirstName('');
    setLastName('');
    setHeight('');
    setPhoneNum('');
    setEmail('');
    setGender('na');
    setStreet('');
    setCity('');
    setState('');
    setZipCode('');
    setAptPlot('');
    setLatitude('');
    setLongitude('');
    setLicenseIssDate(null);
    setLicenseExpDate(null);
    setLicenseClass('');
    setLicenseNumber('');
    setCertificateName('');
    setCertificateAuthority('');
    setEnableCertificateForm(false);
    setProfileImage(undefined);
    setProfileImageData(undefined);
    setProfileImageResponse(undefined);
    setLicenseFrontImage(undefined);
    setLicenseFrontImageData(undefined);
    setLicenseFrontImageResponse(undefined);
    setLicenseBackImage(undefined);
    setLicenseBackImageData(undefined);
    setLicenseBackImageResponse(undefined);
    setCertificateImage(undefined);
    setCertificateImageData(undefined);
    setCertificateImageResponse(undefined);
    setCreatedDriverID('');
    setActiveStep(0);
    setIsEdit(false);
    setFlag(false);
    setButtonText('SAVE');
    setButtonLoader(false);
    setImageValidation(false);
    setImagesUploaded(false);

    setDriverShift([
      {
        clock_in_time: '06:00:00',
        clock_out_time: '07:00:00',
        allow_early_clock_in: false,
      },
    ]);
    setPermissionType(Roles.SCHOOL_LEVEL_READ_ONLY_ACCESS);
    setPermitSchools([props?.schoolsData[0]?.school_id]);
    setDriverAppPermit(false);
    setDesignation('');
    setRoleType([]);
    setEnrolmentType([]);
  };

  // function for row click
  const handleRowClick = (e, dat) => {
    if (dat.driver_username === selectedDriverData.driver_username) {
      return;
    }
    const findData = (!props.isFromSearch ? driverData : props.driverData).find(
      (driver) => driver.driver_username === dat.driver_username,
    );
    setSelectedDriverData(findData);
    setSelectedDriverRowId(dat.id);
    setOpenDriverShifts({ loading: false, open: false });
  };

  // useeffect for guidedworkflow edit driver
  useEffect(() => {
    if (props.isFromGuided && props.editGuidedDriver) {
      setSelectedDriverData(props.driverCardData);
      setCreatedDriverID(props.driverCardData.driver_username);
      handleStatesOnEdit(props.driverCardData);
      setIsEdit(true);
      // setAddDriverFormOpen(true);
    }
  }, [props.editGuidedDriver]);

  // function for edit button click
  const handleEditClick = (data) => {
    setCreatedDriverID(selectedDriverData.driver_username);
    handleStatesOnEdit();
    setIsEdit(true);
    setAddDriverFormOpen(true);
  };

  const getPhoneNumber = (data = selectedDriverData) => {
    return data.phone_number.includes('+1') ? data.phone_number.slice(2) : data.phone_number;
  };

  const getImageResponse = (imageJson) => {
    return {
      organization_admin_username: imageJson.organization_admin_username,
      category: imageJson.category,
      sub_category: imageJson.sub_category,
      image_id: imageJson.image_id,
      image_version: imageJson.image_version,
    };
  };
  const handleStatesOnEdit = (data = selectedDriverData) => {
    setDesignation(data?.designation || '');
    setRoleType(data?.role || []);
    setEnrolmentType(data?.enrollment_type || '');
    setFlag(false);
    setFirstName(data?.first_name);
    setLastName(data?.last_name);
    setGender(data?.gender);
    setHeight(data?.height);
    setEmail(data?.email);
    setPhoneNum(getPhoneNumber(data));
    setProfileImage(data?.profile_image?.url);
    setImageValidation(data?.profile_image?.url ? true : false);
    setProfileImageResponse(
      data?.profile_image ? getImageResponse(data?.profile_image) : undefined,
    );
    setStreet(data?.address?.street);
    setCity(data?.address?.city);
    setState(data?.address?.state);
    setZipCode(data?.address?.zip_code);
    setAptPlot(data?.address?.apt_plot);
    setLatitude(data?.geo_location?.latitude);
    setLongitude(data?.geo_location?.longitude);
    setLicenseFrontImage(data?.license_front_image?.url);
    setLicenseFrontImageResponse(
      data?.license_front_image ? getImageResponse(data?.license_front_image) : undefined,
    );
    setLicenseBackImage(data?.license_back_image?.url);
    setLicenseBackImageResponse(
      data?.license_back_image ? getImageResponse(data?.license_back_image) : undefined,
    );
    setLicenseIssDate(
      data?.license_iss_date ? new Date(Number(data?.license_iss_date * 1000)) : null,
    );
    setLicenseExpDate(
      data?.license_exp_date ? new Date(Number(data?.license_exp_date * 1000)) : null,
    );
    setLicenseClass(data?.license_class);
    setLicenseNumber(data?.license_number);
    setCertificateImage(data?.certificate_image?.url);
    setCertificateImageResponse(
      data?.certificate_image ? getImageResponse(data?.certificate_image) : undefined,
    );
    setCertificateName(data?.certificate_name ?? '');
    setCertificateAuthority(data?.certificate_authority ?? '');
    setButtonText('UPDATE');
    if (data?.certificate_authority || data?.certificate_name || data?.certificate_image) {
      setEnableCertificateForm(true);
    } else {
      setEnableCertificateForm(false);
    }
    // setDriverShift(data?.shifts);
  };

  const handleEventClick = (event) => {
    const filterDriverShiftFormData = driverShiftsFormData.find((ele) => ele.id === event.shiftID);
    setOpenDriverShiftsForm(true);
    setEditedDriverShiftFormData(filterDriverShiftFormData);
    setIsUpdate(true);
    setShiftStartDate(filterDriverShiftFormData.startDate);
    setOccurrenceType(filterDriverShiftFormData.dateOccurrence);
  };

  const handleOpenDriverShiftForm = () => {
    setOpenDriverShiftsForm(true);
    setIsUpdate(false);
    setOccurrenceType('nonrepetitive');
  };

  const handleOpenDriverShiftFormClose = (actions) => {
    setOpenDriverShiftsForm(false);
    actions.resetForm();
  };

  function convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);

    console.log(`dd:${day} mm:${mnth} yy:${date.getFullYear()}`);

    return [mnth, day, date.getFullYear()].join('/');
  }

  function getTime(date) {
    if (date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ':' + '00';
      return strTime;
    }
    return '';
  }

  const _handleSubmit = async (values, actions) => {
    formFieldValues.forEach((day) => {
      actions.setFieldTouched(`${day}StartTime`, true);
      actions.setFieldTouched(`${day}EndTime`, true);
    });

    const uniqueID = new Date().getTime().toString();
    const convertedValues = {
      shiftTitle: values.shiftTitle,
      startDate: convertDate(values.startDate),
      endDate: convertDate(values.endDate),
      mondayCheckBox: values.mondayCheckBox,
      mondayStartTime: getTime(values.mondayStartTime),
      mondayEndTime: getTime(values.mondayEndTime),
      // allowEarlyClockInTimeMonday: values.allowEarlyClockInTimeMonday,
      tuesdayCheckBox: values.tuesdayCheckBox,
      tuesdayStartTime: getTime(values.tuesdayStartTime),
      tuesdayEndTime: getTime(values.tuesdayEndTime),
      // allowEarlyClockInTimeTuesday: values.allowEarlyClockInTimeTuesday,
      wednesdayCheckBox: values.wednesdayCheckBox,
      wednesdayStartTime: getTime(values.wednesdayStartTime),
      wednesdayEndTime: getTime(values.wednesdayEndTime),
      // allowEarlyClockInTimeWednesday: values.allowEarlyClockInTimeWednesday,
      thursdayCheckBox: values.thursdayCheckBox,
      thursdayStartTime: getTime(values.thursdayStartTime),
      thursdayEndTime: getTime(values.thursdayEndTime),
      // allowEarlyClockInTimeThursday: values.allowEarlyClockInTimeThursday,
      fridayCheckBox: values.fridayCheckBox,
      fridayStartTime: getTime(values.fridayStartTime),
      fridayEndTime: getTime(values.fridayEndTime),
      // allowEarlyClockInTimeFriday: values.allowEarlyClockInTimeFriday,
      saturdayCheckBox: values.saturdayCheckBox,
      saturdayStartTime: getTime(values.saturdayStartTime),
      saturdayEndTime: getTime(values.saturdayEndTime),
      // allowEarlyClockInTimeSaturday: values.allowEarlyClockInTimeSaturday,
      sundayCheckBox: values.sundayCheckBox,
      sundayStartTime: getTime(values.sundayStartTime),
      sundayEndTime: getTime(values.sundayEndTime),
      // allowEarlyClockInTimeSunday: values.allowEarlyClockInTimeSunday,
      shiftID: isUpdate ? editedDriverShiftFormData.shiftID : '',
      monthlyDate: values.monthlyDate,
      adhocEndTime: values.adhocEndTime,
      adhocStartTime: values.adhocStartTime,
      dateOccurrence: values.dateOccurrence,
    };

    /**
     * Generate events based on the selected date occurrence.
     *
     * @return {array} An array of generated events for calender view.
     */

    // Update Driver Shift
    if (isUpdate) {
      try {
        const ShiftID = editedDriverShiftFormData.shiftID;
        // values['shiftID'] = ShiftID;

        const updatedRequest = requestFactory({ values, selectedDriverUserId });
        const modifiedRequest = {
          ...updatedRequest,
          driver_id: selectedDriverUserId,
          id: ShiftID,
          organization_admin_username: editedDriverShiftFormData.organization_admin_username,
          created_timestamp: editedDriverShiftFormData.created_timestamp,
          created_by: editedDriverShiftFormData.created_by,
          last_updated_timestamp: editedDriverShiftFormData.last_updated_timestamp,
          last_updated_by: editedDriverShiftFormData.last_updated_by,
        };
        const result = await updateDriverShift(modifiedRequest, selectedDriverUserId, ShiftID);
        const getEvents = () => {
          if (values.dateOccurrence === DATE_OCCURRENCE.WEEKLY) {
            return generateEventsFromData([{ ...convertedValues, shiftID: ShiftID }]);
          }
          if (values.dateOccurrence === DATE_OCCURRENCE.MONTHLY) {
            return generateEventsFromDataMonth([{ ...convertedValues, shiftID: ShiftID }]);
          }
          if (values.dateOccurrence === DATE_OCCURRENCE.ADHOC) {
            return generateEventsFromAdhoc([{ ...convertedValues, shiftID: ShiftID }]);
          }
        };
        const updatedDriverFormData = driverShiftsFormData.map((ele) => {
          if (editedDriverShiftFormData.shiftID === ele.shiftID) {
            return { ...ele, ...values, ...result };
          }
          return ele;
        });
        setDriverShiftsFormData(updatedDriverFormData);

        const updatedEventsOnCalendar = driverShifts.filter(
          (ele) => ele.shiftID !== editedDriverShiftFormData.shiftID,
        );

        setDriverShifts([...updatedEventsOnCalendar, ...getEvents()]);
        setOpenDriverShiftsForm(false);
        enqueueSnackbar('User shift updated successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('User shift update failed ', {
          variant: 'error',
        });
      }
    } else {
      try {
        // values['shiftID'] = uniqueID;
        const requestBody = requestFactory({ values, selectedDriverUserId });

        const result = await registerDriverShift(requestBody);
        const getEvents = () => {
          if (values.dateOccurrence === DATE_OCCURRENCE.WEEKLY) {
            return generateEventsFromData([{ ...convertedValues, shiftID: result.id }]);
          }
          if (values.dateOccurrence === DATE_OCCURRENCE.MONTHLY) {
            return generateEventsFromDataMonth([{ ...convertedValues, shiftID: result.id }]);
          }
          if (values.dateOccurrence === DATE_OCCURRENCE.ADHOC) {
            return generateEventsFromAdhoc([{ ...convertedValues, shiftID: result.id }]);
          }
        };

        setDriverShiftsFormData([
          ...driverShiftsFormData,
          ...generateEventsFromDriverShifts([result]),
        ]);

        setDriverShifts([...driverShifts, ...getEvents()]);

        handleOpenDriverShiftFormClose(actions);
        enqueueSnackbar('User shift created successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('User shift creation failed', {
          variant: 'error',
        });
      }
    }
  };

  const handleShiftClick = useCallback(
    async (event, driverUserName) => {
      setOpenDriverShifts((pre) => {
        return {
          ...pre,
          loading: true,
        };
      });
      try {
        const results = await getDriverShifts(driverUserName);
        const driverShifts = [...results];

        setDriverShiftsFormData(generateEventsFromDriverShifts([...driverShifts]));
        setSelectedDriverUserId(driverUserName);

        const weeklyData = driverShifts.filter(
          (ele) => ele.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.WEEKLY,
        );
        const monthlyData = driverShifts.filter(
          (ele) => ele.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.MONTHLY,
        );

        const adhocData = driverShifts.filter(
          (ele) => ele.recurrence_pattern.recurrence_type === DATE_OCCURRENCE.ADHOC,
        );

        const generatedWeeklyEvents = generateEventsFromData(
          generateEventsFromDriverShifts(weeklyData),
        );
        const generatedMonthlyEvents = generateEventsFromDataMonth(
          generateEventsFromDriverShifts(monthlyData),
        );

        const generatedAdhocEvents = generateEventsFromAdhoc(
          generateEventsFromDriverShifts(adhocData),
        );

        const events = [
          ...generatedWeeklyEvents,
          ...generatedMonthlyEvents,
          ...generatedAdhocEvents,
        ];
        setDriverShifts(events);

        setOpenDriverShifts({
          open: true,
          loading: false,
        });
      } catch (error) {
        console.log('handleShiftClick', error);
      }
    },
    // eslint-disable-next-line
    [driverShift, selectedDriverData?.driver_username],
  );

  function handleResetForm(resetForm) {
    resetForm();
  }

  /**
   * Handle driver sheet click
   */
  const handleShiftDetails = () => {
    setOpenShift(true);
    getShiftDetails(selectedDriverData.driver_username);
  };

  useEffect(() => {
    if (isEdit) {
      const userId = selectedDriverData?.driver_username;
      if (!isLoadingUsers || userId) {
        try {
          const userData = users.find((user) => user.dashboard_user_username === userId)
            .user_permissions;
          if (userData && userData?.role_type) {
            setPermissionType(userData?.role_type);
            setPermitSchools(userData?.schools);
            setDriverAppPermit(userData?.driver_app_access);
            return;
          } else {
            setPermissionType(Roles.SCHOOL_LEVEL_READ_ONLY_ACCESS);
            setPermitSchools([props?.schoolsData[0]?.school_id]);
            return;
          }
        } catch (error) {
          console.log(error, 'This driver have not any permission');
        }
      }
    }
    setPermissionType(Roles.SCHOOL_LEVEL_READ_ONLY_ACCESS);
    setPermitSchools([props?.schoolsData[0]?.school_id]);
    // eslint-disable-next-line
  }, [isLoadingUsers, isEdit, props?.schoolsData]);

  console.log('Driver shifts info: ', shiftData);

  return (
    <React.Fragment>
      {openShift ? (
        <>
          <Grid container alignItems={'center'} xs={12}>
            <IconButton onClick={() => setOpenShift(false)}>
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>
          </Grid>
          <ShiftTable
            loader={isDataLoading}
            data={shiftData}
            nextToken={nextTokenShift}
            nextResultSet={() =>
              getShiftDetails(selectedDriverData.driver_username, nextTokenShift)
            }
          />
        </>
      ) : openDriverShifts.open ? (
        <>
          <ShiftHeader
            setOpenDriverShifts={setOpenDriverShifts}
            handleOpenDriverShiftForm={handleOpenDriverShiftForm}
            isReadOnlyUser={isReadOnlyUser}
            isEdit={isEdit}
            loadUpdatedData={loadUpdatedData}
            shiftTab={shiftTab}
            setShiftTab={setShiftTab}
            driverData={driverData}
            handleShiftClick={handleShiftClick}
            openDriverShifts={openDriverShifts}
            // selectedDriverData={selectedDriverData}
            selectedDriverUserId={selectedDriverUserId}
            setSelectedDriverUserId={setSelectedDriverUserId}
          />
          <Stack>
            {shiftTab === 0 && (
              <ShiftCalender
                driverShifts={driverShifts}
                selectedDateForCalendar={selectedDateForCalendar}
                handleEventClick={handleEventClick}
              />
            )}
            <Formik
              initialValues={formInitialValuesDriverShifts(editedDriverShiftFormData, isUpdate)}
              validationSchema={() =>
                validationSchemaDriverShifts({ occurrenceType, shiftStartDate, isUpdate })
              }
              onSubmit={_handleSubmit}
              enableReinitialize
              resetForm={handleResetForm}
            >
              {(formikProps) => (
                <Form id='driverShiftForm'>
                  <DriverShifts
                    handleOpenDriverShiftForm={handleOpenDriverShiftForm}
                    openDriverShiftsForm={openDriverShiftsForm}
                    handleOpenDriverShiftFormClose={(formikProps) =>
                      handleOpenDriverShiftFormClose(formikProps)
                    }
                    formikProps={formikProps}
                    isUpdate={isUpdate}
                    setOccurrenceType={setOccurrenceType}
                    driverData={driverData}
                    selectedDriverUserId={selectedDriverUserId}
                  />
                </Form>
              )}
            </Formik>

            <Stack>
              {shiftTab === 1 && (
                <Stack>
                  <DriverShiftsList
                    driverShifts={driverShifts}
                    setEditedDriverShiftFormData={setEditedDriverShiftFormData}
                    setIsUpdate={setIsUpdate}
                    driverShiftsFormData={driverShiftsFormData}
                    setOpenDriverShiftsForm={setOpenDriverShiftsForm}
                    setDriverShiftDeleteAlertOpen={setDriverShiftDeleteAlertOpen}
                    driverShiftDeleteAlertOpen={driverShiftDeleteAlertOpen}
                    setDriverShiftsFormData={setDriverShiftsFormData}
                    setDriverShifts={setDriverShifts}
                    editedDriverShiftFormData={editedDriverShiftFormData}
                    setOccurrenceType={setOccurrenceType}
                    setShiftStartDate={setShiftStartDate}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        !props.isFromGuided && (
          <Grid container spacing={3}>
            {!props.isFromSearch ? (
              <Grid item xs={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  spacing={3}
                >
                  <Grid item width={'100%'}>
                    {location.pathname === '/drivers' ? (
                      <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Grid xs={8}>
                          <Alert severity='info'>
                            For managing users and drivers, visit the user page.
                          </Alert>
                        </Grid>
                        <Grid xs={4} display={'flex'} justifyContent={'flex-end'}>
                          <Link to={'/users'}>
                            <ActionButton variant={'text'} startIcon={<ArrowUpwardIcon />}>
                              Go to Users
                            </ActionButton>
                          </Link>
                        </Grid>
                      </Stack>
                    ) : (
                      <Stack width={'100%'} direction={'row'} justifyContent={'flex-end'}>
                        <ActionButton
                          id='driver-add-button'
                          disabled={isReadOnlyUser || isEdit || loadUpdatedData}
                          onClick={(e) => setAddDriverFormOpen(true)}
                          startIcon={<PersonAddIcon />}
                        >
                          Add User
                        </ActionButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={8}>
              <DriverTable
                columns={columns}
                driverData={!props.isFromSearch ? driverData : props.driverData}
                dataLoading={dataLoading || props.dataLoading}
                setSelectedDriverData={setSelectedDriverData}
                selectedDriverData={selectedDriverData || props.driverData}
                setSelectedDriverRowId={setSelectedDriverRowId}
                selectedDriverRowId={selectedDriverRowId}
                handleRowClick={handleRowClick}
                setPage={setPage}
                page={page}
                filledRows={filledRows}
                setFilledRows={setFilledRows}
                searched={searched}
                setSearched={setSearched}
                nextToken={nextToken}
                isFromSearch={props.isFromSearch}
              />
            </Grid>
            <Grid item xs={4}>
              <DriverDetails
                selectedDriverRowId={selectedDriverRowId}
                selectedDriverData={selectedDriverData}
                handleDeleteDriver={handleDeleteDriver}
                handleEditClick={handleEditClick}
                setOpenDeleteDriverAlert={setOpenDeleteDriverAlert}
                setDriverUserName={setDriverUserName}
                toggleDriverStatusHandler={toggleDriverStatusHandler}
                isEdit={isEdit}
                loadUpdatedData={loadUpdatedData}
                filledRows={filledRows}
                dataLoading={!props.isFromSearch ? dataLoading : props.dataLoading}
                isPremiumUser={props.isPremiumUser}
                toggleloader={toggleloader}
                openDriverShifts={openDriverShifts}
                handleShiftClick={handleShiftClick}
                handleShiftDetails={handleShiftDetails}
                readPermissionProps={readPermissionProps}
              />
            </Grid>
          </Grid>
        )
      )}

      <DriverFormWrapper
        addDriverFormOpen={addDriverFormOpen}
        closeAddDriverForm={() => {
          setAddDriverFormOpen(false);
        }}
        selectedDriverRowId={selectedDriverRowId}
        loadDrivers={loadDrivers}
        handleSubmitBasicForm={(values) => handleSubmitBasicForm(values)}
        handleUpdateBasicForm={(values) => handleUpdateBasicForm(values)}
        selectedDriverData={selectedDriverData}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        gender={gender}
        setGender={setGender}
        height={height}
        setHeight={setHeight}
        phoneNum={phoneNum}
        setPhoneNum={setPhoneNum}
        email={email}
        setEmail={setEmail}
        street={street}
        setStreet={setStreet}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zipCode={zipCode}
        setZipCode={setZipCode}
        aptPlot={aptPlot}
        setAptPlot={setAptPlot}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleSelectImage={handleSelectImage}
        handleDragImage={handleDragImage}
        uploadImage={uploadImage}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        profileImageData={profileImageData}
        setProfileImageData={setProfileImageData}
        setProfileImageResponse={setProfileImageResponse}
        licenseFrontImage={licenseFrontImage}
        setLicenseFrontImage={setLicenseFrontImage}
        licenseFrontImageData={licenseFrontImageData}
        setLicenseFrontImageData={setLicenseFrontImageData}
        setLicenseFrontImageResponse={setLicenseFrontImageResponse}
        licenseBackImage={licenseBackImage}
        setLicenseBackImage={setLicenseBackImage}
        licenseBackImageData={licenseBackImageData}
        setLicenseBackImageData={setLicenseBackImageData}
        setLicenseBackImageResponse={setLicenseBackImageResponse}
        certificateImage={certificateImage}
        setCertificateImage={setCertificateImage}
        certificateImageData={certificateImageData}
        setCertificateImageData={setCertificateImageData}
        setCertificateImageResponse={setCertificateImageResponse}
        licenseClass={licenseClass}
        setLicenseClass={setLicenseClass}
        licenseNumber={licenseNumber}
        setLicenseNumber={setLicenseNumber}
        licenseIssDate={licenseIssDate}
        setLicenseIssDate={setLicenseIssDate}
        licenseExpDate={licenseExpDate}
        setLicenseExpDate={setLicenseExpDate}
        certificateName={certificateName}
        setCertificateName={setCertificateName}
        certificateAuthority={certificateAuthority}
        setCertificateAuthority={setCertificateAuthority}
        enableCertificateForm={enableCertificateForm}
        setEnableCertificateForm={setEnableCertificateForm}
        handleUploadAllImages={handleUploadAllImages}
        flag={flag}
        setFlag={setFlag}
        loadUpdatedData={loadUpdatedData}
        setLoadUpdatedData={setLoadUpdatedData}
        clearAllFormStates={clearAllFormStates}
        buttonText={buttonText}
        setButtonText={setButtonText}
        buttonLoader={buttonLoader}
        setButtonLoader={setButtonLoader}
        imageValidation={imageValidation}
        setImageValidation={setImageValidation}
        driverShift={driverShift}
        setDriverShift={setDriverShift}
        permissionProps={permissionProps}
        additionalInfoBasicInfoProps={additionalInfoBasicInfoProps}
        {...props}
      />
      {!props.isFromGuided && (
        <DeleteAlert
          open={openDeleteDriverAlert}
          setOpen={setOpenDeleteDriverAlert}
          setClose={setOpenDeleteDriverAlert}
          handleDeleteDriver={(id) => handleDeleteDriver(id)}
          driverUserName={driverUserName}
          dataLoading={buttonLoader}
        />
      )}
    </React.Fragment>
  );
}
