import React from 'react';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Tooltip } from '@mui/material';

const StyledBadgePresent = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const StyledBadgeAbsent = withStyles((theme) => ({
    badge: {
        backgroundColor: '#ff1744',
        color: '#ff1744',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        textAlign: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    typography: {
        fontSize: '10px',
    },
    error: {
        color: theme.palette.error.getContrastText,
        backgroundColor: theme.palette.error.main,
    },
}));

export function StudentPresent() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <StyledBadgePresent
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="dot"
                    >
                        <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                            className={classes.avatar}
                        />
                    </StyledBadgePresent>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        className={classes.typography}
                        variant="body1"
                        display="inline"
                        noWrap
                    >
                        Sharp
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export function StudentAbsent() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <StyledBadgeAbsent
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="dot"
                    >
                        <Avatar
                            alt="Sam Smith"
                            src="/static/images/avatar/1.jpg"
                            className={classes.avatar}
                        />
                    </StyledBadgeAbsent>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        className={classes.typography}
                        variant="caption"
                        display="inline"
                        noWrap
                    >
                        Sam
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export function getPresentStudents(childsAttendanceInfo, stopId, history) {
    if (
        childsAttendanceInfo &&
        childsAttendanceInfo.stop_attendance &&
        childsAttendanceInfo.stop_attendance[stopId] &&
        childsAttendanceInfo.stop_attendance[stopId].PRESENT.length !== 0
    ) {
        return (
            <AvatarGroup max={4}>
                {childsAttendanceInfo.stop_attendance[stopId].PRESENT.map(
                    (child, index) => (
                        <Tooltip title={child.first_name + ' ' + child.last_name} arrow placement="top">
                        <Avatar
                            key={index}
                            alt={child.first_name}
                            src={child.profile_image && child.profile_image.url ? 
                                child.profile_image.url : "/static/images/avatar/1.jpg"
                            }
                            // onClick={() => history.push(
                            //     {pathname: "/student/" + child.id}
                            // )}
                        />
                        </Tooltip>
                    )
                )}
            </AvatarGroup>
        );
    }
    return (
        // <Avatar
        //     style={{ color: '#fff', backgroundColor: '#f44336' }}
        //     alt="None"
        //     src="/static/images/avatar/1.jpg"
        // />
        <div style={{width: 40,
            height: 40}}>

        </div>

    );
}

export function getAbsentStudents(childsAttendanceInfo, stopId) {
    if (
        childsAttendanceInfo &&
        childsAttendanceInfo.stop_attendance &&
        childsAttendanceInfo.stop_attendance[stopId] &&
        childsAttendanceInfo.stop_attendance[stopId].ABSENT.length !== 0
    ) {
        return (
            <AvatarGroup max={4} sx={{pb: 12}}>
                {childsAttendanceInfo.stop_attendance[stopId].ABSENT.map(
                    (child, index) => (
                        <Tooltip title={child.first_name + ' ' + child.last_name} arrow placement="top">
                        <Avatar
                            key={index}
                            alt={child.first_name}
                            src={child.profile_image && child.profile_image.url ? 
                                child.profile_image.url : "/static/images/avatar/1.jpg"
                            }
                        />
                        </Tooltip>
                    )
                )}
            </AvatarGroup>
        );
    }
    return (
        // <Avatar
        //     style={{ color: '#fff', backgroundColor: '#f44336' }}
        //     alt="None"
        //     src="/static/images/avatar/1.jpg"
        // />
        <div style={{width: 40,
            height: 40}}>

        </div>
    );
}
