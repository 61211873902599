import React, { useState } from 'react';
import PropTypes from 'prop-types';

// third-party imports
import { Field } from 'formik';
import { TextField } from 'formik-mui';

// material-ui
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// project imports
import { inputVariant } from 'constants';
import { chipItems } from '../FormModel/sessionFormModel';
import { CustomChipArray } from 'components/Formik';

// assets
import NotesIcon from '@mui/icons-material/Notes';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

// title textfield start icon
const nameInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <SubtitlesIcon />
    </InputAdornment>
  ),
};

// subject start icon
const descInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <NotesIcon />
    </InputAdornment>
  ),
};

const getYearPlaceHolder = () => {
  const d = new Date();
  let year = d.getFullYear();

  return `${year}-${year + 1}`;
};

// ============================== Details Form - Sessions ============================== //

function DetailsForm({ formikProps, isUpdate, guidedForm }) {
  const { values } = formikProps;

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Field
          fullWidth
          component={TextField}
          label='Session name *'
          name='name'
          variant={inputVariant}
          InputProps={nameInputProps}
          placeholder={getYearPlaceHolder()}
          helperText='Please enter a name for this session'
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          fullWidth
          label='Description'
          component={TextField}
          variant={inputVariant}
          InputProps={descInputProps}
          name='desc'
          placeholder={`Session description for session ${getYearPlaceHolder()}`}
          helperText='Please enter the session description up to 256 characters'
        />
      </Grid>
      {guidedForm ? null : (
        <Grid item xs={6}>
          {isUpdate && (
            <Field
              fullWidth
              component={CustomChipArray}
              label='Status *'
              name='status'
              chipItems={chipItems}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

DetailsForm.propTypes = {};

export default DetailsForm;
