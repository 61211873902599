export const APP_TITLE = 'SpotBus | Transportation Admin';
export const drawerWidth = 240;
export const inputVariant = 'standard';
export const inputVariantsmall = 'small';

export const inputVariantV2 = 'outlined';
export const inputSizeV2 = 'small';

export const inputVariantDevice = 'outlined';
export const inputSizeDevice = 'small';

export const inputVariantStudent = 'outlined';

export const allowedCountries = ['IN', 'US'];

export const tableRowAlignment = 'left ';

export const tableHeadAlignment = 'left';

/**
 * @type {number}
 */
export const rowsPerPage = 20;

export const sortOrder = 'DESC';

export const iconSize = 'small';

export const iconColor = 'primary';

export const studentListCount = 300
