import React from 'react';

// third-party imports
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { InputAdornment, Stack, Typography, FormHelperText } from '@mui/material';

// Components
import { CountrySelect, SelectField } from 'components/Formik';
import { inputVariantDevice } from 'constants';
import ChipFormik from 'components/Formik/ChipFormik';
import CheckBoxFormik from 'components/Formik/CheckBoxFormik';

// Context
import { useTheme } from '@mui/material/styles';

//icons
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import TabletIcon from '@mui/icons-material/Tablet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import MemoryIcon from '@mui/icons-material/Memory';
import { inputSizeDevice } from 'constants';
import MainCard from 'components/MainCard';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SimCardIcon from '@mui/icons-material/SimCard';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import {
  CARRIER,
  DEVICE_MODEL_LIST,
  DEVICE_TYPE,
  MANUFACTURER,
} from 'containers/layouts/Vehicle/helper';

const InputProps = (Icon) => {
  return {
    startAdornment: (
      <InputAdornment position='start' color='default'>
        <Icon sx={{ color: 'default' }} />
      </InputAdornment>
    ),
  };
};
const DeviceBasic = ({ formikProps, ...props }) => {
  const errorMessage = () => {
    const fmTouches = formikProps.touched;
    const fmValues = formikProps.values;
    if (
      (fmTouches.driverapp || fmTouches.gps_tracking || fmTouches.attendance) &&
      (fmValues.driverapp || fmValues.gps_tracking || fmValues.attendance)
    ) {
      return false;
    } else if (!fmTouches.driverapp || !fmTouches.gps_tracking || !fmTouches.attendance) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Stack spacing={3} direction='row'>
        <Stack width={'33%'} mt={3}>
          <Field
            size={inputSizeDevice}
            component={SelectField}
            label='Device Type *'
            name='device_type'
            Icon={PhonelinkIcon}
            data={DEVICE_TYPE}
            async
            id='device_type'
            helperText='Select device type'
            selectFieldProps={{
              variant: inputVariantDevice,
            }}
            fullWidth
          />
        </Stack>
        <Stack width={'33%'}>
          <Field
            fullWidth
            component={TextField}
            label='Device Unique ID *'
            name='device_id'
            variant={inputVariantDevice}
            InputProps={InputProps(SmartScreenIcon)}
            async
            id='device_id'
            helperText='Enter device unique ID'
            size={inputSizeDevice}
          />
        </Stack>
        <Stack width={'33%'}>
          <Field
            fullWidth
            component={SelectField}
            label='Device Manufacturer *'
            name='manufacturer'
            variant={inputVariantDevice}
            size={inputSizeDevice}
            Icon={MemoryIcon}
            data={MANUFACTURER}
            async
            id='manufacturer'
            helperText='Select device manufacturer'
          />
        </Stack>
      </Stack>

      <Stack spacing={3} mt={4} direction='row'>
        <Stack width={'33%'}>
          <Field
            size={inputSizeDevice}
            component={SelectField}
            label='Device Model *'
            name='device_model'
            Icon={TabletIcon}
            data={DEVICE_MODEL_LIST}
            async
            id='device_id'
            helperText='Select device model'
            selectFieldProps={{
              variant: inputVariantDevice,
            }}
            fullWidth
          />
        </Stack>
        <Stack width={'33%'}>
          <Field
            size={inputSizeDevice}
            component={SelectField}
            label='Carrier'
            name='carrier'
            Icon={SignalCellularAltIcon}
            data={CARRIER}
            async
            id='carrier'
            helperText='Select carrier'
            selectFieldProps={{
              variant: inputVariantDevice,
            }}
            fullWidth
          />
        </Stack>
        <Stack width={'33%'}>
          <Field
            fullWidth
            component={TextField}
            label='Sim Number'
            name='sim_number'
            variant={inputVariantDevice}
            InputProps={InputProps(SimCardIcon)}
            async
            id='sim_number'
            helperText='Enter sim number'
            size={inputSizeDevice}
          />
        </Stack>
      </Stack>

      <Stack mt={4} spacing={3} direction='row'>
        <Stack
          direction='row'
          justifyContent='flex-start'
          width={'50%'}
          alignItems='center'
          spacing={2}
        >
          <Stack width={'30%'}>
            <CountrySelect
              {...formikProps}
              name='device_phone_code'
              label='Phone code'
              id='device_phone_code'
              type='phone'
              countryId='device_phone_code'
              helperTextContent='Country code'
              textFieldProps={{
                variant: 'outlined',
                size: 'small',
              }}
            />
          </Stack>

          <Stack width={'70%'}>
            <Field
              fullWidth
              component={TextField}
              label='Phone Number'
              name='device_phone_number'
              variant={inputVariantDevice}
              InputProps={InputProps(PhoneAndroidIcon)}
              id='device_phone_number'
              helperText='Enter phone number'
              size='small'
            />
          </Stack>
        </Stack>

        <Stack width={'50%'}>
          <Field
            fullWidth
            component={DeviceCompatibility}
            label='Device Model *'
            name='device_model'
            variant={inputVariantDevice}
            InputProps={InputProps(PhoneIphoneIcon)}
            async
            id='device_model'
            helperText='Enter Device Model'
            errorMessage={errorMessage()}
          />
          <FormHelperText sx={{ ml: 3 }} error={errorMessage()}>
            {errorMessage() ? 'Device compatibility is required' : 'Select  device compatibility '}
          </FormHelperText>
        </Stack>
      </Stack>

      <Stack mt={5}>
        <Field
          component={CheckBoxFormik}
          name='status'
          label='Enable Device.'
          headingSx={{
            direction: 'column',
          }}
          direction='column'
          alignItems='center'
        />
      </Stack>
    </div>
  );
};

const DeviceCompatibility = ({ formikProps, errorMessage, ...props }) => {
  const { driverapp, gps_tracking, attendance } = props.form.values;
  return (
    <MainCard content={false}>
      <Stack
        px={2}
        borderRadius={2}
        height={'3.5rem'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography component={'p'}>Device Compatibility *</Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
          <Field
            fullWidth
            component={ChipFormik}
            label='Driver App'
            name='driverapp'
            variant={inputVariantDevice}
            async
            id='driverapp'
            icon={<PhoneIphoneIcon />}
            sx={{ borderRadius: 10 }}
            color={driverapp ? 'primary' : 'default'}
          />

          {/* <Field
            fullWidth
            component={ChipFormik}
            label='GPS'
            name='gps_tracking'
            variant={inputVariantDevice}
            async
            id='gps_tracking'
            icon={<PhoneIphoneIcon />}
            sx={{ borderRadius: 10 }}
            color={gps_tracking ? 'primary' : 'default'}
          /> */}

          <Field
            fullWidth
            component={ChipFormik}
            label='Student Attendance'
            name='attendance'
            variant={inputVariantDevice}
            async
            id='attendance'
            icon={<AccountCircleIcon />}
            sx={{ borderRadius: 10 }}
            color={attendance ? 'primary' : 'default'}
          />
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default DeviceBasic;
