import React, { useState } from 'react';

// third party
import { Auth } from 'aws-amplify';

// Routing
import { useNavigate } from 'react-router-dom';

// MUI
import { useTheme } from '@mui/material/styles';
import { styled, alpha } from '@mui/material/styles';
import { Grid, Typography, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

// project
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';
import CustomImageInput from './components/CustomImageInput';
import { getgendericon } from './components/getgenderIcon';
import { getGreeting } from './components/getGreeting';
import { getreadableroletype } from './components/getreadableroletype';

// assets
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
// Query
import { useQueryClient } from '@tanstack/react-query';

export default function UserProfile({ accountInfo }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const userAttributes = accountInfo;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleManageUser = () => {
    navigate(`/users`);
    setAnchorElUser(null);
  };

  const handleAccountSettings = () => {
    navigate(`/account-settings`);
    setAnchorElUser(null);
  };

  const handleResetPassword = () => {
    navigate(`/change-password`);
    setAnchorElUser(null);
  };

  async function signOut() {
    queryClient.clear();
    try {
      await Auth.signOut();
      sessionStorage.clear();
      localStorage.clear();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: theme.shape.borderRadius * 4,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));

  return (
    <React.Fragment>
      {userAttributes ? (
        <>
          <IconButton
            sx={{ p: 0 }}
            size='small'
            onClick={handleOpenUserMenu}
            id='headerUserSettings'
          >
            <Avatar sx={{ width: 30, height: 30, fontSize: 16 }}>
              {userAttributes?.first_name[0] + userAttributes?.last_name[0]}
            </Avatar>
          </IconButton>
          <StyledMenu
            id='menu-appbar'
            anchorEl={anchorElUser}
            keepMounted
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Typography
              color={theme.palette.common.white}
              sx={{ backgroundColor: theme.palette.primary.main, pb: 12, pt: 2, pl: 4 }}
            >
              Hi, {getGreeting()}
            </Typography>
            <LocationOnIcon
              color='#AE8400'
              sx={{ position: 'absolute', fontSize: '101px', top: -19, right: -30, opacity: '50%' }}
            />

            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ width: 260 }}
            >
              <div style={{ position: 'absolute', top: '40px' }}>
                <CustomImageInput />
              </div>
              <Grid container justifyContent='center' alignItems='center' sx={{ mt: 11 }}>
                <Typography variant='h4' sx={{ mr: 1 }}>
                  {userAttributes?.first_name + ' ' + userAttributes?.last_name}
                </Typography>
                {getgendericon(userAttributes?.gender, theme)}
              </Grid>
              <Typography sx={{ fontSize: '10px' }} color={theme.palette.text.secondary}>
                {getreadableroletype(userAttributes?.user_permissions.role_type)}
              </Typography>
              <Chip
                size='small'
                label={userAttributes?.visible_user_status == 'Active' ? 'Active' : 'Not Active'}
                variant='outlined'
                color={userAttributes?.visible_user_status == 'Active' ? 'success' : 'error'}
              />
            </Grid>
            <MenuItem>
              <EmailIcon color='primary' />
              <Typography textAlign='center'>{userAttributes?.email}</Typography>
            </MenuItem>
            <MenuItem>
              <PhoneIphoneIcon color='primary' />
              <Typography textAlign='center'>
                {formatPhoneNumber(userAttributes?.phone_number)}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleManageUser} id='header-manage-users'>
              <GroupIcon color='primary' />
              <Typography textAlign='center'>Manage Users</Typography>
            </MenuItem>
            <MenuItem id='account_settings' onClick={handleAccountSettings}>
              <ManageAccountsIcon color='primary' />
              <Typography textAlign='center'>Account Settings</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => signOut()}
              sx={{ ':hover': { backgroundColor: 'error.main' } }}
            >
              <LogoutIcon color='primary' />
              <Typography textAlign='center'>Sign Out</Typography>
            </MenuItem>
          </StyledMenu>
        </>
      ) : (
        <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
          <Avatar>{'DC'}</Avatar>
        </IconButton>
      )}
    </React.Fragment>
  );
}
