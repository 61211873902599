import { Box, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Grid, InputAdornment, Stack } from '@mui/material';
import { inputVariant } from 'constants';
import Face6Icon from '@mui/icons-material/Face6';
import ImageInput from 'components/Formik/ImageInput';
import SelectIcon from './SelectIcon';
import ColorPickerForm from './ColorPickerForm';
import { SaveButton } from 'components/Buttons';
import CustomSwitch from './CustomSwitch';
import CustomSelect from './CustomSelect';
import CustomThemeChip from './CustomThemeChip';
import MultiLineTextField from './MultiLineTextField';
//ICONS
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SchoolIcon from '@mui/icons-material/School';
import ExpandIcon from '@mui/icons-material/Expand';
import EmailIcon from '@mui/icons-material/Email';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChatIcon from '@mui/icons-material/Chat';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import LanguageIcon from '@mui/icons-material/Language';
import LeftRightChip from './LeftRightChip';

const botName = {
  startAdornment: (
    <InputAdornment position='start'>
      <SmartToyIcon />
    </InputAdornment>
  ),
};

const schoolName = {
  startAdornment: (
    <InputAdornment position='start'>
      <SchoolIcon />
    </InputAdornment>
  ),
};

const chatSubheading = {
  startAdornment: (
    <InputAdornment position='start'>
      <SubtitlesIcon />
    </InputAdornment>
  ),
};

const responseLen = {
  startAdornment: (
    <InputAdornment position='start'>
      <ExpandIcon />
    </InputAdornment>
  ),
};

const chatBotPlace = {
  startAdornment: (
    <InputAdornment position='start'>
      <ChatIcon />
    </InputAdornment>
  ),
};

const customerMail = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const welcomeMessage = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmojiPeopleIcon />
    </InputAdornment>
  ),
};

const languageSelect = {
  startAdornment: (
    <InputAdornment position='start'>
      <LanguageIcon />
    </InputAdornment>
  ),
};

export default function CustomForm({
  formInitialValues,
  validationSchema,
  handleSubmit,
  setBotData,
  chatloading,
}) {
  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='chatForm'>
          {setBotData(formikProps.values)}
          <Stack sx={{ mt: 3 }} spacing={3}>
            <Grid spacing={3} container xs={12} alignItems='center'>
              <Grid item xs={12}>
                <Typography variant='h4'>General</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Bot Name *'
                  name='name'
                  variant='standard'
                  id='name'
                  helperText='Bot name is required'
                  InputProps={botName}
                  disabled={false}
                />
              </Grid>
              <Grid sx={{ pl: 2.8 }} container xs={6} alignItems='center'>
                <Field
                  component={ImageInput}
                  name='company_logo'
                  label='Chat Subheading'
                  id='company_logo'
                  imageName={'Upload School/District Logo'}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={TextField}
                  label='School/District Name *'
                  name='company_name'
                  variant='standard'
                  id='company_name'
                  helperText='School/District name is required'
                  InputProps={schoolName}
                  disabled={false}
                />
              </Grid>
              <Grid sx={{ pl: 2.8 }} container xs={6} alignItems='center'>
                <Field
                  component={ImageInput}
                  name='chatbot_pic'
                  label='Chat Subheading'
                  id='chatbot_pic'
                  imageName={'Upload Assistant Picture'}
                />
              </Grid>
              <Grid item xs={6} alignItems='center'>
                <Field
                  fullWidth
                  component={ColorPickerForm}
                  label='Assistant color'
                  name='color'
                  variant='standard'
                  id='color'
                  helperText={'AI assistant color is required'}
                />
              </Grid>
              <Grid item xs={6} justifyContent='center' alignItems='center'>
                <Field
                  name='language'
                  component={CustomSelect}
                  label='Select Language'
                  InputProps={languageSelect}
                  options={[{ value: 'english', label: 'English' }]}
                  helperText={'Language is required'}
                />
              </Grid>
              <Grid item xs={6} justifyContent='center' alignItems='center'>
                <Field
                  fullWidth
                  component={TextField}
                  label='Chat Subheading'
                  name='sub_heading'
                  variant='standard'
                  id='sub_heading'
                  helperText='Chat subheading is required'
                  InputProps={chatSubheading}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6} justifyContent='center' alignItems='center'>
                <Field
                  fullWidth
                  component={CustomSelect}
                  label='Response Length'
                  name='res_length'
                  variant='standard'
                  id='res_length'
                  InputProps={responseLen}
                  helperText={'Response Length is required'}
                  options={[
                    { value: '50', label: 'Short (Up to 50 words)' },
                    { value: '100', label: 'Medium (Up to 100 words)' },
                    { value: '250', label: 'Long (Up to 250 words)' },
                  ]}
                />
              </Grid>
              <Grid item xs={6} justifyContent='center' alignItems='center'>
                <Field
                  fullWidth
                  component={TextField}
                  label='Chat Input Placeholder'
                  name='input_placeholder'
                  variant='standard'
                  id='input_placeholder'
                  helperText='Chat Input is required'
                  InputProps={chatBotPlace}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6} justifyContent='center' alignItems='center'>
                <Field
                  fullWidth
                  component={TextField}
                  label='Customer Support Email'
                  name='support_email'
                  variant='standard'
                  id='support_email'
                  helperText='Customer Support Email is required'
                  InputProps={customerMail}
                  disabled={false}
                />
              </Grid>
            </Grid>
            <Field
              fullWidth
              component={MultiLineTextField}
              label='Welcome Message'
              name='welcome_msg'
              variant='standard'
              id='welcome_msg'
              sx={{ mt: 3, pr: 3 }}
              multiline
            />
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Typography sx={{ mb: 2, mt: 2 }} variant='h4'>
                  Widget UI
                </Typography>
              </Grid>
              <Grid container xs={6} alignItems={'center'}>
                <Field
                  fullWidth
                  component={CustomSwitch}
                  label='Open AI Assistant By Default'
                  name='default_open'
                  variant={inputVariant}
                  id='default_open'
                />
                {/* <Typography>Open By Default</Typography> */}
              </Grid>
              <Grid container xs={6} alignItems={'center'}>
                <Field
                  fullWidth
                  component={LeftRightChip}
                  label='Right'
                  name='widget_pos'
                  variant={inputVariant}
                  id='widget_pos'
                />
              </Grid>

              <Grid item xs={6} alignItems={'center'} sx={{ mt: 2 }}>
                <Field
                  fullWidth
                  component={CustomThemeChip}
                  name='theme'
                  variant={inputVariant}
                  id='theme'
                />
              </Grid>
              <Grid item alignItems={'center'} xs={6}>
                <Field
                  fullWidth
                  component={SelectIcon}
                  name='icon'
                  variant={inputVariant}
                  id='icon'
                />
              </Grid>
            </Grid>
            <Grid spacing={3} container xs={12} justifyContent={'flex-end'}>
              <SaveButton
                disabled={!formikProps.dirty}
                loading={chatloading}
                sx={{ mt: 3 }}
                id='save'
                size={'medium'}
                type='submit'
              >
                Publish
              </SaveButton>
            </Grid>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
