import React from 'react';

import * as Yup from 'yup';

// Material UI
import { InputAdornment, Stack } from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Formik
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import DateSelectorFormik from 'components/Formik/DateSelectorFormik';


// Custom Component
import { DefaultButton } from 'components/Buttons';
import { validationSchemaForHolidays } from 'pages/Sessions/FormModel/validationSchema';
import { initialValuesOfHolidays } from 'pages/Sessions/FormModel/formInitialValues';

export default function HolidayForm({
  selectedHoliday,
  _handleSubmitHoliday,
  editHoliday,
  variant,
  sessionData,
  sessionStartDate,
  sessionEndDate,
}) {
  const _handleResetForm = (resetForm) => {
    resetForm();
  };

  return (
    <>
      <Formik
        initialValues={initialValuesOfHolidays(selectedHoliday)}
        validationSchema={validationSchemaForHolidays()}
        onSubmit={_handleSubmitHoliday}
        enableReinitialize
        resetForm={_handleResetForm}
      >
        {(formikProps) => (
          <Form id='holiday'>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
              <Field
                fullWidth
                component={TextField}
                disabled={sessionData?.status === 'active'}
                name={`hname`}
                placeholder='Holiday Name'
                helperText='Enter your holiday details'
                variant={variant}
                label='Holiday name'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SubjectIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                fullWidth={true}
                name={`hdate`}
                helperText={'Select a date'}
                component={DateSelectorFormik}
                variant={variant}
                label='Date'
                minDate={sessionStartDate ? sessionStartDate : new Date()}
                maxDate={sessionEndDate ? sessionEndDate : new Date()}
              />

              <Stack sx={{ pb: 5 }}>
                <DefaultButton
                  startIcon={editHoliday ? <EditIcon /> : <AddIcon />}
                  form={'holiday'}
                  type='submit'
                  disabled={sessionData?.status === 'active'}
                >
                  {editHoliday ? 'UPDATE' : 'ADD'}
                </DefaultButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
