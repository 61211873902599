import React from 'react';

import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';

// material-ui
import { List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import NavSection from './NavSection';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  // const menu = useSelector((state) => state.menu);
  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
      case 'section':
        return <NavSection key={menuItem.id} menu={menuItem} level={0} />;
      case 'item':
        return !menuItem.isDrawerHidden && <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant='h6' color='error' align='center'>
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return <List id='drawer'>{navCollapse}</List>;
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
