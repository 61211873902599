import React, { Component } from 'react';
// 
import './App.css';
import CustomSignIn from './containers/layouts/Auth/CustomSignIn';
import { Hub } from 'aws-amplify';
import ForgotPassword from './containers/layouts/Auth/ForgotPassword';
import SignUp from './containers/layouts/Auth/SignUp';
import CompleteSignUp from './containers/layouts/Auth/CompleteSignUp';

class AppBeforeAuth extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageType: 'SIGN_IN',
    };
    Hub.listen('authPage', (data) => {
      console.log('Auth page Event recevied with event: ', data.payload.event);
      this.setState({
        pageType: data.payload.event,
        user: data.payload.data.user,
      });
    });
  }

  renderSignUpOrForgotPassword() {
    return (
      <div>
        {this.state.pageType === 'SIGN_UP' ? (
          <SignUp {...this.props} />
        ) : this.state.pageType === 'COMPLETE_SIGN_UP' ? (
          <CompleteSignUp user={this.state.user} {...this.props} />
        ) : (
          <ForgotPassword {...this.props} />
        )}
      </div>
    );
  }
  render() {
    console.log('Paging Type: ' + this.state.pageType);
    return (
      <div>
        {this.state.pageType === 'SIGN_IN' ? (
          <CustomSignIn {...this.props} />
        ) : (
          this.renderSignUpOrForgotPassword()
        )}
      </div>
    );
  }
}

export default AppBeforeAuth;
