import { queryKey } from 'react-query/queryKey';
import { useImageQuery } from './imageQuery';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';

export default function ImageComponent({ dataInspection, imageID, handleImage, setHasError }) {
  // Image Query to feth the image
  const { getImageUrl, isLoadingImage } = useImageQuery({
    customQueryKey: [queryKey.TRIP_INSPECTIONS.TRIP_INSPECTION_REPORT_IMAGE, imageID],
    image_url: imageID,
    inspectionReportID: dataInspection?.id,
  });

  return (
    <>
      {isLoadingImage ? (
        <Stack width={'60px'}>
          <Skeleton variant='rounded' width={'100%'} height={'100%'} />
        </Stack>
      ) : (
        <>
          <img
            role='presentation'
            width={60}
            alt='Not found'
            id='not-found-page'
            src={getImageUrl?.url}
            onError={() => setHasError(true)}
            onClick={() => handleImage(getImageUrl?.url)}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
    </>
  );
}
