import { getMonth, getYear, isValid } from 'date-fns';
import * as yup from 'yup';
import Validations from 'containers/layouts/Utils/Validations';
import { DATE_OCCURRENCE } from './formInitialValuesDriverShifts';
import { isValidYear } from 'pages/Sessions/Utils';

const nonrepetitiveValidation = {
  adhocStartTime: yup
    .date()
    .typeError('Start time must be valid')
    .required('Start time is required')
    .nullable(),

  adhocEndTime: yup
    .date()
    .typeError('End time must be valid')
    .required('End time is required')
    .test('is-later-than-start', 'End time must be after start time', function(adhocEndTime) {
      const startTime = this.resolve(yup.ref('adhocStartTime'));
      if (!startTime || !adhocEndTime) return true;
      return adhocEndTime > startTime;
    }),
};

// helping variable for weeklyOccurrenceValidation
const weeklyOccurrenceValidation = {
  shiftTitle: Validations.shiftTitle,

  // MONDAY
  mondayCheckBox: yup.boolean(),
  mondayStartTime: yup.date().when('mondayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  mondayEndTime: yup.date().when('mondayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(mondayEndTime) {
        const startTime = this.resolve(yup.ref('mondayStartTime'));
        if (!startTime || !mondayEndTime) return true;
        return mondayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // TUESDAY
  tuesdayCheckBox: yup.boolean(),
  tuesdayStartTime: yup.date().when('tuesdayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  tuesdayEndTime: yup.date().when('tuesdayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(tuesdayEndTime) {
        const startTime = this.resolve(yup.ref('tuesdayStartTime'));
        if (!startTime || !tuesdayEndTime) return true;
        return tuesdayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // WEDNESDAY
  wednesdayCheckBox: yup.boolean(),
  wednesdayStartTime: yup.date().when('wednesdayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  wednesdayEndTime: yup.date().when('wednesdayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(wednesdayEndTime) {
        const startTime = this.resolve(yup.ref('wednesdayStartTime'));
        if (!startTime || !wednesdayEndTime) return true;
        return wednesdayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // THURDSAY
  thursdayCheckBox: yup.boolean(),
  thursdayStartTime: yup.date().when('thursdayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  thursdayEndTime: yup.date().when('thursdayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(thursdayEndTime) {
        const startTime = this.resolve(yup.ref('thursdayStartTime'));
        if (!startTime || !thursdayEndTime) return true;
        return thursdayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // FRIDAY
  fridayCheckBox: yup.boolean(),
  fridayStartTime: yup.date().when('fridayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  fridayEndTime: yup.date().when('fridayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(fridayEndTime) {
        const startTime = this.resolve(yup.ref('fridayStartTime'));
        if (!startTime || !fridayEndTime) return true;
        return fridayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // SATURDAY
  saturdayCheckBox: yup.boolean(),
  saturdayStartTime: yup.date().when('saturdayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  saturdayEndTime: yup.date().when('saturdayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(saturdayEndTime) {
        const startTime = this.resolve(yup.ref('saturdayStartTime'));
        if (!startTime || !saturdayEndTime) return true;
        return saturdayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),

  // SUNDAY
  sundayCheckBox: yup.boolean(),
  sundayStartTime: yup.date().when('sundayCheckBox', {
    is: true,
    then: Validations.startTime,
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  sundayEndTime: yup.date().when('sundayCheckBox', {
    is: true,
    then: yup
      .date()
      .typeError('End time must be valid')
      .required('End time is required')
      .test('is-later-than-start', 'End time must be after start time', function(sundayEndTime) {
        const startTime = this.resolve(yup.ref('sundayStartTime'));
        if (!startTime || !sundayEndTime) return true;
        return sundayEndTime > startTime;
      }),
    otherwise: yup
      .date()
      .typeError('Start time must be valid')
      .nullable(),
  }),
  // allowEarlyClockInTimeSunday: yup.number().when('sundayCheckBox', {
  //   is: true,
  //   then: Validations.allowEarlyClockInTime,
  //   otherwise: yup.number(),
  // }),

  // monthly Validation
};
const minNameChar = /[a-zA-Z]{3}/;
const commonValidation = ({ shiftStartDate, isUpdate }) => {
  const isNeedToValidate = isUpdate ? shiftStartDate : new Date();
  const validMessageStartDate = isUpdate
    ? 'Start date should not be previous date'
    : 'Start date should not be before the current date.';
  return {
    earlyClockInTime: yup
      .number('Early clock in time must be a number')
      .required('Early clock in time is required')
      .min(0, 'Early clock in time must be greater than or equal to 0')
      .max(15, 'Early clock in time must be less than or equal to 15'),

    dateOccurrence: yup.string().required('Date Occurrence is required'),
    shiftTitle: yup
      .string()
      .trim()
      .required('Shift title is required')
      .min(3, 'Shift title must be at least 3 characters')
      .max(50, 'Shift contains maximum 50 characters')
      .matches(minNameChar, 'Shift title must be at least 3 characters without white space'),

    startDate: yup
      .date()
      .typeError('Start date must be a valid date')
      .required('Start date is required')
      .test('is-later-than-start', validMessageStartDate, function(startDate) {
        return (
          isValidDate(startDate) &&
          isValidYear(startDate) &&
          isSecondDateGreaterThanFirst(isNeedToValidate, startDate)
        );
      }),
    endDate: yup
      .date()
      .typeError('End date must be a valid date')
      .required('End date is required')
      .test('is-later-than-start', 'End date must be after start date', function(endDate) {
        const schoolFormEndDate = this.resolve(yup.ref('startDate'));
        return (
          isValidDate(endDate) &&
          isSecondDateGreaterThanFirst(schoolFormEndDate, endDate) &&
          isValidYear(endDate)
        );
      }),
  };
};

/**
 * Generates a validation schema for driver shifts based on the occurrence type.
 * This code snippet exports a function called validationSchemaDriverShifts that generates a validation schema for driver shifts based on the occurrence type.
 * The function takes in a parameter occurrenceType which specifies whether the occurrence is weekly or monthly.
 * If the occurrenceType is 'weekly', the function returns a validation schema object that combines commonValidation and weeklyOccurrenceValidation.
 * If the occurrenceType is 'monthly', the function returns a validation schema object that includes validation rules for monthlyDate, clockIn, and clockOut. It also combines commonValidation with the other validation rules.
 * If the occurrenceType is neither 'weekly' nor 'monthly', there is no return value specified in the code snippet.
 * The validation schema is defined using the yup library, which provides a convenient way to define and validate schemas.
 * @param {string} occurrenceType - The type of occurrence (weekly or monthly).
 * @return {object} - The generated validation schema.
 */
export const validationSchemaDriverShifts = ({ isUpdate, occurrenceType, shiftStartDate }) => {
  if (occurrenceType === DATE_OCCURRENCE.WEEKLY) {
    return yup.object().shape({
      ...commonValidation({ shiftStartDate, isUpdate }),
      ...weeklyOccurrenceValidation,
    });
  }
  if (occurrenceType === DATE_OCCURRENCE.ADHOC) {
    return yup.object().shape({
      ...commonValidation({ shiftStartDate, isUpdate }),
      ...nonrepetitiveValidation,
    });
  }
  if (occurrenceType === DATE_OCCURRENCE.MONTHLY) {
    return yup.object().shape({
      monthlyDate: yup.array().of(
        yup.object().shape({
          startDateMonthly: yup
            .number('It should be a valid number')
            .required('Select Date is required')
            .integer('Select Date must be an integer')
            .min(1, 'Select Date must be greater than or equal to 1')
            .max(31, 'Select Date must be less than or equal to 31')
            .test('unique-date', 'Dates must be unique', function(value) {
              const data = this.options.context.monthlyDate;
              // const isError = data.filter((dateObj) => dateObj.startDateMonthly !== undefined);

              // Exclude the current object being validated from the check
              const filteredData = data.filter(
                (ele) => ele.startDateMonthly !== undefined && ele !== this.parent,
              );

              // Check if any other object has the same startDateMonthly value
              const isErrorValue = filteredData.some((ele) => ele.startDateMonthly === value);

              // If there is a duplicate, return false
              return !isErrorValue;
            }),
          clockIn: yup
            .date()
            .transform((originalValue, originalObject) => {
              const isValidDate = originalValue instanceof Date && !isNaN(originalValue.getTime());

              // If it's not a valid date, you can return a default value or null
              return isValidDate ? originalValue : null;
            })
            .typeError('Start time must be valid')
            .required('Clock-in time is required'),

          clockOut: yup
            .date()
            .transform((originalValue, originalObject) => {
              const isValidDate = originalValue instanceof Date && !isNaN(originalValue.getTime());

              // If it's not a valid date, you can return a default value or null
              return isValidDate ? originalValue : null;
            })
            .typeError('End time must be valid')
            .required('Clock-out time is required')
            .min(yup.ref('clockIn'), 'Start time must be greater than end time'),
        }),
      ),
      ...commonValidation({ shiftStartDate, isUpdate }),
    });
  }
  // MONDAY
};

const isValidDate = (dateToCheck) => {
  const convertDateFormat = typeof dateToCheck === 'string' ? new Date(dateToCheck) : dateToCheck;

  return (
    convertDateFormat !== null &&
    isValid(convertDateFormat) &&
    getMonth(convertDateFormat) !== undefined &&
    getYear(convertDateFormat) !== undefined
  );
};

function isSecondDateGreaterThanFirst(date1, date2) {
  // Extract only the date part (year, month, day) from each date
  const d1 = new Date(date1?.getFullYear(), date1?.getMonth(), date1?.getDate());
  const d2 = new Date(date2?.getFullYear(), date2?.getMonth(), date2?.getDate());

  // Compare the two date objects
  return d1.getTime() <= d2.getTime();
}
