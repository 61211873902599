import * as React from 'react';

//material ui
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

//project imports
import MainCard from 'components/MainCard';
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';
import { CardHeading } from 'components/Headers/CardHeading';

export default function DriverCard(props) {
  return (
    <Grid mt={4}>
      <MainCard>
        <Grid container mb={-4} xs={12}>
          <Grid item xs={3}>
            <Avatar
              src={props.driver.profile_image?.url}
              sx={{
                width: 100,
                height: 100,
                border: '1px dotted grey',
                mt: -1,
              }}
              // variant= 'square'
            />
          </Grid>
          <Grid item xs={9} sx={{ pl: 4 }}>
            <Grid item xs={12}>
              <CardHeading title={props.driver.first_name + ' ' + props.driver.last_name} />
            </Grid>
            <Grid container xs={12} sx={{ pl: 1 }}>
              <Grid item xs={2}>
                <EmailIcon color='secondary' />
              </Grid>
              <Grid item xs={10}>
                <Typography variant='body1' color='secondary.main' sx={{ pl: 1 }}>
                  {props.driver.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={8}>
                <Grid container sx={{ pl: 1 }}>
                  <Grid item xs={3}>
                    <PhoneIcon color='secondary' />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body1' color='secondary.main'>
                      {formatPhoneNumber(props.driver.phone_number)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end', borderWidth: '2px' }}
                >
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    onClick={() => props.handleEdit(props.driver)}
                    disabled={props.loadingData}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    color='error'
                    onClick={() => {
                      props.setOpen();
                      props.setDeleteId(props.driver.driver_username);
                    }}
                    disabled={props.loadingData}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
