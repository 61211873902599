import React, { useState } from 'react';
import PropTypes from 'prop-types';

// third-party
import { ReactMultiEmail } from 'react-multi-email';

// material-ui imports
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

// assets
import 'react-multi-email/dist/style.css';

// ============================== MultiEmailTextField - Input ============================== //

export const MultiEmailTextField = (props) => {
  const { helperText, label, id, disabled = false } = props;
  const { name, value } = props.field;
  const { errors, touched, setFieldValue, setFieldTouched, isSubmitting } = props.form;

  const [focus, setFocus] = useState(false);

  const theme = useTheme();

  const isError = Boolean(errors[name] && touched[name]);
  const helpText = isError ? errors[name] : helperText;

  const handleChange = (emails) => {
    setFieldValue(name, emails);
  };

  const _handleFocus = () => {
    setFocus(true);
  };

  const _handleBlur = () => {
    setFieldTouched(name, true);
    setFocus(false);
  };
  const _handleBorderColor = () => {
    if (disabled) {
      return theme.palette.secondary.light;
    }
    if (isError) {
      return theme.palette.error.main;
    } else {
      if (Boolean(touched[name]) || focus) {
        return theme.palette.primary.main;
      } else {
        return theme.palette.secondary.main;
      }
    }
  };

  return (
    <>
      <Typography variant='h6' component='div' gutterBottom>
        {label}
      </Typography>
      <FormControl variant='standard' fullWidth disabled>
        <ReactMultiEmail
          placeholder='example@abc.com'
          id={id}
          emails={value}
          onFocus={_handleFocus}
          onBlur={_handleBlur}
          onChange={(emails) => {
            handleChange(emails);
          }}
          style={{
            border: `1px solid ${_handleBorderColor()}`,
            background: 'transparent',
            color: theme.palette.text.primary,
            pointerEvents: disabled && 'none',
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <>
                <Chip
                  key={`phone-${index}`}
                  label={email}
                  variant='outlined'
                  sx={{ mx: 1 }}
                  onDelete={() => removeEmail(index)}
                  id={`email_${index}`}
                />
              </>
            );
          }}
        />
      </FormControl>
      <Typography
        sx={{ ml: 3 }}
        id='email-to'
        variant='caption'
        color={isError ? 'error' : 'text.secondary'}
        error={isError}
      >
        {helpText}
      </Typography>
    </>
  );
};

MultiEmailTextField.propTypes = {};

export default MultiEmailTextField;
