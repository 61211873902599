import { useEffect, useState } from 'react';
//MUI
import { Grid, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
//Project Imports
import TripInspectionCategory from './Categories/TripInspectionCategory';
import TripQues from './TripQues/TripQues';
import TripReport from './TripReport/TripReport';
//API
import {
  getDriversByTransportationAdmin,
  getInspectionReport,
  getVehiclesList,
} from 'clients/SpotBus';
import NoRecordDisplay from 'components/NoRecordDisplay';
import { convertObjToLocalTime } from 'utils/spotbus-date-time';

export default function TripInspectionDetails(props) {
  const inspectionId = useParams();
  const [loading, setLoading] = useState(false);
  const [dataInspection, setDataInspection] = useState([]);
  const [dataVehicle, setVehicle] = useState([]);
  const [dataDrivers, setDrivers] = useState([]);
  const [selectionQuestion, setSelectedQuestion] = useState([]);

  const getTripInspection = async (inspectionId) => {
    setLoading(true);
    try {
      const response = await getInspectionReport(inspectionId);

      const tripData = {
        ...response,
        created_timestamp: convertObjToLocalTime(response.created_timestamp),
        date: convertObjToLocalTime(response.date),
        start_time: convertObjToLocalTime(response.start_time),
        end_time: convertObjToLocalTime(response.end_time),
        last_updated_timestamp: convertObjToLocalTime(response.last_updated_timestamp),
      };
      setDataInspection(tripData);
      setLoading(false);
      setSelectedQuestion(tripData.categories[0].subcategories[0]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getVehicle = async () => {
    try {
      const response = await getVehiclesList();
      setVehicle(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getDrivers = async () => {
    try {
      const response = await getDriversByTransportationAdmin();
      setDrivers(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (inspectionId.inspection_Id) {
      getTripInspection(inspectionId.inspection_Id);
    }
    getVehicle();
    getDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      {loading ? (
        <>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' width={'100%'} height={150} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant='rectangular' width={'100%'} height={'75vh'} />
          </Grid>
          <Grid item xs={9}>
            <Skeleton variant='rectangular' width={'100%'} height={'75vh'} />
          </Grid>
        </>
      ) : (
        <>
          {dataInspection.length === 0 ? (
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <NoRecordDisplay />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TripReport
                  dataInspection={dataInspection}
                  dataVehicle={dataVehicle}
                  dataDrivers={dataDrivers}
                />
              </Grid>
              <Grid item xs={3}>
                <TripInspectionCategory
                  dataInspection={dataInspection}
                  setSelectedQuestion={setSelectedQuestion}
                  selectionQuestion={selectionQuestion}
                />
              </Grid>
              <Grid item xs={9}>
                <TripQues selectionQuestion={selectionQuestion} dataInspection={dataInspection} />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
