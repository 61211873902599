import { Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import NoRecordDisplay from 'components/NoRecordDisplay';
import React from 'react';
import GetDevicesDetailsInfoHeader from './GetDevicesDetailsInfoHeader';
import GetDeviceDetailsItems from './GetDeviceDetailsItems';
import QuickViewLoader from 'components/Loader/QuickViewLoader';

const DeviceDetails = ({
  selectedDevicesData,
  handleDeleteClick,
  handleEditClick,
  onlyGetRequestAllowed,
  isDataLoading = false,
  filledRows,
  devicesSerial,
}) => {
  if (isDataLoading) {
    return (
      <MainCard content={false} sx={{ p: 3, height: '100% !important' }}>
        <QuickViewLoader />
      </MainCard>
    );
  }
  return (
    <React.Fragment>
      <MainCard content={false} sx={{ p: 3, height: '100% !important' }}>
        <Grid item xs={12}>
          <Typography color='primary' variant='h6' gutterBottom>
            Device Details
          </Typography>
        </Grid>
        {filledRows?.length === 0 ? (
          <Grid item xs={12} sx={{ mt: '20%', textAlign: 'center' }}>
            <NoRecordDisplay imageWidth={'Details'} />
          </Grid>
        ) : (
          <>
            {/* GetVehicleDetailsInfoHeader */}
            <GetDevicesDetailsInfoHeader
              selectedDevicesData={selectedDevicesData}
              handleDeleteClick={handleDeleteClick}
              handleEditClick={handleEditClick}
              onlyGetRequestAllowed={onlyGetRequestAllowed}
              isDataLoading={isDataLoading}
              filledRows={filledRows}
              devicesSerial={devicesSerial}
            />
            <GetDeviceDetailsItems
              selectedDevicesData={selectedDevicesData}
              isDataLoading={isDataLoading}
            />
          </>
        )}
      </MainCard>
    </React.Fragment>
  );
};

export default DeviceDetails;
