// material-ui
import { createTheme } from '@mui/material/styles';

// assets
import { red, green, orange, grey } from '@mui/material/colors';

const Palette = (mode) => {
  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      primary: {
        lighter: '#fff9e5',
        light: '#ffe54b',
        main: '#e5b300', //rgb(229, 179, 0)
        dark: '#ae8400',
        darker: '#b38c00',
        contrastText: '#fff',
      },
      secondary: {
        lighter: grey[100],
        light: grey[300],
        main: grey[500],
        dark: grey[600],
        darker: grey[800],
        contrastText: '#fff',
      },
      error: {
        lighter: red[100],
        light: red[300],
        main: red[400],
        dark: red[700],
        darker: red[900],
        contrastText: '#fff',
      },
      success: {
        lighter: green[100],
        light: green[300],
        main: green[400],
        dark: green[700],
        darker: green[900],
        contrastText: '#fff',
      },
      warning: {
        lighter: orange[100],
        light: orange[300],
        main: orange[400],
        dark: orange[700],
        darker: orange[900],
        contrastText: '#fff',
      },
      text: {
        primary: mode === 'dark' ? '#fff' : grey[800],
        secondary: mode === 'dark' ? '#fff' : grey[600],
        disabled: mode === 'dark' ? '#fff' : grey[400],
      },
      background: {
        default: mode === 'light' ? grey[50] : grey[800],
        paper: mode === 'light' ? '#fff' : grey[900],
      },
      hover: {
        default: mode === 'light' ? grey[100] : grey[700],
        paper: mode === 'light' ? '#fff' : grey[900],
      },
    },
  });
};

export default Palette;
