import { ROUTE_TYPE } from './TripUtils';

/**
 * Class that represents the date and time in UTC, with all the supporting methods.
 * Use this class wherever there is a need to use the date time in UTC or local timezone.
 */
export class SpotBusDateTime {
  /**
   * Creates a new instance of the class and initializes the `date` property.
   *
   * @param {Date} date - The date to be assigned to the `date` property. If not provided, the current date and time will be used.
   */
  constructor(date) {
    this.date = date instanceof Date ? date : new Date();
  }

  /**
   * Returns the AM or PM designation based on the current time.
   *
   * @return {string} The AM or PM designation.
   */
  amOrPm() {
    const hours = this.date.getHours();
    if (hours >= 12) {
      return ROUTE_TYPE.PM;
    }
    return ROUTE_TYPE.AM;
  }

  /**
   * Check if the date's timezone offset is 0 (UTC)
   *
   * @return {boolean} true if the date's timezone offset is 0, false otherwise
   */
  isUTC() {
    // Check if the date's timezone offset is 0 (UTC)
    return this.date.getTimezoneOffset() === 0;
  }

  /**
   * Convert the date to ISO string format
   *
   * @return {string} the date in ISO string format
   */
  toISOString() {
    // Convert the date to ISO string format
    return this.date.toISOString();
  }

  /**
   * Converts the date to a localized date string.
   *
   * @return {string} The localized date string.
   */
  toLocalizeDateString() {
    // Convert the date to a localized date string
    return this.date.toLocaleDateString();
  }

  /**
   * Convert the date to a LocalDate object.
   *
   * @return {string} the formatted date string
   */
  toLocalizeDate() {
    // Convert the date to a LocalDate object
    const year = this.date.getFullYear();
    const month = this.date.getMonth() + 1; // Month is zero-based
    const day = this.date.getDate();
    return `${year}-${month}-${day}`;
  }

  /**
   * Convert the date to the system local timezone and return time in "HH:mm:ss" format
   *
   * @return {string} the time in "HH:mm:ss" format
   */
  toLocalizeTime() {
    // Convert the date to the system local timezone and return time in "HH:mm:ss" format
    return this.date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  /**
   * Converts the local time to UTC time.
   *
   * @return {Date} a new Date object with the UTC timestamp
   */
  localTimeToUTC() {
    // Get the local timestamp
    const localTimestamp = this.date.getTime();

    // Get the local timezone offset in milliseconds
    const localTimezoneOffset = this.date.getTimezoneOffset() * 60000;

    // Convert the local timestamp to UTC timestamp
    const utcTimestamp = localTimestamp + localTimezoneOffset;

    // Create a new Date object with the UTC timestamp
    return new Date(utcTimestamp);

    // Return a new SpotBusDateTime object with the UTC Date
  }

  /**
   * Convert the date to the system local timezone
   *
   * @return {SpotBusDateTime} new SpotBusDateTime object with the date converted to the system local timezone
   */
  toLocalizeTimezone() {
    // Get the original UTC timestamp
    const originalTimestamp = this.date.getTime();

    // Get the local timezone offset in milliseconds
    const localTimezoneOffset = this.date.getTimezoneOffset() * 60000;

    // Convert the UTC timestamp to the local timezone
    const localTimestamp = originalTimestamp - localTimezoneOffset;

    // Create a new Date object with the local timestamp
    return new Date(localTimestamp);
  }

  todayMidnightUTCTime() {
    // Get the current date in local timezone
    const currentDate = this.date;

    // Set time to midnight
    currentDate.setHours(0, 0, 0, 0);

    // Convert to UTC
    const utcTimestamp = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;

    // Create a new Date object with the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Return a new SpotBusDateTime object with the UTC Date
    return new SpotBusDateTime(utcDate);
  }

  todayMidnightLocalTime() {
    // Get the current date in local timezone
    const currentDate = this.date;
    // Set time to midnight
    currentDate.setHours(0, 0, 0, 0);
    // Return a new SpotBusDateTime object with the midnight local time
    return currentDate;
  }
}

//! Test localTimeToUTC
// const now = new Date();
// const spotBusDateTime = new SpotBusDateTime(now);
// console.log(spotBusDateTime.localTimeToUTC().toLocaleTimeString());

//! Example usage toLocalizeTimezone:
// const now = new Date("2023-05-30 04:34:00 PM"); // suppose this is utc time
// const spotBusDateTime = new SpotBusDateTime(now);
// console.log(spotBusDateTime.toLocalizeTimezone().toLocaleTimeString());

// ! Example usage toLocalizeTimezone:
// const now = new Date("2023-05-30 04:34:00 PM"); // suppose this is utc time
// const spotBusDateTime = new SpotBusDateTime(now);
// console.log(spotBusDateTime.getTimeDiffToCurrent());

//! Example usage toLocalizeTimezone:
// // Create an instance of SpotBusDateTime
// const spotBusDateTime = new SpotBusDateTime();
// // Call the todayMidnightUTCTime method
// const midnightUTC = spotBusDateTime.todayMidnightUTCTime();
// // Use the resulting SpotBusDateTime object
// console.log(midnightUTC.todayMidnightLocalTime()); // Print the midnight UTC time in ISO format

export const convertObjToLocalTime = (date) => {
  return new Date(date);
};

// Get Local Date
// Example -
// 1712762810 to 4/12/2024
export function getLocalDate(dateValue) {
  if (!dateValue) {
    return '--';
  }
  const newDate = new Date(dateValue * 1000);
  const localDate = new SpotBusDateTime(newDate).toLocalizeDateString();

  return localDate;
}

// isoToLocalTime
// Example -
// 2024-04-14T07:00:00.000000Z to Local Time 12:25:00:AM
export function isoToLocalTime(isoDateString) {
  if (!isoDateString) {
    return '--';
  }

  // Create a Date object from the ISO 8601 formatted string
  var date = new Date(isoDateString);

  // Get the local time components
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  // Determine if it's AM or PM
  var period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the time components
  var formattedTime =
    ('0' + hours).slice(-2) +
    ':' +
    ('0' + minutes).slice(-2) +
    ':' +
    ('0' + seconds).slice(-2) +
    ' ' +
    period;

  return formattedTime;
}

// Get Local Time
// Example -
// 1712762810 to 6:48:00:AM
export function getLocalTime(unixTimestamp) {
  if (!unixTimestamp) {
    return '--';
  }
  // Create a new Date object using the Unix timestamp
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds

  // Extract hours, minutes, and seconds
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Format hours to be in 12-hour format
  const formattedHours = hours % 12 || 12;

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Construct the time string
  const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds} ${ampm}`;

  return timeString;
}


// Convert Local to UTC Time 
export function convertISTtoUTC(istTimeString) {
  // Parse the IST time string
  var istTime = new Date(istTimeString);

  // Convert IST to UTC
  var utcTime = istTime.toUTCString();

  // Extract only the time part (hh:mm:ss)
  var utcTimeFormatted = utcTime.split(' ')[4];

  // Return the UTC time in the format of hh:mm:ss
  return utcTimeFormatted;
}
