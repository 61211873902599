import React from 'react';
import { Grid, Stack } from '@mui/material';
import { formFieldValues } from '../DriverShiftForm';
import DayAndTimeSelection from '../DayAndTimeSelection';

const WeeklySelection = ({ formikProps, variant }) => {
  const [showComponent, setShowComponent] = React.useState(false);
  return (
    <>
      <Grid item xs={12}>
        {formFieldValues?.map((ele) => {
          return (
            <Stack key={ele.day} mb={2}>
              <DayAndTimeSelection
                data={ele}
                variant={variant}
                setShowComponent={setShowComponent}
                showComponent={showComponent}
                formikProps={formikProps}
              />
            </Stack>
          );
        })}
      </Grid>
    </>
  );
};
export default WeeklySelection;
