import React from 'react';
import Chip from '@mui/material/Chip';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';

const getColor = (value) => {
  switch (value) {
    case 'PASSED':
      return 'success';
    case 'FAIL':
      return 'error';
    case 'AT_RISK':
      return 'warning';
    case 'NOT_APPLICABLE':
      return 'secondary';
    case 'completed':
      return 'success';
    case 'PASS':
      return 'success';
    default:
      return 'secondary';
  }
};
const getLabel = (value) => {
  switch (value) {
    case 'PASSED':
      return 'Pass';
    case 'AT_RISK':
      return 'At risk';
    case 'FAIL':
      return 'Fail';
    case 'NOT_APPLICABLE':
      return 'N/A';
    case 'completed':
      return 'Completed';
    case 'PASS':
      return 'Pass';
    default:
      return 'secondary';
  }
};
const getIcon = (value, size = 'medium') => {
  switch (value) {
    case 'PASS':
      return <GppGoodOutlinedIcon fontSize={size} color={getColor(value)} />;
    case 'PASSED':
      return <GppGoodOutlinedIcon fontSize={size} color={getColor(value)} />;
    case 'AT_RISK':
      return <ReportOutlinedIcon fontSize={size} color={getColor(value)} />;
    case 'FAIL':
      return <ReportProblemOutlinedIcon fontSize={size} color={getColor(value)} />;
    case 'NOT_APPLICABLE':
      return <DoDisturbOutlinedIcon fontSize={size} color={getColor(value)} />;
    case 'completed':
      return null;
    default:
      return null;
  }
};
const ChipType = ({ value, ...props }) => {
  return (
    <Chip
      variant='outlined'
      size='small'
      label={getLabel(value)}
      color={getColor(value)}
      icon={getIcon(value)}
      {...props}
    />
  );
};

export { ChipType, getIcon, getLabel };
