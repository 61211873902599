import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import Roles from './RBAC/Roles';

import { DriverImports, StudentImports } from 'containers/layouts/Imports/Imports';
import Devices from 'containers/layouts/Vehicle/Devices';
import SchoolChatBotSupport from 'pages/SchoolChatBotSupport/SchoolChatBotSupport';
import PublicRouteDashboard from 'pages/schoolWebsite/publicRouteDashboard/PublicRouteDashboard';
import TripInspectionDetails from 'pages/TripInspection/TripInspectionDetails/TripInspectionDetails';
const MainVehicle = Loadable(lazy(() => import('pages/Vehicle')));

// render - auth routes
const SignUp = Loadable(lazy(() => import('containers/layouts/Auth/SignUp')));

// render - after auth sections
const Dashboard = Loadable(lazy(() => import('containers/layouts/Dashboard/Dashboard')));
const DashboardDefault = Loadable(lazy(() => import('pages/DashboardV2')));
const LiveMapViewWrapper = Loadable(lazy(() => import('pages/LiveView')));
const Trips = Loadable(lazy(() => import('containers/layouts/Trips/Trips')));
const TripDetails = Loadable(lazy(() => import('containers/layouts/Trips/TripDetails')));

const Schools = Loadable(lazy(() => import('containers/layouts/Schools/Schools')));
const Students = Loadable(lazy(() => import('containers/layouts/Students/Students')));
const StudentAttendanceList = Loadable(
  lazy(() => import('containers/layouts/Students/AttendanceTable/StudentAttendanceList')),
);
const Routes = Loadable(lazy(() => import('containers/layouts/Routes/Routes')));
const RouteDetail = Loadable(lazy(() => import('containers/layouts/Routes/RouteDetailv2')));

const Drivers = Loadable(lazy(() => import('containers/layouts/Drivers/Drivers')));
const DriverApp = Loadable(lazy(() => import('containers/layouts/Drivers/DriverApp/DriverApp')));
const Vehicle = Loadable(lazy(() => import('containers/layouts/Vehicle/Vehicle')));
const Parent = Loadable(lazy(() => import('containers/layouts/Parents/Parent')));
const Breakdowns = Loadable(lazy(() => import('containers/layouts/Breakdowns/Breakdowns')));
const Donation = Loadable(lazy(() => import('containers/layouts/Donation/Donation')));
const Reports = Loadable(lazy(() => import('containers/layouts/Reports/Reports')));
const Forms = Loadable(lazy(() => import('pages/Forms/FormManage/FormManage')));

const TripInspectionDashboard = Loadable(lazy(() => import('pages/TripInspection')));
const TripInspectionForm = Loadable(lazy(() => import('pages/TripInspection/TripInspectionForm')));

const FormRequests = Loadable(lazy(() => import('pages/Forms/FormRequests/FormRequests')));
const HistoricalCommunications = Loadable(
  lazy(() => import('pages/Communication/HistoricalCommunication/HistoricalCommunication')),
);
const CreateCommunication = Loadable(
  lazy(() => import('pages/Communication/CreateCommunication/MainCommunicationForm')),
);
const CommunicationTemplates = Loadable(
  lazy(() => import('pages/Communication/CommunicationTemplates/Templates')),
);

const TemplateLibrary = Loadable(
  lazy(() => import('pages/Communication/CommunicationTemplates/TemplateLibrary')),
);
const OrganizationTemplates = Loadable(
  lazy(() => import('pages/Communication/CommunicationTemplates/OrganizationTemplate')),
);
const AccountSettings = Loadable(
  lazy(() => import('containers/layouts/Settings/AccountSettingsv2')),
);
const EditAccountSettings = Loadable(
  lazy(() => import('containers/layouts/Settings/EditAccountSettingsv2')),
);
const Users = Loadable(lazy(() => import('containers/layouts/ManageUsersV1/Users')));
const CreateUser = Loadable(
  lazy(() => import('containers/layouts/ManageUsersV1/components/CreateUser')),
);
const SearchTab = Loadable(lazy(() => import('containers/layouts/HeaderSearch/SearchTab')));
const ChangePassword = Loadable(lazy(() => import('containers/layouts/Auth/ChangePassword')));
const HistoricalCommunicationDetails = Loadable(
  lazy(() => import('pages/Communication/CommunicationDetails/CommunicationDetails')),
);

const Sessions = Loadable(lazy(() => import('pages/Sessions/Sessions')));
const Incident = Loadable(lazy(() => import('pages/Incident/incident/Incident')));
const IncidentConfig = Loadable(
  lazy(() => import('pages/Incident/configuration/MainConfiguration')),
);

const TripInspectionReportsTable = Loadable(
  lazy(() => import('pages/TripInspection/TripInspectionReports/TripInspectionReportsTable')),
);
const StudentAttendance = Loadable(
  lazy(() => import('containers/layouts/Students/StudentAttendance/StudentAttendance')),
);

const AppRoutesList = [
  {
    component: SignUp,
    path: 'signup',
    exact: false,
  },
  {
    component: Dashboard,
    path: '/',
    exact: false,
  },
  {
    component: DashboardDefault,
    path: 'dashboardv2',
    exact: false,
  },
  {
    component: LiveMapViewWrapper,
    path: 'live-view',
    exact: true,
  },
  {
    component: Trips,
    path: 'trips',
    exact: true,
  },
  {
    component: TripInspectionDashboard,
    path: '/trips/inspection',
    exact: true,
  },
  {
    component: TripInspectionDetails,
    path: '/trip-inspection/reports/:inspection_Id',
    exact: true,
  },
  {
    component: TripInspectionForm,
    path: '/trip-inspection/forms/pre-trip',
    exact: true,
  },
  {
    component: TripInspectionForm,
    path: '/trip-inspection/forms/post-trip',
    exact: true,
  },
  {
    component: TripInspectionReportsTable,
    path: '/trip-inspection/reports',
    exact: true,
  },
  {
    component: TripDetails,
    path: 'trip-details/:route_id',
    exact: true,
  },
  {
    component: Schools,
    path: 'schools',
    exact: false,
  },
  {
    component: PublicRouteDashboard,
    path: 'public-route-dashboard',
    exact: false,
  },
  // {
  //   component: SchoolChatBotSupport,
  //   path: '/ai-assistant',
  //   exact: false,
  // },
  {
    component: Students,
    path: 'students',
    exact: false,
  },
  {
    component: StudentAttendance,
    path: 'students/attendance/:school_id/:trip_id',
    exact: true,
  },
  {
    component: StudentImports,
    path: 'students/import',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: StudentAttendanceList,
    path: 'students/attendance-list',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: Routes,
    path: 'routes',
    exact: false,
  },
  {
    component: RouteDetail,
    path: 'routes/:route_id',
    exact: true,
    routePageMode: 'READ',
  },
  {
    component: Drivers,
    path: 'drivers',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: DriverApp,
    path: 'drivers/app',
    exact: true,
    pageMode: 'DRIVERS_APP',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: DriverImports,
    path: 'drivers/import',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: MainVehicle,
    path: 'vehicles',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: Devices,
    path: 'devices',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  // {
  //   component: Planning,
  //   path: 'route-planning',
  //   exact: false,
  //   permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  // },
  {
    component: Parent,
    path: 'parents',
    exact: true,
  },
  // {
  //   component: Breakdowns,
  //   path: 'breakdowns',
  //   exact: false,
  // },
  {
    component: Donation,
    path: 'donations',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  // {
  //   component: Reports,
  //   path: 'reports',
  //   exact: true,
  // },
  // {
  //   component: Forms,
  //   path: 'forms',
  //   exact: true,
  // },

  // {
  //   component: FormRequests,
  //   path: 'forms/responses',
  //   exact: false,
  //   permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  // },
  {
    component: HistoricalCommunications,
    path: 'communications',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: HistoricalCommunicationDetails,
    path: 'communication/:communication_id',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateCommunication,
    path: 'communications/create',
    exact: true,
    pageMode: 'CREATE',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateCommunication,
    path: 'communications/edit/:communication_id',
    exact: true,
    pageMode: 'EDIT',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CommunicationTemplates,
    path: 'communications/templates',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateCommunication,
    path: 'communications/templates/create',
    exact: true,
    pageMode: 'CREATE_TEMPLATE',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateCommunication,
    path: 'communications/templates/edit/:template_id',
    exact: true,
    pageMode: 'EDIT_TEMPLATE',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateCommunication,
    path: 'communications/create/canvas/:template_id',
    exact: true,
    pageMode: 'CREATE',
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: TemplateLibrary,
    path: 'communications/templates/library',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: OrganizationTemplates,
    path: 'communications/templates/organization',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: Sessions,
    path: 'sessions/create',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: Sessions,
    path: 'sessions/edit/:session_id',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: AccountSettings,
    path: 'account-settings',
    exact: false,
  },
  {
    component: EditAccountSettings,
    path: 'account-settings/edit',
    exact: true,
    permission: [Roles.SUPER_ADMIN],
  },
  {
    component: Drivers,
    path: 'users',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: CreateUser,
    path: 'add-user',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: SearchTab,
    path: 'search',
    exact: false,
  },
  {
    component: ChangePassword,
    path: 'change-password',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: Incident,
    path: 'incident',
    exact: false,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
  {
    component: IncidentConfig,
    path: 'incident/configuration',
    exact: true,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
  },
];

export default AppRoutesList;
