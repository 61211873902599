import React from 'react';
import PropTypes from 'prop-types';

// third-party imports
import { Scrollbars } from 'react-custom-scrollbars-2';

// material-ui
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// custom components
import { SchoolInfoCard } from 'components/cards';
import MainCard from 'components/MainCard';

// utils
import { oneLineAddress } from 'utils';

// assets
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FaceIcon from '@mui/icons-material/Face';
import MapIcon from '@mui/icons-material/Map';
import { useEffect } from 'react';
import NextButton from 'components/Buttons/NextButton';
import { updateGuidedWorkFlow } from 'clients/SpotBus';
import { IcoButton } from 'components/Buttons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const skeltonCount = [1, 2, 3];

// other elements for the screen
const CircleElement = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '280px',
  height: '280px',
  background: `linear-gradient(white, ${theme.palette.primary.main})`,
  borderRadius: '50%',
  transform: 'translate(-120px, -120px)',
}));

const SmallFilledCircleElement = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100px',
  height: '100px',
  background: theme.palette.primary.light,
  borderRadius: '50%',
  opacity: 0.8,
  transform: 'translate(40vw, 50vh)',
}));

const BorderRectangleElement = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '250px',
  height: '100px',
  border: `1.5px solid ${theme.palette.secondary.main}`,
  borderRadius: '10px',
  transform: 'translate(42vw, 520px)',
}));

const BorderSquareElement = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '80px',
  height: '80px',
  border: `1.5px solid ${theme.palette.success.main}`,
  borderRadius: '10px',
  transform: 'translate(22vw, 68vh) rotate(45deg)',
  transition: `${theme.transitions.create(['border-right', 'transform'])}`,
  '&:hover': {
    transform: 'translate(21vw, 68vh) rotate(0deg)',
  },
}));

const pulseAnimation = keyframes`
from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`;

const CompletedButton = styled('button')(({ theme }) => ({
  background: theme.palette.success.main,
  borderRadius: '10px',
  padding: 12,
  animation: `${pulseAnimation} 1.5s ease-in-out infinite both`,
  variant: 'contained',
  size: 'large',
  color: 'white',
  border: `0px solid `,
  textTransform: 'uppercase',
}));

const SchoolSelectionPageSkeleton = () => {
  return (
    <>
      {skeltonCount.map((skelton, index) => {
        return (
          <MainCard key={index} sx={{ mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Skeleton variant='rectangular' width={100} height={100} />
              </Grid>
              <Grid item xs={8}>
                <Typography component='div' variant='subtitle1' width='70%'>
                  <Skeleton />
                </Typography>
                <Typography component='div' variant='h2' width='30%'>
                  <Skeleton />
                </Typography>
                <Typography component='div' variant='body2' width='90%'>
                  <Skeleton />
                </Typography>
              </Grid>
            </Grid>
          </MainCard>
        );
      })}
    </>
  );
};

// ============================== SCHOOL SELECTION SCREEN ============================== //

function SchoolSelectionPage({
  isLoading,
  schoolsData,
  handleSchoolSelection,
  guidedWorkflowData,
  isGuidedWorkflowCompleted,
  handleGuidedWorkflowDataUpdate,
  guidedButtonDisable,
  setPhase,
}) {
  const guidedData = guidedWorkflowData.steps_graph;

  let findingIndex = guidedData.findIndex((ele) => {
    return ele.name === 'CONFIGURE_SCHOOLS';
  });

  let configure_School_data = guidedWorkflowData.steps_graph[findingIndex].data.map((ele) => {
    return { shoolId: ele.school_id, is_setup_completed: ele.is_completed, section_name: ele.name };
  });

  const cSecondPhase = configure_School_data.filter((ele) => {
    if (ele.section_name === 'ADD_STUDENTS' && ele.is_setup_completed === true) {
      return true;
    }
  });

  const comparisonResult = schoolsData.map((school) => {
    return cSecondPhase.some((phase) => phase.shoolId === school.school_id);
  });

  const handleRedirectToDashboard = async () => {
    let response = guidedWorkflowData;
    response.is_completed = true;
    try {
      const result = await updateGuidedWorkFlow(response);
      handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box display='flex' height='82vh' mx={10}>
      {/** Styling elements */}
      <CircleElement />
      <BorderRectangleElement />
      <SmallFilledCircleElement />
      <BorderSquareElement />

      {/** Main code */}
      <Grid container sx={{ zIndex: 'modal' }} spacing={3} alignItems='center'>
        <Grid
          sx={{
            position: 'fixed',
            top: '80px',
            left: '20px',
            zIndex: 'tooltip',
          }}
        >
          <IcoButton onClick={() => setPhase(0)} icon={<ArrowBackIcon />} />
        </Grid>
        <Grid item xs={8} alignItems='center'>
          <Typography
            variant='h1'
            component='div'
            sx={{ fontWeight: '600', fontSize: '60px' }}
            gutterBottom
          >
            Guided Setup - Phase 2
          </Typography>
          <Typography
            variant='h2'
            component='div'
            sx={{ fontWeight: '200', fontSize: '28px' }}
            gutterBottom
          >
            Please select a school to start configuring students <br></br>for your selected school.
          </Typography>
          {/* <Typography variant='body' component='div'>
            This phase consist of two 2 steps, which are
          </Typography> */}
          <List>
            <ListItem>
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary='Configure Students' />
            </ListItem>
            {/* <ListItem>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary='Configure Routes' />
            </ListItem> */}
          </List>

          <NextButton
            disabled={!guidedButtonDisable}
            endIcon={<ArrowForwardIcon />}
            onClick={handleRedirectToDashboard}
          >
            Complete Account setup
          </NextButton>
          {/* <NextButton onClick={handleRedirectToDashboard}> Completed Guided Work Flow</NextButton> */}
        </Grid>
        <Grid item xs={4}>
          <Scrollbars
            autoHeight
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeightMax={400}
          >
            {isLoading && <SchoolSelectionPageSkeleton />}
            {!isLoading && (
              <Grid container spacing={3} alignItems='center'>
                {schoolsData?.map((schoolData, i) => {
                  return (
                    <Grid item xs={12} key={schoolData.school_id}>
                      <SchoolInfoCard
                        index={i}
                        guidedWorkflowData={guidedWorkflowData}
                        name={schoolData.school_name}
                        abbreviation={
                          schoolData.abbreviation ? schoolData.abbreviation : schoolData.school_id
                        }
                        address={oneLineAddress(schoolData.address)}
                        src={schoolData.image?.url}
                        onClick={() => handleSchoolSelection(schoolData)}
                        configure_School_dataSS={schoolData.school_id}
                        completedSchool={comparisonResult[i]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Scrollbars>
        </Grid>
      </Grid>
    </Box>
  );
}

SchoolSelectionPage.defaultProps = {
  isLoading: false,
};

SchoolSelectionPage.propTypes = {
  isLoading: PropTypes.bool,
  schoolsData: PropTypes.oneOf([undefined, PropTypes.array]).isRequired,
  handleSchoolSelection: PropTypes.func.isRequired,
};

export default SchoolSelectionPage;
