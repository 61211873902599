import { Grid, Tooltip, Typography } from '@mui/material';
//ICONS
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';

export default function CategoryDetails({ title, count }) {
  return (
    <Grid container xs={12}>
      <Typography variant='h5'>{title}</Typography>
      <Grid container xs={12} sx={{ mt: 2 }} justifyContent={'space-between'}>
        <Grid item xs={3}>
          <Tooltip title='Pass'>
            <Grid container alignItems={'center'}>
              <GppGoodOutlinedIcon color='success' fontSize='small' />
              <Typography sx={{ ml: 1, mt: 0.4 }} variant='body2'>
                {count.PASS ?? 0}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title='Fail'>
            <Grid container alignItems={'center'}>
              <ReportOutlinedIcon color='warning' fontSize='small' />
              <Typography sx={{ ml: 1, mt: 0.4 }} variant='body2'>
                {count.FAIL ?? 0}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title='At Risk'>
            <Grid container alignItems={'center'}>
              <ReportProblemOutlinedIcon color='error' fontSize='small' />
              <Typography sx={{ ml: 1, mt: 0.4 }} variant='body2'>
                {count.AT_RISK ?? 0}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title='N/A'>
            <Grid container alignItems={'center'}>
              <DoDisturbOutlinedIcon color='secondary' fontSize='small' />
              <Typography sx={{ ml: 1, mt: 0.4 }} variant='body2'>
                {count.NOT_APPLICABLE ?? 0}
              </Typography>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
