import React, { useState } from 'react';

// material ui
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Autocomplete, FormHelperText, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MainCard from 'components/MainCard';
import InputAdornment from '@mui/material/InputAdornment';

// assets
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HeightIcon from '@mui/icons-material/Height';

import Validations from 'containers/layouts/Utils/Validations';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// Custom Component
import CancelButton from 'components/Buttons/CancelButton';
import NextButton from 'components/Buttons/NextButton';
import DefaultAvatar from 'components/avatars/DefaultAvatar';
import { ClassificationHeading } from 'components/Headers';
import SearchAutocomplete from 'components/search/AsyncSearch';
import MultipleSelectCheckMarks from 'components/inputs/BaseMultiSelect';
import { BaseXMultiSelect, BaseXSelect } from 'components/muix/filters';
import BaseSingleSelect from 'components/inputs/BaseSingleSelect';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { CountrySelect } from 'components/Formik';

const phoneRegExp = /^\+?[\d]{10,14}$/;

const DESIGNATION = [
  {
    label: 'Transportation Director',
  },
  {
    label: 'Dispatcher',
  },
  {
    label: 'Supervisor',
  },
  {
    label: 'Driver',
  },
  {
    label: 'Mechanic',
  },
  {
    label: 'Lead Driver',
  },
];

const ROLE_TYPE = [
  {
    label: 'Director',
    value: 'DIRECTOR',
  },
  {
    label: 'Dispatcher',
    value: 'DISPATCHER',
  },
  {
    label: 'Supervisor',
    value: 'SUPERVISOR',
  },
  {
    label: 'Driver',
    value: 'DRIVER',
  },
  {
    label: 'Mechanic',
    value: 'MECHANIC',
  },
  {
    label: 'Viewer',
    value: 'VIEWER',
  },
];

const EnrolmentType = {
  label: 'Enrolment Type',
  options: [
    {
      label: 'Regular',
      value: 'REGULAR',
    },
    {
      label: 'Temporary',
      value: 'TEMPORARY',
    },
    {
      label: 'Substitute',
      value: 'SUBSTITUTE',
    },
  ],
};

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required *'),
  first_name: Validations.first_name,
  last_name: Validations.last_name,
  driver_height_feet: yup
    .number()
    .min(4, 'Height must be at least 4 Feet')
    .max(8, 'Max height can be 8 Feet')
    .typeError('Enter only numbers'),
  driver_height_inches: yup
    .number()
    .min(0, 'Height must be at least 0 inches')
    .max(11, 'Max height can be 11 inches')
    .typeError('Enter only numbers'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid *')
    .min(10, 'Phone number should be of length 10')
    .max(13, 'Phone number should be of length 13')
    .required('Phone Number is required *'),
});

export default function BasicDriverForm(props) {
  const classes = useStyles();

  const {
    designation,
    setDesignation,
    roleType,
    setRoleType,
    enrolmentType,
    setEnrolmentType,
  } = props.additionalInfoBasicInfoProps;

  const handleInputChange = (event, value) => {
    setDesignation(value);
  };
  const handleMenuChange = (event) => {
    const value = event.target.value;
    setRoleType(value);
  };

  const handleHeightValue = (key, value) => {
    switch (key) {
      case 'centimeter':
        return Number(value) * 2.54;
      case 'feet':
        // return parseInt(Number(value) / 2.54 / 12);
        var realFeet = (value * 0.3937) / 12;
        var feet = Math.floor(realFeet);
        var inch = Math.round((realFeet - feet) * 12);
        return inch === 12 ? feet + 1 : feet;
      case 'inches':
        // var inch = Number(value) / 2.54 / 12;
        // var intInch = Math.round(inch - parseInt(inch)) * 12;
        // return intInch;
        var convertedFeet = (value * 0.3937) / 12;
        var intFeet = Math.floor(convertedFeet);
        var inches = Math.round((convertedFeet - intFeet) * 12);
        return inches === 12 ? 0 : inches;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: props.firstName ? props.firstName : '',
      last_name: props.lastName ? props.lastName : '',
      email: props.email ? props.email : '',
      phone: props.phoneNum ? props.phoneNum : '',
      driver_height_feet: props.height ? handleHeightValue('feet', props.height) : '',
      driver_height_inches: props.height ? handleHeightValue('inches', props.height) : '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setFirstName(values.first_name);
      props.setLastName(values.last_name);
      props.setPhoneNum(values.phone);
      props.setHeight(
        handleHeightValue(
          'centimeter',
          Number(values.driver_height_feet) * 12 + Number(values.driver_height_inches),
        ),
      );
      props.setEmail(values.email);
      props.setActiveStep(1);
    },
  });

  const handleCancel = () => {
    props.clearAllFormStates();
    props.closeAddDriverForm();
    props.setImageValidation(false);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        minHeight: props.isFromGuided ? 490 : '',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Stack height={'100%'}>
        <ClassificationHeading>Basic Information</ClassificationHeading>
        {/* Mani form */}
        <Stack direction={'row'} spacing={3} justifyContent={'space-between'}>
          {/* container Left */}
          <Stack width={'60%'} spacing={3}>
            <Stack spacing={3} direction={'row'} justifyContent={'space-between'}>
              <Stack width={'50%'}>
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  autoFocus
                  id='first_name'
                  name='first_name'
                  label='First Name *'
                  type='text'
                  variant='standard'
                  fullWidth
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                  helperText={
                    Boolean(formik.touched.first_name && formik.errors.first_name)
                      ? formik.touched.first_name && formik.errors.first_name
                      : 'Enter first name'
                  }
                  InputProps={firstNameInputProps}
                />
              </Stack>

              <Stack width={'50%'}>
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  id='last_name'
                  name='last_name'
                  label='Last Name *'
                  type='text'
                  variant='standard'
                  fullWidth
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                  helperText={
                    Boolean(formik.touched.last_name && formik.errors.last_name)
                      ? formik.touched.last_name && formik.errors.last_name
                      : 'Enter last name'
                  }
                  InputProps={lastNameInputProps}
                />
              </Stack>
            </Stack>

            <Stack spacing={3} direction={'row'} justifyContent={'space-between'}>
              <Stack width={'50%'}>
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  id='height_feet'
                  name='driver_height_feet'
                  label='Height in feet'
                  type='number'
                  variant='standard'
                  fullWidth
                  value={formik.values.driver_height_feet}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.driver_height_feet && Boolean(formik.errors.driver_height_feet)
                  }
                  helperText={
                    Boolean(formik.touched.driver_height_feet && formik.errors.driver_height_feet)
                      ? formik.touched.driver_height_feet && formik.errors.driver_height_feet
                      : 'Enter height in feet'
                  }
                  InputProps={heightInFeetInputProps}
                />
              </Stack>

              <Stack width={'50%'}>
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  id='height_inch'
                  name='driver_height_inches'
                  label='Height in inches'
                  type='number'
                  variant='standard'
                  fullWidth
                  value={formik.values.driver_height_inches}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.driver_height_inches &&
                    Boolean(formik.errors.driver_height_inches)
                  }
                  helperText={
                    Boolean(
                      formik.touched.driver_height_inches && formik.errors.driver_height_inches,
                    )
                      ? formik.touched.driver_height_inches && formik.errors.driver_height_inches
                      : 'Enter height in inches'
                  }
                  InputProps={heightInInchInputProps}
                />
              </Stack>
            </Stack>

            <Stack spacing={3} direction={'row'} justifyContent={'space-between'}>
              <Stack width={'50%'}>
                {/* <CountrySelect
                  {...formik}
                  name='driver_phone_code'
                  label='Phone code'
                  id='driver_phone_code'
                  type='phone'
                  countryId='driver_phone_code'
                  helperTextContent='Country code'
                  textFieldProps={{
                    variant: 'outlined',
                    size: 'small',
                  }}
                /> */}
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  id='phone_number'
                  name='phone'
                  label='Phone Number *'
                  type='phone'
                  variant='standard'
                  fullWidth
                  disabled={props.isEdit}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={
                    Boolean(formik.touched.phone && formik.errors.phone)
                      ? formik.touched.phone && formik.errors.phone
                      : 'Enter phone number'
                  }
                  InputProps={phoneNumberInputProps}
                />
              </Stack>
              <Stack width={'50%'}>
                <TextField
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  label='Email *'
                  id='email'
                  name='email'
                  type='email'
                  variant='standard'
                  fullWidth
                  disabled={props.isEdit}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    Boolean(formik.touched.email && formik.errors.email)
                      ? formik.touched.email && formik.errors.email
                      : 'Enter email id'
                  }
                  InputProps={emailInputProps}
                />
              </Stack>
            </Stack>
          </Stack>

          {/* Image Container */}
          <Stack width={'40%'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              className={classes.image}
            >
              <Badge
                overlap='circular'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <DefaultAvatar src={props.profileImage} className={classes.large} />
              </Badge>
              <label className={classes.imageButton} htmlFor='driverImage'>
                <input
                  accept='image/*'
                  className={classes.input}
                  id='driverImage'
                  type='file'
                  onChange={(event) => props.handleSelectImage(event, 'profileImage')}
                />
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                  sx={{
                    bgcolor: 'background.default',
                    borderColor: 'primary.main',
                    border: 2,
                    p: 1,
                    '&:hover': {
                      bgcolor: 'background.default',
                    },
                  }}
                  // className={classes.iconBtn}
                  id='profileImage'
                  size='large'
                >
                  {props.profileImage ? <EditIcon /> : <PhotoCameraIcon />}
                </IconButton>
              </label>
            </Stack>
            <Stack style={{ textAlign: 'center' }}>
              <Typography variant='body2' color='text.secondary'>
                Upload image
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <MainCard content={false} sx={{ my: 2 }}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-around', pb: 2, mt: 2 }}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>Gender</Typography>
            <Button
              size='small'
              id='male'
              onClick={() => props.setGender('male')}
              variant={props.gender === 'male' ? 'contained' : 'outlined'}
              color={props.gender === 'male' ? 'primary' : 'secondary'}
              startIcon={<PersonIcon />}
              className={classes.male}
            >
              Male
            </Button>
            <Button
              size='small'
              id='female'
              onClick={() => props.setGender('female')}
              variant={props.gender === 'female' ? 'contained' : 'outlined'}
              color={props.gender === 'female' ? 'primary' : 'secondary'}
              startIcon={<PersonIcon />}
              className={classes.male}
            >
              Female
            </Button>
            <Button
              size='small'
              id='third'
              onClick={() => props.setGender('na')}
              variant={props.gender === 'na' ? 'contained' : 'outlined'}
              color={props.gender === 'na' ? 'primary' : 'secondary'}
              startIcon={<PersonIcon />}
              className={classes.male}
            >
              Unspecified
            </Button>
          </Grid>
        </MainCard>
      </Stack>

      <Stack spacing={2}>
        <ClassificationHeading>Role & Designation</ClassificationHeading>
        <Stack direction={'row'} spacing={3} alignItems={'center'}>
          <Stack width={'50%'}>
            <Autocomplete
              disablePortal
              value={designation}
              options={DESIGNATION}
              onInputChange={handleInputChange}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Designation'
                  helperText='Enter designation'
                />
              )}
            />
          </Stack>
          <Stack width={'50%'}>
            <BaseSingleSelect
              value={enrolmentType}
              setValue={setEnrolmentType}
              options={EnrolmentType.options}
              label='Enrolment Type'
              size='small'
              variant='standard'
            />
            <FormHelperText>Select enrolment type</FormHelperText>
          </Stack>
        </Stack>

        <Stack>
          <MultipleSelectCheckMarks
            list={ROLE_TYPE}
            label={'Role Type'}
            value={roleType}
            setValue={setRoleType}
            size='small'
            variant='standard'
          />
          <FormHelperText>Select role type</FormHelperText>
        </Stack>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'}>
        {!props.isFromGuided ? (
          <Stack>
            <CancelButton isIcon={true} size='medium' id='driver_cancel' onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        ) : (
          <Stack></Stack>
        )}
        <Stack>
          <Stack>
            <NextButton
              onClick={() => props.setImageValidation(true)}
              id='driver_save_button'
              size='medium'
              type='submit'
            >
              Next
            </NextButton>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  gender: {
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    marginTop: 8,
  },
  male: {
    borderRadius: 25,
  },
  saveButton: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  input: {
    display: 'none',
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageButton: {
    marginLeft: -32,
    marginTop: 58,
  },
  iconBtn: {
    backgroundColor: 'white !important',
    border: '2px solid',
    borderColor: 'primary',
    padding: 4,
  },
}));

const emailInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const firstNameInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const lastNameInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const heightInFeetInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <HeightIcon />
    </InputAdornment>
  ),
};

const heightInInchInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <HeightIcon />
    </InputAdornment>
  ),
};

const phoneNumberInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PhoneAndroidIcon />
    </InputAdornment>
  ),
};
