// material-ui
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';

export const FailureChip = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginLeft: theme.spacing(2),
  },
}))(Chip);

export const SuccessChip = withStyles((theme) => ({
  root: {
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
    marginLeft: theme.spacing(2),
  },
}))(Chip);

export const ActiveChip = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
}))(Chip);
