export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAIL = 'SEARCH_FAIL';

export const FILTER_LIST_REQUEST = 'FILTER_LIST_REQUEST';
export const FILTER_LIST_SUCCESS = 'FILTER_LIST_SUCCESS';
export const FILTER_LIST_FAIL = 'FILTER_LIST_FAIL';

export const TAB_SEARCH_FILTER_REQUEST = 'TAB_SEARCH_FILTER_REQUEST';
export const TAB_SEARCH_FILTER_SUCCESS = 'TAB_SEARCH_FILTER_SUCCESS';
export const TAB_SEARCH_FILTER_FAIL = 'TAB_SEARCH_FILTER_FAIL';

export const SEARCH_INPUT_VALUE_CHANGE = 'SEARCH_INPUT_VALUE_CHANGE';

export const UPDATE_DATA = 'UPDATE_DATA';