import { TableHeadInsideIcoWtext as TableHead } from 'components/Table';
// mui
import { TableCell } from '@mui/material';
// Icons
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import MemoryIcon from '@mui/icons-material/Memory';
import ErrorIcon from '@mui/icons-material/Error';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import { FailureChip, SuccessChip } from '../CustomComponents';
import { IcoButton } from 'components/Buttons';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import QrCode2Icon from '@mui/icons-material/QrCode2';

function formatDeviceType(device) {
  switch (device) {
    case 'gps_tracker':
      return 'GPS Tracker';
    case 'tablet':
      return 'Tablet';
    case 'rfid':
      return 'RFID';
    default:
      return 'Unknown Device';
  }
}

export const tableHeadingData = [
  {
    id: '0',
    headerName: <TableHead icon={<PhonelinkIcon fontSize='small' />} title={'Device Type'} />,
  },
  {
    id: '1',
    headerName: <TableHead title='Device ID' icon={<SmartScreenIcon fontSize='small' />} />,
  },
  {
    id: '2',
    headerName: <TableHead title='Manufacturer' icon={<MemoryIcon fontSize='small' />} />,
  },
  {
    id: '3',
    headerName: <TableHead title='Status' icon={<ErrorIcon fontSize='small' />} />,
  },
  {
    id: '4',
    headerName: <TableHead title='Link Device' icon={<InsertLinkIcon fontSize='small' />} />,
  },
];

export const tablerowDataFunction = (row, index, handleOpenQrCodeDialog) => {
  return (
    <>
      <TableCell align='left' id={'licence_plate_number_' + index}>
        {formatDeviceType(row.device_type)}
      </TableCell>
      <TableCell align='left' id={'vehicle_' + index} sx={{ textTransform: 'uppercase' }}>
        {row?.device_id}
      </TableCell>
      <TableCell align='left' id={'model_number_' + index} sx={{ textTransform: 'capitalize' }}>
        {row.manufacturer}
      </TableCell>
      <TableCell align='left' id={'seating_capacity_' + index}>
        {row.status === 'active' ? (
          <SuccessChip variant='outlined' size='small' label='Active'></SuccessChip>
        ) : (
          <FailureChip variant='outlined' size='small' label='Inactive'></FailureChip>
        )}
      </TableCell>
      <TableCell align='left' id={'link_device' + index}>
        <IcoButton icon={<QrCode2Icon />} onClick={() => handleOpenQrCodeDialog(row)} />
      </TableCell>
    </>
  );
};
