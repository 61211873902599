import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';

// project-imports
import NoMatchPage from 'containers/layouts/ErrorPages/NomatchPage';

// ============================== Routes Mapper ============================== //

function MapAllowedRoutes({ routes, basePath, isAddNotFound, ...props }) {
  return (
    <Routes>
      {routes.map((route) => {
        const { path, component: Component, children, title, permission, ...rest } = route;

        return (
          <Route
            {...rest}
            key={path}
            path={`${basePath}${path}`}
            element={<Component {...props} {...rest} />}
          />
        );
      })}
      {isAddNotFound && <Route path='*' element={<NoMatchPage />} />}
    </Routes>
  );
}

export default memo(MapAllowedRoutes);
