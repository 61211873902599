// project import
import items from './drawerItems';
import navbarItems from './navbarItems';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [items, navbarItems],
};

export default menuItems;
