import React from 'react';
import PropTypes from 'prop-types';

// material ui imports
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

// assets
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';

// third party
import GoogleMapReact from 'google-map-react';

// project imports
import { googleMapApi } from 'containers/layouts/API/GoogleMapApi';
import SpotBusMapStyles from 'containers/layouts/Maps/SpotBusMapStyles';
import CustomTooltip from 'components/tooltips/CustomTooltip';

function MapMarker({ iconName, showTooltip, tooltipTitle }) {
  switch (iconName) {
    case 'home':
      return (
        <CustomTooltip title={tooltipTitle} disableHoverListener={!showTooltip}>
          <HomeIcon variant='h4' text={'home'} color='primary' style={{ fontSize: '36px' }} />
        </CustomTooltip>
      );
    case 'school':
      return (
        <CustomTooltip title={tooltipTitle} disableHoverListener={!showTooltip}>
          <SchoolIcon variant='h4' text={'school'} color='primary' style={{ fontSize: '36px' }} />
        </CustomTooltip>
      );
    case 'breakdown':
      return (
        <CustomTooltip title={tooltipTitle} disableHoverListener={!showTooltip}>
          <Avatar alt='bus icon' src='/static/images/icons/sb-bus-bk-icon.png' />
        </CustomTooltip>
      );
    default:
      return (
        <CustomTooltip title={tooltipTitle} disableHoverListener={!showTooltip}>
          <img height='40px' width='40px' src={`${process.env.PUBLIC_URL}/MapPin.png`} alt='' />;
        </CustomTooltip>
      );
  }
}

export default function GoogleMaps({ height, markers, isLoading }) {
  /*
  ? How to use this component 
  * use case =>
  *  <GoogleMaps
  *    height={0.35}
  *    isLoading={dataLoading}
  *    markers={[
  *      {
  *        iconName: 'home',
  *        showTooltip: true,
  *        tooltipTitle: <Typography>Home</Typography>,
  *        geoLocation: geoLocation,
  *      },
  *    ]}
  *  />;
  */

  const theme = useTheme();

  const options = {
    lat: markers[0]?.geoLocation ? Number(markers[0].geoLocation?.latitude) : 48.15091896593926,
    lng: markers[0]?.geoLocation ? Number(markers[0].geoLocation?.longitude) : -122.20859547801348,
  };

  return (
    <div
      style={{
        height: window.innerHeight * height,
        width: '100%',
        zIndex: 1,
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        borderColor:
          theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey[300],
      }}
    >
      <GoogleMapReact
        options={(maps) => ({
          styles: SpotBusMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
          },
        })}
        bootstrapURLKeys={{
          key: googleMapApi(),
        }}
        center={options}
        defaultZoom={isLoading ? 10 : 14}
        yesIWantToUseGoogleMapApiInternals
      >
        {!isLoading &&
          markers &&
          markers.map(
            (data, index) =>
              data.geoLocation?.latitude &&
              data.geoLocation?.longitude && (
                <MapMarker
                  lat={Number(data.geoLocation?.latitude)}
                  lng={Number(data.geoLocation?.longitude)}
                  iconName={data.iconName}
                  showTooltip={data.showTooltip}
                  tooltipTitle={data.tooltipTitle}
                />
              ),
          )}
      </GoogleMapReact>
    </div>
  );
}

GoogleMaps.defaultProps = {
  height: 0.35,
};

GoogleMaps.propTypes = {
  height: PropTypes.number.isRequired,
  markers: PropTypes.array,
  isLoading: PropTypes.bool,
};
