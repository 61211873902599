import React from 'react';

// Material UI
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';

// Formik
import { useFormikContext } from 'formik';

const DatePickerField = ({
  field,
  form,
  helperText,
  meta,
  index = 0,
  multiFieldArray = false,
  fullWidth = false,
  textFieldProps = {},
  variant = 'standard',
  error = false,
  ...other
}) => {
  const { name, value } = field;
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();

  const handleDateChange = (date) => {
    setFieldValue(name, date);
    setFieldTouched(name, true, false);
  };

  let isError = touched[name] && errors[name];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        // disableFuture
        id='date-picker-dialog'
        value={value}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            fullWidth={fullWidth}
            variant={variant}
            {...params}
            error={isError || error}
            helperText={isError ? isError : helperText}
            InputLabelProps={{
              shrink: true,
              error: isError,
            }}
            views={['day']}
            {...textFieldProps}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
