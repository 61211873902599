import React from 'react';
import TextField from '@mui/material/TextField';
import ValidatorComponent from './ValidatorComponent';
import { isNotBlank } from './Validators';

let MIN_PASSWORD_LENGTH = 8 

export default class PasswordTextField extends ValidatorComponent {

    constructor(props){
        super(props)
        
    }



    isValidPassword(value){
        
        return value.length >= MIN_PASSWORD_LENGTH
    }

    getDefaultValidationRules(){
        return [
            {
                function: isNotBlank,
                errorMessage: "Password field is required."
            },
            {
                function: this.isValidPassword,
                errorMessage: "Password should have atleast "+MIN_PASSWORD_LENGTH+" characters."
            }
        ]
    }

    render(){
        
        return (
            <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                {...this.props}
                onChange={(e) => { 
                    this.validate(e.target.value);
                    this.props.onChange(e)
                 }}
                error={this.state.errorMessage != undefined || this.props.error}
                helperText={this.state.errorMessage || this.props.helperText}
            />
        );
    }
}