import Auth from '@aws-amplify/auth';

console.warn("Spotbus API Config called")

const spotbusapis = {
  endpoints: [
    {
      name: 'SpotBusAPI',
      endpoint: 'https://uxd2g0rdm6.execute-api.us-east-1.amazonaws.com/api',
      // endpoint: "http://127.0.0.1:8000",
      custom_header: async () => {
        return {
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          SessionId: `${localStorage.getItem('currentSessionId')}`
        };
      },
    },
  ],
};

export default spotbusapis;
