import * as Yup from 'yup';
export const validationSchemaDevices = [
  Yup.object().shape({
    device_type: Yup.string().required('Device type is required'),
    device_id: Yup.string()
      .trim()
      .required('Device ID is required'),
    device_model: Yup.string().required('Device model is required'),
    manufacturer: Yup.string().required('Manufacturer is required'),
    status: Yup.boolean(),
    driverapp: Yup.boolean(),
    gps_tracking: Yup.boolean(),
    attendance: Yup.boolean(),
    sim_number: Yup.string().trim('Sim number is invalid'),
    device_phone_code: Yup.object().optional(),
    device_phone_number: Yup.string()
      .optional()
      .matches(/^\d+$/, 'Number must contain only numbers')
      .min(10, 'Number must be at least 10 characters')
      .max(12, 'Number must be at most 12 characters'),
  }),
];
