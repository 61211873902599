import { Divider, Grid, Typography, Stack, Skeleton } from '@mui/material';
import { QuickViewActionsTitleV2 } from 'components/quickViews';
import React from 'react';
import { GetStatusAndLastUpdatedTime } from '../CustomComponents';
import { longDateFormatter } from 'containers/layouts/Utils/DateHandling';
import QuickViewActionItem from 'components/quickViews/QuickViewActionItem';

// icons
import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

// Query
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKey } from 'react-query/queryKey';

// snkabar
import { useSnackbar } from 'notistack';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import {  updateDevice } from 'clients/vehicle';

const GetDevicesDetailsInfoHeader = ({
  selectedDevicesData,
  handleDeleteClick,
  handleEditClick,
  onlyGetRequestAllowed,
  isDataLoading,
  filledRows,
  devicesSerial,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const toggleDeviceStatusHandler = async (selectedDevicesData) => {
    const request_schema = {
      ...selectedDevicesData,
      status: selectedDevicesData.status === 'active' ? 'inactive' : 'active',
    };
    console.log('request_schema', request_schema);

    try {
      await updateDeviceMutation.mutateAsync({ request_schema });
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    }
  };

  const updateDeviceMutation = useMutation({
    mutationFn: (body) => {
      return updateDevice(body.request_schema);
    },
    onSuccess: () => {
      enqueueSnackbar('Device updated successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries([queryKey.DEVICE.ALL_DEVICES]);
    },
    onError: (error) => {
      // enqueueSnackbar(getErrorMessage(error), {
      //   variant: 'error',
      // });
    },
  });

  if (!selectedDevicesData) return null;
  const { status } = selectedDevicesData;
  return (
    <div>
      {' '}
      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <QuickViewActionsTitleV2
            isLoading={isDataLoading}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            isSubmitting={onlyGetRequestAllowed}
          >
            <Typography variant='h3' noWrap textTransform={'uppercase'}>
              {selectedDevicesData.device_type}
            </Typography>
          </QuickViewActionsTitleV2>
        </Grid>
        <Grid item xs={12} sx={{ my: 3 }}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <GetStatusAndLastUpdatedTime
            status={selectedDevicesData.status === 'active' ? 'Active' : 'Inactive'}
            lastUpdatedTime={`Last Update: ${longDateFormatter(
              selectedDevicesData?.last_updated_timestamp,
              true,
            )}`}
            isDataLoading={isDataLoading}
          />
          <QuickViewActionItem
            sx={{ my: 1 }}
            isIcon={true}
            id='active_disabled_device_button'
            size={'small'}
            color={status === 'active' ? 'success' : 'error'}
            description={'Toggle device status'}
            tooltipContent={status === 'active' ? 'Active' : 'Disabled'}
            isLoading={isDataLoading}
            startIcon={status === 'active' ? <DoneIcon /> : <NotInterestedIcon />}
            loaderButton={true}
            disabled={updateDeviceMutation.isLoading || updateDeviceMutation.isFetching}
            loading={updateDeviceMutation.isLoading || updateDeviceMutation.isFetching}
            loadingPosition='start'
            buttonName={status === 'active' ? 'Active' : 'Disabled'}
            handleClick={() => toggleDeviceStatusHandler(selectedDevicesData)}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, mb: 1 }}>
          <Divider light />
        </Grid>
      </Grid>
    </div>
  );
};

export default GetDevicesDetailsInfoHeader;
