import {
  SAVED_SEARCH_FORM_DIALOG,
  SAVED_SEARCH_DATA_REQUEST,
} from '../reduxConstants/savedConstants';

export const savedSearchFormDialogReducer = (
  state = { isSavedSearchFormDialogOpen: false },
  action,
) => {
  switch (action.type) {
    case SAVED_SEARCH_FORM_DIALOG:
      return { ...state, isSavedSearchFormDialogOpen: action.payload };
    default:
      return state;
  }
};

export const savedSearchDataReducer = (state = { searchData: {} }, action) => {
  switch (action.type) {
    case SAVED_SEARCH_DATA_REQUEST:
      return { ...state, searchData: action.payload };
    default:
      return state;
  }
};
