import React from 'react';
import Chip from '@mui/material/Chip';

export default function StatusInformationChip({ status = '' }) {
  return (
    <Chip
      label={status}
      color={
        status === 'Active'
          ? 'primary'
          : status === 'Completed'
          ? 'success'
          : status === 'Pending'
          ? 'secondary'
          : 'error'
      }
      variant='outlined'
      size='small'
    />
  );
}
