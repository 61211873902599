import React from 'react';

// material ui
import { Grid, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

function MainSkeletonComponent(props) {
  const { classes } = props;
  return (
    <Grid container item xs={12} justifyContent='center' alignItems='center'>
      <Skeleton animation='wave' variant='rectangular' height={122} className={classes.large} />
    </Grid>
  );
}

function DataComponent(props) {
  const { classes } = props;
  return (
    <>
      <Grid container item xs={12} justifyContent='center' alignItems='center'>
        <Avatar
          src={props.rowData?.profile_image && props.rowData?.profile_image.url}
          className={classes.large}
        />
      </Grid>
    </>
  );
}

export default function DriverImage(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {props.rowData ? (
        <DataComponent classes={classes} {...props} />
      ) : (
        <MainSkeletonComponent classes={classes} />
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    marginBottom: 5,
  },
}));
