import axios from 'axios';
const NOMINATIM_URL = 'https://nominatim.openstreetmap.org?addressdetails=1&';
const NOMINATIM_REVERSE_URL = 'https://nominatim.openstreetmap.org';

export const NOMINATIM_URL_GEO = `${NOMINATIM_URL}/search`;
export const NOMINATIME_URL_REVERSE = `${NOMINATIM_REVERSE_URL}/reverse`;

export const searchAddress = (userInput) =>
  axios.get(NOMINATIM_URL_GEO, {
    params: {
      // eslint-disable-next-line
      q: userInput,
      format: 'json',
      limit: 5,
    },
  });

export const reverse_geocode = (lon, lat) =>
  axios.get(NOMINATIME_URL_REVERSE, {
    params: {
      lat: lat,
      lon: lon,
      format: 'json',
    },
  });

export const parseGeocodeResponse = (results, lngLat) => {
  if (!(Object.prototype.toString.call(results) === '[object Array]')) {
    results = [results];
  }
  const processedResults = [];
  for (const [index, result] of results.entries()) {
    if ('error' in result && result.error.toLowerCase() === 'unable to geocode') {
      processedResults.push({
        title: lngLat.toString(),
        description: '',
        selected: true,
        addresslnglat: '',
        sourcelnglat: lngLat,
        displaylnglat: lngLat,
        key: index,
        addressindex: index,
      });
    } else {
      processedResults.push({
        title: result.display_name.length > 0 ? result.display_name : lngLat.toString(),
        description: `https://www.openstreetmap.org/${result.osm_type}/${result.osm_id}`,
        selected: false,
        addresslnglat: [parseFloat(result.lon), parseFloat(result.lat)],
        sourcelnglat:
          lngLat === undefined ? [parseFloat(result.lon), parseFloat(result.lat)] : lngLat,
        displaylnglat:
          lngLat !== undefined ? lngLat : [parseFloat(result.lon), parseFloat(result.lat)],
        key: index,
        addressindex: index,
      });
    }
  }
  return processedResults;
};
