import {
  createCommunication,
  getCommunication,
  updateCommunication,
  createCommunicationTemplate,
  getCommunicationTemplate,
  updateCommunicationTemplate,
} from './communications';

import { createSession, getSession, updateSession, deleteSession, getSessionList } from './sessions';

export const API_ID = 'SpotBusAPI';

export {
  createCommunication,
  getCommunication,
  updateCommunication,
  createCommunicationTemplate,
  getCommunicationTemplate,
  updateCommunicationTemplate,
  createSession,
  getSession,
  updateSession,
  deleteSession,
  getSessionList
};
