import { InputAdornment, Stack, Tooltip } from '@mui/material';
import { Field, FieldArray } from 'formik';
import React from 'react';
import { Select, TextField } from 'formik-mui';
import { TimePickerFieldArray } from 'components/Formik';
import { DefaultButton, IcoButton } from 'components/Buttons';
import { monthlyOccurrenceInitialValues } from '../formInitialValuesDriverShifts';

// icons
import RestoreIcon from '@mui/icons-material/Restore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MainCard from 'components/MainCard';
const MonthlySelection = ({ formikProps, variant }) => {
  const { values } = formikProps;
  // monthlyDate
  return (
    <Stack sx={{ minHeight: '100%' }}>
      <FieldArray
        name='monthlyDate'
        render={(arrayHelpers) => (
          <Stack>
            {values.monthlyDate && values.monthlyDate.length > 0 ? (
              values.monthlyDate.map((data, index) => (
                <MainCard content={false} sx={{ pt: 4, pb: 3, px: 2, mb: 2 }}>
                  <Stack
                    key={index}
                    direction='row'
                    justifyContent='space-between'
                    // alignItems='center'
                    width={'100%'}
                    spacing={3}
                  >
                    <Stack width={'30%'}>
                      <Field
                        name={`monthlyDate.[${index}].startDateMonthly`}
                        type='number'
                        variant={variant}
                        helperText='Select Date'
                        component={TextField}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder='dd'
                        disablePast
                        label='Date *'
                      />
                    </Stack>

                    <Stack width={'34%'}>
                      <Field
                        variant={variant}
                        name={`monthlyDate.[${index}].clockIn`}
                        helperText='Enter time'
                        component={TimePickerFieldArray}
                        disabled={formikProps.isSubmitting}
                        // component={TimePickerField}
                        label='Shift start time *'
                        index={index}
                        icon={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <RestoreIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack width={'34%'}>
                      <Field
                        variant={variant}
                        name={`monthlyDate.[${index}].clockOut`}
                        helperText='Enter time'
                        component={TimePickerFieldArray}
                        disabled={formikProps.isSubmitting}
                        // component={TimePickerField}
                        label='Shift end time *'
                        index={index}
                      />
                    </Stack>
                    <Stack direction='row' alignItems={'flex-start'}>
                      <IcoButton
                        onClick={() => arrayHelpers.remove(index)}
                        icon={
                          <Tooltip title='Remove Date Occurrence'>
                            <RemoveIcon />
                          </Tooltip>
                        }
                        color='primary'
                        disabled={values.monthlyDate.length < 2}
                      />

                      <IcoButton
                        type='button'
                        onClick={() => arrayHelpers.push(monthlyOccurrenceInitialValues[0])}
                        disabled={values.monthlyDate.length >= 31}
                        icon={
                          <Tooltip title='Add Date Occurrence'>
                            <AddIcon />
                          </Tooltip>
                        }
                        color='primary'
                      />
                    </Stack>
                  </Stack>
                </MainCard>
              ))
            ) : (
              <DefaultButton
                type='button'
                onClick={() => arrayHelpers.push('')}
                startIcon={<AddIcon />}
              >
                Add Date Occurrence
              </DefaultButton>
            )}
          </Stack>
        )}
      />
    </Stack>
  );
};

export default MonthlySelection;
