import React, { useState } from 'react';

// Material UI
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';

// Formik
import { useFormikContext } from 'formik';

const TimePickerFieldArray = ({
  field,
  variant,
  label,
  form,
  helperText,
  meta,
  icon,
  ...other
}) => {
  const { name, value } = field;
  const { index } = other;
  const [mainFieldName, fieldIndex, childrenFieldName] = name.split('.');
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();

  /**
   * Check if a value is a valid JavaScript Date object
   * @param {*} value - The value to check
   * @returns {boolean} - True if the value is a valid Date object, false otherwise
   */
  const isValidDate = (value) => {
    // Check if the value is a Date object and the toString() result is not "Invalid Date"
    return value instanceof Date && !isNaN(value);
  };

  /**
   * Determines if there is an error.
   *
   * @return {boolean} True if there is an error, false otherwise.
   */
  const isError = () => {
    try {
      return errors[mainFieldName][index][childrenFieldName];
    } catch (errors) {
      return false;
    }
  };
  /**
   * Handles the change of time.
   *
   * @param {Date} date - The selected date and time.
   * @return {void} This function does not return anything.
   */
  const handleTimeChange = (date) => {
    setFieldValue(name, date);
    // setFieldTouched(name, true, false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        fullWidth
        id='time-picker'
        value={value}
        onChange={handleTimeChange}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <TextField
            inputProps={inputProps}
            inputRef={inputRef}
            label={label}
            {...InputProps}
            error={isError()}
            helperText={isError() ? isError() : helperText}
            variant={variant}
            fullWidth
            InputLabelProps={{
              shrink: true,
              error: isError(),
            }}
            InputProps={{
              endAdornment: <React.Fragment>{InputProps?.endAdornment}</React.Fragment>,
            }}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
};

export default TimePickerFieldArray;
