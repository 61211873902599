import React, { useState } from 'react';

// third party
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

// mui
import {
  InputAdornment,
  Paper,
  Typography,
  Grid,
  Stack,
  Link,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// components
import { ActionButton } from 'components/Buttons';

// assets
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const emailIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const validationSchema = yup.object({
  emailUser: yup
    .string()
    .email('Invalid email address')
    .trim('')
    .required('Please enter your email'),
  password: yup
    .string()
    .required('Please enter your password')
    .trim()
    .min(8, 'Password must be at least 8 characters'),
});

function SignIn({ setAuthPage, signIn, loading }) {
  const [viewPassword, setViewPassword] = useState('password');

  const formInitialValues = () => {
    return {
      emailUser: '',
      password: '',
    };
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSubmit = (values) => {
    signIn(values.emailUser, values.password);
  };

  const handleAuthForm = () => {
    setAuthPage('signUp');
  };

  const handleAuthFormForgot = () => {
    setAuthPage('forgotPassword');
  };

  const handleViewPassword = () => {
    setViewPassword(viewPassword === 'text' ? 'password' : 'text');
  };

  const handleKeyPress = (event, handleSubmission) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmission();
    }
  };

  const passwordIcon = {
    startAdornment: (
      <InputAdornment position='start'>
        <HttpsIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position='end'>
        <Tooltip
          placement='right'
          title={viewPassword === 'password' ? 'Show password' : 'Hide password'}
        >
          <IconButton onClick={() => handleViewPassword()} edge='end'>
            {viewPassword === 'password' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='signIn'>
          <Paper
            sx={{
              p: 5,
              width: 400,
              borderRadius: 4,
              boxShadow: 10,
            }}
          >
            <Typography sx={{ mt: 2, mb: 10 }} align='flex-start' variant='h3'>
              Sign in to your account
            </Typography>
            <Stack spacing={3}>
              <Field
                fullWidth
                hiddenLabel
                variant='filled'
                component={TextField}
                placeholder='Email'
                name='emailUser'
                id='emailUser'
                InputProps={emailIcon}
                disabled={false}
                helperText=' '
                onKeyPress={(event) => handleKeyPress(event, formikProps.handleSubmit)}
              />
              <Field
                fullWidth
                hiddenLabel
                component={TextField}
                variant='filled'
                placeholder='Password'
                name='password'
                id='password'
                type={viewPassword}
                InputProps={passwordIcon}
                disabled={false}
                helperText=' '
                onKeyPress={(event) => handleKeyPress(event, formikProps.handleSubmit)}
              />
            </Stack>
            <Grid container justifyContent={'space-between'}>
              <FormControlLabel control={<Checkbox defaultChecked />} label='Stay signed in' />
              <Link component='button' variant='body1' onClick={handleAuthFormForgot}>
                Forgot your password?
              </Link>
            </Grid>
            <ActionButton
              disabled={!formikProps.dirty}
              fullWidth
              sx={{ mt: 10, mb: 4 }}
              id='save'
              size={'large'}
              type='submit'
              startIcon={false}
              variant='contained'
              isLoading={loading}
            >
              Continue
            </ActionButton>
            {/* <Typography sx={{ mt: 6, mb: 4 }} align='center' variant='body2'>
              Don't have an account?{' '}
              <Link component='button' variant='body2' sx={{ ml: 1 }} onClick={handleAuthForm}>
                Sign up
              </Link>
            </Typography> */}
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
