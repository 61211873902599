import * as React from 'react';

// material-ui imports
import Box from '@mui/material/Box';
import { Drawer, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, useTheme } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

// project imports
import { drawerWidth } from 'constants';

// assets
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import DoneIcon from '@mui/icons-material/Done';

const drawerStyling = {
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    border: 'none',
    width: drawerWidth,
  },
};

export default function DrawerTimeline(props) {
  // Getting props from main file
  const { activeStep } = props;

  const handleTimelineItemClick = () => {};
  return (
    <Drawer variant='permanent' anchor='left' open={true} sx={drawerStyling}>
      <Box display='flex' flex={1} alignItems='center'>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  bgcolor: `${activeStep === 0 ? 'primary.main' : 'primary.main'}`,
                  width: '3px',
                }}
              />
              <TimelineDot
                sx={{
                  bgcolor: `${activeStep === 0 ? 'primary.main' : 'primary.main'}`,
                  boxShadow: '0 4px 6px 0 rgba(0,0,0,.5)',
                }}
              >
                {activeStep === 0 ? <SettingsIcon /> : <DoneIcon />}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  bgcolor: `primary.main`,
                  width: '3px',
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 12, px: 2 }}>
              <Typography variant='h6'>Configure Students</Typography>
            </TimelineContent>
          </TimelineItem>
          {/* <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  bgcolor: `${activeStep === 1 && 'primary.main'}`,
                  width: '3px',
                }}
              />
              <TimelineDot
                sx={{
                  bgcolor: `${activeStep === 1 && 'primary.main'}`,
                  boxShadow: `${activeStep === 1 && '0 4px 6px 0 rgba(0,0,0,.5)'}`,
                }}
              >
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector
                sx={{
                  bgcolor: `${activeStep === 1 && 'primary.main'}`,
                  width: '3px',
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 12, px: 2 }}>
              <Typography variant='h6' component='span'>
                Configure Routes
              </Typography>
            </TimelineContent>
          </TimelineItem> */}
        </Timeline>
      </Box>
    </Drawer>
  );
}
