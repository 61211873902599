import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';

import { getErrorFields, getChildValidationStatus } from './HelperFunctions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  typoMargin: {
    marginLeft: theme.spacing(3),
  },
  cardHeader: {
    position: 'sticky',
  },
  weight: {
    fontWeight: '500',
  },
}));

export function ChildsValidationListView(props) {
  const classes = useStyles();
  // student details column controller
  const cOne = 5;
  const cTwo = 7;
  return (
    <div>
      <Paper className={clsx(classes.paper, classes.cardHeader)}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={1}>
            <Typography variant='h6' gutterBottom>
              S.No
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h6' gutterBottom>
              Student Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant='h6' gutterBottom>
              Status
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {props.data.validation_result.map((student, index) => (
        <Paper className={classes.paper} key={index}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={1}>
              <Typography gutterBottom>{index + 1}</Typography>
            </Grid>
            <Grid item xs={10} className={clsx(classes.column, classes.helper)}>
              <Grid container direction='column'>
                <Grid container direction='row' className={classes.divider}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>First Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='firstName'>
                        {getErrorFields(student.fields.first_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Last Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='lastName'>
                        {getErrorFields(student.fields.last_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Phone Number:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='parentId'>
                        {getErrorFields(student.fields.phone_number)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='row' className={classes.divider}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Student Grade:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='firstName'>
                        {getErrorFields(student.fields.grade)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Student Email:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='lastName'>
                        {getErrorFields(student.fields.email)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Address:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='parentId'>
                        {getErrorFields(student.fields.address)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Divider className={classes.divider} variant="middle" /> */}
                <Grid container direction='row' className={classes.divider}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Gender:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='specialNeeds'>
                        {getErrorFields(student.fields.gender)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>School ID:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='schoolId'>
                        {getErrorFields(student.fields.school_id)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Father's First Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='fathers_firstName'>
                        {getErrorFields(student.fields.fathers_first_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='row' className={classes.divider}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Father's Last Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='fathers_lastName'>
                        {getErrorFields(student.fields.fathers_last_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Mother's First Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='mothers_firstName'>
                        {getErrorFields(student.fields.mothers_first_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Mother's Last Name:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='mothers_lastName'>
                        {getErrorFields(student.fields.mothers_last_name)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Divider className={classes.divider} variant="middle" /> */}
                <Grid container direction='row' className={classes.divider}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Special Needs:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='specialNeeds'>
                        {getErrorFields(student.fields.special_needs)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>Driver Notes:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='driverNotes'>
                        {getErrorFields(student.fields.driver_notes)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={cOne}>
                        <Typography>School Notes:</Typography>
                      </Grid>
                      <Grid item xs={cTwo} id='schoolNotes'>
                        {getErrorFields(student.fields.school_notes)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className={clsx(classes.column, classes.helper)} id='isValid'>
              {getChildValidationStatus(student.is_valid)}
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
}
