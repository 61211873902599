import React from 'react';
import PropTypes from 'prop-types';

// MUI imports
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// components
import { DefaultButton } from 'components/Buttons';

// assets
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// skeleton component
function QuickViewTitleSkeleton({ variant, ...props }) {
  return (
    <Typography component='div' key={variant} variant={variant}>
      <Skeleton {...props} />
    </Typography>
  );
}

function QuickViewActionSkeleton({ variant, ...props }) {
  return <Skeleton variant='rounded' width={210} height={60} />;
}

// ============================== Actions Title - Quick View  ============================== //
function QuickViewActionsTitleV2({
  children,
  isLoading,
  isSubmitting,
  handleEditClick,
  handleDeleteClick,
  hideEdit,
  hideDelete,
  customAction,
}) {
  // showing skeleton in case of loading
  if (isLoading) return <QuickViewTitleSkeleton variant='h3' />;

  // handling case if loading is false
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item xs zeroMinWidth>
        {children}
      </Grid>
      {!hideEdit && (
        <Grid item xs='auto'>
          <DefaultButton
            startIcon={<EditIcon />}
            onClick={handleEditClick}
            disabled={isSubmitting}
            color='secondary'
          >
            Edit
          </DefaultButton>
        </Grid>
      )}
      {!hideDelete && (
        <Grid item xs='auto'>
          <DefaultButton
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick}
            disabled={isSubmitting}
            color='error'
          >
            Delete
          </DefaultButton>
        </Grid>
      )}
      {customAction && (
        <Grid item xs='auto'>
          {customAction}
        </Grid>
      )}
    </Grid>
  );
}

QuickViewActionsTitleV2.propTypes = {
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool,
  customAction: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

QuickViewActionsTitleV2.defaultProps = {
  isLoading: false,
  isSubmitting: false,
  handleEditClick: () => console.log('Edit action button clicked'),
  handleDeleteClick: () => console.log('Delete action button clicked'),
  hideEdit: false,
  hideDelete: false,
  customAction: false,
};

export default QuickViewActionsTitleV2;
