import SpotBusMapStyles from "./SpotBusMapStyles";

const SB_MAP_OPTIONS = {
  fullscreenControl: true,
  mapTypeControl: true,
  scaleControl: true,
  scrollwheel: false,
  streetViewControl: true,
  styles: SpotBusMapStyles,
};

export default SB_MAP_OPTIONS;

export const SHORT_MAP_OPTIONS = {
  fullscreenControl: false,
  mapTypeControl: false,
  scaleControl: true,
  scrollwheel: false,
  streetViewControl: false,
  styles: SpotBusMapStyles,
};
