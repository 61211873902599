import React from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import { BackButton, NextButton } from 'components/Buttons';

// assets
import AssignmentIcon from '@mui/icons-material/Assignment';

import SchoolIcon from '@mui/icons-material/School';
import { ClassificationHeading } from 'components/Headers';
import { useDashboardUsers } from 'hooks/api/useDashboardUsers';

const ROLE = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SCHOOL_LEVEL_ACCESS: 'ROLE_SCHOOL_LEVEL_ACCESS',
  ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS: 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS',
  DRIVER_APP: 'DRIVER_APP',
};

const PermissionDriver = (props) => {
  const {
    permissionType,
    setPermissionType,
    permitSchools,
    setPermitSchools,
    driverAppPermit,
    setDriverAppPermit,
  } = props.permissionProps;

  const { users, isLoadingUsers } = useDashboardUsers();

  const handlePermissionChange = (value) => {
    setPermissionType(value);
    if (
      value === ROLE.ROLE_SCHOOL_LEVEL_ACCESS ||
      value === ROLE.ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS
    ) {
      setPermitSchools([props.schoolsData[0].school_id]);
    } else if (value === ROLE.ROLE_ADMIN) {
      setDriverAppPermit(true);
      setPermitSchools([]);
    } else {
      setDriverAppPermit(false);
    }
  };

  function handleSchoolClick(id) {
    if (!permitSchools.includes(id)) {
      setPermitSchools([...permitSchools, id]);
    } else {
      const temp = permitSchools.filter((el) => el !== id);
      if (temp.length === 0) {
        return;
      }
      setPermitSchools(temp);
    }
  }

  const handleDriverAppPermission = (value) => {
    setDriverAppPermit((pre) => !pre);
  };

  const handleBack = () => {
    props.setActiveStep((pre) => pre - 1);
  };

  if (isLoadingUsers) {
    return <div>Loading...</div>;
  }
  if (ROLE.ROLE_SUPER_ADMIN === permissionType) {
    return (
      <Stack height={500} direction={'column'} justifyContent={'space-between'}>
        <Chip label='Super Admin' color='primary' />
        <Stack direction='row' justifyContent='space-between'>
          {!props.isFromGuided ? (
            <Grid>
              <BackButton isIcon={true} size='medium' id='driver_cancel' onClick={handleBack}>
                Back
              </BackButton>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          <Grid>
            <Grid container>
              <NextButton
                onClick={() => props.setActiveStep((pre) => pre + 1)}
                id='driver_save_button'
                size='medium'
                type='submit'
              >
                Next
              </NextButton>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{ width: '100%', height: 510 }}
      direction={'column'}
      justifyContent={'space-between'}
    >
      <Stack spacing={3}>
        {/* <AlertWindow open={open} setOpen={setOpen} createdUserData={props.createdUserData} /> */}
        <ClassificationHeading> Dashboard Permissions</ClassificationHeading>

        <Stack direction='row' spacing={3}>
          <RoleChip
            handlePermissionChange={(e) => handlePermissionChange(ROLE.ROLE_ADMIN)}
            permissionType={permissionType}
            permission={ROLE.ROLE_ADMIN}
            label='Full access/Admin'
            icon={<AssignmentIcon />}
          />

          <RoleChip
            handlePermissionChange={(e) => handlePermissionChange(ROLE.ROLE_SCHOOL_LEVEL_ACCESS)}
            permissionType={permissionType}
            permission={ROLE.ROLE_SCHOOL_LEVEL_ACCESS}
            label='School level access'
            icon={<AssignmentIcon />}
          />

          <RoleChip
            handlePermissionChange={(e) =>
              handlePermissionChange(ROLE.ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS)
            }
            permissionType={permissionType}
            permission={ROLE.ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS}
            label='School level read only access'
            icon={<AssignmentIcon />}
          />
        </Stack>
        <ClassificationHeading> Select Schools</ClassificationHeading>

        <ShowSchools
          permissionType={permissionType}
          permitSchools={permitSchools}
          setPermitSchools={ROLE.DRIVER_APP}
          handleSchoolClick={handleSchoolClick}
          {...props}
        />
        <ClassificationHeading>Driver App Permissions</ClassificationHeading>
        <Stack direction='row' spacing={3}>
          <Chip
            sx={{ p: 0 }}
            variant={driverAppPermit ? 'default' : 'outlined'}
            icon={<AssignmentIcon />}
            clickable
            label='Driver App permissions'
            color={driverAppPermit ? 'primary' : 'default'}
            onClick={(e) => handleDriverAppPermission()}
          />
        </Stack>
      </Stack>

      <Stack direction='row' justifyContent='space-between'>
        {!props.isFromGuided ? (
          <Grid>
            <BackButton isIcon={true} size='medium' id='driver_cancel' onClick={handleBack}>
              Back
            </BackButton>
          </Grid>
        ) : (
          <Grid></Grid>
        )}
        <Grid>
          <Grid container>
            <NextButton
              onClick={() => props.setActiveStep((pre) => pre + 1)}
              id='driver_save_button'
              size='medium'
              type='submit'
            >
              Next
            </NextButton>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default PermissionDriver;

const ShowSchools = ({
  permissionType,
  permitSchools,
  setPermitSchools,
  handleSchoolClick,
  ...props
}) => {
  return (
    <Stack direction='row' spacing={3}>
      {props.schoolsData.map((school, index) => {
        const getPermission = () => {
          try {
            return permitSchools?.includes(school?.school_id) ? permissionType : '';
          } catch (error) {
            return [];
          }
        };
        return (
          <RoleChip
            disabled={permissionType === ROLE.ROLE_ADMIN}
            key={school.school_id}
            handlePermissionChange={(e) => handleSchoolClick(school.school_id)}
            permissionType={permissionType}
            permission={getPermission()}
            label={school.school_name}
            icon={<SchoolIcon />}
          />
        );
      })}
    </Stack>
  );
};

const RoleChip = ({
  permissionType,
  handlePermissionChange,
  permission,
  label = '',
  icon,
  disabled = false,
}) => {
  return (
    <Chip
      disabled={disabled}
      sx={{ p: 0 }}
      variant={permissionType === permission ? 'default' : 'outlined'}
      icon={icon}
      clickable
      label={label}
      color={permissionType === permission ? 'primary' : 'default'}
      onClick={handlePermissionChange}
    />
  );
};
