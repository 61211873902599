import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { FormHeader } from 'components/SectionForms';
export const CustomizedDialog = ({
  openDialog,
  handleCloseDialog,
  title,
  disableClose,
  icon,
  children,
  isActionButtonRequired = false,
  actionButtons,
  ...props
}) => {
  return (
    <div>
      <Dialog open={openDialog} aria-labelledby='form-dialog-main' fullWidth={true} {...props}>
        <DialogTitle id='form-dialog-title' sx={{ p: 0, ml: 2 }}>
          <FormHeader
            id={title}
            title={title}
            onClose={handleCloseDialog}
            disableClose={disableClose}
            icon={icon}
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ m: 2, p: 0 }}>{children}</DialogContent>
        {isActionButtonRequired && <DialogActions sx={{ mb: 2 }}>{actionButtons}</DialogActions>}
      </Dialog>
    </div>
  );
};
