import { useState } from 'react';

// mui
import { InputAdornment, Paper, Typography, Grid, Stack, Link, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// third party
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

// project imports
import { defaultCountry } from 'utils';

// components
import { ActionButton } from 'components/Buttons';

// assets
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import GenderChip from './components/GenderChip';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CountrySelect } from 'components/Formik';

const firstLastNameIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const phoneIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <PhoneAndroidIcon />
    </InputAdornment>
  ),
};

const organizationIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <DomainIcon />
    </InputAdornment>
  ),
};

const addressIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <LocationOnIcon />
    </InputAdornment>
  ),
};

const emailIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const phoneRegExp = /^\d{10}$/;

function SignUp({ setAuthPage, signUp, loading }) {
  const [viewPassword, setViewPassword] = useState('password');
  const [viewConfirmPassword, setViewConfirmPassword] = useState('password');

  const formInitialValues = () => {
    return {
      firstName: '',
      lastName: '',
      emailUser: '',
      password: '',
      confirmPassword: '',
      gender: '',
      phone_country_code: defaultCountry(),
      phone: '',
      organization: '',
      address: '',
    };
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required('Please enter first name')
      .trim()
      .min(3, 'First name must be at least 3 characters'),
    lastName: yup
      .string()
      .required('Please enter last name')
      .trim()
      .min(3, 'Last name must be at least 3 characters'),
    emailUser: yup
      .string()
      .email('Invalid email address')
      .trim('')
      .required('Please enter your email'),
    password: yup
      .string()
      .required(
        'Password combination of a capital and lowercase letter, a number, and a special character',
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least 8 characters including a capital and lowercase letter, a number, and a special character',
      )
      .trim(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords did not match')
      .trim()
      .required('Please re-enter your password'),
    gender: yup
      .string()
      .required('Please select one option')
      .trim()
      .min(1, 'Gender must be at least 4 characters'),
    phone: yup
      .string()
      .required('Enter a valid phone number')
      .min(10, 'Invalid phone number')
      .matches(phoneRegExp, 'Invalid phone number'),
    phone_country_code: yup.mixed().required('Required field'),
    organization: yup
      .string()
      .required('Please enter your organization name')
      .trim()
      .min(3, 'Organization name must be at least 3 characters'),
    address: yup
      .string()
      .required('Please enter organization address')
      .trim()
      .min(3, 'Organization address must be at least 3 characters'),
  });

  const handleSubmit = (values) => {
    signUp(values);
  };

  const handleAuthForm = () => {
    setAuthPage('logIn');
  };

  const handleViewPassword = (field) => {
    if (field === 'password') {
      setViewPassword(viewPassword === 'text' ? 'password' : 'text');
    }
    if (field === 'confirmPassword') {
      setViewConfirmPassword(viewConfirmPassword === 'text' ? 'password' : 'text');
    }
  };

  const passwordIcon = {
    startAdornment: (
      <InputAdornment position='start'>
        <HttpsIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position='end'>
        <Tooltip
          placement='right'
          title={viewPassword === 'password' ? 'Show password' : 'Hide password'}
        >
          <IconButton onClick={() => handleViewPassword('password')} edge='end'>
            {viewPassword === 'password' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  const confirmPasswordIcon = {
    startAdornment: (
      <InputAdornment position='start'>
        <HttpsIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position='end'>
        <Tooltip
          placement='right'
          title={viewConfirmPassword === 'password' ? 'Show password' : 'Hide password'}
        >
          <IconButton onClick={() => handleViewPassword('confirmPassword')} edge='end'>
            {viewConfirmPassword === 'password' ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Form id='signIn'>
          <Paper
            sx={{
              p: 5,
              width: 740,
              borderRadius: 4,
              boxShadow: 10,
            }}
          >
            <Typography variant='h3' sx={{ mb: 6 }}>
              Create your SpotBus account
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='First name'
                  name='firstName'
                  id='firstName'
                  InputProps={firstLastNameIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Last name'
                  name='lastName'
                  id='lastName'
                  InputProps={firstLastNameIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Email address'
                  name='emailUser'
                  id='emailUser'
                  InputProps={emailIcon}
                  disabled={false}
                  autoComplete='new-password' // disable autocomplete and autofill
                  helperText=' '
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} justifyContent={'space-between'}>
                  <Grid item xs={3}>
                    <CountrySelect
                      fullWidth
                      hiddenLabel
                      variant='filled'
                      name='phone_country_code'
                      placeholder='Country code'
                      id='country_code'
                      type='phone'
                      countryId='country_code_select'
                      helperTextContent=' '
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      fullWidth
                      hiddenLabel
                      variant='filled'
                      component={TextField}
                      placeholder='Phone number'
                      name='phone'
                      id='phone'
                      InputProps={phoneIcon}
                      disabled={false}
                      helperText=' '
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={GenderChip}
                  label='Gender'
                  name='gender'
                  id='gender'
                  disabled={false}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Password'
                  name='password'
                  id='password'
                  type={viewPassword}
                  InputProps={passwordIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Confirm password'
                  name='confirmPassword'
                  id='confirmPassword'
                  type={viewConfirmPassword}
                  InputProps={confirmPasswordIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Organization name'
                  name='organization'
                  id='organization'
                  InputProps={organizationIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  hiddenLabel
                  variant='filled'
                  component={TextField}
                  placeholder='Organization address'
                  name='address'
                  id='address'
                  InputProps={addressIcon}
                  disabled={false}
                  helperText=' '
                />
              </Grid>
            </Grid>

            <ActionButton
              disabled={!formikProps.dirty}
              fullWidth
              sx={{ mt: 6 }}
              id='save'
              size={'Large'}
              type='submit'
              startIcon={false}
              variant='contained'
              isLoading={loading}
            >
              Create Account
            </ActionButton>
            <Typography sx={{ mt: 6, mb: 4 }} align='center' variant='body2'>
              Already have an account?{' '}
              <Link component='button' variant='body2' onClick={handleAuthForm}>
                Sign in
              </Link>
            </Typography>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
