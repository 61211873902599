import React from 'react';

// Material UI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import MainCard from 'components/MainCard';

export default function RightSideCard(props) {
  // Props from Main Configure Student File
  const {
    firstMainHeadLine,
    firtsParagraph,
    secondMainHeadine,
    secondParagraph,
    firstImage,
    secondImage,
  } = props;

  return (
    <MainCard>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia component='img' height='100' image={firstImage} alt='Bult Import Student' />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {firstMainHeadLine}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {firtsParagraph}
          </Typography>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: 345, mt: 2 }}>
        <CardMedia component='img' height='100' image={secondImage} alt='Add Student Manually ' />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {secondMainHeadine}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {secondParagraph}
          </Typography>
        </CardContent>
      </Card>
    </MainCard>
  );
}
