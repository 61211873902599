import React from 'react';
import { Grid, Chip } from '@mui/material';

import LightTooltip from '../../Utils/LightTooltip';

export default function MapChip(props) {
  return (
    <>
      <Grid container direction='row' style={{ marginBottom: '10px' }}>
        <Grid item xs={6} md={6} align='center'>
          <LightTooltip
            title={
              props.isActaulDataPresent
                ? 'Yellow dots on Polyline show Actual Path'
                : 'Data is not Available'
            }
            placement='bottom'
            arrow
          >
            <Chip
              id='required'
              label='Actual Path'
              clickable
              onClick={() => props.setShowActualPath()}
              variant={props.showActualPath ? 'default' : 'outlined'}
              color={props.showActualPath ? 'primary' : 'default'}
            />
          </LightTooltip>
        </Grid>
        <Grid item xs={6} md={6} align='center'>
          <LightTooltip title='Black lines on Map show Planned Path' placement='bottom' arrow>
            <Chip
              id='required'
              label='Planned Path'
              clickable
              onClick={() => props.setShowPlannedPath()}
              variant={props.showPlannedPath ? 'default' : 'outlined'}
              color={props.showPlannedPath ? 'primary' : 'default'}
            />
          </LightTooltip>
        </Grid>
      </Grid>
    </>
  );
}
