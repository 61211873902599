import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import LoadingButton from '@mui/lab/LoadingButton';

// assets
import SendIcon from '@mui/icons-material/Send';

// ============================== STUDENT FORM - MAIN  ============================== //

function SendButton({ children, ...props }) {
  return (
    <LoadingButton {...props} startIcon={<SendIcon />} loadingPosition='start' type='submit'>
      {children.toUpperCase()}
    </LoadingButton>
  );
}

SendButton.defaultProps = {
  variant: 'contained',
  size: 'large',
  color: 'primary',
};
SendButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string,
};

export default SendButton;
