import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function NoRecordDisplay(props) {
  const { paddingTop = '8%' } = props;
  return (
    <Grid>
      <Grid item xs={12}>
        <img
          alt='Not found image'
          id='not-found-page'
          src='/static/images/icons/noData.png'
          style={{
            width: props.imageWidth === 'Details' ? '60%' : '30%',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            paddingTop: paddingTop,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography align='center' variant='h4'>
          No records to display
        </Typography>
      </Grid>
    </Grid>
  );
}
