import { getMonth, getYear, isAfter, isToday, isValid } from 'date-fns';
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFormattedSessionStatus(sessionStatus = undefined) {
  if (!sessionStatus && typeof sessionStatus !== 'string') return '--';

  return capitalizeFirstLetter(sessionStatus === 'in_progress' ? 'In Progress' : sessionStatus);
}

function getSessionStatusColor(status = undefined) {
  if (!status && typeof status !== 'string') return 'secondary';

  switch (status) {
    case 'active':
      return 'success';
    case 'closed':
      return 'error';
    case 'draft':
      return 'secondary';
    case 'testing':
      return 'primary';
    case 'pending':
      return 'secondary';
    case 'in_progress':
      return 'warning';
    default:
      return 'secondary';
  }
}

function getAvailableWeekdays(availabilityArray) {
  const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const availableWeekdays = [];

  WEEK_DAYS.forEach((day, index) => {
    if (availabilityArray[index]) {
      availableWeekdays.push(day);
    }
  });

  return availableWeekdays;
}

function ConvertedSchoolScheduleDays(values = undefined) {
  const convertedScheduleValues = values?.map((ele) => ({
    ...ele,
    days_of_week: getAvailableWeekdays(ele.days_of_week),
  }));

  const filterScheduleValues = convertedScheduleValues.filter(
    (ele) => ele.date_range.start_date !== null,
  );

  return filterScheduleValues;
}

function getWeekdayAvailability(availableWeekdays) {
  const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const availabilityArray = [];

  WEEK_DAYS.forEach((day) => {
    availabilityArray.push(availableWeekdays.includes(day));
  });

  return availabilityArray;
}

function convertedSchoolScheduleDaysIntoBoolean(values = undefined) {
  const convertedScheduleValues = values?.map((ele) => ({
    ...ele,
    days_of_week: getWeekdayAvailability(ele.days_of_week),
  }));

  const filterScheduleValues = convertedScheduleValues.filter(
    (ele) => ele.date_range.start_date !== undefined && ele.date_range.end_date !== undefined,
  );

  return filterScheduleValues;
}

function createSchoolScheduleArray(inputArray) {
  if (inputArray) {
    const newArray = inputArray.map((item) => ({
      school_id: item.school_id,
      date_range: {
        start_date: null,
        end_date: null,
      },
      days_of_week: [true, true, true, true, true, false, false],
      holidays: [{ name: '', date: null }],
      vacations: [{ name: '', start_date: null, end_date: null }],
    }));

    return newArray;
  }
}

function checkAllWeekDays(array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== false) {
      return false; // If any value is not false, return false
    }
  }
  return true; // If all values are false, return true
}

function checkConditionsforAllSchoolScheduleValues(arr) {
  for (const obj of arr) {
    if (obj.date_range.start_date === null || obj.date_range.end_date === null) {
      return false;
    }
    for (const holiday of obj.holidays) {
      if (holiday.date === null) {
        return false;
      }
    }
    for (const vacation of obj.vacations) {
      if (vacation.start_date === null || vacation.end_date === null) {
        return false;
      }
    }
  }
  return true;
}

function validateholidays(holidayData = []) {
  if (holidayData[0]?.name.length > 0) {
    return true;
  }
  return false;
}

function checkConditionsForSingleSchoolScheduleValue(data) {
  if (!data) {
    return false; // Handle case where data is not provided
  }

  if (!isValidDate(data.date_range.start_date)) {
    return false;
  }

  if (!isValidDate(data.date_range.end_date)) {
    return false;
  }

  if (!isValidYear(data.date_range.start_date)) {
    return false;
  }

  if (!isValidYear(data.date_range.end_date)) {
    return false;
  }

  if (!isSecondDateGreaterThanFirst(data.date_range.start_date, data.date_range.end_date)) {
    return false;
  }

  if (
    !data.date_range ||
    data.date_range.start_date === null ||
    data.date_range.end_date === null
  ) {
    return false;
  }

  if (!data.holidays || data.holidays.length === 0 || data.holidays[0].name === '') {
    return false;
  }

  if (!data.vacations || data.vacations.length === 0 || data.vacations[0].name === '') {
    return false;
  }

  if (!data.days_of_week || !data.days_of_week.some((day) => day)) {
    return false;
  }

  return true;
}

function getSchoolName(schoolValues, selectedSchoolID) {
  const scholName = schoolValues.find((ele) => ele.school_id === selectedSchoolID);
  return scholName?.school_name;
}

const isValidDate = (dateToCheck) => {
  const convertDateFormat = typeof dateToCheck === 'string' ? new Date(dateToCheck) : dateToCheck;

  return (
    convertDateFormat !== null &&
    isValid(convertDateFormat) &&
    getMonth(convertDateFormat) !== undefined &&
    getYear(convertDateFormat) !== undefined
  );
};

function isValidYear(dateObject) {
  // Extract year, month, and date from the Date object
  const convertedDate = typeof dateObject === 'string' ? new Date(dateObject) : dateObject;
  const year = convertedDate.getFullYear();
  const month = convertedDate.getMonth() + 1; // Month is zero-based, so we add 1
  const day = convertedDate.getDate();

  // Check if the year, month, and date are above 1900
  return year > 1900 && month > 0 && day > 0;
}

const isSecondDateGreaterThanFirst = (firstDate, secondDate) => {
  const firstDateConvert = typeof firstDate === 'string' ? new Date(firstDate) : firstDate;
  const secondConvert = typeof secondDate === 'string' ? new Date(secondDate) : secondDate;
  return new Date(secondConvert) >= new Date(firstDateConvert);
};

const isValidateDateBetween = (start_date, end_date, validate_date) => {
  const startDateConvert = typeof start_date === 'string' ? new Date(start_date) : start_date;
  const endDateConvert = typeof end_date === 'string' ? new Date(end_date) : end_date;
  const validateDateConvert =
    typeof validate_date === 'string' ? new Date(validate_date) : validate_date;
  return validateDateConvert >= startDateConvert && validateDateConvert <= endDateConvert;
};

const isValidateDateRangeBetween = (
  start_date,
  end_date,
  validate_start_date,
  validate_end_date,
) => {
  const startDateConvert = typeof start_date === 'string' ? new Date(start_date) : start_date;
  const endDateConvert = typeof end_date === 'string' ? new Date(end_date) : end_date;
  const validateStartDateConvert =
    typeof validate_start_date === 'string' ? new Date(validate_start_date) : validate_start_date;
  const validateEndDateConvert =
    typeof validate_end_date === 'string' ? new Date(validate_end_date) : validate_end_date;

  return (
    validateStartDateConvert >= startDateConvert &&
    validateStartDateConvert <= endDateConvert &&
    validateEndDateConvert >= startDateConvert &&
    validateEndDateConvert <= endDateConvert
  );
};

const isValidateDateRangeForHolidays = (start_date, end_date, data) => {
  if (data.length === 0) {
    return [];
  }
  return data.map((ele) => {
    return !isValidateDateBetween(start_date, end_date, ele?.date);
  });
};

const isValidateDateRangeForVacations = (start_date, end_date, data) => {
  if (data.length === 0) {
    return [];
  }
  return data.map((ele) => {
    return !isValidateDateRangeBetween(start_date, end_date, ele?.start_date, ele?.end_date);
  });
};

const disableButton = (sessionData, start_date, end_date) => {
  if (!Boolean(start_date) || !Boolean(end_date)) {
    return true;
  }

  if (sessionData?.status === 'active') {
    return true;
  }
  if (!isValidYear(start_date) || !isValidYear(end_date)) {
    return true;
  }
  if (!isSecondDateGreaterThanFirst(start_date, end_date)) {
    return true;
  }
  return false;
};

function validateAllValuesWithDateRange(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return arrayA;
  }

  const resultArray = [];

  for (let i = 0; i < arrayA.length; i++) {
   
    if (arrayB[i] === false) {
      resultArray.push(false);
    } else {
      // Otherwise, use the value from arrayA
      resultArray.push(arrayA[i]);
    }
  }

  return resultArray;
}

const validationMessageForVacation = (validateDate) => {
  const validationResult = validateDate?.some((ele) => ele === true);
  return validationResult;
};


const validateEachSchoolForm = (data) => {
  const validationHoliday = isValidateDateRangeForHolidays(
    data?.date_range.start_date,
    data?.date_range.end_date,
    data?.holidays,
  );

  const validationVacation = isValidateDateRangeForVacations(
    data?.date_range.start_date,
    data?.date_range.end_date,
    data?.vacations,
  );

  const valideHoliday = validationHoliday.some((ele) => ele === true);
  const valideVacation = validationVacation.some((ele) => ele === true);
  const combineValidaions = valideHoliday || valideVacation;

  return combineValidaions;
};


const dateRangeValidation = (schoolSchedule) => {
  if (schoolSchedule.length === 0) {
    return [];
  }
  return schoolSchedule?.map((ele) => {
    return !validateEachSchoolForm(ele);
  });
};

function checkDateRangeValidity(schoolArray) {
  return schoolArray.map((school) => {
    return checkConditionsForSingleSchoolScheduleValue(school);
  });
}

const finalErrorMessageWithDateRange = (validationOfVacationAndHoliday) => {
  const response = validationOfVacationAndHoliday.some((ele) => ele === false)
  return !response
}



export {
  getFormattedSessionStatus,
  getSessionStatusColor,
  ConvertedSchoolScheduleDays,
  getAvailableWeekdays,
  getWeekdayAvailability,
  createSchoolScheduleArray,
  convertedSchoolScheduleDaysIntoBoolean,
  checkAllWeekDays,
  checkConditionsforAllSchoolScheduleValues,
  validateholidays,
  checkConditionsForSingleSchoolScheduleValue,
  getSchoolName,
  isValidDate,
  isSecondDateGreaterThanFirst,
  isValidYear,
  disableButton,
  isValidateDateRangeForHolidays,
  isValidateDateRangeForVacations,
  validateAllValuesWithDateRange,
  validationMessageForVacation,
  dateRangeValidation,
  checkDateRangeValidity,
  finalErrorMessageWithDateRange,
};
