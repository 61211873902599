import React from 'react';
import { useEffect } from 'react';
import { useField } from 'formik';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

const CheckboxInput = ({ label, schoolAddress, ...props }) => {
  const [field, meta, helpers, error] = useField(props.field.name);

  const handleChange = (event) => {
    helpers.setValue(event.target.checked);
  };

  useEffect(() => {
    if (schoolAddress !== undefined) {
      if (schoolAddress.address.apt_plot !== undefined) {
        props.formikProps.setFieldValue('apt_plot', schoolAddress.address.apt_plot);
      }
      if (schoolAddress.address.street !== undefined) {
        props.formikProps.setFieldValue('street', schoolAddress.address.street);
      }
      if (schoolAddress.address.city !== undefined) {
        props.formikProps.setFieldValue('city', schoolAddress.address.city);
      }
      if (schoolAddress.address.state !== undefined) {
        props.formikProps.setFieldValue('state', schoolAddress.address.state);
      }
      if (schoolAddress.address.zip_code !== undefined) {
        props.formikProps.setFieldValue('zip_code', schoolAddress.address.zip_code);
      }
      if (schoolAddress.address.country !== undefined) {
        props.formikProps.setFieldValue('country', schoolAddress.address.country);
      }
    }
  }, [schoolAddress]);

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={field.value} onChange={handleChange} {...props} />}
        label={label}
        error={!props.field.value}
      />
      {props.formikProps.submitCount !== 0 &&
        props.formikProps.values.acknowledgementForStateCompliance === false && (
          <Typography color='error'>{props.helperText}</Typography>
        )}
    </>
  );
};

export default CheckboxInput;
