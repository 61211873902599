import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Stack } from '@mui/material';

export default function MultipleSelectCheckMarks({
  value = [],
  setValue,
  label = '',
  list = [
    {
      label: '',
      value: '',
    },
  ],
  variant = 'standard',
  inputProps = {},
  ...props
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const selectedLabel = list
    .filter((ele) => value.indexOf(ele.value) > -1)
    .map((ele) => ele.label)
    .join(', ');

  return (
    <Stack width={'100%'}>
      <FormControl variant={variant}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          inputProps={inputProps}
          renderValue={(selected) => selectedLabel}
          {...props}
        >
          {list.map((ele) => (
            <MenuItem key={ele.value} value={ele.value}>
              <Checkbox checked={value.indexOf(ele.value) > -1} />
              <ListItemText primary={ele.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
