import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// third-party imports
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKey } from 'react-query/queryKey';

// APIs
import {
  // registerStudent,
  getRoutesBySchools,
  uploadChildImage,
  // updateStudentById,
  registerStudentNew,
  updateStudentByIdNew,
} from 'clients/SpotBus';

// project imports
import { countries } from 'utils/countries';
import { getErrorMessage } from '../Utils/ErrorHandling';
import { FormHeader } from 'components/SectionForms';
import StudentForm from './StudentForm';
import { Divider } from '@mui/material';

// icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { convertDateFormat } from '../Utils/TimeHandling';
// import { languageDataBasedOnID } from '../Utils/ArrayofNameList';
import { defaultCountry } from 'utils';

// Custom dialog based form regular add students section.
function CustomizedDialog({ openDialog, handleCloseDialog, title, children, disableClose, icon }) {
  return (
    <Dialog open={openDialog} aria-labelledby='form-dialog-main' fullWidth={true} maxWidth='lg'>
      <DialogTitle id='form-dialog-title' sx={{ p: 0, m: 0 }}>
        <FormHeader
          id={`student-from-dialog-close-button${title}`}
          title={title}
          onClose={handleCloseDialog}
          disableClose={disableClose}
          icon={icon}
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ mx: 5, my: 2, p: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}
const currentSessionId = localStorage.getItem('currentSessionId');
/**
 * Generates a new request object based on the provided parameters.
 *
 * @param {Object} options - The options for generating the request object.
 * @param {string} options.selectedSchoolId - The ID of the selected school.
 * @param {Object} options.values - The values object containing various student details.
 * @param {string} options.result_image - The result image of the student.
 * @param {string} options.id - The ID of the request.
 * @return {Object} - The generated request object.
 */

/**
 * Filter addresses based on the presence of filled information in the geo_location field.
 * @param {Array} addresses - Array of address objects.
 * @returns {Array} Returns an array containing only the address objects with filled information in the geo_location field.
 */
const filterAddressByFilledInformation = (addresses) => {
  /**
   * Checks if the geo_location field contains filled latitude and longitude information.
   * @param {Object} geo_location - The geo_location object containing latitude and longitude.
   * @returns {boolean} Returns true if both latitude and longitude fields are filled, otherwise false.
   */
  return addresses?.filter((obj) => {
    const { geo_location } = obj;
    return isValidField(geo_location.latitude) && isValidField(geo_location.longitude);
  });
};

/**
 * Filter parent information based on the validity of the label field.
 * @param {Array} parentsInfo - Array of parent information objects.
 * @returns {Array} Returns an array containing only the parent information objects where the label field is valid.
 */
const filterParentValidInformation = (parentsInfo) => {
  /**
   * Checks if the label field is valid.
   * @param {string} label - The label field to be checked.
   * @returns {boolean} Returns true if the label field is valid (not empty), otherwise false.
   */
  return parentsInfo?.filter((obj) => {
    const { label } = obj;
    return isValidField(label);
  });
};

/**
 * Check if a phone number is valid.
 * @param {string} phoneNumber - The phone number to be checked.
 * @returns {boolean} Returns true if the phone number has at least 10 characters, otherwise false.
 */
const isValidPhoneNumber = (phoneNumber) => {
  return String(phoneNumber)?.length >= 10 ? true : false;
};

/**
 * Check if a value is valid (not empty).
 * @param {*} value - The value to be checked.
 * @returns {boolean} Returns true if the value is not empty, otherwise false.
 */
const isValidField = (value) => {
  return value?.length > 0 ? true : false;
};

// Get Stop Value
function getStopValue(values, stopField, routeFiled) {
  if (values?.[stopField] === 'none') {
    return null;
  } else if (values?.[routeFiled] === 'none') {
    return null;
  } else if (values?.[stopField]?.length === 0) {
    return null;
  } else {
    return values?.[stopField] || null;
  }
}

// Get Route Value
function getRouteValue(values, stopField) {
  if (values?.[stopField] === 'none') {
    return null;
  } else if (values?.[stopField]?.length === 0) {
    return null;
  } else {
    return values?.[stopField] || null;
  }
}

const newReqFactory = ({ selectedSchoolId, values, result_image }) => {
  if (!selectedSchoolId || !values) {
    return null;
  }
  let phone_number_value = {
    country_code: values?.student_phone_code.phone,
    number: String(values?.student_phone_number),
  };

  let father_phone_number_value = {
    country_code: values?.father_phone_code.phone,
    number: String(values?.father_phone_number),
  };

  let mother_phone__value = {
    country_code: values?.mother_phone_code?.phone,
    number: String(values?.mother_phone_number),
  };

  let guardian_phone_number_value = {
    country_code: values?.guardian_phone_code?.phone,
    number: String(values?.guardian_phone_number),
  };

  let addresses = [
    {
      label: 'Default Address',
      geo_location: {
        latitude: values?.latitude || '',
        longitude: values?.longitude || '',
      },
      address: {
        apt_plot: values.address_apt_plot || '',
        street: values.address_street || '',
        city: values.address_city || '',
        state: values.address_state || '',
        zip_code: values.address_zip_code || '',
        country: values.address_country || '',
      },
      am_preference_index:
        values.address_route_type === 'am' ? 0 : values.address_route_type === 'am_pm' ? 0 : 1,
      pm_preference_index:
        values.address_route_type === 'pm' ? 0 : values.address_route_type === 'am_pm' ? 0 : 1,
    },
    // optional address
    {
      label: 'Father Address',
      geo_location: {
        latitude: values.latitude_optional,
        longitude: values.longitude_optional,
      },
      address: {
        apt_plot: values.address_apt_plot_optional || '',
        street: values.address_street_optional || '',
        city: values.address_city_optional || '',
        state: values.address_state_optional || '',
        zip_code: values.address_zip_code_optional || '',
        country: values.address_country_optional || '',
      },
      am_preference_index:
        values.address_route_type_optional === 'am'
          ? 0
          : values.address_route_type_optional === 'am_pm'
          ? 0
          : 1,
      pm_preference_index:
        values.address_route_type_optional === 'pm'
          ? 0
          : values.address_route_type_optional === 'am_pm'
          ? 0
          : 1,
    },
    // optional2 address
    {
      label: 'Mother Address',
      geo_location: {
        latitude: values.latitude_optional2,
        longitude: values.longitude_optional2,
      },
      address: {
        apt_plot: values.address_apt_plot_optional2 || '',
        street: values.address_street_optional2 || '',
        city: values.address_city_optional2 || '',
        state: values.address_state_optional2 || '',
        zip_code: values.address_zip_code_optional2 || '',
        country: values.address_country_optional2 || '',
      },
      am_preference_index:
        values.address_route_type_optional2 === 'am'
          ? 0
          : values.address_route_type_optional2 === 'am_pm'
          ? 0
          : 1,
      pm_preference_index:
        values.address_route_type_optional2 === 'pm'
          ? 0
          : values.address_route_type_optional2 === 'am_pm'
          ? 0
          : 1,
    },
  ];

  let parents_Information = [
    {
      label: values?.father_label.toLowerCase(),
      first_name: isValidField(values?.father_first_name) ? values?.father_first_name : null,
      last_name: isValidField(values?.father_last_name) ? values?.father_last_name : null,
      email: isValidField(values?.father_email) ? values?.father_email : null,
      phone_number: isValidPhoneNumber(values?.father_phone_number)
        ? father_phone_number_value
        : null,
    },
    {
      label: values.mother_label.toLowerCase(),
      first_name: isValidField(values?.mother_first_name) ? values?.mother_first_name : null,
      last_name: isValidField(values?.mother_last_name) ? values?.mother_last_name : null,
      email: isValidField(values?.mother_email) ? values?.mother_email : null,
      phone_number: isValidPhoneNumber(values?.mother_phone_number) ? mother_phone__value : null,
    },
    {
      label: values?.guardian_label?.toLowerCase() || '',
      first_name: isValidField(values?.guardian_first_name) ? values?.guardian_first_name : null,
      last_name: isValidField(values.guardian_last_name) ? values?.guardian_last_name : null,
      email: isValidField(values?.guardian_email) ? values?.guardian_email : null,
      phone_number: isValidPhoneNumber(values?.guardian_phone_number)
        ? guardian_phone_number_value
        : null,
    },
  ];

  return {
    session_id: currentSessionId,
    first_name: values.first_name,
    last_name: values.last_name,
    school_id: selectedSchoolId,
    grade: `${values.grade}`,
    lang: values.lang.code,
    birthdate: Boolean(values.birth_date) ? convertDateFormat(values.birth_date) : null,
    gender: values.gender,
    email: isValidField(values.student_email) ? values.student_email : null,
    parents_id: values?.parents_id || '',
    phone_number: isValidPhoneNumber(values?.student_phone_number) ? phone_number_value : null,
    special_needs: {
      has_disability: values.special_needs,
      disability_type: isValidField(values.special_needs_level) ? values.special_needs_level : null,
    },
    parents_info: filterParentValidInformation(parents_Information),
    addresses: filterAddressByFilledInformation(addresses),
    driver_notes: isValidField(values.driver_notes) ? values.driver_notes : null,
    school_notes: isValidField(values.school_notes) ? values.school_notes : null,
    profile_image: result_image || null,
    medicines: values.medicines,
    program_info: {
      name: isValidField(values.student_program_name) ? values.student_program_name : null,
      student_type: isValidField(values.student_type) ? values.student_type : null,
      neighbourhood_code: isValidField(values.neighborhood_code) ? values.neighborhood_code : null,
      description: isValidField(values.student_neighborhood_code_description)
        ? values.student_neighborhood_code_description
        : null,
    },
    special_considerations: {
      ac: values.student_ac,
      booster: values.student_booster,
      car_seat: values.student_car_seat,
      harness: values.student_harness,
      lock: values.student_lock,
      m: values.student_m,
      mclb: values.student_mclb,
      monitor: values.student_monitor,
      sch_unattended: values.student_unattended,
      therepy: values.student_therapy,
      walker: values.student_walker,
      wc: values.student_wc,
      west: values.student_west,
    },

    route_id_am: getRouteValue(values, 'am_route'),
    stop_id_am: getStopValue(values, 'am_stop', 'am_route'),
    route_id_pm: getRouteValue(values, 'pm_route'),
    stop_id_pm: getStopValue(values, 'pm_stop', 'pm_route'),
  };
};

/**
 * Generates a function comment for the given function body.
 *
 * @param {Object} studentData - the student data object
 * @return {Object} the generated function comment
 */
const update_input_factory = (studentData) => {
  if (!studentData || !studentData?.parents_info || !studentData?.addresses) return null;
  const [father, mother, guardian] = studentData?.parents_info;
  const [address, address_optional, address_optional2] = studentData?.addresses;

  const getCountryObject = (code) => {
    return countries.find((country) => country?.phone === code);
  };
  const getAddressType = (addressAm, addressPm) => {
    // address.am_preference_index
    if (Number(addressAm) === 0 && Number(addressPm) === 0) {
      return 'am_pm';
    }
    if (Number(addressPm) === 0) {
      return 'pm';
    }
    if (Number(addressAm) === 0) {
      return 'am';
    }
    return '';
  };

  return {
    profile_image: studentData?.profile_image,
    student_image: studentData?.profile_image?.url,
    first_name: studentData?.first_name,
    last_name: studentData?.last_name,
    birth_date: studentData?.birthdate,
    grade: studentData?.grade,
    gender: studentData?.gender,
    lang: studentData?.lang,

    // Student Contact info
    student_phone_code:
      getCountryObject(studentData?.phone_number?.country_code) || defaultCountry(),
    student_phone_number: studentData?.phone_number?.number || '',
    student_email: studentData?.email || '',

    // if special_needs is true then add special_needs
    special_needs_level: studentData?.special_needs.disability_type,
    special_needs: studentData?.special_needs.has_disability,

    parents_id: studentData?.parents_id || '',

    // student Address
    address_label: address?.label,
    address_route_type: getAddressType(address?.am_preference_index, address?.pm_preference_index),
    address_search: `[${address?.geo_location.latitude},${address?.geo_location.longitude}]`,
    latitude: address?.geo_location.latitude,
    longitude: address?.geo_location.longitude,
    address_apt_plot: address?.address?.apt_plot || '',
    address_street: address?.address?.street || '',
    address_city: address?.address?.city || '',
    address_state: address?.address?.state || '',
    address_zip_code: address?.address?.zip_code || '',
    address_country: address?.address?.country || '',

    address_label_optional: address_optional?.label || '',
    address_route_type_optional: getAddressType(
      address_optional?.am_preference_index,
      address_optional?.pm_preference_index,
    ),
    address_search_optional: address_optional?.geo_location?.latitude
      ? `[${address_optional?.geo_location?.latitude},${address_optional?.geo_location?.longitude}]`
      : '',
    latitude_optional: address_optional?.geo_location?.latitude || '',
    longitude_optional: address_optional?.geo_location?.longitude || '',
    address_apt_plot_optional: address_optional?.address?.apt_plot || '',
    address_street_optional: address_optional?.address?.street || '',
    address_city_optional: address_optional?.address?.city || '',
    address_state_optional: address_optional?.address?.state || '',
    address_zip_code_optional: address_optional?.address?.zip_code || '',
    address_country_optional: address_optional?.address?.country || '',

    address_label_optional2: address_optional2?.label || '',
    address_route_type_optional2: getAddressType(
      address_optional2?.am_preference_index,
      address_optional2?.pm_preference_index,
    ),
    address_search_optional2: address_optional2?.geo_location?.latitude
      ? `[${address_optional2?.geo_location?.latitude},${address_optional2?.geo_location?.longitude}]`
      : '',
    latitude_optional2: address_optional2?.geo_location?.latitude || '',
    longitude_optional2: address_optional2?.geo_location?.longitude || '',
    address_apt_plot_optional2: address_optional2?.address?.apt_plot || '',
    address_street_optional2: address_optional2?.address?.street || '',
    address_city_optional2: address_optional2?.address?.city || '',
    address_state_optional2: address_optional2?.address?.state || '',
    address_zip_code_optional2: address_optional2?.address?.zip_code || '',
    address_country_optional2: address_optional2?.address?.country || '',

    // Student Parent info father
    father_label: father?.label,
    father_first_name: father?.first_name || '',
    father_last_name: father?.last_name || '',
    father_phone_code: getCountryObject(father?.phone_number?.country_code) || defaultCountry(),
    father_phone_number: father?.phone_number?.number || '',
    father_email: father?.email || '',

    // Student Parent info mother
    mother_label: mother?.label,
    mother_first_name: mother?.first_name || '',
    mother_last_name: mother?.last_name || '',
    mother_phone_code: getCountryObject(mother?.phone_number?.country_code) || defaultCountry(),
    mother_phone_number: mother?.phone_number?.number || '',
    mother_email: mother?.email || '',

    // Student Parent info guardian
    guardian_label: guardian?.label,
    guardian_first_name: guardian?.first_name || '',
    guardian_last_name: guardian?.last_name || '',
    guardian_phone_code: getCountryObject(guardian?.phone_number?.country_code) || defaultCountry(),
    guardian_phone_number: guardian?.phone_number?.number || '',
    guardian_email: guardian?.email || '',

    // Student Contact Route info
    route_id_am: studentData?.route_id_am || '',
    stop_id_am: studentData?.stop_id_am || '',
    route_id_pm: studentData?.route_id_pm || '',
    stop_id_pm: studentData?.stop_id_pm || '',

    // student Medical info
    medicines: studentData?.medicines || [],

    // student program
    student_program_name: studentData?.program_info.name || '',
    student_type: studentData?.program_info.student_type || '',
    neighborhood_code: studentData?.program_info.neighbourhood_code || '',
    student_neighborhood_code_description: studentData?.program_info.description || '',

    // school Notes
    school_notes: studentData?.school_notes || '',
    driver_notes: studentData?.driver_notes || '',

    // special needs
    student_ac: studentData?.special_considerations.ac,
    student_booster: studentData?.special_considerations.booster,
    student_car_seat: studentData?.special_considerations.car_seat,
    student_harness: studentData?.special_considerations.harness,
    student_lock: studentData?.special_considerations.lock,
    student_mclb: studentData?.special_considerations.mclb,
    student_monitor: studentData?.special_considerations.monitor,
    student_unattended: studentData?.special_considerations.sch_unattended,
    student_therapy: studentData?.special_considerations.therepy,
    student_walker: studentData?.special_considerations.walker,
    student_wc: studentData?.special_considerations.wc,
    student_west: studentData?.special_considerations.west,
    student_m: studentData?.special_considerations.m,
    // first_name: studentData.first_name,
    // last_name: studentData.last_name,
    // school_id: studentData.school_id,
    // route_id_am: studentData.route_id_am,
    // stop_id_am: studentData.stop_id_pm,
    // route_id_pm: studentData.route_id_pm,
    // stop_id_pm: studentData.stop_id_pm,
    // // phone_country_code: countries.find((country) => country.phone === code),
    // // parent_id: studentData.parent_id.slice(studentData.parent_id.length - 10),
    // fathers_first_name: studentData.fathers_first_name,
    // fathers_last_name: studentData.fathers_last_name,
    // mothers_first_name: studentData.mothers_first_name,
    // mothers_last_name: studentData.mothers_last_name,
    // special_needs: studentData.special_needs,
    // school_notes: studentData.school_notes,
    // driver_notes: studentData.driver_notes,
    // state: studentData.address.state,
    // city: studentData.address.city,
    // apt_plot: studentData.address.apt_plot,
    // street: studentData.address.street,
    // zip_code: studentData.address.zip_code,
    // student_image: studentData.profile_image?.url,
    // lang: studentData.lang,
  };
};

const request_image_factory = (imageFile) => {
  if (!imageFile) return '';
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    // register event listeners
    reader.addEventListener('loadend', (e) => resolve(e.target.result));
    reader.addEventListener('error', (e) => reject(e));

    // read file
    reader.readAsDataURL(imageFile);
  });
};

// ============================== STUDENT FORM - MAIN ============================== //

/**
 * Component for the main student form.
 * @param {Object} props - The props for the component.
 * @param {function} props.enqueueSnackbar - Function to show a snack bar notification.
 * @param {function} props.closeSnackbar - Function to close a snack bar notification.
 * @param {string} props.selectedSchoolId - The ID of the selected school.
 * @param {boolean} props.isDialogForm - Flag indicating if the form is displayed in a dialog.
 * @param {boolean} props.isUpdate - Flag indicating if the form is for updating a student.
 * @param {Object} props.studentData - The data of the student.
 * @param {function} props.handleRefreshStudentList - Function to refresh the student list.
 * @param {function} props.setIsUpdate - Function to set the update flag.
 * @param {function} props.setApiLoadingStudet - Function to set the API loading status.
 * @param {boolean} props.isFromGuided - Flag indicating if the form is from guided mode.
 * @param {Object} props.selectedSchoolData - The data of the selected school.
 * @returns {JSX.Element} - The main student form component.
 */
function MainStudentForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedSchoolId,
    isDialogForm,
    isUpdate,
    studentData,
    handleRefreshStudentList,
    setIsUpdate,
    setApiLoadingStudet,
    isFromGuided,
    selectedSchoolData,
    setStudentData,
    setSelectedStudentRowId,
    setSearched,
    setSelectedStudentData,
    setLocalSearch,
    schoolsData,
    imageLoader,
    imageUrl,
    pmRoutes,
    amRoutes,
  } = props;

  const factoryStudentData = isUpdate ? update_input_factory(studentData) : studentData;

  // dialog states
  const [openDialog, setOpenDialog] = useState(true);

  // function to handle dialog form close events
  const handleDialogClose = (refreshList) => {
    setOpenDialog(false);
    handleRefreshStudentList(refreshList);
  };

  //Disable form close button
  const [disableClose, setDisableClose] = useState(false);

  const queryClient = useQueryClient();

  const updateStudentMutation = useMutation({
    /**
     * Executes the mutation function with the given body.
     *
     * @param {Object} body - The body object containing the id and request_schema.
     * @return {Promise} - A promise that resolves to the result of the updateStudentByIdNew function call.
     */
    mutationFn: (body) => {
      return updateStudentByIdNew(body.id, body.request_schema);
      // return _sleep;
    },
    /**
     * Called when the API request is successful.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    onSuccess: () => {
      setSelectedStudentRowId(null);
      setSearched('');
      setStudentData([]);
      setSelectedStudentData(null);
      setLocalSearch('');
      queryClient.invalidateQueries([queryKey.STUDENTS.ALL_STUDENTS]);
      queryClient.clear();
    },
  });

  const registerStudentMutation = useMutation({
    mutationFn: (body) => {
      return registerStudentNew(body.request_schema);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.STUDENTS.ALL_STUDENTS]);
    },
  });

  const uploadImage = async (request_image) => {
    if (!request_image) return null;
    try {
      return await uploadChildImage(request_image);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Register a student with the given values, actions, and resetActiveStep.
   *
   * @param {object} values - The values for the student.
   * @param {object} actions - The actions for the formik form.
   * @param {function} resetActiveStep - A function to reset the active step.
   * @return {Promise<void>} - A promise that resolves when the registration is complete.
   */
  const _register_student = async (values, actions, resetActiveStep) => {
    setDisableClose(true);
    isFromGuided && setApiLoadingStudet(true);
    try {
      const request_image = await request_image_factory(values.student_image);

      const result_image = await uploadImage(request_image);

      // const request_schema = request_factory(selectedSchoolId, values, result_image);
      const request_schema = newReqFactory({ selectedSchoolId, values, result_image });
      // const result = await registerStudent(request_schema);
      const result = await registerStudentMutation.mutateAsync({ request_schema });
      console.log('Student added successfully: ', result);

      enqueueSnackbar('Student registered successfully', {
        variant: 'success',
      });
      isFromGuided && setApiLoadingStudet(false);
      actions.resetForm();
      resetActiveStep();
      setDisableClose(false);
      handleDialogClose(true);
    } catch (error) {
      console.error('Error occurred during register student: ', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDisableClose(false);
      isFromGuided && setApiLoadingStudet(false);
    } finally {
      actions.setSubmitting(false);
    }
  };

  /**
   * Updates a student's information.
   *
   * @param {Object} values - The new values for the student.
   * @param {Object} actions - The actions object provided by Formik.
   * @param {Function} resetActiveStep - A function to reset the active step.
   * @return {Promise} A promise that resolves when the student is updated successfully.
   */
  const _update_student = async (values, actions, resetActiveStep) => {
    // setDisableClose(true);
    isFromGuided && setApiLoadingStudet(true);
    var result_image = studentData.profile_image;
    try {
      if (values.student_image instanceof Object) {
        const request_image = await request_image_factory(values.student_image);
        result_image = await uploadImage(request_image);
        console.log('Image uploaded successfully: ', result_image);
      }
      const request_schema = newReqFactory({
        selectedSchoolId,
        values,
        result_image,
        id: studentData.id,
      });
      const result = await updateStudentMutation.mutateAsync({
        id: studentData.id,
        request_schema: { ...request_schema, id: studentData.id },
      });
      console.log('Student updated successfully: ', result);

      enqueueSnackbar('Student updated successfully', {
        variant: 'success',
      });
      isFromGuided && setApiLoadingStudet(false);

      actions.resetForm();
      resetActiveStep();
      setDisableClose(false);
      handleDialogClose(true);
    } catch (error) {
      console.error('Error occurred during update student: ', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDisableClose(false);
      isFromGuided && setApiLoadingStudet(false);
    } finally {
      actions.setSubmitting(false);
    }
  };

  // handle form submit
  const handleFormSubmit = async (values, actions, resetActiveStep) => {
    if (isUpdate) {
      _update_student(values, actions, resetActiveStep);
      // updateStudentMutation.mutate(values, actions, resetActiveStep);
    } else {
      _register_student(values, actions, resetActiveStep);
    }
    // await _sleep(3000);
    // alert(JSON.stringify(values, null, 2));
  };

  useEffect(() => {
    setOpenDialog(isDialogForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isDialogForm && (
        <CustomizedDialog
          openDialog={openDialog}
          handleCloseDialog={handleDialogClose}
          title={isUpdate ? 'Update Student' : 'Add Student'}
          disableClose={disableClose}
          icon={isUpdate ? <ManageAccountsIcon /> : <PersonAddIcon />}
        >
          <StudentForm
            isUpdate={isUpdate}
            isDialogForm={isDialogForm}
            studentData={factoryStudentData}
            handleSubmit={handleFormSubmit}
            amRoutes={amRoutes}
            pmRoutes={pmRoutes}
            setIsUpdate={setIsUpdate}
            setActiveStepforRoutes={props.setActiveStepforRoutes}
            isSubmitting={props.isSubmitting}
            setRenderGPTwo={props.setRenderGPTwo}
            selectedSchoolId={props.selectedSchoolId}
            updateActiveStep={props.updateActiveStep}
            selectedSchoolData={selectedSchoolData}
            schoolsData={schoolsData}
            imageLoader={imageLoader}
            imageUrl={imageUrl}
          />
        </CustomizedDialog>
      )}
      {!isDialogForm && (
        <StudentForm
          isUpdate={isUpdate}
          isDialogForm={isDialogForm}
          studentData={factoryStudentData}
          handleSubmit={handleFormSubmit}
          amRoutes={amRoutes}
          pmRoutes={pmRoutes}
          setIsUpdate={setIsUpdate}
          setActiveStepforRoutes={props.setActiveStepforRoutes}
          isSubmitting={props.isSubmitting}
          setRenderGPTwo={props.setRenderGPTwo}
          selectedSchoolId={props.selectedSchoolId}
          updateActiveStep={props.updateActiveStep}
          isFromGuided={isFromGuided}
          selectedSchoolData={selectedSchoolData}
          schoolsData={schoolsData}
          imageLoader={imageLoader}
          imageUrl={imageUrl}
        />
      )}
    </React.Fragment>
  );
}

MainStudentForm.defaultProps = {
  isDialogForm: true,
  isUpdate: false,
  hideStepper: false,
  studentData: {},
};

MainStudentForm.propTypes = {
  selectedSchoolId: PropTypes.string.isRequired,
  studentData: PropTypes.object.isRequired,
  handleRefreshStudentList: PropTypes.func.isRequired,
  setIsUpdate: PropTypes.bool.isRequired,
};

export default MainStudentForm;
