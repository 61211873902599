export function oneLineAddress(addressObject) {
  return `${addressObject.apt_plot} ${addressObject.street} ${addressObject.city} ${addressObject.state} ${addressObject.zip_code}`;
}

export function convertToNormalCase(inputString) {
  // Check if the input string is empty
  if (!inputString) {
    return '';
  }
  try {
    // Split the input string by underscores and convert to an array of words
    const words = inputString.split('_');

    // Capitalize the first letter of each word and make the rest lowercase
    const normalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the normalized words with spaces to create the final normal case string
    const normalCaseString = normalizedWords.join(' ');

    return normalCaseString;
  } catch (error) {
    console.log(error);
    return inputString;
  }
}
