import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import AdbOutlinedIcon from '@mui/icons-material/AdbOutlined';

// project imports
import MainCard from 'components/MainCard';

const QuickViewItemCard = ({ label, icon, id, isDataLoading, children, sx, ...props }) => {
  // ! Readme
  // ! for icon use Only outlined icons (please check "https://mui.com/material-ui/material-icons/?theme=Outlined")
  /**
   * 1. Import component in your .jsx file ()
   **/
  //     !<QuickViewItemCard
  //     *   label='Transportation Organization'
  //     *   icon={<YourOutlinedIcon fontSize='large' />} or icon={YourIcon}
  //     *   id='someId_Not_Required'
  //     *   isDataLoading={props.isDataLoading}
  //      >
  //     *  Your Heading
  //     ! </QuickViewItemCard>

  /**
 * another way 
 * 
 *      <QuickViewItemCard
          label='label Name'
          icon={<YourIcon fontSize='large' />} or icon={YourIcon}
          id='someId_Not_Required'
          isDataLoading={props.isDataLoading}
        >
         <Grid> 
            <Your_Another_MUI_or_JSX />
         </Grid>
        </QuickViewItemCard>
 * 
 */
  const lowerLabel = label.toLowerCase();
  const nodeCheckIcon = () => {
    if (String(icon?.$$typeof) === 'Symbol(react.memo)') {
      const IconComponent = icon;
      return <IconComponent fontSize='large' />;
    } else if (String(icon?.$$typeof) === 'Symbol(react.element)') {
      return icon;
    } else console.error('error in QuickViewItemCard.js please check icon props');
  };

  return (
    <>
      <MainCard content={false} border boxShadow sx={{ my: 1, p: 3, ...sx }} {...props}>
        <Stack direction='row' alignItems='center'>
          <Stack>{nodeCheckIcon()}</Stack>
          <Stack sx={{ pl: '25px', width: '100%' }}>
            {typeof label === 'string' && label.length !== 0 && (
              <Typography color='primary'>{label}</Typography>
            )}
            {isDataLoading ? (
              <Skeleton variant='text' />
            ) : typeof children === 'string' && children.length !== 0 ? (
              <Typography
                component='h5'
                variant='h5'
                color='textSecondary'
                id={id}
                sx={{ textTransform: lowerLabel === 'email' ? 'lowercase' : 'capitalize' }}
              >
                {children}
              </Typography>
            ) : (
              children
            )}
          </Stack>
        </Stack>
      </MainCard>
    </>
  );
};

// * ________________Default Values_________________
QuickViewItemCard.defaultProps = {
  label: 'label',
  icon: <AdbOutlinedIcon />,
  id: '',
  isDataLoading: false,
  children: '',
  sx: {},
};

// * ____________Validation using PropType___________
QuickViewItemCard.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  id: PropTypes.string,
  isDataLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default QuickViewItemCard;
