import React, { useState } from 'react';

// material ui
import { Grid, Typography, Card, CardContent } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';
import MainCard from 'components/MainCard';

// project imports
import VehicleCard from './VehicleCard';
import DeleteAlert from '../../DeleteAlert';

export default function VehicleList(props) {
  const theme = useTheme();
  const [deleteId, setDeleteId] = useState(false);
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7];

  return (
    <Card
      sx={{
        height: '100%',
        zIndex: 2,
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? 'secondary.lighter' : 'secondary.darker',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <CardContent>
        {/* <MainCard sx={{ backgroundColor: theme.palette.primary.main }}> */}
        <Grid container>
          <Typography
            variant='h2'
            sx={{ py: 1, pl: 2, color: 'common.white', fontWeight: 300, mb: -4 }}
          >
            Vehicle
          </Typography>
          <Typography
            variant='h5'
            sx={{
              pt: 4,
              pl: 2,
              color: 'common.white',
              fontWeight: 400,
              fontStyle: 'italic',
            }}
          >
            {`(${props.guidedVehicles?.length ? props.guidedVehicles?.length : '- '} vehicle${
              props.guidedVehicles?.length > 1 ? 's are' : ' is'
            } registered)`}
          </Typography>
        </Grid>
        <Scrollbars
          style={{ height: 695 }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          disableHorizontalScrolling
        >
          <Grid>
            {!props.guidedVehicles && props.loadingData === true ? (
              <Grid>
                {skeletonCount.map((skeleton, index) => {
                  return (
                    <MainCard content={false} key={index} sx={{ padding: 2, mb: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography component='div' variant='h2' width='80%'>
                            <Skeleton />
                          </Typography>
                          <Typography component='div' variant='body1' width='50%'>
                            <Skeleton />
                          </Typography>
                          <Typography component='div' variant='body1' width='50%'>
                            <Skeleton />
                          </Typography>
                        </Grid>
                      </Grid>
                    </MainCard>
                  );
                })}
              </Grid>
            ) : (
              // props.guidedDrivers?.length === 0 ? (
              //   <NoRegisteredSchool />
              // ) :
              props.guidedVehicles?.map((vehicle) => (
                <VehicleCard
                  vehicle={vehicle}
                  key={vehicle.email}
                  handleEdit={props.handleEdit}
                  setOpen={() => props.setOpenDeleteAlert(true)}
                  setDeleteId={setDeleteId}
                  loadingData={props.loadingData}
                />
              ))
            )}
          </Grid>
        </Scrollbars>
        {/* </MainCard> */}
      </CardContent>
      <DeleteAlert
        open={props.openDeleteAlert}
        setOpen={() => props.setOpenDeleteAlert(true)}
        setClose={() => props.setOpenDeleteAlert(false)}
        handleDelete={props.handleDeleteVehicle}
        buttonLoader={props.deleteButtonLoader}
        deleteId={deleteId}
        section={'vehicle'}
      />
    </Card>
  );
}
