import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import { Hub, Logger, Auth } from 'aws-amplify';
import { Button } from '@mui/material';
import { Provider } from 'react-redux';

import config from './aws-exports';
import AppBeforeAuth from './AppBeforeAuth';
import App from './App';
import store from './reduxStore';
import UnauthorizedDashboard from 'unauthorizedPages/unauthorizedDashboard';
import Authentication from 'containers/layouts/Authv2/Authentication';

const logger = new Logger('SpotbusLogger', 'DEBUG');

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props);

    // state variables
    this.state = {
      userAuthState: 'SIGNED_OUT',
      username: undefined,
      isGuidedWorkflowCompleted: false,
      guidedWorkflowData: undefined,
    };

    // event handlers bindings
    this.handleGuidedWorkflowDataUpdate = this.handleGuidedWorkflowDataUpdate.bind(this);
  }

  componentDidMount() {
    // Amplify HUB subscription, listening to every auth event
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
      if (data.payload.data == undefined) {
        logger.debug('Data in auth event is null.');
      } else {
        logger.debug(
          'A new auth event has happened: ',
          data.payload.data.username + ' has ' + data.payload.event + ' ' + data.payload,
        );
      }
    });

    Auth.currentAuthenticatedUser()
      .then((response) => {
        localStorage.setItem('spotbus-username', response.username);
        logger.debug('User already logged in: ' + JSON.stringify(response));
        this.setState({
          userAuthState: 'SIGNED_IN',
          username: response.username,
        });
      })
      .catch((error) => logger.error('No User Logged in: ' + JSON.stringify(error), error));
  }

  onAuthEvent(payload) {
    logger.debug('Auth Event: ' + payload.event + ' Data: ' + payload.data);
    switch (payload.event) {
      case 'signIn':
        logger.debug('User Signed In with username: ', payload.data.username);
        this.setState({
          userAuthState: 'SIGNED_IN',
          username: payload.data.username,
        });
        break;
      case 'signUp':
        logger.debug('User Signed Up with username: ', payload.data.username);
        this.setState({
          userAuthState: 'SIGNED_UP',
        });
        break;
      case 'signOut':
        logger.debug('User Signed Out with username: ', payload.data.username);
        this.setState({
          userAuthState: 'SIGNED_OUT',
        });
        break;
      case 'signIn_failure':
        logger.debug(
          'User Signed In failure for username: ' + payload.data.username + ' message: ',
          payload.data,
        );
        break;
      case 'configured':
        logger.debug('the Auth module is configured');
        break;
      default:
        return;
    }
  }

  handleGuidedWorkflowDataUpdate(updatedData) {
    this.setState({
      guidedWorkflowData: updatedData,
      isGuidedWorkflowCompleted: updatedData?.is_completed,
    });
  }
  render() {
    return (
      <Provider store={store}>
        <div>
          {window.location.pathname.split('/')[1] === 'publicDashboard' ? (
            <UnauthorizedDashboard {...this.props} />
          ) : (
            <Authenticator hideDefault amplifyConfig={config}>
              {this.state.userAuthState === 'SIGNED_IN' ? (
                <App
                  username={this.state.username}
                  isGuidedWorkflowCompleted={this.state.isGuidedWorkflowCompleted}
                  guidedWorkflowData={this.state.guidedWorkflowData}
                  handleGuidedWorkflowDataUpdate={this.handleGuidedWorkflowDataUpdate}
                  {...this.props}
                />
              ) : (
                // <AppBeforeAuth
                //   handleGuidedWorkflowDataUpdate={this.handleGuidedWorkflowDataUpdate}
                //   {...this.props}
                // />
                <Authentication
                  handleGuidedWorkflowDataUpdate={this.handleGuidedWorkflowDataUpdate}
                />
              )}
            </Authenticator>
          )}
        </div>
      </Provider>
    );
  }
}

export default AppWithAuth;
