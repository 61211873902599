import * as React from 'react';

// material ui
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// APIs
import {
  getAllSchoolsPerAdminTransportationUsername,
  getAllDashboardUsers,
  getPolicies,
  getDriversByTransportationAdmin,
  getVehiclesList,
  getRoutesBySchoolsPaginated,
} from 'clients/SpotBus';

// project imports
import FlowProgress from './Components/FlowProgress';
import Topbar from './Components/Topbar';
import Copyright from 'containers/layouts/Auth/Copyright';
import SchoolForm from './Components/Forms/SchoolForm';
import UserForm from './Components/Forms/UserForm';
import DriverForm from './Components/Forms/DriverForm/index';
import SecondPhase from './SecondPhase/index';
import VehicleGuidedForm from './Components/Forms/VehicleGuidedForm/index';
import SessionForm from './Components/Sessions/SessionForm';
import { getSessionList } from 'clients';

// ============================== GUIDED WORKFLOW - MAIN ============================== //

export default function GuidedWorkflow(props) {
  // loading and submit states
  const [isLoading, setIsLoading] = React.useState(false);

  // Guided setup phase selection state
  const [phase, setPhase] = React.useState(0);

  // APIs data storage and others
  const [activeStep, setActiveStep] = React.useState(0);
  const [guidedSchools, setGuidedSchools] = React.useState(undefined);
  const [guidedUsers, setGuidedUsers] = React.useState(undefined);
  const [guidedSessions, setGuidedSessions] = React.useState(undefined);
  const [guidedDrivers, setGuidedDrivers] = React.useState(undefined);
  const [guidedRoutes, setGuideRoutes] = React.useState(undefined);
  const [guidedVehicles, setGuidedVehicles] = React.useState(undefined);
  const [policies, setPolicies] = React.useState(undefined);

  const getAllRoutes = async (schoolId) => {
    setIsLoading(true);
    try {
      const result = await getRoutesBySchoolsPaginated(schoolId);
      console.log(result, 'GET ALL ROUTES DATA IN MAIN FILE ');
      setGuideRoutes(result.routes);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const setGuidedStep = () => {
    for (let i = 0; i < props.guidedWorkflowData?.steps_graph.length - 1; i++) {
      if (props.guidedWorkflowData?.steps_graph[i].data[0].is_completed === false) {
        setActiveStep(i);
        return;
      } else {
        if (i === 4) {
          setPhase(1);
        }
      }
    }
  };

  const getAllSchools = async () => {
    setGuidedSchools(undefined);
    setIsLoading(true);
    try {
      const result = await getAllSchoolsPerAdminTransportationUsername();
      console.log('guided all schools result', result);
      setGuidedSchools(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllUsers = async () => {
    setGuidedUsers(undefined);
    setIsLoading(true);
    try {
      let result = await getAllDashboardUsers(props.authData?.attributes?.sub);
      result = result.filter((res) => res.user_permissions.role_type !== 'ROLE_SUPER_ADMIN');
      setGuidedUsers(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSessions = async () => {
    setIsLoading(true);
    try {
      let result = await getSessionList(props.authData?.attributes?.sub);
      console.log('guided all session', result);
      setGuidedSessions(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Driver data
  const getAllDrivers = async () => {
    setGuidedDrivers(undefined);
    setIsLoading(true);
    try {
      let result = await getDriversByTransportationAdmin(props.authData?.attributes?.sub);
      console.log('guided all drivers result', result);
      setGuidedDrivers(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Vehicle Data
  const getAllVehicles = async () => {
    setGuidedVehicles(undefined);
    setIsLoading(true);
    try {
      let result = await getVehiclesList();
      console.log('guided all vehicle result', result);
      setGuidedVehicles(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEducationPolicies = async () => {
    try {
      const result = await getPolicies();
      console.log('Education Policies result', result);
      setPolicies(result.schools[0]);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAllSchools();
    getAllDrivers();
    getAllVehicles();
    getAllSessions();
    getEducationPolicies();
    getAllUsers();
    setGuidedStep();
  }, []);

  const increaseActiveStep = () => {
    setActiveStep((step) => step + 1);
  };

  const decreaseActiveStep = () => {
    if (activeStep > 0) {
      setActiveStep((step) => step - 1);
    }
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CssBaseline />
      <Topbar {...props} />
      <Box sx={{ mt: 20, width: '100vw' }}>
        {phase === 0 && (
          <Grid item xs={12} sx={{ height: 105 }}>
            <FlowProgress activeStep={activeStep} />
          </Grid>
        )}
        {phase === 0 ? (
          <GetFirstPhaseContent
            guidedWorkflowData={props.guidedWorkflowData}
            policies={policies}
            guidedSchools={guidedSchools}
            guidedUsers={guidedUsers}
            guidedDrivers={guidedDrivers}
            guidedVehicles={guidedVehicles}
            guidedSessions={guidedSessions}
            getAllSessions={getAllSessions}
            getAllSchools={getAllSchools}
            getAllUsers={getAllUsers}
            getAllDrivers={getAllDrivers}
            getAllVehicles={getAllVehicles}
            isGuidedWorkflowCompleted={props.isGuidedWorkflowCompleted}
            handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
            activeGuidedStep={activeStep}
            increaseActiveStep={increaseActiveStep}
            decreaseActiveStep={decreaseActiveStep}
            loadingData={isLoading}
            setPhase={setPhase}
            setIsLoading={setIsLoading}
          />
        ) : (
          <SecondPhase
            guidedSchools={guidedSchools}
            guidedRoutes={guidedRoutes}
            getAllRoutes={getAllRoutes}
            setlistLoader={setIsLoading}
            listLoader={isLoading}
            username={props.username}
            guidedWorkflowData={props.guidedWorkflowData}
            handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
            setPhase={setPhase}
          />
        )}

        <Grid item xs={12} sx={{ py: 4 }}>
          <Copyright />
        </Grid>
      </Box>
    </Box>
  );
}

function GetFirstPhaseContent(props) {
  switch (props.activeGuidedStep) {
    case 0:
      return (
        <SchoolForm
          guidedWorkflowData={props.guidedWorkflowData}
          guidedSchools={props.guidedSchools}
          policies={props.policies}
          getAllSchools={props.getAllSchools}
          handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
          isGuidedWorkflowCompleted={props.isGuidedWorkflowCompleted}
          increaseActiveStep={props.increaseActiveStep}
          decreaseActiveStep={props.decreaseActiveStep}
          loadingData={props.loadingData}
          activeGuidedStep={props.activeGuidedStep}
          setloadingData={props.setIsLoading}
        />
      );
    case 1:
      return (
        <UserForm
          guidedWorkflowData={props.guidedWorkflowData}
          guidedSchools={props.guidedSchools}
          guidedUsers={props.guidedUsers}
          getAllUsers={props.getAllUsers}
          handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
          isGuidedWorkflowCompleted={props.isGuidedWorkflowCompleted}
          increaseActiveStep={props.increaseActiveStep}
          decreaseActiveStep={props.decreaseActiveStep}
          loadingData={props.loadingData}
          activeGuidedStep={props.activeGuidedStep}
          setloadingData={props.setIsLoading}
        />
      );
    case 2:
      return (
        <SessionForm
          schools={props.guidedSchools}
          guidedWorkflowData={props.guidedWorkflowData}
          guidedSessions={props.guidedSessions}
          getAllSessions={props.getAllSessions}
          increaseActiveStep={props.increaseActiveStep}
          decreaseActiveStep={props.decreaseActiveStep}
          username={props.authData?.attributes?.sub}
          handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
          setloadingData={props.setIsLoading}
          {...props}
        />
      );
    case 3:
      return (
        <DriverForm
          guidedWorkflowData={props.guidedWorkflowData}
          getAllDrivers={props.getAllDrivers}
          increaseActiveStep={props.increaseActiveStep}
          decreaseActiveStep={props.decreaseActiveStep}
          guidedDrivers={props.guidedDrivers}
          username={props.authData?.attributes?.sub}
          handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
          setloadingData={props.setIsLoading}
          {...props}
        />
      );
    case 4:
      return (
        <VehicleGuidedForm
          guidedWorkflowData={props.guidedWorkflowData}
          guidedVehicles={props.guidedVehicles}
          getAllVehicles={props.getAllVehicles}
          increaseActiveStep={props.increaseActiveStep}
          decreaseActiveStep={props.decreaseActiveStep}
          handleGuidedWorkflowDataUpdate={props.handleGuidedWorkflowDataUpdate}
          loadingData={props.isLoading}
          setloadingData={props.setIsLoading}
          {...props}
        />
      );
    default:
      return 'Unknown stepIndex';
  }
}
