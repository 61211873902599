import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

// third-party imports
import { Autocomplete } from 'formik-mui';
import { Field } from 'formik';

// project imports
import { countries } from 'utils';
import { inputVariant, allowedCountries } from 'constants';


// Autocomplete render options style and fields
const RenderOptions = ({ props, option }) => {
  return (
    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading='lazy'
        width='20'
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        alt=''
      />
      {option.phone}
    </Box>
  );
};

// ============= Country select autocomplete (Uses formik and formik 3rd party bindings) ============= //

function CountrySelect(props) {
  const {
    name,
    label,
    touched,
    errors,
    id,
    countryId,
    helperTextContent,
    variant = 'standard',
    disabled = false,
    textFieldProps = {},
    hiddenLabel = false
  } = props;

  return (
    <Field
      name={name}
      component={Autocomplete}
      id={countryId}
      fullWidth
      disabled={disabled}
      options={countries.filter((country) => allowedCountries.includes(country.code))}
      getOptionLabel={(option) => option.phone}
      renderOption={(props, option) => <RenderOptions props={props} option={option} />}
      clearIcon={null}
      renderInput={(params) => (
        <TextField
          {...params}
          hiddenLabel={hiddenLabel}
          id={id}
          name={name}
          error={touched[name] && !!errors[name]}
          helperText={Boolean(errors[name]) ? errors[name] : helperTextContent}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            readOnly: true,
          }}
          {...textFieldProps}
        />
      )}
    />
  );
}

CountrySelect.defaultProps = {
  type: 'phone',
};

CountrySelect.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

{
  /*
* TODO: Component can further be extended to support more props few examples
  Not only for country phone codes but for other available option labels
  Show country flag in autocomplete option label
  Fallback cases
*/
}

export default CountrySelect;
