import React from 'react';
import PropTypes from 'prop-types';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// material-ui
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project imports
import MainCard from 'components/MainCard';

export default function QuickViewItemAccordion({
  accordionSummary,
  children,
  id,
  isExpandable,
  isExpanded,
  isLoading,
  isSubmitting,
  label,
  startIcon,
  ...props
}) {
  /*
** a custom component for accordion 
* We can use all props of accordion provided by material ui in this component
* Props => 
*    1.) accordionSummary = it is required prop to render in accordion summary, it can be string or node,
*    2.) children         = it is required and will be rendered in accordion details, it can be node or string
*    3.) id               = not required can be used in testing
*    4.) isLoading        = for showing skeleton while loading is true (get API call)
*    5.) isSubmitting     = for showing skeleton while submitting is true (create or update API call)
*    6.) label            = it is required prop to render in accordion summary title always use string
*    7.) startIcon        = developer have to pass startIcon to show in start
*    8.) isExpandable     = boolean to show expanded content ( children )
    
* How to use => (use case)

  <QuickViewItemAccordion
    id='show_details'
    startIcon={<MyLocationOutlinedIcon fontSize='large' />}
    label={'Tracking and Navigation'}
    isExpandable={true}
    isLoading={false}
    accordionSummary={
      * pass string or node in this props
        'string' 
        * OR
        <Grid item>
          <Typography sx={{ml:1}} component='h5' variant='h5' color='textSecondary'>
            {'Yes'}
          </Typography>
        </Grid>
    }>
      <Grid item>
          <Typography variant='headline' color='textSecondary' style={{ fontWeight: 500 }}>
            Device Model
          </Typography>
      </Grid>
  </QuickViewItemAccordion> */

  const [open, setOpen] = React.useState(isExpanded);

  const handleAccordionToggle = () => {
    setOpen(!open);
  };

  return (
    <MainCard content={false} border boxShadow sx={{ my: 1 }}>
      <Accordion {...props} elevation={0} disableGutters expanded={open}>
        <AccordionSummary
          expandIcon={!isLoading && isExpandable && <ExpandMoreIcon color='primary' id={id} />}
          onClick={handleAccordionToggle}
          sx={{ px: 3 }}
        >
          <Stack direction='row' alignItems='center'>
            <Stack>{startIcon}</Stack>
            <Stack sx={{ pl: '25px' }}>
              {typeof label === 'string' && label.length !== 0 && (
                <Typography color='primary'>{label}</Typography>
              )}
              {isLoading || isSubmitting ? (
                <Skeleton />
              ) : typeof accordionSummary === 'string' && accordionSummary.length !== 0 ? (
                <Typography id={id} component='h5' variant='h5' color='textSecondary'>
                  {accordionSummary}
                </Typography>
              ) : (
                accordionSummary
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        {isExpandable && (
          <AccordionDetails sx={{ overflow: 'hidden' }}>
            <Divider />
            <Grid sx={{ pt: 2 }}>{children}</Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </MainCard>
  );
}

QuickViewItemAccordion.defaultProps = {
  id: 'show_details',
  isExpandable: true,
  isExpanded: false,
  isLoading: false,
  isSubmitting: false,
};

QuickViewItemAccordion.propTypes = {
  accordionSummary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.string,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.node.isRequired,
};
