export const queryKey = {
  STUDENTS: {
    ALL_STUDENTS: 'ALL_STUDENTS',
    STUDENT_QR_CODE: 'STUDENT_QR_CODE',
    STUDENT_PARENT_ACCOUNT: 'STUDENT_PARENT_ACCOUNT',
    STUDENT_IMAGE: 'STUDENT_IMAGE',
    STUDENT_ATTENDANCE_BY_TRIP_ID: 'STUDENT_ATTENDANCE_BY_TRIP_ID',
    ALL_STUDENTS_LIST: 'ALL_STUDENTS_LIST',
  },
  GUIDED_STUDENTS: {
    ALL_STUDENTS: 'ALL_STUDENTS',
  },
  DEVICE: {
    ALL_DEVICES: 'ALL_DEVICES',
  },
  INCIDENTS: {
    ALL_INCIDENTS: 'ALL_INCIDENTS',
    STATUS: 'STATUS',
    CONFIGURATION: 'CONFIGURATION',
    FILTERS: 'FILTERS',
  },
  TRIP_INSPECTION: {
    TRIP_INSPECTION_REPORTS: 'TRIP_INSPECTION_REPORTS',
  },
  DASHBOARD_USER: {
    ALL_DASHBOARD_USER: 'ALL_DASHBOARD_USER',
  },
  VEHICLES: {
    ALL_VEHICLES: 'ALL_VEHICLES',
  },
  DRIVERS: {
    ALL_DRIVERS: 'ALL_DRIVERS',
  },
  TRIP_INSPECTIONS: {
    ALL_TRIP_INSPECTIONS: 'ALL_TRIP_INSPECTIONS',
    TRIP_FROM_VEHICLE_ID: 'TRIP_FROM_VEHICLE_ID',
    TRIP_INSPECTION_REPORT_IMAGE: 'TRIP_INSPECTION_REPORT_IMAGE',
  },
  TRIP: {
    TRIP_INFORMATION: 'TRIP_INFORMATION',
  },
  SCHOOLS: {
    ALL_SCHOOLS: 'ALL_SCHOOLS',
  },
  LIVE_VIEW: 'LIVE_VIEW',
  DEFAULT_IMAGE: 'DEFAULT_IMAGE',
  ROUTE: {
    ROUTES_BY_SCHOOL: 'ROUTES_BY_SCHOOL',
  },
};
