import React from 'react';
import {
  Box,
  Stack,
  TableCell,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CustomTable from 'unauthorizedPages/CustomTable';
import { useState } from 'react';
import { StatusChip } from 'components/Chips';
import MapIcon from '@mui/icons-material/Map';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/Tabs/TabPanel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoopIcon from '@mui/icons-material/Loop';
import CountCard from './Routes/CountCard';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import HistoryIcon from '@mui/icons-material/History';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Chip from '@mui/material/Chip';
import { getTime } from 'containers/layouts/Utils/TimeHandling';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useEffect } from 'react';
import MainCard from 'components/MainCard';

const ROUTES_TABLE_HEADING = [
  { title: 'Route Number', icon: <ModeOfTravelIcon color='primary' /> },
  { title: 'Route Type', icon: <HistoryIcon color='primary' /> },
  { title: 'Schedule start time', icon: <AccessTimeIcon color='primary' /> },
  { title: 'Drivers', icon: <EngineeringIcon color='primary' /> },
  { title: 'Vehicles', icon: <DirectionsBusIcon color='primary' /> },
  { title: 'Stops', icon: <FmdGoodIcon color='primary' /> },
];

const TRIPS_TABLE_HEADING = [
  { title: 'Route Number', icon: <ModeOfTravelIcon color='primary' /> },
  { title: 'Route Type', icon: <HistoryIcon color='primary' /> },
  { title: 'Schedule start time', icon: <AccessTimeIcon color='primary' /> },
  { title: 'Actual start time', icon: <EngineeringIcon color='primary' /> },
  { title: 'Status', icon: <AutorenewIcon color='primary' /> },
  { title: 'Total Stops', icon: <FmdGoodIcon color='primary' /> },
  { title: 'Next Stop', icon: <FmdGoodIcon color='primary' /> },
];

export default function ListViewsOfRoutesandTrips({
  route,
  handleRouteDetails,
  setListDetails,
  setSelectedRowData,
  dataLoading,
  settingData,
  setSelectedDate,
  selectedDate,
  routeTrip,
  dataTripLoading,
  setRefreshPage,
  setSelectedTripRowData,
  checkDistrictLevel,
  setdistrictLevel,
  setRunOnce,
  runOnce,
  handleUrl,
}) {
  const theme = useTheme();

  // For Routes
  const [routeData, setRouteData] = useState(route);
  const [filledRows, setFilledRows] = useState([]);
  const [searched, setSearched] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(5);

  // For Trips
  const [filledRowsTrips, setFilledRowsTrips] = useState([]);
  const [dataLoadingTrips, setDataLoadingTrips] = useState(false);
  const [searchedTrips, setSearchedTrips] = useState('');
  const [pageTrips, setPageTrips] = useState(0);
  const [routeDataTrips, setRouteDataTrips] = useState();
  const [rowsPerPageTrips, setrowsPerPageTrips] = useState(10);
  const [selectedRouteType, setSelectedRouteType] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [filterRoutes, setFilterRoutes] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTripType, setSelectedTripType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filterTrip, setFilterTrip] = useState([]);

  const tableCellData = (row, index) => {
    return (
      <>
        <TableCell align='center' id={'route_id_' + index}>
          {row.school_route_number}
        </TableCell>
        <TableCell align='center' id={'route_type_' + index}>
          {
            <Chip
              label={row.route_type}
              color={row.route_type === 'AM' ? 'info' : 'primary'}
              variant='outlined'
              size='small'
            />
          }
        </TableCell>
        <TableCell align='center' id={'route_number_' + index}>
          {row.scheduled_start_time}
        </TableCell>
        {settingData?.settings.route_settings.show_driver_details === true ? (
          <TableCell align='center' id={'driver_name' + index}>
            {row.driver_info?.first_name === undefined
              ? '--'
              : row.driver_info?.first_name + ' ' + row.driver_info?.last_name}
          </TableCell>
        ) : null}
        {settingData?.settings.route_settings.show_vehicle_details === true ? (
          <TableCell align='center' id={'start_time_' + index}>
            {row.vehicle_info.licence_plate_number === undefined
              ? '--'
              : row.vehicle_info.licence_plate_number}
          </TableCell>
        ) : null}
        <TableCell align='center' id={'start_time_' + index}>
          {row.stops_count}
        </TableCell>
      </>
    );
  };

  const epochTime = (time) => {
    const milliseconds = Math.floor(time) * 1000;
    const dateObject = new Date(milliseconds);
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();
    function addZero(timeValue) {
      return String(timeValue).padStart(2, '0');
    }
    return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
  };

  const tableCellDataTrips = (row, index) => {
    return (
      <>
        <TableCell align='center'>
          <Typography id={'table_route_number_' + index}>
            {row.route_info.school_route_number}
          </Typography>
        </TableCell>
        <TableCell align='center' id={'table_route_type_' + index}>
          {row.route_info.route_type}
        </TableCell>
        <TableCell align='center' id={'table_scheduled_start_time_' + index}>
          {row.route_info.scheduled_start_time}
        </TableCell>
        <TableCell align='center' id={'table_scheduled_start_time_' + index}>
          {epochTime(row.trip_start_time.timestamp)}
        </TableCell>
        <TableCell align='center' id={'trip_school' + index}>
          <StatusChip value={row?.status} />
        </TableCell>
        <TableCell align='center' id={'table_scheduled_start_time_' + index}>
          {row.route_info.route_graph.length}
        </TableCell>
        <TableCell align='center' id={'table_scheduled_start_time_' + index}>
          {row.last_stop}
        </TableCell>
      </>
    );
  };

  const searchVariableForRoutes = (row) => {
    return (
      row.route_id.toLowerCase() +
      row.scheduled_start_time +
      row.stops_count +
      row.school_id.toLowerCase()
    );
  };

  const searchVariableForTrips = (row) => {
    return (
      row.route_info.school_route_number +
      row.route_info.route_type.toLowerCase() +
      row.route_info.scheduled_start_time.toLowerCase() +
      row?.status.toLowerCase()
    );
  };

  const selectedRoute = (data) => {
    setSelectedTripRowData(false);
    handleRouteDetails(data.route_id, data.session_id);
    setSelectedRowData(data);
    setListDetails(true);
    handleUrl('routes', data.route_id);
  };

  const selectedTripRoute = (data) => {
    setSelectedTripRowData(true);
    setSelectedRowData(data);
    setListDetails(true);
    handleUrl('trips', data.route_id);
  };

  const handleRouteTypeChange = (event) => {
    const { value } = event.target;
    const filteredRouteType = route.filter((item) => value.includes(item.route_type));
    setSelectedRouteType(value);
    setFilterRoutes([...filteredRouteType]);
  };

  const handleDriverChange = (event) => {
    const { value } = event.target;
    const filteredDriver = route.filter((item) => value.includes(item.driver_info.first_name));
    setSelectedDriver(value);
    setFilterRoutes([...filteredDriver]);
  };

  const handleVehicleChange = (event) => {
    const { value } = event.target;
    const filteredVehicle = route.filter((item) => value.includes(item.vehicle_id));
    setSelectedVehicle(value);
    setFilterRoutes([...filteredVehicle]);
  };

  const handleTripTypeChange = (event) => {
    const { value } = event.target;
    const filteredTripType = routeTrip.filter((item) => value.includes(item.route_info.route_type));
    setSelectedTripType(value);
    setFilterTrip([...filteredTripType]);
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    const filteredStatusType = routeTrip.filter((item) => value.includes(item.status));
    setSelectedStatus(value);
    setFilterTrip([...filteredStatusType]);
  };

  const driverList = route.reduce((driver, item) => {
    if (!driver.includes(item.driver_info.first_name)) {
      driver.push(item.driver_info.first_name);
    }
    return driver;
  }, []);

  const vehicleList = route.reduce((vehicle, item) => {
    if (!vehicle.includes(item.vehicle_id)) {
      vehicle.push(item.vehicle_id);
    }
    return vehicle;
  }, []);

  const routeTypeList = route.reduce((routeType, item) => {
    if (!routeType.includes(item.route_type)) {
      routeType.push(item.route_type);
    }
    return routeType;
  }, []);

  const tripRouteTypeList = routeTrip?.reduce((routeType, item) => {
    if (!routeType.includes(item.route_info.route_type)) {
      routeType.push(item.route_info.route_type);
    }
    return routeType;
  }, []);

  const tripStatusList = routeTrip?.reduce((routeType, item) => {
    if (!routeType.includes(item.status)) {
      routeType.push(item.status);
    }
    return routeType;
  }, []);
  const _handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `communication-simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const completedTrips = routeTrip?.filter((value) => value.status.includes('Completed'));
  const activeTrips = routeTrip?.filter((value) => value.status.includes('Active'));
  const breakdownTrips = routeTrip?.filter((value) => value.status.includes('Breakdown'));

  return (
    <>
      <Grid item xs={12} sx={{ p: 3, bgcolor: theme.palette.background.default }}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            {checkDistrictLevel === 'district' ? (
              <Grid container xs={0.5}>
                <Grid
                  sx={{ bgcolor: theme.palette.background.paper }}
                  container
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <IconButton
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      '&:hover': {
                        bgcolor: theme.palette.primary.light,
                      },
                    }}
                    onClick={() => setdistrictLevel(true)}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'flex-end'}
              xs={checkDistrictLevel === 'district' ? 11.5 : 12}
            >
              <Tabs value={activeTab} onChange={_handleTabChange} aria-label='basic tabs example'>
                {settingData?.settings.route_settings.enabled === true ? (
                  <Tab
                    sx={{ bgcolor: theme.palette.background.paper }}
                    label='Routes'
                    icon={<MapIcon />}
                    iconPosition='start'
                    {...a11yProps(0)}
                  />
                ) : null}
                {settingData?.settings.route_trips_settings.enabled === true ? (
                  <Tab
                    sx={{ bgcolor: theme.palette.background.paper }}
                    label='Route Trips'
                    icon={<DirectionsBusIcon />}
                    iconPosition='start'
                    {...a11yProps(1)}
                  />
                ) : null}
              </Tabs>
              <Grid>
                <IconButton
                  size='small'
                  onClick={() => setRefreshPage(Math.random())}
                  id='loopIcon'
                >
                  <LoopIcon />
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture={true}
                    fullWidth
                    id='date-picker-dialog'
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ pb: 2, pr: 1, pl: 4 }} variant='standard' {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          {settingData?.settings.route_settings?.enabled === true ? (
            <TabPanel boxPaddingTop={0} value={activeTab} index={0}>
              <Grid sx={{ bgcolor: theme.palette.background.paper }}>
                <Grid
                  xs={11}
                  container
                  justifyContent={'space-evenly'}
                  alignItems={'center'}
                  sx={{ bgcolor: theme.palette.background.paper, p: 5 }}
                >
                  <CountCard
                    title={'Total Available Routes'}
                    dataLoading={dataLoading}
                    count={route?.length}
                  />
                  <CountCard
                    title={'Total Assigned Vehicles'}
                    dataLoading={dataLoading}
                    count={vehicleList?.length}
                  />
                  <CountCard
                    title={'Total Assigned Drivers'}
                    dataLoading={dataLoading}
                    count={driverList?.length}
                  />
                </Grid>
                <CustomTable
                  icon={<MapIcon color={'primary'} />}
                  tableTitle={'Routes'}
                  setFilledRows={setFilledRows}
                  setSearched={setSearched}
                  setPage={setPage}
                  // fetchNextRoutes={fetchNextRoutes}
                  page={page}
                  isFromSearch={false}
                  routes={filterRoutes.length === 0 ? route : filterRoutes}
                  searched={searched}
                  filledRows={filledRows}
                  dataLoading={dataLoading}
                  schools={[]}
                  rowsPerPage={rowsPerPage}
                  tableHeadingData={ROUTES_TABLE_HEADING}
                  tableCellData={tableCellData}
                  searchVariable={searchVariableForRoutes}
                  selectedRows={selectedRoute}
                  settingData={settingData}
                  setRunOnce={setRunOnce}
                  runOnce={runOnce}
                  filterMenu={
                    <MainCard>
                      <Grid container xs={12}>
                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel>Route Type</FormLabel>
                            <Select
                              multiple
                              value={selectedRouteType}
                              onChange={handleRouteTypeChange}
                              renderValue={(selected) => selected.join(', ')}
                              sx={{ width: 120, height: 38 }}
                            >
                              {routeTypeList?.map((item) => (
                                <MenuItem sx={{ width: 120, height: 38 }} key={item} value={item}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={selectedRouteType.includes(item)} />
                                    }
                                    label={item}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Select drivers</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel>Drivers</FormLabel>
                            <Select
                              multiple
                              value={selectedDriver}
                              onChange={handleDriverChange}
                              renderValue={(selected) => selected.join(', ')}
                              sx={{ width: 120, height: 38 }}
                            >
                              {driverList?.map((item) => (
                                <MenuItem sx={{ width: 120, height: 38 }} key={item} value={item}>
                                  <FormControlLabel
                                    control={<Checkbox checked={selectedDriver.includes(item)} />}
                                    label={item}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Select drivers</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel>Vehicle</FormLabel>
                            <Select
                              multiple
                              value={selectedVehicle}
                              onChange={handleVehicleChange}
                              renderValue={(selected) => selected.join(', ')}
                              sx={{ width: 120, height: 38 }}
                            >
                              {vehicleList?.map((item) => (
                                <MenuItem sx={{ width: 120, height: 38 }} key={item} value={item}>
                                  <FormControlLabel
                                    control={<Checkbox checked={selectedVehicle.includes(item)} />}
                                    label={item}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Select vehicle</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </MainCard>
                  }
                />
              </Grid>
            </TabPanel>
          ) : null}
          {settingData?.settings.route_trips_settings.enabled === true ? (
            <TabPanel
              boxPaddingTop={0}
              value={activeTab}
              index={settingData?.settings.route_settings.enabled === false ? 0 : 1}
            >
              <Grid sx={{ bgcolor: theme.palette.background.paper }}>
                <Grid
                  container
                  justifyContent={'space-evenly'}
                  alignItems={'center'}
                  sx={{ bgcolor: theme.palette.background.paper, p: 5 }}
                >
                  <CountCard
                    title={'Total Available Trips'}
                    dataLoading={dataTripLoading}
                    count={routeTrip?.length}
                  />
                  <CountCard
                    title={'Total Active Trips'}
                    dataLoading={dataTripLoading}
                    count={activeTrips?.length}
                  />
                  <CountCard
                    title={'Total Completed Trips'}
                    dataLoading={dataTripLoading}
                    count={completedTrips?.length}
                  />
                  <CountCard
                    title={'Breakdown Trips'}
                    dataLoading={dataTripLoading}
                    count={breakdownTrips?.length}
                  />
                </Grid>
                <CustomTable
                  icon={<DirectionsBusIcon color={'primary'} />}
                  tableTitle={'Trips'}
                  setFilledRows={setFilledRowsTrips}
                  setSearched={setSearchedTrips}
                  setPage={setPageTrips}
                  page={pageTrips}
                  isFromSearch={false}
                  routes={filterTrip.length === 0 ? routeTrip : filterTrip}
                  searched={searchedTrips}
                  filledRows={filledRowsTrips}
                  dataLoading={dataTripLoading}
                  selectedRows={selectedTripRoute}
                  rowsPerPage={rowsPerPageTrips}
                  tableHeadingData={TRIPS_TABLE_HEADING}
                  tableCellData={tableCellDataTrips}
                  searchVariable={searchVariableForTrips}
                  settingData={settingData}
                  setRunOnce={setRunOnce}
                  runOnce={runOnce}
                  filterMenu={
                    <>
                      <Grid container xs={12}>
                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel>Route Type</FormLabel>
                            <Select
                              multiple
                              value={selectedTripType}
                              onChange={handleTripTypeChange}
                              renderValue={(selected) => selected.join(', ')}
                              sx={{ width: 120, height: 38 }}
                            >
                              {tripRouteTypeList?.map((item) => (
                                <MenuItem sx={{ width: 120, height: 38 }} key={item} value={item}>
                                  <FormControlLabel
                                    control={<Checkbox checked={selectedTripType.includes(item)} />}
                                    label={item}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Select Route Type</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel>Status</FormLabel>
                            <Select
                              multiple
                              value={selectedStatus}
                              onChange={handleStatusChange}
                              renderValue={(selected) => selected.join(', ')}
                              sx={{ width: 120, height: 38 }}
                            >
                              {tripStatusList?.map((item) => (
                                <MenuItem sx={{ width: 120, height: 38 }} key={item} value={item}>
                                  <FormControlLabel
                                    control={<Checkbox checked={selectedStatus.includes(item)} />}
                                    label={item}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Select drivers</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </Grid>
            </TabPanel>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
