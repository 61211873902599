import {
  SAVED_SEARCH_FORM_DIALOG,
  SAVED_SEARCH_DATA_REQUEST,
} from '../reduxConstants/savedConstants';

export const handleSavedSearchFormDialog = (bool) => {
  return { type: SAVED_SEARCH_FORM_DIALOG, payload: bool };
};

export const handleSavedSearchData = (searchData) => {
  return { type: SAVED_SEARCH_DATA_REQUEST, payload: searchData };
};
