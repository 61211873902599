import React from 'react';
// import PropTypes from 'prop-types';

//material ui
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//project imports
import MainCard from 'components/MainCard';

// icons
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';
const ImageSkeleton = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Skeleton variant='circle' width={75} height={75} />
      </Grid>
      <Grid item xs={9}>
        <Skeleton variant='rectangular' width={'100%'} height={75} />
      </Grid>
    </Grid>
  );
};

function ImageCardWithActions({
  id,
  name,
  avatar_url,
  listItemOne,
  listItemTwo,
  onDeleteClick,
  onEditClick,
  isLoading = false,
  apiLoadingStudent,
}) {
  const theme = useTheme();

  // const FirstIcon = listItemOne[1];
  // const SecondIcon = listItemTwo[1];

  const { apt_plot, street, state, zip_code, country } = listItemTwo;

  const fullAddress = `${street}, ${apt_plot}, ${state}, ${zip_code}, ${country}`;

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const avatarDimension = matchDownLG ? 55 : 75;

  return (
    <MainCard content={false} sx={{ padding: 2 }}>
      {isLoading && <ImageSkeleton />}
      {!isLoading && (
        <Grid container justifyContent='flex-start' alignItems='center' spacing={3}>
          <Grid item xs={3}>
            <Avatar
              alt='Student Images '
              src={avatar_url}
              sx={{ width: avatarDimension, height: avatarDimension }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid xs={12}>
              <Typography
                variant='h4'
                component='div'
                gutterBottom
                sx={{ textTransform: 'capitalize' }}
              >
                {name}
              </Typography>
              <Typography component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                <LocalPhoneIcon fontSize='small' color='secondary' sx={{ mr: 2 }} />
                <Typography variant='body1' component='span'>
                  {listItemOne}
                </Typography>
              </Typography>
              <Typography component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeIcon fontSize='small' color='secondary' sx={{ mr: 2 }} />
                <Typography variant='body1' component='span'>
                  {fullAddress}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='column' alignItems='flex-end'>
                <Stack direction='row' justifyContent='flex-end'>
                  <IconButton
                    disabled={apiLoadingStudent}
                    aria-label='delete'
                    size='small'
                    onClick={() => onEditClick(id)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color='error'
                    aria-label='delete'
                    size='small'
                    onClick={() => onDeleteClick(id)}
                    disabled={apiLoadingStudent}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={2} >
            <Grid container height='100px' alignItems='flex-end'>
              <Stack direction='row' justifyContent="flex-start">
                <IconButton aria-label='delete' size='small' onClick={() => onEditClick(id)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  color='error'
                  aria-label='delete'
                  size='small'
                  onClick={() => onDeleteClick(id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </MainCard>
  );
}

export default ImageCardWithActions;
