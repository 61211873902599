import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardMedia from '@mui/material/CardMedia';

export default function VideoGuides() {
  
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.heading}
      >
        <Grid>
          <Typography variant="h6">Video Guides</Typography>
        </Grid>
        <Grid>
          <Button
            size="small"
            variant="outlined"
            className={classes.buttonColor}
          >
            See more
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.heading}
      >
        <CardMedia
          component="video"
          className={classes.cover}
          image={'/static/videos/demo.mp4'}
          pause
        />
        <CardMedia
          component="video"
          className={classes.cover}
          image={'/static/videos/demo2.mp4'}
          autoPlay
        />
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonColor: {
    color: '#e1b101',
    borderColor: '#e1b101',
  },
  heading: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    width: '92%',
  },
  cover: {
    width: 140,
  },
}));
