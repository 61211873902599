import React from 'react';

// material ui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

// Project Import
import { FormHeader } from 'components/SectionForms';
import DriverShiftForm from './DriverShifts/DriverShiftForm';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { Stack } from '@mui/material';
import { SaveButton } from 'components/Buttons';
export default function DriverShifts({
  openDriverShiftsForm,
  handleOpenDriverShiftFormClose,
  formikProps,
  driverShifts,
  isUpdate,
  setOccurrenceType,
  driverData,
  selectedDriverUserId,
}) {
  return (
    <Dialog
      open={openDriverShiftsForm}
      aria-labelledby='form-dialog-title'
      fullWidth='md'
      maxWidth='md'
    >
      <Stack position={'relative'} overflow={'hidden'}>
        <DialogTitle sx={{ margin: 0, padding: 0 }}>
          <FormHeader
            title={isUpdate ? 'Edit Driver Shift' : 'Add Driver Shift'}
            id={'close_DriverShift_form'}
            onClose={() => handleOpenDriverShiftFormClose(formikProps)}
            icon={isUpdate ? <ManageHistoryIcon /> : <ManageHistoryIcon />}
            disableClose={formikProps.isSubmitting}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ margin: 0, padding: 0 }}>
          <Stack sx={{ height: 500, overflowY: 'auto', padding: 3 }}>
            <DriverShiftForm
              isUpdate={isUpdate}
              formikProps={formikProps}
              driverShifts={driverShifts}
              setOccurrenceType={setOccurrenceType}
              driverData={driverData}
              selectedDriverUserId={selectedDriverUserId}
            />
          </Stack>
          <Stack>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <SaveButton
                type='submit'
                form={'driverShiftForm'}
                loading={formikProps.isSubmitting}
                sx={{ m: 2 }}
              >
                {isUpdate ? 'UPDATE' : 'SAVE'}
              </SaveButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}
