import { Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import React from 'react';
import ShiftCard from './ShiftCard';
import DeleteAlert from 'components/alert/DeleteAlert';
import NoRecordDisplay from 'components/NoRecordDisplay';

import { useSnackbar } from 'notistack';
import { deleteDriverShift } from 'clients/driver';
export default function DriverShiftsList({
  driverShifts,
  setEditedDriverShiftFormData,
  setIsUpdate,
  driverShiftsFormData,
  setOpenDriverShiftsForm,
  setDriverShiftDeleteAlertOpen,
  driverShiftDeleteAlertOpen,
  setDriverShiftsFormData,
  setDriverShifts,
  editedDriverShiftFormData,
  setOccurrenceType,
  setShiftStartDate,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // for loading state when we are deleting driver shift from list button loader
  const [loading, setLoading] = React.useState(false);

  /**
   * Handles the deletion of a driver shift.
   *
   * @return {Promise<void>} - A promise that resolves when the driver shift is deleted successfully.
   */
  const handleDeleteDriverShift = async () => {
    console.log(editedDriverShiftFormData, 'driverShiftsFormData');
    setLoading(true);
    try {
      await deleteDriverShift(
        editedDriverShiftFormData.driver_id,
        editedDriverShiftFormData.shiftID,
      );
      setLoading(false);
      const updatedDriverShiftFormData = driverShiftsFormData.filter(
        (ele) => ele.shiftID !== editedDriverShiftFormData.shiftID,
      );
      setDriverShiftsFormData(updatedDriverShiftFormData);
      const updateEventsOnCalendar = driverShifts.filter(
        (ele) => ele.shiftID !== editedDriverShiftFormData.shiftID,
      );
      setDriverShifts(updateEventsOnCalendar);
      setDriverShiftDeleteAlertOpen(false);
      enqueueSnackbar('Driver shift deleted successfully', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Driver shift deletion failed', {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  return (
    <MainCard>
      <Stack>
        <Stack>
          <Typography variant='h5' color={'primary'}>
            List of shifts
          </Typography>
        </Stack>

        <Stack
          maxHeight='58vh'
          sx={{ overflowY: 'auto' }}
          position={'relative'}
          direction={'column'}
          flexDirection={'column-reverse'}
        >
          {driverShiftsFormData.length > 0 ? (
            driverShiftsFormData.map((data, index) => {
              return (
                <Stack key={index} my={1}>
                  <ShiftCard
                    data={data}
                    setEditedDriverShiftFormData={setEditedDriverShiftFormData}
                    setIsUpdate={setIsUpdate}
                    driverShiftsFormData={driverShiftsFormData}
                    index={index}
                    setOpenDriverShiftsForm={setOpenDriverShiftsForm}
                    setDriverShiftDeleteAlertOpen={setDriverShiftDeleteAlertOpen}
                    setOccurrenceType={setOccurrenceType}
                    setShiftStartDate={setShiftStartDate}
                  />
                </Stack>
              );
            })
          ) : (
            <Stack
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                // transform: 'translateY(-8rem)',
              }}
            >
              <NoRecordDisplay />
            </Stack>
          )}
        </Stack>
      </Stack>
      <DeleteAlert
        deleteItemName={'Shift'}
        open={driverShiftDeleteAlertOpen}
        setOpen={() => setDriverShiftDeleteAlertOpen(true)}
        setClose={() => setDriverShiftDeleteAlertOpen(false)}
        handleDelete={handleDeleteDriverShift}
        buttonLoader={loading}
      />
    </MainCard>
  );
}
