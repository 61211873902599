export const languages = [
  { name: 'Amharic', code: 'am-ET', value: 'amET' },
  { name: 'Arabic (Egypt)', code: 'ar-EG', value: 'arEG' },
  { name: 'Arabic (Saudi Arabia)', code: 'ar-SA', value: 'arSA' },
  { name: 'Arabic (Sudan)', code: 'ar-SD', value: 'arSD' },
  { name: 'Armenian', code: 'hy-AM', value: 'hyAM' },
  { name: 'Azerbaijani', code: 'az-AZ', value: 'azAZ' },
  { name: 'Bangla', code: 'bn-BD', value: 'bnBD' },
  { name: 'Bulgarian', code: 'bg-BG', value: 'bgBG' },
  { name: 'Catalan', code: 'ca-ES', value: 'caES' },
  { name: 'Chinese (Hong Kong)', code: 'zh-HK', value: 'zhHK' },
  { name: 'Chinese (Simplified)', code: 'zh-CN', value: 'zhCN' },
  { name: 'Chinese (Taiwan)', code: 'zh-TW', value: 'zhTW' },
  { name: 'Croatian', code: 'hr-HR', value: 'hrHR' },
  { name: 'Czech', code: 'cs-CZ', value: 'csCZ' },
  { name: 'Danish', code: 'da-DK', value: 'daDK' },
  { name: 'Dutch', code: 'nl-NL', value: 'nlNL' },
  { name: 'English (United States)', code: 'en-US', value: 'enUS', default: true },
  { name: 'Estonian', code: 'et-EE', value: 'etEE' },
  { name: 'Finnish', code: 'fi-FI', value: 'fiFI' },
  { name: 'French', code: 'fr-FR', value: 'frFR' },
  { name: 'German', code: 'de-DE', value: 'deDE' },
  { name: 'Greek', code: 'el-GR', value: 'elGR' },
  { name: 'Hebrew', code: 'he-IL', value: 'heIL' },
  { name: 'Hindi', code: 'hi-IN', value: 'hiIN' },
  { name: 'Hungarian', code: 'hu-HU', value: 'huHU' },
  { name: 'Icelandic', code: 'is-IS', value: 'isIS' },
  { name: 'Indonesian', code: 'id-ID', value: 'idID' },
  { name: 'Italian', code: 'it-IT', value: 'itIT' },
  { name: 'Japanese', code: 'ja-JP', value: 'jaJP' },
  { name: 'Khmer', code: 'kh-KH', value: 'khKH' },
  { name: 'Kazakh', code: 'kk-KZ', value: 'kkKZ' },
  { name: 'Korean', code: 'ko-KR', value: 'koKR' },
  { name: 'Kurdish (Central)', code: 'ku-CKB', value: 'kuCKB' },
  { name: 'Macedonian', code: 'mk-MK', value: 'mkMK' },
  { name: 'Myanmar', code: 'my-MY', value: 'myMY' },
  { name: 'Malay', code: 'ms-MS', value: 'msMS' },
  { name: 'Nepali', code: 'ne-NP', value: 'neNP' },
  { name: 'Norwegian (bokmål)', code: 'nb-NO', value: 'nbNO' },
  { name: 'Persian', code: 'fa-IR', value: 'faIR' },
  { name: 'Polish', code: 'pl-PL', value: 'plPL' },
  { name: 'Portuguese', code: 'pt-PT', value: 'ptPT' },
  { name: 'Portuguese (Brazil)', code: 'pt-BR', value: 'ptBR' },
  { name: 'Romanian', code: 'ro-RO', value: 'roRO' },
  { name: 'Russian', code: 'ru-RU', value: 'ruRU' },
  { name: 'Serbian', code: 'sr-RS', value: 'srRS' },
  { name: 'Sinhalese', code: 'si-LK', value: 'siLK' },
  { name: 'Slovak', code: 'sk-SK', value: 'skSK' },
  { name: 'Spanish', code: 'es-ES', value: 'esES' },
  { name: 'Swedish', code: 'sv-SE', value: 'svSE' },
  { name: 'Thai', code: 'th-TH', value: 'thTH' },
  { name: 'Turkish', code: 'tr-TR', value: 'trTR' },
  { name: 'Tagalog', code: 'tl-TL', value: 'tlTL' },
  { name: 'Ukrainian', code: 'uk-UA', value: 'ukUA' },
  { name: 'Urdu (Pakistan)', code: 'ur-PK', value: 'urPK' },
  { name: 'Vietnamese', code: 'vi-VN', value: 'viVN' },
];
