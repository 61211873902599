import React from 'react';
import { Stack } from '@mui/material';
import { Field } from 'formik';
import TimePickerField from 'components/Formik/TimePickerField';
import HistoryIcon from '@mui/icons-material/History';
import MainCard from 'components/MainCard';

export default function NonRepetitive({ variant }) {
  return (
    <MainCard content={false} sx={{ pt: 4, pb: 3, px: 2 }}>
      <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
        <Field
          variant={variant}
          name={'adhocStartTime'}
          helperText='Enter time'
          component={TimePickerField}
          Icon={<HistoryIcon />}
          label='Shift start time *'
        />

        <Field
          variant={variant}
          name={'adhocEndTime'}
          helperText='Enter time'
          component={TimePickerField}
          label='Shift end time *'
        />
      </Stack>
    </MainCard>
  );
}
