import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
//ICONS
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

//Project Import
import { ChipType } from './ChipType';
import dayjs from 'dayjs';
import IconWithText from 'components/IconWithText';
import TripColumns from './TripColumns';
import { DateAndTimeFormatter } from 'pages/Communication/utils/utils';
import { saveAs } from 'file-saver';
import {
  convertResponsesStaticsToString,
  getInspectionStatusLabel,
  getInspectionTypeLabel,
} from 'pages/TripInspection/TripInspectionReports/TripInspectionReportUtils';
import { SpotBusDateTime } from 'utils/spotbus-date-time';
import { StatusChip } from 'components/Chips';

export default function TripReport({ dataInspection, dataVehicle, dataDrivers }) {
  const reportType = dataInspection.type === 'PRE_TRIP' ? 'Pre Trip' : 'Post Trip';
  const vehicle = dataVehicle.filter((vehicle) => vehicle.vehicle_id === dataInspection.vehicle_id);
  const driver = dataDrivers.find(
    (drivers) => drivers.driver_username === dataInspection.submitted_by,
  );
  const toLocalDate = dataInspection.date;
  const format = 'MMM DD, YYYY';
  const formattedDate = dayjs(toLocalDate).format(format);

  const convertToAMPM = (date) => {
    const originalTime = new Date(date);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const convertedTime = originalTime.toLocaleTimeString('en-US', options);
    return convertedTime;
  };

  function formatTime(timeString) {
    const dateObject = new Date(timeString);

    if (isNaN(dateObject.getTime())) {
      return 'Invalid time format';
    }
    const hour = dateObject.getHours();
    const minute = dateObject.getMinutes();

    const amPm = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12;

    const formattedTime = `${adjustedHour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')} ${amPm}`;

    return formattedTime;
  }

  function generateCSV(e, rowData) {
    e.preventDefault();
    e.stopPropagation();
    const csvData = `Vehicle,Submission Time,Inspection Type,Status,Allotted Time,Time Taken,Answers Statistics
    ${
      vehicle[0]?.licence_plate_number === undefined
        ? '--'
        : vehicle[0]?.licence_plate_number
        ? `${
            vehicle[0]?.licence_plate_number === undefined ? '--' : vehicle[0]?.licence_plate_number
          }`
        : ''
    }, ${rowData?.end_time ? formatTime(rowData?.end_time) : ''},${
      rowData?.type ? getInspectionTypeLabel(rowData?.type) : ''
    },${rowData?.status ? getInspectionStatusLabel(rowData?.status) : ''},${
      rowData?.configuration.timer.minimum_duration
        ? DateAndTimeFormatter.addMinutesLabel(rowData?.configuration.timer.minimum_duration)
        : ''
    },${rowData?.time_taken ? DateAndTimeFormatter.addMinutesLabel(rowData?.time_taken) : ''},${
      rowData?.statistics ? convertResponsesStaticsToString(rowData?.statistics.answer_stats) : ''
    }`;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(
      blob,
      `${dataInspection.type}_INSPECTION_REPORT | ${formattedDate || '--'} VEHICLE-${
        vehicle[0]?.licence_plate_number === undefined ? '--' : vehicle[0]?.licence_plate_number
      }.csv`,
    );
  }

  console.log(dataInspection, 'trip_inspection');

  return (
    <MainCard>
      <Grid container justifyContent={'space-between'} alignItems={'center'} xs={12}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{ width: '100%' }}
          spacing={2}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='h4'>
              {reportType} Inspection Report -{' '}
              {vehicle[0]?.licence_plate_number === undefined
                ? '--'
                : vehicle[0]?.licence_plate_number}{' '}
              - {formattedDate}
            </Typography>
            <Tooltip title='Share'>
              <IconButton>
                <ShareOutlinedIcon color='secondary' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Download'>
              <IconButton onClick={(e) => generateCSV(e, dataInspection)}>
                <FileDownloadOutlinedIcon color='secondary' />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack>
            <ChipType sx={{ borderRadius: 2, p: 1, mt: 2 }} value={dataInspection.status} />
          </Stack>
        </Stack>

        <Stack
          direction={'row'}
          sx={{ width: '100%', mt: 3 }}
          justifyContent={'space-between'}
          spacing={3}
          flexWrap='wrap'
        >
          <TripColumns
            iconname={DirectionsBusFilledOutlinedIcon}
            title={'Vehicle'}
            detail={
              vehicle[0]?.licence_plate_number === undefined
                ? '--'
                : vehicle[0]?.licence_plate_number
            }
          />
          <TripColumns iconname={DateRangeOutlinedIcon} title={'Date'} detail={formattedDate} />
          <TripColumns
            iconname={AccessTimeOutlinedIcon}
            title={'Start - End Time'}
            detail={
              dataInspection?.categories === undefined
                ? null
                : `${formatTime(dataInspection?.start_time)} - ${formatTime(
                    dataInspection?.end_time,
                  )}`
            }
          />
          <TripColumns
            iconname={AccessTimeOutlinedIcon}
            title={'Time Taken / Allotted Time'}
            detail={`${DateAndTimeFormatter.addMinutesLabel(dataInspection.time_taken)} ${
              dataInspection.configuration.timer.minimum_duration === undefined
                ? '/ --'
                : '/ ' +
                  DateAndTimeFormatter.addMinutesLabel(
                    dataInspection.configuration.timer.minimum_duration,
                  )
            }`}
          />
          <TripColumns
            iconname={AccountCircleOutlinedIcon}
            title={'Submitted By'}
            detail={`${driver?.first_name || '--'} ${driver?.last_name || '--'}`}
          />
          <TripColumns
            iconname={ChecklistIcon}
            title={'Statistics'}
            detail={
              <Stack direction={'row'} spacing={2}>
                <IconWithText
                  title={dataInspection?.statistics?.total_questions}
                  toolTipTitle={'Total Questions'}
                  titleProps={{ variant: 'body1' }}
                  icon={HelpOutlineIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
                <IconWithText
                  title={dataInspection?.statistics?.total_answered}
                  toolTipTitle={'Total Answered'}
                  titleProps={{ variant: 'body1' }}
                  icon={ChecklistIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
                <IconWithText
                  title={dataInspection?.statistics?.total_skipped}
                  toolTipTitle={'Total Skipped'}
                  titleProps={{ variant: 'body1' }}
                  icon={RefreshIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />

                <IconWithText
                  title={dataInspection?.statistics?.total_justification_skipped}
                  toolTipTitle={'Total Justification Skipped'}
                  titleProps={{ variant: 'body1' }}
                  icon={EventNoteOutlinedIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
                <IconWithText
                  title={dataInspection?.statistics?.total_picture_attachments_skipped}
                  toolTipTitle={'Total Picture Attachments Skipped'}
                  titleProps={{ variant: 'body1' }}
                  icon={AddPhotoAlternateOutlinedIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
              </Stack>
            }
          />
        </Stack>
      </Grid>
    </MainCard>
  );
}
