import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Typography } from '@mui/material';

// project import
import MainCard from '../../MainCard';

// assets
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';

// ============================== STATISTICS - ROUTE TRIP CARD  ============================== //

const AnalyticRouteTrips = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  extra,
  Icon = null,
  description,
}) => (
  <MainCard>
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      <Grid item xs={10}>
        <Grid spacing={0.5}>
          <Typography variant='h5' color='textSecondary'>
            {title}
          </Typography>
          <Grid container alignItems='center'>
            <Grid item>
              <Typography variant='h2' color='inherit'>
                {count}
              </Typography>
            </Grid>
            {percentage && (
              <Grid item>
                <Chip
                  color={color}
                  icon={
                    <>
                      {!isLoss && (
                        <ShowChartOutlinedIcon style={{ fontSize: '0.75rem', color: 'inherit' }} />
                      )}
                      {isLoss && (
                        <ShowChartOutlinedIcon style={{ fontSize: '0.75rem', color: 'inherit' }} />
                      )}
                    </>
                  }
                  label={`${percentage}%`}
                  style={{ marginLeft: 20, paddingLeft: 1 }}
                  size='small'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Box sx={{ pt: 1 }}>
          <Typography variant='caption' color='textSecondary'>
            {description}
          </Typography>
        </Box>
        {/* <Box>
          <Typography variant='caption' color='textSecondary'>
            You made an extra{' '}
            <Typography
              component='span'
              variant='caption'
              sx={{ color: `${color || 'primary'}.main` }}
            >
              {extra}
            </Typography>{' '}
            this year
          </Typography>
        </Box> */}
      </Grid>
      <Grid item xs={2}>
        {Icon}
      </Grid>
    </Grid>
  </MainCard>
);

AnalyticRouteTrips.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

AnalyticRouteTrips.defaultProps = {
  color: 'primary',
};

export default AnalyticRouteTrips;
