import React from 'react';

// material-ui
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// assets
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import CameraEnhanceOutlinedIcon from '@mui/icons-material//CameraEnhanceOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NavigationIcon from '@mui/icons-material/Navigation';
import SaveIcon from '@mui/icons-material/Save';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// project imports
import ActionButton from 'components/Buttons/ActionButton';
import MainCard from 'components/MainCard';
import DeviceAttach from './DeviceAttach';

//FORMIK validations
const validationSchema = yup.object({
  licenseNumber: yup
    .string()
    .required('License plate number is required')
    .trim()
    .min(3, 'License plate num must be at least 3 characters'),

  modelNumber: yup
    .string()
    .required(`Model number is required`)
    .trim()
    .min(2, 'Model number must be at least 2 characters'),
  manufacturingCompany: yup
    .string()
    .required('Manufacturing company name is required')
    .trim()
    .min(2, 'Company name must be at least 2 characters'),

  capacity: yup
    .number()
    .typeError('Enter only numbers')
    .required('Seating capacity is required')
    .min(1, 'Capacity of a vehicle must be at least 1 seat'),

  transportationOrganization: yup
    .string()
    .required('Transportation organization is required')
    .trim()
    .min(2, 'Organization must be at least 2 characters'),
});

export default function AddEditForm(props) {
  const classes = useStyles();

  const formSubmit = (values) => {
    props.setButtonLoader(true);
    props.handleSubmit(values);
    props.setFlag(false);
    setTimeout(function() {
      if (props.isApiSuccesfull) {
        props.setIsApiSuccesfull(false);
      }
    }, 5000);
  };

  //formik initial values
  const formik = useFormik({
    initialValues: {
      licenseNumber: props.licenseNumber ? props.licenseNumber : '',
      modelNumber: props.modelNumber ? props.modelNumber : '',
      manufacturingCompany: props.manufacturingCompany ? props.manufacturingCompany : '',
      capacity: props.capacity ? props.capacity : '',
      disabledSeating: props.disabledSeatCount ? props.disabledSeatCount : '',
      transportationOrganization: props.transportationOrganization
        ? props.transportationOrganization
        : '',
      fueltank: props.fuel ? props.fuel : '',
      disableAccessibility: props.seat ? props.seat : '',
      trackingNavigation: props.track ? props.track : '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      props.setLicenseNumber(values.licenseNumber);
      props.setModelNumber(formik.values.modelNumber);
      props.setManufacturingCompany(values.manufacturingCompany);
      props.setDisabledSeating(values.disabledSeating);
      props.setCapacity(values.capacity);
      props.setTransportationOrganization(values.transportationOrganization);

      if (props.track === true && props.seat === true) {
        if (
          props.model &&
          props.model.trim().length > 0 &&
          props.deviceId &&
          props.deviceId.trim().length > 0 &&
          props.disabledSeatCount > 0
        ) {
          formSubmit(values);
        }
      } else if (props.track === true && props.seat === false) {
        if (
          props.model &&
          props.model.trim().length > 0 &&
          props.deviceId &&
          props.deviceId.trim().length > 0
        ) {
          formSubmit(values);
        }
      } else if (props.seat === true && props.track === false) {
        if (props.disabledSeatCount > 0) {
          formSubmit(values);
        }
      } else if (props.track === false && props.seat === false) {
        formSubmit(values);
      }
      props.setLicenseNumber(values.licenseNumber);
      props.setModelNumber(values.modelNumber);
      props.setManufacturingCompany(values.manufacturingCompany);
      props.setDisabledSeating(values.disabledSeating);
      props.setCapacity(values.capacity);
      props.setTransportationOrganization(values.transportationOrganization);
    },
  });

  //Vehicle fuel values
  const fuelMarks = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction='row'
        rowSpacing={4}
        spacing={3}
        sx={{ minHeight: props.isFromGuided ? 410 : 0 }}
      >
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            autoFocus
            id='license-number'
            name='licenseNumber'
            label='License Plate Number *'
            variant='standard'
            fullWidth
            value={formik.values.licenseNumber}
            onChange={(e) => {
              e.preventDefault();
              formik.handleChange(e);
              props.setLicenseNumber(e.target.value);
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SmartScreenIcon />
                </InputAdornment>
              ),
            }}
            error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
            helperText={formik.errors.licenseNumber ?? `Enter vehicle's license plate number`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Manufacturing Company *'
            variant='standard'
            id='manufacturing-company'
            name='manufacturingCompany'
            fullWidth
            value={formik.values.manufacturingCompany}
            onChange={(event) => {
              formik.handleChange(event);
              props.setManufacturingCompany(event.target.value);
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmojiTransportationIcon />
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.manufacturingCompany && Boolean(formik.errors.manufacturingCompany)
            }
            helperText={
              formik.errors.manufacturingCompany ?? `Enter vehicle's manufacturing company name`
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Transportation Organization *'
            variant='standard'
            id='transportation-organization'
            name='transportationOrganization'
            fullWidth
            value={formik.values.transportationOrganization}
            onChange={(event) => {
              formik.handleChange(event);
              props.setTransportationOrganization(event.target.value);
            }}
            error={
              formik.touched.transportationOrganization &&
              Boolean(formik.errors.transportationOrganization)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CorporateFareIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              formik.errors.transportationOrganization ??
              'Enter your Transportation Organization Name'
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            id='model-number'
            name='modelNumber'
            label='Model Number *'
            variant='standard'
            fullWidth
            value={formik.values.modelNumber}
            onChange={(event) => {
              formik.handleChange(event);
              props.setModelNumber(event.target.value);
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <DirectionsBusIcon />
                </InputAdornment>
              ),
            }}
            error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
            helperText={formik.errors.modelNumber ?? `Enter vehicle's Model Number`}
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id='model-year'
              inputFormat='yyyy'
              views={['year']}
              label='Model year *'
              variant='standard'
              name='modelYear'
              fullWidth
              value={props.modelYear}
              onChange={props.setModelYear}
              disableFuture={true}
              inputProps={{ readOnly: true }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  fullWidth
                  helperText={`Select vehicle's model year`}
                />
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <DepartureBoardIcon />
                  </InputAdornment>
                ),
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Seat Capacity *'
            variant='standard'
            id='seat-capacity'
            name='capacity'
            type='number'
            fullWidth
            InputProps={{
              inputProps: {
                max: 100,
                min: 0,
              },
              startAdornment: (
                <InputAdornment position='start'>
                  <AirlineSeatReclineNormalIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.capacity}
            onChange={(event) => {
              formik.handleChange(event);
              props.setCapacity(event.target.value);
              props.setFlag(false);
            }}
            error={formik.touched.capacity && Boolean(formik.errors.capacity)}
            helperText={formik.errors.capacity ?? `Enter vehicle's seating capacity`}
          />
        </Grid>
        <Grid item xs={12}>
          <MainCard content={false}>
            <Grid container sx={{ justifyContent: 'space-around', alignItems: 'center', py: 4 }}>
              <Grid item>
                <Typography>Vehicle Surveillance</Typography>
              </Grid>
              <Grid item>
                <Button
                  size='small'
                  id='front-cameras'
                  onClick={() => props.handleFront()}
                  variant={props.front ? 'contained' : 'outlined'}
                  sx={{
                    color: props.front ? 'primary' : 'secondary.main',
                    borderColor: props.front ? 'primary' : 'secondary.main',
                    borderRadius: 25,
                  }}
                  startIcon={<CameraEnhanceOutlinedIcon />}
                >
                  Front cameras
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id='back-cameras'
                  size='small'
                  onClick={props.handleBack}
                  variant={props.back ? 'contained' : 'outlined'}
                  sx={{
                    color: props.back ? 'primary' : 'secondary.main',
                    borderColor: props.back ? 'primary' : 'secondary.main',
                    borderRadius: 25,
                  }}
                  startIcon={<CameraEnhanceOutlinedIcon />}
                >
                  Back cameras
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id='side-cameras'
                  size='small'
                  onClick={props.handleSide}
                  variant={props.side ? 'contained' : 'outlined'}
                  sx={{
                    color: props.side ? 'primary' : 'secondary.main',
                    borderColor: props.side ? 'primary' : 'secondary.main',
                    borderRadius: 25,
                  }}
                  startIcon={<CameraEnhanceOutlinedIcon />}
                >
                  Side cameras
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id='internal-cameras'
                  size='small'
                  onClick={props.handleInternal}
                  variant={props.internal ? 'contained' : 'outlined'}
                  sx={{
                    color: props.internal ? 'primary' : 'secondary.main',
                    borderColor: props.internal ? 'primary' : 'secondary.main',
                    borderRadius: 25,
                  }}
                  startIcon={<CameraEnhanceOutlinedIcon />}
                >
                  Internal cameras
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={4} sx={{ marginTop: 0.7, pr: 2 }}>
          <Typography id='discrete-slider-custom' gutterBottom>
            Fuel capacity (Gallons)
          </Typography>
          <Slider
            id='fuel-capacity'
            value={props.fuel}
            onChange={(event, newValue) => props.setFuel(newValue)}
            label='Fuel Capacity (Gallons)'
            aria-labelledby='discrete-slider-custom'
            valueLabelDisplay='auto'
            step={10}
            marks={fuelMarks}
            min={10}
            max={100}
            sx={{ marginLeft: 1 }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            select
            id='disable-accessibility'
            label='Disable Accessibility *'
            variant='standard'
            name='disableAccessibility'
            open={props.menuOpen}
            onClose={props.handleMenuClose}
            onOpen={props.handleMenuOpen}
            fullWidth
            value={props.seat}
            onChange={(event) => {
              event.preventDefault();
              props.setDisabledSeating(event.target.value);
              props.disableSeat(event, formik.values.disableAccessibility);
              formik.handleChange(event);
              props.seatsAvailable(event);
              props.setDisabledSeatCount('');
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <WheelchairPickupIcon />
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.disableAccessibility && Boolean(formik.errors.disableAccessibility)
            }
            helperText={formik.errors.disableAccessibility ?? 'Select disable accessibility'}
          >
            <MenuItem id='disable-accessibility-yes' value={true}>
              Yes
            </MenuItem>
            <MenuItem id='disable-accessibility-no' value={false}>
              No
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={props.seat ? false : true}
            style={{ display: 'block' }}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            id='disabled-seating-capacity'
            name='disabledSeating'
            label='Available Seats'
            variant='standard'
            type='number'
            value={formik.values.disabledSeating}
            fullWidth
            onChange={(event) => {
              event.preventDefault();
              props.setDisabledSeatCount(event.target.value);
              formik.handleChange(event);
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AirlineSeatReclineNormalIcon />
                </InputAdornment>
              ),
            }}
          />
          {props.seat === true && props.flag ? (
            <FormHelperText style={{ color: 'red' }}>
              {props.disabledSeatCount === ''
                ? 'Seat count for disabled person is required'
                : props.disabledSeatCount !== '' && props.disabledSeatCount < 1
                ? 'Capacity for disabled in vehicle must be at least 1 seat'
                : null}
            </FormHelperText>
          ) : (
            <FormHelperText>{props.seat && 'Enter Tracking device model'}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            id='tracking-navigation'
            label='Tracking and Navigation *'
            variant='standard'
            name='trackingNavigation'
            open={props.menuOpen}
            onClose={props.handleMenuClose}
            onOpen={props.handleMenuOpen}
            fullWidth
            value={props.track}
            onChange={(event) => {
              // props.enableNavigation(event, formik.values.trackingNavigation);
              formik.handleChange(event);
              props.trackNavigate(event);
              props.setModel('');
              props.setDeviceId('');
              props.setInstallationDate(new Date());
              props.setFlag(false);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <NavigationIcon />
                </InputAdornment>
              ),
            }}
            error={formik.touched.trackingNavigation && Boolean(formik.errors.trackingNavigation)}
            helperText={formik.errors.trackingNavigation ?? 'Tracking and navigation availability'}
          >
            <MenuItem id='tracking-navigation-yes' value={true}>
              Yes
            </MenuItem>
            <MenuItem id='tracking-navigation-no' value={false}>
              No
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id='tracking-device-model'
            disabled={props.track ? false : true}
            fullWidth
            open={props.menuOpen}
            label='Tracking Device Model'
            variant='standard'
            name='model'
            value={props.model}
            onChange={(event) => {
              props.setModel(event.target.value);
              props.setFlag(false);
              formik.handleChange(event);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <GpsFixedIcon />
                </InputAdornment>
              ),
            }}
          />
          {formik.values.trackingNavigation && props.flag ? (
            <FormHelperText style={{ color: 'red' }}>
              {!props.model
                ? 'Tracking device model is required'
                : props.model.trim().length < 1
                ? 'Tracking device model number must be at least 1 characters'
                : null}
            </FormHelperText>
          ) : (
            <FormHelperText>
              {!props.deviceId && props.track && 'Enter Tracking device model'}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            id='tracking-device-id'
            disabled={props.track ? false : true}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            fullWidth
            open={props.menuOpen}
            label='Tracking Device ID'
            variant='standard'
            name='deviceId'
            value={props.deviceId}
            onChange={(event) => {
              props.setDeviceId(event.target.value);
              props.setFlag(false);
              formik.handleChange(event);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <GpsFixedIcon />
                </InputAdornment>
              ),
            }}
          />
          {formik.values.trackingNavigation && props.flag ? (
            <FormHelperText style={{ color: 'red' }}>
              {!props.deviceId
                ? 'Tracking device id is required'
                : props.deviceId.trim().length < 1
                ? 'Tracking device id must be at least 1 characters'
                : null}
            </FormHelperText>
          ) : (
            <FormHelperText>
              {!props.deviceId && props.track && 'Enter Tracking device id'}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id='tracking-device-installation-date'
              disabled={props.track ? false : true}
              disableFuture={true}
              label='Installation Date'
              margin='none'
              format='MM/dd/yyyy'
              value={props.installationDate}
              onChange={props.handleDateChange}
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{ readOnly: true }}
              renderInput={(params) => (
                <TextField
                  id='tracking-device-installation-date'
                  variant='standard'
                  fullWidth
                  helperText={props.track && `Select installation date`}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Stack>
        <DeviceAttach
          formikProps={formik}
          {...props}
          selectedData={props.selectedData}
          setSelectedData={props.setSelectedData}
        />
      </Stack>
      <Grid
        container
        justifyContent='space-between'
        xs={12}
        sx={{ mt: props.isFromGuided ? 49 : 8, mb: 3 }}
      >
        <Grid container xs={3}></Grid>
        <Grid container justifyContent='flex-end' xs={9}>
          <ActionButton
            id='create-update-vehicle-button'
            isLoading={props.buttonLoader}
            startIcon={<SaveIcon />}
            type='submit'
            onClick={() => {
              props.setFlag(true);
            }}
          >
            {props.editVehicleForm ? 'UPDATE VEHICLE' : 'ADD VEHICLE'}
          </ActionButton>
          {
            //! deprecated
            /* <LoadingButton
            variant='contained'
            type='submit'
            loading={props.buttonLoader}
            loadingPosition='start'
            onClick={() => {
              props.setFlag(true);
            }}
            startIcon={<SaveIcon />}
          >
            <Typography sx={{ paddingLeft: props.buttonLoader ? 6 : 0 }}>
              {props.editVehicleForm ? 'UPDATE VEHICLE' : 'ADD VEHICLE'}
            </Typography>
          </LoadingButton> */
          }
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  vehicleSurveillance: {
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    marginTop: 8,
  },
  vehicleButton: {},
}));
