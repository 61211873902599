import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Typography from '@mui/material/Typography';

// ============================== CLASSIFICATION - HEADING  ============================== //

function ClassificationHeading({ children, sx, ...props }) {
  return (
    <Typography sx={{ textTransform: 'capitalize', ...sx }} {...props}>
      {children}
    </Typography>
  );
}

ClassificationHeading.defaultProps = {
  variant: 'h5',
  color: 'primary',
  gutterBottom: true,
};

ClassificationHeading.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

export default ClassificationHeading;
