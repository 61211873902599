import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const ContainerEditableMenu = styled(Stack)(({ theme, flag, isEditable }) => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'max-content',
    minWidth: '5rem',
    cursor: 'pointer',
    padding: '0 10px',

    '&:hover': {
      backgroundColor: flag === 'status' ? '' : theme.palette.hover.paper,
      borderRadius: 20,
      '& .edit-icon': {
        marginTop: '0.1rem',
        display: 'inline-flex',
        '&:hover': {
          color: theme.palette.primary.main,
        },
        '&:active': {
          color: theme.palette.primary.dark,
        },
      },
    },
    '.css-pzlop0-MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
      display: 'none',
    },
    '.css-df7bat-MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
      display: 'none',
    },
    '.MuiSelect-select': {
      paddingRight: isEditable ? '10px !important' : '0px !important',
      fontSize: '0.75rem',
      fontWeight: '400',
      letterSpacing: '0.01071em',
      maxWidth: '4.5rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  };
});

const EditableMenu = ({
  list,
  value = '',
  handleListChange = () => {},
  loading = false,
  isShowEditIcon = false,
  isFullyHideEditIcon = true,
  isEditable = true,
  width = 85,
  isListOpen = false,
}) => {
  const [listValue, setListValue] = useState(value);
  const [open, setOpen] = useState(isListOpen);

  const handleChange = (event) => {
    event.stopPropagation();
    const { value } = event.target;
    setListValue(value);
    handleListChange(value);
    setOpen(false);
  };
  const handleOpen = (e) => {
    // e.stopPropagation();
    setOpen(true);
  };
  const label = list && list.find((item) => item.value === listValue)?.label;

  return (
    <ContainerEditableMenu onClick={handleOpen}>
      <FormControl>
        <Select
          variant='standard'
          labelId='editable-menu'
          disableUnderline
          open={open}
          IconComponent={forwardRef((e) => {
            return isEditable ? (
              <KeyboardArrowDownIcon style={{ fontSize: '20px' }} {...e} />
            ) : null;
          })}
          onClose={() => setOpen(false)}
          onOpen={handleOpen}
          value={listValue}
          onClick={(e) => e.stopPropagation()}
          onChange={handleChange}
          readOnly={!isEditable}
          sx={{
            // width: width,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 10,
            px: 5,
          }}
        >
          {list &&
            list.map((item, index) => {
              return (
                <MenuItem
                  sx={{ display: 'flex', alignItems: 'center' }}
                  key={index}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </ContainerEditableMenu>
  );
};

export const ShowMenu = ({ label }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }} spacing={1}>
      <Stack>{label}</Stack>
      <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
    </Stack>
  );
};

EditableMenu.propTypes = {
  list: PropTypes.array,
  value: PropTypes.string,
  handleListChange: PropTypes.func,
  loading: PropTypes.bool,
  isShowEditIcon: PropTypes.bool,
};

export default EditableMenu;
