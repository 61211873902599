import { Grid, Stack, Typography } from '@mui/material';
import AttachmentDialog from './AttachmentDialog';
import ErrorIcon from '@mui/icons-material/Error';
import { useState } from 'react';
import IconWithText from 'components/IconWithText';
import ImageComponent from './ImageComponent';

export default function Attachments({ data, dataInspection }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [attachmentType, setAttachmentType] = useState('');
  const [selectedImage, setSelectedImage] = useState();
  const [hasError, setHasError] = useState(false);

  const handleImage = (data) => {
    setAttachmentType('picture');
    setSelectedImage(data);
    setOpenDialog(true);
  };
  const handleVideo = (data) => {
    setAttachmentType('video');
    setSelectedImage(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setAttachmentType('');
    setOpenDialog(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant='h5' color='text'>
          Attachments
        </Typography>
        <Grid container justifyContent={'flex-start'} xs={12}>
          <Grid item xs={12}>
            <Typography variant='body1' sx={{ mb: 2 }} color='text.secondary'>
              Photos
            </Typography>
            <Stack direction={'row'} spacing={2} useFlexGap flexWrap='wrap'>
              {data?.picture_attachments &&
                data?.picture_attachments?.map((img) => (
                  <Stack
                    key={img.s3_url}
                    border={1}
                    borderColor='grey.300'
                    borderRadius={1}
                    height={40}
                    justifyContent={'center'}
                  >
                    {/* <img
                      role='presentation'
                      width={60}
                      alt='Not found'
                      id='not-found-page'
                      src={img.s3_url}
                      onClick={() => handleImage(img.s3_url)}
                      style={{ cursor: 'pointer' }}
                    /> */}
                    <ImageComponent
                      handleImage={handleImage}
                      dataInspection={dataInspection}
                      imageID={getLastPartOfUrl(img.s3_url)}
                      setHasError={setHasError}
                    />
                  </Stack>
                ))}

              {!data?.picture_attachments || data?.picture_attachments?.s3_url?.length === 0 ? (
                <IconWithText
                  title={'No photos attached.'}
                  titleProps={{ variant: 'caption' }}
                  icon={ErrorIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
              ) : null}
            </Stack>
            <Stack pt={1}>
              {hasError && (
                <Typography variant='caption' sx={{ mt: 3 }}>
                  Image Synchronization in progress.
                </Typography>
              )}
            </Stack>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography variant='body1' sx={{ mb: 2 }} color='text.secondary'>
              Videos
            </Typography>
            <Stack direction={'row'} spacing={2} useFlexGap flexWrap='wrap'>
              {data?.videos_attachments &&
                data?.videos_attachments?.map((vid) => (
                  <Stack key={vid.s3_url} border={1} borderColor='grey.300' borderRadius={1}>
                    <video
                      width={60}
                      height={40}
                      onClick={() => handleVideo(vid.s3_url)}
                      style={{ cursor: 'pointer' }}
                    >
                      <source src={vid.s3_url} type='video/mp4' />
                    </video>
                  </Stack>
                ))}
              {!data?.videos_attachments || data?.videos_attachments?.length === 0 ? (
                <IconWithText
                  title={'No videos attached.'}
                  titleProps={{ variant: 'caption' }}
                  icon={ErrorIcon}
                  iconProps={{ color: 'secondary', fontSize: 'small' }}
                />
              ) : null}
            </Stack>
          </Grid> */}
        </Grid>
      </Stack>
      {(data.picture_attachments || data.videos_attachments) && (
        <AttachmentDialog
          dataInspection={dataInspection}
          type={attachmentType}
          openDialog={openDialog}
          closeDialog={handleCloseDialog}
          image={selectedImage}
          images={data.picture_attachments}
          videos={data.videos_attachments}
        />
      )}
    </>
  );
}

// Get The Image URL id from the url
export function getLastPartOfUrl(url) {
  if (!Boolean(url)) {
    return '';
  }
  const parts = url.split('/');
  const lastPart = parts.pop();
  return lastPart;
}
