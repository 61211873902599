import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import QuestionDetails from './QuestionDetails';
import Attachments from './Attachments';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import IconWithText from 'components/IconWithText';
import MainCard from 'components/MainCard';
import StatusChip from 'components/Table/StatusChip';

export default function TripQues({ selectionQuestion, dataInspection }) {
  return (
    <Stack spacing={3} position={'relative'}>
      <Stack position={'sticky'} top={0} zIndex={2}>
        <Stack>
          <MainCard content={false} sx={{ p: 2 }}>
            <Typography variant='h4'>{selectionQuestion.name}</Typography>
            <Typography variant='body2'>{selectionQuestion.description}</Typography>
          </MainCard>
        </Stack>
        <Stack>{/* <StatusChip value={row?.status} /> */}</Stack>
      </Stack>
      {selectionQuestion.questions?.map((data) => (
        <QuestionDetails
          chipType={'pass'}
          key={data.id}
          data={data}
          details={
            <Grid item sx={{ pl: 3, pb: 3 }}>
              <Stack spacing={5}>
                <Grid container xs={12}>
                  <Grid xs={10.2}>
                    <Stack spacing={2}>
                      <Typography variant='h5' color='text'>
                        Notes
                      </Typography>
                      <Typography color='text'>{data.answer.justification}</Typography>
                      {data.answer.justification === undefined ? (
                        <IconWithText
                          title={'No notes are provided.'}
                          titleProps={{ variant: 'caption' }}
                          icon={ErrorIcon}
                          iconProps={{ color: 'secondary', fontSize: 'small' }}
                        />
                      ) : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={1.8}>
                    <Stack spacing={2}>
                      <Typography variant='h5' color='text'>
                        Severity Level
                      </Typography>
                      <Grid container alignItems={'center'}>
                        {data?.answer?.issue_automation?.severity_level === undefined ? null : (
                          <Typography color='primary'>
                            SEV
                            {data?.answer?.issue_automation?.severity_level === 2
                              ? '- 2 | Breakdown'
                              : '- 3 | Issue'}
                          </Typography>
                        )}
                        {/* {data.answer?.issue_automation === undefined ? null : (
                            <Tooltip title='Share'>
                              <OpenInNewOutlinedIcon
                                onClick={() => handleIssue(data.answer?.issue_automation.id)}
                                color='primary'
                                fontSize='small'
                              />
                            </Tooltip>
                          )} */}
                        {data.answer.issue_automation === undefined ? (
                          <IconWithText
                            title={'No issue provided.'}
                            titleProps={{ variant: 'caption' }}
                            icon={ErrorIcon}
                            iconProps={{ color: 'secondary', fontSize: 'small' }}
                          />
                        ) : null}
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
                <Attachments dataInspection={dataInspection} data={data.answer} />
              </Stack>
            </Grid>
          }
        />
      ))}
    </Stack>
  );
}
