import React from 'react';
import { Grid, Stack } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/**
 * Renders a public route card.
 *
 * @param {object} props - The props object containing the following properties:
 *   - children: The children components to render inside the card.
 *   - color: The color of the card.
 *   - school: The school object containing information about the school.
 *   - sx: Additional styling props.
 * @return {ReactElement} The rendered public route card.
 */
export const PublicRouteCard = (props) => {
  const { children, color, school, sx = {}, nextScreen = false, handleOnClick = () => {} } = props;
  const logo = school?.image?.url;
  return (
    <Stack
      direction={'row'}
      width={nextScreen ? '450px' : '400px'}
      border={1}
      borderRadius={'5px'}
      position={'relative'}
      borderRight={0}
      borderColor={'secondary.light'}
      m={1}
      {...sx}
    >
      <Stack
        border={1}
        // backgroundColor={color}
        width={'110px'}
        // height={'100%'}
        borderRadius={'5px 0px 0px 5px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        borderColor={'secondary.light'}
      >
        {logo ? (
          <img src={logo} width={'100%'} height={'auto'} alt='' />
        ) : (
          <SchoolIcon fontSize='large' />
        )}
      </Stack>
      <Stack width={'290px'} mx={2}>
        {children}
      </Stack>
      <Stack
        sx={{
          cursor: 'default',
          '&:hover': {
            cursor: nextScreen ? 'pointer' : 'default',
          },
        }}
        onClick={handleOnClick}
        position={'absolute'}
        top={0}
        right={0}
        minHeight={'100%'}
        backgroundColor={color}
        width={nextScreen ? '30px' : '8px'}
        justifyContent={'center'}
        alignContent={'center'}
        direction={'column'}
        style={{ borderRadius: '0px 5px 5px 0px' }}
      >
        {nextScreen ? (
          <Grid sx={{ pl: 1 }}>
            <ArrowForwardIosIcon />
          </Grid>
        ) : null}
      </Stack>
    </Stack>
  );
};
