import React from 'react';
import PropTypes from 'prop-types';

import GoogleMaps from './GoogleMaps';
import OpenStreetMap from './OpenStreetMap';

export default function Maps({ isPremiumUser, height, markers, isLoading, zoom }) {
  return isPremiumUser ? (
    <GoogleMaps height={height} markers={markers} isLoading={isLoading} />
  ) : (
    <OpenStreetMap height={height} markers={markers} isLoading={isLoading} zoom={zoom} />
  );
}

Maps.defaultProps = {
  height: 0.35,
  isPremiumUser: false,
  zoom: 13,
};

Maps.propTypes = {
  height: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  markers: PropTypes.array.isRequired,
  zoom: PropTypes.number,
};
