import React from 'react';
import { Grid, Box, Stack, Typography, Skeleton } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import ListViewsOfRoutesandTrips from './ListViewsOfRoutesandTrips';

import Details from './Routes/Details';
import DistrictLevelSchoolInformation from './DistrictLevelSchools/DistrictLevelSchoolInformation';
import SpotBusLogo from 'components/SpotBusLogo';
import Progress from 'components/ProgressBar/Progress';

export default function QuickDetailsOfRoutesandTrips({
  routeData,
  tripData,
  handleRouteDetails,
  dataLoading,
  settingData,
  setSelectedDate,
  selectedDate,
  routeTrip,
  dataTripLoading,
  setRefreshPage,
  schoolData,
  handleOnClickSchool,
  selectedSchool,
  setSelectedSchool,
  districtLevel,
  setdistrictLevel,
  checkDistrictLevelOrSchoolLevel,
  loading,
  listDetails,
  setListDetails,
  selectedRowData,
  setSelectedRowData,
  selectedTripRowData,
  setSelectedTripRowData,
  runOnce,
  setRunOnce,
  handleUrl,
  handleUrlBack,
}) {
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12} sx={{ p: 3 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Box sx={{ width: '150px' }}>
              <SpotBusLogo width={150} />
            </Box>
            <Box>
              <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                {districtLevel === false ? (
                  <Avatar
                    alt={selectedSchool?.school_name}
                    src={selectedSchool?.image === undefined ? '' : selectedSchool?.image?.url}
                  >
                    {selectedSchool?.school_name[0]}
                  </Avatar>
                ) : null}
                <Typography variant='h4'>
                  {districtLevel === true ? (
                    'District Level Public Route Dashboard'
                  ) : !Boolean(selectedSchool?.school_name) ? (
                    <Skeleton variant='rectangular' width={210} height={40} />
                  ) : (
                    selectedSchool?.school_name
                  )}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        {districtLevel === true ? (
          <Grid item xs={12}>
            <DistrictLevelSchoolInformation
              schoolData={schoolData}
              handleOnClickSchool={handleOnClickSchool}
              settingData={settingData}
              loading={loading}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {!listDetails ? (
              <ListViewsOfRoutesandTrips
                route={routeData}
                handleRouteDetails={handleRouteDetails}
                setListDetails={setListDetails}
                listDetails={listDetails}
                setSelectedRowData={setSelectedRowData}
                dataLoading={dataLoading}
                settingData={settingData}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                routeTrip={routeTrip}
                dataTripLoading={dataTripLoading}
                setRefreshPage={setRefreshPage}
                setSelectedTripRowData={setSelectedTripRowData}
                setSelectedSchool={setSelectedSchool}
                districtLevel={districtLevel}
                setdistrictLevel={setdistrictLevel}
                checkDistrictLevel={checkDistrictLevelOrSchoolLevel}
                setRunOnce={setRunOnce}
                runOnce={runOnce}
                handleUrl={handleUrl}
              />
            ) : selectedRowData ? (
              <Details
                tripData={
                  selectedTripRowData === true ? selectedRowData.route_info.route_graph : tripData
                }
                settingData={settingData}
                setListDetails={setListDetails}
                listDetails={listDetails}
                selectedRowData={selectedRowData}
                selectedTripRowData={selectedTripRowData}
                selectedSchool={selectedSchool}
                handleUrlBack={handleUrlBack}
              />
            ) : (
              <Progress />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
