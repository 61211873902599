import React from 'react';
// MUI
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Images
import ErrorScreenBg from '../../assets/ErrorBg.webp';
import SpotbusLogo from './../../assets/static/images/SpotbusPublicDashboardLogo.png';
import LaunchIcon from '@mui/icons-material/Launch';

export const PublicDashboardErrorScreen = () => {
  return (
    <ErrorMainScreen>
      <img
        src={SpotbusLogo}
        alt='SpotBus-Logo'
        style={{
          padding: '1rem',
          width: '357px',
          height: 'auto',
        }}
      />

      <ErrorContains>
        <FourZeroFour>404</FourZeroFour>
        <Typography variant='h1' align='center' color='error' gutterBottom>
          Error !
        </Typography>
        <Typography variant='h3' gutterBottom>
          The provided URL is invalid or data does not exist.
        </Typography>
        <Typography variant='h3' gutterBottom>
          Please double-check the URL and try again.
        </Typography>
      </ErrorContains>
    </ErrorMainScreen>
  );
};

export default PublicDashboardErrorScreen;

const ErrorMainScreen = styled(Stack)(({ theme }) => {
  return {
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${ErrorScreenBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };
});

const ErrorContains = styled(Stack)(({ theme }) => {
  return {
    position: 'absolute',
    top: ' 50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const FourZeroFour = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.error.main,
    fontSize: '13rem',
    fontWeight: 'bold',
    WebkitTextFillColor: theme.palette.background.paper,
    WebkitTextStrokeWidth: '8px',
    WebkitTextStrokeColor: theme.palette.error.main,
    textShadow: '0px 0px 9px rgba(0, 0, 0, 0.51)',
    lineHeight: '1',
  };
});
