import React from 'react';

// material-ui
import { Typography, Box  } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menus/DrawerMenu';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'drawerGroup':
                return <NavGroup key={item.id} item={item} />;
            case 'headerGroup':
                return
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
