import React from 'react';

// third party imports
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

// MUI imports
import { Grid } from '@mui/material';

// project imports
import StudentStepperHeader from './Components/StudentStepperHeader';
import {
  validateBulkStudentImport,
  getStudentsBulkImportStatus,
  cancelStudentsBulkImport,
} from '../../../../clients/SpotBus';
import { getErrorMessage } from '../../Utils/ErrorHandling';
import AbortAlert from './Components/AbortAlert';

function generateCSV() {
  // Replace this with your CSV data generation logic
  const csvData = `first_name,last_name,gender,school_id,grade,special_needs,email,phone_number,fathers_first_name,fathers_last_name,mothers_first_name,mothers_last_name,address,driver_notes,school_notes
Jam,Bean,Male,SEA1,6,FALSE,jambean@stevenson.edu,4019992847,Jam,Bean,Rebecca,Bean,"""1203 147th Ave SE Bellevue WA 98007""","""Bean father will pick him after school for today""","""Today his father will pick him up after school."""
Anne,Frey,Female,SEA1,7,FALSE,anne@stevnsons.edu,4019992848,Will,Frey,Sawn,Frey,"""14150 NE 20th St Bellevue WA 98007""","""We are not able to receive our child today. Instead her uncle Benjium will be available there.""","""She forgot her sports shoes at home."""`;

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, 'sample-student-import.csv');
}

export default function StudentImport(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = React.useState('');
  const [data, setData] = React.useState(undefined);
  const [childValidationFilter, setChildValidationFilter] = React.useState('all_students');
  const [activeStep, setActiveStep] = React.useState(0);
  const [uploadButton, setUploadButton] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [uploadLoader, setUploadLoader] = React.useState(false);
  const [abortbutton, setAbortbutton] = React.useState(false);

  const [abortAlertValue, setAbortAlertValue] = React.useState(false);

  React.useEffect(() => {
    return closeSnackbar();
  }, []);

  async function handleFileUpload() {
    setUploadLoader(true);

    try {
      const formData = new FormData();
      formData.append('csvFile', selectedFile);
      console.log(formData);

      const result = await validateBulkStudentImport(props.username, formData);
      setData(result);

      if (result.status === 'VALIDATION_FAILED') {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        handleValidatedFileUpload(result);
      }

      console.log(JSON.stringify(result, null, 2));
      setUploadLoader(false);
    } catch (error) {
      console.log('Error while getting validated data' + JSON.stringify(error, null, 2));
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setUploadLoader(false);
    }
  }

  function handleValidatedFileUpload(prevData) {
    const timer = setInterval(async () => {
      const result = await getStudentsBulkImportStatus(props.username, prevData.jobId);
      if (result.completed_items == prevData.total_items) {
        stopTimer();
      }
      if (result.status === 'CANCELLED') {
        stopTimer();
      }

      setProgress(Math.round((result.completed_items / result.total_items) * 100));
    }, 1200);

    function stopTimer() {
      setActiveStep(3);
      clearInterval(timer);
      enqueueSnackbar('Student bulk import successful', {
        variant: 'success',
      });
    }
  }

  function handleAbortProcess() {
    // props.setIsLoading(true);
    setAbortbutton(true);
    setSelectedFile('');
    setData(undefined);
    setActiveStep(0);
    setAbortAlertValue(false);
    // props.setIsLoading(false);
    setAbortbutton(false);
    // return new Promise((resolve, reject) => {
    //   cancelStudentsBulkImport(props.username, data.jobId).then(
    //     (result) => {
    //       console.log(JSON.stringify(result, null, 2));
    //       setSelectedFile('');
    //       setData(undefined);
    //       setActiveStep(0);
    //       setAbortAlertValue(false);
    //       // props.setIsLoading(false);
    //       setAbortbutton(false);
    //       resolve();
    //     },
    //     (error) => {
    //       console.log(JSON.stringify(error, null, 2));
    //       setSelectedFile('');
    //       setData(undefined);
    //       setActiveStep(0);
    //       setAbortAlertValue(false);
    //       // props.setIsLoading(false);
    //       setAbortbutton(false);
    //       reject();
    //     },
    //   );
    // });
  }

  function handleFilterStudents() {
    if (data && data.validation_result && childValidationFilter === 'failed_students') {
      let failedStudents = data.validation_result.filter((student) => student.is_valid === false);
      return { ...data, validation_result: failedStudents };
    }
    if (data && data.validation_result && childValidationFilter === 'passed_students') {
      let passedStudents = data.validation_result.filter((student) => student.is_valid === true);
      if (passedStudents.length === 0) {
        return data;
      }
      return { ...data, validation_result: passedStudents };
    }
    return data;
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StudentStepperHeader
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleFileUpload={handleFileUpload}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            data={handleFilterStudents()}
            childValidationFilter={childValidationFilter}
            setChildValidationFilter={setChildValidationFilter}
            uploadButton={uploadButton}
            progress={progress}
            setAbortAlertValue={setAbortAlertValue}
            templateFileDownload={generateCSV}
            uploadloader={uploadLoader}
            {...props}
          />
        </Grid>
      </Grid>

      <AbortAlert
        open={abortAlertValue}
        setClose={() => setAbortAlertValue(false)}
        handleAbortProcess={handleAbortProcess}
        abortbutton={abortbutton}
      />
    </div>
  );
}
