export const routeIdFormatted = (school_id = '', routeID = '', routeType = '') => {
  return `${school_id ? school_id : '--'} - ${routeID ? routeID : '--'} | ${
    routeType ? routeType : '--'
  }`;
};

export const routeNumberFormatter = (
  schools = [],
  schoolID = '',
  routeNumber = '',
  routeType = '',
) => {
  const findSchoolAbbreviation = (schools, schoolID) => {
    const foundSchool = schools.find((ele) => ele.school_id === schoolID);
    return foundSchool?.abbreviation ?? '--';
  };

  if (!Array.isArray(schools)) {
    return '--';
  }

  const schoolAbbreviation = findSchoolAbbreviation(schools, schoolID);
  const formattedRouteNumber = routeNumber ? routeNumber : '--';
  const formattedRouteType = routeType ? routeType : '--';

  return `${schoolAbbreviation} - ${formattedRouteNumber} | ${formattedRouteType}`;
};

export const getSchoolAbbreviation = (schools, schoolID) => {
  if (!schools || !schoolID) {
    return '--';
  }

  const findschool = schools.find((ele) => ele.school_id === schoolID);
  return findschool ? findschool.abbreviation : '--';
};
