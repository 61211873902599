import React, { Component } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Copyright from './Copyright';
import EmailTextField from '../../../components/Form/EmailTextField';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { SignIn } from "aws-amplify-react";
import LOGGER from "../../../Logger"
import { Hub, Auth } from 'aws-amplify';
import VerificationCodeTextField from '../../../components/Form/VerificationCodeTextField';
import PasswordTextField from '../../../components/Form/PasswordTextField';
import { Navigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
      margin: theme.spacing(5)
  },
}));

const ERR_MESSAGES = {
  "UserNotConfirmedException": "Account confirmation pending, please contact support@spotbus.us",
  "InvalidParameterException": "Please correct invalid fields in form and try again.",
  "LimitExceededException": "Limit exceeded exception, please try again later.",
  "ELSE": "Something went wrong, please try again later."
}


class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: undefined,
      verificationCode: undefined,
      password: undefined,
      confirmpassword: undefined,
      isUsernameValidated: false,
      isPasswordValidated: false,
      isConfirmPasswordValidated: false,
      isVerificationCodeValidated:false,
      isLoading: false,
      showErrorSnackBar: false,
      error: undefined,
      showConfirmationCode: false,
      passwordResetCompleted: false,
      buttonText: "Request Verification Code"
    }

    this.onRecoverAccountClicked.bind(this)
  }

  getErrorMessage(errorCode){
      if (errorCode in ERR_MESSAGES){
          return ERR_MESSAGES[errorCode]
      }

      return ERR_MESSAGES["ELSE"]
  }
  onRecoverAccountClicked(event){
    event.preventDefault(); 
    this.setState({
        isLoading: true
      })

    if (this.state.showConfirmationCode){
        // CONFIRM CODE
        Auth.forgotPasswordSubmit(this.state.username, this.state.verificationCode, this.state.password)
        .then(data => {
            console.log("Password reset successful: "+JSON.stringify(data))
            this.setState({
                isLoading: true,
                showConfirmationCode: true,
                successMessage: "Password reset successfully completed.",
                showSuccessSnackBar: true,
                buttonText: "Reset Password"
              })
            setTimeout(function(){
                this.setState({passwordResetCompleted: true});
            }.bind(this),2000);
        })
        .catch(err => {
            console.log("Error in Password Recovery Code Requested: "+JSON.stringify(err))
            this.setState({
                error: this.getErrorMessage(err.code),
                isLoading: false,
                showErrorSnackBar:true
              })
            
        })
    } else {
        // FORGOT PASSWORD
        Auth.forgotPassword(this.state.username)
        .then(data => {
            console.log("Password Recovery Code Requested: "+JSON.stringify(data))
            this.setState({
                isLoading: false,
                showConfirmationCode: true,
                successMessage: "Password recovery code sent to your registered email address.",
                showSuccessSnackBar: true,
                buttonText: "Recover account"
              })
        })
        .catch(err => {
            console.log("Error in Password Recovery Code Requested: "+JSON.stringify(err))
            this.setState({
                error: this.getErrorMessage(err.code),
                isLoading: false,
                showErrorSnackBar:true
              })
            
        })
    }
    
    
  }
  
  renderCodeAndPasswordFields(){
      return(
          <React.Fragment>
              <Grid item xs={12} sm={12}>
                      <VerificationCodeTextField
                        onChange={(e) => {this.setState({verificationCode: e.target.value})}}
                        value={this.state.verificationCode}
                        id="verificationcode"
                        name="verificationcode"
                        validationstatushandler={(st) => {this.setState({isVerificationCodeValidated: st})}}
                        disabled={this.showConfirmationCode || this.state.isLoading}
                      />
    
                </Grid>

                  <Grid item xs={12}>
                    <PasswordTextField
                        onChange={(e) => {this.setState({password: e.target.value})}}
                        value={this.state.password}
                        autoComplete="current-password"
                        validationstatushandler={(st) => {this.setState({isPasswordValidated: st})}}
                        disabled={this.state.isLoading}
                        />
                </Grid>

                <Grid item xs={12}>
                    <PasswordTextField
                        name="confirmpassword"
                        label="Confirm Password"
                        id="confirmpassword"
                        onChange={(e) => {this.setState({confirmPassword: e.target.value})}}
                        value={this.state.confirmPassword}
                        validationRules={[
                            {
                                function: (value) => {
                                    if (value !== this.state.password) {
                                        return false;
                                    }
                                    return true;
                                },
                                errorMessage: "Confirm Password should match with the password."
                            }
                            
                        ]}
                        validationstatushandler={(st) => {this.setState({isConfirmPasswordValidated: st})}}
                        disabled={this.state.isLoading}
                        />
                </Grid>
          </React.Fragment>
      )
  }

  showSignIn(){
    Hub.dispatch(
        'authPage', 
        { 
            event: 'SIGN_IN', 
            data: {color:'blue'}, 
            message:'Navigate to sign IN.' 
    });
  }

  renderForgotPasswordForm(classes){
    return(
        <div className={classes.paper}>
            <CardMedia 
                className={classes.media}
                src={require("../../../assets/static/images/SpotBus_Logo.png")}
                component="img"
            />
            
            <Typography component="h1" variant="h5" align={"left"}>
              Recover your account {this.state.showConfirmationCode} {this.state.isLoading}
            </Typography>
            <form className={classes.form} noValidate onSubmit={(event) => {event.preventDefault(); }}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Snackbar 
                    open={this.state.showErrorSnackBar} 
                    autoHideDuration={6000} 
                    onClose={(e) => {this.setState({showErrorSnackBar: false})}}>
                        <MuiAlert  
                          elevation={6} 
                          variant="filled" 
                          onClose={(e) => {this.setState({showErrorSnackBar: false})}} 
                          severity="error">
                            {this.state.error}
                        </MuiAlert>
                  </Snackbar>
                  </Grid>

                  <Grid item xs={12}>

                    <Snackbar 
                        open={this.state.showSuccessSnackBar} 
                        autoHideDuration={6000} 
                        onClose={(e) => {this.setState({showSuccessSnackBar: false})}}>
                            <MuiAlert  
                            elevation={6} 
                            variant="filled" 
                            onClose={(e) => {this.setState({showSuccessSnackBar: false})}} 
                            severity="success">
                                {this.state.successMessage}
                            </MuiAlert>
                    </Snackbar>
                  </Grid>
    
                  <Grid item xs={12} sm={12}>
                      <EmailTextField
                        onChange={(e) => {this.setState({username: e.target.value})}}
                        value={this.state.username}
                        id="username"
                        name="username"
                        validationstatushandler={(st) => {this.setState({isUsernameValidated: st})}}
                        disabled={this.state.showConfirmationCode || this.state.isLoading}
                      />
    
                  </Grid>
                  {this.state.showConfirmationCode && this.renderCodeAndPasswordFields()}
                  
              </Grid>
              
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!(this.state.isUsernameValidated) || this.state.isLoading}
                onClick={this.onRecoverAccountClicked.bind(this) }
              >
                {this.state.buttonText}
              </Button>

              <Grid container>
                <Grid item>
                  <Link onClick={this.showSignIn} variant="body2">
                    {"Remember your password? Sign In"}
                  </Link>
                </Grid>
              </Grid>
    
            </form>
          </div>
          
    );
  }
  render() {
      const classes = this.props.classes
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {this.state.passwordResetCompleted ? <Navigate to="/" /> : this.renderForgotPasswordForm(classes) }
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      );
    }

}


export default withStyles(useStyles)(ForgotPassword);