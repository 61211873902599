import React from 'react';

// third-party
import L from 'leaflet';
import { TileLayer, Polyline, Marker, MapContainer, Tooltip, useMap, Popup } from 'react-leaflet';

// mui
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// assets
import 'leaflet/dist/leaflet.css';

// styles define
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  schoolTooltip: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  popupText: {
    // fontSize: '50px',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },

  popupContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #37474f',
    backgroundColor: theme.palette.secondary.main,
    height: 25,
    width: 25,
    borderRadius: '50%',
  },
  popupSchool: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #37474f',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const stopMarkerIcon = new L.Icon({
  iconUrl: '/static/images/icons/sb-mappin-black.svg',
  iconSize: [35, 35],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const stopNumberIcon = new L.Icon({
  iconUrl: '/static/images/icons/final.png',
  iconSize: [30, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const schoolMarkerIcon = new L.Icon({
  iconUrl: '/static/images/icons/outline_school.png',
  iconSize: [55, 55],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const sourceMarker = new L.Icon({
  iconUrl: '/static/images/icons/startIcon.png',
  iconSize: [35, 35],
});

const destinationMarker = new L.Icon({
  iconUrl: '/static/images/icons/flag.png',
  iconSize: [35, 35],
});

function getCenter(props) {
  let schoolStop = props.routeGraph.find((e) => e.route_stop_id == 'source');
  return [Number(schoolStop.geo_location.latitude), Number(schoolStop.geo_location.longitude)];
}

function PlotSchoolMarker(props) {
  const classes = useStyles();
  let school = props.school;
  if (!school) return;

  return (
    <Marker
      key={school.school_id}
      position={[Number(school.geo_location.latitude), Number(school.geo_location.longitude)]}
      icon={schoolMarkerIcon}
    >
      <Tooltip
        direction='bottom'
        offset={[11, -2]}
        opacity={0.8}
        permanent
        className={classes.popupSchool}
      >
        <span className={classes.schoolTooltip}>{school.address.entity}</span>
      </Tooltip>
    </Marker>
  );
}

function PlotSourceDestinationMarkers(props) {
  let source = props.routeGraph.find((e) => e.route_stop_id == 'source');
  let destination = props.routeGraph.find((e) => e.route_stop_id == 'destination');

  return (
    <>
      <Marker
        key='source-marker'
        position={[Number(source.geo_location.latitude), Number(source.geo_location.longitude)]}
        icon={sourceMarker}
      >
        <Popup>{source.address}</Popup>
      </Marker>
      <Marker
        key='destination-marker'
        position={[
          Number(destination.geo_location.latitude),
          Number(destination.geo_location.longitude),
        ]}
        icon={destinationMarker}
      >
        <Popup>{destination.address}</Popup>
      </Marker>
    </>
  );
}

function GetStopsMarkers(props) {
  const classes = useStyles();

  return props.routeGraph?.map((routeStop, index) => {
    if (['source', 'destination'].includes(routeStop.route_stop_id)) return;

    return (
      <Marker
        key={index}
        position={[
          Number(routeStop.geo_location.latitude),
          Number(routeStop.geo_location.longitude),
        ]}
        icon={stopMarkerIcon}
      >
        <Tooltip
          offset={[0, -11]}
          opacity={0.7}
          permanent
          direction='bottom'
          className={classes.popupContent}
        >
          <span className={classes.popupText}>{routeStop.index}</span>
        </Tooltip>
      </Marker>
    );
  });
}

function plotPolyline(props) {
  if (!props.overviewPolyline.decoded_points) {
    return;
  }
  let tripPolyline = props.overviewPolyline.decoded_points;
  const blackOptions = { color: 'black', opacity: 0.9, weight: 3 };
  return <Polyline pathOptions={blackOptions} positions={tripPolyline} />;
}

export default function RenderRouteOpenStreetMap(props) {
  const classes = useStyles();
  const theme = useTheme();

  function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
  }

  return (
    <div>
      <MapContainer
        center={getCenter(props)}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: window.innerHeight * 0.65, width: '100', zIndex: 1 }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {PlotSchoolMarker(props)}
        {PlotSourceDestinationMarkers(props)}
        {GetStopsMarkers(props)}
        {plotPolyline(props)}
        <ChangeMapView coords={getCenter(props)} />
      </MapContainer>
    </div>
  );
}
