import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

// third-party imports
import { Formik, Form } from 'formik';

// custom components
import { BackButton, NextButton, SaveButton, ResetButton } from 'components/Buttons';

// project imports
import formInitialValues from './FormModel/formInitialValues';
import BasicForm from './Forms/BasicForm';
import AddressRouteForm from './Forms/AddressRouteForm';
import { validationSchema } from './FormModel/validationSchema';
import ParentsAndOtherInformation from './Forms/ParentsAndOtherInformation';
import StudentProgram from './Forms/StudentProgram';

// Multi step form steps
const steps = ['Basic details', 'Parents & Other Information', 'Addresses', 'Student Program'];

function _renderStepContent(step, formikProps, props) {
  switch (step) {
    case 0:
      return <BasicForm formikProps={formikProps} {...props} />;
    case 1:
      return <ParentsAndOtherInformation formikProps={formikProps} {...props} />;
    case 2:
      return <AddressRouteForm formikProps={formikProps} {...props} />;
    case 3:
      return <StudentProgram formikProps={formikProps} {...props} />;
    default:
      return <div>Not Found</div>;
  }
}

// ============================== STUDENT FORM - MAIN  ============================== //

function StudentForm({
  isUpdate,
  isDialogForm,
  studentData,
  setActiveStepforRoutes,
  isSubmitting,
  handleSubmit,
  setRenderGPTwo,
  selectedSchoolId,
  updateActiveStep,
  isFromGuided,
  imageUrl,
  ...props
}) {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  // Used ref so that it can persist across renders

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const headingText = isUpdate ? 'Update Student' : 'Add Student';
  const leftmarginCondition = activeStep === 0 ? 2 : 2;

  const resetActiveStep = () => setActiveStep(0);

  async function _submitForm(values, actions) {
    handleSubmit(values, actions, resetActiveStep);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleResetForm(formikProps) {
    const { resetForm, setFieldValue } = formikProps;
    if (!isUpdate) {
      setTimeout(() => {
        setFieldValue('birth_date', null);
      }, 50);
    }
    resetForm();
    if (activeStep === 1) setActiveStep(activeStep - 1);
    if (isDialogForm === false && isUpdate === true) props.setIsUpdate(false);
  }

  function handleValidate(values) {
    const errors = {};
    if (activeStep === 2) {
      if (values.address_search_optional && !values.address_label_optional) {
        errors.address_label_optional = 'Address Label is required when Address Search is filled';
      }

      if (!values.address_search_optional && values.address_label_optional) {
        errors.address_search_optional = 'Address Search is required when Address Label is filled';
      }
    }

    return errors;
  }

  useEffect(() => {
    // validateStudentBasicData();
  }, []);

  // We need to update the setAcite step when we click on edit in guided work flow so it will switch to first step
  useEffect(() => {
    setActiveStep(0);
  }, [updateActiveStep]);

  return (
    <Box>
      <Formik
        initialValues={formInitialValues(isUpdate, studentData, imageUrl)}
        validationSchema={currentValidationSchema}
        onSubmit={_handleSubmit}
        // validate={handleValidate}
        enableReinitialize
        resetForm={handleResetForm}
      >
        {(formikProps) => (
          <Form id='studentForm'>
            <Stack sx={{ position: 'relative' }}>
              <Grid item xs={12}>
                {!isDialogForm && (
                  <Typography component='h2' variant='h2' gutterBottom>
                    {headingText}
                  </Typography>
                )}

                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconProps={{ sx: { color: theme.palette.grey[600] } }}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} minHeight={480}>
                {_renderStepContent(activeStep, formikProps, props)}
              </Grid>

              <Grid
                container
                justifyContent='space-between'
                sx={{
                  pt: isFromGuided && activeStep !== 0 ? 6 : 3,
                  position: 'sticky',
                  bottom: 0,

                  bgcolor: 'background.paper',
                  zIndex: 1000,
                }}
                // border={1}
              >
                <Grid justifyContent='flex-start' xs={8}>
                  {activeStep !== 0 && (
                    <BackButton
                      id='back_button'
                      sx={{ ml: 2, mr: 2 }}
                      size={'medium'}
                      onClick={_handleBack}
                      disabled={formikProps.isSubmitting}
                    >
                      Back
                    </BackButton>
                  )}
                  {isDialogForm === false && isUpdate === true ? (
                    <ResetButton
                      sx={{ ml: 2 }}
                      onClick={handleResetForm.bind(null, formikProps)}
                      size={'medium'}
                    >
                      {'Add Student'}
                    </ResetButton>
                  ) : (
                    <ResetButton
                      sx={{ ml: leftmarginCondition }}
                      onClick={handleResetForm.bind(null, formikProps)}
                      disabled={!formikProps.dirty}
                      size={'medium'}
                      id='reset'
                    >
                      {'Reset'}
                    </ResetButton>
                  )}
                </Grid>
                <Grid container justifyContent='flex-end' xs={4}>
                  {isLastStep && isUpdate && (
                    <SaveButton
                      id='save'
                      loading={formikProps.isSubmitting}
                      size={'medium'}
                      type='submit'
                    >
                      UPDATE STUDENT
                    </SaveButton>
                  )}
                  {isLastStep && !isUpdate && (
                    <SaveButton
                      id='save'
                      loading={formikProps.isSubmitting}
                      size={'medium'}
                      type='submit'
                    >
                      ADD STUDENT
                    </SaveButton>
                  )}
                  {!isLastStep && (
                    <NextButton id='next' size={'medium'} onClick={formikProps.handleSubmit}>
                      Next
                    </NextButton>
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

StudentForm.propTypes = {
  isUpdate: PropTypes.bool,
  isDialogForm: PropTypes.bool,
  studentData: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default StudentForm;
