import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FormHeader } from 'components/SectionForms';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { queryKey } from 'react-query/queryKey';
import { useImageQuery } from './imageQuery';
import { getLastPartOfUrl } from './Attachments';

export default function AttachmentDialog({
  type,
  closeDialog,
  dataInspection,
  openDialog,
  videos,
  images,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const image_url = getLastPartOfUrl(images[currentIndex].s3_url);

  // Query to fetch Image 
  const { getImageUrl, isLoadingImage } = useImageQuery({
    customQueryKey: [queryKey.TRIP_INSPECTIONS.TRIP_INSPECTION_REPORT_IMAGE, image_url],
    image_url: image_url,
    inspectionReportID: dataInspection?.id,
  });

  return (
    <Dialog open={openDialog} maxWidth={'lg'}>
      <DialogTitle sx={{ p: 0, m: 0 }}>
        <FormHeader
          title={type === 'picture' ? 'Photos' : 'Videos'}
          id={'close-vehicle-form'}
          onClose={closeDialog}
        />
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          width: '700px',
          height: '500px',
          bgcolor: 'background.default',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          position: 'relative',
        }}
      >
        <div style={{}}>
          {type === 'picture' ? (
            <img
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              alt='Not found'
              id='not-found-page'
              src={getImageUrl?.url}
            />
          ) : type === 'video' ? (
            <video
              width='100%'
              autoPlay
              controls
              style={{
                width: '100%',
                height: '450px',
                borderRadius: '10px',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
            >
              <track kind='captions' />
              <source src={videos[currentIndex].s3_url} type='video/mp4' />
            </video>
          ) : null}
          {type === 'picture' ? (
            <>
              <IconButton
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '20px',
                  transform: 'translateY(-50%)',
                }}
                onClick={handleBack}
                disabled={images.length <= 1}
              >
                <ButtonStyle>
                  <ArrowBackIcon />
                </ButtonStyle>
              </IconButton>
              <IconButton
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  transform: 'translateY(-50%)',
                }}
                onClick={handleNext}
                disabled={images.length <= 1}
              >
                <ButtonStyle>
                  <ArrowForwardIcon />
                </ButtonStyle>
              </IconButton>
            </>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const ButtonStyle = ({ children }) => {
  return (
    <Stack
      sx={{
        border: 1,
        borderColor: 'grey.300',
        borderRadius: '50%',
        p: 0.5,
        bgcolor: 'background.default',
      }}
    >
      {children}
    </Stack>
  );
};
