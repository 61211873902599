import L from 'leaflet';
export const svgLocationMarker = (color = '#FF6E6E') => {
  return `<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="-4 0 36 36" version="1.1">
  <circle cx="11" cy="11" r="11" transform="matrix(-1 0 0 1 27 0)" fill="${color}"/>
</svg>`;
};

// NEW STOP MARKER
export const StopMarker = (color) => {
  const Marker = new L.DivIcon({
    iconSize: [28, 28],
    iconAnchor: [17, 46],
    popupAnchor: [1, -46],
    className: 'student-single-marker',
    html: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6887_52641)">
    <circle cx="12" cy="14" r="6" fill="white"/>
    <ellipse cx="12.0808" cy="14.0808" rx="3.83077" ry="3.83077" fill="${color}"/>
    </g>
    <defs>
    <filter id="filter0_d_6887_52641" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="2"/>
    <feGaussianBlur stdDeviation="4"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6887_52641"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6887_52641" result="shape"/>
    </filter>
    </defs>
    </svg>
    `, // SVG image wrapped in a div
  });
  return Marker;
};
