import * as React from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function LetterAvatar(props) {
  const theme = useTheme();
  const { data, condition } = props;
  const { value, name } = props.field;
  const { setFieldValue } = props.form;

  const handleClick = () => {
    if (value) {
      setFieldValue(name, false);
    } else {
      setFieldValue(name, true);
    }
  };
  return (
    <Stack direction='row' spacing={2}>
      <Grid
        sx={{
          bgcolor:
            value === data.value ? theme.palette.primary.main : theme.palette.secondary.light,
          width: 35, // Set a fixed width
          height: 35, // Set a fixed height
          borderRadius: '50%', // Make it round
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={!condition ? () => handleClick() : null}
      >
        <Typography>{data.day[0]}</Typography>
      </Grid>
    </Stack>
  );
}
