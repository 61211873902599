import React, { useState } from 'react';

// material ui
import { Grid, Typography, Card, CardContent } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';

// project imports
import UserCard from './UserCard';
import DeleteAlert from '../../DeleteAlert';
// import NoRegisteredSchool from './NoRegisteredSchool';
import MainCard from 'components/MainCard';

export default function UserList(props) {
  const theme = useTheme();
  const [deleteId, setDeleteId] = useState(false);
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7];

  return (
    <Card
      sx={{
        height: '100%',
        // mx: 4,
        zIndex: 2,
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? 'secondary.lighter' : 'secondary.darker',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <CardContent>
        {/* <MainCard sx={{ backgroundColor: theme.palette.primary.main }}> */}
        <Grid container>
          <Typography
            variant='h2'
            sx={{ py: 1, pl: 2, color: 'common.white', fontWeight: 300, mb: -4 }}
          >
            Users
          </Typography>
          <Typography
            variant='h5'
            sx={{
              pt: 4,
              pl: 2,
              color: 'common.white',
              fontWeight: 400,
              fontStyle: 'italic',
            }}
          >
            {`(${props.guidedUsers?.length ? props.guidedUsers?.length : '- '} user${
              props.guidedUsers?.length > 1 ? 's are' : ' is'
            } registered)`}
          </Typography>
        </Grid>
        <Scrollbars
          style={{ height: 718 }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          disableHorizontalScrolling
        >
          <Grid sx={{ mt: 4 }}>
            {!props.guidedUsers ? (
              <Grid>
                {skeletonCount.map((skeleton, index) => {
                  return (
                    <MainCard content={false} key={index} sx={{ padding: 2, mb: 3 }}>
                      <Grid container xs={12}>
                        <Grid container>
                          <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                              <Typography component='div' variant='h1' width='80%'>
                                <Skeleton />
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ pl: 1 }}>
                              <Grid container>
                                <Typography component='div' variant='h4' width='60%'>
                                  <Skeleton />
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container>
                                <Typography component='div' variant='h4' width='60%'>
                                  <Skeleton />
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container xs={12}>
                              <Grid item xs={8}>
                                <Grid item xs={12} sx={{ pl: 1 }}>
                                  <Grid container>
                                    <Typography component='div' variant='h4' width='45%'>
                                      <Skeleton />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MainCard>
                  );
                })}
              </Grid>
            ) : (
              // props.guidedUsers?.length === 0 ? (
              //   <NoRegisteredSchool />
              // ) :
              props.guidedUsers?.map((user) => (
                <UserCard
                  user={user}
                  key={user.email}
                  handleEdit={props.handleEdit}
                  setOpen={() => props.setOpenDeleteAlert(true)}
                  handleOnDeleteClick={props.handleOnDeleteClick}
                  setDeleteId={setDeleteId}
                  loadingData={props.loadingData}
                />
              ))
            )}
          </Grid>
        </Scrollbars>
        {/* </MainCard> */}
      </CardContent>
      <DeleteAlert
        open={props.openDeleteAlert}
        setOpen={() => props.setOpenDeleteAlert(true)}
        setClose={() => props.setOpenDeleteAlert(false)}
        handleDelete={props.handleDeleteUser}
        buttonLoader={props.deleteButtonLoader}
        deleteId={deleteId}
        section={'User'}
      />
    </Card>
  );
}
