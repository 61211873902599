import MainCard from 'components/MainCard';
import CategoryAccordion from './CategoryAccordion';
import CategoryDetails from './CategoryDetails';
import { Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { truncateString } from 'utils/text';
// icons
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

export default function TripInspectionCategory({
  dataInspection,
  setSelectedQuestion,
  selectionQuestion,
}) {
  const theme = useTheme();
  return (
    <MainCard content={false} sx={{ height: '100%', minHeight: '100vh' }}>
      {dataInspection.categories?.map((data, index) => (
        <CategoryAccordion
          categoryName={
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Tooltip title={data.name}>
                <Typography variant='h4'>{truncateString(data.name, 25)}</Typography>
              </Tooltip>
              <Stack>
                {data.enabled ? (
                  <CheckCircleOutlinedIcon color='success' />
                ) : (
                  <PendingOutlinedIcon color='secondary' />
                )}
              </Stack>
            </Stack>
          }
          chipType={data.status}
          index={index}
          details={data?.subcategories?.map((data) => (
            <>
              <Grid
                sx={{
                  backgroundColor:
                    selectionQuestion.name === data.name ? theme.palette.hover.default : null,
                  '&:hover': {
                    backgroundColor: theme.palette.hover.default,
                    cursor: 'pointer',
                  },
                  p: 3,
                }}
                onClick={() => setSelectedQuestion(data)}
              >
                <CategoryDetails title={data.name} count={data.statistics.answer_stats} />
              </Grid>
              <Divider />
            </>
          ))}
        />
      ))}
    </MainCard>
  );
}
