import React from 'react';

// material-ui
import { Chip, Box, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
  },
  value: {
    marginTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.light,
  },
}));

export function VehicleSurveillanceItem({ icon, vehicleSurveillanceInfo }) {
  const classes = useStyles();
  const theme = useTheme();
  const IconName = icon;

  const getVehicleSurveillanceChip = (name, value) => {
    if (value === true) {
      return <Chip size='small' color='primary' label={name} />;
    } else {
      return <Chip variant='outlined' size='small' label={name} />;
    }
  };
  return (
    <Box
      display='flex'
      bgcolor='white'
      border={1}
      borderColor='#E8E8E8'
      borderRadius="2px"
      marginBottom={2}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        className={classes.main}
      >
        <Grid item xs={2}>
          <IconName fontSize='large' className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography color='primary'>Vehicle Surveillance</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {getVehicleSurveillanceChip(
                    'Front Cameras',
                    vehicleSurveillanceInfo?.cameras.front,
                  )}
                </Grid>
                <Grid item xs={6}>
                  {getVehicleSurveillanceChip(
                    'Back Cameras',
                    vehicleSurveillanceInfo?.cameras.back,
                  )}
                </Grid>
                <Grid item xs={6}>
                  {getVehicleSurveillanceChip(
                    'Internal Cameras',
                    vehicleSurveillanceInfo?.cameras.internal,
                  )}
                </Grid>
                <Grid item xs={6}>
                  {getVehicleSurveillanceChip(
                    'Side Cameras',
                    vehicleSurveillanceInfo?.cameras.side,
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
