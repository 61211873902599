import React from 'react';

// material ui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';

// project imports
import DriverInfo from './DriverInfo';
import DialogImage from './DialogImage';

// Custom Component
import QuickViewItemAccordion from 'components/accordions/QuickViewItemAccordion';

export default function DriverCertificate({
  certificateAuthority,
  certificateName,
  certificateImage,
  dataLoading,
}) {
  const theme = useTheme();
  const [openAddImageDialog, setOpenImageDialog] = React.useState(false);

  return (
    <React.Fragment>
      <QuickViewItemAccordion
        startIcon={<CardMembershipOutlinedIcon fontSize='large' />}
        label={'Certificate Information'}
        accordionSummary={certificateName ? certificateName : '---'}
        isLoading={dataLoading}
        isExpandable={certificateName ? certificateName : false}
      >
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item xs={12}>
            <DriverInfo
              heading={'Certificate authority'}
              details={certificateAuthority}
              id='certificate_authority'
              headingWidth={4}
              bodyWidth={8}
            />
            <DriverInfo
              heading={'Certificate name'}
              details={certificateName}
              id='certificate_name'
              headingWidth={4}
              bodyWidth={8}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={(a) => setOpenImageDialog(true)}>
              <img
                style={{ width: '100%' }}
                src={
                  certificateImage?.certificate_image?.image_id
                    ? certificateImage?.certificate_image?.url
                    : ''
                }
                key={
                  certificateImage?.certificate_image?.image_id
                    ? certificateImage?.certificate_image?.url
                    : ''
                }
              ></img>
            </Button>
          </Grid>
        </Grid>
        <DialogImage
          openDialog={openAddImageDialog}
          closeDialog={() => setOpenImageDialog(false)}
          title={'Driver Cerificate'}
          image={
            certificateImage?.certificate_image?.image_id
              ? certificateImage?.certificate_image?.url
              : ''
          }
        />
      </QuickViewItemAccordion>
      {/* old Code (deprecated) */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' id='show_school_info' />}>
          <Grid container alignItems='center' spacing={3} sx={{ paddingLeft: theme.spacing(1) }}>
            <Grid item xs={2} sx={{ paddingRight: theme.spacing(1) }}>
              <CardMembershipOutlinedIcon color='secondary' fontSize='large' />
            </Grid>
            <Grid item xs={10}>
              <Typography color='primary'>Certificate Information</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12}>
              <DriverInfo
                heading={'Certificate authority'}
                details={certificateAuthority}
                id='certificate_authority'
                headingWidth={4}
                bodyWidth={8}
              />
              <DriverInfo
                heading={'Certificate name'}
                details={certificateName}
                id='certificate_name'
                headingWidth={4}
                bodyWidth={8}
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={(a) => setOpenImageDialog(true)}>
                <img
                  style={{ width: '100%' }}
                  src={
                    certificateImage?.certificate_image?.image_id
                      ? certificateImage?.certificate_image?.url
                      : ''
                  }
                  key={
                    certificateImage?.certificate_image?.image_id
                      ? certificateImage?.certificate_image?.url
                      : ''
                  }
                ></img>
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion> */}
    </React.Fragment>
  );
}
