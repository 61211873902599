import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Stack } from '@mui/material';
import { ChipType } from '../TripReport/ChipType';
import MainCard from 'components/MainCard';

export default function QuestionDetails({ data, details }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MainCard content={false}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Grid container alignItems={'center'} xs={12} sx={{ pl: 3 }}>
            <Grid item xs={2}>
              <Typography variant='h5'>Question {data.index + 1} :</Typography>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Typography variant='h5'>Label</Typography>
                <Typography color='text'>{data.label}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={5.5}>
              <Stack spacing={2}>
                <Typography variant='h5'>Title</Typography>
                <Typography color='text'>{data.title}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant='h5'>Status</Typography>
              <ChipType
                sx={{ borderRadius: 2, p: 1, mt: 2 }}
                value={data.answer?.answer_option_id}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </MainCard>
  );
}
