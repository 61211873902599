import React from 'react';
import { useEffect } from 'react';

// Formik
import { Field } from 'formik';

// Material Ui
import { Chip, Grid, Stack, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TodayIcon from '@mui/icons-material/Today';
import CopyAllIcon from '@mui/icons-material/CopyAll';

// Custom Components
import DateSelectorFormik from 'components/Formik/DateSelectorFormik';
import { SaveButton } from 'components/Buttons';
import LetterAvatar from 'components/Formik/LetterAvatar';

// Helper Function
import {
  checkConditionsForSingleSchoolScheduleValue,
  checkConditionsforAllSchoolScheduleValues,
  checkDateRangeValidity,
  createSchoolScheduleArray,
  dateRangeValidation,
  finalErrorMessageWithDateRange,
  getSchoolName,
  isValidateDateRangeForHolidays,
  isValidateDateRangeForVacations,
  validateAllValuesWithDateRange,
  validationMessageForVacation,
} from '../Utils';
import { useState } from 'react';
import { FormHeader } from 'components/SectionForms';
import { CustomTooltip } from 'components/tooltips';

import VacationsForm from './SubForms/VacationsForm';
import HolidayForm from './SubForms/HolidayForm';
import { initialValuesOfSessionForm } from '../FormModel/formInitialValues';

export default function SchoolSchedule({
  formikProps,
  schoolsData,
  scheduleValues,
  sessionData,
  isUpdate,
  setScheduleValues,
  selectedSchoolID,
  setSelectedSchoolID,
  setInitialValues,
  setSchoolScheduleValidation,
  enqueueSnackbar,
  setHolidayForSelectedSchool,
  holidayForSelectedSchool,
  formikpropsOfSchoolSchedule,
  openSchoolScheduleForm,
  setOpenSchoolScheduleForm,
  editHoliday,
  setEditHoliday,
  editVacation,
  setEditVacation,
  vacationForSelectedSchool,
  setVacationForSelectedSchool,
  setCopyAllData,
  copyAllData,
  finalMessage,
  setSelectedHoliday,
  selectedHoliday,
  setSelectedVacation,
  selectedVacation,
}) {
  const variant = 'outlined';

  const [uniqueId, setUniqueId] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexVacation, setSelectedIndexVacation] = useState(0);

  const sessionRangeStartDate = formikpropsOfSchoolSchedule.values.start_date;
  const sessionRangeEndDate = formikpropsOfSchoolSchedule.values.end_date;

  const handleSaveSchool = () => {
    formikpropsOfSchoolSchedule.submitForm();
  };

  // Trigger when holidays is submitted
  const _handleSubmitHoliday = (values, actions) => {
    let singleHoliday = {
      name: values.hname,
      date: values.hdate,
    };

    if (editHoliday) {
      const updatedHolidays = holidayForSelectedSchool.map((item, index) => {
        if (index === selectedIndex) {
          return {
            ...singleHoliday,
          };
        }
        return item;
      });
      setHolidayForSelectedSchool(updatedHolidays);
      formikpropsOfSchoolSchedule.setFieldValue('holidays', updatedHolidays);
      setEditHoliday(false);
      setSelectedHoliday(undefined);
      actions.resetForm();
    } else {
      setHolidayForSelectedSchool([...holidayForSelectedSchool, singleHoliday]);
      formikpropsOfSchoolSchedule.setFieldValue('holidays', [
        ...holidayForSelectedSchool,
        singleHoliday,
      ]);
      actions.resetForm();
    }
  };

  // Trigger When Vacation is Submitted
  const _handleSubmitVacation = (values, actions) => {
    setInitialValues(initialValuesOfSessionForm(formikProps.values));
    let singleVacation = {
      name: values.name,
      start_date: values.start_date,
      end_date: values.end_date,
    };

    if (editVacation) {
      const updatedVacation = vacationForSelectedSchool.map((item, index) => {
        if (index === selectedIndexVacation) {
          return {
            ...singleVacation,
          };
        }
        return item;
      });
      setVacationForSelectedSchool(updatedVacation);
      formikpropsOfSchoolSchedule.setFieldValue('vacations', updatedVacation);
      setEditVacation(false);
      setSelectedVacation(undefined);
      actions.resetForm();
    } else {
      setVacationForSelectedSchool([...vacationForSelectedSchool, singleVacation]);
      formikpropsOfSchoolSchedule.setFieldValue('vacations', [
        ...vacationForSelectedSchool,
        singleVacation,
      ]);
      actions.resetForm();
    }
  };

  const uniqueID = window.location.href;

  const WEEK_DAYS = [
    { day: 'Monday', value: true },
    { day: 'Tuesday', value: true },
    { day: 'Wednesday', value: true },
    { day: 'Thursday', value: true },
    { day: 'Friday', value: true },
    { day: 'Saturday', value: true },
    { day: 'Sunday', value: true },
  ];

  // Handle For Canceling Form
  const handleCancel = () => {
    setInitialValues(initialValuesOfSessionForm(formikProps.values));
    setScheduleValues(copyAllData);
    formikpropsOfSchoolSchedule.resetForm();
    setEditVacation(false);
    setEditHoliday(false);
    setSelectedHoliday(undefined);
    setSelectedVacation(undefined);
    setOpenSchoolScheduleForm(false);
  };

  const handleEditHoliday = (index) => {
    setEditHoliday(true);
    setSelectedIndex(index);
    const selectSchoolHolidayUpdates = holidayForSelectedSchool[index];
    setSelectedHoliday(selectSchoolHolidayUpdates);
  };

  const handleEditVacation = (index) => {
    setEditVacation(true);
    setSelectedIndexVacation(index);
    const selectSchoolVacation = vacationForSelectedSchool[index];
    setSelectedVacation(selectSchoolVacation);
  };

  const handleDeleteHoliday = (selectedIndex) => {
    setEditHoliday(false);
    const filterHolidaysSingle = holidayForSelectedSchool.filter(
      (ele, index) => index !== selectedIndex,
    );
    if (filterHolidaysSingle.length === 0) {
      formikpropsOfSchoolSchedule.setFieldValue('holidays', []);
    }
    setHolidayForSelectedSchool(filterHolidaysSingle);
    setSelectedHoliday(undefined);
  };

  const handleDeleteVacation = (selectedIndex) => {
    setEditVacation(false);
    const filterVacationSingle = vacationForSelectedSchool.filter(
      (ele, index) => index !== selectedIndex,
    );
    if (filterVacationSingle.length === 0) {
      formikpropsOfSchoolSchedule.setFieldValue('vacations', []);
    }
    setVacationForSelectedSchool(filterVacationSingle);
    setSelectedVacation(undefined);
  };

  const handleChipClick = (data) => {
    setInitialValues(initialValuesOfSessionForm(formikProps.values));
    setCopyAllData(scheduleValues);
    const selectedSchool = scheduleValues?.find((ele) => ele.school_id === data.school_id);
    const selectedSchoolHoliday = selectedSchool?.holidays.filter((ele) => ele.name.length > 0);
    const selectedSchoolVacations = selectedSchool?.vacations.filter((ele) => ele.name.length > 0);

    if (checkConditionsForSingleSchoolScheduleValue(selectedSchool)) {
      formikpropsOfSchoolSchedule.setFieldValue('holidays', selectedSchool.holidays);
      formikpropsOfSchoolSchedule.setFieldValue('vacations', selectedSchool.vacations);
    } else {
      setTimeout(() => {
        formikpropsOfSchoolSchedule.setFieldValue('holidays', []);
        formikpropsOfSchoolSchedule.setFieldValue('vacations', []);
      }, 100);
    }

    const holidaysAsPerRequirement = selectedSchoolHoliday.map((ele) => {
      return {
        name: ele.name,
        date: ele.date,
      };
    });
    const vacationsAsPerRequirement = selectedSchoolVacations.map((ele) => {
      return {
        name: ele.name,
        start_date: ele.start_date,
        end_date: ele.end_date,
      };
    });

    setHolidayForSelectedSchool(holidaysAsPerRequirement);
    setVacationForSelectedSchool(vacationsAsPerRequirement);
    setSelectedSchoolID(data.school_id);
    setOpenSchoolScheduleForm(true);
  };

  function checkAndAddData(schools_schedule, previous_schools_schedule) {
    let schoolScheduleData = schools_schedule;
    for (let i = 0; i < schools_schedule.length; i++) {
      for (let j = 0; j < previous_schools_schedule.length; j++) {
        if (schools_schedule[i].school_id === previous_schools_schedule[j].school_id) {
          schoolScheduleData[i] = previous_schools_schedule[j];
        }
      }
    }

    setScheduleValues(schoolScheduleData);
    setSelectedSchoolID(schoolScheduleData[0].school_id);
  }

  const handleClickSameForAllSchool = (copyAllData) => {
    const updateAllSchoolScheduleData = copyAllData?.map((ele) => {
      return {
        ...ele,
        date_range: {
          start_date: formikpropsOfSchoolSchedule.values.start_date,
          end_date: formikpropsOfSchoolSchedule.values.end_date,
        },
      };
    });

    if (
      !Boolean(formikpropsOfSchoolSchedule.errors.start_date) &&
      !Boolean(formikpropsOfSchoolSchedule.errors.end_date)
    ) {
      setCopyAllData(updateAllSchoolScheduleData);

      if (!Boolean(sessionData?.status === 'active')) {
        enqueueSnackbar('Copied current start and end date across all schools', {
          variant: 'success',
        });
      }
    }
  };

  const handleClickSameForAllSchoolHoliday = () => {
    const updateAllSchoolScheduleData = copyAllData?.map((ele) => {
      return {
        ...ele,
        holidays: holidayForSelectedSchool,
      };
    });

    setCopyAllData(updateAllSchoolScheduleData);

    if (!Boolean(sessionData?.status === 'active')) {
      enqueueSnackbar('Copied current holidays across all schools', {
        variant: 'success',
      });
    }
  };

  const handleClickSameForAllVacations = () => {
    const updateAllSchoolScheduleData = copyAllData?.map((ele) => {
      return {
        ...ele,
        vacations: vacationForSelectedSchool,
      };
    });

    setCopyAllData(updateAllSchoolScheduleData);

    if (!Boolean(sessionData?.status === 'active')) {
      enqueueSnackbar('Copied current vacations across all schools', {
        variant: 'success',
      });
    }
  };

  const handleClickSameForAllSchoolWeekDays = () => {
    const updateAllSchoolScheduleData = copyAllData?.map((ele) => {
      return {
        ...ele,
        days_of_week: formikpropsOfSchoolSchedule.values.days_of_week,
      };
    });

    const validateWeekDays = formikpropsOfSchoolSchedule?.values.days_of_week.some(
      (ele) => ele === true,
    );

    if (validateWeekDays) {
      setCopyAllData(updateAllSchoolScheduleData);

      if (!Boolean(sessionData?.status === 'active')) {
        enqueueSnackbar('Copied current week days across all schools', {
          variant: 'success',
        });
      }
    }
  };

  useEffect(() => {
    if (schoolsData.length > 0 && !isUpdate) {
      const schoolSchedule = createSchoolScheduleArray(schoolsData);
      setScheduleValues(schoolSchedule);
      setSelectedSchoolID(schoolSchedule[0].school_id);
      setInitialValues(initialValuesOfSessionForm());
    }

    if (Boolean(sessionData) && isUpdate) {
      if (schoolsData.length > 0) {
        const schoolSchedule = createSchoolScheduleArray(schoolsData);
        setSelectedSchoolID(schoolSchedule[0].school_id);
        checkAndAddData(schoolSchedule, sessionData.schools_schedule);
        setInitialValues(initialValuesOfSessionForm(sessionData));
      }
    }

    if (uniqueID) {
      setUniqueId(uniqueID);
    }
  }, [uniqueID, schoolsData]);

  const resultArray = checkDateRangeValidity(scheduleValues);

  const validationChecForHoliday = dateRangeValidation(scheduleValues);

  const validationOfVacationAndHoliday = validateAllValuesWithDateRange(
    resultArray,
    validationChecForHoliday,
  );

  const validateDateBetweenSessionDateRangeForHolidays = isValidateDateRangeForHolidays(
    sessionRangeStartDate,
    sessionRangeEndDate,
    holidayForSelectedSchool,
  );

  const validateDateBetweenSessionDateRangeForVacation = isValidateDateRangeForVacations(
    sessionRangeStartDate,
    sessionRangeEndDate,
    vacationForSelectedSchool,
  );

  const validationMsgVacation = validationMessageForVacation(
    validateDateBetweenSessionDateRangeForVacation,
  );

  const validationMsgHolidays = validationMessageForVacation(
    validateDateBetweenSessionDateRangeForHolidays,
  );

  useEffect(() => {
    if (finalMessage) {
      setSchoolScheduleValidation(false);
    }
  }, [formikProps]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            Select School
          </Typography>

          <Grid container spacing={2}>
            {schoolsData.map((data, index) => {
              return (
                <>
                  <Grid key={index} item>
                    <Chip
                      key={index}
                      icon={<SchoolIcon />}
                      label={data.school_name}
                      size={'large'}
                      variant={resultArray[index] ? 'default' : 'outlined'}
                      color={validationOfVacationAndHoliday[index] ? 'success' : 'secondary'}
                      onClick={(event) => handleChipClick(data)}
                      id={data.school_name.toLowerCase()}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Dialog
            open={openSchoolScheduleForm}
            aria-labelledby='form-school-schedule'
            fullWidth='md'
            maxWidth='md'
          >
            <DialogTitle>
              <FormHeader
                title={getSchoolName(schoolsData, selectedSchoolID)}
                id={'close_school_schedule'}
                onClose={handleCancel}
                icon={<SchoolIcon sx={{ fontSize: 30 }} />}
              />
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ mb: 4 }}
                  >
                    <Typography variant='h5' gutterBottom>
                      Session Date Range
                    </Typography>
                    <CustomTooltip title='Copy for all school'>
                      <CopyAllIcon
                        sx={{ cursor: 'pointer' }}
                        color={sessionData?.status === 'active' ? 'secondary' : 'primary'}
                        onClick={() =>
                          formikProps.errors.start_date === undefined &&
                          formikProps.errors.end_date === undefined
                            ? handleClickSameForAllSchool(copyAllData)
                            : null
                        }
                      />
                    </CustomTooltip>
                  </Stack>

                  <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Field
                      fullWidth={true}
                      variant={variant}
                      name='start_date'
                      component={DateSelectorFormik}
                      // disablePast
                      label='Start Date'
                      disabled={sessionData?.status === 'active'}
                      helperText={'Select start date*'}
                    />
                    <Field
                      fullWidth={true}
                      variant={variant}
                      name='end_date'
                      helperText='Select end date*'
                      component={DateSelectorFormik}
                      // disablePast
                      label='End Date'
                      disabled={sessionData?.status === 'active'}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                    sx={{ mb: 4 }}
                  >
                    <Typography variant='h5' gutterBottom>
                      Select Days
                    </Typography>

                    <CustomTooltip title='Copy for all school'>
                      <CopyAllIcon
                        color={sessionData?.status === 'active' ? 'secondary' : 'primary'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleClickSameForAllSchoolWeekDays(scheduleValues, formikProps)
                        }
                      />
                    </CustomTooltip>
                  </Stack>

                  <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    {WEEK_DAYS.map((data, index) => {
                      return (
                        <>
                          <Field
                            key={index}
                            component={LetterAvatar}
                            data={data}
                            name={`days_of_week[${index}]`}
                            condition={sessionData?.status === 'active'}
                          />
                        </>
                      );
                    })}
                  </Stack>
                  <Stack sx={{ pt: 2 }}></Stack>

                  <Stack sx={{ pt: 2 }}>
                    {formikpropsOfSchoolSchedule.errors.days_of_week ? (
                      <Typography variant='caption' color={'error'}>
                        Choose at least one day
                      </Typography>
                    ) : null}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                    sx={{ mb: 4 }}
                  >
                    <Typography variant='h5' component='div' gutterBottom>
                      Holidays
                    </Typography>
                    <CustomTooltip title='Copy for all school'>
                      <CopyAllIcon
                        color={sessionData?.status === 'active' ? 'secondary' : 'primary'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleClickSameForAllSchoolHoliday(scheduleValues, formikProps)
                        }
                      />
                    </CustomTooltip>
                  </Stack>

                  <Stack>
                    <HolidayForm
                      selectedHoliday={selectedHoliday}
                      _handleSubmitHoliday={_handleSubmitHoliday}
                      sessionStartDate={formikpropsOfSchoolSchedule.values.start_date}
                      sessionEndDate={formikpropsOfSchoolSchedule.values.end_date}
                      editHoliday={editHoliday}
                      variant={variant}
                      sessionData={sessionData}
                      formikpropsOfSchoolSchedule={formikpropsOfSchoolSchedule}
                    />
                  </Stack>

                  <Grid container xs={12} sx={{ pt: 3 }}>
                    <Grid direction='row' justifyContent='flex-start' alignItems='center' item>
                      {Boolean(holidayForSelectedSchool) && holidayForSelectedSchool.length > 0 ? (
                        holidayForSelectedSchool.map((ele, index) => {
                          return (
                            <Chip
                              icon={<TodayIcon />}
                              onClick={
                                sessionData?.status === 'active'
                                  ? () => {}
                                  : () => handleEditHoliday(index)
                              }
                              label={ele.name}
                              color={
                                validateDateBetweenSessionDateRangeForHolidays[index]
                                  ? 'error'
                                  : 'secondary'
                              }
                              sx={{ mr: 1, mb: 1 }}
                              onDelete={
                                sessionData?.status === 'active'
                                  ? () => {}
                                  : () => handleDeleteHoliday(index)
                              }
                            />
                          );
                        })
                      ) : (
                        <Chip icon={<TodayIcon />} label='No Holidays' />
                      )}
                    </Grid>
                  </Grid>

                  <Stack sx={{ pt: 2 }}>
                    {formikpropsOfSchoolSchedule.errors.holidays &&
                    formikpropsOfSchoolSchedule.touched.holidays ? (
                      <Typography variant='caption' color={'error'}>
                        Minimum one holiday is required
                      </Typography>
                    ) : null}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                    sx={{ mb: 4 }}
                  >
                    <Typography variant='h5' component='div' gutterBottom>
                      Vacations
                    </Typography>

                    <CustomTooltip title='Copy for all school'>
                      <CopyAllIcon
                        color={sessionData?.status === 'active' ? 'secondary' : 'primary'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClickSameForAllVacations(scheduleValues, formikProps)}
                      />
                    </CustomTooltip>
                  </Stack>

                  <VacationsForm
                    variant={variant}
                    selectedVacation={selectedVacation}
                    _handleSubmitVacation={_handleSubmitVacation}
                    editVacation={editVacation}
                    sessionData={sessionData}
                    sessionStartDate={formikpropsOfSchoolSchedule.values.start_date}
                    sessionEndDate={formikpropsOfSchoolSchedule.values.end_date}
                  />

                  <Grid container xs={12} sx={{ pt: 3 }}>
                    <Grid direction='row' justifyContent='flex-start' alignItems='center' item>
                      {Boolean(vacationForSelectedSchool) &&
                      vacationForSelectedSchool.length > 0 ? (
                        vacationForSelectedSchool.map((ele, index) => {
                          return (
                            <Chip
                              icon={<TodayIcon />}
                              onClick={
                                sessionData?.status === 'active'
                                  ? () => {}
                                  : () => handleEditVacation(index)
                              }
                              label={ele.name}
                              color={
                                validateDateBetweenSessionDateRangeForVacation[index]
                                  ? 'error'
                                  : 'secondary'
                              }
                              sx={{ mr: 1, mb: 1 }}
                              onDelete={
                                sessionData?.status === 'active'
                                  ? () => {}
                                  : () => handleDeleteVacation(index)
                              }
                            />
                          );
                        })
                      ) : (
                        <Chip icon={<TodayIcon />} label='No Vacations' />
                      )}
                    </Grid>
                  </Grid>

                  <Stack sx={{ pt: 2 }}>
                    {formikpropsOfSchoolSchedule.errors.vacations &&
                    formikpropsOfSchoolSchedule.touched.vacations ? (
                      <Typography variant='caption' color={'error'}>
                        Minimum one vacation is required
                      </Typography>
                    ) : null}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack sx={{ pt: 2 }}>
                    {validationMsgVacation && (
                      <Typography variant='caption' color={'error'}>
                        Confirm vacations dates,at least one doesn't align with the school session
                        date range
                      </Typography>
                    )}
                  </Stack>
                  <Stack sx={{ pt: 2 }}>
                    {validationMsgHolidays && (
                      <Typography variant='caption' color={'error'}>
                        Confirm holidays dates,at least one doesn't align with the school session
                        date range
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                    <SaveButton
                      form={'schoolScheduleForm'}
                      disabled={sessionData?.status === 'active'}
                      onClick={handleSaveSchool}
                    >
                      {Boolean(isUpdate) ? 'UPDATE' : 'SAVE'}
                    </SaveButton>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}
