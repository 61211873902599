import { Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { PublicRouteCard } from './PublicRouteCard';
const SkeletonLoadingPubDash = () => {
  return (
    <MainCard sx={{height: '81vh'}}>
      <Stack>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={3}
          color={'text.secondary'}

        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      </Stack>

      {/* __ District Level Public Route__ */}
      <Stack mt={10}>
        <Typography variant='h4' mb={1} maxWidth={'300px'} height={'40px'}>
          <Skeleton  />
        </Typography>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={3}
          color={'text.secondary'}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
        <PublicRouteCard color={'secondary.main'}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </PublicRouteCard>
      </Stack>

      {/* __ School Level Public Route__ */}
      <Stack mt={10}>
        <Typography variant='h4' mb={1} maxWidth={'300px'} height={'40px'}>
          <Skeleton height={'30px'} />
        </Typography>
        <Typography
          variant='subtitle2'
          fontWeight={'normal'}
          ml={1}
          mb={1}
          color={'text.secondary'}
        >
          <Skeleton />
          <Skeleton />
        </Typography>
        <Stack direction={'row'} flexWrap={'wrap'}>
          <PublicRouteCard color={'secondary.main'}>
            <Skeleton /> <Skeleton /> <Skeleton />
          </PublicRouteCard>
          <PublicRouteCard color={'secondary.main'}>
            <Skeleton /> <Skeleton /> <Skeleton />
          </PublicRouteCard>
          <PublicRouteCard color={'secondary.main'}>
            <Skeleton /> <Skeleton /> <Skeleton />
          </PublicRouteCard>
          <PublicRouteCard color={'secondary.main'}>
            <Skeleton /> <Skeleton /> <Skeleton />
          </PublicRouteCard>
          <PublicRouteCard color={'secondary.main'}>
            <Skeleton /> <Skeleton /> <Skeleton />
          </PublicRouteCard>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default SkeletonLoadingPubDash;
