import React from 'react';
import { useField } from 'formik';
import { Slider, Typography, Grid } from '@mui/material';

const RangeSliderInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);

  const minDistance = 3600;

  const handleChange = (event, value, activeThumb) => {
    if (!Array.isArray(value)) {
      return;
    }

    if (value[1] - value[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(value[0], 43200 - minDistance);
        helpers.setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(value[1], minDistance);
        helpers.setValue([clamped - minDistance, clamped]);
      }
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <div>
      <Grid item xs={12} sx={{ textAlign: 'center', p: 3, pr: 6 }}>
        <Typography id='range-slider-label'>{label}</Typography>
        <Slider
          value={field.value}
          onChange={handleChange}
          // valueLabelDisplay='auto'
          aria-labelledby='range-slider-label'
          {...props}
        />
      </Grid>
    </div>
  );
};

export default RangeSliderInput;
