import * as React from 'react';
// mui
import { useTheme } from '@mui/material';
const SpotbusPin = (props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const textColor = isDarkMode ? '#fff' : '#0c0d0c';
  const busColor = isDarkMode ? '#0c0d0c' : '#fff';
  return (
    <svg
      width={props.width? props.width : '640'}
      viewBox='0 0 970 1364'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M846.976 160.44C719.384 17.6333 552.495 -5.40113 485 0.932513V107.938C487.833 107.938 499.599 108.238 523.997 109.438C548.396 110.638 566.828 114.605 572.994 116.438C584.993 119.605 614.791 128.939 637.99 140.94C666.988 155.94 708.485 183.442 725.984 198.942C739.983 211.343 759.815 234.111 767.981 243.945C780.147 259.279 806.278 295.047 813.478 315.448C820.678 335.849 832.477 368.284 837.476 381.951C842.809 410.62 851.275 480.456 842.476 530.459C833.677 580.461 819.478 615.296 813.478 626.464C805.645 642.964 785.58 680.866 767.981 700.467C745.983 724.968 722.484 746.469 712.985 754.97C703.485 763.47 671.987 785.971 664.488 789.472C656.988 792.972 611.492 816.973 566.495 826.973C530.497 834.974 497.166 835.641 485 834.974V1364L559.495 1264.99L609.992 1195.49L679.987 1094.99L729.984 1018.98L788.48 926.478L839.976 838.974L868.474 784.971C877.974 765.804 902.372 714.968 923.971 664.965C945.569 614.963 959.968 557.46 964.468 534.959C978.467 469.622 974.567 303.247 846.976 160.44Z'
        fill='#E4B301'
      />
      <path
        d='M123.024 160.44C250.616 17.6333 417.505 -5.40113 485 0.932513V107.938C482.167 107.938 470.401 108.238 446.003 109.438C421.604 110.638 403.172 114.605 397.006 116.438C385.007 119.605 355.209 128.939 332.01 140.94C303.012 155.94 261.515 183.442 244.016 198.942C230.017 211.343 210.185 234.111 202.019 243.945C189.853 259.279 163.722 295.047 156.522 315.448C149.322 335.849 137.523 368.284 132.524 381.951C127.191 410.62 118.725 480.456 127.524 530.459C136.323 580.461 150.522 615.296 156.522 626.464C164.355 642.964 184.42 680.866 202.019 700.467C224.017 724.968 247.516 746.469 257.015 754.97C266.515 763.47 298.013 785.971 305.512 789.472C313.012 792.972 358.508 816.973 403.505 826.973C439.503 834.974 472.834 835.641 485 834.974V1364L410.505 1265L360.008 1195.49L290.013 1094.99L240.016 1018.98L181.52 926.478L130.024 838.974L101.526 784.971C92.0263 765.804 67.628 714.968 46.0294 664.965C24.4309 614.963 10.0318 557.46 5.53212 534.959C-8.46693 469.622 -4.56721 303.247 123.024 160.44Z'
        fill='#FDC801'
      />
      <path
        d='M876.114 464C876.114 671.107 701.032 839 485.057 839C269.082 839 94 671.107 94 464C94 256.893 269.082 89 485.057 89C701.032 89 876.114 256.893 876.114 464ZM166.82 464C166.82 632.541 309.3 769.17 485.057 769.17C660.814 769.17 803.294 632.541 803.294 464C803.294 295.459 660.814 158.83 485.057 158.83C309.3 158.83 166.82 295.459 166.82 464Z'
        fill={textColor}
      />
      <path
        d='M355.053 308.5L402.043 303.5L345.655 266.5L314.329 279L310.674 338.5C310.5 429.167 310.256 613.1 310.674 623.5C311.092 633.9 321.638 638.5 326.859 639.5H345.133V663.5C345.133 671.1 352.095 673 355.575 673C361.493 673.167 374.789 673.4 380.636 673C386.484 672.6 388.293 666.5 388.468 663.5V639.5H578.514C578.34 644.667 578.096 656.8 578.514 664C578.932 671.2 584.954 673 587.912 673H614.539C619.969 673 621.327 667.667 621.327 665V639.5H636.99C651.191 639.5 653.697 626.833 653.175 620.5L652.131 305.5L583.213 262C574.685 276 566.192 304.4 600.443 306C634.693 307.6 641.863 324.333 641.167 332.5V477.5C640.992 479.833 636.259 487 618.716 497C601.173 507 523.345 513.167 486.624 515C482.621 515.667 459.579 515.4 399.432 509C339.285 502.6 323.901 485 323.727 477V342.5C323.727 311.3 344.089 307 355.053 308.5Z'
        fill={textColor}
      />
      <path
        d='M556.586 260H410.396C406.936 260 404.131 262.686 404.131 266V283C404.131 286.314 406.936 289 410.396 289H556.586C560.046 289 562.851 286.314 562.851 283V266C562.851 262.686 560.046 260 556.586 260Z'
        fill='#061754'
        fill-opacity='0.49'
      />
      <path
        d='M310.152 286.366C310.152 262.416 330.427 243 355.436 243H609.459C634.467 243 654.742 262.416 654.742 286.366C654.742 295.553 646.966 303 637.372 303H327.522C317.929 303 310.152 295.553 310.152 286.366Z'
        fill={textColor}
      />
      <path
        d='M556.586 594H410.396C406.936 594 404.131 596.686 404.131 600V617C404.131 620.314 406.936 623 410.396 623H556.586C560.046 623 562.851 620.314 562.851 617V600C562.851 596.686 560.046 594 556.586 594Z'
        fill='white'
      />
      <path
        d='M312.762 340V349L316.417 345.5V321V306L400.998 303.5C371.064 296.833 311.092 285 310.674 291C310.256 297 305.278 305.167 302.842 308.5C283.629 315.7 270.472 341.5 266.295 353.5V405H291.878V359C291.878 356.167 293.34 348.4 299.188 340C305.035 331.6 310.674 336.5 312.762 340Z'
        fill={textColor}
      />
      <path
        d='M291.878 404.5C291.878 410.851 285.979 416 279.348 416C272.716 416 266.295 410.851 266.295 404.5C266.295 398.149 272.716 393 279.348 393C285.979 393 291.878 398.149 291.878 404.5Z'
        fill={textColor}
      />
      <path
        d='M655.263 339.646L652.131 353L651.608 345.146V320.646V305.646L567.027 303.146C596.962 296.48 654.323 282.5 654.741 288.5C655.159 294.5 662.747 304.813 665.183 308.146C684.397 315.346 697.554 341.146 701.731 353.146V404.646H676.147V358.646C676.147 355.813 674.685 348.046 668.838 339.646C662.99 331.246 657.352 336.146 655.263 339.646Z'
        fill={textColor}
      />
      <path
        d='M676.146 404C676.146 410.351 682.045 415.646 688.677 415.646C695.309 415.646 701.73 410.498 701.73 404.146C701.73 397.795 695.309 392.646 688.677 392.646C682.045 392.646 676.146 397.649 676.146 404Z'
        fill={textColor}
      />
      <path
        d='M323.727 564V536.5C326.65 525.7 353.487 534.667 366.539 540.5L395.777 560C405.384 568 399.78 575.667 395.777 578.5H351.92C331.454 576.5 324.596 568 323.727 564Z'
        fill={busColor}
      />
      <path
        d='M640.959 564.519V537.054C638.007 526.268 610.909 535.223 597.729 541.049L568.207 560.524C558.508 568.514 564.165 576.17 568.207 579H612.49C633.156 577.003 640.079 568.514 640.959 564.519Z'
        fill={busColor}
      />
    </svg>
  );
};
export default SpotbusPin;
