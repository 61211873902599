import React from 'react';

// material ui
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';

// project imports
import Maps from 'components/Maps/index';

// Custom Component
import QuickViewItemAccordion from 'components/accordions/QuickViewItemAccordion';

export default function DriverAddress({
  street,
  city,
  state,
  zipCode,
  aptPlot,
  geoLocation,
  isPremiumUser,
  dataLoading,
}) {
  const theme = useTheme();

  const [openClose, setOpenClose] = React.useState(true);

  const handleAccordionToggle = () => {
    setOpenClose(!openClose);
  };

  const geoLocationDate = Boolean(geoLocation?.latitude) && Boolean(geoLocation?.longitude);

  return (
    <>
      <QuickViewItemAccordion
        accordionSummary={street && state ? `${street}  ${state}` : '---'}
        startIcon={<HomeIcon fontSize='large' />}
        label={'Address'}
        isLoading={dataLoading}
        isExpandable={street && state ? `${street}  ${state}` : false}
        isExpanded={true}
      >
        <Grid item xs={12} container spacing={3} style={{ marginBottom: 8 }}>
          <Grid item xs={4}>
            <Typography color='textSecondary' variant='headline' style={{ fontWeight: 500 }}>
              Address :
            </Typography>
          </Grid>
          {dataLoading ? (
            <Grid item container xs={8} justifyContent='flex-end'>
              <Skeleton variant='text' width={'100%'} />
            </Grid>
          ) : (
            <Grid item container xs={8} justifyContent='flex-end'>
              {street && (
                <Grid item>
                  <Typography color='textSecondary' inline id='street'>
                    {street}
                  </Typography>
                </Grid>
              )}
              {aptPlot && (
                <Grid item>
                  <Typography color='textSecondary' inline id='apt_plot'>
                    {'Apt '}
                    {aptPlot}
                  </Typography>
                </Grid>
              )}
              {city && (
                <Grid item>
                  <Typography color='textSecondary' inline id='city'>
                    {city}
                  </Typography>
                </Grid>
              )}
              {state && (
                <Grid item>
                  <Typography color='textSecondary' inline id='state'>
                    {state}
                  </Typography>
                </Grid>
              )}
              {zipCode && (
                <Grid item>
                  <Typography color='textSecondary' inline id='zip_code'>
                    {zipCode}
                  </Typography>
                </Grid>
              )}
              {!street && !aptPlot && !city && !state && !zipCode && (
                <Grid item>
                  <Typography
                    color='textSecondary'
                    inline
                    variant='headline'
                    style={{ fontWeight: 500 }}
                  >
                    ---
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {geoLocationDate && (
              <Maps
                isPremiumUser={isPremiumUser}
                height={0.35}
                isLoading={dataLoading}
                markers={[
                  {
                    iconName: 'home',
                    showTooltip: false,
                    geoLocation: geoLocation,
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </QuickViewItemAccordion>
      {/* old code (deprecated) */}
      {/* <Accordion expanded={openClose}>
        <AccordionSummary
          onClick={handleAccordionToggle}
          expandIcon={<ExpandMoreIcon color='primary' id='show_home_addess' />}
        >
          <Grid container alignItems='center' spacing={3} sx={{ paddingLeft: theme.spacing(1) }}>
            <Grid item xs={2} sx={{ paddingRight: theme.spacing(1) }}>
              <HomeOutlinedIcon color='secondary' fontSize='large' />
            </Grid>
            <Grid item xs={10}>
              <Typography color='primary'>Address</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container spacing={3} style={{ marginBottom: 8 }}>
            <Grid item xs={4}>
              <Typography color='textSecondary' variant='headline' style={{ fontWeight: 500 }}>
                Address :
              </Typography>
            </Grid>
            {dataLoading ? (
              <Grid item container xs={8} justifyContent='flex-end'>
                <Skeleton variant='text' width={'100%'} />
              </Grid>
            ) : (
              <Grid item container xs={8} justifyContent='flex-end'>
                {street && (
                  <Grid item>
                    <Typography color='textSecondary' inline id='street'>
                      {street}
                      {', '}
                    </Typography>
                  </Grid>
                )}
                {aptPlot && (
                  <Grid item>
                    <Typography color='textSecondary' inline id='apt_plot'>
                      {'Apt '}
                      {aptPlot}
                      {', '}
                    </Typography>
                  </Grid>
                )}
                {city && (
                  <Grid item>
                    <Typography color='textSecondary' inline id='city'>
                      {city}
                      {', '}
                    </Typography>
                  </Grid>
                )}
                {state && (
                  <Grid item>
                    <Typography color='textSecondary' inline id='state'>
                      {state}
                      {', '}
                    </Typography>
                  </Grid>
                )}
                {zipCode && (
                  <Grid item>
                    <Typography color='textSecondary' inline id='zip_code'>
                      {zipCode}
                    </Typography>
                  </Grid>
                )}
                {!street && !aptPlot && !city && !state && !zipCode && (
                  <Grid item>
                    <Typography
                      color='textSecondary'
                      inline
                      variant='headline'
                      style={{ fontWeight: 500 }}
                    >
                      ---
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Maps
                isPremiumUser={isPremiumUser}
                geoLocation={geoLocation}
                dataLoading={dataLoading}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
}
