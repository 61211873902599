import React, { Component } from 'react';

// third-party imports
import Amplify, { API } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

// APIs
import { getGuidedWorkflow } from 'clients/SpotBus';
import { getSessionList } from 'clients';

// project imports
import awsconfig from './aws-exports';
import Loader from 'components/Loader';
// import GuidedWorkflow from 'containers/layouts/GuidedWorkflow';
import GuidedWorkflow from 'pages/GuidedWorkflow/index';
import MainLayout from 'layouts';
import spotbusapis from './spotbus-api-config';

// assets
import './App.css';

// context
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: 1000 * 60 * 5,
    },
  },
});

// ============================== APP - Main Component after authentication ============================== //
class App extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.guidedWorkflowData) return;
    try {
      let guidedResponse = await getGuidedWorkflow();
      let localSessionData = localStorage.getItem('currentSessionId');
      if (!localSessionData) {
        let sessionsResponse = await getSessionList();
        localStorage.setItem('currentSessionId', sessionsResponse?.[0]?.id);
      }
      this.props.handleGuidedWorkflowDataUpdate(guidedResponse);
    } catch (error) {
      console.error('Guided workflow api error', error);
    }
  }

  render() {
    Amplify.configure(awsconfig);
    API.configure(spotbusapis);

    return (
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={2}>
          <BrowserRouter>
            {!this.props.guidedWorkflowData && <Loader />}
            {this.props.guidedWorkflowData &&
              (this.props.isGuidedWorkflowCompleted ? (
                <MainLayout {...this.props} />
              ) : (
                <GuidedWorkflow {...this.props} />
              ))}
          </BrowserRouter>
        </SnackbarProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    );
  }
}

export default App;
