import React, { useState } from 'react';
import { TextField, Grid, FormHelperText, FormControl, Typography } from '@mui/material';
//FORMIK
import { Field, ErrorMessage } from 'formik';

const OtpTextField = ({ field, form, otpLength }) => {
  const [otp, setOtp] = useState(otpLength);

  const handleChange = (e, index) => {
    if (/[^0-9]/.test(e.target.value)) return;

    const newOtp = [...otp];
    newOtp[index] = e.target.value;

    if (e.target.value !== '' && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    const otpVrfy = newOtp.toString().replace(/,/g, '');
    form.setFieldValue(field.name, otpVrfy);
    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <FormControl sx={{ width: '100%' }} error={form.errors[field.name] && form.touched[field.name]}>
      <Grid container spacing={3}>
        {otp.map((digit, index) => (
          <Grid item xs={2}>
            <TextField
              hiddenLabel
              key={index}
              variant='filled'
              id={`otp-input-${index}`}
              type='text'
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center' },
              }}
            />
          </Grid>
        ))}
        {form.errors[field.name] && form.touched[field.name] ? null : (
          <FormHelperText>{' '}</FormHelperText>
        )}
        <ErrorMessage name={field.name} component={FormHelperText} />
      </Grid>
    </FormControl>
  );
};

const OTPInput = ({ name, ...rest }) => {
  return <Field name={name} component={OtpTextField} {...rest} />;
};

export default OTPInput;
