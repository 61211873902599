import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project imports
import MainCard from 'components/MainCard';

// ============================== CUSTOM CHIP ARRAY COMPONENT (Uses formik)  ============================== //

function CustomChipArray(props) {
  const { size, color, icon, chipItems, label, helperText, children } = props;
  const { setFieldValue } = props.form;
  const { name, value } = props.field;

  const handleChipClick = (newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <>
      <MainCard
        content={false}
        style={{
          padding: 6,
          height: '4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={3}
          width={'100%'}
        >
          <Typography>{label}</Typography>
          <Stack direction='row' spacing={3} alignItems='center'>
            {chipItems.map((data, key) => {
              return (
                <Chip
                  key={key}
                  icon={icon && data.icon}
                  label={data.label}
                  size={size}
                  variant={value === data.value ? 'default' : 'outlined'}
                  color={value === data.value ? color : 'default'}
                  onClick={(event) => handleChipClick(data.value)}
                  id={data.label.toLowerCase()}
                  disabled={props.disabled}
                />
              );
            })}
            {children}
          </Stack>
        </Stack>
      </MainCard>
      <Typography variant='caption' color='text.secondary' ml={3}>
        {helperText}
      </Typography>
    </>
  );
}

CustomChipArray.defaultProps = {
  size: 'medium',
  color: 'primary',
  icon: true,
  children: null,
};

CustomChipArray.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  color: PropTypes.string,
  icon: PropTypes.bool,
  chipItems: PropTypes.array,
  label: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  children: PropTypes.node,
};

export default CustomChipArray;
