import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';

// assets
import CancelIcon from '@mui/icons-material/Cancel';

// ============================== Reset Button ============================== //

function CancelButton({ children, ...props }) {
  return (
    <Button {...props} startIcon={props.isIcon && <CancelIcon />}>
      {children.toUpperCase()}
    </Button>
  );
}

CancelButton.defaultProps = {
  variant: 'outlined',
  size: 'large',
  color: 'secondary',
  isIcon: false,
};
CancelButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  isIcon: PropTypes.bool,
  children: PropTypes.string,
};

export default CancelButton;
