import React from 'react';
import { useState } from 'react';

// third-party imports
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

// APIs
import { createSession, updateSession } from 'clients';

// project imports
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import SessionForm from './SessionForm';

// Helper Function
import { ConvertedSchoolScheduleDays, convertedSchoolScheduleDaysIntoBoolean } from './Utils';
import { initialValuesOfSessionForm } from './FormModel/formInitialValues';

const _register_factory = (values, updatedSchoolScheduleValues) => {
  const finalSchoolScheduleValues =
    Boolean(updatedSchoolScheduleValues) && updatedSchoolScheduleValues;

  return {
    name: values.name,
    desc: values.desc,
    status: values.status,
    routes_config: {
      min_dis_btw_stops: '0.2',
      distance_range: ['1.5', '22'],
      duration_range: [30, 120],
      max_student_ridership: 90,
      max_students_per_stop: 5,
      sped_transportation: false,
      tier_based_planning: false,
      use_adj_vehicle_cap: false,
      reserve_cap: 5,
      sex_offenders: false,
    },
    session_config: {
      auto_import_schools: true,
      auto_import_users: true,
      auto_import_drivers: values.auto_import_drivers,
      auto_import_vehicles: values.auto_import_vehicles,
      auto_import_students: values.auto_import_students,
      auto_promote_students: {
        elementary: values.auto_promote_students_elementary,
        middle: values.auto_promote_students_middle,
        high: values.auto_promote_students_high,
        others: values.auto_promote_leftover_students,
      },
      auto_import_routes: values.auto_import_routes,
    },
    schools_schedule: ConvertedSchoolScheduleDays(finalSchoolScheduleValues),
  };
};

const _form_factory = (session) => {
  return {
    name: session.name,
    desc: session.desc,
    status: session.status,
    auto_import_drivers: session.session_config.auto_import_drivers,
    auto_import_vehicles: session.session_config.auto_import_vehicles,
    auto_import_students: session.session_config.auto_import_students,
    auto_promote_students_elementary: session.session_config.auto_promote_students.elementary,
    auto_promote_students_middle: session.session_config.auto_promote_students.middle,
    auto_promote_students_high: session.session_config.auto_promote_students.high,
    auto_promote_leftover_students: session.session_config.auto_promote_students.others,
    auto_import_routes: session.session_config.auto_import_routes,
    schools_schedule: convertedSchoolScheduleDaysIntoBoolean(session.schools_schedule),
  };
};

const _update_factory = (sessionData, values, updatedSchoolScheduleValues) => {
  const finalScheduleSchoolValues =
    Boolean(updatedSchoolScheduleValues) && updatedSchoolScheduleValues;

  let updated_obj = { ...sessionData };

  updated_obj.name = values.name;
  updated_obj.desc = values.desc;
  updated_obj.status = values.status;

  let updated_session_config = updated_obj.session_config;
  updated_session_config.auto_import_drivers = values.auto_import_drivers;
  updated_session_config.auto_import_vehicles = values.auto_import_vehicles;
  updated_session_config.auto_import_students = values.auto_import_students;
  updated_session_config.auto_import_routes = values.auto_import_routes;

  let updated_auto_promote_students = updated_session_config.auto_promote_students;
  updated_auto_promote_students.elementary = values.auto_promote_students_elementary;
  updated_auto_promote_students.middle = values.auto_promote_students_middle;
  updated_auto_promote_students.high = values.auto_promote_students_high;
  updated_auto_promote_students.others = values.auto_promote_leftover_students;
  updated_obj.schools_schedule = ConvertedSchoolScheduleDays(finalScheduleSchoolValues);

  return updated_obj;
};

function Sessions(props) {
  const {
    schoolsData,
    guidedForm,
    sessionCardData,
    isEditGuidedSession,
    getSession,
    handleUpdateGuidedWorkflowData,
  } = props;

  const [initialValues, setInitialValues] = useState(initialValuesOfSessionForm());

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  let navigate = useNavigate();

  let sessionData;
  let factorySessionData;

  if (guidedForm && sessionCardData) {
    sessionData = sessionCardData;
    factorySessionData = isEditGuidedSession && _form_factory(sessionData);
  } else {
    sessionData = location.state?.sessionData;
    factorySessionData = location.state?.isUpdate && _form_factory(sessionData);
  }

  const _registerSession = async (values, actions, updatedSchoolScheduleValues) => {
    try {
      const request_body = _register_factory(values, updatedSchoolScheduleValues);
      const response = await createSession(request_body);
      enqueueSnackbar('Session created successfully', {
        variant: 'success',
      });
      actions.resetForm();
      if (guidedForm) {
        getSession();
        handleUpdateGuidedWorkflowData(1);
      } else {
        const newWindow = window.open(
          `https://canvas.spotbus.app/${response?.id}`,
          '_blank',
          'noopener, noreferrer',
        );
        if (newWindow) newWindow.opener = null;
        navigate('/');
      }
    } catch (error) {
      console.error('Error registering session', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    } finally {
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  //Update Session
  const _updateSession = async (values, actions, updatedSchoolScheduleValues) => {
    let sessionId = isEditGuidedSession ? sessionCardData.id : location.state.sessionData.id;

    try {
      const request_body = _update_factory(sessionData, values, updatedSchoolScheduleValues);
      await updateSession(sessionId, request_body);
      enqueueSnackbar('Session update successfully', {
        variant: 'success',
      });
      actions.resetForm();
      if (isEditGuidedSession) {
        getSession();
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Error registering session', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    } finally {
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  let edit = isEditGuidedSession ? isEditGuidedSession : location.state?.isUpdate;

  const handleSubmit = (values, actions, updatedSchoolScheduleValues) => {
    if (edit) {
      _updateSession(values, actions, updatedSchoolScheduleValues);
    } else {
      _registerSession(values, actions, updatedSchoolScheduleValues);
    }
  };

  return (
    <SessionForm
      handleSubmit={handleSubmit}
      isUpdate={edit}
      sessionData={factorySessionData}
      schoolsData={schoolsData}
      enqueueSnackbar={enqueueSnackbar}
      setInitialValues={setInitialValues}
      initialValues={initialValues}
      guidedForm={guidedForm}
    />
  );
}

Sessions.propTypes = {};

export default Sessions;
