import React from 'react';
import PropTypes from 'prop-types';

// MUI imports
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// assets
import AddRoadIcon from '@mui/icons-material/AddRoad';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LoopIcon from '@mui/icons-material/Loop';
import PendingIcon from '@mui/icons-material/Pending';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

// All available status object
const statusObj = {
  pending: { label: 'Pending', value: 'pending', icon: PendingIcon, color: 'secondary' },
  Scheduled: { label: 'Scheduled', value: 'Scheduled', icon: PendingIcon, color: 'secondary' },
  inProgress: { label: 'In Progress', value: 'inProgress', icon: LoopIcon, color: 'info' },
  InProgress: { label: 'In Progress', value: 'InProgress', icon: LoopIcon, color: 'info' },
  Completed: { label: 'Completed', value: 'completed', icon: CheckCircleIcon, color: 'success' },
  failed: { label: 'Failed', value: 'failed', icon: CancelIcon, color: 'error' },
  OnRoad: { label: 'On Road', value: 'onRoad', icon: AddRoadIcon, color: 'primary' },
  BreakDown: { label: 'Breakdown', value: 'breakdown', icon: ReportProblemIcon, color: 'error' },
  OnTime: { label: 'On Time', value: 'OnTime', icon: DirectionsBusIcon, color: 'primary' },
  ArrivingEarly: {
    label: 'Arriving Early',
    value: 'OnTime',
    icon: DepartureBoardIcon,
    color: 'success',
  },
  RunningLate: {
    label: 'Running Late',
    value: 'RunningLate',
    icon: DepartureBoardIcon,
    color: 'error',
  },
  Covered: { label: 'Covered', value: 'Covered', icon: CheckCircleIcon, color: 'success' },
};

// Skeleton component
function StatusChipSkeleton(props) {
  return <Skeleton {...props} />;
}

// ============================== Chips - Status Chip  ============================== //

function StatusChip({ isLoading, isSubmitting, value, disableIcon, ...props }) {
  const matchedStatus = value && statusObj[value];

  if (isLoading) return <StatusChipSkeleton height={24} width={80} />;

  if (!matchedStatus) return <Typography color='error'>Invalid value</Typography>;

  return (
    <Chip
      color={matchedStatus.color}
      label={matchedStatus.label}
      icon={!disableIcon && <matchedStatus.icon />}
      {...props}
    />
  );
}

StatusChip.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  value: PropTypes.string.isRequired,
  disableIcon: PropTypes.bool,
};

StatusChip.defaultProps = {
  isLoading: false,
  isSubmitting: false,
  variant: 'filled',
  size: 'small',
  disableIcon: false,
};

export default StatusChip;
