import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';

import {
  searchSchool,
  callGetSearchFilter,
  handleSearchInputValue,
} from '../../../actions/searchActions';

import { handleSavedSearchData } from '../../../actions/savedSearchActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    marginRight: theme.spacing(2),

    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius + 2,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(1, 1, 0, 1),
    height: '100%',
    pointerEvents: 'none',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '32ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
  spinner: {
    color: '#fff',
    padding: theme.spacing(1, 1, 0, 1),
  },
  cancelIcon: {
    cursor: 'pointer',
  },
}));

export default function Search(props) {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchReducer = useSelector((state) => state.searchReducer);
  const { inputValue } = useSelector((state) => state.searchInputValue);

  const { isLoading } = searchReducer;

  const handleSearchEnter = (e) => {
    if (!inputValue.length) {
      console.log('No SearchTerm');
    } else {
      // callGetSearchData();
      dispatch(searchSchool(inputValue));
      dispatch(callGetSearchFilter(inputValue));
      dispatch(handleSavedSearchData({}));
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        id='navigationSearchInput'
        disabled={isLoading}
        value={inputValue}
        onChange={(e) => dispatch(handleSearchInputValue(e.target.value))}
        placeholder='Search for routes, drivers & more...'
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            if (location.pathname !== '/search') {
              navigate('/search');
            }
            handleSearchEnter();
          }
        }}
      />
      <div>
        {inputValue.length && !isLoading ? (
          <ClearIcon
            className={classes.cancelIcon}
            size={25}
            onClick={() => dispatch(handleSearchInputValue(''))}
          />
        ) : (
          ''
        )}
      </div>
      <div>{isLoading && <CircularProgress className={classes.spinner} size={25} />}</div>
    </div>
  );
}
