import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ListItemIcon, useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// custom hooks & contexts
import { useColorMode } from 'contexts';
import { useDrawer } from 'contexts';

// third party imports
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// components
import IcoButton from 'components/Buttons';

// project imports
import { drawerWidth } from 'constants';
import WeatherWidgetv2 from 'weather/WeatherWidgetv2';
import Search from 'containers/layouts/HeaderSearch/Search';
import SessionHeader from 'pages/Sessions/SessionHeader';

// utils
import { hexToRgbA } from 'utils';

// assets
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import UserProfile from 'pages/UserProfile';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, matchDownMD }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderBottom: `1px solid ${theme.palette.divider}`,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open &&
    !matchDownMD && {
      width: `calc(100% - ${theme.spacing(17)} + 1px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

function getMenuItems(menuItems, handleClose) {
  return menuItems
    ? menuItems.map((item) => {
        if (
          item.id === 'manage_users' &&
          localStorage.getItem('loggedUserRoleType') === 'ROLE_SCHOOL_LEVEL_ACCESS'
        )
          return;
        if (
          item.id === 'manage_users' &&
          localStorage.getItem('loggedUserRoleType') === 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS'
        )
          return;

        return (
          <Link to={item.url} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <MenuItem onClick={handleClose} id={item.id}>
              <ListItemIcon>
                <item.icon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                {item.name}
              </Typography>
            </MenuItem>
          </Link>
        );
      })
    : undefined;
}

async function onSignOutClick() {
  await Auth.signOut()
    .then((data) => {
      console.log(data);
      sessionStorage.clear();
      localStorage.clear();
    })
    .catch((err) => console.log(err));
}

export default function MainNavbar(props, city) {
  const theme = useTheme();
  const { isDarkTheme, toggleThemeColorMode } = useColorMode();
  const { drawerOpen, handleDrawerToggle } = useDrawer();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAvatarMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position='fixed'
      color='inherit'
      style={{
        background: `${hexToRgbA(theme.palette.background.paper, 0.6)}`,
        backdropFilter: 'blur( 8px )',
        WebkitBackdropFilter: 'blur( 8px )', // important for Safari
      }}
      elevation={0}
      open={drawerOpen}
      matchDownMD={matchDownMD}
    >
      <Toolbar>
        {matchDownMD && (
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={handleDrawerToggle}
            id='drawerMenuButton'
            size='small'
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              marginLeft: theme.spacing(2),
              ...(!drawerOpen && {
                transform: 'rotate(180deg)',
              }),
              '&.MuiIconButton-root': {
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.default,
              },
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        )}
        <Search />
        <Box sx={{ flexGrow: 1 }} />
        <SessionHeader />
        {/* <WeatherWidgetv2
          selectedWeatherCity={props.selectedWeatherCity}
          defaultWeatherCity={props.defaultWeatherCity}
        /> */}
        <Tooltip title={isDarkTheme ? 'Switch to Light Mode' : 'Switch to Dark Mode'} arrow>
          <IconButton
            sx={{ ml: 1 }}
            onClick={toggleThemeColorMode}
            color='inherit'
            id='headerThemeToggle'
          >
            {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Tooltip>
        <UserProfile accountInfo={props.accountInformation} />
        {auth && (
          <div>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openAvatarMenu}
              onClose={handleAvatarMenuClose}
            >
              {getMenuItems(props.avatarMenuItems, handleAvatarMenuClose)}
              <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <MenuItem onClick={onSignOutClick} key='Signout'>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize='small' />
                  </ListItemIcon>
                  <Typography variant='inherit' id='sign_out' noWrap>
                    Sign Out
                  </Typography>
                </MenuItem>
              </Link>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

MainNavbar.propTypes = {
  appTitle: PropTypes.string.isRequired,
  avatarMenuItems: PropTypes.array.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};
