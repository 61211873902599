import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui imports
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Project Import
import ButtonBases from 'components/Buttons/ComplexButton';
import RightSideCard from '../ConfigureStudents/RightSideCard';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import RoutesListSection from './RoutesListSection';

// Third Party
import { useSnackbar } from 'notistack';

// Calling API
import { deleteRoute, updateGuidedWorkFlow } from 'clients/SpotBus';
import RouteForm from '../Forms/RouteForm/index';
import { useEffect } from 'react';
import { getAllRoutesClassification } from 'clients/SpotBus';
import GuidedRoutePlanningWrapper from './GuidedRoutePlanningWrapper';
import BackButton from 'components/Buttons/BackButton';

function FirstRightSec(props) {
  return (
    <>
      <RightSideCard
        firstMainHeadLine={'Add Route '}
        firtsParagraph={
          'Add all stops with accurate address for route with scheduled time and assign it to a driver. Mark address on map to fill latitude and longitude accurately.'
        }
        secondMainHeadine={' Route Planning   '}
        secondParagraph={
          'We will help you in creating new and accurate route according to available students and vehicle with minimum stops.'
        }
        firstImage={'https://i.ibb.co/wrGmxY2/Add-Route-Image.png'}
        secondImage={'https://i.ibb.co/mSKqfp3/route-planing.jpg'}
      />
    </>
  );
}

function RoutePlannigSection(props) {
  return (
    <RoutesListSection
      guidedMainData={props.guidedRoutes}
      openDeleteAlert={props.openDeleteAlert}
      setOpenDeleteAlert={props.setOpenDeleteAlert}
      deleteButtonLoader={props.deleteButtonLoader}
      handleDelete={props.handleDeleteRoute}
      mainheadingofList={'Routes'}
      deteleListLoader={props.deteleListLoader}
      deleteAlertText={'Route'}
      cardForSection={true}
      handleEdit={props.handleEdit}
      userListHeading={'routes'}
      listLoader={props.listLoader}
      apiLoading={props.apiLoading}
    />
  );
}

function AddRouteSection(props) {
  return (
    <>
      <RoutesListSection
        guidedMainData={props.guidedRoutes}
        openDeleteAlert={props.openDeleteAlert}
        setOpenDeleteAlert={props.setOpenDeleteAlert}
        deleteButtonLoader={props.deleteButtonLoader}
        handleDelete={props.handleDeleteRoute}
        mainheadingofList={'Routes'}
        deteleListLoader={props.deteleListLoader}
        deleteAlertText={'Route'}
        cardForSection={true}
        handleEdit={props.handleEdit}
        userListHeading={'routes'}
        listLoader={props.listLoader}
        apiLoading={props.apiLoading}
      />
    </>
  );
}

function ConfigureRoutes(props) {
  const [csActiveStep, setCsActiveStep] = useState(0);
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deteleListLoader, setDeleteListLoader] = useState(false);

  // handleEdit for Guided Routes
  const [routeEditData, setRouteEditData] = useState(undefined);
  const [editRouteForm, setEditRouteForm] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [classificationData, setClassificationData] = React.useState([]);
  const [reloadEditForm, setreloadEditForm] = React.useState(undefined);

  // Route Planning Variables
  const [isRoutePlanningInProgress, setIsRoutePlanningInProgress] = useState(false);
  const [routePlanningProgress, setRoutePlanningProgress] = useState(0);

  //guided workflow complete

  // disable edit and delete button on add and update api call
  const [apiLoading, setApiLoading] = useState(false);

  // getting index for update guided workflow data on register or delete
  const getIndex = (req, schoolId) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'CONFIGURE_SCHOOLS') {
        for (let j = 0; j < req.steps_graph[i].data.length; j++) {
          if (
            req.steps_graph[i].data[j].name === 'ADD_ROUTES' &&
            req.steps_graph[i].data[j].school_id === schoolId
          ) {
            return [i, j];
          }
        }
        return i;
      }
    }
  };

  // update guided workflow data on register or delete
  const handleUpdateGuidedWorkflowData = async (count = 1, schoolId) => {
    let req = props.guidedWorkflowData;
    let indexArr = getIndex(props.guidedWorkflowData, schoolId);
    req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count =
      req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count + count;
    if (
      req.steps_graph[indexArr[0]].data[indexArr[1]].min_items_required <=
      req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count
    ) {
      req.steps_graph[indexArr[0]].data[indexArr[1]].is_completed = true;
    } else {
      req.steps_graph[indexArr[0]].data[indexArr[1]].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoutesClassification = async () => {
    try {
      const result = await getAllRoutesClassification();
      setClassificationData(result.routes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoutesClassification();
  }, [editRouteForm === true]);

  const handleEdit = (data) => {
    setCsActiveStep(1);
    setRouteEditData(data);
    setEditRouteForm(true);
    setreloadEditForm(data.route_id);
  };

  const handleDeleteRoute = async (routeID) => {
    setDeleteButtonLoader(true);
    setDeleteListLoader(true);
    try {
      const result = await deleteRoute(routeID);
      enqueueSnackbar('Route Successfully deleted', {
        variant: 'success',
      });
      props.getAllRoutes(props.selectedSchoolId);
      setOpenDeleteAlert(false);
      setDeleteButtonLoader(false);
      setDeleteListLoader(false);
      setEditRouteForm(false);
      handleUpdateGuidedWorkflowData(-1, routeID.split('_')[0]);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDeleteButtonLoader(false);
    }
  };

  useEffect(() => {
    props.getAllRoutes(props.selectedSchoolId);
    return closeSnackbar();
  }, []);

  const ConfigureRoutesTypes = () => {
    const addRoutehandle = () => {
      setCsActiveStep(1);
      setEditRouteForm(false);
      props.setGloablBtnRoutes(true);
    };

    const addRouteButton = () => {
      setCsActiveStep(2);
      props.setGloablBtnRoutes(true);
    };
    return (
      <>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Grid xs='auto'>
            <ButtonBases
              title='Add Route '
              imageForButtonBackGround={'https://i.ibb.co/wrGmxY2/Add-Route-Image.png'}
              onClickHandler={addRoutehandle}
            />
          </Grid>
          <Grid xs='auto'>
            <ButtonBases
              title='Route Planning '
              imageForButtonBackGround={'https://i.ibb.co/mSKqfp3/route-planing.jpg'}
              onClickHandler={addRouteButton}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <BackButton onClick={() => props.setSecondPhaseActiveStep(0)}>
            CONFIGURE STUDENTS
          </BackButton>
        </Grid>
      </>
    );
  };

  const getConfigureRoutesActiveStep = (step) => {
    switch (step) {
      case 0:
        return <ConfigureRoutesTypes />;
      case 1:
        return (
          <RouteForm
            getAllRoutes={props.getAllRoutes}
            editRouteForm={editRouteForm}
            routeEditData={routeEditData}
            setRouteEditData={setRouteEditData}
            classificationData={classificationData}
            setEditRouteForm={setEditRouteForm}
            reloadEditForm={reloadEditForm}
            setlistLoader={props.setlistLoader}
            setCsActiveStep={setCsActiveStep}
            isRoutePlanningInProgress={isRoutePlanningInProgress}
            setIsRoutePlanningInProgress={setIsRoutePlanningInProgress}
            routePlanningProgress={routePlanningProgress}
            setRoutePlanningProgress={setRoutePlanningProgress}
            handleUpdateGuidedWorkflowData={handleUpdateGuidedWorkflowData}
            guidedWorkflowData={props.guidedWorkflowData}
            setApiLoading={setApiLoading}
            selectedSchoolData={props.selectedSchoolData}
            selectedGuidedSchoolId={props.selectedSchoolId}
            selectedSchoolId={props.selectedSchoolId}
            setSelectedSchoolId={props.setSelectedSchoolId}
            globalBtnRoutes={props.globalBtnRoutes}
            setGloablBtnRoutes={props.setGloablBtnRoutes}
            guidedRoutes={props.guidedRoutes}
          />
        );
      case 2:
        return (
          <GuidedRoutePlanningWrapper
            setCsActiveStep={setCsActiveStep}
            isRoutePlanningInProgress={isRoutePlanningInProgress}
            setIsRoutePlanningInProgress={setIsRoutePlanningInProgress}
            routePlanningProgress={routePlanningProgress}
            setRoutePlanningProgress={setRoutePlanningProgress}
            handleUpdateGuidedWorkflowData={handleUpdateGuidedWorkflowData}
            selectedGuidedSchoolData={props.selectedSchoolData}
            setSelectedSchoolId={props.setSelectedSchoolId}
            selectedSchoolId={props.selectedSchoolId}
            setGloablBtnRoutes={props.setGloablBtnRoutes}
            globalBtnRoutes={props.globalBtnRoutes}
            guidedRoutes={props.guidedRoutes}
            {...props}
          />
        );

      default:
        return (
          <Typography color='error' variant='h5'>
            Error: No step available try using right step value.{' '}
          </Typography>
        );
    }
  };

  const getRightSection = (step) => {
    switch (step) {
      case 0:
        return <FirstRightSec guidedRoutes={props.guidedRoutes} />;
      case 1:
        return (
          <AddRouteSection
            guidedRoutes={props.guidedRoutes}
            openDeleteAlert={openDeleteAlert}
            setOpenDeleteAlert={setOpenDeleteAlert}
            deleteButtonLoader={deleteButtonLoader}
            handleDeleteRoute={handleDeleteRoute}
            deteleListLoader={deteleListLoader}
            handleEdit={handleEdit}
            listLoader={props.listLoader}
            apiLoading={apiLoading}
          />
        );
      case 2:
        return (
          <RoutePlannigSection
            guidedRoutes={props.guidedRoutes}
            openDeleteAlert={openDeleteAlert}
            setOpenDeleteAlert={setOpenDeleteAlert}
            deleteButtonLoader={deleteButtonLoader}
            handleDeleteRoute={handleDeleteRoute}
            deteleListLoader={deteleListLoader}
            handleEdit={handleEdit}
            listLoader={props.listLoader}
            apiLoading={apiLoading}
          />
        );

      default:
        return (
          <Typography color='error' variant='h5'>
            Error: No step available try using right step value.{' '}
          </Typography>
        );
    }
  };

  return (
    <>
      {csActiveStep === 2 ? (
        <Grid xs={12}>{getConfigureRoutesActiveStep(csActiveStep)}</Grid>
      ) : (
        <Grid container spacing={1} sx={{ ml: -2 }}>
          <Grid xs={8}>{getConfigureRoutesActiveStep(csActiveStep)}</Grid>
          <Grid xs={4}>{getRightSection(csActiveStep)}</Grid>
        </Grid>
      )}
    </>
  );
}

ConfigureRoutes.propTypes = {
  firstMainHeadLine: PropTypes.string,
  firtsParagraph: PropTypes.string,
  secondMainHeadine: PropTypes.string,
  secondParagraph: PropTypes.string,
  secondImage: PropTypes.string,
};

export default ConfigureRoutes;
