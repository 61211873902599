import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// third-party imports
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';

// ==============================|| Multi mobile numbers - Formik textfield ||============================== //

function MultiMobileNumberInput(props) {
  const { helperText, label, disabled } = props;
  const { name, value } = props.field;
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    setErrors,
    setStatus,
  } = props.form;

  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');

  const isError = Boolean(errors[name] && touched[name]);
  const helpText = isError ? errors[name] : helperText;

  const _handleNumberDelete = (value, item) => {
    let tempNumbers = value.filter((mob) => mob !== item);
    setFieldValue(name, tempNumbers);
  };

  const _checkNumber = (num) => {
    return isValidPhoneNumber(String(num));
  };

  const _handleOnChange = (newInputValue) => {
    setInputValue(newInputValue);
  };

  const _checkDuplicate = (allNumbers, tempNumber) => {
    const set = new Set(allNumbers);
    return !set.has(tempNumber);
  };

  const _checkedNumber = () => {
    let tempNumber = inputValue && inputValue.trim();
    let validPhoneNumber = _checkNumber(tempNumber);
    let duplicatNumber = _checkDuplicate(value, tempNumber);

    if (tempNumber && validPhoneNumber && duplicatNumber) {
      setFieldValue(name, [...value, tempNumber]);
      setInputValue('');
    }
  };

  const _handleKeyDownEvent = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      _checkedNumber();
    }
  };

  const _handleFocus = () => {
    // setFieldTouched(name, true);
  };

  const _handleBlur = () => {
    setFieldTouched(name, true);
    _checkedNumber();
  };

  const _handleBorderColor = (disabled) => {
    if (disabled) {
      return theme.palette.secondary.light;
    }
    if (isError) {
      return theme.palette.error.main;
    } else {
      if (Boolean(touched[name])) {
        return theme.palette.primary.main;
      } else {
        return theme.palette.secondary.main;
      }
    }
  };

  const _handleInputBorder = (tempNumber) => {
    let validPhoneNumber = _checkNumber(tempNumber);
    let duplicatNumber = !_checkDuplicate(value, tempNumber);
    if (!tempNumber) {
      return theme.palette.secondary.main;
    } else if ((duplicatNumber && validPhoneNumber) || !validPhoneNumber) {
      return theme.palette.error.main;
    } else if (validPhoneNumber) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.secondary.main;
    }
  };

  return (
    <FormControl variant='standard' fullWidth disabled={disabled}>
      <Box
        sx={{
          border: `1px solid ${_handleBorderColor(disabled)}`,
          padding: '.35rem',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          pointerEvents: disabled && 'none',
        }}
      >
        {value?.map((item, index) => {
          return (
            <Chip
              key={`phone-${index}`}
              label={formatPhoneNumber(item)}
              variant='outlined'
              sx={{ mx: 1 }}
              onDelete={() => _handleNumberDelete(value, item)}
            />
          );
        })}
        <div id='country_select'>
          <PhoneInput
            countries={['US', 'IN']}
            id='comm_mob_num'
            defaultCountry='US'
            international
            withCountryCallingCode
            countryCallingCodeEditable={false}
            value={inputValue}
            onChange={_handleOnChange}
            onKeyDown={(evt) => _handleKeyDownEvent(evt)}
            onFocus={_handleFocus}
            onBlur={_handleBlur}
            style={{ borderColor: `${_handleInputBorder(inputValue)}` }}
            dropdownClass='country_dropdown'
          />
        </div>
      </Box>
      <Typography
        sx={{ ml: 3 }}
        id='email-to'
        variant='caption'
        color={isError ? 'error' : 'text.secondary'}
        error={isError}
      >
        {helpText}
      </Typography>
    </FormControl>
  );
}

MultiMobileNumberInput.propTypes = {};

export default MultiMobileNumberInput;
