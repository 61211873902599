import { CURRENT_SESSION_DATA } from 'reduxConstants/globalConstants';

const initialState = {
  selectedSessionData: null,
};

/**
 * Update the state with session data if the action type is CURRENT_SESSION_DATA.
 *
 * @param {object} state - The current state of the application.
 * @param {object} action - The action object with a type and payload property.
 * @param {string} action.type - The type of the action.
 * @param {any} action.payload - The data payload of the action.
 * @returns {object} - The updated state object.
 */

export const globalReducers = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_SESSION_DATA:
      return {
        ...state,
        selectedSessionData: action.payload,
      };

    default:
      return state;
  }
};
