import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

// Skeleton implementation
function DefaultAvatarSkeleton(props) {
  return <Skeleton {...props} />;
}

DefaultAvatarSkeleton.defaultProps = {
  variant: 'circular',
  height: 40,
  width: 40,
};

// ============================== Default Avatar - Global Component  ============================== //

function DefaultAvatar({
  isLoading,
  isSubmitting,
  width,
  height,
  sx,
  enableTooltip,
  tooltipContent,
  children,
  ...props
}) {
  /**
   * * Usage guide:
   * This global component is wrapper over MUI Avatar component, it can be used to show image and icon as per props passed
   * Support tooltips out of the box and MUI icon avatar, when passed as children component

   * example usage
   * default - <DefaultAvatar {refer MUI Avatar documentation for all accepted props} />
   * icon avatar - <DefaultAvatar {...props}> icon </DefaultAvatar>
   * TODO: with tooltip - add this example
  
   * @returns Default avatar global component
   */
  
  function getAvatar() {
    if (isLoading) return <DefaultAvatarSkeleton height={height} width={width} />;
    else {
      if (children) {
        return (
          <Avatar {...props} sx={{ height: height, width: width, ...sx }}>
            {children}
          </Avatar>
        );
      } else {
        return <Avatar {...props} sx={{ height: height, width: width, ...sx }} />;
      }
    }
  }

  return (
    <>
      {tooltipContent ? (
        <Tooltip arrow title={tooltipContent}>
          {getAvatar()}
        </Tooltip>
      ) : (
        getAvatar()
      )}
    </>
  );
}

DefaultAvatar.prototype = {
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DefaultAvatar.defaultProps = {
  isLoading: false,
  isSubmitting: false,
  width: 40,
  height: 40,
  sx: {},
  variant: 'circle',
};

export default DefaultAvatar;
