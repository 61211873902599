export const reverseGeocode = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lon=${lng}&lat=${lat}&format=json`,
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const geoCode = async (address) => {
  // const url = " https://nominatim.openstreetmap.org/?addressdetails=1&/search&q=westborough+fire+department&format=json&limit=5"
  // const url = `https://nominatim.openstreetmap.org/?addressdetails=1&/search&=${address}&format=json&limit=5`;
  const url = `https://nominatim.openstreetmap.org/?addressdetails=1&/search&q=${address}&format=json&limit=5`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
