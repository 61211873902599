import React, { useState, useEffect } from 'react';

// material ui
import { Grid } from '@mui/material';

// third party
import { useSnackbar } from 'notistack';

// project imports
import VehicleList from '../../List/VehicleList/index';
import MainCard from 'components/MainCard';
import { Heading } from 'components/Headers/Heading';
import AddEditVehicle from 'containers/layouts/Vehicle/Components/AddEditVehicle';
import { deleteVehicle, updateGuidedWorkFlow } from 'clients/SpotBus';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import BackForward from '../../BackForward';

export default function VehicleGuidedForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [vehicleEditData, setVehicleEditData] = useState(undefined);
  const [editVehicleForm, setEditVehicleForm] = useState(false);

  // delete vehicle function
  const handleDeleteVehicle = async (vehicleId) => {
    setDeleteButtonLoader(true);
    enqueueSnackbar(`Deleting vehicle ${vehicleId}`);
    try {
      const result = await deleteVehicle(vehicleId);
      handleUpdateGuidedWorkflowData(-1);
      enqueueSnackbar('Vehicle successfully deleted', {
        variant: 'success',
      });
      setDeleteButtonLoader(false);
      props.getAllVehicles();
      setOpenDeleteAlert(!openDeleteAlert);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDeleteButtonLoader(false);
    }
  };

  // getting index for update guided workflow data on register or delete
  const getIndex = (req) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'ADD_VEHICLES') {
        return i;
      }
    }
  };

  // update guided workflow data on register or delete
  const handleUpdateGuidedWorkflowData = async (count = 1) => {
    let req = props.guidedWorkflowData;
    let i = getIndex(props.guidedWorkflowData);
    req.steps_graph[i].data[0].available_items_count =
      req.steps_graph[i].data[0].available_items_count + count;
    if (
      req.steps_graph[i].data[0].min_items_required <=
      req.steps_graph[i].data[0].available_items_count
    ) {
      req.steps_graph[i].data[0].is_completed = true;
    } else {
      req.steps_graph[i].data[0].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
      // console.log('Update Guided WorkFlow result : ', result);
    } catch (error) {
      console.log(error);
    }
  };

  //Handle Vehicle Edit
  const handleEdit = (data) => {
    setVehicleEditData(data);
    setEditVehicleForm(true);
  };

  useEffect(() => {
    return closeSnackbar();
  }, []);

  return (
    <Grid sx={{ mx: '2vw' }}>
      <MainCard>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid xs={12} sx={{ mb: 5 }}>
              <Heading title={editVehicleForm === true ? 'Update Vehicles' : 'Add Vehicles'} />
            </Grid>
            <Grid sx={{ pr: 2 }}>
              <AddEditVehicle
                isFromGuided={true}
                editVehicleForm={editVehicleForm}
                editData={vehicleEditData}
                getAllVehicles={props.getAllVehicles}
                setEditVehicleForm={setEditVehicleForm}
                increaseActiveStep={props.increaseActiveStep}
                decreaseActiveStep={props.decreaseActiveStep}
                setPhase={props.setPhase}
                handleUpdateGuidedWorkflowData={handleUpdateGuidedWorkflowData}
                setloadingData={props.setloadingData}
              />
            </Grid>

            <Grid>
              <BackForward
                disableButton={props.guidedWorkflowData?.steps_graph[4].data[0].is_completed}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <VehicleList
              guidedVehicles={props.guidedVehicles}
              handleEdit={handleEdit}
              handleDeleteVehicle={handleDeleteVehicle}
              deleteButtonLoader={deleteButtonLoader}
              openDeleteAlert={openDeleteAlert}
              setOpenDeleteAlert={setOpenDeleteAlert}
              loadingData={props.loadingData}
            />
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
