import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Typography } from '@mui/material';

// project imports
import MainCard from './MainCard';

// assets
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { resetBreadcumbsData } from 'actions/breadcrumbsActions';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ navigation, title, ...others }) => {
  const location = useLocation();
  const theme = useTheme();

  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [itemId, setItemId] = useState();

  const iconStyle = {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  };

  const breadcrumbsData = useSelector((state) => state.breadcrumbs).breadcrumbsData;

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if ((collapse.type && collapse.type === 'collapse') || collapse.type === 'section') {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === 'item') {
          let slashedUrl = collapse.url.split('/');
          let slashSeparatedPath = location.pathname.split('/');
          const id = slashSeparatedPath[slashSeparatedPath.length - 1];

          // popping last element of each arrays
          slashedUrl.pop();
          slashSeparatedPath.pop();

          if (location.pathname === collapse.url) {
            console.log('Inside default breadcrumb');
            setMain(menu);
            setItem(collapse);
            setItemId();
          } else if (
            slashedUrl.length > 1 &&
            id !== 'create' &&
            id !== 'pre-trip' &&
            id !== 'post-trip' &&
            id !== 'import' &&
            id !== 'app' &&
            slashedUrl.length === slashSeparatedPath.length &&
            slashedUrl.every((value, index) => value === slashSeparatedPath[index])
          ) {
            console.log('Inside details or edit breadcrumb');
            console.log(slashedUrl);
            console.log(slashSeparatedPath);

            setMain(menu);
            setItem(collapse);
            setItemId(id);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    // called every time when either location or breadcrumbsdata changed
    if (breadcrumbsData) {
      resetBreadcumbsData(location, breadcrumbsData);
    }

    navigation?.items?.map((menu) => {
      if ((menu.type && menu.type === 'drawerGroup') || 'headerGroup') {
        getCollapse(menu);
      }
      return false;
    });
  }, [location, breadcrumbsData]);

  // it detects back keyword
  window.onpopstate = () => {
    resetBreadcumbsData(
      location,
      breadcrumbsData,
      breadcrumbsData && breadcrumbsData.content ? breadcrumbsData.content.length - 1 : null,
    );
  };

  let mainContent;
  let breadcrumbContent = <Typography />;

  // return main content of breadcumbs
  function getMainContent(main, i, typeOf) {
    return main.typeOf === 'id' ? (
      <Typography
        key={i}
        variant='h6'
        sx={{ textDecoration: 'none', cursor: 'pointer' }}
        color='textSecondary'
        onClick={() => resetBreadcumbsData(location, breadcrumbsData, i)}
      >
        {main.title}
      </Typography>
    ) : (
      <Typography
        key={i}
        component={Link}
        to={main.url ? main.url : document.location.pathname}
        variant='h6'
        sx={{ textDecoration: 'none' }}
        color='textSecondary'
      >
        {main.title}
      </Typography>
    );
  }

  // collapse item
  if (main && main.type === 'collapse') {
    mainContent = getMainContent(main);
  }

  // section item
  if (main && main.type === 'section' && main.id !== item.id) {
    mainContent = getMainContent(main);
  }

  function getItemContent(itemTitle, i) {
    return (
      <Typography key={i} variant='subtitle1' color='textPrimary'>
        {itemTitle}
      </Typography>
    );
  }

  // items
  if (item && item.type === 'item') {
    // main
    if (item.breadcrumbs) {
      breadcrumbContent = (
        <MainCard sx={{ mb: 3, py: 1, px: 2 }} {...others} content={false}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}
          >
            {(title || (breadcrumbsData && breadcrumbsData.title)) && (
              <Grid item>
                <Typography variant='h2'>
                  {breadcrumbsData && breadcrumbsData.content
                    ? breadcrumbsData.content[breadcrumbsData.content.length - 1].mainTitle
                    : item.title}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <MuiBreadcrumbs
                aria-label='breadcrumb'
                separator={<NavigateNextIcon fontSize='small' />}
              >
                <Typography
                  component={Link}
                  to='/'
                  color='textSecondary'
                  variant='h6'
                  sx={{ textDecoration: 'none' }}
                >
                  <HomeIcon sx={{ ...iconStyle }} />
                </Typography>

                {breadcrumbsData && breadcrumbsData.content
                  ? breadcrumbsData.content.map((main, i) =>
                      i !== breadcrumbsData.content.length - 1
                        ? getMainContent(main, i, 'link')
                        : getItemContent(main.title, i),
                    )
                  : mainContent}

                {!breadcrumbsData && getItemContent(itemId ? itemId : item.title)}
              </MuiBreadcrumbs>
            </Grid>
          </Grid>
        </MainCard>
      );
    }
  }

  return location.pathname === '/search' ? null : breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool,
};

export default Breadcrumbs;

//It's an democode for data and calling the functions

// let breadcrumbsData = {
//     pathname: '/communication/' + communication_id,
//     content: [
//       {
//         mainTitle: 'Final Registration Reminder Emails',
//         title: 'Communications',
//         url: '/communications',
//         typeOf: 'link',
//       },
//       {
//         mainTitle: 'Final Registration Reminder Emails',
//         title: 'Historical Communications',
//         url: '/communications',
//         typeOf: 'link',
//       },
//       {
//         mainTitle: 'Final Registration Reminder Emails',
//         title: 'Form',
//         url: '/communication/' + communication_id,
//         typeOf: 'id',
//       },
//       {
//         mainTitle: 'Final Registration Reminder Emails',
//         title: 'Form',
//         url: '/communication/' + communication_id,
//         typeOf: 'id',
//       },
//     ],
//     isShowBreadcrumbs: true,
//   };
// saveBreadcumbsData(breadcrumbsData)
