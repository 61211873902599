import * as React from 'react';

//material ui
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//project imports
import MainCard from 'components/MainCard';
import { Heading } from 'components/Headers/Heading';

export default function UserCard(props) {
  return (
    <>
      {props.cardForSection && (
        <>
          <Grid mt={4}>
            <MainCard>
              <Grid container xs={12} sx={{ mb: -6, mt: -2 }}>
                <Grid container rowSpacing={0}>
                  <Grid container>
                    {props.specificforRouteSectionAvatar && (
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          pr: 6,
                          pl: 4,
                        }}
                      >
                        <Avatar
                          alt='Student Images '
                          src={props.user.profile_image?.url}
                          sx={{ width: 75, height: 75 }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={props.specificforRouteSectionAlignment} sx={{ pl: 2 }}>
                      <Grid
                        container
                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Grid item xs={12}>
                          <Typography variant='h4'>{props.firstheadingName}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container sx={{ pt: 1 }}>
                            <Grid item xs={1}>
                              {props.firstParagraphIcon}
                            </Grid>
                            <Grid item xs={11} sx={{ pl: 3 }}>
                              <Typography variant='body1' color='secondary.main'>
                                {props.firstParagraphName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={props.secondLineSecondsectionAlingnment}>
                          <Grid container>
                            <Grid item xs={1}>
                              {props.secondParagraphIcon}
                            </Grid>
                            <Grid
                              item
                              xs={11}
                              sx={props.specificforRouteSectionAvatar ? { pl: 4 } : { pl: 6 }}
                            >
                              <Typography variant='body1' color='secondary.main'>
                                {props.secondParagraphName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {props.secondParaSecondSectionNotForStudent && (
                          <>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item xs={1}>
                                  {props.secondLineSecondsectionIcon}
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    variant='body1'
                                    color='secondary.main'
                                    sx={{ pl: 4 }}
                                    noWrap
                                  >
                                    {props.secondLineSecondsection}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: -5 }}>
                      <IconButton
                        aria-label='edit'
                        color='secondary'
                        onClick={() => props.handleEdit(props.user)}
                        disabled={props.apiLoading}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        color='error'
                        onClick={() => {
                          props.setOpen();
                          props.setDeleteId(props.deleteIdforSection);
                        }}
                        disabled={props.apiLoading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </>
      )}
    </>
  );
}
