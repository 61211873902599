import React, { useState } from 'react';

// MUI Imports
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

// Components
import IconWithText from 'components/IconWithText';
import SettingsIcon from '@mui/icons-material/Settings';

import { getTogglePublicDashboardStatus, updatePublicRouteDashboardConfig } from 'clients/SpotBus';

import { CopyLinkAndViewLink } from './CopyLinkAndView';
import { SwitchWithText } from 'components/SwitchWithText';
import { PublicRouteCard } from './PublicRouteCard';
import { SettingConfigPopup } from './SettingConfigPopup';

/**
 * Renders a school level dashboard.
 *
 * @param {Object} school - The school object.
 * @param {Function} setSchoolsData - The function to set the schools data.
 * @return {JSX.Element} - The rendered school level dashboard.
 */
export const SchoolLevelDashboard = ({ school, setSchoolsData }) => {
  const routeDetails = school?.settings?.route_settings;
  const routeTripsDetails = school?.settings?.route_trips_settings;
  const [switchSchool, setSwitchSchool] = useState(school?.status === 'active' ? true : false);
  const [openSchoolSetting, setOpenSchoolSetting] = useState(false);

  // popupDialogState
  const [routeDetail, setRouteDetail] = useState(routeDetails?.enabled ? true : false);
  const [routeDriver, setRouteDriver] = useState(routeDetails?.show_driver_details ? true : false);
  const [routeVehicle, setRouteVehicle] = useState(
    routeDetails?.show_vehicle_details ? true : false,
  );

  const [tripDetail, setTripDetail] = useState(routeTripsDetails?.enabled ? true : false);
  const [tripDriver, setTripDriver] = useState(
    routeTripsDetails?.show_driver_details ? true : false,
  );
  const [tripVehicle, setTripVehicle] = useState(
    routeTripsDetails?.show_vehicle_details ? true : false,
  );

  /**
   * Handles toggling the school.
   *
   * @param {object} school - The school object.
   * @return {Promise<void>} - Resolves when the toggle is complete.
   */
  const handleToggleSchool = async (school) => {
    const { school_id } = school;

    // calling toggle api
    setSwitchSchool((pre) => !pre);
    try {
      getTogglePublicDashboardStatus(school_id);
    } catch (error) {
      console.log(error);
      setSwitchSchool((pre) => !pre);
    }
  };

  const requestBodyMain = {
    id: school.id,
    token: school.token,
    status: school.status,
    expires_on: school.expires_on,
    allowed_domains: [],
    created_by: school.created_by,
    creation_timestamp: school.creation_timestamp,
    updated_by: school.updated_by,
    updation_timestamp: school.updation_timestamp,
    organization_admin_username: school.organization_admin_username,
    settings: { ...school.settings },
  };

  // these function for when toggle then call api

  const handleRouteDetail = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          enabled: !routeDetail,
        },
      },
    };
    setRouteDetail((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      setRouteDetail((pre) => !pre);
    }
  };

  const handleRouteDriver = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          show_driver_details: !routeDriver,
        },
      },
    };
    setRouteDriver((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      console.log(error);
      setRouteDriver((pre) => !pre);
    }
  };
  const handleRouteVehicle = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_settings: {
          ...requestBodyMain.settings.route_settings,
          show_vehicle_details: !routeVehicle,
        },
      },
    };
    setRouteVehicle((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      console.log(error);
      setRouteVehicle((pre) => !pre);
    }
  };

  const handleTripDetail = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          enabled: !tripDetail,
        },
      },
    };
    setTripDetail((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      console.log(error);
      setTripDetail((pre) => !pre);
    }
  };
  const handleTripDriver = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          show_driver_details: !tripDriver,
        },
      },
    };
    setTripDriver((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      console.log(error);
      setTripDriver((pre) => !pre);
    }
  };
  const handleTripVehicle = async () => {
    const requestBody = {
      ...requestBodyMain,
      settings: {
        ...requestBodyMain.settings,
        route_trips_settings: {
          ...requestBodyMain.settings.route_trips_settings,
          show_vehicle_details: !tripVehicle,
        },
      },
    };
    setTripVehicle((pre) => !pre);
    setSchoolsData((pre) => {
      return pre.map((item) => {
        if (item.id === school.id) {
          return { ...item, ...requestBody };
        }
        return item;
      });
    });
    try {
      await updatePublicRouteDashboardConfig(requestBody, school.id);
    } catch (error) {
      console.log(error);
      setTripVehicle((pre) => !pre);
    }
  };

  const logo = school?.image?.url || '';

  const handleOpenSchoolSettings = () => {
    setOpenSchoolSetting(true);
  };

  // getting data from localhost
  const currentSessionId = localStorage.getItem('currentSessionId');

  //   this is Url
  const mainUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PROD_URL;
  const url = `${mainUrl}publicDashboard/${school.organization_admin_username}/${currentSessionId}/${school.school_id}?token=${school.token}`;

  return (
    <>
      <PublicRouteCard color={switchSchool ? 'primary.main' : 'secondary.main'} school={school}>
        <Stack>
          <SwitchWithText
            label={school.school_name}
            checked={switchSchool}
            onChange={() => handleToggleSchool(school)}
          />
          <IconWithText title='Embed URL' titleProps={{ variant: 'subtitle2' }} icon={LinkIcon} />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={5}>
          <CopyLinkAndViewLink
            disabled={!switchSchool}
            url={url}
            id={`public-route-dashboard-school-url${school.school_id}`}
          />

          <IconButton size='small' onClick={handleOpenSchoolSettings} disabled={!switchSchool}>
            <SettingsIcon fontSize='small' />
          </IconButton>
        </Stack>
      </PublicRouteCard>
      <SettingConfigPopup
        open={openSchoolSetting}
        handleClose={() => setOpenSchoolSetting(false)}
        schoolName={school.school_name}
        logo={logo}
        routeDetail={routeDetail}
        routeDriver={routeDriver}
        routeVehicle={routeVehicle}
        handleRouteDetail={handleRouteDetail}
        handleRouteDriver={handleRouteDriver}
        handleRouteVehicle={handleRouteVehicle}
        // trip
        tripDetail={tripDetail}
        tripDriver={tripDriver}
        tripVehicle={tripVehicle}
        handleTripDetail={handleTripDetail}
        handleTripDriver={handleTripDriver}
        handleTripVehicle={handleTripVehicle}
      />
    </>
  );
};
