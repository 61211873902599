
import React, { useState } from 'react';
//AWS
import { Auth } from 'aws-amplify';
//MUI
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//PROJECT Imports
import SignIn from './SignIn';
import SpotBusLogo from 'components/SpotBusLogo';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Verification from './components/Verification';
import SpotbusPin from 'components/SpotbusPin';
import SignUpConfirmation from './components/SignUpConfirmation';
import { getGuidedWorkflow } from 'clients/SpotBus';
import ChangePassword from 'containers/layouts/Authv2/ChangePassword';

//ERROR MESSAGES
const ERR_MESSAGES = {
  UsernameExistsException: 'User already registered with the given email address.',
  InvalidParameterException: 'Please correct invalid fields in form and try again.',
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function Authentication({ handleGuidedWorkflowDataUpdate }) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [authPage, setAuthPage] = useState('logIn');
  const [userDetails, setUserDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [signUpConfigPage, setSignUpConfigPage] = useState(false);
  const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
  const [userNameForgot, setUserNameForgot] = useState('');
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  //SIGN IN
  const signIn = async (username, password) => {
    setLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      setUserInfo(user);
      console.log(`Sign In authentication`, user);
      localStorage.setItem('spotbus-username', user.username);
      setUserDetails(user.username);
      if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoading(false);
        setAuthPage('newPasswordRequired');
        return;
      }

      let res = await getGuidedWorkflow();
      handleGuidedWorkflowDataUpdate(res);
      setSuccessMessage('Welcome back,' + user?.attributes.family_name);
      setOpenSuccess(true);
      setLoading(false);
    } catch (error) {
      if (error.message === 'User is not confirmed.') {
        console.log('error signing in', error);
        setAuthPage('signUp');
        resendSignUpOTP(username);
        setSignUpConfigPage(true);
        setUserDetails(username);
      }
      setLoading(false);
      setErrorMessage(error);
      setOpen(true);
    }
  };

  //SIGNUP
  const signUp = async (values) => {
    setLoading(true);
    const username = values.emailUser;
    const password = values.password;
    const signUpDetails = {
      username,
      password,
      attributes: {
        email: values.emailUser,
        name: values.emailUser,
        phone_number: values.phone_country_code.phone + values.phone,
        given_name: values.firstName,
        family_name: values.lastName,
        gender: values.gender,
        picture: '',
        birthdate: '',
        address: values.address,
        'custom:user_type': 'superAdmin',
      },
      autoSignIn: {
        enabled: true,
      },
    };
    try {
      const { user } = await Auth.signUp(signUpDetails);

      setUserDetails(user?.username);
      setSignUpConfigPage(true);
      setSuccessMessage('OTP send successfully');
      setOpenSuccess(true);
      setLoading(false);
    } catch (error) {
      console.log('error signing up:', error);
      setErrorMessage(error);
      setLoading(false);
      setOpen(true);
    }
  };

  const confirmSignUp = async (code) => {
    setLoading(true);
    try {
      await Auth.confirmSignUp(userDetails, code);
      setSuccessMessage('User created successfully');
      setOpenSuccess(true);
      setLoading(false);
      let res = await getGuidedWorkflow();
      handleGuidedWorkflowDataUpdate(res);
    } catch (error) {
      console.log('error confirming sign up', error);
      setErrorMessage(error);
      setLoading(false);
      setOpen(true);
    }
  };

  const resendSignUpOTP = async (username) => {
    try {
      await Auth.resendSignUp(username);
      setSuccessMessage('OTP send successfully');
      setOpenSuccess(true);
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  //FORGOT PASSWORD

  const forgotPassword = async (username) => {
    setUserNameForgot(username);
    try {
      const data = await Auth.forgotPassword(username);
      setForgotPasswordPage(true);
      setSuccessMessage('OTP send successfully');
      setOpenSuccess(true);
    } catch (err) {
      console.log(err);
      setErrorMessage(err);
      setLoading(false);
      setOpen(true);
    }
  };

  const forgotPasswordSubmit = async (code, newPassword) => {
    setLoading(true);
    try {
      const data = await Auth.forgotPasswordSubmit(userNameForgot, code, newPassword);
      setSuccessMessage('Password changed successfully');
      setOpenSuccess(true);
      setAuthPage('logIn');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setErrorMessage(err);
      setLoading(false);
      setOpen(true);
    }
  };

  const handleResetPassword = async (newPassword) => {
    setLoading(true);
    try {
      await Auth.completeNewPassword(userInfo, newPassword);
      await signIn(userDetails, newPassword);
      setSuccessMessage('Reset password successfully');
      setOpenSuccess(true);
    } catch (e) {
      console.error(e);
      setErrorMessage(e);
      setOpen(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpen(false);
  };

  const getAuthPage = () => {
    switch (authPage) {
      case 'logIn':
        return <SignIn setAuthPage={setAuthPage} signIn={signIn} loading={loading} />;
      case 'signUp':
        return signUpConfigPage ? (
          <SignUpConfirmation
            setAuthPage={setAuthPage}
            confirmSignUp={confirmSignUp}
            loading={loading}
            resendSignUpOTP={() => resendSignUpOTP(userDetails)}
          />
        ) : (
          <SignUp setAuthPage={setAuthPage} signUp={signUp} loading={loading} />
        );
      case 'forgotPassword':
        return forgotPasswordPage ? (
          <Verification
            setAuthPage={setAuthPage}
            forgotPasswordSubmit={forgotPasswordSubmit}
            loading={loading}
            resendOtp={() => forgotPassword(userNameForgot)}
          />
        ) : (
          <ForgotPassword
            setAuthPage={setAuthPage}
            forgotPassword={forgotPassword}
            loading={loading}
          />
        );
      case 'newPasswordRequired':
        return (
          <ChangePassword
            setAuthPage={setAuthPage}
            handleResetPassword={handleResetPassword}
            loading={loading}
          />
        );
    }
  };

  return (
    <Grid xs={12} container justifyContent='center' alignItems='center' sx={{ minHeight: '100vh' }}>
      {getAuthPage()}

      <SpotBusLogo width={150} style={{ position: 'fixed', top: 22, left: 22 }} />
      <div
        style={{
          position: 'fixed',
          top: 90,
          right: -250,
          filter: 'blur(9px)', // Adjust the blur radius as needed
          zIndex: -1,
        }}
      >
        <SpotbusPin width={550} />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage.code === 'UsernameExistsException'
            ? ERR_MESSAGES[errorMessage.code]
            : errorMessage.message}
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default Authentication;
