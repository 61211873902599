import { Stack, Typography } from '@mui/material';
import IconWithText from 'components/IconWithText';

export default function TripColumns({ iconname, title, detail }) {
  return (
    <Stack spacing={2}>
      <IconWithText
        title={title}
        titleProps={{ variant: 'h5' }}
        icon={iconname}
        iconProps={{ color: 'secondary', fontSize: 'small' }}
      />
      <Typography sx={{ pl: 6 }} color={'text'}>
        {detail}
      </Typography>
    </Stack>
  );
}
