import React, { useState, useEffect } from 'react';
import QuickDetailsOfRoutesandTrips from './QuickDetailsOfRoutesandTrips';
import {
  getUnAuthoriseRoutes,
  getUnAuthoriseRoutesDetails,
  getUnAuthoriseSettingsDetails,
  getUnAuthoriseRouteTrips,
  getUnAuthorizedSchoolDetails,
  getPublicSchool,
} from 'clients/unauthorised';
import PublicDashboardErrorScreen from './PublicDashboardErrorScreen';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Progress from 'components/ProgressBar/Progress';

export default function UnauthorizedDashboard(props) {
  const [routeData, setRouteData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataTripLoading, setDataTripLoading] = useState(false);
  const [tripData, setTripData] = useState();
  const [routeTrip, setRouteTrip] = useState([]);
  const [settingData, setSettingData] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [refreshPage, setRefreshPage] = useState(1);
  const [schoolData, setSchoolData] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const [districtLevel, setdistrictLevel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [listDetails, setListDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [selectedTripRowData, setSelectedTripRowData] = useState(false);
  const [runOnce, setRunOnce] = useState(false);
  const [tripDate, setTripDate] = useState();

  const formattedDate = selectedDate.toLocaleDateString('en', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  const userId = window.location.pathname.split('/')[2];
  const sessionId = window.location.pathname.split('/')[3];

  const checkDistrictLevelOrSchoolLevel = window.location.pathname.split('/')[4];
  const type = window.location.pathname.split('/')[5];
  const routeTripId = window.location.pathname.split('/')[6];

  const url = window.location.href;

  const parsedUrl = new URL(url);

  // Get the value of the "token" query parameter
  const token = parsedUrl.searchParams.get('token');
  const date = parsedUrl.searchParams.get('date');

  let district = '';
  let schoolId = '';
  if (checkDistrictLevelOrSchoolLevel === 'district') {
    district = 'district';
  } else {
    schoolId = checkDistrictLevelOrSchoolLevel;
  }

  const handleSettings = async (schoolId, token) => {
    try {
      let response = await getUnAuthoriseSettingsDetails(userId, sessionId, token);
      setSettingData(response.data);
      if (schoolId !== '') {
        getRouteListAndTripList(schoolId, response.data);
      }
    } catch (error) {
      console.log('Get error: ', error);
      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  const getRouteListAndTripList = (schoolId, settingData) => {
    if (settingData?.settings.route_settings?.enabled === true) {
      handleRouteList(schoolId, userId, sessionId);
    }
    if (
      parsedUrl.searchParams.get('date') !== null &&
      settingData?.settings.route_trips_settings?.enabled === true
    ) {
      handleRouteTripsList(schoolId, date, userId, sessionId);
    } else if (settingData?.settings.route_trips_settings?.enabled === true) {
      handleRouteTripsList(schoolId, formattedDate, userId, sessionId);
    }
  };

  const handleRouteList = async (schoolId, userId, sessionId) => {
    if (type === 'routes') {
      setListDetails(true);
    }
    setDataLoading(true);
    try {
      let response = await getUnAuthoriseRoutes(schoolId, userId, sessionId);
      setRouteData(response.data);
      if (type === 'routes') {
        let selectedRoute = response.data.find((route) => route.route_id === routeTripId);

        setSelectedRowData(selectedRoute);
        handleRouteDetails(routeTripId, sessionId);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      console.log('Get list error: ', error);
      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  const handleRouteDetails = async (routeId, sessionId) => {
    setDataLoading(true);
    setTripData();
    try {
      let response = await getUnAuthoriseRoutesDetails(routeId, sessionId);
      setTripData(response);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.log('Get list error: ', error);
      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  const handleRouteTripsList = async (schoolId, date, userId, sessionId) => {
    if (type === 'trips') {
      setListDetails(true);
    }
    setDataTripLoading(true);
    setTripDate(date);
    try {
      let response = await getUnAuthoriseRouteTrips(schoolId, date, userId, sessionId);
      setRouteTrip(response.data);

      if (type === 'trips') {
        let selectedRoute = response.data.find((route) => route.route_id === routeTripId);
        setSelectedRowData(selectedRoute);
        setSelectedTripRowData(true);
      }

      setDataTripLoading(false);
    } catch (error) {
      console.log('Get error: ', error);
      setDataTripLoading(false);

      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  const getSchoolList = async () => {
    setLoading(true);
    setdistrictLevel(checkDistrictLevelOrSchoolLevel === 'district' ? true : false);
    try {
      const response = await getUnAuthorizedSchoolDetails(userId, sessionId, token);
      setSchoolData(response.data);
      handleSettings(schoolId, token);
      setLoading(false);
      if (checkDistrictLevelOrSchoolLevel !== 'district') {
        let filteredData = response.data?.filter((school) => school.school_id === schoolId);
        setSelectedSchool(filteredData[0]);
      }
    } catch (error) {
      setLoading(false);
      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  const getSchool = async () => {
    try {
      const response = await getPublicSchool(schoolId, userId, sessionId);
      setSelectedSchool(response.data);
      handleSettings(schoolId, token);
    } catch (error) {
      setApiError(true);
      setApiErrorMessage(error.message);
      setTimeout(() => {
        setApiError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if (checkDistrictLevelOrSchoolLevel === 'district') {
      getSchoolList();
    } else {
      getSchool();
    }
    // return closeSnackbar();
  }, []);

  const handleUrl = (type, routeID) => {
    let updatedURL = window.location.pathname;

    if (type === 'routes') {
      const newURL = `/${type}/${routeID}?token=${token}`;
      window.history.pushState({}, '', updatedURL + newURL);
    }
    if (type === 'trips') {
      const newURL = `/${type}/${routeID}?token=${token}&date=${tripDate}`;
      window.history.pushState({}, '', updatedURL + newURL);
    }
  };

  const handleUrlBack = () => {
    setListDetails(false);
    let updatedURL;
    if (window.location.pathname.split(type)[0]) {
      let a = window.location.pathname.split(type)[0];
      if (a.charAt(a.length - 1) === '/') {
        a = a.slice(0, -1);
      }
      updatedURL = a;
    } else if (window.location.pathname.split('?')[0]) {
      updatedURL = window.location.pathname.split('?')[0];
    }

    const newURL = `?token=${token}`;
    window.history.pushState({}, '', updatedURL + newURL);
  };

  useEffect(() => {
    if (settingData?.settings.route_trips_settings.enabled === true) {
      handleRouteTripsList(selectedSchool.school_id, formattedDate, userId, sessionId);
    }
  }, [selectedDate, refreshPage]);

  const handleOnClickSchool = (selectedSchool, settingData) => {
    setSelectedSchool(selectedSchool);
    getRouteListAndTripList(selectedSchool.school_id, settingData);
    setdistrictLevel(false);
  };

  return (
    <>
      {!Boolean(checkDistrictLevelOrSchoolLevel) ||
      (settingData?.settings.route_settings.enabled === false &&
        settingData?.settings.route_trips_settings.enabled === false) ? (
        <>
          <Snackbar open={apiError} autoHideDuration={5000}>
            <Alert variant='filled' severity='error'>
              {apiErrorMessage ? apiErrorMessage : apiErrorMessage}
            </Alert>
          </Snackbar>
          <PublicDashboardErrorScreen />
        </>
      ) : (
        <>
          <Snackbar open={apiError} autoHideDuration={5000}>
            <Alert variant='filled' severity='error'>
              {apiErrorMessage ? apiErrorMessage : apiErrorMessage}
            </Alert>
          </Snackbar>
          {!(dataLoading && dataTripLoading) ? (
            <QuickDetailsOfRoutesandTrips
              routeData={routeData}
              handleRouteDetails={handleRouteDetails}
              tripData={tripData?.data}
              dataLoading={dataLoading}
              settingData={settingData}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              routeTrip={routeTrip}
              dataTripLoading={dataTripLoading}
              setRefreshPage={setRefreshPage}
              schoolData={schoolData}
              districtUrl={district}
              handleOnClickSchool={handleOnClickSchool}
              selectedSchool={selectedSchool}
              checkDistrictLevelOrSchoolLevel={checkDistrictLevelOrSchoolLevel}
              setSelectedSchool={setSelectedSchool}
              districtLevel={districtLevel}
              setdistrictLevel={setdistrictLevel}
              loading={loading}
              listDetails={listDetails}
              setListDetails={setListDetails}
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
              selectedTripRowData={selectedTripRowData}
              setSelectedTripRowData={setSelectedTripRowData}
              runOnce={runOnce}
              setRunOnce={setRunOnce}
              handleUrl={handleUrl}
              handleUrlBack={handleUrlBack}
            />
          ) : (
            <Progress />
          )}
        </>
      )}
    </>
  );
}
