import React from 'react';

// material ui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// assets
import CloseIcon from '@mui/icons-material/Close';

export default function DialogLicenseFront(props) {
  const theme = useTheme();
  return (
    <div>
      <Dialog
        open={props.openFrontDialog}
        onClose={props.closeFrontDialog}
        aria-labelledby='form-dialog-title'
        fullWidth='md'
        maxWidth='md'
        style={{ marginTop: 40 }}
      >
        <Grid container xs={12}>
          <Grid container justifyContent='flex-start' xs={6}>
            <DialogTitle
              style={{ textAlign: 'center', color: theme.palette.primary.main }}
              id='form-dialog-title'
            >
              {props.title}
            </DialogTitle>
          </Grid>
          <Grid container justifyContent='flex-end' xs={6}>
            <IconButton aria-label='close' onClick={props.closeFrontDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container alignItems='center' justifyContent='center' xs={12}>
            <img
              style={{ width: '100%' }}
              src={props.image}
              key={props.image}
            ></img>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}