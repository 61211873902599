import React from 'react';

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

// Custom Component
import ActionButton from 'components/Buttons/ActionButton';

const useStyles = makeStyles((theme) => ({
  delete: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
}))(MuiDialogActions);

export default function AbortAlert(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setClose();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        // style={{ zIndex: 2 }}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Attention!
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity='error'>
            <AlertTitle>
              <strong>Do you want to Abort this process?</strong>
            </AlertTitle>
            Once Aborted, this action cannot be reversed.
          </Alert>
        </DialogContent>
        <DialogActions>
          <ActionButton
            loaderButton={true}
            color='error'
            startIcon={<WarningIcon />}
            onClick={() => props.handleAbortProcess()}
            isLoading={props.abortbutton}
          >
            ABORT
          </ActionButton>
          {/* Code Depricated */}
          {/* <Button
            className={classes.delete}
            autoFocus
            variant='contained'
            onClick={() => props.handleAbortProcess()}
            startIcon={<WarningIcon />}
          >
            Abort
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
