import React from 'react';
import PropTypes from 'prop-types';

//Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function TabPanel(props) {
  const { children, value, index, minHeight, boxPaddingTop, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: boxPaddingTop, minHeight: minHeight }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//Default props
TabPanel.defaultProps = {
  minHeight: 250,
  boxPaddingTop: 3,
};

//Props types
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
};

export default TabPanel;

//Readme

//minHeight: this props is used for box minimum height
//index: Index is used for indexing of div
//value: Value of the current tab
