import React from 'react';
import { Typography } from '@mui/material';

export function Heading({ title, color = 'text' }) {
  return (
    <Typography color={color} sx={{ fontSize: 30, fontWeight: 600 }} gutterBottom>
      {title}
    </Typography>
  );
}
