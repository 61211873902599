import React from 'react';

// Material UI
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckBoxFormik = ({ field, form, label = '', ...props }) => {
  const { setFieldValue } = form;
  const { name, value } = field;

  const handleChange = (event) => {
    const { checked } = event.target;
    setFieldValue(name, checked);
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox {...field} {...props} checked={value} onChange={handleChange} />}
        sx={{
          m: 0,
          p: 0,
        }}
        label={label}
      />
    </>
  );
};

export default CheckBoxFormik;
