export default class TimeUtils {
  /**
   * Get current time in ISO 8601 format.
   * @returns {string} Current time in ISO 8601 format.
   */
  static getCurrentTimeISO() {
    return new Date().toISOString();
  }

  /**
   * Format time input in seconds.
   * @param {number} epochTimestamp - Time input in seconds.
   * @param is24HourClock - Clock type
   * @param {string} format - Format of the time (either 'hh:mm' or 'hh:mm:ss').
   * @returns {string} Formatted time string.
   */
  static formatSecondsToTime(epochTimestamp, is24HourClock = false, format = 'hh:mm') {
    // Call parseEpochTimestamp method of the same class
    const timestamp = this.parseEpochTimestamp(epochTimestamp);

    // If timestamp is null or undefined, return an empty string
    if (timestamp === null || timestamp === undefined) {
      return '--';
    }

    // Converting seconds into milliseconds and creating a new Date object
    const date = new Date(timestamp * 1000);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    let timeString = '';

    if (is24HourClock) {
      timeString = `${this.pad(hours)}:${this.pad(minutes)}`;
      if (format === 'hh:mm:ss') {
        timeString += `:${this.pad(seconds)}`;
      }
    } else {
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours || 12; // 12-hour clock

      timeString = `${this.pad(hours)}:${this.pad(minutes)}`;
      if (format === 'hh:mm:ss') {
        timeString += `:${this.pad(seconds)}`;
      }

      timeString += ` ${ampm}`;
    }

    return timeString;
  }

  /**
   * Pad single digit numbers with leading zero.
   * @param {number} num - Number to pad.
   * @returns {string} Padded number as string.
   */
  static pad(num) {
    return num < 10 ? `0${num}` : num;
  }

  static parseEpochTimestamp(timestamp) {
    /**
     * Convert the timestamp to a number if it's not already
     */

    const timestampNumber = Number(timestamp);

    // If the timestamp is NaN or not a finite number, return null
    if (isNaN(timestampNumber) || !isFinite(timestampNumber)) {
      return null;
    }

    // Parse the timestamp into an integer
    return parseInt(timestampNumber, 10);
  }

  static formatTimeFromString(timeString, is24HourClock = false, format = 'hh:mm') {
    let [hours, minutes, seconds] = timeString.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return ''; // Invalid time format
    }

    let timeStringFormatted = '';

    if (is24HourClock) {
      timeStringFormatted = `${this.pad(hours)}:${this.pad(minutes)}`;
      if (format === 'hh:mm:ss') {
        timeStringFormatted += `:${this.pad(seconds)}`;
      }
    } else {
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours || 12; // 12-hour clock

      timeStringFormatted = `${this.pad(hours)}:${this.pad(minutes)}`;
      if (format === 'hh:mm:ss') {
        timeStringFormatted += `:${this.pad(seconds)}`;
      }

      timeStringFormatted += ` ${ampm}`;
    }

    return timeStringFormatted;
  }

  static convertTo12HourFormat(timestamp) {
    if (!timestamp) {
      return '--';
    }
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0'); // Pad single digit minutes with leading zero
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${period}`;
  }

  static calculateTimeDifference(firstTimestamp, secondTimestamp) {
    // Timestamp 1
    const timestamp1 = new Date(firstTimestamp);

    // Timestamp 2 (current time)
    const timestamp2 = new Date(secondTimestamp);

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = timestamp2 - timestamp1;

    // Convert milliseconds to seconds
    const timeDifferenceSec = Math.floor(timeDifferenceMs / 1000);

    // Calculate minutes and seconds
    const minutes = Math.floor(timeDifferenceSec / 60);
    const seconds = timeDifferenceSec % 60;

    return `${minutes}:${seconds}`;
  }

  // convert  "08:00:00" to 2024-04-08T07:15:00.000000Z
  static convertToISO8601(timeString) {
    if (!Boolean(timeString)) {
      return '--';
    }
    // Split the time string into hours, minutes, and seconds
    var timeComponents = timeString.split(':');

    // Create a Date object with today's date and the provided time
    var dateObj = new Date();
    dateObj.setHours(parseInt(timeComponents[0], 10)); // Hours
    dateObj.setMinutes(parseInt(timeComponents[1], 10)); // Minutes
    dateObj.setSeconds(parseInt(timeComponents[2], 10)); // Seconds

    // Convert the date object to ISO 8601 format
    var isoString =
      dateObj.toISOString().split('T')[0] + 'T' + dateObj.toTimeString().split(' ')[0];

    return isoString;
  }

  // convert  "08:00:00" to 08:00 AM
  static convert24HoursTo12HourFormat(timeString) {
    if (!Boolean(timeString)) {
      return '--';
    }

    // Split the time string into hours, minutes, and seconds
    var timeComponents = timeString.split(':');

    // Extract hours, minutes, and seconds
    var hours = parseInt(timeComponents[0], 10);
    var minutes = parseInt(timeComponents[1], 10);

    // Determine if it's AM or PM
    var amOrPm = hours >= 12 ? 'pm' : 'am';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Construct the formatted time string
    var formattedTime =
      hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + amOrPm;

    return formattedTime;
  }

  // convert  Apr 08 2024 16:15:00 GMT+0530 (India Standard Time) to  16:15:00
  static convertStringFormatToISOformatTime(dateString) {
    if (!Boolean(dateString)) {
      return '--';
    }

    // Parse the date string
    var dateObj = new Date(dateString);

    // Extract hours, minutes, and seconds
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var seconds = dateObj.getSeconds();

    // Format hours, minutes, and seconds to ensure they have two digits
    var formattedHours = hours.toString().padStart(2, '0');
    var formattedMinutes = minutes.toString().padStart(2, '0');
    var formattedSeconds = seconds.toString().padStart(2, '0');

    // Construct the time string
    var timeString = formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;

    return timeString;
  }
}
