import React from 'react';

// material ui
import { Grid, Button, TextField, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';

// assets
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddIcon from '@mui/icons-material/Add';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import DriverGoogleMap from '../Maps/DriverGoogleMap';
import RoomIcon from '@mui/icons-material/Room';
import MyLocationIcon from '@mui/icons-material/MyLocation';

//Helper function
import Validations from 'containers/layouts/Utils/Validations';

// Custom Component
import BackButton from 'components/Buttons/BackButton';
import NextButton from 'components/Buttons/NextButton';

// const validationSchema = yup.object({
//   str eet: yup.string().trim().required('Street is required*'),
//   city: yup.string().trim().required('City is required*'),
//   state: yup.string().trim().required('State is required*'),
//   zipCode: yup.number().typeError('Enter only numbers').min(2).required('Zip Code is required*'),
//   aptPlot: yup.string().trim().min(2).required('Apt plot is required*'),
// });
const minAddressChar = /[a-zA-Z]{2}/;
const minAlphaNum = /[a-zA-Z0-9]{1}/;

const validationSchema = yup.object({
  street: yup
    .string()
    .trim()
    .min(2)
    .max(50)
    .matches(minAddressChar, 'Street Name must be at least 2 characters without white space'),

  city: yup
    .string()
    .trim()
    .min(2)
    .max(50)
    .matches(minAddressChar, 'City Name must be at least 2 characters without white space'),

  state: yup
    .string()
    .trim()
    .min(2)
    .max(50)
    .matches(minAddressChar, 'State Name must be at least 2 characters without white space'),

  country: yup
    .string()
    .trim()
    .min(2)
    .max(50)
    .matches(minAddressChar, 'Country Name must be at least 2 characters without white space'),

  zip_code: yup.number().typeError('Enter only numbers'),

  apt_plot: yup
    .string()
    .trim()
    .min(1)
    .max(50)
    .matches(minAlphaNum, 'APT / Plot is required'),
});

export default function DriverAddressForm(props) {
  const classes = useStyles();

  const [validation, setValidation] = React.useState(false);
  const [showGeoLocationField, setShowGeoLocationField] = React.useState(false);

  const bccClickHandler = () => {
    setShowGeoLocationField((prevValue) => !prevValue);
  };

  const handleBack = () => {
    props.setActiveStep((pre) => pre - 1);
  };

  const formik = useFormik({
    initialValues: {
      city: props.city ? props.city : '',
      street: props.street ? props.street : '',
      state: props.state ? props.state : '',
      zipCode: props.zipCode ? props.zipCode : '',
      aptPlot: props.aptPlot ? props.aptPlot : '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setValidation(true);
      // if (props.latitude !== '' && props.longitude !== '') {
      console.log('FORMIK', values);
      props.setStreet(formik.values.street);
      props.setCity(formik.values.city);
      props.setState(formik.values.state);
      props.setZipCode(formik.values.zipCode);
      props.setAptPlot(formik.values.aptPlot);
      props.setActiveStep((pre) => pre + 1);
      // }
    },
  });

  return (
    <Grid container xs={12}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container xs={12} sx={{ height: 510 }}>
          <Grid item xs={12} sx={{ minHeight: props.isFromGuided ? 495 : 0 }}>
            <Grid container direction='row' spacing={3}>
              <Grid container item xs={7} style={{ marginTop: '6%', marginBottom: '8%' }}>
                <Grid item xs={12}>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <TextField
                      autoFocus
                      fullWidth
                      id='street'
                      label='Street'
                      name='street'
                      variant='standard'
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      error={formik.touched.street && Boolean(formik.errors.street)}
                      helperText={
                        Boolean(formik.touched.street && formik.errors.street)
                          ? formik.touched.street && formik.errors.street
                          : 'Enter street name'
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RoomIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      id='city'
                      label='City'
                      name='city'
                      variant='standard'
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={
                        Boolean(formik.touched.city && formik.errors.city)
                          ? formik.touched.city && formik.errors.city
                          : 'Enter city name'
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RoomIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <TextField
                      fullWidth
                      id='state'
                      label='State'
                      name='state'
                      variant='standard'
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      error={formik.touched.state && Boolean(formik.errors.state)}
                      helperText={
                        Boolean(formik.touched.state && formik.errors.state)
                          ? formik.touched.state && formik.errors.state
                          : 'Enter state name'
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RoomIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      id='zipCode'
                      label='Zip Code'
                      name='zipCode'
                      variant='standard'
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                      helperText={
                        Boolean(formik.touched.zipCode && formik.errors.zipCode)
                          ? formik.touched.zipCode && formik.errors.zipCode
                          : 'Enter zipcode'
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RoomIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    <TextField
                      fullWidth
                      id='aptPlot'
                      label='Apt Plot'
                      name='aptPlot'
                      variant='standard'
                      value={formik.values.aptPlot}
                      onChange={formik.handleChange}
                      // error={formik.touched.aptPlot && Boolean(formik.errors.aptPlot)}
                      helperText={'Enter apt plot name'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RoomIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  {showGeoLocationField ? (
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                      <TextField
                        fullWidth
                        id='latitude'
                        label='Latitude'
                        placeholder='Latitude'
                        name='latitude'
                        type='number'
                        variant='standard'
                        value={props.latitude}
                        onChange={(e) => props.setLatitude(e.target.value)}
                        error={validation && props.latitude?.length < 1}
                        helperText={
                          validation && props.latitude?.length < 1
                            ? '*Latitude is required (Enter only numbers)'
                            : 'Enter latitude '
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <MyLocationIcon color='secondary' />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        id='longitude'
                        placeholder='Longitude'
                        label='Longitude'
                        name='longitude'
                        type='number'
                        variant='standard'
                        value={props.longitude}
                        onChange={(e) => props.setLongitude(e.target.value)}
                        error={validation && props.longitude?.length < 1}
                        helperText={
                          validation && props.longitude?.length < 1
                            ? '*Longitude is required (Enter only numbers)'
                            : 'Enter longitude'
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <MyLocationIcon color='secondary' />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  ) : (
                    <Grid xs={12}>
                      <Button
                        variant='text'
                        startIcon={<MyLocationIcon />}
                        onClick={bccClickHandler}
                      >
                        {props.latitude && props.longitude
                          ? 'Edit geolocation manually'
                          : 'Add geolocation manually'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <DriverGoogleMap {...props} />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} container justifyContent='space-between' alignItems='flex-end'>
            <Grid item>
              <BackButton id='back_button' size='medium' onClick={handleBack}>
                BACK
              </BackButton>
            </Grid>
            <Grid item>
              <NextButton size='medium' type='submit' id='submit_address_form'>
                NEXT
              </NextButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  saveButton: {
    spacing: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
