import React from 'react';
import { StyledCalendar } from '../style/calender.style';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { DATE_OCCURRENCE } from '../formInitialValuesDriverShifts';
import LoopIcon from '@mui/icons-material/Loop';
import NotesIcon from '@mui/icons-material/Notes';
import { SpotBusDateTime } from 'utils/spotbus-date-time';
const ShiftCalender = ({ driverShifts = [], selectedDateForCalendar, handleEventClick }) => {
  const theme = useTheme();

  const driverShiftLocalTime = driverShifts.map((shift) => {
    const localStartTime = new SpotBusDateTime(shift.start).toLocalizeTimezone();
    const localEndTime = new SpotBusDateTime(shift.end).toLocalizeTimezone();
    return {
      ...shift,
      start: localStartTime,
      end: localEndTime,
    };
  });

  return (
    <MainCard>
      <StyledCalendar
        disabled={true}
        localizer={momentLocalizer(moment)}
        events={driverShiftLocalTime}
        theme={theme}
        startAccessor='start'
        endAccessor='end'
        defaultDate={selectedDateForCalendar}
        onSelectEvent={handleEventClick}
        timeFormat='HH:mm'
        style={{ height: '915px', fontSize: '18px', fontFamily: 'Roboto' }}
        views={['month', 'week', 'day']}
        components={{
          event: EventComponent,
        }}
      />
    </MainCard>
  );
};

export default ShiftCalender;

const EventComponent = ({ event }) => {
  const theme = useTheme();
  const isRecurrence =
    event.dateOccurrence === DATE_OCCURRENCE.WEEKLY ||
    event.dateOccurrence === DATE_OCCURRENCE.MONTHLY;

  return (
    <>
      <Box
        sx={{
          bgcolor: isRecurrence ? theme.palette.primary.main : theme.palette.primary.light,
          p: 1,
          // mb: 0.2,
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        border={1}
      >
        <Stack>
          <Typography whiteSpace={'pre-wrap'} color={isRecurrence || '#0f0f0f'}>
            {event.title}
          </Typography>
        </Stack>
        <Stack>
          {isRecurrence ? <LoopIcon fontSize='small' /> : <NotesIcon fontSize='small' />}
        </Stack>
      </Box>
    </>
  );
};
