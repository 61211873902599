import { useQuery } from '@tanstack/react-query';
import { getAllDashboardUsers } from 'clients/SpotBus';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import { useSnackbar } from 'notistack';
import { queryKey } from 'react-query/queryKey';

/**
 * Fetches dashboard users data and handles errors using useQuery hook.
 *
 * @return {Object} Object containing allUserData and isLoadingUsers
 *
 * @example
 * const { allUserData, isLoadingUsers } = useDashboardUsers();
 */
export const useDashboardUsers = () => {
  const username = localStorage.getItem('spotbus-username') || '';
  const { enqueueSnackbar } = useSnackbar();
  const _queryKey = [queryKey.DASHBOARD_USER.ALL_DASHBOARD_USER];

  // Use useQuery hook to fetch data and handle errors
  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: _queryKey,
    queryFn: () => getAllDashboardUsers(username),
    onError: (error) => {
      // Handle errors and display a snack bar notification
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    },
  });

  // Return the necessary data or functions
  return {
    users,
    isLoadingUsers,
  };
};
