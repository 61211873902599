import { countries } from './countries';
import { hexToRgbA } from './hexToRgba';
import { stringAvatar } from './letterAvatar';
import { convertNestedListToFloats } from './mapHelpers';
import { oneLineAddress } from './address';
import { languages } from './languages';

// Default selected country
const defaultCountry = () => countries.find((country) => country.default);

// Default selected country
const defaultLanguage = () => languages.find((language) => language.default);

// Utility method that returns if the provided URL is valid
function isValidHttpUrl(string_url) {
  let resultURL;
  try {
    resultURL = new resultURL(string_url);
  } catch (_) {
    return false;
  }
  return resultURL.protocol === 'http:' || resultURL.protocol === 'https:';
}

export {
  convertNestedListToFloats,
  countries,
  defaultCountry,
  hexToRgbA,
  isValidHttpUrl,
  oneLineAddress,
  stringAvatar,
  languages,
  defaultLanguage,
};
