import * as Yup from 'yup';
import { isSecondDateGreaterThanFirst, isValidDate, isValidYear } from '../Utils';

export const validationSchemaSessionForm = () => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3, 'Minimum 3 character is required')
      .max(25, 'Session name must be 25 characters or less')
      .required('Session name is required')
      .test('not-only-whitespace', 'Session name cannot be only blank spaces', function(value) {
        return /\S/.test(value);
      }),
    desc: Yup.string()
      .min(10, 'Please enter at least 10 characters')
      .max(256, 'Description must be 256 characters or less'),
  });
};

export const validationSchemaForSchoolSchedule = () => {
  return Yup.object().shape({
    start_date: Yup.date()
      .typeError('Start date must be a valid date')
      .required('Start date is required')
      .test('is-later-than-start', 'End date must be after start date', function(start_date) {
        return isValidDate(start_date) && isValidYear(start_date);
      }),
    end_date: Yup.date()
      .typeError('End date must be a valid date')
      .required('End date is required')
      .test('is-later-than-start', 'End date must be after start date', function(end_date) {
        const schoolFormEndDate = this.resolve(Yup.ref('start_date'));
        return (
          isValidDate(end_date) &&
          isSecondDateGreaterThanFirst(schoolFormEndDate, end_date) &&
          isValidYear(end_date)
        );
      }),
    days_of_week: Yup.array()
      .required('Days of week are required')
      .test('has-true-value', 'At least one day should be selected', function(value) {
        return Array.isArray(value) && value.some((day) => day === true);
      }),
    holidays: Yup.array()
      .required('Holidays are required')
      .test('has-objects', 'Minimum one holiday is required', function(value) {
        return Array.isArray(value) && value.length > 0;
      }),
    vacations: Yup.array()
      .required('Vacations are required')
      .test('has-objects', 'Minimum one vacation is required', function(value) {
        return Array.isArray(value) && value.length > 0;
      }),
  });
};

export const validationSchemaForHolidays = () => {
  return Yup.object().shape({
    hname: Yup.string()
      .trim()
      .min(3, 'Minimum 3 character is required')
      .max(25, 'Holiday name must be 25 characters or less')
      .required('Holiday name is required')
      .test('not-only-whitespace', 'Holiday name cannot be only blank spaces', function(value) {
        return /\S/.test(value);
      }),
    hdate: Yup.date()
      .typeError('Date must be a valid date')
      .required('Date is required')
      .test('is-later-than-start', 'Date must be a valid date', function(hdate) {
        return isValidDate(hdate) && isValidYear(hdate);
      }),
  });
};

export const validationSchemaForVacations = () => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3, 'Minimum 3 character is required')
      .max(25, 'Vacation name must be 25 characters or less')
      .required('Vacation name is required')
      .test('not-only-whitespace', 'Vacation name cannot be only blank spaces', function(value) {
        return /\S/.test(value);
      }),
    start_date: Yup.date()
      .typeError('Start date must be a valid date')
      .required('Start date is required')
      .test('is-later-than-start', 'End date must be after start date', function(start_date) {
        return isValidDate(start_date) && isValidYear(start_date);
      }),
    end_date: Yup.date()
      .typeError('End date must be a valid date')
      .required('End date is required')
      .test('is-later-than-start', 'End date must be after start date', function(end_date) {
        const vacationStartTime = this.resolve(Yup.ref('start_date'));
        return (
          isValidDate(end_date) &&
          isSecondDateGreaterThanFirst(vacationStartTime, end_date) &&
          isValidYear(end_date)
        );
      }),
  });
};
