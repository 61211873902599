import React, { useState } from 'react';

// third-party imports
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';
import { useLocation } from 'react-router-dom';

// material-ui
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// assets
import InfoIcon from '@mui/icons-material/Info';

// ============================== Configuration Form - Sessions ============================== //

function ConfigurationForm({ formikProps }) {
  const location = useLocation();
  const edit = location.pathname.split('/').includes('edit');

  const isDisabled = formikProps.isSubmitting || edit;
  const isRouteImportDisabled = formikProps.values.auto_import_students;

  const handleChange = (event) => {
    formikProps.setFieldValue('auto_import_students', event.target.checked);
    if (!event.target.checked) {
      formikProps.setFieldValue('auto_promote_students_elementary', false);
      formikProps.setFieldValue('auto_promote_students_middle', false);
      formikProps.setFieldValue('auto_promote_students_high', false);
      formikProps.setFieldValue('auto_promote_leftover_students', false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Typography variant='h4' component='div' gutterBottom>
            Drivers import settings
          </Typography>
          <Typography variant='body2' component='div' gutterBottom>
            These settings suggests importing all the drivers in the ongoing active session. This
            can be useful for accessing and utilizing the functionalities provided by those drivers
            in the current environment.
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_import_drivers'
              disabled={isDisabled}
            />
            Import all the drivers from current active session
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Typography variant='h4' component='div' gutterBottom>
            Vehicles import settings
          </Typography>
          <Typography variant='body2' component='div' gutterBottom>
            The given settings will import all the vehicles that are currently active in the ongoing
            session, and along with that, it will also import their complete information. This can
            be helpful in managing and tracking vehicles in real-time.
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_import_vehicles'
              disabled={isDisabled}
            />
            Import all the vehicle from current active session
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Typography variant='h4' component='div' gutterBottom>
            Students import settings
          </Typography>
          <Typography variant='body2' component='div' gutterBottom>
            With the provided settings, all the students from the previous active session will be
            automatically imported and promoted to the next level. This convenient one-step solution
            takes care of all the necessary steps, saving you time and effort.
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              onChange={handleChange}
              type='checkbox'
              name='auto_import_students'
              disabled={isDisabled}
            />
            Import all the students from current active session
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_promote_students_elementary'
              disabled={isDisabled || !isRouteImportDisabled}
            />
            Auto Promote all students from 5th grade elementary to 6th grade middle school
            {!isRouteImportDisabled && (
              <Tooltip title='When students are imported, this setting becomes mandatory.'>
                <IconButton aria-label='delete' size='small' sx={{ padding: 0.5, ml: 1 }}>
                  <InfoIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_promote_students_middle'
              disabled={isDisabled || !isRouteImportDisabled}
            />
            Auto Promote all students from 8th grade middle to 9th grade high school
            {!isRouteImportDisabled && (
              <Tooltip title='When students are imported, this setting becomes mandatory.'>
                <IconButton aria-label='delete' size='small' sx={{ padding: 0.5, ml: 1 }}>
                  <InfoIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_promote_students_high'
              disabled={isDisabled || !isRouteImportDisabled}
            />
            Auto graduate all students from 12th grade high school
            {!isRouteImportDisabled && (
              <Tooltip title='When students are imported, this setting becomes mandatory.'>
                <IconButton aria-label='delete' size='small' sx={{ padding: 0.5, ml: 1 }}>
                  <InfoIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_promote_leftover_students'
              disabled={isDisabled || !isRouteImportDisabled}
            />
            Auto promote students for rest of the grades
            {!isRouteImportDisabled && (
              <Tooltip title='When students are imported, this setting becomes mandatory.'>
                <IconButton aria-label='delete' size='small' sx={{ padding: 0.5, ml: 1 }}>
                  <InfoIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Typography variant='h4' component='div' gutterBottom>
            Routes import settings
          </Typography>
          <Typography variant='body2' component='div' gutterBottom>
            By using the settings provided, you can effortlessly import and promote all your
            previous session routes to the next level. This streamlined process eliminates the need
            for any additional steps, making it a convenient and time-saving solution.
          </Typography>
          <Typography component='div' gutterBottom>
            <Field
              component={Checkbox}
              type='checkbox'
              name='auto_import_routes'
              disabled={isDisabled}
            />
            Import all the routes from current active session
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

ConfigurationForm.propTypes = {};

export default ConfigurationForm;
