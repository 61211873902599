import React from 'react';

// material ui imports
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, TextField, InputAdornment, Chip } from '@mui/material';
import { SubHeading } from 'components/Headers/SubHeading';

// assets
import PersonIcon from '@mui/icons-material/Person';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
  gender: {
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    marginTop: 8,
  },
}));

export default function UserInformation(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={4} rowSpacing={1}>
        <Grid item xs={12}>
          <SubHeading subTitle={'Basic Information'} color={'primary'} />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus
            variant='standard'
            id='first_name'
            name='first_name'
            label='First name*'
            fullWidth
            value={props.firstName}
            onChange={(event) => {
              props.setFirstName(props.firstName ? event.target.value : event.target.value.trim());
            }}
            error={props.proceed && !props.firstName}
            helperText={'First name required'}
            disabled={props.isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonIcon color='secondary' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='last_name'
            variant='standard'
            name='last_name'
            label='Last name*'
            fullWidth
            value={props.lastName}
            onChange={(event) => {
              props.setLastName(props.lastName ? event.target.value : event.target.value.trim());
            }}
            error={props.proceed && !props.lastName}
            helperText={'Last name required'}
            disabled={props.isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonIcon color='secondary' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='email'
            variant='standard'
            name='email'
            label='Email*'
            fullWidth
            autoComplete='email'
            value={props.email}
            onChange={(event) => {
              props.setEmail(props.email ? event.target.value : event.target.value.trim());
            }}
            error={props.proceed && !props.email}
            helperText={'Email  required'}
            disabled={props.isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon color='secondary' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='phone_number'
            variant='standard'
            name='phone_number'
            label='Phone Number*'
            fullWidth
            autoComplete='mobile'
            value={props.phoneNumber}
            onChange={(event) => {
              props.setPhoneNumber(
                props.phoneNumber ? event.target.value : event.target.value.trim(),
              );
            }}
            error={props.proceed && !props.phoneRegExp.test(props.phoneNumber)}
            helperText={'Phone number required'}
            disabled={props.isEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PhoneIcon color='secondary' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <div className={classes.gender}>
            <Typography color='#808080'>Gender</Typography>
            <Chip
              id='male'
              label='Male'
              onClick={() => !props.isEdit && props.setGender('male')}
              variant={props.gender.toLowerCase() === 'male' ? 'default' : 'outlined'}
              color={props.gender.toLowerCase() === 'male' ? 'primary' : 'default'}
              icon={<ManIcon color='secondary' />}
            />
            <Chip
              id='female'
              label='Female'
              onClick={() => !props.isEdit && props.setGender('female')}
              variant={props.gender.toLowerCase() === 'female' ? 'default' : 'outlined'}
              color={props.gender.toLowerCase() === 'female' ? 'primary' : 'default'}
              icon={<WomanIcon color='secondary' />}
            />
            <Chip
              id='third'
              label='Not Specific'
              onClick={() => !props.isEdit && props.setGender('na')}
              variant={props.gender.toLowerCase() === 'na' ? 'default' : 'outlined'}
              color={props.gender.toLowerCase() === 'na' ? 'primary' : 'default'}
              icon={<PersonIcon color='secondary' />}
            />
          </div>
          {!props.gender && props.proceed && <p style={{ color: 'red' }}>* Gender Required</p>}
        </Grid>
      </Grid>
    </>
  );
}
