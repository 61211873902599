import React from 'react';
import PropTypes from 'prop-types';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { useTheme } from '@mui/material/styles';

const LeafIcon = L.Icon.extend({
  options: {
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]
  },
});

const homeMarkerIcon = new LeafIcon({
  iconUrl: '/static/images/icons/homeIcon.png',
  iconSize: [35, 35],
});

const schoolMarkerIcon = new LeafIcon({
  iconUrl: '/static/images/icons/School - size 2.png',
  iconSize: [28, 28],
});

const busBreakdownMarkerIcon = new LeafIcon({
  iconUrl: '/static/images/icons/sb-bus-bk-icon.png',
  iconSize: [40, 40],
});

const stopMarkerIcon = new LeafIcon({
  iconUrl: '/static/images/icons/MapPin.png',
  iconSize: [32, 32],
});

function MapMarker(iconName) {
  switch (iconName) {
    case 'home':
      return homeMarkerIcon;
    case 'school':
      return schoolMarkerIcon;
    case 'breakdown':
      return busBreakdownMarkerIcon;
    default:
      return stopMarkerIcon;
  }
}

export default function OpenStreetMap({ height, markers, isLoading, zoom }) {
  const theme = useTheme();
  const position = [
    markers[0]?.geoLocation ? Number(markers[0].geoLocation?.latitude) : 48.15091896593926,
    markers[0]?.geoLocation ? Number(markers[0].geoLocation?.longitude) : -122.20859547801348,
  ];

  function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
  }

  return (
    <>
      <MapContainer
        center={position}
        zoom={isLoading ? 10 : zoom}
        scrollWheelZoom={false}
        zoomControl={false}
        style={{
          height: window.innerHeight * height,
          width: '100%',
          zIndex: 1,
          border: '1px solid',
          borderRadius: theme.shape.borderRadius,
          borderColor:
            theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey[300],
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {!isLoading &&
          markers &&
          markers.map(
            (data, index) =>
              data.geoLocation?.latitude &&
              data.geoLocation?.longitude && (
                <Marker position={position} icon={MapMarker(data.iconName)}>
                  {data.showTooltip && <Popup>{data.tooltipTitle}</Popup>}
                </Marker>
              ),
          )}
        <ChangeMapView coords={position} />
      </MapContainer>
    </>
  );
}

OpenStreetMap.defaultProps = {
  height: 0.35,
  zoom: 13,
};

OpenStreetMap.propTypes = {
  height: PropTypes.number.isRequired,
  markers: PropTypes.array,
  isLoading: PropTypes.bool,
  zoom: PropTypes.number,
};
