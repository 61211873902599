import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FormHeader } from 'components/SectionForms';
import React from 'react';
import AddEditDevicesForm from './AddEditDevicesForm';

// icons
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
const AddEditDevices = ({ openDialog, closeDialog, setIsDataLoading, isEditing, ...props }) => {
  return (
    <div>
      <Dialog
        id='vehicle-form-dialog'
        open={openDialog}
        aria-labelledby='form-dialog-title'
        fullWidth='md'
        maxWidth='md'
      >
        <DialogTitle sx={{ p: 0, m: 0 }}>
          <FormHeader
            title={isEditing === true ? 'Update Device' : 'Add Device'}
            id={'close-vehicle-form'}
            onClose={closeDialog}
            disableClose={false}
            icon={isEditing ? <BrowserUpdatedIcon /> : <PhonelinkIcon />}
          />
        </DialogTitle>
        <DialogContent dividers>
          <AddEditDevicesForm
            isEditing={isEditing}
            closeDialog={closeDialog}
            setIsDataLoading={setIsDataLoading}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddEditDevices;
