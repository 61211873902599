import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// assets
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  borderColor: theme.palette.grey[500],
  '&:hover': {
    borderColor: theme.palette.grey[700],
  },
}));

// ============================== STUDENT FORM - MAIN  ============================== //

function BackButton({ children, ...props }) {
  return (
    <StyledBackButton {...props} startIcon={<ArrowBackIcon />}>
      {children.toUpperCase()}
    </StyledBackButton>
  );
}

BackButton.defaultProps = {
  variant: 'outlined',
  size: 'large',
  color: 'secondary',
};
BackButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string,
};

export default BackButton;
