import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { Zoom } from '@mui/material';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '8px 8px',
    marginTop: 5,
  },
}))(Tooltip);

export default function WeatherWidget(props) {
  const apiKey = '754eb52e4ee07bc723082216ffc978ca';

  const [weatherData, setWeatherData] = useState([{}]);

  const city = props.selectedWeatherCity
    ? props.selectedWeatherCity
    : sessionStorage.getItem('SelectedSessionCityForWeather') || props.defaultWeatherCity;

  const getWeather = (event) => {
    fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}`)
      .then((response) => response.json())
      .then((data) => {
        setWeatherData(data);
      })
      .catch((error) => console.log('Error getting weather data', error));
  };

  const getTempInFahrenheit = (temp) => {
    if (temp) {
      return `${Math.round(((temp - 273.15) * 9) / 5 + 32)}°F`;
    }
    return '--';
  };

  const imageUrl = `http://openweathermap.org/img/wn/${weatherData.weather?.[0]['icon']}.png`;

  useEffect(() => {
    getWeather();
  }, [city]);

  return (
    <React.Fragment>
      <LightTooltip arrow title={weatherData.weather?.[0]['main']} TransitionComponent={Zoom}>
        <img src={imageUrl} />
      </LightTooltip>
      <Typography variant='subtitle1' id="headerWeatherShow">
        {getTempInFahrenheit(weatherData.main?.['temp'])} | {weatherData.name?.toUpperCase()}
      </Typography>
    </React.Fragment>
  );
}
