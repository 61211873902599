import { BREADCRUMBS_DATA_RESET, BREADCRUMBS_DATA_SUCCESS } from "reduxConstants/breadcrumbsConstants";

export const breadcrumbsDataReducer = (state = { breadcrumbsData: null }, action) => {
    switch (action.type) {
        case BREADCRUMBS_DATA_SUCCESS:
            return { breadcrumbsData: action.payload }
        case BREADCRUMBS_DATA_RESET:
            return { breadcrumbsData: null }
        default:
            return state;
    }
};