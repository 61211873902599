import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// third party imports
import { useSnackbar } from 'notistack';

// material-ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

// APIs
import {
  deleteStudent,
  updateGuidedWorkFlow,
  getStudentsBySchoolIdAndSession,
} from 'clients/SpotBus';

// custom components
import ButtonBases from 'components/Buttons/ComplexButton';

// utils
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';

// project imports
import DeleteAlert from '../../Components/DeleteAlert';
import MainStudentForm from 'containers/layouts/Students/MainStudentForm';
import RightSideCard from './RightSideCard';
import StudentListCard from './StudentListCard';
import GpTwoBackForwordBtn from '../GpTwoBackForwordBtn';
import { queryKey } from 'react-query/queryKey';
import { useQuery } from '@tanstack/react-query';

function InitialRightSideCard() {
  return (
    <>
      <RightSideCard
        firstMainHeadLine={'Bult Import Students '}
        firtsParagraph={
          'firtsParagraph We can import student in bulk in the form of Excel Sheet at once Including all information of student'
        }
        secondMainHeadine={'Add Student Manually  '}
        secondParagraph={'We can add Student One at a time We can add Student One at a time '}
        firstImage={'https://i.ibb.co/pjVNXhV/import-image.jpg'}
        secondImage={
          'https://img.freepik.com/free-vector/large-school-building-scene_1308-32058.jpg?w=2000'
        }
      />
    </>
  );
}

const ConfigureStudentTypes = (onConfigureTypesClickHandler) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
      }}
    >
      <Grid xs='auto'>
        <ButtonBases
          title='Bulk import students'
          imageForButtonBackGround={'https://i.ibb.co/pjVNXhV/import-image.jpg'}
          onClickHandler={() => onConfigureTypesClickHandler(2)}
        />
      </Grid>
      <Grid xs='auto'>
        <ButtonBases
          title='Manually add students'
          imageForButtonBackGround={
            'https://img.freepik.com/free-vector/large-school-building-scene_1308-32058.jpg?w=2000'
          }
          onClickHandler={() => onConfigureTypesClickHandler(1)}
        />
      </Grid>
    </Grid>
  );
};

// ============================== MAIN CONFIGURE STUDENT - GUIDED PHASE 2 ============================== //
function ConfigureStudents({
  selectedSchoolId,
  setActiveStepforRoutes,
  guidedWorkflowData,
  handleGuidedWorkflowDataUpdate,
  setRenderGPTwo,
  globalBtnC,
  setGlobalBtnC,
  setSelectedSchoolId,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [csActiveStep, setCsActiveStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  // student specific states
  const [studentsData, setStudentsData] = useState();
  const [selectedStudentData, setSelectedStudentData] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [updateActiveStep, setupdateActiveStep] = useState(false);

  // Student Api Loading
  const [apiLoadingStudent, setApiLoadingStudet] = useState(false);

  // configure students click handlers
  const onConfigureTypesClickHandler = (step) => {
    setCsActiveStep(step);
  };

  const onEditClickcometoaBasicDetails = () => {
    setupdateActiveStep(!updateActiveStep);
  };

  // Student delete alert cancel handler
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedStudentData();
  };

  const handleDeleteStudentClick = (student_id) => {
    let tempData = studentsData.find((studentData) => studentData.id === student_id);
    setSelectedStudentData(tempData);
    setOpenDeleteDialog(true);
  };

  const handleEditStudentClick = (student_id) => {
    console.log('Edit student event: ', student_id);
    let tempData = studentsData.find((studentData) => studentData.id === student_id);
    setSelectedStudentData(tempData);
    setIsUpdate(true);
    onEditClickcometoaBasicDetails();
  };

  // helper function when add student operation is success full and refresh list is required
  const handleRefreshStudentList = () => {
    getAllStudentsForSelectedSchool(selectedSchoolId);
    handleUpdateGuidedWorkflowData(1, selectedSchoolId);
    // TODO: Add guided workflow update/add process
  };

  // Update student operation is success full and refresh list is required
  const handleEditRefreshStudentList = () => {
    // clearing not required states
    setIsUpdate(false);
    setSelectedStudentData();
    // APIs call to get new list of students
    getAllStudentsForSelectedSchool(selectedSchoolId);
  };

  // API call to get list of all available students
  const getAllStudentsForSelectedSchool = async (school_id) => {
    const paginate = false;
    setIsLoading(true);
    try {
      const result = await getStudentsBySchoolIdAndSession({ school_id, paginate });
      console.log('result___', result);
      setStudentsData(result);
      return result;
    } catch (error) {
      console.error('Error getting all students for selected school', error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  // API handler for delete student
  const handleDeleteStudent = async (student_id) => {
    setApiLoadingStudet(true);
    setIsSubmitting(true);
    try {
      const result = await deleteStudent(student_id);
      enqueueSnackbar('Student deleted ', {
        variant: 'success',
      });
      setApiLoadingStudet(false);
      handleUpdateGuidedWorkflowData(-1, selectedSchoolId);
      // essential state reset
      setIsSubmitting(false);
      setOpenDeleteDialog(false);
      // update form state reset
      setSelectedStudentData(false);
      setIsUpdate(false);
      // refreshing list of students
      getAllStudentsForSelectedSchool(selectedSchoolId);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setApiLoadingStudet(false);
      setIsSubmitting(false);
      setOpenDeleteDialog(false);
      // update form state reset
      setSelectedStudentData(false);
      setIsUpdate(false);
    }
  };

  // get the index
  const getIndex = (req, selectedSchoolId) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'CONFIGURE_SCHOOLS') {
        for (let j = 0; j < req.steps_graph[i].data.length; j++) {
          if (
            req.steps_graph[i].data[j].name === 'ADD_STUDENTS' &&
            req.steps_graph[i].data[j].school_id === selectedSchoolId
          ) {
            return [i, j];
          }
        }
        return i;
      }
    }
  };

  // update guided workflow data on add or delete
  const handleUpdateGuidedWorkflowData = async (count = 1, selectedSchoolId) => {
    let req = guidedWorkflowData;
    let indexArr = getIndex(guidedWorkflowData, selectedSchoolId);
    req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count =
      req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count + count;
    if (
      req.steps_graph[indexArr[0]].data[indexArr[1]].min_items_required <=
      req.steps_graph[indexArr[0]].data[indexArr[1]].available_items_count
    ) {
      req.steps_graph[indexArr[0]].data[indexArr[1]].is_completed = true;
    } else {
      req.steps_graph[indexArr[0]].data[indexArr[1]].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getConfigureStudentsActiveStep = (step) => {
    switch (step) {
      case 0:
        return (
          <ConfigureStudentTypes onConfigureTypesClickHandler={onConfigureTypesClickHandler} />
        );
      case 1:
        return (
          <>
            {!isUpdate && (
              <>
                <Grid>
                  <MainStudentForm
                    isDialogForm={false}
                    isFromGuided={true}
                    selectedSchoolId={selectedSchoolId}
                    handleRefreshStudentList={handleRefreshStudentList}
                    setActiveStepforRoutes={setActiveStepforRoutes}
                    isSubmitting={isSubmitting}
                    setRenderGPTwo={setRenderGPTwo}
                    globalBtnC={globalBtnC}
                    setGlobalBtnC={setGlobalBtnC}
                    setApiLoadingStudet={setApiLoadingStudet}
                    updateActiveStep={updateActiveStep}
                  />
                </Grid>

                <Grid>
                  <GpTwoBackForwordBtn
                    selectedSchoolId={selectedSchoolId}
                    setRenderGPTwo={setRenderGPTwo}
                    setActiveStepforRoutes={setActiveStepforRoutes}
                    nextButtonTitle={'SECOND PHASE'}
                    isFromSection={'Student'}
                    globalBtnC={globalBtnC}
                    setGlobalBtnC={setGlobalBtnC}
                    guidedApi={studentsData}
                    setSelectedSchoolId={setSelectedSchoolId}
                  />
                </Grid>
              </>
            )}
            {isUpdate && (
              <>
                <Grid>
                  <MainStudentForm
                    isDialogForm={false}
                    selectedSchoolId={selectedSchoolId}
                    isUpdate={true}
                    studentData={selectedStudentData}
                    setActiveStepforRoutes={setActiveStepforRoutes}
                    handleRefreshStudentList={handleEditRefreshStudentList}
                    setIsUpdate={setIsUpdate}
                    isSubmitting={isSubmitting}
                    setRenderGPTwo={setRenderGPTwo}
                    globalBtnC={globalBtnC}
                    setGlobalBtnC={setGlobalBtnC}
                    setApiLoadingStudet={setApiLoadingStudet}
                    updateActiveStep={updateActiveStep}
                    isFromGuided={true}
                  />
                </Grid>

                <Grid>
                  <GpTwoBackForwordBtn
                    selectedSchoolId={selectedSchoolId}
                    setRenderGPTwo={setRenderGPTwo}
                    setActiveStepforRoutes={setActiveStepforRoutes}
                    nextButtonTitle={'SECOND PHASE'}
                    isFromSection={'Student'}
                    globalBtnC={globalBtnC}
                    setGlobalBtnC={setGlobalBtnC}
                    guidedApi={studentsData}
                    setSelectedSchoolId={setSelectedSchoolId}
                  />
                </Grid>
              </>
            )}
          </>
        );
      case 2:
        return <Typography variant='h5'>Bulks import students</Typography>;

      default:
        return (
          <Typography color='error' variant='h5'>
            Error: No step available try using right step value.{' '}
          </Typography>
        );
    }
  };

  const getConfigureStudentsActiveStepRightCard = (step) => {
    switch (step) {
      case 0:
        return <InitialRightSideCard guidedStudent={studentsData} />;
      default:
        return (
          <StudentListCard
            isLoading={isLoading}
            studentsData={studentsData}
            handleDelete={handleDeleteStudentClick}
            handleEdit={handleEditStudentClick}
            apiLoadingStudent={apiLoadingStudent}
          />
        );
    }
  };

  const { data: getStudents, isLoading: isStudentsLoading } = useQuery({
    queryKey: [queryKey.GUIDED_STUDENTS.ALL_STUDENTS, selectedSchoolId],
    queryFn: () => getAllStudentsForSelectedSchool(selectedSchoolId),
    // enabled: !selectedSchoolId,
  });

  console.log(getStudents, isStudentsLoading, 'this_is_students');

  // useEffect(() => {
  //   getAllStudentsForSelectedSchool(selectedSchoolId);
  // }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={8}>{getConfigureStudentsActiveStep(csActiveStep)}</Grid>
        <Grid xs={4}>{getConfigureStudentsActiveStepRightCard(csActiveStep)}</Grid>
      </Grid>
      {/** Delete student dialog component */}
      <DeleteAlert
        buttonLoader={isSubmitting}
        deleteId={selectedStudentData?.id}
        open={openDeleteDialog}
        setClose={handleCloseDeleteDialog}
        section={'Student'}
        handleDelete={handleDeleteStudent}
      />
    </>
  );
}

ConfigureStudents.propTypes = {
  selectedSchoolId: PropTypes.string.isRequired,
};

export default ConfigureStudents;
