import React from 'react';
import TextField from '@mui/material/TextField';
import ValidatorComponent from './ValidatorComponent';
import { isNotBlank } from './Validators';

let MIN_PASSWORD_LENGTH = 8 

export default class PhoneNumberTextField extends ValidatorComponent {

    constructor(props){
        super(props)
    }



    isValidPhoneNumber(value){
        
        let re = /^([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}$/
        if (!re.test(value)) return false;
        
        return true
    }

    getDefaultValidationRules(){
        return [
            {
                function: isNotBlank,
                errorMessage: "Phone Number is required."
            },
            {
                function: this.isValidPhoneNumber,
                errorMessage: "Invalid PhoneNumber, should have a country code."
            }
        ]
    }

    render(){
        
        return (
            <TextField
                variant="outlined"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                {...this.props}
                onChange={(e) => { 
                    this.validate(e.target.value);
                    this.props.onChange(e)
                 }}
                error={this.state.errorMessage != undefined || this.props.error}
                helperText={this.state.errorMessage || this.props.helperText}
            />
        );
    }
}