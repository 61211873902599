import * as React from 'react';

// material ui
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// project imports
import MainCard from 'components/MainCard';

export default function NoRegisteredSchool() {
  return (
    <MainCard>
      <CardMedia
        component='img'
        height='100%'
        image='https://img.freepik.com/free-vector/large-school-building-scene_1308-32058.jpg?w=2000'
        alt='green iguana'
      />
      <Typography gutterBottom variant='h2'>
        School
      </Typography>
      <Typography variant='body1'>
        Schools are basic requirement to use SpotBus services. You are required to add at least one
        school so we can help you in storing student, driver and vehicle data. Then we will start
        planning best route with minimum stops that is most suitable for all students. We will
        assign an available driver for planned route. Now there is no need to worry about buses you
        can easily track there live locations and check ETA for all stops. if any bus got stuck in
        trafic student got notified about ETA for there stops. It will reduce there waiting time for
        bus. if there is any issue with bus drive can easily tell that to school and on one click
        you can assign a new bus for that route.
      </Typography>
    </MainCard>
  );
}
