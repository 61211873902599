import React from 'react';

// material-ui
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

// assets
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import AddIcon from '@mui/icons-material/Add';
import FileOpenIcon from '@mui/icons-material/FileOpen';

// project imports
import LightTooltip from 'containers/layouts/Utils/LightTooltip';

// Custom Component
import QuickViewActionItem from 'components/quickViews/QuickViewActionItem';
import QuickViewActionsTitle from 'components/quickViews/QuickViewActionsTitle';
import { useLocation } from 'react-router-dom';

const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(Button);

export default function DriverNameAndButtons({
  firstName,
  lastName,
  handleDeleteDriver,
  driverId,
  handleDriverEdit,
  setOpenDeleteDriverAlert,
  setDriverUserName,
  selectedDriverData,
  isEdit,
  loadUpdatedData,
  dataLoading,
  enabled,
  toggleDriverStatusHandler,
  toogleStatusLoading,
  toggleloader,
  openDriverShifts,
  handleShiftClick,
  handleShiftDetails,
}) {
  const theme = useTheme();
  const isReadOnlyUser =
    localStorage.getItem('loggedUserRoleType') === 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS';
  const location = useLocation();
  return (
    <React.Fragment>
      {location.pathname === '/users' && (
        <QuickViewActionsTitle
          isDataLoading={dataLoading}
          onEditClick={() => handleDriverEdit(selectedDriverData)}
          onDeleteClick={() => {
            setOpenDeleteDriverAlert(true);
            setDriverUserName(driverId);
          }}
          isSubmitting={isReadOnlyUser || isEdit || loadUpdatedData}
          heading={`${firstName + ' ' + lastName}`}
        />
      )}
      <QuickViewActionItem
        isIcon={true}
        id='active_disabled'
        size={'small'}
        color={enabled ? 'success' : 'error'}
        description={'Toggle account status'}
        tooltipContent={enabled === true ? 'Active' : 'Disabled'}
        isLoading={dataLoading}
        startIcon={!enabled ? <NotInterestedIcon /> : <DoneIcon />}
        loaderButton={true}
        loading={toggleloader}
        loadingPosition='start'
        buttonName={enabled === true ? 'Active' : 'Disabled'}
        handleClick={() => toggleDriverStatusHandler(selectedDriverData)}
      />

      {location.pathname === '/drivers' && (
        <>
          <QuickViewActionItem
            isIcon={false}
            id='driver_role'
            size={'small'}
            color={'primary'}
            description={'Driver Role'}
            tooltipContent={'Driver Role'}
            isLoading={dataLoading}
            loaderButton={true}
            loadingPosition='start'
            buttonName={'Regular Driver'}
            // handleClick={() => setOpenDriverShifts(true)}
          />

          <QuickViewActionItem
            isIcon={true}
            id='driver_shifts'
            size={'small'}
            color={'primary'}
            description={'Manage Shifts'}
            tooltipContent={'Driver shifts can be managed using this feature'}
            isLoading={dataLoading}
            startIcon={<AddIcon />}
            loaderButton={true}
            loading={openDriverShifts.loading}
            loadingPosition='start'
            buttonName={'Shifts'}
            handleClick={(event) => handleShiftClick(event, selectedDriverData?.driver_username)}
          />

          <QuickViewActionItem
            isIcon={true}
            id='shifts_details'
            size={'small'}
            color={'primary'}
            description={'Timesheet'}
            tooltipContent='View driver historical timesheet data'
            isLoading={dataLoading}
            startIcon={<FileOpenIcon />}
            loaderButton={true}
            loadingPosition='start'
            buttonName={'View Table'}
            handleClick={() => handleShiftDetails(true)}
          />
        </>
      )}
    </React.Fragment>
  );
}
