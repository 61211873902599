import React from 'react';
import TextField from '@mui/material/TextField';
import ValidatorComponent from './ValidatorComponent';
import { isNotBlank,isEmail } from './Validators';

export default class EmailTextField extends ValidatorComponent {

    constructor(props){
        super(props)
        
    }

    isValidEmail(email){

        if (!email) return false;
    
        if (email.length > 256) return false;
    
        var tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!tester.test(email)) return false;
        
        // Further checking of some things regex can't handle
        var [account, address] = email.split('@');
        if (account.length > 64) return false;
    
        var domainParts = address.split('.');
        if (domainParts.some(function (part) {
            return part.length > 63;
        })) return false;
    
        return true;
    }

    getDefaultValidationRules(){
        return [
            {
                function: isNotBlank,
                errorMessage: "Email field is required."
            },
            {
                function: this.isValidEmail,
                errorMessage: "Please enter a valid email."
            }
        ]
    }

    render(){
        return (
            <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...this.props}
                onChange={(e) => { 
                    this.validate(e.target.value);
                    this.props.onChange(e)
                 }}
                error={this.state.errorMessage != undefined || this.props.error}
                helperText={this.state.errorMessage || this.props.helperText}
            />
        );
    }
}