import * as React from 'react';

// mui
import { useTheme } from '@mui/material';

const SpotBusLogo = (props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const textColor = isDarkMode ? '#fff' : '#0c0d0c'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 750 750',
      }}
      xmlSpace='preserve'
      viewBox='31.09 283.4 687.91 183.1'
      {...props}
    >
      <style>{`.st0{fill:${textColor}}.st1{fill:#e4b301}`}</style>
      <path
        className='st0'
        d='M127.6 327.1c-1-24.1-19.1-36.1-45.1-36.1-16 0-38.6 6.7-38.6 30 0 22.7 24.1 26.6 47.9 31.3 24.1 4.7 48.1 11.4 48.1 39 0 29.2-28 39.6-49.5 39.6-32.9 0-59.9-13.6-59.3-49.9h8.7c-1.6 30.8 22.7 42.4 50.7 42.4 16.8 0 40.8-7.7 40.8-32.1 0-23.7-24.1-28.2-48.1-32.9-23.9-4.7-47.9-10.8-47.9-37.5 0-27 25-37.5 47.3-37.5 29.4 0 52.2 12.6 53.8 43.6h-8.8zM158 349.5h.4c3.2-10.3 14.6-25.6 36.9-25.6 31 0 45.5 24.8 45.5 53.2 0 30.8-14.6 52.8-45.5 52.8-16.8 0-30.8-6.9-36.9-23.5h-.4v57.4h-7.5V326.3h7.5v23.2zm75.3 27.6c0-23.1-10.8-46.7-38.1-46.7-27 0-37.1 22.7-37.1 46.7 0 24.3 8.5 46.3 37.1 46.3 26.3.1 38.1-21.2 38.1-46.3zM390 332.8h-22.1v70.8c0 16.2 7.7 18.1 21.9 17.5v6.5c-15 1-30.4-1.2-29.4-24.1v-70.8h-18.7v-6.5h18.7v-31.5h7.5v31.5H390v6.6z'
      />
      <path
        className='st1'
        d='M396.5 286.8h66.2c26.8 0 45 8.7 45 34.9 0 14-6.7 23.3-19.1 29.4 17.4 4.9 25.8 18.1 25.8 35.7 0 28.6-24.2 40.8-49.7 40.8h-68.2V286.8zm62.3 57c11 0 18.9-4.9 18.9-16.8 0-13.2-10.3-16.2-21.3-16.2h-29v32.9h31.4zm1.7 59.8c12 0 22.9-3.9 22.9-18.5 0-14.4-8.9-20.1-22.3-20.1h-33.7v38.6h33.1zM618.1 427.6h-26.6v-14.2h-.6c-7.1 11.6-19.3 17-31.2 17-29.8 0-37.3-16.8-37.3-42v-62.7h28v57.6c0 16.8 4.7 25 17.9 25 15.2 0 21.7-8.5 21.7-29.2v-53.4h28v101.9zM651.2 394.5c0 12 10.6 17.2 21.5 17.2 7.9 0 18.3-3.2 18.3-13 0-8.5-11.8-11.6-31.7-15.8-16.4-3.7-32.3-9.1-32.3-27.2 0-26 22.5-32.7 44.4-32.7 22.1 0 42.8 7.5 45 32.5h-26.6c-.8-10.8-9.1-13.8-19.1-13.8-6.3 0-15.6 1-15.6 9.5 0 10.1 16 11.4 31.7 15.2C703 370 719 376 719 394.9c0 26.8-23.5 35.5-46.5 35.5-23.7 0-46.9-8.9-47.9-35.9h26.6zM296.7 323.9c-27.5 0-49.7 22.3-49.7 49.7 0 27.5 49.7 90.2 49.7 90.2s49.7-62.8 49.7-90.2c.1-27.4-22.2-49.7-49.7-49.7zm0 83.6c-19.3 0-35-15.7-35-35s15.7-35 35-35 35 15.7 35 35c.1 19.3-15.6 35-35 35z'
      />
      <path
        className='st0'
        d='M318.5 362.3c0-.5-.1-1-.2-1.5-.4-1.5-1.3-2.7-2.6-3.6-.8-.6-1.4-1.3-1.8-2.1-.1-.3-.2-.6-.2-.9 0-2.4-1.8-4.2-4.2-4.2h-25.8c-.4 0-.7 0-1.1.1-1.9.5-3.2 2.1-3.1 4.1.1 1.2-.4 1.9-1.2 2.6-.1.1-.3.2-.4.3-1.9 1.4-2.9 3.2-2.9 5.6v3.3c0 .9.5 1.5 1.3 1.5s1.3-.6 1.3-1.5v-4c0-1 .4-1.9 1.2-2.5.1-.1.3-.2.4-.1.1.1.2.2.3.4v27.5c0 2.4.8 3.2 3 3h.5v2.3c0 .8.4 1.2 1.2 1.2h1.9c.7 0 1.1-.4 1.1-1.1v-2.3h19v2.3c0 .6.4 1.1 1 1.1h2.2c.6 0 1-.4 1-1V390.4h1c1.5 0 2.2-.8 2.2-2.2v-27.1c0-.4.2-.9.4-1.3s.4-.4.7-.1c.8.7 1.2 1.5 1.2 2.5v4c0 .2 0 .4.1.6.2.6.8 1 1.4.9.6-.1 1.1-.6 1.1-1.3 0-1.6.1-2.8 0-4.1zm-29.6-10c0-.5.2-.7.7-.7h14c.7 0 .9.2.9.9v1.1c0 .6-.2.8-.8.8h-13.9c-.7 0-.8-.2-.8-.8v-1.3zm-.5 31.4c0 .3-.3.6-.7.6H286c-1.5 0-2.9 0-4.3-.7l-.6-.3c-.3-.2-.4-.5-.4-.9v-2.3c0-.4.2-.6.5-.7.6-.3 1.3-.2 1.9 0 1.8.5 3.4 1.4 4.8 2.6.5.5.6 1.1.5 1.7zm16 4c0 .6-.2.8-.8.8h-14c-.7 0-.8-.2-.8-.8v-1.1c0-.6.2-.8.8-.8h13.9c.7 0 .9.2.9.9v1zm8.2-5.4c0 .5-.3.8-.7 1-.8.5-1.6.7-2.4.8-.7.1-1.5.1-2.2.2h-1.4c-.7 0-1-.3-1-1 0-.6.2-1 .6-1.4 1.6-1.4 3.4-2.3 5.5-2.7.4-.1.8.1 1.3.2.3.1.4.3.4.6-.1.8-.1 1.6-.1 2.3zm0-9.8c0 1.7-.8 2.8-2.4 3.3-1.7.5-3.5.9-5.2 1.1-2.3.3-4.7.5-7.1.8-.4 0-.8.1-1.2.1-1.3 0-2.5-.1-3.8-.3-1.4-.1-2.8-.3-4.2-.4-2.1-.3-4.2-.6-6.2-1.4-.5-.2-.9-.5-1.3-.9s-.5-.9-.5-1.4v-14.7c0-.7.3-1.1.8-1.4.9-.5 2-.8 3-.9 1.7-.2 3.4-.3 5.1-.3 2.2-.1 4.4 0 6.6 0v-.1h6.4c1.8 0 3.7.1 5.5.3 1 .1 1.9.4 2.9.7 1 .4 1.5 1.1 1.5 2.3v13.2z'
      />
      <path
        d='M296.7 323.9v13.5c-19.3 0-35 15.7-35 35s15.7 35 35 35v56.4S247 401 247 373.6c0-27.4 22.3-49.7 49.7-49.7z'
        style={{
          fill: '#fdc801',
        }}
      />
      <circle
        cx={296.7}
        cy={372.5}
        r={35}
        style={{
          fill: 'none',
          stroke: textColor,
          strokeWidth: 4.6236,
          strokeMiterlimit: 10,
        }}
      />
      <ellipse
        cx={296.7}
        cy={465.3}
        rx={31.9}
        ry={1.2}
        style={{
          fill: '#d8d8d8',
        }}
      />
    </svg>
  );
};

export default SpotBusLogo;
