import React from 'react';

// MUI Imports
import Switch from '@mui/material/Switch';
import { Stack, Typography } from '@mui/material';

// Components

/**
 * Renders a switch component with a label and helper text.
 *
 * @param {string} label - The text label for the switch.
 * @param {boolean} checked - The value of the switch.
 * @param {function} onChange - The function called when the switch is toggled.
 * @param {string} helperText - The helper text displayed below the switch.
 * @param {object} props - Additional props to be spread on the Stack component.
 * @return {JSX.Element} - The JSX element representing the SwitchWithText component.
 */
export const SwitchWithText = ({
  label = '',
  checked = false,
  onChange = () => {},
  helperText = '',
  // maxWidth = '100%',
  ...props
}) => {
  return (
    <Stack width={'100%'} my={1} {...props}>
      <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
        <Typography variant='h6'>{label}</Typography>
        <Switch
          checked={checked}
          onChange={onChange}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
        />
      </Stack>
      {helperText && (
        <Typography variant='subtitle2' fontWeight={'normal'} ml={1} color={'text.secondary'}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};
