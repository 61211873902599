// Device Model List
export const DEVICE_MODEL_LIST = [
  {
    label: 'Galaxy Active Tab 4',
    value: 'galaxy_active_tab_4',
  },
];

// Manufacturer List
export const MANUFACTURER = [
  // {
  //   label: 'Geo Tab',
  //   value: 'geotab',
  // },
  // {
  //   label: 'Calamp',
  //   value: 'calamp',
  // },
  // {
  //   label: 'Others',
  //   value: 'others',
  // },
  {
    label: 'Samsung',
    value: 'samsung',
  },
];

// Device Type List
export const DEVICE_TYPE = [
  // {
  //   label: 'GPS Tracker',
  //   value: 'gps_tracker',
  // },
  // {
  //   label: 'RFID',
  //   value: 'rfid',
  // },
  {
    label: 'Tablet',
    value: 'tablet',
  },
];


// Carrier List 
export const CARRIER = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Verizon',
    value: 'verizon',
  },
  {
    label: 'T-Mobile ',
    value: 't_mobile ',
  },
];
