import React from 'react';

// MUI Imports
import { Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import MapIcon from '@mui/icons-material/Map';

// Components
import IconWithText from 'components/IconWithText';
import SettingsIcon from '@mui/icons-material/Settings';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { SwitchWithText } from 'components/SwitchWithText';

/**
 * Renders a popup component for setting configuration.
 *
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.open - Whether the popup is open or not.
 * @param {function} props.handleClose - The function to handle closing the popup.
 * @param {string} [props.schoolName='MAX School'] - The name of the school.
 * @param {string} props.logo - The logo of the school.
 * @param {boolean} props.routeDetail - Whether route detail is enabled or not.
 * @param {boolean} props.routeDriver - Whether route driver detail is enabled or not.
 * @param {boolean} props.routeVehicle - Whether route vehicle detail is enabled or not.
 * @param {function} props.handleRouteDetail - The function to handle enabling/disabling route detail.
 * @param {function} props.handleRouteDriver - The function to handle enabling/disabling route driver detail.
 * @param {function} props.handleRouteVehicle - The function to handle enabling/disabling route vehicle detail.
 * @param {boolean} props.tripDetail - Whether trip detail is enabled or not.
 * @param {boolean} props.tripDriver - Whether trip driver detail is enabled or not.
 * @param {boolean} props.tripVehicle - Whether trip vehicle detail is enabled or not.
 * @param {function} props.handleTripDetail - The function to handle enabling/disabling trip detail.
 * @param {function} props.handleTripDriver - The function to handle enabling/disabling trip driver detail.
 * @param {function} props.handleTripVehicle - The function to handle enabling/disabling trip vehicle detail.
 * @return {JSX.Element} The rendered popup component.
 */
export function SettingConfigPopup({
  open,
  handleClose,
  schoolName = 'MAX School',
  logo,

  routeDetail,
  routeDriver,
  routeVehicle,
  handleRouteDetail,
  handleRouteDriver,
  handleRouteVehicle,
  // trip
  tripDetail,
  tripDriver,
  tripVehicle,
  handleTripDetail,
  handleTripDriver,
  handleTripVehicle,
}) {
  function getAbbreviation(schoolName) {
    const words = schoolName.split(' ');
    const firstLetter = words[0][0];
    const secondLetter = words[1][0];
    return `${firstLetter}${secondLetter}`;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description-district'
        maxWidth='md'
      >
        <Stack
          id='alert-dialog-title-district'
          bgcolor={'primary.main'}
          direction={'row'}
          sx={{ display: 'flex', alignItems: 'center' }}
          py={2}
          px={3}
          position={'relative'}
        >
          <Stack
            width={'100px'}
            height={'100px'}
            borderRadius={'50%'}
            mx={1}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={'secondary.light'}
            boxShadow={
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
            }
          >
            {logo ? (
              <img
                src={logo}
                width={'100px'}
                height={'100px'}
                alt=''
                style={{ borderRadius: '50%', border: '2px solid gray' }}
              />
            ) : (
              <Typography variant='h1' letterSpacing={'1px'}>
                {getAbbreviation(schoolName)}
              </Typography>
            )}
          </Stack>
          <Typography variant='h4' ml={2}>
            {schoolName}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: 10,
            }}
          >
            <CloseIcon fontSize='medium' />
          </IconButton>
        </Stack>
        <DialogContent>
          <IconWithText
            title='Data Configuration'
            icon={SettingsIcon}
            iconProps={{ color: 'secondary' }}
            titleProps={{ variant: 'h4' }}
          />
          <Typography
            variant='subtitle2'
            fontWeight={'normal'}
            ml={7}
            mb={1}
            color={'text.secondary'}
          >
            Route data configuration refers to the process of setting up or adjusting the parameters
            and settings that govern the collection, organization, and presentation of route-related
            information within a system or application.
          </Typography>

          <Stack ml={6}>
            <Stack direction={'row'}>
              <IconWithText
                title='Enable Route Details'
                icon={MapIcon}
                titleProps={{ variant: 'h6' }}
                iconProps={{ fontSize: 'small' }}
              />
              <SwitchWithText maxWidth='90px' onChange={handleRouteDetail} checked={routeDetail} />
            </Stack>
            <Stack ml={6}>
              <Typography variant='subtitle2' fontWeight={'normal'} mb={1} color={'text.secondary'}>
                Enabling route details with a toggle button refers to a functionality within a
                dashboard or user interface that allows users to display or hide specific
                information related to a particular route by activating or deactivating a toggle
                button. Also toggle to enable the details related to driver and vehicle on the
                dashboard.
              </Typography>
              <SwitchWithText
                label='Driver Details'
                maxWidth='220px'
                onChange={handleRouteDriver}
                checked={routeDriver}
              />
              <SwitchWithText
                label='Vehicle Details'
                maxWidth='220px'
                onChange={handleRouteVehicle}
                checked={routeVehicle}
              />
            </Stack>

            <Stack direction={'row'}>
              <IconWithText
                title='Enable Route Trip Details'
                icon={DirectionsBusIcon}
                titleProps={{ variant: 'h6' }}
                iconProps={{ fontSize: 'small' }}
              />
              <SwitchWithText maxWidth='60px' onChange={handleTripDetail} checked={tripDetail} />
            </Stack>
            <Stack ml={6}>
              <Typography variant='subtitle2' fontWeight={'normal'} mb={1} color={'text.secondary'}>
                Enabling route trip details with a toggle button refers to a functionality within a
                dashboard or user interface that allows users to display or hide specific
                information about individual trips or segments of a route by activating or
                deactivating a toggle button. Also toggle to enable the details related to driver
                and vehicle on the dashboard.
              </Typography>
              <SwitchWithText
                label='Driver Details'
                maxWidth='220px'
                onChange={handleTripDriver}
                checked={tripDriver}
              />
              <SwitchWithText
                label='Vehicle Details'
                maxWidth='220px'
                onChange={handleTripVehicle}
                checked={tripVehicle}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
