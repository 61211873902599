import React from 'react';

// material ui imports
import { Grid, Chip } from '@mui/material';

// assets
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';

// project imports
import { SubHeading } from 'components/Headers/SubHeading';
import { AdditionalSubHeading } from 'components/Headers/AdditionalSubHeading';

export default function GeneratePermission(props) {
  const handlePermissionChange = (value) => {
    props.setPermissionType(value);
    if (value === 'ROLE_ADMIN') {
      props.setPermittedSchools(props.guidedSchools.map((school) => school.school_id));
    } else {
      props.setPermittedSchools([]);
    }
  };

  function handleSchoolClick(id) {
    if (!props.permittedSchools.includes(id)) {
      props.setPermittedSchools([...props.permittedSchools, id]);
    } else {
      const temp = props.permittedSchools.filter((el) => el !== id);
      props.setPermittedSchools(temp);
    }
  }

  const ShowSchools = () => {
    return (
      <>
        {props.guidedSchools?.map((school, index) => (
          <Grid item xs={'auto'} key={index}>
            <Chip
              id={'school' + index}
              variant={props.permittedSchools.includes(school.school_id) ? 'default' : 'outlined'}
              icon={<SchoolIcon />}
              key={school.school_id}
              clickable
              label={school.school_name}
              color={props.permittedSchools.includes(school.school_id) ? 'primary' : 'default'}
              onClick={(e) => {
                if (props.permissionType === 'ROLE_ADMIN') {
                  return;
                }
                handleSchoolClick(school.school_id);
              }}
            />
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item xs={12}>
        <SubHeading subTitle={'Permissions'} color={'primary'} />
      </Grid>
      <Grid container direction='row' sx={{ pl: 4 }} columnSpacing={4} rowSpacing={2}>
        <Grid item xs={12}>
          <AdditionalSubHeading subTitle={'Select Permissions / Access'} />
        </Grid>
        <Grid item xs={'auto'}>
          <Chip
            variant={props.permissionType === 'ROLE_ADMIN' ? 'default' : 'outlined'}
            icon={<AssignmentIcon color='secondary' />}
            clickable
            label='Full access/Admin'
            color={props.permissionType === 'ROLE_ADMIN' ? 'primary' : 'default'}
            onClick={(e) => handlePermissionChange('ROLE_ADMIN')}
            id='full_access'
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Chip
            id='school_level_access'
            variant={props.permissionType === 'ROLE_SCHOOL_LEVEL_ACCESS' ? 'default' : 'outlined'}
            icon={<AssignmentIcon color='secondary' />}
            clickable
            label='School level access'
            color={props.permissionType === 'ROLE_SCHOOL_LEVEL_ACCESS' ? 'primary' : 'default'}
            onClick={(e) => handlePermissionChange('ROLE_SCHOOL_LEVEL_ACCESS')}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Chip
            id='read_only_access'
            variant={
              props.permissionType === 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS' ? 'default' : 'outlined'
            }
            icon={<AssignmentIcon color='secondary' />}
            clickable
            label='School level read only Access'
            color={
              props.permissionType === 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS' ? 'primary' : 'default'
            }
            onClick={(e) => handlePermissionChange('ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS')}
          />
        </Grid>
        <Grid item xs={12}>
          {!props.permissionType && props.proceed && (
            <p style={{ color: 'red' }}>* Please select permission / access for this user</p>
          )}
        </Grid>
      </Grid>
      <Grid container columnSpacing={4} rowSpacing={2} sx={{ py: 2, pl: 4 }}>
        <Grid item xs={12}>
          <AdditionalSubHeading subTitle={'Select Schools'} />
        </Grid>
        <ShowSchools />
        <Grid item xs={12}>
          {props.permittedSchools.length === 0 && props.proceed && (
            <p style={{ color: 'red' }}>* Please select at least one school</p>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
