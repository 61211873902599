import { Stack, TableCell } from "@mui/material";

export const TableCellCustom = ({ label, icon, id }) => {
  return (
    <TableCell align='left' id={id}>
      <Stack direction='row' alignItems={'center'}>
        <Stack mr={1}>{icon}</Stack>
        {label}
      </Stack>
    </TableCell>
  );
};
