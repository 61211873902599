import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// third-party imports
import { Field } from 'formik';

// custom components
import { SelectField } from 'components/Formik';
import { ClassificationHeading } from 'components/Headers';

// assets
import { Divider, InputAdornment, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import LabelIcon from '@mui/icons-material/Label';

// utils
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import useDebounce from 'components/search/useDebounce';
import SearchAutocomplete from 'components/search/AsyncSearch';

import { LocationIconStudent } from 'containers/layouts/Routes/Components/LocationIcon';
import { geoCode, reverseGeocode } from 'utils/mapapi';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import { inputVariantStudent } from 'constants';
import { TextField } from 'formik-mui';
// start icons for text fields

// ============================== BASIC DETAILS FORM - FIRST STEP  ============================== //
const _addressSwitch = {
  address: true,
  optional: false,
  optional2: false,
};

const FLAGS = {
  ADDRESSES_REQUIRED: 'ADDRESS_REQUIRED',
  ADDRESSES_OPTIONAL: 'ADDRESS_OPTIONAL',
  ADDRESSES_OPTIONAL2: 'ADDRESS_OPTIONAL2',
};
const initialLatLng = {
  label: '',
  value: { lng: '', lat: '' },
  type: '',
  allData: {},
};

const routeType = [
  { label: <i>None</i>, value: '' },
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
  { label: 'AM and PM both', value: 'am_pm' },
];
const routeTypeRequired = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
  { label: 'AM and PM both', value: 'am_pm' },
];

const InputProps = (icon) => {
  return {
    startAdornment: <InputAdornment position='start'>{icon}</InputAdornment>,
    readOnly: true,
  };
};
const AddressRouteForm = (props) => {
  const { formikProps } = props;
  const {
    address_search,
    address_search_optional,
    address_search_optional2,
    latitude,
    longitude,
    longitude_optional,
    latitude_optional,
  } = formikProps.values;
  const { setFieldValue, setValues } = formikProps;

  // for decide which address to show
  const [addressSwitch, setAddressSwitch] = useState(_addressSwitch);

  // for  Address Information
  const [searchValue, setSearchValue] = useState(address_search);
  const [searchedResults, setSearchedResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [getLatLng, setGetLatLng] = useState({
    ...initialLatLng,
    value: {
      lng: longitude,
      lat: latitude,
    },
  });

  // for optional Address State
  const [searchValueOptional, setSearchValueOptional] = useState(address_search_optional);
  const [searchValueOptional2, setSearchValueOptional2] = useState(address_search_optional2);
  const [searchedResultsOptional, setSearchedResultsOptional] = useState([]);
  const [searchedResultsOptional2, setSearchedResultsOptional2] = useState([]);
  const [searchLoadingOptional, setSearchLoadingOptional] = useState(false);
  const [searchLoadingOptional2, setSearchLoadingOptional2] = useState(false);
  const [getLatLngOptional, setGetLatLngOptional] = useState({
    ...initialLatLng,
    value: {
      lng: longitude_optional,
      lat: latitude_optional,
    },
  });
  const [getLatLngOptional2, setGetLatLngOptional2] = useState({
    ...initialLatLng,
    value: {
      lng: longitude_optional,
      lat: latitude_optional,
    },
  });

  const debouncedInputValue = useDebounce(searchValue, 600);
  const debouncedInputValueOptional = useDebounce(searchValueOptional, 600);
  const debouncedInputValueOptional2 = useDebounce(searchValueOptional2, 600);

  const handleInputChangeSearchAddress = (event) => {
    setAddressSwitch(_addressSwitch);
    setSearchLoading(true);
    setGetLatLng((pre) => {
      return { ...pre, type: 'search' };
    });
    const value = event.target.value;
    setSearchValue(value);
    if (value === '') {
      setTimeout(() => {
        setFieldValue('address_search', '');
      }, 50);
      setValues((pre) => {
        return {
          ...pre,
          latitude: '',
          longitude: '',
          address_apt_plot: '',
          address_street: '',
          address_city: '',
          address_state: '',
          address_zip_code: '',
          address_country: '',
          // address_search: address?.display_name,
        };
      });
    }
    return value;
  };

  const handleInputChangeSearchAddressOptional = (event) => {
    setAddressSwitch({
      address: false,
      optional: true,
      optional2: false,
    });
    setSearchLoadingOptional(true);
    setGetLatLngOptional((pre) => {
      return { ...pre, type: 'search' };
    });
    const value = event.target.value;
    setSearchValueOptional(value);
    if (value === '') {
      setTimeout(() => {
        setFieldValue('address_search_optional', '');
      }, 50);

      setValues((pre) => {
        return {
          ...pre,
          latitude_optional: '',
          longitude_optional: '',
          address_apt_plot_optional: '',
          address_street_optional: '',
          address_city_optional: '',
          address_state_optional: '',
          address_zip_code_optional: '',
          address_country_optional: '',
        };
      });
    }
    return value;
  };
  const handleInputChangeSearchAddressOptional2 = (event) => {
    setAddressSwitch({
      address: false,
      optional: false,
      optional2: true,
    });
    setSearchLoadingOptional2(true);
    setGetLatLngOptional2((pre) => {
      return { ...pre, type: 'search' };
    });
    const value = event.target.value;
    setSearchValueOptional2(value);
    if (value === '') {
      setTimeout(() => {
        setFieldValue('address_search_optional2', '');
      }, 50);

      setValues((pre) => {
        return {
          ...pre,
          latitude_optional2: '',
          longitude_optional2: '',
          address_apt_plot_optional2: '',
          address_street_optional2: '',
          address_city_optional2: '',
          address_state_optional2: '',
          address_zip_code_optional2: '',
          address_country_optional2: '',
        };
      });
    }
    return value;
  };

  const getSearchResults = async (
    value,
    setLoading,
    setSearchedRes,
    setSearchValue,
    searchValue,
    setLatLng,
    flag,
  ) => {
    try {
      const data = await geoCode(value);
      const display_data = data.map((item) => {
        return {
          label: item.display_name,
          value: { lat: item.lat, lng: item.lon },
          allData: { ...item },
        };
      });

      setSearchedRes(display_data);
      setLoading(false);

      const initialValue = JSON.parse(searchValue) || '';
      if (!initialValue || initialValue === '') return;
      if (Array.isArray(initialValue)) {
        setSearchValue(data[0]?.display_name);

        if (flag === FLAGS.ADDRESSES_REQUIRED) {
          setFieldValue('address_search', data[0]?.display_name);
        }
        if (flag === FLAGS.ADDRESSES_OPTIONAL) {
          setFieldValue('address_search_optional', data[0]?.display_name);
        }
        if (flag === FLAGS.ADDRESSES_OPTIONAL2) {
          setFieldValue('address_search_optional2', data[0]?.display_name);
        }
      }

      // setting data in formik call api reverse geocode
      // const getReverseGeocode = await reverseGeocode(lat, lng)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    const value = debouncedInputValueOptional?.replace(/\s+/g, '+');
    if (getLatLngOptional.type === 'dragend') return;
    getSearchResults(
      value,
      setSearchLoadingOptional,
      setSearchedResultsOptional,
      setSearchValueOptional,
      searchValueOptional,
      setGetLatLngOptional,
      FLAGS.ADDRESSES_OPTIONAL,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValueOptional]);

  useMemo(() => {
    const value = debouncedInputValueOptional2?.replace(/\s+/g, '+');
    if (getLatLngOptional2.type === 'dragend') return;
    getSearchResults(
      value,
      setSearchLoadingOptional2,
      setSearchedResultsOptional2,
      setSearchValueOptional2,
      searchValueOptional2,
      setGetLatLngOptional2,
      FLAGS.ADDRESSES_OPTIONAL2,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValueOptional2]);

  useMemo(() => {
    const value = debouncedInputValue?.replace(/\s+/g, '+');
    if (getLatLng.type === 'dragend') return;
    getSearchResults(
      value,
      setSearchLoading,
      setSearchedResults,
      setSearchValue,
      searchValue,
      setGetLatLng,
      FLAGS.ADDRESSES_REQUIRED,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  const _getLatLng = () => {
    if (addressSwitch.address) {
      return getLatLng;
    } else if (addressSwitch.optional) {
      return getLatLngOptional;
    } else if (addressSwitch.optional2) {
      return getLatLngOptional2;
    }
  };

  const _setGetLatLng = () => {
    if (addressSwitch.address) {
      return setGetLatLng;
    } else if (addressSwitch.optional) {
      return setGetLatLngOptional;
    } else if (addressSwitch.optional2) {
      return setGetLatLngOptional2;
    }
  };

  const _setSearchValue = () => {
    if (addressSwitch.address) {
      return setSearchValue;
    } else if (addressSwitch.optional) {
      return setSearchValueOptional;
    } else if (addressSwitch.optional2) {
      return setSearchValueOptional2;
    }
  };

  const _setSearchLoading = () => {
    if (addressSwitch.address) {
      return setSearchLoading;
    } else if (addressSwitch.optional) {
      return setSearchLoadingOptional;
    } else if (addressSwitch.optional2) {
      return setSearchLoadingOptional2;
    }
  };

  const _searchValue = () => {
    if (addressSwitch.address) {
      return searchValue;
    } else if (addressSwitch.optional) {
      return searchValueOptional;
    } else if (addressSwitch.optional2) {
      return searchValueOptional2;
    }
  };

  return (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Stack id='address-information' width={'39%'}>
        <StudentAddress
          setGetLatLng={setGetLatLng}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchLoading={searchLoading}
          handleInputChangeSearch={handleInputChangeSearchAddress}
          searchedResults={searchedResults}
          setAddressSwitch={setAddressSwitch}
          {...props}
        />
        <StudentAddressOptional
          setGetLatLng={setGetLatLngOptional}
          searchValue={searchValueOptional}
          setSearchValue={setSearchValueOptional}
          searchLoading={searchLoadingOptional}
          handleInputChangeSearch={handleInputChangeSearchAddressOptional}
          searchedResults={searchedResultsOptional}
          setAddressSwitch={setAddressSwitch}
          {...props}
        />
        <StudentAddressOptional2
          setGetLatLng={setGetLatLngOptional2}
          searchValue={searchValueOptional2}
          setSearchValue={setSearchValueOptional2}
          searchLoading={searchLoadingOptional2}
          handleInputChangeSearch={handleInputChangeSearchAddressOptional2}
          searchedResults={searchedResultsOptional2}
          setAddressSwitch={setAddressSwitch}
          {...props}
        />
      </Stack>
      <Stack id='address-map' width={'60%'}>
        <MainMapContainer
          getLatLng={_getLatLng()}
          setGetLatLng={_setGetLatLng()}
          setSearchValue={_setSearchValue()}
          searchValue={_searchValue()}
          setSearchLoading={_setSearchLoading()}
          addressSwitch={addressSwitch}
          {...props}
        />
      </Stack>
    </Stack>
  );
};

const StudentAddress = ({
  setGetLatLng,
  searchValue,
  setSearchValue,
  searchLoading,
  handleInputChangeSearch,
  searchedResults,
  setAddressSwitch,
  ...props
}) => {
  const { formikProps } = props;
  const { errors } = formikProps;
  const handleListItemClick = async (event, option, props) => {
    const { setValues, setFieldValue } = formikProps;
    setFieldValue('address_search', option.label);
    setSearchValue(option.label);
    setGetLatLng((pre) => {
      return { ...pre, value: { ...option.value } };
    });
    const { lat, lng } = option.value;
    try {
      const getReverseGeocode = await reverseGeocode(lat, lng);
      const { address, lat: _let, lon: _lng } = getReverseGeocode;

      setValues((pre) => {
        return {
          ...pre,
          latitude: _let,
          longitude: _lng,
          address_apt_plot: address?.house_number || address?.Rowlandton,
          address_street: address?.road,
          address_city: address?.city,
          address_state: address?.state,
          address_zip_code: address?.postcode,
          address_country: address?.country,
          // address_search: address?.display_name,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack mb={2}>
      <ClassificationHeading>Address Information</ClassificationHeading>
      <Stack mt={3}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Stack width={'50%'}>
            <Field
              fullWidth
              component={TextField}
              label='Label *'
              name='address_label'
              variant={inputVariantStudent}
              InputProps={InputProps(<LabelIcon />)}
              readOnly={true}
              id='address_label'
              value={'Default Address'}
              helperText='Enter label'
              size='small'
            />
          </Stack>
          <Stack width={'50%'}>
            <Field
              component={SelectField}
              label='Preferred Route Type *'
              name='address_route_type'
              Icon={AltRouteIcon}
              data={routeTypeRequired}
              async={false}
              id='address_route_type'
              helperText='Select route type'
              selectFieldProps={{
                variant: inputVariantStudent,
                size: 'small',
              }}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack pt={3}>
          <SearchAutocomplete
            handleInputChangeSearch={handleInputChangeSearch}
            searchValue={searchValue}
            searchedResults={searchedResults}
            searchLoading={searchLoading}
            setSearchValue={setSearchValue}
            setSelectedValue={setGetLatLng}
            handleListItemClick={handleListItemClick}
            textFieldProps={{
              size: 'small',
              error: errors?.address_search ? true : false,
              onFocus: () => setAddressSwitch(_addressSwitch),
              label: 'Search Address *',
              helperText: (
                <div>
                  {errors?.address_search
                    ? `${errors.address_search} or drop marker correctly`
                    : 'Search address'}
                </div>
              ),
            }}
          />
          {/* <Field
            fullWidth
            component={SearchAutocompleteFormik}
            label='Select Address *'
            name='student_address'
            variant={'outlined'}
            InputProps={notesInputProps}
            id='student_address'
            helperText='Select address'
            size='small'
          /> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

const StudentAddressOptional = ({
  setGetLatLng,
  searchValue,
  setSearchValue,
  searchLoading,
  handleInputChangeSearch,
  searchedResults,
  setAddressSwitch,
  ...props
}) => {
  const { formikProps } = props;
  const { errors } = formikProps;
  const handleListItemClick = async (event, option, props) => {
    setSearchValue(option.label);
    setGetLatLng((pre) => {
      return { ...pre, value: { ...option.value } };
    });
    const { lat, lng } = option.value;
    const { setValues, setFieldValue } = formikProps;
    setTimeout(() => {
      setFieldValue('address_search_optional', option.label);
    }, 50);
    try {
      const getReverseGeocode = await reverseGeocode(lat, lng);
      const { address, lat: _let, lon: _lng } = getReverseGeocode;
      setValues((pre) => {
        return {
          ...pre,
          latitude_optional: _let,
          longitude_optional: _lng,
          address_apt_plot_optional: address?.house_number || address?.Rowlandton,
          address_street_optional: address?.road,
          address_city_optional: address?.city,
          address_state_optional: address?.state,
          address_zip_code_optional: address?.postcode,
          address_country_optional: address?.country,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Stack>
      <Divider sx={{ mb: 2 }} />
      <Stack mt={3}>
        <Stack direction={'row'} spacing={2} width={'100%'}>
          <Stack width={'50%'}>
            <Field
              fullWidth
              component={TextField}
              label='Label '
              name='address_label_optional'
              variant={inputVariantStudent}
              InputProps={InputProps(<LabelIcon />)}
              id='address_label_optional'
              value={'Father Address'}
              helperText='Enter label'
              size='small'
            />
          </Stack>
          <Stack width={'50%'}>
            <Field
              component={SelectField}
              label='Preferred Route Type'
              name='address_route_type_optional'
              Icon={AltRouteIcon}
              data={routeType}
              async={false}
              id='address_route_type_optional'
              helperText='Select route type'
              selectFieldProps={{
                variant: inputVariantStudent,
                size: 'small',
              }}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack pt={3}>
          <SearchAutocomplete
            handleInputChangeSearch={handleInputChangeSearch}
            searchValue={searchValue}
            searchedResults={searchedResults}
            searchLoading={searchLoading}
            setSearchValue={setSearchValue}
            setSelectedValue={setGetLatLng}
            handleListItemClick={handleListItemClick}
            textFieldProps={{
              size: 'small',
              helperText: (
                <div>
                  {errors?.address_search_optional
                    ? `${errors.address_search_optional} or drop marker correctly`
                    : 'Search address'}
                </div>
              ),
              error: errors?.address_search_optional ? true : false,
              label: 'Search Address',
              onFocus: () =>
                setAddressSwitch({
                  address: false,
                  optional: true,
                  optional2: false,
                }),
            }}
          />
          {/* <Field
            fullWidth
            component={TextField}
            label='Select Address *'
            name='student_address_optional'
            variant={'outlined'}
            InputProps={notesInputProps}
            id='student_address_optional'
            helperText='Select address'
            size='small'
          /> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

const StudentAddressOptional2 = ({
  setGetLatLng,
  searchValue,
  setSearchValue,
  searchLoading,
  handleInputChangeSearch,
  searchedResults,
  setAddressSwitch,
  ...props
}) => {
  const { formikProps } = props;
  const { errors } = formikProps;
  const handleListItemClick = async (event, option, props) => {
    setSearchValue(option.label);
    setGetLatLng((pre) => {
      return { ...pre, value: { ...option.value } };
    });
    const { lat, lng } = option.value;
    const { setValues, setFieldValue } = formikProps;
    setTimeout(() => {
      setFieldValue('address_search_optional2', option.label);
    }, 50);
    try {
      const getReverseGeocode = await reverseGeocode(lat, lng);
      const { address, lat: _let, lon: _lng } = getReverseGeocode;
      setValues((pre) => {
        return {
          ...pre,
          latitude_optional2: _let,
          longitude_optional2: _lng,
          address_apt_plot_optional2: address?.house_number || address?.Rowlandton,
          address_street_optional2: address?.road,
          address_city_optional2: address?.city,
          address_state_optional2: address?.state,
          address_zip_code_optional2: address?.postcode,
          address_country_optional2: address?.country,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Stack>
      <Divider sx={{ mb: 2 }} />
      <Stack mt={3}>
        <Stack direction={'row'} spacing={2} width={'100%'}>
          <Stack width={'50%'}>
            <Field
              fullWidth
              component={TextField}
              label='Label '
              name='address_label_optional2'
              variant={inputVariantStudent}
              InputProps={InputProps(<LabelIcon />)}
              id='address_label_optional2'
              value={'Mother Address'}
              helperText='Enter label'
              size='small'
            />
          </Stack>
          <Stack width={'50%'}>
            <Field
              component={SelectField}
              label='Preferred Route Type'
              name='address_route_type_optional2'
              Icon={AltRouteIcon}
              data={routeType}
              async={false}
              id='address_route_type_optional2'
              helperText='Select route type'
              selectFieldProps={{
                variant: inputVariantStudent,
                size: 'small',
              }}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack pt={3}>
          <SearchAutocomplete
            handleInputChangeSearch={handleInputChangeSearch}
            searchValue={searchValue}
            searchedResults={searchedResults}
            searchLoading={searchLoading}
            setSearchValue={setSearchValue}
            setSelectedValue={setGetLatLng}
            handleListItemClick={handleListItemClick}
            textFieldProps={{
              size: 'small',
              helperText: (
                <div>
                  {errors?.address_search_optional
                    ? `${errors.address_search_optional} or drop marker correctly`
                    : 'Search address'}
                </div>
              ),
              error: errors?.address_search_optional ? true : false,
              label: 'Search Address',
              onFocus: () =>
                setAddressSwitch({
                  address: false,
                  optional: false,
                  optional2: true,
                }),
            }}
          />
          {/* <Field
            fullWidth
            component={TextField}
            label='Select Address *'
            name='student_address_optional'
            variant={'outlined'}
            InputProps={notesInputProps}
            id='student_address_optional'
            helperText='Select address'
            size='small'
          /> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

const MainMapContainer = ({
  getLatLng,
  setGetLatLng,
  setSearchValue,
  setSearchLoading,
  addressSwitch,
  ...props
}) => {
  return (
    <Stack
      width={'100%'}
      height={'100%'}
      spacing={2}
      // boxShadow={3}
      p={2}
      bgcolor={'secondary.lighter'}
    >
      <Alert severity='info' color='primary'>
        "Drag our marker or click to choose your precise location effortlessly on our user-friendly
        map interface.
      </Alert>
      <StreetMap
        getLatLng={getLatLng}
        setGetLatLng={setGetLatLng}
        setSearchValue={setSearchValue}
        setSearchLoading={setSearchLoading}
        addressSwitch={addressSwitch}
        {...props}
      />
    </Stack>
  );
};

const StreetMap = ({
  getLatLng,
  setGetLatLng,
  setSearchValue,
  searchValue,
  setSearchLoading,
  addressSwitch,
  ...props
}) => {
  const { formikProps } = props;
  const { value /* type */ } = getLatLng;
  const { lat, lng } = value;
  const initialLng = props?.selectedSchoolData?.geo_location?.latitude || 48.15091896593926;
  const initialLat = props?.selectedSchoolData?.geo_location?.longitude || -122.20859547801348;

  const { setValues, setFieldValue } = formikProps;
  const handleDragEnd = useCallback(
    async (event) => {
      const { target, type } = event;
      const { _latlng } = target;
      setGetLatLng((prevLatLng) => ({
        value: { lat: _latlng.lat, lng: _latlng.lng },
        type,
      }));
      try {
        const { lat, lng } = _latlng;
        const getReverseGeocode = await reverseGeocode(lat, lng);
        setSearchValue(getReverseGeocode.display_name);
        if (addressSwitch.address === true) {
          setTimeout(() => {
            setFieldValue('address_search', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude: _let,
              longitude: _lng,
              address_apt_plot: address?.house_number || address?.Rowlandton,
              address_street: address?.road,
              address_city: address?.city,
              address_state: address?.state,
              address_zip_code: address?.postcode,
              address_country: address?.country,
            };
          });
          return;
        }
        if (addressSwitch.optional === true) {
          setTimeout(() => {
            setFieldValue('address_search_optional', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude_optional: _let,
              longitude_optional: _lng,
              address_apt_plot_optional: address?.house_number || address?.Rowlandton,
              address_street_optional: address?.road,
              address_city_optional: address?.city,
              address_state_optional: address?.state,
              address_zip_code_optional: address?.postcode,
              address_country_optional: address?.country,
            };
          });
          return;
        }
        if (addressSwitch.optional2 === true) {
          setTimeout(() => {
            setFieldValue('address_search_optional2', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude_optional2: _let,
              longitude_optional2: _lng,
              address_apt_plot_optional2: address?.house_number || address?.Rowlandton,
              address_street_optional2: address?.road,
              address_city_optional2: address?.city,
              address_state_optional2: address?.state,
              address_zip_code_optional2: address?.postcode,
              address_country_optional2: address?.country,
            };
          });
          return;
        }
        setSearchLoading(false);
      } catch (error) {
        console.error(error);
        setSearchLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLatLng.value, setGetLatLng, setSearchLoading, setSearchValue],
  );

  return (
    <MapContainer
      center={[Number(lat) || Number(initialLng), Number(lng) || Number(initialLat)]}
      zoom={15}
      scrollWheelZoom={true}
      style={{
        height: '420px',
        width: '100%',
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {searchValue && (
        <Marker
          position={[Number(lat) || Number(initialLng), Number(lng) || Number(initialLat)]}
          draggable={true}
          icon={LocationIconStudent}
          eventHandlers={{
            dragend: handleDragEnd,
          }}
        ></Marker>
      )}
      <ChangeMapView
        position={[Number(lat) || Number(initialLng), Number(lng) || Number(initialLat)]}
        setGetLatLng={setGetLatLng}
        setSearchValue={setSearchValue}
        formikProps={formikProps}
        addressSwitch={addressSwitch}
        setSearchLoading={setSearchLoading}
      />
    </MapContainer>
  );
};
const ChangeMapView = ({
  position,
  setGetLatLng,
  setSearchValue,
  formikProps,
  addressSwitch,
  setSearchLoading,
}) => {
  const { setValues, setFieldValue } = formikProps;
  const _map = useMap();
  _map.setView(position);

  // enable click event in future
  useMapEvents({
    async click(e) {
      const { latlng, type } = e;

      setGetLatLng((prevLatLng) => ({
        value: { lat: latlng.lat, lng: latlng.lng },
        type,
      }));
      try {
        const { lat, lng } = latlng;
        const getReverseGeocode = await reverseGeocode(lat, lng);
        setSearchValue(getReverseGeocode.display_name);

        if (addressSwitch.address === true) {
          setTimeout(() => {
            setFieldValue('address_search', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude: _let,
              longitude: _lng,
              address_apt_plot: address?.house_number || address?.Rowlandton,
              address_street: address?.road,
              address_city: address?.city,
              address_state: address?.state,
              address_zip_code: address?.postcode,
              address_country: address?.country,
            };
          });
          return;
        }
        if (addressSwitch.optional === true) {
          setTimeout(() => {
            setFieldValue('address_search_optional', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude_optional: _let,
              longitude_optional: _lng,
              address_apt_plot_optional: address?.house_number || address?.Rowlandton,
              address_street_optional: address?.road,
              address_city_optional: address?.city,
              address_state_optional: address?.state,
              address_zip_code_optional: address?.postcode,
              address_country_optional: address?.country,
            };
          });
          return;
        }
        if (addressSwitch.optional2 === true) {
          setTimeout(() => {
            setFieldValue('address_search_optional2', getReverseGeocode.display_name);
          }, 50);
          const { address, lat: _let, lon: _lng } = getReverseGeocode;
          setValues((pre) => {
            return {
              ...pre,
              latitude_optional2: _let,
              longitude_optional2: _lng,
              address_apt_plot_optional2: address?.house_number || address?.Rowlandton,
              address_street_optional2: address?.road,
              address_city_optional2: address?.city,
              address_state_optional2: address?.state,
              address_zip_code_optional2: address?.postcode,
              address_country_optional2: address?.country,
            };
          });
          return;
        }
        setSearchLoading(false);
      } catch (error) {
        console.error(error);
        setSearchLoading(false);
      }
    },
  });
  return null;
};

AddressRouteForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
  amRoutes: PropTypes.oneOfType([undefined, PropTypes.array]),
  pmRoutes: PropTypes.oneOfType([undefined, PropTypes.array]),
};

export default AddressRouteForm;
