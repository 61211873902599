import React, { useState } from 'react';

// material-ui
import {
  Grid,
  Table,
  TableBody,
  TablePagination,
  TableCell,
  Skeleton,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// components
import MainCard from 'components/MainCard';

// Third Party
import NoRecordDisplay from 'components/NoRecordDisplay';
import { objToArray } from 'pages/Communication/utils/utils';

export default function DeviceTable(props) {
  // All Necessary Data to Show Table Data is coming from props ( Main Vehicle Page )
  const {
    mainTableData,
    filledRows,
    searchedTerm,
    tableHeadingData,
    isDataLoading,
    page,
    setPage,
    nextFetchingData,
    setFilledRows,
    handleRowClick,
    selectedSectionData,
    idForBackgroundSelection,
    tablerowDataFunction,
    nextToken,
    isFromSearch,
    setSearchedTerm,
    handleOpenQrCodeDialog,
  } = props;

  // For Handling Pagination
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState(1);
  const [order, setOrder] = useState('asc');

  // Empty Array to manage row data length as twenty
  const emptyArray = new Array(20).fill(0);

  // Handle Change for Handling Pagination
  const handleChangePage = (event, newPage) => {
    if (newPage > page && filledRows.length / (newPage * 20) === 1) {
      // nextFetchingData is used for student table for calling next page data from api
      nextFetchingData();
      setPage(newPage);
    }
    setPage(newPage);
  };

  // For Handling RowPerChange
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(1);
  };

  const requestSearch = (searchedVal) => {
    setPage(0);
    const searchVal = searchedVal.target.value.toLowerCase();
    if (searchedVal.target.value === '') {
      setFilledRows(mainTableData);
    }
    setSearchedTerm(searchedVal.target.value);
    setFilledRows((data) => {
      return data.filter((data) => {
        const _single_data = JSON.stringify(objToArray(data)).toLocaleLowerCase();
        const isIncludeData = _single_data.includes(searchVal);
        return isIncludeData;
      });
    });
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filledRows.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedArray = stableSort(mainTableData, getComparator(order, orderBy));
    setFilledRows([...sortedArray]);
  };

  return (
    <>
      <MainCard content={false}>
        <Grid container>
          {!isFromSearch && (
            <>
              <Grid xs={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  spacing={3}
                >
                  <Grid item xs={3} sx={{ mr: 3 }}>
                    <TextField
                      id='search'
                      label='Search'
                      value={searchedTerm}
                      onChange={requestSearch}
                      fullWidth='true'
                      variant='standard'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton size='large'>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <TableContainer>
            <Table aria-label='simple table'>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                tableHeadingData={tableHeadingData}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {isDataLoading &&
                  emptyArray.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={tableHeadingData.length}>
                        <Skeleton animation='wave' variant='text' />
                      </TableCell>
                    </TableRow>
                  ))}
                {/* Showing Table Data Row  */}
                {!isDataLoading && filledRows.length > 0 ? (
                  (rowsPerPage > 0
                    ? filledRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : filledRows
                  ).map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      onClick={(event) => handleRowClick(event, row, index)}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: selectedSectionData
                          ? selectedSectionData[idForBackgroundSelection] ===
                            row[idForBackgroundSelection]
                            ? 'background.default'
                            : 'inherit'
                          : 'inherit',
                      }}
                    >
                      {/* tablerowDataFunction is function prop from we are getting all table row data display section ( we are calling it from 
                      main page because we were facing some difficulties to make it custom for each component ) */}
                      {tablerowDataFunction(row, index, handleOpenQrCodeDialog)}
                    </TableRow>
                  ))
                ) : !isDataLoading && filledRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableHeadingData.length}
                      align='center'
                      id='no_records'
                      sx={{ border: 'none' }}
                    >
                      <NoRecordDisplay />
                    </TableCell>
                  </TableRow>
                ) : null}
                {!isDataLoading && emptyRows > 0 && (
                  <TableRow sx={{ height: 50 * emptyRows, borderColor: '#fff' }}>
                    <TableCell colSpan={tableHeadingData.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component='div'
              rowsPerPage={rowsPerPage}
              page={page}
              count={
                nextToken || mainTableData.length - (page + 1) * 20 > 0
                  ? filledRows.length + 1
                  : filledRows.length
              }
              onPageChange={handleChangePage}
              labelDisplayedRows={({ from }) =>
                `${from}-${Math.min((page + 1) * 20, filledRows.length)}`
              }
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

function EnhancedTableHead({ order, orderBy, onRequestSort, tableHeadingData }) {
  return (
    <TableHead>
      <TableRow>
        {tableHeadingData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : true}
            width={'10rem'}
          >
            {headCell.headerName}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
};
