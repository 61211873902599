import React from 'react';
import Chip from '@mui/material/Chip';

export default function RouteTypeChip({ routeType = '--' }) {
  return (
    <Chip
      label={routeType}
      color={routeType === 'AM' ? 'primary' : 'secondary'}
      variant='outlined'
      size='small'
    />
  );
}
