import L from 'leaflet';

export const LocationIcon = new L.DivIcon({
  iconSize: [30, 35],
  iconAnchor: [19, 34], //[left/right, top/bottom]
  // popupAnchor: [0, -46], //[left/right, top/bottom]
  className: 'my-custom-marker-class',
  html: `
  <div>
    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1C10.49 1 6 5.5 6 11.04C6 16.29 14.34 24.76 15.29 25.71C15.49 25.9 15.74 26 16 26C16.26 26 16.51 25.9 16.71 25.71C17.66 24.76 26 16.29 26 11.04C26 5.5 21.51 1 16 1Z" fill="#E5B300"/>
        <path d="M20 11.04C20 13.24 18.21 15.04 16 15.04C13.79 15.04 12 13.24 12 11.04C12 8.83004 13.79 7.04004 16 7.04004C18.21 7.04004 20 8.83004 20 11.04Z" fill="white"/>
        <path d="M16 30.9994C15.7646 30.9994 15.5405 30.9989 15.3057 30.9887C8.21 30.8646 1 28.5951 1 24.4994C1 22.4188 2.9492 20.6122 6.4893 19.412C7.0118 19.2372 7.5796 19.515 7.7574 20.0385C7.9346 20.5614 7.6544 21.1288 7.1309 21.3066C4.583 22.1703 3 23.3934 3 24.4994C3 26.5209 8.0791 28.8617 15.3677 28.9896C15.5986 28.9994 15.7925 29.0004 16 28.9994C16.208 28.9994 16.4009 28.9994 16.6055 28.9906C23.9209 28.8617 29 26.5209 29 24.4994C29 23.3934 27.417 22.1703 24.8691 21.3065C24.3457 21.1288 24.0654 20.5614 24.2426 20.0384C24.4203 19.515 24.9877 19.2362 25.5107 19.4119C29.0508 20.6122 31 22.4188 31 24.4994C31 28.5951 23.79 30.8646 16.6675 30.9896C16.4595 30.9989 16.2354 30.9994 16 30.9994Z" fill="#E5B300"/>
    </svg>
  </div>`, // SVG image wrapped in a div
});

export const LocationIconStudent = new L.DivIcon({
  iconSize: [30, 35],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
  className: 'my-custom-marker-class',
  html: `
  <div>
  <svg width="23" height="37" viewBox="0 0 23 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_2)">
  <path d="M11.5001 -0.0783997C5.17934 -0.0783997 0.0574951 5.11774 0.0574951 11.5331C0.0574951 22.622 11.5001 37.0784 11.5001 37.0784C11.5001 37.0784 22.9427 22.622 22.9427 11.5331C22.9427 5.11774 17.8208 -0.0783997 11.5001 -0.0783997ZM11.5001 17.1647C8.50756 17.1647 6.09053 14.7069 6.09053 11.6783C6.09053 8.64958 8.5172 6.1821 11.5001 6.1821C14.4926 6.1821 16.9096 8.63986 16.9096 11.6783C16.9096 14.7069 14.4926 17.1647 11.5001 17.1647Z" fill="#AE8400"/>
  <path d="M11.4605 19.8142C15.8645 19.8142 19.4347 16.2125 19.4347 11.7696C19.4347 7.32663 15.8645 3.72491 11.4605 3.72491C7.05652 3.72491 3.48637 7.32663 3.48637 11.7696C3.48637 16.2125 7.05652 19.8142 11.4605 19.8142Z" fill="white" stroke="#424242"/>
  <path d="M11.4604 11.7695C12.6309 11.7695 13.579 10.813 13.579 9.63215C13.579 8.45134 12.6309 7.4949 11.4604 7.4949C10.2899 7.4949 9.34185 8.45134 9.34185 9.63215C9.34185 10.813 10.2899 11.7695 11.4604 11.7695ZM11.4604 12.8381C10.0463 12.8381 7.22333 13.5541 7.22333 14.9754V16.044H15.6975V14.9754C15.6975 13.5541 12.8745 12.8381 11.4604 12.8381Z" fill="#424242"/>
  </g>
  <defs>
  <clipPath id="clip0_1_2">
  <rect width="23" height="37" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  </div>`, // SVG image wrapped in a div
});
