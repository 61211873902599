import React from 'react';
import TextField from '@mui/material/TextField';
import ValidatorComponent from './ValidatorComponent';

export default class NameTextField extends ValidatorComponent {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <TextField
                {...this.props}
                onChange={(e) => { 
                    this.validate(e.target.value);
                    this.props.onChange(e)
                 }}
                error={this.state.errorMessage != undefined || this.props.error}
                helperText={this.state.errorMessage || this.props.helperText}
            />
        );
    }
}