import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  FILTER_LIST_REQUEST,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_FAIL,
  SEARCH_INPUT_VALUE_CHANGE,
  UPDATE_DATA,
} from '../reduxConstants/searchConstants';

export const searchReducer = (
  state = {
    isLoading: false,
    foundSchools: [],
    childData: [],
    driverData: [],
    tripData: [],
    searchRoutesData: [],
    searchVehiclesData: [],
    query: '',
    foundSchoolsOptions: {},
    childDataOptions: {},
    driverDataOptions: {},
    tripDataOptions: {},
    searchRoutesDataOptions: {},
    searchVehiclesDataOptions: {},
  },
  action,
) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return { ...state, isLoading: true };
    case SEARCH_SUCCESS:
      if (action.isFilter) {
        const { data, ...rest } = action.payload;
        return {
          ...state,
          isLoading: false,
          [action.reducerKey]: action.payload.data,
          [action.reducerKey + 'Options']: rest,
          query: action.payload.query,
        };
      }
      if (action.isPagination) {
        const { data, ...rest } = action.payload;
        return {
          ...state,
          isLoading: false,
          [action.reducerKey]: [...state[action.reducerKey], ...action.payload.data],
          [action.reducerKey + 'Options']: rest,
          query: action.payload.query,
        };
      }
      return {
        isLoading: false,
        foundSchools: action.payload[0].data,
        foundSchoolsOptions: action.payload[0],
        driverData: action.payload[1].data,
        driverDataOptions: action.payload[1],
        searchRoutesData: action.payload[2].data,
        searchRoutesDataOptions: action.payload[2],
        searchVehiclesData: action.payload[3].data,
        searchVehiclesDataOptions: action.payload[3],
        childData: action.payload[4].data,
        childDataOptions: action.payload[4],
        tripData: action.payload[5].data,
        tripDataOptions: action.payload[5],
        query: action.payload[1].query,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        foundSchools: [],
        childData: [],
        driverData: [],
        tripData: [],
        searchRoutesData: [],
        searchVehiclesData: [],
      };
    case UPDATE_DATA:
      return {
        ...state,
        isLoading: false,
        [action.reducerKey]: action.payload.updatedData,
      }
    default:
      return state;
  }
};

export const filterListReducer = (
  state = {
    isLoading: false,
    tripFilter: null,
    schoolFilter: null,
    studentFilter: null,
    routeFilter: null,
    driverFilter: null,
    vehicleFilter: null,
  },
  action,
) => {
  switch (action.type) {
    case FILTER_LIST_REQUEST:
      return { ...state, isLoading: true };
    case FILTER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tripFilter: action.payload[0],
        schoolFilter: action.payload[1],
        studentFilter: action.payload[2],
        routeFilter: action.payload[3],
        driverFilter: action.payload[4],
        vehicleFilter: action.payload[5],
      };
    case FILTER_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        schoolFilter: {},
        tripFilter: {},
        studentFilter: {},
        routeFilter: {},
        driverFilter: {},
        vehicleFilter: {},
      };
    default:
      return state;
  }
};

export const searchInputValueReducer = (state = { inputValue: '' }, action) => {
  switch (action.type) {
    case SEARCH_INPUT_VALUE_CHANGE:
      return { ...state, inputValue: action.payload };
    default:
      return state;
  }
};
