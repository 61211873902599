import React from 'react';

// material-ui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { Typography, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CameraEnhanceOutlinedIcon from '@mui/icons-material//CameraEnhanceOutlined';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// project imports
import LoaderButton from '../../../../../components/Loader/LoaderButton';

const validationSchema = yup.object({
  licenseNumber: yup
    .string('Enter your license number')
    .required('license number is required')
    .trim()
    .min(3, 'License number must be at least 3 characters'),
  modelNumber: yup
    .string('Enter your Model Number')
    .required('Model Number is required')
    .trim()
    .min(2, 'Model number must be at least 2 characters'),
  manufacturingCompany: yup
    .string('Manufacturing Company is not valid')
    .required('Manufacturing Company is required')
    .trim()
    .min(2, 'Manufacturing Company name must be at least 2 characters'),

  capacity: yup
    .number()
    .typeError('Enter only numbers')
    .required('Seating capacity is required')
    .min(1, 'Capacity of a vehicle must be at least 1 seat'),

  transportationOrganization: yup
    .string('Enter your Transportation Organization')
    .required('Transportation Organization is required')
    .trim()
    .min(2, 'Transportation organization name must be at least 2 characters'),

  disableAccessibility: yup
    .string('Please Select Disable Accessibility')
    .required('Disable Accessibility is required'),
  trackingNavigation: yup
    .string('Please Select Tracking Navigation')
    .required('Tracking Navigation is required'),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function VehicleForm(props) {
  const classes = useStyles();

  const clearVehicleSurveillance = () => {
    setFront(false);
    setBack(false);
    setSide(false);
    setInternal(false);
    setNavigation('');
    setSeat('');
  };

  const [menuOpen, setMenuOpen] = React.useState('');

  const [seat, setSeat] = React.useState('');

  const [navigation, setNavigation] = React.useState('');

  const [front, setFront] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [side, setSide] = React.useState(false);
  const [internal, setInternal] = React.useState(false);

  const [disabledSeatCount, setDisabledSeatCount] = React.useState('');
  const [flag, setFlag] = React.useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const formSubmit = (values) => {
    props.setButtonLoader(true);
    props.handleSubmit(values);
    setFlag(false);
    setTimeout(function () {
      if (props.isApiSuccesfull) {
        formik.resetForm();
        clearVehicleSurveillance();
        props.setIsApiSuccesfull(false);
      }
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      licenseNumber: '',
      modelNumber: '',
      manufacturingCompany: '',
      modelYear: '',
      capacity: '',
      disabledSeating: '',
      transportationOrganization: '',
      fueltank: '',
      disableAccessibility: '',
      trackingNavigation: '',
      // model: undefined,
      // deviceId: undefined,
      installationDate: undefined,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (formik.values.trackingNavigation === true && props.disableSeating === true) {
        if (
          props.model &&
          props.model.trim().length > 0 &&
          props.deviceId &&
          props.deviceId.trim().length > 0 &&
          disabledSeatCount > 0
        ) {
          formSubmit(values);
        }
      } else if (formik.values.trackingNavigation === true && props.disableSeating === false) {
        if (
          props.model &&
          props.model.trim().length > 0 &&
          props.deviceId &&
          props.deviceId.trim().length > 0
        ) {
          formSubmit(values);
        }
      } else if (props.disableSeating === true && formik.values.trackingNavigation === false) {
        if (disabledSeatCount > 0) {
          formSubmit(values);
        }
      } else if (formik.values.trackingNavigation === false && props.disableSeating === false) {
        formSubmit(values);
      }
    },
  });

  const disableSeat = (event) => {
    setSeat(event.target.value);
  };

  const enableNavigation = (event) => {
    setNavigation(event.target.value);
  };

  function valuetext(value) {
    return `${value} Gallons`;
  }

  const fuelMarks = [
    {
      value: 10,
      label: '10 Gal',
    },
    {
      value: 100,
      label: '100 Gal',
    },
  ];

  const handleFront = () => {
    setFront(!front);
    props.setFront(!front);
  };

  const handleBack = () => {
    setBack(!back);
    props.setBack(!back);
  };

  const handleSide = () => {
    setSide(!side);
    props.setSide(!side);
  };

  const handleInternal = () => {
    setInternal(!internal);
    props.setInternal(!internal);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction='row' rowSpacing={4} spacing={3}>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            autoFocus
            id='licenseNumber'
            name='licenseNumber'
            label='License Plate Number *'
            variant='standard'
            // type='text'
            fullWidth
            value={formik.values.licenseNumber}
            onChange={formik.handleChange}
            error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
            helperText={formik.touched.licenseNumber && formik.errors.licenseNumber}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Manufacturing Company *'
            variant='standard'
            id='manufacturingCompany'
            name='manufacturingCompany'
            // type='manufacturingCompany'
            fullWidth
            value={formik.values.manufacturingCompany}
            onChange={formik.handleChange}
            error={
              formik.touched.manufacturingCompany && Boolean(formik.errors.manufacturingCompany)
            }
            helperText={formik.touched.manufacturingCompany && formik.errors.manufacturingCompany}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Transporation Organization *'
            variant='standard'
            id='transportationOrganization'
            name='transportationOrganization'
            // type='transportationOrganization'
            fullWidth
            value={formik.values.transportationOrganization}
            onChange={formik.handleChange}
            error={
              formik.touched.transportationOrganization &&
              Boolean(formik.errors.transportationOrganization)
            }
            helperText={
              formik.touched.transportationOrganization && formik.errors.transportationOrganization
            }
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            id='modelNumber'
            name='modelNumber'
            label='Model Number *'
            variant='standard'
            // type='modelNumber'
            fullWidth
            value={formik.values.modelNumber}
            onChange={formik.handleChange}
            error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
            helperText={formik.touched.modelNumber && formik.errors.modelNumber}
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat='yyyy'
              views={['year']}
              label='Model year *'
              variant='standard'
              fullWidth
              value={props.modelYear}
              onChange={props.setModelYear}
              disableFuture={true}
              inputProps={{ readOnly: true }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} variant='standard' fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            label='Seat Capacity *'
            variant='standard'
            id='capacity'
            name='capacity'
            type='number'
            fullWidth
            InputProps={{
              inputProps: {
                max: 100,
                min: 0,
              },
            }}
            value={formik.values.capacity}
            onChange={formik.handleChange}
            error={formik.touched.capacity && Boolean(formik.errors.capacity)}
            helperText={formik.touched.capacity && formik.errors.capacity}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.vehicleSurveillance}>
            <Typography color='#808080'>Vehicle Surveillance</Typography>
            <Button
              size='small'
              onClick={handleFront}
              variant={front ? 'contained' : 'outlined'}
              sx={{
                color: front ? 'primary' : 'secondary.main',
                borderColor: front ? 'primary' : 'secondary.main',
              }}
              startIcon={<CameraEnhanceOutlinedIcon />}
              className={classes.cameraButton}
              id='frontCamera'
            >
              Front cameras
            </Button>
            <Button
              size='small'
              onClick={handleBack}
              variant={back ? 'contained' : 'outlined'}
              sx={{
                color: back ? 'primary' : 'secondary.main',
                borderColor: back ? 'primary' : 'secondary.main',
              }}
              startIcon={<CameraEnhanceOutlinedIcon />}
              className={classes.cameraButton}
              id='backCamera'
            >
              Back cameras
            </Button>
            <Button
              size='small'
              onClick={handleSide}
              variant={side ? 'contained' : 'outlined'}
              sx={{
                color: side ? 'primary' : 'secondary.main',
                borderColor: side ? 'primary' : 'secondary.main',
              }}
              startIcon={<CameraEnhanceOutlinedIcon />}
              className={classes.cameraButton}
              id='sideCamera'
            >
              Side cameras
            </Button>
            <Button
              size='small'
              onClick={handleInternal}
              variant={internal ? 'contained' : 'outlined'}
              sx={{
                color: internal ? 'primary' : 'secondary.main',
                borderColor: internal ? 'primary' : 'secondary.main',
              }}
              startIcon={<CameraEnhanceOutlinedIcon />}
              className={classes.cameraButton}
              id='internalCamera'
            >
              Internal cameras
            </Button>
          </div>
        </Grid>

        <Grid item xs={4} className={classes.sliderGrid}>
          <Typography id='discrete-slider-custom' gutterBottom>
            Fuel capacity (Gallons)
          </Typography>
          <Slider
            value={props.fuel}
            onChange={props.handleFuelChange}
            label='Fuel Capacity (Gallons)'
            aria-labelledby='discrete-slider-custom'
            valueLabelDisplay='auto'
            step={10}
            marks={fuelMarks}
            min={10}
            max={100}
            className={classes.slider}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            select
            id='disableAccessibility'
            label='Disable Accessibility *'
            variant='standard'
            name='disableAccessibility'
            open={menuOpen}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
            fullWidth
            value={formik.values.disableAccessibility}
            onChange={(event) => {
              disableSeat(event, formik.values.disableAccessibility);
              formik.handleChange(event);
              props.seatsAvailable(event);
            }}
            error={
              formik.touched.disableAccessibility && Boolean(formik.errors.disableAccessibility)
            }
            helperText={formik.touched.disableAccessibility && formik.errors.disableAccessibility}
          >
            <MenuItem id='yes' value={true}>
              Yes
            </MenuItem>
            <MenuItem id='no' value={false}>
              No
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={seat ? false : true}
            style={{ display: 'block' }}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            id='disabledSeating'
            name='disabledSeating'
            label='Available Seats'
            variant='standard'
            type='number'
            value={formik.values.disabledSeating}
            fullWidth
            onChange={(event) => {
              setDisabledSeatCount(event.target.value);
              formik.handleChange(event);
            }}
            error={formik.touched.disabledSeating && Boolean(formik.errors.disabledSeating)}
            helperText={formik.touched.disabledSeating && formik.errors.disabledSeating}
          />
          {props.disableSeating === true && flag && (
            <FormHelperText style={{ color: 'red' }}>
              {disabledSeatCount === ''
                ? 'Seat count for disabled person is required'
                : disabledSeatCount !== '' && disabledSeatCount < 1
                ? 'Capacity for disabled in vehicle must be at least 1 seat'
                : null}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            id='trackingNavigation'
            label='Tracking and Navigation *'
            variant='standard'
            name='trackingNavigation'
            open={menuOpen}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
            fullWidth
            value={formik.values.trackingNavigation}
            onChange={(event) => {
              enableNavigation(event, formik.values.trackingNavigation);
              formik.handleChange(event);
              props.trackNavigate(event);
            }}
            error={formik.touched.trackingNavigation && Boolean(formik.errors.trackingNavigation)}
            helperText={formik.touched.trackingNavigation && formik.errors.trackingNavigation}
          >
            <MenuItem id='yes' value={true}>
              Yes
            </MenuItem>
            <MenuItem id='no' value={false}>
              No
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={navigation ? false : true}
            fullWidth
            open={menuOpen}
            label='Tracking Device Model'
            variant='standard'
            id='model'
            name='model'
            // type='model'
            value={props.model}
            onChange={(event) => props.setModel(event.target.value)}
          />
          {formik.values.trackingNavigation && flag && (
            <FormHelperText style={{ color: 'red' }}>
              {!props.model
                ? 'Traking device model is required'
                : props.model.trim().length < 1
                ? 'Traking device model number must be at least 1 characters'
                : null}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={navigation ? false : true}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            fullWidth
            open={menuOpen}
            label='Tracking Device ID'
            variant='standard'
            id='deviceId'
            name='deviceId'
            // type='deviceId'
            value={props.deviceId}
            onChange={(event) => props.setDeviceId(event.target.value)}
          />
          {formik.values.trackingNavigation && flag && (
            <FormHelperText style={{ color: 'red' }}>
              {!props.deviceId
                ? 'Traking device id is required'
                : props.deviceId.trim().length < 1
                ? 'Traking device id must be at least 1 characters'
                : null}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={navigation ? false : true}
              disableFuture={true}
              label='Installation Date'
              margin='none'
              id='date-picker-dialog'
              format='MM/dd/yyyy'
              value={props.installationDate}
              onChange={props.handleDateChange}
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(params) => <TextField variant='standard' {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid className={classes.saveButton} item xs={12}>
        <LoaderButton
          id={'vehicle_save'}
          buttonName={'Save'}
          icon={'save'}
          buttonLoader={props.buttonLoader}
          color={'primary'}
          disable={props.buttonLoader}
          click={() => setFlag(true)}
        />
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  vehicleSurveillance: {
    backgroundColor: '#f1f1f1',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    marginTop: 8,
  },
  cameraButton: {
    borderRadius: 25,
  },
  sliderGrid: {
    marginTop: 6,
  },
  slider: {
    marginBottom: 0,
  },
  saveButton: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
