import { API } from 'aws-amplify';

const API_ID = 'SpotBusAPI';
// Devices_Constants
const API_GET_DEVICES = '/dashboard/device';
const API_REGISTER_DEVICE = '/dashboard/device';
const API_UPDATE_DEVICE = '/dashboard/device';
const API_DELETE_DEVICE = '/dashboard/device';

const API_VEHICLE = '/dashboard/vehicle';

// vehicle

// Device

/**
 * Registers a device with the given request body.
 *
 * @param {Object} requestBody - The request body containing the device details.
 * @return {Promise} A promise that resolves with the result of the device registration.
 */
export function registerDevice(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_REGISTER_DEVICE, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('Device registered with ', requestBody, result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

/**
 * Retrieves all devices from the API.
 *
 * @return {Promise} A promise that resolves with the result of the API call.
 */
export function getAllDevices() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_DEVICES)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Updates a device with the given request body.
 *
 * @param {Object} req_body - The request body containing the device data.
 * @return {Promise} A promise that resolves with the updated device data or rejects with an error.
 */
export function updateDevice(req_body) {
  const id = req_body.id;
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_UPDATE_DEVICE + '/' + id, {
      body: req_body,
    })
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

/**
 * Deletes a device with the given id.
 *
 * @param {string} id - The id of the device to be deleted.
 * @return {Promise} A promise that resolves with the result of the deletion or rejects with an error.
 */
export function deleteDevice(id) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_DEVICE + '/' + id)
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

/**
 * Register a vehicle using the provided request body.
 *
 * @param {Object} requestBody - The request body for registering the vehicle
 * @return {Promise} A Promise that resolves with the result of the registration or rejects with the error response
 */
export function registerVehicle(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_VEHICLE, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

/**
 * Updates a vehicle with the given request body.
 *
 * @param {Object} req_body - The request body containing the vehicle information to be updated.
 * @param {string} req_body.id - The ID of the vehicle to be updated.
 * @return {Promise<Object>} A promise that resolves with the updated vehicle information if the update is successful, or rejects with an error response if the update fails.
 */
export function updateVehicle(req_body) {
  const id = req_body.vehicle_id;
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_VEHICLE + '/' + id, {
      body: req_body,
    })
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function deleteVehicle(id) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_VEHICLE + '/' + id)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
