import * as React from 'react';

//material ui
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//project imports
import MainCard from 'components/MainCard';
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';
import { AdditionalSubHeading } from 'components/Headers/AdditionalSubHeading';
import { Heading } from 'components/Headers/Heading';
import { CardHeading } from 'components/Headers/CardHeading';

export default function VehicleCard(props) {
  const ShowRoleType = (role) => {
    switch (role) {
      case 'ROLE_SUPER_ADMIN':
        return 'Full Access/Admin';
      case 'ROLE_ADMIN':
        return 'Full Access/Admin';
      case 'ROLE_SCHOOL_LEVEL_ACCESS':
        return 'School Level Access';
      case 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS':
        return 'School Level Read Only Access';
      default:
        break;
    }
  };

  return (
    <Grid mt={4}>
      <MainCard>
        <Grid container xs={12} sx={{ mb: -4 }}>
          <Grid container>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CardHeading title={props.vehicle.licence_plate_number} color='text' />
              </Grid>
              <Grid xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant='subtitle1' gutterBottom>
                      Model Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{ pl: 1 }}>
                    <Typography variant='body1' color='secondary.main'>
                      {props.vehicle.model_number}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container xs={12}>
                <Grid item xs={10}>
                  <Grid xs={12} container>
                    <Grid item xs={5}>
                      <Typography variant='subtitle1' gutterBottom>
                        Seating Capacity:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant='body1' color='secondary.main'>
                        {props.vehicle.seating_capacity}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', justifyContent: 'flex-end', borderWidth: '2px' }}
                >
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    onClick={() => props.handleEdit(props.vehicle)}
                    disabled={props.loadingData}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    color='error'
                    onClick={() => {
                      props.setOpen();
                      props.setDeleteId(props.vehicle.vehicle_id);
                    }}
                    disabled={props.loadingData}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
