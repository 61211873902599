import React, { useState } from 'react';

// material ui
import { Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import PersonIcon from '@mui/icons-material/Person';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';

// project imports
import UserCard from '../UserCard/UserCard';
import DeleteAlert from 'pages/GuidedWorkflow/Components/DeleteAlert';
import MainCard from 'components/MainCard';

export default function RoutesListSection(props) {
  const theme = useTheme();
  const [deleteId, setDeleteId] = useState(false);
  return (
    <Card
      sx={{
        height: '100%',
        // mx: 4,
        zIndex: 2,
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? 'secondary.lighter' : 'secondary.darker',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <CardContent>
        <Grid container>
          <Typography
            variant='h2'
            sx={{ py: 1, pl: 2, color: 'common.white', fontWeight: 300, mb: -4 }}
          >
            {props.mainheadingofList}
          </Typography>
          <Typography
            variant='h5'
            sx={{
              pt: 4,
              pl: 2,
              color: 'common.white',
              fontWeight: 400,
              fontStyle: 'italic',
            }}
          >
            {`(${props.guidedMainData?.length ? props.guidedMainData?.length : '- '} ${
              props.userListHeading
            }${props.guidedMainData?.length > 1 ? ' are' : ' is'} registered)`}
          </Typography>
        </Grid>
        <Scrollbars
          style={{ height: '60rem' }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          disableHorizontalScrolling
        >
          <Grid>
            {!props.guidedMainData || props.listLoader === true ? (
              <>
                <ListCardSkeleton />
              </>
            ) : props.guidedMainData.length > 0 ? (
              props.guidedMainData?.map((user) => (
                <UserCard
                  user={user}
                  key={user.route_id}
                  handleEdit={props.handleEdit}
                  setOpen={() => props.setOpenDeleteAlert(true)}
                  setDeleteId={setDeleteId}
                  cardForSection={props.cardForSection}
                  deleteIdforSection={user.route_id}
                  firstheadingName={user.route_id}
                  firstParagraphName={user.school_id}
                  firstParagraphIcon={<SchoolIcon color='secondary' />}
                  secondParagraphName={user.driver_name}
                  secondParagraphIcon={<PersonIcon color='secondary' />}
                  secondLineSecondsection={user.scheduled_start_time}
                  secondLineSecondsectionIcon={<AvTimerIcon color='secondary' />}
                  specificforRouteSectionAlignment={12}
                  specificforRouteSectionAvatar={false}
                  secondLineSecondsectionAlingnment={6}
                  secondParaSecondSectionNotForStudent={true}
                  listLoader={props.listLoader}
                  apiLoading={props.apiLoading}
                />
              ))
            ) : (
              <NoDataCard />
            )}
          </Grid>
        </Scrollbars>
      </CardContent>
      <DeleteAlert
        open={props.openDeleteAlert}
        setOpen={() => props.setOpenDeleteAlert(true)}
        setClose={() => props.setOpenDeleteAlert(false)}
        handleDelete={props.handleDelete}
        buttonLoader={props.deleteButtonLoader}
        deleteId={deleteId}
        section={props.deleteAlertText}
      />
    </Card>
  );
}

const skeletonCount = [1, 2, 3, 4, 5, 6, 7];

const ListCardSkeleton = () => {
  return (
    <React.Fragment>
      {skeletonCount.map((skeleton, index) => {
        return (
          <MainCard content={false} key={index} sx={{ padding: 2, mb: 3, py: 4 }}>
            <Grid container spacing={3} sx={{ px: 6 }}>
              <Typography component='div' variant='h2' width='60%'>
                <Skeleton />
              </Typography>
              <Typography component='div' variant='body1' width='50%'>
                <Skeleton />
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography component='div' variant='body1' width='80%'>
                    <Skeleton />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component='div' variant='body1' width='80%'>
                    <Skeleton />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        );
      })}
    </React.Fragment>
  );
};

const NoDataCard = () => {
  return (
    <MainCard sx={{ maxWidth: 345, mt: 4 }}>
      <CardMedia
        component='img'
        height='120'
        image={'https://i.ibb.co/wrGmxY2/Add-Route-Image.png'}
        alt='Bult Import Student'
      />
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {'Add Route'}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {
            'Add all stops with accurate address for route with scheduled time and assign it to a driver. Mark address on map to fill latitude and longitude accurately.'
          }
        </Typography>
      </CardContent>
    </MainCard>
  );
};
