import React, { useState, useEffect } from 'react';

// material ui
import { Grid } from '@mui/material';

// third party
import { useSnackbar } from 'notistack';

// project imports
import DriverList from '../../List/DriverList/index';
import MainCard from 'components/MainCard';
import { Heading } from 'components/Headers/Heading';
import DriverTabs from './DriverTabs';
import { deleteDriver, updateGuidedWorkFlow } from 'clients/SpotBus';
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import BackForward from '../../BackForward';

export default function DriverForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [editGuidedDriver, setEditGuidedDriver] = useState(undefined);
  const [driverCardData, setDriverCardData] = useState({});
  const [isEditGuidedDriver, setIsEditGuidedDriver] = useState(false);
  const [onEditActiveStep, setOnEditActiveStep] = useState(false);

  // delete driver function
  const handleDeleteDriver = async (driverId) => {
    setDeleteButtonLoader(true);
    enqueueSnackbar(`Deleting driver ${driverId}`);
    try {
      const result = await deleteDriver(driverId);
      // console.log('Delete result is ', result);
      handleUpdateGuidedWorkflowData(-1);
      enqueueSnackbar('Driver successfully deleted', {
        variant: 'success',
      });
      props.getAllDrivers();
      setDeleteButtonLoader(false);
      setOpenDeleteAlert(!openDeleteAlert);
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDeleteButtonLoader(false);
    }
  };

  // getting index for update guided workflow data on register or delete
  const getIndex = (req) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'ADD_DRIVERS') {
        return i;
      }
    }
  };

  // update guided workflow data on register or delete
  const handleUpdateGuidedWorkflowData = async (count = 1) => {
    let req = props.guidedWorkflowData;
    let i = getIndex(props.guidedWorkflowData);
    req.steps_graph[i].data[0].available_items_count =
      req.steps_graph[i].data[0].available_items_count + count;
    if (
      req.steps_graph[i].data[0].min_items_required <=
      req.steps_graph[i].data[0].available_items_count
    ) {
      req.steps_graph[i].data[0].is_completed = true;
    } else {
      req.steps_graph[i].data[0].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
      // console.log('Update Guided WorkFlow result : ', result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (driver) => {
    setEditGuidedDriver(driver.driver_username);
    setDriverCardData(driver);
    setIsEditGuidedDriver(true);
    setOnEditActiveStep(!onEditActiveStep);
  };

  useEffect(() => {
    return closeSnackbar();
  }, []);

  return (
    <Grid sx={{ mx: '2vw' }}>
      <MainCard>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid sx={{ pl: 2 }}>
              <Heading title={'Driver'} />
            </Grid>
            <Grid sx={{ minHeight: 688 }}>
              <DriverTabs
                isFromGuided={true}
                editGuidedDriver={editGuidedDriver}
                setEditGuidedDriver={setEditGuidedDriver}
                driverCardData={driverCardData}
                setDriverCardData={setDriverCardData}
                handleUpdateGuidedWorkflowData={handleUpdateGuidedWorkflowData}
                isEditGuidedDriver={isEditGuidedDriver}
                setIsEditGuidedDriver={setIsEditGuidedDriver}
                setloadingData={props.setloadingData}
                onEditActiveStep={onEditActiveStep}
                {...props}
              />
            </Grid>
            <Grid>
              <BackForward
                disableButton={props.guidedWorkflowData?.steps_graph[3].data[0].is_completed}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <DriverList
              guidedDrivers={props.guidedDrivers}
              handleEdit={handleEdit}
              handleDeleteDriver={handleDeleteDriver}
              deleteButtonLoader={deleteButtonLoader}
              openDeleteAlert={openDeleteAlert}
              setOpenDeleteAlert={setOpenDeleteAlert}
              loadingData={props.loadingData}
            />
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
