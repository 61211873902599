import React from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';

// Formik
import { Field } from 'formik';

// Custom Compoent
import TimePickerField from 'components/Formik/TimePickerField';
import CheckBoxFormik from 'components/Formik/CheckBoxFormik';
import MainCard from 'components/MainCard';

export default function DayAndTimeSelection({ data, variant, formikProps }) {
  const { day, checkBoxName, startTimeName, endTimeName, allowEarlyClockInTime } = data;

  const [checked, setChecked] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleChange = (event) => {
    // setChecked(event.target.checked);

    const daysOfWeek = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    daysOfWeek.forEach((day) => {
      formikProps.setFieldValue(`${day}StartTime`, formikProps.values[startTimeName]);
      formikProps.setFieldTouched(`${day}StartTime`, true);
      formikProps.setFieldValue(`${day}EndTime`, formikProps.values[endTimeName]);
      formikProps.setFieldTouched(`${day}EndTime`, true);

      formikProps.setFieldTouched(`allowEarlyClockInTime${capitalizeFirstLetter(day)}`, true);
      formikProps.setFieldValue(
        `allowEarlyClockInTime${capitalizeFirstLetter(day)}`,
        formikProps.values[allowEarlyClockInTime],
      );
    });

    setTimeout(() => {
      formikProps.validateForm();
    }, [10]);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <MainCard
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        content={false}
        sx={{ pt: 4, pb: 3 }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack width={'20%'}>
            <Stack direction='row' justifyContent='flex-start' alignItems='center'>
              <Field component={CheckBoxFormik} name={checkBoxName} />
              <Typography variant='h4' color={'primary'}>
                {day}
              </Typography>
            </Stack>
          </Stack>

          <Stack width={'30%'}>
            <Field
              variant={variant}
              name={startTimeName}
              helperText='Enter time'
              component={TimePickerField}
              disabled={formikProps.isSubmitting}
              label='Shift Start Time *'
            />
          </Stack>

          <Stack width={'30%'}>
            <Field
              variant={variant}
              name={endTimeName}
              disabled={formikProps.isSubmitting}
              helperText='Enter time'
              component={TimePickerField}
              label='Shift End Time *'
            />
          </Stack>

          {/* <Stack> */}
          {/* <Field
            fullWidth
            component={TextField}
            id={allowEarlyClockInTime}
            name={allowEarlyClockInTime}
            placeholder={'Allow Early Clock in time'}
            helperText={'Enter time'}
            variant='standard'
            type='number'
            label='Allow Early Clock in time'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AccessTimeIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{'Minutes'}</Typography>
                </InputAdornment>
              ),

              inputProps: { min: 0, max: 15, step: 15 },
            }}
          /> */}
          {/* </Stack> */}
          <Stack width={'15%'}>
            {isHovered && (
              <Button variant={variant} onClick={handleChange} size='small'>
                Same for all
              </Button>
            )}
          </Stack>
        </Stack>
      </MainCard>
    </>
  );
}
