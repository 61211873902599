/**
 * Represents a date and time object for Spot Bus system, initialized from an ISO 8601
 * formatted string. Provides methods to work with the date and time, such as converting
 * them to localized formats.
 */
export default class SpotBusDateTime {
  /**
   * Initializes a new SpotBusDateTime object from the provided ISO 8601 formatted string.
   * @param {string} isoString - The ISO 8601 formatted string representing the date and time.
   */
  constructor(isoString) {
    this.date = new Date(isoString);
  }

  /**
   * Creates a new SpotBusDateTime object from the provided ISO 8601 formatted string.
   * Throws an error if the provided string is null, undefined, or invalid.
   *
   * @param {string} isoString - The ISO 8601 formatted string representing the date and time.
   * @returns {SpotBusDateTime} A new SpotBusDateTime object.
   * @throws {Error} If the provided ISO string is null, undefined, or invalid.
   */
  static fromISOString(isoString) {
    if (!isoString) {
      throw new Error('ISO string is required');
    }

    const date = new Date(isoString);

    if (isNaN(date.getTime())) {
      throw new Error('Invalid ISO string');
    }

    return new SpotBusDateTime(date);
  }

  /**
   * Returns a SpotBusDateTime object initialized with the current date and time.
   *
   * This method creates a new SpotBusDateTime object using the current date and time,
   * and returns it.
   *
   * @returns {SpotBusDateTime} A SpotBusDateTime object initialized with the current date and time.
   */

  static getCurrentDate() {
    const date = new Date();
    return new SpotBusDateTime(date);
  }

  /**
   * Returns the localized date in the format 'Month Day, Year'.
   *
   * @returns {string} The localized date string.
   */
  toLocalizeDate() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return this.date.toLocaleDateString(undefined, options);
  }

  /**
   * Returns the localized time in the format 'Hour:Minute:Second'.
   *
   * @returns {string} The localized time string.
   */
  toLocalizeTime() {
    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return this.date.toLocaleTimeString(undefined, options);
  }

  /**
   * Converts the date to an ISO 8601 string.
   *
   * This method returns a string in simplified extended ISO format (ISO 8601),
   * which is always 24 characters long: YYYY-MM-DDTHH:mm:ss.sssZ.
   *
   * @returns {string} The ISO 8601 string representation of the date.
   */
  toISOString() {
    return this.date.toISOString();
  }

  /**
   * Returns the ISO 8601 string for midnight of the provided date in local time.
   *
   * @returns {string} The ISO 8601 string representation of the local midnight timestamp.
   */
  getLocalMidnightTimestamp() {
    // Clone the current date to avoid modifying the original date
    const midnightDate = new Date(this.date.getTime());

    // Set the time to midnight
    midnightDate.setHours(0, 0, 0, 0);

    // Convert local midnight to UTC by subtracting the timezone offset
    midnightDate.setMinutes(midnightDate.getMinutes() - midnightDate.getTimezoneOffset());

    // Return the adjusted Date object as ISO string
    return midnightDate.toISOString();
  }

  // Additional methods can be added here
}
