import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/styles';
import { Typography } from '@mui/material';

// project imports
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavSection = ({ menu, level }) => {
  const theme = useTheme();

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'item':
        return !item.isDrawerHidden && <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        );
    }
  });

  return menus;
};

NavSection.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavSection;
