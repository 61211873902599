import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// third-party imports
import { Formik, Form } from 'formik';

// material-ui
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// components
import { ResetButton, SaveButton } from 'components/Buttons';
import MainCard from 'components/MainCard';

// project imports
import ConfigurationForm from './Forms/ConfigurationForm';
import DetailsForm from './Forms/DetailsForm';
import formInitialValues, {
  initialValuesOfSchoolScheduleForm,
  initialValuesOfSessionForm,
} from './FormModel/formInitialValues';
import {
  validationSchemaForSchoolSchedule,
  validationSchemaSessionForm,
} from './FormModel/validationSchema';

// assets
import InfoIcon from '@mui/icons-material/Info';
import TitleIcon from '@mui/icons-material/Title';
import SchoolSchedule from './Forms/SchoolSchedule';
import {
  checkConditionsforAllSchoolScheduleValues,
  checkDateRangeValidity,
  createSchoolScheduleArray,
  dateRangeValidation,
  finalErrorMessageWithDateRange,
  isValidateDateRangeForHolidays,
  isValidateDateRangeForVacations,
  validateAllValuesWithDateRange,
} from './Utils';

function SessionForm({
  sessionData,
  handleSubmit,
  isUpdate,
  schoolsData,
  enqueueSnackbar,
  setInitialValues,
  initialValues,
  guidedForm,
}) {
  const navigate = useNavigate();

  const [selectedSchoolID, setSelectedSchoolID] = useState('');
  const [schoolScheduleValidation, setSchoolScheduleValidation] = useState(false);
  const [holidayForSelectedSchool, setHolidayForSelectedSchool] = useState([]);
  const [openSchoolScheduleForm, setOpenSchoolScheduleForm] = useState(false);
  const [editHoliday, setEditHoliday] = useState(false);
  const [editVacation, setEditVacation] = useState(false);
  const [vacationForSelectedSchool, setVacationForSelectedSchool] = useState([]);
  const [copyAllData, setCopyAllData] = useState([]);
  const [scheduleValues, setScheduleValues] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(undefined);
  const [selectedVacation, setSelectedVacation] = useState(undefined);

  function checkAndAddData(schools_schedule, previous_schools_schedule) {
    let schoolScheduleData = schools_schedule;
    for (let i = 0; i < schools_schedule.length; i++) {
      for (let j = 0; j < previous_schools_schedule.length; j++) {
        if (schools_schedule[i].school_id === previous_schools_schedule[j].school_id) {
          schoolScheduleData[i] = previous_schools_schedule[j];
        }
      }
    }
    setScheduleValues(schoolScheduleData);
    setSelectedSchoolID(schoolScheduleData[0].school_id);
  }

  const resultArray = checkDateRangeValidity(scheduleValues);

  const validationChecForHoliday = dateRangeValidation(scheduleValues);

  const validationOfVacationAndHoliday = validateAllValuesWithDateRange(
    resultArray,
    validationChecForHoliday,
  );

  const finalMessage = finalErrorMessageWithDateRange(validationOfVacationAndHoliday);

  const _handleResetForm = async (resetForm) => {
    await resetForm(); // resetting formik values

    if (!Boolean(isUpdate)) {
      const getSchoolScheduleData = createSchoolScheduleArray(schoolsData);
      setScheduleValues(getSchoolScheduleData);
      setInitialValues(initialValuesOfSessionForm());
      setSelectedSchoolID(getSchoolScheduleData[0].school_id);
    }

    if (schoolsData.length > 0 && Boolean(isUpdate)) {
      const getSchoolScheduleData = createSchoolScheduleArray(schoolsData);
      checkAndAddData(getSchoolScheduleData, sessionData.schools_schedule);
    }
  };

  const _handleSubmit = (values, actions) => {
    if (!finalMessage) {
      actions.setSubmitting(false);
      return;
    } else {
      const allSchoolFieldValidations = checkConditionsforAllSchoolScheduleValues(scheduleValues);
      if (allSchoolFieldValidations) {
        handleSubmit(values, actions, scheduleValues);
      }
    }
  };

  // Formik Initial Values are updating based on school ID
  const filterScheduleSchoolData = scheduleValues?.find(
    (ele) => ele.school_id === selectedSchoolID,
  );

  // Validation are implemented based on requirement
  const validationShemaSteps = validationSchemaSessionForm();

  const _handleSubmitSchoolSchedule = (values, actions) => {
    const validateDateBetweenSessionDateRangeForHolidays = isValidateDateRangeForHolidays(
      values.start_date,
      values.end_date,
      holidayForSelectedSchool,
    );

    const validateDateBetweenSessionDateRangeForVacation = isValidateDateRangeForVacations(
      values.start_date,
      values.end_date,
      vacationForSelectedSchool,
    );

    const validationMessageForVacation = (validateDate) => {
      const validationResult = validateDate.some((ele) => ele === true);
      return validationResult;
    };

    const validationMsgVacation = validationMessageForVacation(
      validateDateBetweenSessionDateRangeForVacation,
    );

    const validationMsgHolidays = validationMessageForVacation(
      validateDateBetweenSessionDateRangeForHolidays,
    );

    if (!validationMsgVacation && !validationMsgHolidays) {
      if (values) {
        const updatedTasks = copyAllData.map((item) => {
          if (item.school_id === selectedSchoolID) {
            return {
              ...item,
              date_range: {
                start_date: values.start_date,
                end_date: values.end_date,
              },
              days_of_week: values.days_of_week,
              holidays: holidayForSelectedSchool,
              vacations: vacationForSelectedSchool,
            };
          }
          return item;
        });
        setScheduleValues(updatedTasks);
        setEditVacation(false);
        setEditHoliday(false);
        setOpenSchoolScheduleForm(false);
        setSelectedHoliday(undefined);
        setSelectedVacation(undefined);
        enqueueSnackbar('School schedule added successfully', {
          variant: 'success',
        });
      }
    }
  };

  const handleValidationForSchoolSchedule = () => {
    setSchoolScheduleValidation(true);
  };

  return (
    <MainCard border={guidedForm ? false : true} content={false} sx={{ py: 2 }}>
      <Formik
        initialValues={formInitialValues(initialValues)}
        validationSchema={validationShemaSteps}
        onSubmit={_handleSubmit}
        enableReinitialize
        resetForm={_handleResetForm}
      >
        {(formikProps) => (
          <Form id='sessionsForm'>
            <Stack
              // direction='column'
              // justifyContent='space-between'
              alignItems='flex-start'
              spacing={3}
              minHeight={'87vh'}
              px={2}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h3' color='primary' component='div' gutterBottom>
                    Session Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DetailsForm
                    formikProps={formikProps}
                    isUpdate={isUpdate}
                    guidedForm={guidedForm}
                  />
                </Grid>
                {guidedForm ? null : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h3' color='primary' component='div' gutterBottom>
                        Session Configuration
                        <Tooltip title='Once this information is submitted, it cannot be updated or changed at a later time.'>
                          <IconButton aria-label='delete' size='small' sx={{ padding: 0.5 }}>
                            <InfoIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ConfigurationForm formikProps={formikProps} />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h3' color='primary' gutterBottom>
                  School Schedule
                </Typography>
                <Typography variant='body2' component='div' gutterBottom>
                  Effortlessly organize your school year with our Schedule School feature, allowing
                  you to define session dates, choose specific days, and seamlessly incorporate
                  holidays and vacations complete with descriptions.( All schools data is mandatory
                  )
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Formik
                  initialValues={initialValuesOfSchoolScheduleForm(filterScheduleSchoolData)}
                  validationSchema={validationSchemaForSchoolSchedule()}
                  onSubmit={_handleSubmitSchoolSchedule}
                  enableReinitialize
                  // resetForm={_handleResetForm}
                >
                  {(formikpropsOfSchoolSchedule) => (
                    <Form id='schoolScheduleForm'>
                      <SchoolSchedule
                        formikProps={formikProps}
                        formikpropsOfSchoolSchedule={formikpropsOfSchoolSchedule}
                        isUpdate={isUpdate}
                        schoolsData={schoolsData}
                        scheduleValues={scheduleValues}
                        setScheduleValues={setScheduleValues}
                        sessionData={sessionData}
                        setSelectedSchoolID={setSelectedSchoolID}
                        selectedSchoolID={selectedSchoolID}
                        setInitialValues={setInitialValues}
                        setSchoolScheduleValidation={setSchoolScheduleValidation}
                        enqueueSnackbar={enqueueSnackbar}
                        setHolidayForSelectedSchool={setHolidayForSelectedSchool}
                        holidayForSelectedSchool={holidayForSelectedSchool}
                        openSchoolScheduleForm={openSchoolScheduleForm}
                        setOpenSchoolScheduleForm={setOpenSchoolScheduleForm}
                        editHoliday={editHoliday}
                        setEditHoliday={setEditHoliday}
                        editVacation={editVacation}
                        setEditVacation={setEditVacation}
                        setVacationForSelectedSchool={setVacationForSelectedSchool}
                        vacationForSelectedSchool={vacationForSelectedSchool}
                        setCopyAllData={setCopyAllData}
                        copyAllData={copyAllData}
                        finalMessage={finalMessage}
                        setSelectedHoliday={setSelectedHoliday}
                        selectedHoliday={selectedHoliday}
                        setSelectedVacation={setSelectedVacation}
                        selectedVacation={selectedVacation}
                      />
                    </Form>
                  )}
                </Formik>
                <Stack>
                  <Typography color={'error'} variant='caption'>
                    {schoolScheduleValidation
                      ? 'School schedule information for all schools is required'
                      : null}
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
            <Stack
              direction='row'
              justifyContent='flex-end'
              alignItems='flex-end'
              spacing={3}
              width='100%'
              sx={{ pb: 3, pr: 2 }}
            >
              {Boolean(!isUpdate) && (
                <ResetButton
                  disabled={formikProps.isSubmitting}
                  onClick={() => {
                    _handleResetForm(formikProps.resetForm);
                  }}
                  size={guidedForm ? 'size' : 'medium'}
                >
                  Reset
                </ResetButton>
              )}

              <SaveButton
                size={guidedForm ? 'size' : 'medium'}
                loading={formikProps.isSubmitting}
                type='submit'
                onClick={() => handleValidationForSchoolSchedule()}
              >
                {Boolean(isUpdate) ? 'UPDATE' : 'SAVE'}
              </SaveButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </MainCard>
  );
}

export default SessionForm;
