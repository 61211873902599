import React from 'react';
import StudentImport from './Student/StudentImport';
import DriverImport from './Driver/DriverImport';

export function StudentImports(props) {
  return (
    <div>
      <StudentImport {...props} />
    </div>
  );
}

export function DriverImports(props) {
  return (
    <div>
      <DriverImport {...props} />
    </div>
  );
}
