import { Fragment, useEffect } from 'react';

//MUI Imports
import { Grid, InputAdornment, Typography, Button, Stack, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Formik Imports
import { Field, Form, Formik, useFormik } from 'formik';
import { TextField } from 'formik-mui';

//Formik components
import CustomSelect from 'pages/SchoolChatBotSupport/Sections/CustomizeBot/CustomSelect';
import CheckboxInput from './CheckBox';
import RangeSliderInput from './RangeSliderInput';

//Assets
import AddIcon from '@mui/icons-material/Add';
import SchoolLocation from './SchoolLocation';
import SaveIcon from '@mui/icons-material/Save';
import RoomIcon from '@mui/icons-material/Room';
import { SaveButton } from 'components/Buttons';
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import { BsFillHouseFill } from 'react-icons/bs';
import { BiStreetView, BiSolidCity, BiCurrentLocation } from 'react-icons/bi';
import { PiBuildingsFill } from 'react-icons/pi';
import { FaFlag } from 'react-icons/fa';

import LogoDragAndDrop from './LogoDragAndDrop';
import { CustomTooltip } from 'components/tooltips';

const schoolInfo = {
  startAdornment: (
    <InputAdornment position='start'>
      <ApartmentIcon color='secondary' />
    </InputAdornment>
  ),
};

const address = {
  startAdornment: (
    <InputAdornment position='start'>
      <BsFillHouseFill fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const streetIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <BiStreetView fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const cityIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <BiSolidCity fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const stateIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <PiBuildingsFill fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const countryIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <FaFlag fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const zipCodeIcon = {
  startAdornment: (
    <InputAdornment position='start'>
      <BiCurrentLocation fontSize={'20'} color='secondary' />
    </InputAdornment>
  ),
};

const schoolType = [
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' },
  { value: 'specialSchool', label: 'Special School' },
];

const schoolLevel = [
  { value: 'elementary', label: 'Elementary School' },
  { value: 'middle', label: 'Middle School' },
  { value: 'high', label: 'High School' },
];

const timeZones = [
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata' },
  { value: 'US/Eastern', label: 'US/Eastern' },
  { value: 'US/Pacific', label: 'US/Pacific' },
  { value: 'US/Central', label: 'US/Central' },
];

const timeAM = [
  {
    value: 0,
    label: '12 AM',
  },
  {
    value: 10800,
    label: '3 AM',
  },
  {
    value: 21600,
    label: '6 AM',
  },
  {
    value: 32400,
    label: '9 AM',
  },
  {
    value: 43200,
    label: '12 PM',
  },
];

const timePM = [
  {
    value: 0,
    label: '12 PM',
  },
  {
    value: 10800,
    label: '3 PM',
  },
  {
    value: 21600,
    label: '6 PM',
  },
  {
    value: 32400,
    label: '9 PM',
  },
  {
    value: 43200,
    label: '12 AM',
  },
];

export default function SchoolsForm({
  formInitialValues,
  validationSchema,
  handleSubmit,
  buttonLoader,
  schoolAddress,
  ...props
}) {
  const handleAddLocationOpen = () => {
    props.handleClickOpen();
    props.setEditLocationData();
  };

  return (
    <Formik
      initialValues={formInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isSubmitting={false}
      enableReinitialize
    >
      {(formikProps) => (
        <Form id='schoolForm'>
          <Grid container xs={12}>
            <Typography variant='h3' color='primary'>
              School Information
            </Typography>
            <Grid container xs={12}>
              <Grid spacing={3} container xs={6}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label='School Name *'
                    name='school_name'
                    variant='standard'
                    id='school_name'
                    helperText='Enter complete name of school'
                    InputProps={schoolInfo}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={TextField}
                    label='Abbreviation *'
                    name='abbreviation'
                    variant='standard'
                    id='abbreviation'
                    helperText='Short code / abbreviation of a school name'
                    InputProps={schoolInfo}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={CustomSelect}
                    label='School Type *'
                    name='school_type'
                    variant='standard'
                    id='school_type'
                    helperText='School type is required'
                    InputProps={schoolInfo}
                    options={schoolType}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={CustomSelect}
                    label='School level *'
                    name='level'
                    variant='standard'
                    id='level'
                    helperText='School level is required'
                    InputProps={schoolInfo}
                    options={schoolLevel}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    component={CustomSelect}
                    label='Time Zone *'
                    name='time_zone'
                    variant='standard'
                    id='time_zone'
                    helperText='Time Zone is required'
                    InputProps={schoolInfo}
                    options={timeZones}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container xs={6} justifyContent='center'>
                <LogoDragAndDrop
                  selectedFile={props.previewImage}
                  selectedFileData={props.setImageData}
                  setSelectedFile={props.setPreviewImage}
                  handleDragImage={props.handleDragImage}
                  text={'Upload Logo'}
                  tooltipTitle={'Upload logo or Drag and drop here'}
                />
                <Grid item xs={12} sx={{ textAlign: 'center', pt: 1 }}>
                  {props.proceed && !props.previewImage && (
                    <Typography color='error'>Logo required</Typography>
                  )}
                  <Typography>Allowed *.jpeg, *.jpg, *.png</Typography>
                  <Typography>Max size of 3 MB</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} xs={12}>
              <Grid container xs={4}>
                <Typography variant='h3' color='primary' sx={{ mt: 3, mb: 1 }}>
                  Address
                </Typography>
              </Grid>
              <Grid container xs={8} justifyContent={'flex-end'} alignItems={'center'}>
                {props.multipleAddress?.length === 0 && props.proceed && (
                  <Typography color={'error'}>Add location to proceed *</Typography>
                )}
                <Button
                  size='small'
                  onClick={props.handleAddress}
                  startIcon={schoolAddress ? <EditIcon /> : <AddIcon />}
                >
                  {schoolAddress ? 'Update Address' : 'Add Address'}
                </Button>
              </Grid>
            </Grid>
            <Grid spacing={3} container xs={12}>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Apt/Plot *'
                  name='apt_plot'
                  variant='standard'
                  id='apt_plot'
                  helperText='Apt/Plot is required'
                  InputProps={address}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Street *'
                  name='street'
                  variant='standard'
                  id='street'
                  helperText='Street is required'
                  InputProps={streetIcon}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='City *'
                  name='city'
                  variant='standard'
                  id='city'
                  helperText='City is required'
                  InputProps={cityIcon}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='State *'
                  name='state'
                  variant='standard'
                  id='state'
                  helperText='State is required'
                  InputProps={stateIcon}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Country *'
                  name='country'
                  variant='standard'
                  id='country'
                  helperText='Country is required'
                  InputProps={countryIcon}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Zip Code *'
                  name='zip_code'
                  variant='standard'
                  id='zip_code'
                  helperText='​Zip Code is required'
                  InputProps={zipCodeIcon}
                  disabled={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'} xs={12}>
                <Grid container xs={4}>
                  <Typography variant='h3' color='primary' sx={{ mt: 3, mb: 1 }}>
                    Location
                  </Typography>
                </Grid>
                <Grid container xs={8} justifyContent={'flex-end'} alignItems={'center'}>
                  <CustomTooltip
                    title={
                      props.multipleAddress?.length === 0
                        ? 'Please provide an address to proceed with Add Location.'
                        : ''
                    }
                  >
                    <Grid>
                      <Button
                        size='small'
                        disabled={props.multipleAddress?.length === 0}
                        onClick={handleAddLocationOpen}
                        startIcon={<AddIcon />}
                      >
                        Add Location
                      </Button>
                    </Grid>
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <SchoolLocation
              setEdit={props.setEdit}
              handleClickOpen={props.handleClickOpen}
              handleOpenDelete={props.handleOpenDelete}
              multipleAddress={props.multipleAddress}
              setEditLocationData={props.setEditLocationData}
              setLocationMapping={props.setLocationMapping}
              locationMapping={props.locationMapping}
              setPrimaryDrop={props.setPrimaryDrop}
              primaryDrop={props.primaryDrop}
              setPrimaryPick={props.setPrimaryPick}
              primaryPick={props.primaryPick}
              formikProps={formikProps}
            />
            <Typography variant='h3' color='primary' sx={{ mt: 3, mb: 1 }}>
              Bus Operations Timing
            </Typography>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={RangeSliderInput}
                  label='Start Timing*'
                  name='start_timing'
                  variant='standard'
                  id='start_timing'
                  helperText='Start timing is required'
                  InputProps={address}
                  disabled={false}
                  marks={timeAM}
                  min={3600}
                  max={43200}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={RangeSliderInput}
                  label='End Timing *'
                  name='end_timing'
                  variant='standard'
                  id='end_timing'
                  helperText='End timing is required'
                  InputProps={address}
                  disabled={false}
                  marks={timePM}
                  min={3600}
                  max={43200}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={CheckboxInput}
                label='Please accept state education regulations, and other terms & conditions to continue.'
                name='acknowledgementForStateCompliance'
                variant='standard'
                id='acknowledgementForStateCompliance'
                helperText='*Acknowledgement for state compliance'
                InputProps={address}
                options={timeZones}
                disabled={false}
                formikProps={formikProps}
                schoolAddress={schoolAddress}
              />
            </Grid>
            <Grid container justifyContent={'flex-end'}>
              <SaveButton
                sx={{ mb: 3, mr: 2 }}
                onClick={() => {
                  props.setProceed(true);
                  props.setImageValidation(true);
                }}
                id='submit'
                size={'medium'}
                type='submit'
                loading={buttonLoader}
              >
                Save
              </SaveButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
