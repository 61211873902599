import React from 'react';

// material ui
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
  },
  value: {
    marginTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.light,
  },
}));

export function DriverDetailsItem({ name, id, value, icon, dataLoading }) {
  const classes = useStyles();
  const IconName = icon;
  return (
    <Box
      display='flex'
      bgcolor='white'
      border={1}
      borderColor='#E8E8E8'
      borderRadius='2px'
      marginBottom={2}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        className={classes.main}
      >
        <Grid item xs={2}>
          <IconName fontSize='large' className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <Typography color='primary'>{name}</Typography>
          {!dataLoading ? (
            <Typography
              className={classes.value}
              component='h5'
              variant='h5'
              color='textSecondary'
              id={id}
            >
              {value}
            </Typography>
          ) : (
            <Skeleton variant='text' width={'100%'} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
