import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PopularTopics from './Components/PopularTopics';
import VideoGuides from './Components/VideoGuides';
import Apps from '@mui/icons-material/Apps';
import LocalLibrary from '@mui/icons-material/LocalLibrary';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import '../Utils/scroll.css';

export default function Help(props) {
  console.log('HELPS', props);

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // setState({ ...state, [anchor]: open });
    props.setHelpOpen();
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={'Help'}>
          <ListItemIcon>
            <HelpOutlineIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={'Help & Features'} />
        </ListItem>
        <ListItem button key={'Help'}>
          <ListItemIcon>
            <LocalPhoneIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={'Contact support'} />
        </ListItem>
        <ListItem button key={'Help'}>
          <ListItemIcon>
            <Apps className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={'Apps & integrations'} />
        </ListItem>
        <ListItem button key={'Help'}>
          <ListItemIcon>
            <LocalLibrary className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={'Resource guide'} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className='request-scroll webkit-scrollbar webkit-scrollbar-track webkit-scrollbar-thumb'>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={anchor}
            // open={state[anchor]}
            open={props.helpOpen}
            onClose={toggleDrawer(anchor, false)}
            classes={{ paper: classes.paper }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              className={classes.heading}
            >
              <Typography variant='h4'>Help & getting started</Typography>
              <IconButton aria-label='delete' onClick={toggleDrawer(anchor, false)} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            <TextField label='Search' size='small' variant='outlined' className={classes.search} />
            <VideoGuides />
            <PopularTopics />
            <Divider className={classes.divid} />
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: 310,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#f5f5f5',
  },
  icon: {
    color: '#e1b101',
  },
  heading: {
    marginLeft: 10,
    marginRight: 10,
    width: '92%',
  },
  divid: {
    marginTop: 10,
  },
  search: {
    marginTop: 10,
    marginLeft: 12,
    marginRight: 12,
  },
}));
