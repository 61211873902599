export function convertTimeToTwelveHourFormat(date) {
  if (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + '00';
    return strTime;
  }
  return '';
}

export function convertTimeAccordingToType(date, type) {
  console.log(date, type);
  if (date && type == 'AM') {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + '00';
    return strTime;
  } else if (date && type == 'PM') {
    var hours = date.getHours();
    if (hours < 12) {
      hours = hours + 12;
    }
    var minutes = date.getMinutes();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + '00';
    return strTime;
  }
  return '';
}

export function getTime(date) {
  if (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + '00';
    return strTime;
  }
  return '';
}
export function TwentyFourToTwelveWithAmPm(time) {
  let hh = parseInt(time.split(':')[0]);
  let ch = hh % 12;

  if (ch === 0) {
    if (hh === 24) ch = 0;
    else {
      ch = 12;
    }
  }
  ch = ch < 10 ? '0' + ch : ch;

  let mm = parseInt(time.split(':')[1]);
  mm = mm < 10 ? '0' + mm : mm;

  return ch + ':' + mm + ':' + '00';
}

export function convertDateFormat(inputDateStr) {
  // Create a Date object from the input date string
  const inputDate = new Date(inputDateStr);

  // Get individual date components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(inputDate.getUTCMilliseconds()).padStart(3, '0');

  // Format the date in the desired output format
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return formattedDate;
}



export function getLocalMidnightTimestamp(selectedDate) {
  // Create a new Date object for the current date
  let now = new Date(selectedDate);

  // Extract the local timezone offset in minutes
  let timezoneOffset = now.getTimezoneOffset();

  // Create a new Date object for midnight of the current day in local time
  let localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  // Convert local midnight to UTC by subtracting the timezone offset
  localMidnight.setMinutes(localMidnight.getMinutes() - timezoneOffset);

  // Convert the adjusted Date object to ISO string
  return localMidnight.toISOString();
}
