import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Copyright from './Copyright';
import { Auth, Hub } from 'aws-amplify';
import PasswordTextField from '../../../components/Form/PasswordTextField';
import { Snackbar, Backdrop, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'aws-amplify-react';
import LOGGER from '../../../Logger';
 

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  media: {
    margin: theme.spacing(1)
  },
  title: {
    marginTop: theme.spacing(2)
  },
  subtitle: {
    marginTop: theme.spacing(4)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));


const ERR_MESSAGES = {
    "UsernameExistsException": "User already registered with the given email address.",
    "InvalidParameterException": "Please correct invalid fields in form and try again."
}

export default function CompleteSignUp(props) {

    let userAtts = props.user.challengeParam.userAttributes

    const [username, setUsername] = React.useState(userAtts != undefined && userAtts.email != undefined  ? userAtts.email : undefined);
    const [password, setPassword] = React.useState(undefined);
    const [confirmPassword, setConfirmPassword] = React.useState(undefined);
    const [error, setError] = React.useState("");


    // Validation Status flags
    const [isPasswordValidated, setPasswordValidated] = React.useState(false);
    const [isConfirmPasswordValidated, setConfirmPasswordValidated] = React.useState(false);

    //Control Flags
    const [isLoading, setLoading] = React.useState(false);
    const [showErrorSnackBar, setShowErrorSnackBar] = React.useState(false);
    const [signUpSuccessful, setSignUpSuccessful] = React.useState(false);
    const [verifyEmailCode, setVerifyEmailCode] = React.useState(false);
    const classes = useStyles();


    function resetPasswordClicked(event) {
        event.preventDefault();
        LOGGER.debug("Reset password user with email: "+ {username})
        setLoading(true)


        Auth.completeNewPassword(props.user, password, {
            "address": "1",
            "picture": "p"
        }).then(function (user) {
            LOGGER.debug('Complete new password completed for: ', username);
            setLoading(false)
            
        }).catch(function (err) { 
              LOGGER.error('ERROR in complete new password', err);
              setError(error)
              setLoading(false)
        });
        
    }
    
    function showSignIn(){
        Hub.dispatch(
            'authPage', 
            { 
                event: 'SIGN_IN', 
                data: {color:'blue'}, 
                message:'Redirect to sign IN.' 
        });
    }
    function renderSignUpForm(){
        return (
            <React.Fragment>
                <Typography component="h1" variant="h5" className={classes.title}>
                    Please reset your password to complete registration
                </Typography>
                <form className={classes.form} 
                    // ref="form"
                    onSubmit={resetPasswordClicked}
                    disabled={isLoading}
                    onError={errors => console.log(errors)}>

                    <Grid container spacing={2}>

                    
                    <Grid item xs={12}>
                    <Snackbar open={showErrorSnackBar} autoHideDuration={6000} onClose={(e) => {setShowErrorSnackBar(false)}}>
                        <MuiAlert  elevation={6} variant="filled" onClose={(e) => {setShowErrorSnackBar(false)}} severity="error">
                            {error}
                        </MuiAlert>
                    </Snackbar>
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordTextField
                            onChange={(e) => { setPassword(e.target.value); }}
                            value={password}
                            autoComplete="current-password"
                            validationstatushandler={setPasswordValidated}
                            disabled={isLoading}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordTextField
                            name="confirmpassword"
                            label="Confirm Password"
                            id="confirmpassword"
                            onChange={(e) => { setConfirmPassword(e.target.value); }}
                            value={confirmPassword}
                            validationRules={[
                                {
                                    function: (value) => {
                                        if (value !== password) {
                                            return false;
                                        }
                                        return true;
                                    },
                                    errorMessage: "Confirm Password should match with the password."
                                }
                                
                            ]}
                            validationstatushandler={setConfirmPasswordValidated}
                            disabled={isLoading}
                            />
                    </Grid>
                    

                    </Grid>

                </form>
                <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={resetPasswordClicked}
                        disabled={
                            !(isPasswordValidated && 
                                isConfirmPasswordValidated) || isLoading
                        }
                    >
                    Reset Password
                    </Button>
                    

                    <Grid container>
                        <Grid item>
                        <Link onClick={showSignIn} variant="body2"  style={{ color: '#000' }}>
                            Sign in with different credentials?
                        </Link>
                        </Grid>
                    </Grid>
            </React.Fragment>
        )
    }

    return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
        <CardMedia 
            className={classes.media}
            src={require("../../../assets/static/images/SpotBus_Logo.png")}
            component="img"
        />
        
        {renderSignUpForm()}
        <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
        <Box mt={5}>
        <Copyright />
        </Box>
    </Container>
    );
}