
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const avatarMenuItems = [
  {
    name: "Account Settings",
    url: "/account-settings",
    icon: SettingsIcon,
    id: "account_settings"
  },
  {
    name: "Manage Users",
    url: "/users",
    icon: PeopleAltIcon,
    id: "manage_users"
  },
];

export default avatarMenuItems;