import { API, Storage } from 'aws-amplify';
import { studentListCount } from 'constants';

const API_ID = 'SpotBusAPI';
/**
 * SpotBus APIs
 */
const API_GET_ALL_SCHOOLS = '/dashboard/schools';
const API_GET_SCHOOL = '/dashboard/schools/';
const API_UPDATE_SCHOOL = '/dashboard/schools/';
const API_REGISTER_SCHOOL = '/dashboard/schools';

/**
 * Drivers API
 */
const API_LIST_DRIVERS_BY_TRANSPORTATION_ADMIN = '/dashboard/drivers';
const API_LIST_DRIVERS_BY_TRANSPORTATION_ADMIN_DRIVERS_NAME =
  '/dashboard/drivers?full_details=false';
const API_GET_DRIVER = 'dashboard/driver/';
const API_TOGGLE_DRIVER_STATUS = '/driver/toggle-status/';
const API_ADMIN_DRIVERS = '/dashboard-admin/drivers/';
const API_DRIVER_TOGGLE_STATUS = '/toggle-status/';
const API_UPLOAD_DRIVER_ADDITIONAL_IMAGE = '/dashboard/image?category=drivers&sub_category=';
const API_REGISTER_DRIVER_INFO = '/dashboard-admin/drivers?is_additional_info=';

/**
 * Routes API URLs
 */
const API_LIST_ROUTES = '/dashboard/routes/v2/list/';
const API_GET_ROUTE_INFO = '/dashboard/routes/v2/';

/**
 * Trips API URLs
 */
const API_GET_TRIPS_BY_SCHOOL = '/dashboard/get-all-route-trips-by-school/';
const API_GET_TRIPS_BY_SCHOOL_BY_DATE = '/dashboard/get-all-route-trips-by-school/';
const API_GET_ACTUAL_ROUTE_PATH = '/dashboard/route-trip/';
const API_GET_SPEED_INFO = '/dashboard/route-trip/';
const API_GET_TRIPS_GEOFENCE_TIMECODES = '/dashboard/route-trip/';
const API_UPDATE_TRIP = '/dashboard/trips/';

/**
 * Routes Trips API URLs
 */
const API_GET_ALL_ROUTE_TRIPS_PER_SCHOOL = '/dashboard/get-all-route-trips-stats/';
const API_END_TRIP_FOR_ALL_SCHOOLS = '/end-all-trips/';
const API_POST_CREATE_ROUTE = '/dashboard/routes/v2';
const API_POST_LIST_ROUTE_TRIPS = '/dashboard/list-route-trips';
const API_UPDATE_ROUTE = '/dashboard/routes/v2/';
const API_GET_TRIP_INFO = '/dashboard/route-trip/';
const API_POST_LIVE_VIEW = '/dashboard/live-view';
const API_DELETE_ROUTE = '/dashboard/route/';
const API_GET_ROUTE_CLASSIFICATIONS = '/dashboard/classifications';

const STATISTICS_HOME = '/dashboard/statistics/';
const API_INSPECTION_FORMS = '/dashboard/forms';

/**
 * Stats API URLs
 */
const API_STATS_GET_USERS_BY_DATE = '/stats/usersByDate';

/**
 * Mark Breakdown API URLs
 */
const API_MARK_TRIP_BREAKDOWN = '/dashboard/route-trip/';
const API_LIST_BREAKDOWN_BY_TRANSPORTATION_ADMIN = '/dashboard/list-route-trips?status=BreakDown';

/**
 * Students API URLs
 */
const API_GET_STUDENTS_BY_SCHOOL_WITH_ATTENDANCE = '/dashboard/childs/';
const API_POST_ADD_STUDENT = '/dashboard/register-child';
const API_GET_STUDENT = '/dashboard/child/';
const API_DELETE_STUDENT = '/dashboard/child/';
const API_UPDATE_STUDENT = '/dashboard/child/';
const API_GET_STUDENT_ATTENDENCE_LIST_BETWEEN_DATES = '/dashboard/child-attendance/';
const API_GET_TRIP_CHILDS_ATTENDANCE = '/dashboard/child-attendance-by-route/';
const API_UPDATE_CHILD_ATTENDANCE = '/dashboard/update-child-attendance';
const API_GET_CHILD_ATTENDANCE = '/dashboard/child_attendance/';
const API_EXPORT_CHILDS_BY_SCHOOL = '/dashboard/childs/export/';
const API_UPLOAD_CHILD_IMAGE = '/dashboard/image';
const GET_STUDENT_QR_CODE = '/dashboard/child/qrcode/';
const API_GET_STUDENT_BY_ROUTE_ID = '/driverapp/get-all-childs-by-route/';
const API_GET_ALL_STUDENTS_BY_SCHOOL_ID = '/dashboard/childs-by-school/';

/**
 * Student new api 27/09/2023
 */
const API_GET_STUDENTS_BY_SCHOOL_AND_SESSION = '/dashboard/students/';
const GET_STUDENT_QR_CODE_NEW = '/dashboard/students/qrcode/';
const API_POST_ADD_STUDENT_NEW = '/dashboard/students/';
const API_UPDATE_STUDENT_NEW = '/dashboard/students/';
const API_DELETE_STUDENT_NEW = '/dashboard/students/';
const GET_STUDENT_IMAGE_NEW = '/dashboard/students/image-url/';
const GET_STUDENT_ATTENDANCE_BY_TRIP_ID = '/dashboard/students/attendances/';
/**
 * access control API URLs
 */
const API_REGISTER_DASHBOARD_USER = '/dashboard-admin/register-user?caller_username=';
const API_GET_DASHBOARD_USER = '/dashboard-admin/user/';
const API_GET_DASHBOARD_USERS = '/dashboard/users?caller_username=';
const API_CREATE_PUT_GET_USER_PERMISSIONS = '/dashboard-admin/user-permissions/';
const API_DELETE_USER = '/dashboard-admin/user/';
const API_DELETE_USER_PERMISSION = '/dashboard-admin/user-permissions/';
const API_GET_ACCOUNT_INFORMATION = '/dashboard/user/caller-identity';

/***
 *  Import APIs URLs
 */

const API_VALIDATE_BULK_IMPORT_STUDENTS = '/dashboard/students/import';
const API_GET_BULK_IMPORT_STATUS = '/dashboard-admin/imports/';
const API_CANCEL_BULK_IMPORT_STATUS = '/dashboard-admin/imports/cancel/';

const API_VALIDATE_BULK_IMPORT_DRIVERS = '/dashboard-admin/drivers/import?caller_username=';
const API_CANCEL_BULK_IMPORT_STATUS_DRIVERS = '/dashboard-admin/drivers/import-cancel/';

const API_GET_BULK_IMPORT_STATUS_DRIVERS = '/dashboard-admin/drivers/import-status/';
const API_GET_BULK_IMPORTS_TEMPLATE_FILE = '/dashboard/static/imports?type=';

/**
 * reports APIs
 */

const API_GET_TRIPS_REPORT = '/dashboard/reports/trips?';

// Parent Api
const API_POST_REGISTER_PARENT = '/dashboard/register-parent';
const API_GET_PARENTS = '/dashboard/parents';
const API_GET_PARENT_BY_ID = '/dashboard/parent';
const API_GET_PARENT_BY_PHONE_NUMBER = '/dashboard/parent-by-phone-number';
const API_GET_PARENT_BY_STUDENT_ID_SCHOOL_ID = '/dashboard/parents/students';

const API_GET_UNREGISTERED_PARENTS = '/dashboard/parents/unregistered';
const API_DELETE_PARENT = '/dashboard-admin/parents/';

//Vehicle API
const API_POST_ADD_VEHICLE = '/dashboard/vehicle/';
const API_UPDATE_VEHICLE = '/dashboard/vehicle/';
const API_GET_VEHICLE_LIST = '/dashboard/list-vehicles';
const API_DELETE_VEHICLE_LIST = '/dashboard/vehicle/';

// Donation
const API_GET_DONATION_LIST = '/dashboard/donate';

// Route  Planning API
const API_POST_ROUTE_PLANNING = '/dashboard/route-planning';
const API_POST_ROUTE_DIRECTIONS = '/dashboard/route-directions';
const API_GET_CONFIRM_ROUTE = '/dashboard/route-planning/';

// Search Api
const API_GET_SEARCH_DATA = '/dashboard-search/search';
const API_GET_SEARCH_Filter = '/dashboard-search/filters';
const API_GET_SAVED_SEARCH = '/dashboard-search/save-search';
const API_POST_SAVED_SEARCH = '/dashboard-search/save-search';
const API_UPDATE_SEARCH = '/dashboard-search/save-search/';
const API_DELETE_SEARCH_LIST = '/dashboard-search/save-search/';

// School Api
const API_UPLOAD_SCHOOL_LOGO = '/dashboard/image?category=schools&sub_category=logo';
const API_REGISTER_NEW_SCHOOL = '/dashboard-admin/schools';
const API_DELETE_SCHOOL = '/dashboard-admin/schools/';

// Guided workflow Api
const API_GUIDED_WORKFLOW = '/dashboard-admin/guided-workflow';

// Policies
const API_GET_POLICIES = '/dashboard/policies';

//Communications
const API_POST_CREATE_COMMUNICATION = '/dashboard/communication';
const API_GET_LIBRARY_TEMPLATES = '/dashboard/communications/templates/library';
const API_GET_ORGANIZATION_TEMPLATES = '/dashboard/communications/templates';
const API_DELETE_ORGANIZATION_TEMPLATE = '/dashboard/communication/template/';
const API_GET_COMMUNICATION_LIST = '/dashboard/communications';
const API_GET_COMMUNICATION_DETAILS = '/dashboard/communications/deliveries';

// Forms
const API_GET_ACTIVE_FORMS = '/dashboard/forms';
const API_GET_FORM_TEMPLATES = '/dashboard/forms/templates';
const API_POST_FORM_RESPONSE = '/dashboard/forms/responses';

//Form Responses
const API_GET_FORM_RESPONSES = '/dashboard/forms/responses';

// School Website
const PUBLIC_ROUTE_DASHBOARD = '/dashboard-admin/pub-dash';

//Chatbot
const CHATBOT = '/dashboard-admin/chatbots/';
const CHATBOTIMAGE = '/dashboard/images/';

// chatbot
const ADD_CHATBOT_FAQ = '/dashboard-admin/chatbots/';
const LIST_CHATBOT = '/dashboard-admin/chatbots';

// Trip Inspections Reports
const TRIP_INSPECTION_REPORTS = '/dashboard-admin/trip-inspection/reports';
//Form Responses
const API_GET_INCIDENT_REPORT = '/dashboard-admin/trip-inspection/reports/';
const API_GET_INCIDENT_REPORT_IMAGE = '/dashboard-admin/trip-inspection/report/';

export function getAllSchoolsPerAdminTransportationUsername(adminTransportationUsername = null) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ALL_SCHOOLS)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getDriversByTransportationAdmin(
  adminTransportationUsername,
  full_details = false,
  idDriver,
) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_DRIVERS_BY_TRANSPORTATION_ADMIN, {
      queryStringParameters: {
        full_details: full_details,
        is_driver: idDriver,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getDriversByTransportationAdminDriversName(adminTransportationUsername) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_DRIVERS_BY_TRANSPORTATION_ADMIN_DRIVERS_NAME)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function registerRoute(adminTransportationUsername, request) {
  console.log(request);
  return new Promise((resolve, reject) => {
    console.log('Request ' + request);
    API.post(API_ID, API_POST_CREATE_ROUTE, {
      body: request,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function updateRoute(adminTransportationUsername, request) {
  return new Promise((resolve, reject) => {
    console.log('Update route Request ' + request);
    API.put(API_ID, API_UPDATE_ROUTE + `${request.route_id}`, {
      body: request,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function markTripBreakdown(school_id, route_id, trip_date) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_MARK_TRIP_BREAKDOWN + school_id + '/' + route_id + '/breakdown', {
      queryStringParameters: {
        trip_date: trip_date,
      },
      body: {},
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getBreakdownByTransportationAdmin(adminTransportationUsername) {
  console.log(adminTransportationUsername);
  return new Promise((resolve, reject) => {
    API.get(
      API_ID,
      API_LIST_BREAKDOWN_BY_TRANSPORTATION_ADMIN +
        '&caller_username=' +
        adminTransportationUsername,
    )
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function toggleDriverStatus(adminTransportationUsername, driver_username) {
  return new Promise((resolve, reject) => {
    API.post(
      API_ID,
      API_TOGGLE_DRIVER_STATUS + adminTransportationUsername + '/' + driver_username,
      {
        body: {},
      },
    )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getDriverTimesheet(driverId, nextToken = null) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_DRIVERS_BY_TRANSPORTATION_ADMIN + '/' + driverId + '/timesheet', {
      queryStringParameters: {
        paginate: true,
        next_timestamp: nextToken,
        // limit: 20,
        sort_order: 'DESC',
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

/***
 * Routes API
 */

export function getRoutesBySchools(school_id, route_type = null) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_ROUTES + school_id, {
      queryStringParameters: {
        route_type: route_type,
        include_stops: true,
        include_maneuvers: true,
        decode_polyline: false,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getRoutesBySchoolsPaginated(school_id, route_type = 'ALL', nextToken = null) {
  const temp_params = {
    include_stops: true,
    decode_polyline: true,
    paginated: true,
    next_school_id: Boolean(nextToken) ? nextToken?.school_id : null,
    next_session_id: Boolean(nextToken) ? nextToken?.session_id : null,
    next_route_id: Boolean(nextToken) ? nextToken?.route_id : null,
  };

  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_ROUTES + school_id, {
      queryStringParameters: temp_params,
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getRoutesBySchoolsWithCustomOptions({
  school_id,
  route_type = 'ALL',
  nextToken = null,
  include_stops = false,
  decode_polyline = false,
  paginated = false,
}) {
  const temp_params = {
    include_stops: include_stops,
    decode_polyline: decode_polyline,
    paginated: paginated,
    next_school_id: Boolean(nextToken) ? nextToken?.school_id : null,
    next_session_id: Boolean(nextToken) ? nextToken?.session_id : null,
    next_route_id: Boolean(nextToken) ? nextToken?.route_id : null,
  };

  return new Promise((resolve, reject) => {
    API.get(API_ID, API_LIST_ROUTES + school_id, {
      queryStringParameters: temp_params,
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function listRouteTrips(school_ids, route_types) {
  return new Promise((resolve, reject) => {
    let request = {
      schools: school_ids,
      route_types: route_types,
    };
    console.log('Request ' + request);
    API.post(API_ID, API_POST_LIST_ROUTE_TRIPS, {
      body: request,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getTripsBySchools(school_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_TRIPS_BY_SCHOOL + school_id)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getTripInfoByTripID(school_id, trip_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_UPDATE_TRIP + trip_id, {
      queryStringParameters: {
        school_id: school_id,
      },
    })
      .then((response) => {
        console.log('Trip Information ', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function getTripsBySchoolsByDate(school_id, date) {
  console.log(date);
  const [mm, dd, yy] = date.split('/');
  console.log(`dd:${dd} mm:${mm} yy:${yy}`);
  return new Promise((resolve, reject) => {
    API.get(
      API_ID,
      API_GET_TRIPS_BY_SCHOOL_BY_DATE + school_id + '?' + 'dd=' + dd + '&mm=' + mm + '&yy=' + yy,
    )
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getTripInfo(school_id, route_id_and_param) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_TRIP_INFO + school_id + '/' + route_id_and_param)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

export function getGeofenceTimeCode(school_id, route_id, date) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_TRIPS_GEOFENCE_TIMECODES + school_id + '/' + route_id + '/geofence', {
      queryStringParameters: {
        trip_date: date,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error, null, 2));
        reject(error.response);
      });
  });
}

// Update Trip
export function updateTrip(request, tripId) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_UPDATE_TRIP + tripId, {
      body: request,
    })
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function getActualTrips(school_id, route_id, date) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ACTUAL_ROUTE_PATH + school_id + '/' + route_id + '/actual-path', {
      queryStringParameters: {
        trip_date: date,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Actual Trips Error', error);
        reject(error.response);
      });
  });
}

export function getRouteInfo(route_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ROUTE_INFO + route_id, {
      queryStringParameters: {
        include_stops: true,
        include_maneuvers: true,
        decode_polyline: true,
      },
    }).then(
      (result) => {
        resolve(result);
      },
      (error) => {
        reject(error.response);
      },
    );
  });
}

export function getTestAuthHelloWorld() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, '/dashboard/test-auth/hello-world1').then(
      (result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

//Inspection Forms
export function getInspectionList() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_INSPECTION_FORMS, {
      queryStringParameters: {
        form_type: 'INTERNAL',
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getInspectionTemplateList() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_INSPECTION_FORMS + '/templates', {
      queryStringParameters: {
        form_type: 'INTERNAL',
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function deleteInspectionForm(id) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_INSPECTION_FORMS + '/' + id)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

export function getActiveStatus(formId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_INSPECTION_FORMS + `/${formId}/toggle`)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

/***
 * Routes Trips API
 */

export function getAllSchoolsActiveTripsStats(transportation_admin_username) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ALL_ROUTE_TRIPS_PER_SCHOOL + transportation_admin_username).then(
      (result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

export function endAllTripsForSchool(school_id) {
  return new Promise((resolve, reject) => {
    fetch(API_END_TRIP_FOR_ALL_SCHOOLS + school_id, {
      method: 'POST',
      // mode: 'CORS',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        },
      );
  });
}

/***
 * Stats API
 */

// API for route classification

export function getAllRoutesClassification() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ROUTE_CLASSIFICATIONS).then(
      (result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

export function getUsersByDate() {
  return new Promise((resolve, reject) => {
    fetch(API_STATS_GET_USERS_BY_DATE)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log('Ussssers: ' + result);
          resolve(result);
        },
        (error) => {
          reject(error);
        },
      );
  });
}

/***
 * Child API
 */

// ! old deprecated
export function getStudentsBySchool(school_id, date, nextToken = null) {
  const converted_date = new Date(date);
  const dd = converted_date.getDate();
  const mm = converted_date.getMonth() + 1;
  const yy = converted_date.getFullYear();
  console.log('Selected school to fetch students is: ' + school_id);
  console.log('API call path: ' + API_ID + API_GET_STUDENTS_BY_SCHOOL_WITH_ATTENDANCE + school_id);
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_STUDENTS_BY_SCHOOL_WITH_ATTENDANCE + school_id, {
      queryStringParameters: {
        dd: dd,
        mm: mm,
        yy: yy,
        next_school_id: nextToken === null ? undefined : nextToken['school_id'],
        next_child_hash_key: nextToken === null ? undefined : nextToken['child_hash_key'],
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log('Fetching Students error: ', error);
        reject(error);
      });
  });
}

// new student api
export function getStudentsBySchoolIdAndSession({
  school_id,
  session_id,
  nextToken = null,
  paginate = true,
}) {
  const converted_date = new Date();
  const dd = converted_date.getDate();
  const mm = converted_date.getMonth() + 1;
  const yy = converted_date.getFullYear();
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_STUDENTS_BY_SCHOOL_AND_SESSION, {
      queryStringParameters: {
        // dd: dd,
        // mm: mm,
        // yy: yy,
        school_id: school_id,
        next_school_id: nextToken === null ? undefined : nextToken['school_id'],
        next_id: nextToken === null ? undefined : nextToken['id'],
        next_session_id: nextToken === null ? undefined : nextToken['session_id'],
        paginate: paginate,
        limit: studentListCount,
        pre_assigned_url_status: true,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log('Fetching Students error: ', error);
        reject(error);
      });
  });
}

// ! old
export function registerStudent(request) {
  return new Promise((resolve, reject) => {
    console.log('from Add Student');
    console.log(request);
    API.post(API_ID, API_POST_ADD_STUDENT, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

//  new Reg student
export function registerStudentNew(request) {
  return new Promise((resolve, reject) => {
    console.log('from Add Student');
    console.log(request);
    API.post(API_ID, API_POST_ADD_STUDENT_NEW, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function deleteStudent(id, caller_username) {
  return new Promise((resolve, reject) => {
    // console.log('Request to delete student: ' + id);
    // console.log('Path is :' + API_ID, API_DELETE_STUDENT + id);

    API.del(API_ID, API_DELETE_STUDENT + id + '?caller_username=' + caller_username)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

export function deleteStudentNew(id, caller_username) {
  return new Promise((resolve, reject) => {
    // console.log('Request to delete student: ' + id);
    // console.log('Path is :' + API_ID, API_DELETE_STUDENT + id);

    API.del(API_ID, API_DELETE_STUDENT_NEW + id + '?caller_username=' + caller_username)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

// const API_UPDATE_CHILD_ATTENDANCE = "/dashboard/update-child-attendance";
// const API_GET_CHILD_ATTENDANCE = "/dashboard/child_attendance/";

export function updateChildAttendance(request) {
  return new Promise((resolve, reject) => {
    console.log('Request ' + JSON.stringify(request, null, 2));
    API.post(API_ID, API_UPDATE_CHILD_ATTENDANCE, {
      body: request,
    })
      .then((result) => {
        console.log('Updated student attendance successfully ' + JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        reject(error.response);
      });
  });
}

export function getChildAttendance() {
  // console.log("Selected school to fetch students is: " + school_id);
  console.log(
    'API call path: ' +
      API_ID +
      API_GET_CHILD_ATTENDANCE +
      10 +
      '/' +
      'AM' +
      '/' +
      1621566590 +
      '/' +
      1621568320,
  );
  return new Promise((resolve, reject) => {
    API.get(
      API_ID,
      API_GET_CHILD_ATTENDANCE + 10 + '/' + 'AM' + '/' + 1621566590 + '/' + 1621568320,
    )
      .then((response) => {
        console.log(
          'Fetched Student attendance successfully: ' + JSON.stringify(response, null, 2),
        );
        resolve(response);
      })
      .catch((error) => {
        console.log('Fetching Students attendance error: ' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

// const API_EXPORT_CHILDS_BY_SCHOOL = '/dashboard/childs/export/';

export function exportChildsBySchool(school_id) {
  console.log('Selected school to export childs is: ' + school_id);
  console.log('API call path: ' + API_ID + API_EXPORT_CHILDS_BY_SCHOOL + school_id);
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_EXPORT_CHILDS_BY_SCHOOL + school_id)
      .then((response) => {
        console.log('Exported childs successfully: ' + JSON.stringify(response, null, 2));
        resolve(response);
      })
      .catch((error) => {
        console.log('Exporting childs error: ' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

// const API_UPLOAD_CHILD_IMAGE = '/dashboard/image?category=childs';

export function uploadChildImage(fileContent) {
  console.log('>>> File COntent : ' + fileContent);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_UPLOAD_CHILD_IMAGE, {
      body: fileContent,
      headers: {
        'Content-Type': 'image/jpeg',
      },
      queryStringParameters: {
        category: 'childs',
        sub_category: 'profile-image',
      },
    })
      .then((result) => {
        console.log('upload image result', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from image api', error);

        reject(error.response);
      });
  });
}

/***
 * Users / Permissions / Access control API
 * 
 *  const API_REGISTER_DASHBOARD_USER = "/dashboard/register-user";
 const API_GET_DASHBOARD_USER = "/dashboard/user/"
 const API_GET_DASHBOARD_USERS = "/dashboard/users"
 const API_CREATE_PUT_GET_USER_PERMISSIONS = "/dashboard/user-permissions/"
 */

// status --> working
export function getAllDashboardUsers(super_username) {
  return new Promise((resolve, reject) => {
    console.log('API call path: ' + API_GET_DASHBOARD_USERS + super_username);
    API.get(API_ID, API_GET_DASHBOARD_USERS + super_username)
      .then((result) => {
        console.log('Get Dashboard Users', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Get Dashboard Error', error);
        reject(error);
      });
  });
}

export function getDashboardUser(super_username, username) {
  return new Promise((resolve, reject) => {
    console.log('Getting user data havind username :' + username);
    console.log('API call path: ' + API_GET_DASHBOARD_USER);
    API.get(API_ID, API_GET_DASHBOARD_USER + username + '?caller_username=' + super_username)
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

// status --> working
export function registerDashboardUser(
  super_username,
  firstName,
  lastName,
  phoneNumber,
  email,
  gender,
) {
  return new Promise((resolve, reject) => {
    let request = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email,
      gender: gender,
      organization_admin_username: super_username,
    };

    console.log('Request to register user: ' + JSON.stringify(request, null, 2));
    console.log('API call path: ' + API_REGISTER_DASHBOARD_USER + super_username);
    API.post(API_ID, API_REGISTER_DASHBOARD_USER + super_username, {
      body: request,
    })
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        reject(error.response);
      });
  });
}

// status --> working
export function registerUserPermsissions(super_user, username, request) {
  return new Promise((resolve, reject) => {
    API.post(
      API_ID,
      API_CREATE_PUT_GET_USER_PERMISSIONS + username + '?caller_username=' + super_user,
      {
        body: request,
      },
    )
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

//status --> working
export function updateUserPermsissions(super_user, username, request) {
  return new Promise((resolve, reject) => {
    API.put(
      API_ID,
      API_CREATE_PUT_GET_USER_PERMISSIONS + username + '?caller_username=' + super_user,
      {
        body: request,
      },
    )
      .then((result) => {
        console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

/**
 * Import APIs
 *
 * const API_VALIDATE_BULK_IMPORT_STUDENTS = "/dashboard/childs/validate?caller_username=";
 * const API_GET_BULK_IMPORT_STATUS = "/dashboard/childs/import-status/"
 * const API_CANCEL_BULK_IMPORT_STATUS = "/dashboard/childs/import-cancel/"
 */

export async function validateBulkStudentImport(caller_username, fileContent) {
  console.log('>>> File COntent : ' + fileContent);
  console.log(fileContent);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_VALIDATE_BULK_IMPORT_STUDENTS, {
      body: fileContent,
    })
      .then((result) => {
        // console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function getStudentsBulkImportStatus(caller_username, job_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_BULK_IMPORT_STATUS + job_id)
      .then((result) => {
        console.log(`Bulk import student status ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log('Bulk import student status error' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

export function cancelStudentsBulkImport(caller_username, job_id) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_CANCEL_BULK_IMPORT_STATUS + job_id, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        console.log(`Cancelled bulk import students status ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log('Cancel bulk import students  error' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

/**
 * CHILD ATTENDANCE APIs
 * const API_GET_STUDENT_ATTENDENCE_LIST_BETWEEN_DATES =
 "/dashboard/child-attendance/";
 * const API_GET_TRIP_CHILDS_ATTENDANCE = '/dashboard/child-attendance-by-route/'
 */

export function getStudentAttendenceListBeweenDates(studentId, routeType, startDate, endDate) {
  const [sdd, smm, syy] = startDate.split('/');
  const [edd, emm, eyy] = endDate.split('/');
  return new Promise((resolve, reject) => {
    console.log(`Function called with ${studentId}`);
    API.get(
      API_ID,
      API_GET_STUDENT_ATTENDENCE_LIST_BETWEEN_DATES +
        studentId +
        '/' +
        routeType +
        '?sdd=' +
        sdd +
        '&smm=' +
        smm +
        '&syy=' +
        syy +
        '&edd=' +
        edd +
        '&emm=' +
        emm +
        '&eyy=' +
        eyy,
    )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

//  Get Students by Id : GET

export function getStudentById(studentId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_STUDENT + studentId)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getTripChildsAttendance(route_id, date) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_TRIP_CHILDS_ATTENDANCE + route_id + date)
      .then((result) => {
        console.log(`Selected trip childs attendance ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log(`Selected trip childs attendance error ${JSON.stringify(error, null, 2)}`);
        reject(error);
      });
  });
}

/**
 * Reports APIs
 *
 * const API_GET_TRIPS_REPORT = "/dashboard/reports/trips?";
 */

export function getTripsReport(super_username, school_id, start_date, end_date, type) {
  console.log('Request to generate Trip report');
  return new Promise((resolve, reject) => {
    let sdd = start_date.getDate();
    let smm = start_date.getMonth() + 1;
    let syy = start_date.getFullYear();
    let edd = end_date.getDate();
    let emm = end_date.getMonth() + 1;
    let eyy = end_date.getFullYear();
    API.get(
      API_ID,
      API_GET_TRIPS_REPORT +
        'school_id=' +
        school_id +
        '&sdd=' +
        sdd +
        '&smm=' +
        smm +
        '&syy=' +
        syy +
        '&edd=' +
        edd +
        '&emm=' +
        emm +
        '&eyy=' +
        eyy +
        '&type=' +
        type +
        '&caller_username=' +
        super_username,
      {
        // responseType: 'blob',
        // response: true,
      },
    )
      .then((response) => {
        // const blob = new Blob([response.data], { type: 'application/octet-stream' })
        // const filename = response.headers['content-type']
        // if (filename == undefined) filename = "Trip Report.csv"
        // if (typeof window.navigator.msSaveBlob !== 'undefined') {
        //   window.navigator.msSaveBlob(blob, filename)
        // } else {
        //   const blobURL = window.URL.createObjectURL(blob)
        //   const tempLink = document.createElement('a')
        //   tempLink.style.display = 'none'
        //   tempLink.href = blobURL
        //   tempLink.setAttribute('download', filename)

        //   if (typeof tempLink.download === 'undefined') {
        //     tempLink.setAttribute('target', '_blank')
        //   }

        //   document.body.appendChild(tempLink)
        //   tempLink.click()
        //   document.body.removeChild(tempLink)
        //   window.URL.revokeObjectURL(blobURL)
        // }
        resolve(response);
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 2));
        reject(error.response);
      });
  });
}

// Driver Import APIs
// Bulk import status

export async function validateBulkDriverImport(caller_username, fileContent) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_VALIDATE_BULK_IMPORT_DRIVERS + caller_username, {
      body: fileContent,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((result) => {
        // console.log(JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

// Update Student by Id:put
// ! old Student update
export function updateStudentById(id, request) {
  return new Promise((resolve, reject) => {
    console.log('From update');
    console.log('from api update data is', request);
    API.put(API_ID, API_UPDATE_STUDENT + id, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log('Error from update api', error.response);
        reject(error.response);
      });
  });
}

// new Student api
export function updateStudentByIdNew(id, request) {
  return new Promise((resolve, reject) => {
    console.log('From update');
    console.log('from api update data is', request);
    API.put(API_ID, API_UPDATE_STUDENT_NEW + id, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log('Error from update api', error.response);
        reject(error.response);
      });
  });
}

// Bulk import cancel:

export function cancelDriversBulkImport(caller_username, job_id) {
  return new Promise((resolve, reject) => {
    API.post(
      API_ID,
      API_CANCEL_BULK_IMPORT_STATUS_DRIVERS + job_id + '?caller_username=' + caller_username,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((result) => {
        console.log(`Cancelled bulk import drivers status ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log('Cancel bulk import drivers  error' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

// Driver Bulk import status

export function getDriversBulkImportStatus(caller_username, job_id) {
  return new Promise((resolve, reject) => {
    API.get(
      API_ID,
      API_GET_BULK_IMPORT_STATUS_DRIVERS + job_id + '?caller_username=' + caller_username,
    )
      .then((result) => {
        console.log(`Bulk import Driver status ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log('Bulk import Driver status error' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

/**
 * const API_GET_BULK_IMPORTS_TEMPLATE_FILE = '/dashboard/static/imports?type=';
 */

export function getBulkImportTemplateFile(type) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_BULK_IMPORTS_TEMPLATE_FILE + type)
      .then((result) => {
        console.log(`Bulk import template file ${JSON.stringify(result, null, 2)}`);
        resolve(result);
      })
      .catch((error) => {
        console.log('Bulk import template file error' + JSON.stringify(error, null, 2));
        reject(error);
      });
  });
}

// Register Parent

export function registerParent(
  admin,
  firstName,
  lastName,
  phoneNumber,
  email,
  student_id,
  school_id,
) {
  let request = {
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber.includes('+1') ? phoneNumber : '+1' + phoneNumber,
    email: email,
    school_id: school_id,
    student_id: student_id,
  };
  console.log(request);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_GET_PARENTS + '?' + 'caller_username=' + admin, {
      body: request,
    })
      .then((result) => {
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

// GEt Parents
export function getRegisteredParents(adminTransportationUsername) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_PARENTS + '?caller_username=' + adminTransportationUsername)
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

//  Get Parent By ID:
export function getParent(admin, parentId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_PARENT_BY_ID + '/' + parentId + '?caller_username=' + admin)
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

// Get Parent By Phone Number:
export function getParentByPhoneNumber(phoneNumber) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_PARENT_BY_PHONE_NUMBER + '/' + phoneNumber)
      .then((response) => {
        console.log('Parent account info: ', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Get parent account by phone number error: ', JSON.stringify(error, null, 2));
        reject(error.message);
      });
  });
}

// Parent Info by Student ID and School ID 
export function getParentByStudentIDandSchoolID({ id, selectedSchoolId }) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_PARENT_BY_STUDENT_ID_SCHOOL_ID + '/' + id, {
      queryStringParameters: {
        school_id: selectedSchoolId,
      },
    })
      .then((response) => {
        console.log('Parent account info by student id : ', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Get parent account by student id : ', JSON.stringify(error, null, 2));
        reject(error.message);
      });
  });
}

// Get UnregisteredParents
export function getUnregisteredParents(adminTransportationUsername, schoolId) {
  return new Promise((resolve, reject) => {
    API.get(
      API_ID,
      API_GET_UNREGISTERED_PARENTS +
        '/' +
        schoolId +
        '?caller_username=' +
        adminTransportationUsername,
    )
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function liveView(school_ids, route_types, date, username) {
  return new Promise((resolve, reject) => {
    const d = date;
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    let route_type_param = '';
    let school_ids_param = '';
    for (let x in route_types) {
      route_type_param += route_types[x];
      if (x < route_types.length - 1) {
        route_type_param += ',';
      }
    }
    for (let x in school_ids) {
      school_ids_param += school_ids[x];
      if (x < school_ids.length - 1) {
        school_ids_param += ',';
      }
    }
    API.get(API_ID, API_POST_LIVE_VIEW, {
      queryStringParameters: {
        dd: dd,
        mm: mm,
        yy: yy,
        route_types: route_type_param,
        school_ids: school_ids_param,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function deleteUser(userId, adminId) {
  return new Promise((resolve, reject) => {
    console.log('Request to delete user: ' + userId);
    console.log('Request to delete user by admin: ' + adminId);
    console.log('Path is :' + API_ID, API_DELETE_USER + userId + '?caller_username=' + adminId);

    API.del(API_ID, API_DELETE_USER + userId + '?caller_username=' + adminId)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

//  Delete a Driver
export function deleteParent(userName, parentId) {
  console.log('Request to delete parent: ' + parentId + ' ' + userName);
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_PARENT + parentId + '?' + 'caller_username=' + userName)
      .then((result) => {
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function deleteUserPermission(userId, adminId) {
  return new Promise((resolve, reject) => {
    console.log('Request to delete user permission: ' + userId);
    console.log('Request to delete user permission by admin: ' + userId);
    console.log(
      'Path is :' + API_ID,
      API_DELETE_USER_PERMISSION + userId + '?caller_username=' + adminId,
    );

    API.del(API_ID, API_DELETE_USER_PERMISSION + userId + '?caller_username=' + adminId)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

export function deleteRoute(route_id, caller_username) {
  console.log(API_ID, API_DELETE_ROUTE + route_id);
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_ROUTE + route_id)
      .then((result) => {
        console.log('Route delete response: ', JSON.stringify(result, null, 2));
        resolve(result);
      })
      .catch((error) => {
        console.log('error : ', JSON.stringify(error, null, 2));
        reject(error.response);
      });
  });
}

// Get sPEED Info
export function getSpeedInfo(school_id, routeId, tripDate) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_SPEED_INFO + school_id + '/' + routeId + '/' + 'speed_info', {
      queryStringParameters: {
        trip_date: tripDate,
      },
    })
      .then((response) => {
        console.log('Speed Graph', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

//! old Get Student Qr Code
export function getStudentQrCode(studentId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, GET_STUDENT_QR_CODE + studentId)
      .then((response) => {
        console.log('Qr Response', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

// {{API_URL}}/dashboard/students/qrcode/1695213052f803de04

export function getStudentQrCodeNew(studentId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, GET_STUDENT_QR_CODE_NEW + studentId)
      .then((response) => {
        console.log('Qr Response', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

// Student Image API
export function getStudentImageNew(studentId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, GET_STUDENT_IMAGE_NEW + studentId)
      .then((response) => {
        console.log('Image Response', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function getStudentAttendanceByTrip(school_id, trip_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, GET_STUDENT_ATTENDANCE_BY_TRIP_ID + trip_id, {
      queryStringParameters: {
        school_id: school_id,
      },
    })
      .then((response) => {
        console.log('Student Attendance by Trip ID', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

//Vehicle API
export function getStudentsByRouteId(routeId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_STUDENT_BY_ROUTE_ID + routeId)
      .then((response) => {
        // console.log('Student Response', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

export function getVehiclesList() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_VEHICLE_LIST)
      .then((response) => {
        // console.log('Vehicle List', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Vehicle Error', error);
        reject(error.response);
      });
  });
}

export function addVehicles(request) {
  return new Promise((resolve, reject) => {
    console.log(request);
    API.post(API_ID, API_POST_ADD_VEHICLE, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log('Vehicle');
        console.log(result);
      })
      .catch((error) => {
        console.log('Error add vehicle', error.response);
        reject(error.response);
      });
  });
}

export function deleteVehicle(vehicleId) {
  console.log('Delete vehicle' + vehicleId);
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_VEHICLE_LIST + vehicleId)
      .then((result) => {
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error delete vehicle', error.response);
        reject(error.response);
      });
  });
}

export function updateVehicles(request, vehicle_id) {
  return new Promise((resolve, reject) => {
    console.log('Vehicle Updated', request);
    API.put(API_ID, API_UPDATE_VEHICLE + vehicle_id, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log('Error update vehicle', error.response);
        reject(error.response);
      });
  });
}

export function getAllStudentsBySchoolId(schoolId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ALL_STUDENTS_BY_SCHOOL_ID + schoolId)
      .then((response) => {
        // console.log('Students List', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Student Error', error);
        reject(error.response);
      });
  });
}

export function getDonationList(limit, startingAfter) {
  let url = API_GET_DONATION_LIST;
  if (limit && startingAfter) {
    url += '?limit=' + limit + '&&starting_after=' + startingAfter;
  } else if (limit) {
    url += '?limit=' + limit;
  } else if (startingAfter) {
    url += '?starting_after=' + startingAfter;
  }
  console.log('URL is', url);
  return new Promise((resolve, reject) => {
    API.get(API_ID, url)
      .then((response) => {
        // console.log('Donation Response', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Donation Error', error);
        reject(error.response);
      });
  });
}

export function postRoutePlanning(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_POST_ROUTE_PLANNING, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from route planning', error.response);
        reject(error.response);
      });
  });
}

export function visualizeRoutePlanning(waypoints) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_POST_ROUTE_DIRECTIONS, {
      body: waypoints,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from visualize api', error.response);
        reject(error.response);
      });
  });
}

export function confirmRoutePlanning(username, jobId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_CONFIRM_ROUTE + jobId + '/confirm?caller_username' + username)
      .then((response) => {
        // console.log('Route Confirm Result', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Route Confirm Error', error);
        reject(error.response);
      });
  });
}

export function getStatusRoutePlanning(username, jobId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_CONFIRM_ROUTE + jobId + '/status?caller_username' + username)
      .then((response) => {
        // console.log('Route Status Result', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Route Status Error', error);
        reject(error.response);
      });
  });
}

export function cancelRoutePlanning(username, jobId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_CONFIRM_ROUTE + jobId + '/cancel?caller_username' + username)
      .then((response) => {
        // console.log('Route Cancel Result', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Route Cancel Error', error);
        reject(error.response);
      });
  });
}

export function getTripCountAndDataBySchoolByDate(school_id, dd, mm, yy) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, STATISTICS_HOME + school_id + '/route-trips', {
      queryStringParameters: {
        dd: dd,
        mm: mm,
        yy: yy,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getRouteDataBySchoolByDate(school_id, dd, mm, yy) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, STATISTICS_HOME + school_id + '/routes', {
      queryStringParameters: {
        dd: dd,
        mm: mm,
        yy: yy,
      },
    })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getAccountInformation() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ACCOUNT_INFORMATION)
      .then((response) => {
        // console.log('Account information is', response);
        resolve(response);
      })
      .catch((error) => {
        console.log('Get account information API error', error);
        reject(error.response);
      });
  });
}

export function uploadDriverAdditionalImage(fileContent, subCategory) {
  // console.log(`>>> Driver File Content : subCategory = ${subCategory} : ` + fileContent);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_UPLOAD_DRIVER_ADDITIONAL_IMAGE + subCategory, {
      body: fileContent,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    })
      .then((result) => {
        console.log('upload driver additional', subCategory, ' result', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from upload driver additional', subCategory, ' api', error);

        reject(error.response);
      });
  });
}

export function registerDriverAdditionalInfo(isAdditionalInfo, requestBody) {
  // console.log('From Regiester driver additional info Api', requestBody);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_REGISTER_DRIVER_INFO + isAdditionalInfo, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
        console.log('Driver registered with is_additional_info=', requestBody, result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function updateDriverAdditionalInfo(driverId, isAdditionalInfo, requestBody) {
  // console.log('From update driver additional info Api',driverId,isAdditionalInfo, requestBody);
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_ADMIN_DRIVERS + driverId + '?is_additional_info=' + isAdditionalInfo, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
        console.log('Driver updated with is_additional_info=', requestBody, result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

// Get Search Result
export function getSearchData(category, query, filters, starting_after = 0) {
  return new Promise((resolve, reject) => {
    // API.get(API_ID, API_GET_SEARCH_DATA + '?category=' + category + '&query=' + query)
    API.post(API_ID, API_GET_SEARCH_DATA + '?category=' + category, {
      queryStringParameters: {
        starting_after: starting_after,
      },
      body: {
        query: query,
        filters: filters,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log('Getting search data error', error);
        reject(error.response);
      });
  });
}

export function driverToogleStatus(driver_username) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_ADMIN_DRIVERS + driver_username + API_DRIVER_TOGGLE_STATUS)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function deleteDriver(driverId) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_ADMIN_DRIVERS + driverId)
      .then((result) => {
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error.response);
      });
  });
}

// Get Search filters
export function getSearchFilter(category, query) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_SEARCH_Filter + '?category=' + category + '&query=' + query)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log('Error in getting search filters : ', error);
        reject(error.response);
      });
  });
}

// Get saved search
export function getSavedSearch() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_SAVED_SEARCH)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log('Error while getting saved search', error);
      });
  });
}

// Update Search
export function updateSearch(id, request) {
  return new Promise((resolve, reject) => {
    console.log('from api update data is', request);
    API.put(API_ID, API_UPDATE_SEARCH + id, {
      body: request,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from update api', error.response);
        reject(error.response);
      });
  });
}

//Delete Search
export function deleteSearch(searchId) {
  console.log('Delete Search' + searchId);
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_SEARCH_LIST + searchId)
      .then((result) => {
        console.log(result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error delete search', error.response);
        reject(error.response);
      });
  });
}

// Save Search
export function saveSearch(request) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_POST_SAVED_SEARCH, {
      body: request,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from update api', error.response);
        reject(error.response);
      });
  });
}

// school api
export function uploadSchoolLogo(fileContent) {
  console.log('>>> File COntent : ' + fileContent);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_UPLOAD_SCHOOL_LOGO, {
      body: fileContent,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    })
      .then((result) => {
        console.log('upload schoo logo result', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from school logo api', error);

        reject(error.response);
      });
  });
}

export function registerSchool(requestBody, is_guided = 'False') {
  // console.log('request body for register school', requestBody);
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_REGISTER_NEW_SCHOOL + '?is_guided=' + is_guided, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('School registered with ', requestBody, result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function updateSchool(requestBody, school_id) {
  // console.log('request body for register school', requestBody);
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_REGISTER_NEW_SCHOOL + '/' + school_id, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('School updated with ', requestBody, result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function deleteSchool(schoolId, is_guided = 'True') {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_SCHOOL + schoolId + '?is_guided=' + is_guided)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

export function getGuidedWorkflow() {
  console.log('[API][REQUEST] Guided workflow');
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GUIDED_WORKFLOW)
      .then((response) => {
        console.log('[API][RESPONSE] Guided workflow: ', response);
        resolve(response);
      })
      .catch((error) => {
        console.error('[API][ERROR] Guided workflow', error);
        reject(error);
      });
  });
}

export function getPolicies() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_POLICIES)
      .then((response) => {
        console.log('Policies', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function updateGuidedWorkFlow(requestBody) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_GUIDED_WORKFLOW, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('Guided workflow updated with ', result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getActiveForms() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ACTIVE_FORMS)
      .then((response) => {
        console.log('Forms list', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function createForm(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_GET_ACTIVE_FORMS, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function updateFormInfo(requestBody, formId) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_GET_ACTIVE_FORMS + '/' + formId, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getFormTemplates() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_FORM_TEMPLATES)
      .then((response) => {
        console.log('Form template list', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function createFormResponse(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_POST_FORM_RESPONSE, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
        console.log('Commulication List Data ', result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

// ! Deprecated soon
export function createCommunication(requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_POST_CREATE_COMMUNICATION, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from Post api from CreateCommunication', error.response);
        reject(error.response);
      });
  });
}

export function gethistoricalCommunicationList(requestBody, nextToken) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_GET_COMMUNICATION_LIST, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('List Communication Data', result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getCommunicationTemplatesLibrary() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_LIBRARY_TEMPLATES)
      .then((response) => {
        console.log('Templates', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function getCommunicationTemplatesOrganization() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_ORGANIZATION_TEMPLATES)
      .then((response) => {
        console.log('Templates', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function deleteOrganizationTemplate(id) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DELETE_ORGANIZATION_TEMPLATE + id)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

export function getCommunicationDetails(communicationID, mode, requestBody) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_GET_COMMUNICATION_DETAILS + '/' + communicationID + '/' + mode, {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
        console.log('Commulication List Data ', result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getFormResponses() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_FORM_RESPONSES)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function getListPublicRouteDashboard() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, PUBLIC_ROUTE_DASHBOARD)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function getTogglePublicDashboardStatus(schoolID) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, PUBLIC_ROUTE_DASHBOARD + `/${schoolID}/toggle`)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function updatePublicRouteDashboardConfig(requestBody, id) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, PUBLIC_ROUTE_DASHBOARD + '/' + id, {
      body: requestBody,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getChatbotData(school_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, CHATBOT + school_id)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log('Get Error', error);
        reject(error);
      });
  });
}

export function updateChatbot(request, school_id) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, CHATBOT + school_id, {
      body: request,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log('Error update vehicle', error.response);
        reject(error.response);
      });
  });
}

export function uploadImages(school_id, fileContent, category, sub_category) {
  console.log('File: ' + fileContent);
  return new Promise((resolve, reject) => {
    API.post(API_ID, CHATBOTIMAGE + school_id, {
      body: fileContent,
      headers: {
        'Content-Type': 'image/jpeg',
      },
      queryStringParameters: {
        category: category,
        sub_category: sub_category,
      },
    })
      .then((result) => {
        console.log('upload image result', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error from image api', error);

        reject(error.response);
      });
  });
}

export function addChatBotFaq(requestBody, schoolID) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, ADD_CHATBOT_FAQ + schoolID + '/faqs', {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getListFaqs(schoolID) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, ADD_CHATBOT_FAQ + schoolID + '/faqs')
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function deleteFaq(schoolID, deleteID) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, ADD_CHATBOT_FAQ + schoolID + '/faqs/' + deleteID)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function updateFaq(schoolID, requestBody) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, ADD_CHATBOT_FAQ + schoolID + '/faqs/' + requestBody.faq_id, {
      body: requestBody,
    })
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function getListOfChatBot() {
  return new Promise((resolve, reject) => {
    API.get(API_ID, LIST_CHATBOT)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

export function getToggleAllChatBotStatus(schoolID) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, LIST_CHATBOT + `/${schoolID}/toggle`)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

// Links Registrer
export function addChatBotExternalLinks(requestBody, schoolID) {
  return new Promise((resolve, reject) => {
    API.post(API_ID, ADD_CHATBOT_FAQ + schoolID + '/links', {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export function getListOfExternalLinksChatBot(schoolID) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, ADD_CHATBOT_FAQ + schoolID + '/links')
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

export function deleteExternalLinkOfChatBot(schoolID, deleteID) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, ADD_CHATBOT_FAQ + schoolID + '/links/' + deleteID)
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

/**
 * Retrieves trip inspection reports for a specific date.
 *
 * @param {string} dateValue - The date for which trip inspection reports are to be retrieved.
 *                             This should be in a format supported by the Date constructor.
 * @returns {Promise} - A promise that resolves with the trip inspection reports data if the request is successful,
 *                      otherwise rejects with an error.
 */
export function getTripInspectionReports(dateValue) {
  // Extracting day, month, and year from the provided dateValue
  const dateData = {
    dd: new Date(dateValue).getDate(),
    mm: new Date(dateValue).getMonth() + 1, // Adding 1 because getMonth() returns a zero-based index
    yy: new Date(dateValue).getFullYear(),
  };

  // Making a request to fetch trip inspection reports using the API
  return new Promise((resolve, reject) => {
    API.get(API_ID, TRIP_INSPECTION_REPORTS, {
      queryStringParameters: dateData,
    })
      .then((response) => {
        console.log('Form Responses', response);
        resolve(response); // Resolving the promise with the response data
      })
      .catch((error) => {
        console.log(error.response);
        reject(error); // Rejecting the promise with the error
      });
  });
}

export function getInspectionReport(incidentID) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_INCIDENT_REPORT + incidentID)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}

// Get Trip Inspection Report Image API
export function getInspectionReportImage(inspectionReportID, imageId) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_GET_INCIDENT_REPORT_IMAGE + inspectionReportID + '/img/' + imageId)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
}
