import { useState, useRef, useEffect, useMemo } from 'react';

// third-party
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

// APIs
import { getSessionList, deleteSession } from 'clients';

// MUI
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';

// project imports
import DeleteAlert from 'components/alert/DeleteAlert';
import MainCard from 'components/MainCard';
import Transitions from 'components/extended/Transitions';
import SessionList from './SessionList';

// redux - actions
import { currentSessionData } from 'actions/globalActions';

// utils
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import { getFormattedSessionStatus, getSessionStatusColor } from '../Utils';

// #svg
import sessionIcon from '../../../assets/session_icon.svg';
import CachedIcon from '@mui/icons-material/Cached';

const sessionPlaceHolder = {
  name: 'Select a session',
  status: null,
};

function move(array, from, to, on = 1) {
  return array.splice(to, 0, ...array.splice(from, on)), array;
}

function checkStatus(session) {
  return session.status === 'active';
}

// ==============================|| SESSION - HEADER ||============================== //

const SessionHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  // session data & popup states
  const [selectedSessionData, setSelectedSessionData] = useState(sessionPlaceHolder);
  const [sessionsData, setSessionsData] = useState([]);
  const [open, setOpen] = useState(false);

  // Delete state variables
  const [sessionIdDelete, setSessionIdDelete] = useState('');
  const [openDeleteSessionAlert, setOpenDeleteSessionAlert] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [sessionReload, setSessionReload] = useState(0);

  // console.log(location.pathname);
  /**
   * anchorRef is used on different components and specifying one type leads to other
   * components throwing an error
   * */
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const firstUpdate = useRef(true);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCanvasClick = (event, sessionId) => {
    window.open(`https://canvas.spotbus.app/${sessionId}`, '_blank', 'noopener, noreferrer');
    event.stopPropagation();
  };

  const handleEditClick = (event, sessionData) => {
    navigate(`/sessions/edit/${sessionData?.id}`, {
      state: {
        sessionData: sessionData,
        isUpdate: true,
      },
    });
    handleToggle();
    event.stopPropagation();
  };

  const handleDeleteClick = (event, sessionData) => {
    setOpenDeleteSessionAlert(true);
    setSessionIdDelete(sessionData.id);
    event.stopPropagation();
  };

  const handleListItemClick = (session) => {
    setSelectedSessionData(session);
    dispatch(currentSessionData(session));
    localStorage.setItem('currentSessionId', session.id);
    handleToggle();
  };

  const loadSessions = () => {
    setButtonLoader(true);
    getSessionList().then(
      (result) => {
        if (result && result.length === 0) return;
        // making active status as first item on the list

        var tmp_results = JSON.parse(JSON.stringify(result));

        var active_status_index = tmp_results.findIndex(checkStatus);
        move(tmp_results, active_status_index, 0);
        setSessionsData(tmp_results);
        localStorage.setItem('currentSessionId', tmp_results[0].id);
        // setting selected session data in states and redux store.
        let localSessionId = localStorage.getItem('currentSessionId');
        var localSessionExistInDatabase = false;
        if (localSessionId) {
          for (let tmp in tmp_results) {
            let s = tmp_results[tmp];
            if (s.id === localSessionId) {
              localSessionExistInDatabase = true;
              dispatch(currentSessionData(s));
              setSelectedSessionData(s);
            }
          }
        }
        if (!localSessionExistInDatabase) {
          // let autoSelectedSession = tmp_results?.[0];
          let autoSelectedSession = sessionsData.filter((obj) => obj.status === 'active');

          localStorage.setItem('currentSessionId', autoSelectedSession.id);
          dispatch(currentSessionData(autoSelectedSession));
          setSelectedSessionData(autoSelectedSession);
        }
        setButtonLoader(false);
      },

      (error) => {
        setButtonLoader(false);
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        });
      },
    );
  };

  //Delete Session
  const handleDeleteSession = async () => {
    setButtonLoader(true);
    try {
      await deleteSession(sessionIdDelete);
      enqueueSnackbar('Session deleted successfully', {
        variant: 'success',
      });
      loadSessions();
      let sessionIdIndex = location.pathname
        .toString()
        .split('/')
        .slice(-1);
      setButtonLoader(false);
      if (sessionIdIndex[0] === sessionIdDelete) {
        navigate(`/sessions/create`);
      }
      setOpenDeleteSessionAlert(false);
      setSessionIdDelete('');
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    if (firstUpdate.current || location.pathname === '/') {
      loadSessions();
    }
    firstUpdate.current = false;
    return () => {
      closeSnackbar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, sessionReload]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleRefreshSession = (event) => {
    event.stopPropagation();
    setSessionReload(sessionReload + 1);
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase
          id='navigationSessionButton'
          sx={{
            height: 36,
            width: 'auto',
            borderRadius: theme.shape.borderRadius,
            px: 2,
            cursor: 'pointer',
            '&:hover': { bgcolor: 'hover.default' },
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          <Box sx={{ mr: 2 }}>
            <img src={sessionIcon} alt='session_icon_svg' />
          </Box>
          <Tooltip title={selectedSessionData ? 'Change session' : 'Select a session'}>
            <Typography variant='h6' alignItems='flex-end'>
              {selectedSessionData.name}{' '}
              {selectedSessionData.status && (
                <Chip
                  variant='outlined'
                  sx={{ minWidth: 50, ml: 1, mt: -1, height: 20 }}
                  label={getFormattedSessionStatus(selectedSessionData.status)}
                  color={getSessionStatusColor(selectedSessionData.status)}
                />
              )}
            </Typography>
          </Tooltip>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction='column'>
                    <Grid
                      item
                      xs={12}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ px: 2 }}
                    >
                      <Stack
                        direction='row'
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        spacing={12}
                      >
                        <Grid container>
                          <Typography align='center' variant='h5'>
                            Sessions
                          </Typography>
                          <Chip label={sessionsData?.length} color='primary' size='small' />
                        </Grid>
                        <Tooltip title='Reload Session'>
                          <IconButton
                            disabled={buttonLoader}
                            onClick={(event) => handleRefreshSession(event)}
                          >
                            <CachedIcon className={buttonLoader ? 'spin' : null} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        maxHeight: '420px',
                        overflowY: 'scroll',
                        colorScheme: theme.palette.mode,
                      }}
                    >
                      <SessionList
                        sessionsData={sessionsData}
                        handleCanvasClick={handleCanvasClick}
                        handleEditClick={handleEditClick}
                        handleListItemClick={handleListItemClick}
                        handleDeleteClick={handleDeleteClick}
                        setOpen={setOpen}
                        selectedSessionData={selectedSessionData}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button
                      size='small'
                      disableElevation
                      onClick={(e) => {
                        navigate(`/sessions/create`);
                        handleClose(e);
                      }}
                    >
                      Create new session
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <DeleteAlert
        deleteItemName={'Session'}
        open={openDeleteSessionAlert}
        setOpen={() => setOpenDeleteSessionAlert(true)}
        setClose={() => setOpenDeleteSessionAlert(false)}
        handleDelete={handleDeleteSession}
        buttonLoader={buttonLoader}
      />
    </>
  );
};

export default SessionHeader;
