import React, { Component } from 'react';

export default class ValidatorComponent extends Component {

    constructor(props){
        super(props)
        this.state = {
            isValid: true,
            errorMessage: undefined,
            defaultValidationRules: []
        }
    }
    getDefaultValidationRules(){
        return []
    }
    validate(value){

        let defaultValidators = this.getDefaultValidationRules();

        // Default Validation
        for(var i=0;i<defaultValidators.length;i++){
            if(!defaultValidators[i].function(value)){
                this.setState({
                    errorMessage: defaultValidators[i].errorMessage,
                })
                this.props.validationstatushandler(false)
                return true;
            } else {
                this.setState({
                    errorMessage: undefined
                })
            }
        }

        let validators = this.props.validationRules || [];
        // Props Validation
        for(var i=0;i<validators.length;i++){
            if(!validators[i].function(value)){
                this.setState({
                    errorMessage: validators[i].errorMessage
                })
                this.props.validationstatushandler(false)
                return true;
            } else {
                this.setState({
                    errorMessage: undefined
                })
            }
        }
        this.props.validationstatushandler(true)

    }
    
}