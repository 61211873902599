import React, { useState } from 'react';

// material-ui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';

// project imports
import VehicleForm from './Forms/VehicleForm';
import { getErrorMessage } from '../../Utils/ErrorHandling';
import { FormHeader } from 'components/SectionForms/FormHeader';

// APIs
import { addVehicles, updateVehicles } from '../../../../clients/SpotBus';

// third party
import { useSnackbar } from 'notistack';
import AddEditForm from './Forms/AddEditForm';
// icons
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
const initialVal = [
  {
    capability: '',
    device_id: '',
  },
  {
    capability: '',
    device_id: '',
  },
  {
    capability: '',
    device_id: '',
  },
];
export default function AddEditVehicle(props) {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const surveillanceCameraTypes = [
    'Front cameras',
    'Back cameras',
    'Side cameras',
    'Internal cameras',
  ];
  // const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [licenseNumber, setLicenseNumber] = useState('');
  const [modelNumber, setModelNumber] = useState('');
  const [manufacturingCompany, setManufacturingCompany] = useState('');
  const [capacity, setCapacity] = useState('');
  const [disabledSeating, setDisabledSeating] = useState('');
  const [transportationOrganization, setTransportationOrganization] = useState('');
  const [fuel, setFuel] = React.useState(10);
  const [disableAccessibility, setDisableAccessibility] = useState('');
  const [trackingNavigation, setTrackingNavigation] = useState('');
  const [track, setTrack] = React.useState('');
  const [installationDate, setInstallationDate] = React.useState(new Date());
  const [disableSeating, setDisableSeat] = React.useState(false);
  const [modelYear, setModelYear] = React.useState(new Date());
  const [front, setFront] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [side, setSide] = React.useState(false);
  const [internal, setInternal] = React.useState(false);
  const [model, setModel] = React.useState('');
  const [deviceId, setDeviceId] = React.useState('');
  const [isApiSuccesfull, setIsApiSuccesfull] = React.useState(false);
  const [selectedData, setSelectedData] = useState(initialVal);

  const [menuOpen, setMenuOpen] = React.useState('');

  const [seat, setSeat] = React.useState('');

  const [navigation, setNavigation] = React.useState('');

  const [disabledSeatCount, setDisabledSeatCount] = React.useState('');
  const [flag, setFlag] = React.useState(false);

  //Button Loader
  const [buttonLoader, setButtonLoader] = React.useState(false);

  const handleDateChange = (date) => {
    setInstallationDate(date);
  };

  const seatsAvailable = (event) => {
    setDisableSeat(event.target.value);
  };

  const trackNavigate = (event) => {
    setTrack(event.target.value);
  };

  const valuetext = (value) => {
    setFuel(value);
    return `${value} Gallons`;
  };

  const handleFuelChange = (event, newValue) => {
    event.preventDefault();
    setFuel(newValue);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const disableSeat = (event) => {
    setSeat(event.target.value);
  };

  const enableNavigation = (event) => {
    setNavigation(event.target.value);
  };

  const clearAllFormStates = () => {
    setTrack('');
    setDeviceId('');
    setSeat('');
    setInstallationDate(new Date());
    setDisableSeat(false);
    setModelYear(new Date());
    setFront(false);
    setBack(false);
    setSide(false);
    setInternal(false);
    // setFuel('');
    setModel('');
    setLicenseNumber('');
    setManufacturingCompany('');
    setTransportationOrganization('');
    setModelNumber('');
    setCapacity('');
    setDisabledSeatCount('');
    setFlag(false);
    setSelectedData(initialVal);
  };

  const handleEdit = () => {
    setLicenseNumber(props.editData?.licence_plate_number);
    setManufacturingCompany(props.editData?.manufacturing_company);
    setTransportationOrganization(props.editData?.transportation_org);
    setModelNumber(props.editData?.model_number);
    setModelYear(new Date(`Jan 01, ${props.editData?.model_year} 00:20:18`));
    setCapacity(props.editData?.seating_capacity);
    setFront(props.editData?.vehicle_surveillance.cameras?.front);
    setBack(props.editData?.vehicle_surveillance.cameras?.back);
    setSide(props.editData?.vehicle_surveillance.cameras?.side);
    setInternal(props.editData?.vehicle_surveillance.cameras?.internal);
    setFuel(props.editData?.fuel_capacity);
    setSeat(props.editData?.disable_accessibility.status ? true : false);
    setDisabledSeatCount(props.editData?.disable_accessibility.available_seats);
    setTrack(props.editData.tracking_and_navigation_device?.model ? true : false);
    setModel(props.editData?.tracking_and_navigation_device.model ?? '');
    setDeviceId(props.editData?.tracking_and_navigation_device.device_id ?? '');
    let installingDate =
      props.editData?.tracking_and_navigation_device?.installation_date === undefined
        ? new Date()
        : new Date(props.editData?.tracking_and_navigation_device?.installation_date * 1000);
    setInstallationDate(installingDate);
    setFlag(false);
    setSelectedData(props.editData?.devices.length > 0 ? props.editData?.devices : initialVal);
  };

  const handleSubmit = async (values) => {
    if (props.isFromGuided === true) {
      props.setloadingData(true);
    }
    try {
      let request = {
        licence_plate_number: licenseNumber,
        manufacturing_company: manufacturingCompany,
        transportation_org: transportationOrganization,
        model_number: modelNumber,
        model_year: modelYear.getFullYear(),
        seating_capacity: parseInt(capacity),
        fuel_capacity: fuel.toString(),
        disable_accessibility: {
          status: disabledSeating,
          available_seats: disableSeating === true ? parseInt(disabledSeatCount) : '',
        },
        tracking_and_navigation_device: {
          model: track === true ? model : null,
          device_id: track === true ? deviceId : null,
          installation_date: track === true ? parseInt(installationDate / 1000) : null,
        },
        vehicle_surveillance: {
          cameras: { front: front, back: back, side: side, internal: internal },
        },
        devices: selectedData,
      };

      if (props.editVehicleForm === true) {
        let requestUpdate = {
          licence_plate_number: licenseNumber,
          manufacturing_company: manufacturingCompany,
          transportation_org: transportationOrganization,
          model_number: modelNumber,
          model_year: modelYear.getFullYear(),
          seating_capacity: parseInt(capacity),
          fuel_capacity: fuel.toString(),
          disable_accessibility: {
            status: seat,
            available_seats: seat === true ? parseInt(disabledSeatCount) : '',
          },
          tracking_and_navigation_device: {
            model: track === true ? model : null,
            device_id: track === true ? deviceId : null,
            installation_date: track === true ? parseInt(installationDate / 1000) : null,
          },
          vehicle_id: props?.editData.vehicle_id,
          vehicle_surveillance: {
            cameras: { front: front, back: back, side: side, internal: internal },
          },
          devices: selectedData,
        };
        const result = await updateVehicles(requestUpdate, props?.editData.vehicle_id);
        setTimeout(function() {
          enqueueSnackbar('Vehicle updated successfully', {
            variant: 'success',
          });
        }, 10);
        setButtonLoader(false);
        clearAllFormStates();
        if (props.isFromGuided === true) {
          props.setloadingData(false);
          props.getAllVehicles();
          props.setEditVehicleForm(false);
        } else {
          props.setIsEditing(false);
          props.handleGetVehiclesList();
          props.closeDialog();
          props.handleUpdateVehiclesDataAfterAction(values.vehicleId, 'update', result);
        }
      } else {
        try {
          const result = await addVehicles(request);
          setSelectedData(initialVal);
          if (props.isFromGuided) {
            props.handleUpdateGuidedWorkflowData(1);
          }
          setIsApiSuccesfull(true);
          enqueueSnackbar('Vehicle Successfully added', {
            variant: 'success',
          });
          clearAllFormStates();
          setButtonLoader(false);
          if (props.isFromGuided === true) {
            props.setloadingData(false);
          }
        } catch (error) {
          setIsApiSuccesfull(false);
          enqueueSnackbar('Something went wrong, please try after sometime', {
            variant: 'error',
          });
          setButtonLoader(false);
          if (props.isFromGuided === true) {
            props.setloadingData(false);
          }
        } finally {
          if (props.isFromGuided === true) {
            props.getAllVehicles();
            props.setEditVehicleForm(false);
          } else {
            props.handleGetVehiclesList();
            props.closeDialog();
          }
        }
      }
    } catch (error) {
      !props.isFromGuided && props.closeDialog();
      console.log('Error registering vehicle: ' + error);
      setButtonLoader(false);
      setTimeout(function() {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        });
      }, 4000);
    }
  };

  const handleFront = () => {
    setFront(!front);
  };

  const handleBack = () => {
    setBack(!back);
  };

  const handleSide = () => {
    setSide(!side);
  };

  const handleInternal = () => {
    setInternal(!internal);
  };

  React.useEffect(() => {
    clearAllFormStates();
    if (props?.editVehicleForm === true) {
      handleEdit();
    }
  }, [props?.editData, props.closeDialog]);

  return (
    <div>
      {!props.isFromGuided ? (
        <Dialog
          id='vehicle-form-dialog'
          open={props.openDialog}
          aria-labelledby='form-dialog-title'
          fullWidth='md'
          maxWidth='md'
        >
          <DialogTitle sx={{ p: 0, m: 0 }}>
            <FormHeader
              title={
                props?.editVehicleForm === true
                  ? 'Edit Vehicle Information'
                  : 'Add Vehicle Information'
              }
              id={'close-vehicle-form'}
              onClose={props.closeDialog}
              disableClose={buttonLoader}
              icon={<DirectionsBusFilledIcon />}
            />
          </DialogTitle>
          <DialogContent dividers>
            <AddEditForm
              disabledSeatCount={disabledSeatCount}
              navigation={navigation}
              flag={flag}
              setDisabledSeatCount={setDisabledSeatCount}
              setFlag={setFlag}
              handleInternal={handleInternal}
              handleSide={handleSide}
              handleBack={handleBack}
              handleFront={handleFront}
              seat={seat}
              disableSeat={disableSeat}
              enableNavigation={enableNavigation}
              handleMenuClose={handleMenuClose}
              handleMenuOpen={handleMenuOpen}
              menuOpen={menuOpen}
              licenseNumber={licenseNumber}
              setLicenseNumber={setLicenseNumber}
              modelNumber={modelNumber}
              setModelNumber={setModelNumber}
              manufacturingCompany={manufacturingCompany}
              setManufacturingCompany={setManufacturingCompany}
              capacity={capacity}
              setCapacity={setCapacity}
              disabledSeating={disabledSeating}
              setDisabledSeating={setDisabledSeating}
              transportationOrganization={transportationOrganization}
              setTransportationOrganization={setTransportationOrganization}
              fuel={fuel}
              setFuel={setFuel}
              disableAccessibility={disableAccessibility}
              setDisableAccessibility={setDisableAccessibility}
              trackingNavigation={trackingNavigation}
              setTrackingNavigation={setTrackingNavigation}
              handleSubmit={(values) => handleSubmit(values)}
              surveillanceCameraTypes={surveillanceCameraTypes}
              seatsAvailable={seatsAvailable}
              trackNavigate={trackNavigate}
              handleDateChange={handleDateChange}
              installationDate={installationDate}
              setModelYear={setModelYear}
              modelYear={modelYear}
              track={track}
              valuetext={valuetext}
              front={front}
              back={back}
              side={side}
              internal={internal}
              setFront={setFront}
              setBack={setBack}
              setSide={setSide}
              setInternal={setInternal}
              buttonLoader={buttonLoader}
              setButtonLoader={setButtonLoader}
              disableSeating={disableSeating}
              handleFuelChange={handleFuelChange}
              model={model}
              setModel={setModel}
              deviceId={deviceId}
              setDeviceId={setDeviceId}
              isApiSuccesfull={isApiSuccesfull}
              setIsApiSuccesfull={setIsApiSuccesfull}
              clearAllFormStates={clearAllFormStates}
              setInstallationDate={setInstallationDate}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              {...props}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <AddEditForm
          disabledSeatCount={disabledSeatCount}
          track={track}
          navigation={navigation}
          flag={flag}
          setDisabledSeatCount={setDisabledSeatCount}
          setFlag={setFlag}
          handleInternal={handleInternal}
          handleSide={handleSide}
          handleBack={handleBack}
          handleFront={handleFront}
          seat={seat}
          disableSeat={disableSeat}
          enableNavigation={enableNavigation}
          handleMenuClose={handleMenuClose}
          handleMenuOpen={handleMenuOpen}
          menuOpen={menuOpen}
          licenseNumber={licenseNumber}
          setLicenseNumber={setLicenseNumber}
          modelNumber={modelNumber}
          setModelNumber={setModelNumber}
          manufacturingCompany={manufacturingCompany}
          setManufacturingCompany={setManufacturingCompany}
          capacity={capacity}
          setCapacity={setCapacity}
          disabledSeating={disabledSeating}
          setDisabledSeating={setDisabledSeating}
          transportationOrganization={transportationOrganization}
          setTransportationOrganization={setTransportationOrganization}
          fuel={fuel}
          setFuel={setFuel}
          disableAccessibility={disableAccessibility}
          setDisableAccessibility={setDisableAccessibility}
          trackingNavigation={trackingNavigation}
          setTrackingNavigation={setTrackingNavigation}
          handleSubmit={(values) => handleSubmit(values)}
          surveillanceCameraTypes={surveillanceCameraTypes}
          seatsAvailable={seatsAvailable}
          trackNavigate={trackNavigate}
          handleDateChange={handleDateChange}
          installationDate={installationDate}
          setModelYear={setModelYear}
          modelYear={modelYear}
          valuetext={valuetext}
          front={front}
          back={back}
          side={side}
          internal={internal}
          setFront={setFront}
          setBack={setBack}
          setSide={setSide}
          setInternal={setInternal}
          buttonLoader={buttonLoader}
          setButtonLoader={setButtonLoader}
          disableSeating={disableSeating}
          handleFuelChange={handleFuelChange}
          model={model}
          setModel={setModel}
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          isApiSuccesfull={isApiSuccesfull}
          setIsApiSuccesfull={setIsApiSuccesfull}
          clearAllFormStates={clearAllFormStates}
          setInstallationDate={setInstallationDate}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          {...props}
        />
      )}
    </div>
  );
}
