import { getDefaultSelectedChip } from './sessionFormModel';

// ============================== Communication Formik Form - INITIAL VALUES  ============================== //

function formInitialValues(initialValues) {
  return {
    name: initialValues?.name,
    desc: initialValues?.desc,
    status: initialValues?.status,
    auto_import_drivers: initialValues?.auto_import_drivers,
    auto_import_vehicles: initialValues?.auto_import_vehicles,
    auto_import_students: initialValues?.auto_import_students,
    auto_import_routes: initialValues?.auto_import_routes,
    auto_promote_students_elementary: initialValues?.auto_promote_students_elementary,
    auto_promote_students_middle: initialValues?.auto_promote_students_middle,
    auto_promote_students_high: initialValues?.auto_promote_students_high,
    auto_promote_leftover_students: initialValues?.auto_promote_leftover_students,
  };
}

export default formInitialValues;

export function initialValuesOfSessionForm(formikValues) {
  return {
    name: formikValues ? formikValues.name : '',
    desc: formikValues ? formikValues.desc : '',
    status: formikValues ? formikValues.status : getDefaultSelectedChip(),
    auto_import_drivers: formikValues ? formikValues.auto_import_drivers : true,
    auto_import_vehicles: formikValues ? formikValues.auto_import_vehicles : true,
    auto_import_students: formikValues ? formikValues.auto_import_students : true,
    auto_import_routes: formikValues ? formikValues.auto_import_routes : true,
    auto_promote_students_elementary: formikValues
      ? formikValues.auto_promote_students_elementary
      : true,
    auto_promote_students_middle: formikValues ? formikValues.auto_promote_students_middle : true,
    auto_promote_students_high: formikValues ? formikValues.auto_promote_students_high : true,
    auto_promote_leftover_students: formikValues
      ? formikValues.auto_promote_leftover_students
      : true,
  };
}

export function initialValuesOfSchoolScheduleForm(filterValuesBySchool) {
  return {
    start_date: filterValuesBySchool ? filterValuesBySchool.date_range.start_date : null,
    end_date: filterValuesBySchool ? filterValuesBySchool.date_range.end_date : null,
    days_of_week: filterValuesBySchool ? filterValuesBySchool.days_of_week : [],
    holidays: filterValuesBySchool ? filterValuesBySchool.holidays : [],
    vacations: filterValuesBySchool ? filterValuesBySchool.vacations : [],
  };
}

export function initialValuesOfHolidays(selectedHoliday) {
  return {
    hname: selectedHoliday ? selectedHoliday.name : '',
    hdate: selectedHoliday ? selectedHoliday.date : null,
  };
}

export function initialValuesOfVacations(selectedVacation) {
  return {
    name: selectedVacation ? selectedVacation.name : '',
    start_date: selectedVacation ? selectedVacation.start_date : null,
    end_date: selectedVacation ? selectedVacation.end_date : null,
  };
}
