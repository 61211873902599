import React from 'react';

// Material Ui
import { BackButton, NextButton } from 'components/Buttons';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

export default function GpTwoBackForwordBtn({
  selectedSchoolId,
  setRenderGPTwo,
  setActiveStepforRoutes,
  nextButtonTitle,
  setCsActiveStep,
  isFromSection,
  setRenderPageGPTwo,
  renderPageGPTwo,
  globalBtnRoutes,
  globalBtnC,
  guidedApi,
  setGlobalBtnC,
  setSelectedSchoolId,
}) {
  function handleGlobalBackButton() {
    if (isFromSection === 'Routes') {
      setCsActiveStep(0);
    } else if (isFromSection === 'Student') {
      setSelectedSchoolId(!selectedSchoolId);
      setGlobalBtnC(false);
    }
  }

  function _handleRoute() {
    if (isFromSection === 'Student') {
      setSelectedSchoolId(!selectedSchoolId);
      setGlobalBtnC(false);
      // setActiveStepforRoutes(1);
    }

    // if (isFromSection === 'Routes') {
    //   setRenderPageGPTwo(!renderPageGPTwo);
    //   // setActiveStepforRoutes(0);
    //   // setGlobalBtnC(false);
    // }
  }
  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Divider />
      </Grid>

      <Grid xs={12}>
        <Grid container justifyContent='space-between' xs={12}>
          <Grid justifyContent='flex-start' xs={8}>
            <BackButton sx={{}} onClick={handleGlobalBackButton}>
              BACK
            </BackButton>
          </Grid>
          <Grid container justifyContent='flex-end' xs={4}>
            {/* <NextButton
              onClick={_handleRoute}
              sx={{ 'white-space': 'nowrap', 'min-width': 'max-content' }}
              disabled={!globalBtnC ? true : !guidedApi ? true : false}
            >
              {nextButtonTitle}
            </NextButton> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
