import React from 'react';

import { useTheme } from '@mui/material/styles';

// Material UI
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

// Project Imports
import DistrictLevelInfoCard from './DistrictLevelInfoCard';
import SchoolInfoCard from './SchoolInfoCard';
import MainCard from 'components/MainCard';

export default function DistrictLevelSchoolInformation({
  schoolData,
  handleOnClickSchool,
  settingData,
  loading,
}) {
  const theme = useTheme();

  const elementarySchool = schoolData?.filter((ele) => ele?.level === 'elementary');

  const middleSchool = schoolData?.filter((ele) => ele?.level === 'middle');

  //   We have to use highschool field in high school section just to show i am using schooldata
  const highSchool = schoolData?.filter((ele) => ele?.level === 'high');

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{ p: 10, bgcolor: theme.palette.background.paper, width: '100vw' }}
      >
        <MainCard>
          <Grid item xs={12}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={2}>
              <Typography variant='h2'>District Level School Route Information</Typography>
              <Typography>
                School route information at the district level is organized into three tiers:
                elementary, middle, and high school. Each tier includes multiple schools within the
                district. To access detailed route information, such as bus stops, pick-up and
                drop-off times, and specific routes, simply choose a school from the available list.
                This ensures that parents and students can easily obtain accurate and relevant
                transportation details tailored to their specific school. It streamlines the process
                of planning commutes and ensures efficient and safe transportation for students
                across all levels within the district.
              </Typography>
            </Stack>
          </Grid>

          <Grid container sx={{ p: 10 }} spacing={5}>
            <Grid item xs={12}>
              <DistrictLevelInfoCard
                heading={'Elementary School Routes Information'}
                description='Elementary school, serving students typically in grades K-5, has its own route information for transportation. This includes details about bus stops, designated pick-up and drop-off times, and the specific route taken by the school bus. This information is crucial for facilitating efficient and safe transportation, ensuring that elementary school students arrive at school on time. By selecting an elementary school from the list, you can obtain comprehensive information about the school bus route, ensuring reliable and convenient travel for students in this age group.'
              />
              <Grid container xs={12} direction='row' sx={{ pl: 9, pt: 3 }} spacing={3}>
                {loading ? (
                  <Grid item sx={{ pr: 10 }}>
                    <Skeleton variant='rounded' width={210} height={50} />
                  </Grid>
                ) : elementarySchool.length === 0 && loading === false ? (
                  <Grid item sx={{ pr: 10 }}>
                    <SchoolInfoCard
                      icon={<ReportProblemIcon />}
                      schoolName={'No Schools Data Available'}
                      noData={true}
                    />
                  </Grid>
                ) : (
                  elementarySchool.map((ele) => (
                    <Grid item>
                      <SchoolInfoCard
                        icon={<SchoolIcon />}
                        handleOnClick={() => handleOnClickSchool(ele, settingData)}
                        schoolName={ele.school_name}
                        key={ele.school_id}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DistrictLevelInfoCard
                heading={'Middle School Routes Information'}
                description='Middle school, which typically serves students in grades 6 to 8, has its own route information. This includes bus stops, designated pick-up and drop-off times, and the specific route taken by the school bus. Such information is vital for ensuring efficient and safe transportation, allowing middle school students to arrive at school on time. By selecting a middle school from the list, you can obtain detailed information about the school bus route, facilitating reliable and convenient travel for students in this age group.'
              />
              <Grid container xs={12} direction='row' sx={{ pl: 9, pt: 3 }} spacing={3}>
                {loading ? (
                  <Grid item sx={{ pr: 10 }}>
                    <Skeleton variant='rounded' width={210} height={50} />
                  </Grid>
                ) : middleSchool.length === 0 && loading === false ? (
                  <Grid item sx={{ pr: 10 }}>
                    <SchoolInfoCard
                      icon={<ReportProblemIcon />}
                      schoolName={'No Schools Data Available'}
                      noData={true}
                    />
                  </Grid>
                ) : (
                  middleSchool.map((ele) => (
                    <Grid item>
                      <SchoolInfoCard
                        icon={<SchoolIcon />}
                        handleOnClick={() => handleOnClickSchool(ele, settingData)}
                        schoolName={ele.school_name}
                        key={ele.school_id}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DistrictLevelInfoCard
                heading={'High School Routes Information'}
                description='High school, catering to students in grades 9 to 12, has specific route information for transportation purposes. This encompasses bus stops, designated pick-up and drop-off times, and the exact route taken by the school bus. This information is essential to ensure efficient and safe transportation, enabling high school students to reach school promptly. By selecting a high school from the provided list, you can access detailed information about the school bus route, ensuring reliable and convenient travel for students in this educational stage.'
              />
              <Grid container xs={12} direction='row' sx={{ pl: 9, pt: 3 }} spacing={3}>
                {loading ? (
                  <Grid item sx={{ pr: 10 }}>
                    <Skeleton variant='rounded' width={210} height={50} />
                  </Grid>
                ) : highSchool.length === 0 && loading === false ? (
                  <Grid item sx={{ pr: 10 }}>
                    <SchoolInfoCard
                      icon={<ReportProblemIcon />}
                      schoolName={'No Schools Data Available'}
                      noData={true}
                    />
                  </Grid>
                ) : (
                  highSchool.map((ele) => (
                    <Grid item>
                      <SchoolInfoCard
                        icon={<SchoolIcon />}
                        handleOnClick={() => handleOnClickSchool(ele, settingData)}
                        schoolName={ele.school_name}
                        key={ele.school_id}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </>
  );
}
