import { ACTIVE_DRAWER_ITEM } from 'reduxConstants/menuConstants';

// initial state
const initialState = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true,
};

export const menu = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_DRAWER_ITEM:
      return {...state, openItem: action.payload.openItem}
    default:
      return state;
  }
};
