import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, Paper, Typography, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: theme.spacing(66),
    width: '100%',
  },
  paper: {
    // margin: theme.spacing(1),
    width: '100%',
    height: theme.spacing(66),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function LinearProgressWithLabel(props) {
  const theme = useTheme();
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height={theme.spacing(66)}
    >
      <Box width='90%' mr={1} ml={1}>
        <LinearProgress variant='determinate' color='primary' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='secondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function FileUploadProgressBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={props.progress} />
    </div>
  );
}

export default function RenderFinalUpload(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <FileUploadProgressBar progress={props.progress} />
      <Box
        position='relative'
        display='flex'
        alignItems='center'
        justifyContent='center'
        top='-30%'
      >
        <Typography variant='body2'>
          Drivers uploading in progress ...
        </Typography>
      </Box>
    </Paper>
  );
}
