import React from 'react';
import PropTypes from 'prop-types';

// third-party imports
import { Field } from 'formik';
import { TextField } from 'formik-mui';

// material-ui
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

// project imports
import { ClassificationHeading, SubHeading } from 'components/Headers';
import { inputVariant } from 'constants';
import { CountrySelect, CustomChipArray } from 'components/Formik';
import { chipItemForGender } from 'pages/Sessions/FormModel/sessionFormModel';

const personInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PersonIcon />
    </InputAdornment>
  ),
};

const emailInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <EmailIcon />
    </InputAdornment>
  ),
};

const phoneInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <PhoneAndroidIcon />
    </InputAdornment>
  ),
};

// ============================== BASIC DETAILS FORM ( USER )  ============================== //

function BasicUserForm({ formikProps, loader }) {
  const genderHelperText = Boolean(formikProps.touched.gender && formikProps.errors.gender);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label='First Name *'
          name='first_name'
          variant={inputVariant}
          disabled={loader}
          InputProps={personInputProps}
          helperText={'Enter first Name'}
          id='first_name'
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label='Last Name *'
          name='last_name'
          disabled={loader}
          variant={inputVariant}
          InputProps={personInputProps}
          id='last_name'
          helperText={'Enter last name'}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          component={TextField}
          label='Email ID *'
          name='email'
          disabled={loader}
          variant={inputVariant}
          InputProps={emailInputProps}
          helperText={'Enter email ID'}
          id='email'
        />
      </Grid>

      <Grid
        container
        direction='column'
        justifyContent='flex-end'
        alignItems='center'
        xs={1.8}
        sx={{ ml: 3 }}
      >
        <CountrySelect
          name='phone_country_code'
          label='Country code *'
          id='country_code'
          type='phone'
          disabled={loader}
          countryId='country_code_select'
          helperText={'Enter Country Code'}
          {...formikProps}
          helperTextContent={'Enter country code'}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          fullWidth
          component={TextField}
          label='Mobile Number *'
          name='phone_number'
          variant={inputVariant}
          disabled={loader}
          InputProps={phoneInputProps}
          helperText={'Enter mobile number'}
          id='mobile_number'
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          disabled={loader}
          component={CustomChipArray}
          label='Gender *'
          name='gender'
          chipItems={chipItemForGender}
        />
        <Typography variant='caption' color={genderHelperText ? 'error' : 'text.secondary'}>
          {genderHelperText ? formikProps.errors.gender : 'Select gender'}
        </Typography>
      </Grid>
    </Grid>
  );
}

BasicUserForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
};

export default BasicUserForm;
