const getUtcOffset = {
  atlantic: {
    ast: 4,
    adt: 3,
  },
  eastern: {
    ast: 5,
    adt: 4,
  },
  central: {
    ast: 6,
    adt: 5,
  },
  mountain: {
    ast: 7,
    adt: 6,
  },
  pacific: {
    ast: 8,
    adt: 7,
  },
  alaska: {
    ast: 9,
    adt: 8,
  },
  'hawaii–Aleutian': {
    ast: 10,
    adt: 9,
  },
};

function calc(zone) {
  const st = zone?.toLowerCase();
  for (const e in getUtcOffset) {
    if (st?.includes(e)) {
      return e;
    }
  }
  return 'eastern'; // This is for rare case if zone dont match
}

getUtcOffset.getKey = calc;

// Day saving time is started from second sunday of march
function getSecondSundayOfMarch(year) {
  let start = new Date(year, 2, 7),
    secondSunday = 7 + (7 - start.getDay());
  return new Date(year, 2, secondSunday);
}

// Day saving time is ended on first sunday of october
function getFirstSundayOfOct(y) {
  const date = new Date(y, 9, 1);
  let addend = date.getDay();

  if (addend > 0) {
    // First date of month is NOT Sunday
    addend = 7 - addend;
  }
  return new Date(y, 9, addend + 1);
}

export function getDateAccordingTimeZone(date, zone) {
  let dateObj = new Date(date);
  let d = dateObj.getTime();
  let keyZone = getUtcOffset.getKey(zone);
  let o = dateObj.getTimezoneOffset() * 60 * 1000;
  // console.log(o)
  d = d + o;
  let offSetValue;
  // console.log(getSecondSundayOfMarch(dateObj.getFullYear()))
  if (
    dateObj < getSecondSundayOfMarch(dateObj.getFullYear()) &&
    dateObj > getFirstSundayOfOct(dateObj.getFullYear())
  ) {
    offSetValue = getUtcOffset[keyZone].ast;
  } else {
    offSetValue = getUtcOffset[keyZone].adt;
  }
  let u = offSetValue * 60 * 60 * 1000;

  d = d - u;
  return d;
}

// Fucntion for getting time with timeStamp
export function getTime(timeStamp) {
  const getTwo = (s) => (s < 10 ? '0' + s : s);
  const date = new Date(timeStamp);
  const h = date.getHours();
  const m = date.getMinutes();

  return `${getTwo(h)}:${getTwo(m)}:00`;
}

// Fucntion for getting time with seconds
export function getTimewithSeconds(timeStamp) {
  const getTwo = (s) => (s < 10 ? '0' + s : s);
  const date = new Date(timeStamp);
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();

  return `${getTwo(h)}:${getTwo(m)}:${getTwo(s)}`;
}

export function getTimeWithoutSecond(timeStamp) {
  const getTwo = (s) => (s < 10 ? '0' + s : s);
  const date = new Date(timeStamp);
  const h = date.getHours();
  const m = date.getMinutes();

  return `${getTwo(h)}:${getTwo(m)}`;
}

export function getDateFromTimeStamp(timeStamp) {
  const date = new Date(timeStamp * 1000);
  let day = date.getDate();
  day = day < 10 ? '0' + day : day;
  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}
