import React from 'react';

// material ui
import { Box, Grid, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';

// assets
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmailIcon from '@mui/icons-material/Email';
import WcIcon from '@mui/icons-material/Wc';

// project imports
import { formatPhoneNumber } from '../../Utils/FormatPhoneNumber';
import { DriverDetailsItem } from './DriverDetails/DriverDetailsItem';
import DriverNameAndButtons from './DriverDetails/DriverNameAndButtons';
import DriverStatus from './DriverDetails/DriverStatus';
import DriverImage from './DriverDetails/DriverImage';
import DriverAddress from './DriverDetails/DriverAddress';
import DriverCertificate from './DriverDetails/DriverCertificate';
import DriverLicense from './DriverDetails/DriverLicense';
import { longDateFormatter } from '../../Utils/DateHandling';
import NoRecordDisplay from '../../../../components/NoRecordDisplay';
import { useLocation } from 'react-router-dom';
// third party
import { Scrollbars } from 'react-custom-scrollbars-2';

// Custom Components
import DefaultAvatar from 'components/avatars/DefaultAvatar';
import { QuickViewItemCard } from 'components/cards/index';
import HistoryIcon from '@mui/icons-material/History';
import MainCard from 'components/MainCard';
import { useDashboardUsers } from 'hooks/api/useDashboardUsers';
import { getreadableroletype } from 'pages/UserProfile/components/getreadableroletype';
import SchoolIcon from '@mui/icons-material/School';
const useStyles = makeStyles((theme) => ({
  dividerMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  titleMargin: {
    marginBottom: theme.spacing(1),
  },
  main: {
    marginTop: theme.spacing(23),
  },
  image: {
    height: theme.spacing(50),
    width: theme.spacing(60),
    marginLeft: theme.spacing(4),
  },
  title: {
    fontSize: theme.spacing(30),
  },
  text: {
    fontSize: theme.spacing(5),
    paddingBottom: theme.spacing(50),
    textAlign: 'center',
  },
  outerBorder: {
    maxHeight: '1250px',
    minHeight: '1120px',
    overflow: 'hidden',
  },
}));

function GetDriverDetailsInfoHeader({
  classes,
  selectedDriverRowId,
  selectedDriverData,
  handleDeleteDriver,
  handleEditClick,
  setOpenDeleteDriverAlert,
  setDriverUserName,
  toggleDriverStatusHandler,
  isEdit,
  loadUpdatedData,
  dataLoading,
  toogleStatusLoading,
  toggleloader,
  openDriverShifts,
  handleShiftClick,
  handleShiftDetails,
}) {
  return (
    <Stack spacing={3}>
      <Grid>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <DefaultAvatar
            isLoading={dataLoading}
            src={selectedDriverData?.profile_image && selectedDriverData?.profile_image.url}
            height={120}
            width={120}
            tooltipContent={`${selectedDriverData?.first_name} ${selectedDriverData?.last_name}`}
          />
        </Stack>
      </Grid>

      {/* Old Code - */}
      {/* <DriverImage rowData={selectedDriverData} dataLoading={dataLoading} /> */}

      <DriverNameAndButtons
        firstName={selectedDriverData?.first_name}
        lastName={selectedDriverData?.last_name}
        handleDeleteDriver={handleDeleteDriver}
        driverId={selectedDriverData?.driver_username}
        handleDriverEdit={handleEditClick}
        setOpenDeleteDriverAlert={setOpenDeleteDriverAlert}
        setDriverUserName={setDriverUserName}
        selectedDriverData={selectedDriverData}
        isEdit={isEdit}
        loadUpdatedData={loadUpdatedData}
        dataLoading={dataLoading}
        enabled={selectedDriverData?.enabled}
        toggleDriverStatusHandler={toggleDriverStatusHandler}
        toogleStatusLoading={toogleStatusLoading}
        toggleloader={toggleloader}
        openDriverShifts={openDriverShifts}
        handleShiftClick={handleShiftClick}
        handleShiftDetails={handleShiftDetails}
      />

      <Divider light />
      <DriverStatus
        statusColor={selectedDriverData?.user_status}
        userStatus={selectedDriverData?.visible_user_status}
        enabled={selectedDriverData?.enabled}
        selectedDriverData={selectedDriverData}
        toggleDriverStatusHandler={toggleDriverStatusHandler}
        dataLoading={dataLoading}
      />
      <Divider light />
    </Stack>
  );
}

function GetDriverDetailsItems(props) {
  const { dataLoading } = props;

  const { users, isLoadingUsers } = useDashboardUsers();
  const getUserData = () => {
    try {
      return (
        users.find(
          (user) => user.dashboard_user_username === props.selectedDriverData.driver_username,
        ).user_permissions || null
      );
    } catch (error) {
      return null;
    }
  };
  const location = useLocation();

  return (
    <Stack>
      <QuickViewItemCard
        label={'Name'}
        icon={<PersonIcon fontSize='large' />}
        id='driverName'
        isDataLoading={dataLoading}
      >
        {props.selectedDriverData
          ? props.selectedDriverData?.first_name + ' ' + props.selectedDriverData?.last_name
          : props.selectedDriverData?.first_name}
      </QuickViewItemCard>
      {location.pathname === '/users' && (
        <>
          <QuickViewItemCard
            label={'Role Type'}
            icon={<HistoryIcon fontSize='large' />}
            id='driverName'
            isDataLoading={dataLoading}
          >
            {getreadableroletype(getUserData()?.role_type)}
          </QuickViewItemCard>
          <QuickViewItemCard
            label={'Allowed School'}
            icon={<SchoolIcon fontSize='large' />}
            id='driverName'
            isDataLoading={dataLoading}
          >
            {getUserData()?.schools && getUserData()?.schools?.length > 0
              ? getUserData()?.schools.map((school) => `${school} `)
              : 'Not Specified'}
          </QuickViewItemCard>
        </>
      )}

      <QuickViewItemCard
        label={'Gender'}
        icon={<WcIcon fontSize='large' />}
        id='gender'
        isDataLoading={dataLoading}
      >
        {props.selectedDriverData
          ? props.selectedDriverData?.gender === 'male'
            ? 'Male'
            : props.selectedDriverData?.gender === 'female'
            ? 'Female'
            : 'Unspecified'
          : props.selectedDriverData?.gender}
      </QuickViewItemCard>
      {/* Old Code Depricated  */}
      {/* <DriverDetailsItem
          name='Gender'
          id='gender'
          value={
            props.selectedDriverData
              ? props.selectedDriverData?.gender === 'male'
                ? 'Male'
                : props.selectedDriverData?.gender === 'female'
                ? 'Female'
                : 'Not Specific'
              : props.selectedDriverData?.gender
          }
          icon={WcIcon}
          dataLoading={dataLoading}
        /> */}

      <QuickViewItemCard
        label={'Phone Number'}
        icon={<PhoneIcon fontSize='large' />}
        id='phoneNumber'
        isDataLoading={dataLoading}
      >
        {formatPhoneNumber(props.selectedDriverData?.phone_number)}
      </QuickViewItemCard>
      {/* Old Code Depricated  */}
      {/* <DriverDetailsItem
          name='Phone Number'
          id='phoneNumber'
          value={formatPhoneNumber(props.selectedDriverData?.phone_number)}
          icon={PhoneIcon}
          dataLoading={dataLoading}
        /> */}

      <QuickViewItemCard
        label={'Email'}
        icon={<EmailIcon fontSize='large' />}
        id='emailID'
        isDataLoading={dataLoading}
      >
        {props.selectedDriverData?.email}
      </QuickViewItemCard>
      {/* Old Code Depricated  */}
      {/* <DriverDetailsItem
          name='Email'
          id='emailID'
          value={props.selectedDriverData?.email}
          icon={EmailOutlinedIcon}
          dataLoading={dataLoading}
        /> */}
    </Stack>
  );
}

export default function DriverDetails({
  selectedDriverRowId,
  selectedDriverData,
  handleDeleteDriver,
  handleEditClick,
  setOpenDeleteDriverAlert,
  setDriverUserName,
  toggleDriverStatusHandler,
  toogleStatusLoading,
  toggleloader,
  isEdit,
  loadUpdatedData,
  dataLoading,
  filledRows,
  isPremiumUser,
  openDriverShifts,
  handleShiftClick,
  handleShiftDetails,
  readPermissionProps,
}) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <React.Fragment>
      <MainCard content={false} sx={{ p: 3, height: '100%' }}>
        <Grid height={'100%'}>
          <Stack spacing={3} height={'100%'}>
            <Typography color='primary' variant='subtitle1' gutterBottom>
              {location.pathname === '/users' ? 'User Details' : 'Driver Details'}
            </Typography>
            {dataLoading === false && filledRows.length === 0 ? (
              <Grid item xs={12} className={classes.outerBorder}>
                <Grid className={classes.main}>
                  <NoRecordDisplay imageWidth={'Details'} />
                </Grid>
              </Grid>
            ) : (
              <>
                <GetDriverDetailsInfoHeader
                  classes={classes}
                  selectedDriverRowId={selectedDriverRowId}
                  selectedDriverData={selectedDriverData}
                  handleDeleteDriver={handleDeleteDriver}
                  handleEditClick={handleEditClick}
                  setOpenDeleteDriverAlert={setOpenDeleteDriverAlert}
                  setDriverUserName={setDriverUserName}
                  toggleDriverStatusHandler={toggleDriverStatusHandler}
                  toogleStatusLoading={toogleStatusLoading}
                  toggleloader={toggleloader}
                  isEdit={isEdit}
                  loadUpdatedData={loadUpdatedData}
                  dataLoading={dataLoading}
                  openDriverShifts={openDriverShifts}
                  handleShiftClick={handleShiftClick}
                  handleShiftDetails={handleShiftDetails}
                />

                <Scrollbars
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  disableHorizontalScrolling
                >
                  <Stack>
                    <GetDriverDetailsItems
                      selectedDriverData={selectedDriverData}
                      dataLoading={dataLoading}
                      readPermissionProps={readPermissionProps}
                    />
                    <DriverAddress
                      street={selectedDriverData?.address?.street}
                      city={selectedDriverData?.address?.city}
                      state={selectedDriverData?.address?.state}
                      zipCode={selectedDriverData?.address?.zip_code}
                      aptPlot={selectedDriverData?.address?.apt_plot}
                      geoLocation={selectedDriverData?.geo_location}
                      isPremiumUser={isPremiumUser}
                      dataLoading={dataLoading}
                    />
                    <DriverCertificate
                      certificateAuthority={selectedDriverData?.certificate_authority}
                      certificateName={selectedDriverData?.certificate_name}
                      certificateImage={selectedDriverData}
                      dataLoading={dataLoading}
                    />
                    <DriverLicense
                      licenseClass={selectedDriverData?.license_class}
                      licenseNumber={selectedDriverData?.license_number}
                      licenseExpiryDate={longDateFormatter(
                        selectedDriverData?.license_exp_date,
                        true,
                      )}
                      licenseIssueDate={longDateFormatter(
                        selectedDriverData?.license_iss_date,
                        true,
                      )}
                      licenseImage={selectedDriverData}
                      dataLoading={dataLoading}
                    />
                  </Stack>
                </Scrollbars>
              </>
            )}
          </Stack>
        </Grid>
      </MainCard>
      {/* </Box> */}
    </React.Fragment>
  );
}
