import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

// ============================== Icon Button ============================== //
function IcoButton({ icon, ...props }) {
  return <IconButton {...props}>{icon}</IconButton>;
}

IcoButton.defaultProps = {
  variant: 'text',
  size: 'large',
  color: 'secondary',
  icon: <DeleteIcon />,
};

IcoButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  icon: PropTypes.node,
};

export default IcoButton;

// ! Before use Read me First
/*
  * this is Global Button components for use any where in project 
    how to use
    1. import IcoButton  from "/path"

    2. use in JSX components

  *  <IcoButton
  *  icon={<DeleteIcon />}
  *  />
     
    or 

  *  <IcoButton onClick={()=> console.count("Clicked")}>
  *   <DeleteIcon /> 
  *  </IcoButton>
*/
