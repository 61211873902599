import React from 'react';

// material ui
import { Grid, Button, TextField, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClassIcon from '@mui/icons-material/Class';
import NumbersIcon from '@mui/icons-material/Numbers';
import AssistantIcon from '@mui/icons-material/Assistant';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// assets
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { FormHelperText } from '@mui/material';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// project imports
import MyDropzone from './MyDropzone';

// Custom Component
import NextButton from 'components/Buttons/NextButton';
import BackButton from 'components/Buttons/BackButton';

const validationSchema = yup.object({
  licenseClass: yup.string().trim(),
  licenseNumber: yup.string().trim(),
});

export default function UploadLicenseForm(props) {
  const classes = useStyles();

  const [licenseFrontValidation, setLicenseFrontValidation] = React.useState(false);
  const [licenseBackValidation, setLicenseBackValidation] = React.useState(false);

  const [issueDateError, setIssueDateError] = React.useState(false);
  const [expireDateError, setExpireDateError] = React.useState(false);

  const handleBack = () => {
    props.setActiveStep((pre) => pre - 1);
  };

  const formik = useFormik({
    initialValues: {
      licenseClass: props.licenseClass ? props.licenseClass : '',
      licenseNumber: props.licenseNumber ? props.licenseNumber : '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // if (props.licenseFrontImage && props.licenseBackImage) {
      console.log('license FORMIK', values);
      props.setLicenseClass(formik.values.licenseClass);
      props.setLicenseNumber(formik.values.licenseNumber);
      props.setActiveStep((pre) => pre + 1);
      // }
    },
  });

  const checkImageValidation = () => {
    setLicenseFrontValidation(true);
    setLicenseBackValidation(true);
  };

  const handleChange = (date) => {
    props.setLicenseIssDate(date);
  };

  return (
    <Grid container direction={'column'} xs={12}>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid container sx={{ height: 485 }}>
            <Grid sx={{ minHeight: props.isFromGuided ? 495 : 0 }}>
              <Grid container direction='row' spacing={3}>
                <Grid row className={classes.image} xs={6}>
                  <Grid container xs={12} justifyContent='center'>
                    <MyDropzone
                      selectedFile={props.licenseFrontImage}
                      selectedFileData={props.licenseFrontImageData}
                      setSelectedFile={props.setLicenseFrontImage}
                      imageType={'licenseFrontImage'}
                      text={'Choose licence front image or Drag and drop here'}
                      tooltipTitle={'Choose license front image or Drag and drop here'}
                      {...props}
                    />
                  </Grid>
                  {/* <Grid container xs={12} justifyContent='center'>
                  {props.licenseFrontImage === undefined && licenseFrontValidation === true && (
                    <p style={{ color: 'red' }}>*Image Required</p>
                  )}
                </Grid> */}
                  <Grid container xs={12} justifyContent='center' sx={{ mt: 2 }}>
                    {/* {Boolean(
                      props.licenseFrontImage === undefined && licenseFrontValidation === true,
                    ) ? (
                      <Typography variant='body2' color='error'>
                        *Image Required
                      </Typography>
                    ) : (
                      <Typography variant='body2' color='text.secondary'>
                        Upload image
                      </Typography>
                    )} */}
                    <Typography variant='body2' color='text.secondary'>
                      Upload image
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.image} xs={6}>
                  <Grid container xs={12} justifyContent='center'>
                    <MyDropzone
                      selectedFile={props.licenseBackImage}
                      selectedFileData={props.licenseBackImageData}
                      setSelectedFile={props.setLicenseBackImage}
                      imageType={'licenseBackImage'}
                      text={'Choose licence back image or Drag and drop here'}
                      tooltipTitle={'Choose license back image or Drag and drop here'}
                      {...props}
                    />
                  </Grid>
                  {/* <Grid container xs={12} justifyContent='center'>
                  {props.licenseBackImage === undefined && licenseBackValidation === true && (
                    <p style={{ color: 'red' }}>*Image Required</p>
                  )}
                </Grid> */}
                  <Grid container xs={12} justifyContent='center' sx={{ mt: 2 }}>
                    {/* {Boolean(
                      props.licenseBackImage === undefined && licenseBackValidation === true,
                    ) ? (
                      <Typography variant='body2' color='error'>
                        *Image Required
                      </Typography>
                    ) : (
                      <Typography variant='body2' color='text.secondary'>
                        Upload image
                      </Typography>
                    )} */}
                    <Typography variant='body2' color='text.secondary'>
                      Upload image
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    id='licenseNumber'
                    label='License Number'
                    name='licenseNumber'
                    variant='standard'
                    value={formik.values.licenseNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
                    helperText={
                      Boolean(formik.touched.licenseNumber && formik.errors.licenseNumber)
                        ? formik.touched.licenseNumber && formik.errors.licenseNumber
                        : 'Enter license number'
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <NumbersIcon color='secondary' />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id='licenseClass'
                    label='License Class'
                    name='licenseClass'
                    variant='standard'
                    value={formik.values.licenseClass}
                    onChange={formik.handleChange}
                    error={formik.touched.licenseClass && Boolean(formik.errors.licenseClass)}
                    helperText={
                      Boolean(formik.touched.licenseClass && formik.errors.licenseClass)
                        ? formik.touched.licenseClass && formik.errors.licenseClass
                        : 'Enter license class'
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AssistantIcon color='secondary' />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label='License Issue Date'
                      id='date-picker-dialog'
                      value={props.licenseIssDate}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          id='license_issue_Date'
                          views={['day']}
                          variant='standard'
                          {...params}
                          helperText={`${
                            issueDateError ? 'Enter correct issue date' : 'Enter issue date'
                          }`}
                        />
                      )}
                      onError={(error) => {
                        console.log('licenseFrontValidation', typeof error);
                        if (error) {
                          setIssueDateError(true);
                          return error;
                        } else setIssueDateError(false);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disablePast={props.licenseIssDate}
                      fullWidth
                      label='License Expiration Date'
                      margin='none'
                      id='licenseExpDate'
                      format='MM/dd/yyyy'
                      value={props.licenseExpDate}
                      onChange={(date) => props.setLicenseExpDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'License Issue Date',
                      }}
                      renderInput={(params) => (
                        <TextField
                          id='license_exp_date'
                          variant='standard'
                          fullWidth
                          {...params}
                          helperText={`${
                            expireDateError
                              ? 'Enter correct expiration date'
                              : 'Enter expiration date'
                          }`}
                        />
                      )}
                      onError={(error) => {
                        if (error) {
                          setExpireDateError(true);
                          return error;
                        } else setExpireDateError(false);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} container justifyContent='space-between' alignItems='flex-end'>
              <Grid item>
                <BackButton size='medium' id='back_button' onClick={handleBack}>
                  BACK
                </BackButton>
              </Grid>
              <Grid item>
                <NextButton
                  size='medium'
                  type='submit'
                  id='submit_second_form'
                  onClick={checkImageValidation}
                >
                  NEXT
                </NextButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveButton: {
    spacing: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
