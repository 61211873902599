import React from 'react';

// third-party imports
import { Hub } from 'aws-amplify';
import { SignIn } from 'aws-amplify-react';

// material-ui
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Container from '@mui/material/Container';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// APIs
import { getGuidedWorkflow } from 'clients/SpotBus';

// project imports
import Copyright from './Copyright';
import EmailTextField from '../../../components/Form/EmailTextField';
import LOGGER from '../../../Logger';
import PasswordTextField from '../../../components/Form/PasswordTextField';
import SpotBusLogo from 'components/SpotBusLogo';

const useStyles = createStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    margin: theme.spacing(5),
  },
  spinner: {
    marginRight: theme.spacing(6),
  },
  cursor: {
    cursor: 'pointer',
  },
}));

const ERR_MESSAGES = {
  UserNotConfirmedException: 'Account confirmation pending, please contact support@spotbus.us',
  InvalidParameterException: 'Please correct invalid fields in form and try again.',
  NotAuthorizedException: 'Invalid username/password, please try again.',
  ELSE: 'Something went wrong, please try again later.',
};

// ============================== CUSTOM SIGN IN - MAIN FILE ============================== //

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      password: undefined,
      isUsernameValidated: false,
      isPasswordValidated: false,
      isLoading: false,
      showErrorSnackBar: false,
      error: undefined,
      signInSuccessful: false,
    };

    // Bindings
    this.publishSignInError.bind(this);
    this.onSignInClicked.bind(this);

    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  errorMessage(err) {
    LOGGER.error('Error Message: ' + err);
    return super.errorMessage(err);
  }

  onAuthEvent(payload) {
    LOGGER.debug('[Custom SignIn] Auth Event: ' + payload.event + ' Data: ' + payload.data);
    switch (payload.event) {
      case 'signUp':
        LOGGER.debug('User Signed Up with username: ', payload.data.username);
        this.setState({
          userAuthState: 'SIGNED_UP',
        });
        break;
      case 'signIn_failure':
        LOGGER.debug(
          'User Signed In failure for username: ' + payload.data.username + ' message: ',
          payload.data.code,
        );
        this.publishSignInError(payload.data.code);
        break;
      case 'configured':
        LOGGER.debug('the Auth module is configured');
        break;
      default:
        return;
    }
  }

  changeState(state, user) {
    LOGGER.info('>> Change State called: ' + state + ' Data: ' + JSON.stringify(user));
    super.changeState(state, user);
    this.showCompleteSignUp(user);
  }

  publishSignInError(errorCode) {
    if (errorCode in ERR_MESSAGES) {
      this.setState({
        error: ERR_MESSAGES[errorCode],
        showErrorSnackBar: true,
        isLoading: false,
      });
    } else {
      this.setState({
        error: ERR_MESSAGES['ELSE'],
        showErrorSnackBar: true,
        isLoading: false,
      });
    }
  }

  onSignInClicked(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    super
      .signIn()
      .then((result) => {
        LOGGER.info('Sign In Result: ' + result);
      })
      .then(async () => {
        console.log('Guided workflow API call');
        let res = await getGuidedWorkflow();
        console.log('Guided workflow result', res);
        this.props.handleGuidedWorkflowDataUpdate(res);
      })
      .catch((error) => {
        LOGGER.error('Sign In Error: ' + error);
      });
  }

  showCompleteSignUp(user) {
    Hub.dispatch('authPage', {
      event: 'COMPLETE_SIGN_UP',
      data: { user: user },
      message: 'Redirect to complete sign up.',
    });
  }

  showSignUp() {
    Hub.dispatch('authPage', {
      event: 'SIGN_UP',
      data: { color: 'blue' },
      message: 'Redirect to sign up.',
    });
  }

  showForgotPassword() {
    Hub.dispatch('authPage', {
      event: 'FORGOT_PASSWORD',
      data: { color: 'blue' },
      message: 'Redirect to forgot password.',
    });
  }
  render() {
    const classes = this.props.classes;
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          {/* <CardMedia
            className={classes.media}
            src={require('../../../assets/static/images/SpotBus_Logo.png')}
            component='img'
          /> */}
          <SpotBusLogo className={classes.media} />

          <Typography component='h2' variant='h5'>
            Sign In with your transportation admin credentials
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Snackbar
                  open={this.state.showErrorSnackBar}
                  autoHideDuration={6000}
                  onClose={(e) => {
                    this.setState({ showErrorSnackBar: false });
                  }}
                >
                  <MuiAlert
                    elevation={6}
                    variant='filled'
                    onClose={(e) => {
                      this.setState({ showErrorSnackBar: false });
                    }}
                    severity='error'
                  >
                    {this.state.error}
                  </MuiAlert>
                </Snackbar>
              </Grid>

              <Grid item xs={12} sm={12}>
                <EmailTextField
                  // onChange={(e) => { setUsername(e.target.value); }}
                  onChange={this.handleInputChange}
                  value={this.state.username}
                  id='username'
                  name='username'
                  validationstatushandler={(st) => {
                    this.setState({ isUsernameValidated: st });
                  }}
                  disabled={this.state.isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <PasswordTextField
                  // onChange={(e) => { setPassword(e.target.value); }}
                  onChange={this.handleInputChange}
                  value={this.state.password}
                  autoComplete='current-password'
                  validationstatushandler={(st) => {
                    this.setState({ isPasswordValidated: st });
                  }}
                  disabled={this.state.isLoading}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid> */}
            </Grid>

            <Button
              // type="submit"
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={
                !(this.state.isUsernameValidated && this.state.isPasswordValidated) ||
                this.state.isLoading
              }
              onClick={this.onSignInClicked.bind(this)}
            >
              {this.state.isLoading && (
                <CircularProgress className={classes.spinner} size={25} color='primary' />
              )}{' '}
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link onClick={this.showForgotPassword} variant='body2' className={classes.cursor}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={this.showSignUp} variant='body2' className={classes.cursor}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(useStyles)(CustomSignIn);
