// MUI
import { useTheme, styled } from '@mui/material/styles';
import {
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';

//utils
import { getFormattedSessionStatus, getSessionStatusColor } from '../Utils';

// assets
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClampLines } from 'components/typography';

// ==============================|| SESSIONS LIST ITEM ||============================== //

const SessionList = ({
  sessionsData,
  handleCanvasClick,
  handleEditClick,
  handleListItemClick,
  handleDeleteClick,
  setOpen,
  selectedSessionData,
}) => {
  const theme = useTheme();

  const chipSX = {
    minWidth: 60,
  };

  const handleCardClick = (session) => {
    handleListItemClick(session);
    setOpen(true);
  };

  const handleButtonStatus = (status) => {
    switch (status) {
      case 'closed':
        return true;
      case 'pending':
        return true;
      case 'in_progress':
        return true;
      default:
        return false;
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case 'pending':
        return true;
      case 'in_progress':
        return true;
      default:
        return false;
    }
  };

  return (
    <List
      sx={{
        width: '100%',
        minWidth: 300,
        maxWidth: 330,
        py: 0,
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
        },
        '& .MuiDivider-root': {
          my: 0,
        },
        '& .list-container': {
          pl: 7,
        },
      }}
    >
      {sessionsData &&
        sessionsData?.map((session, index) => (
          <div key={index}>
            <div
              style={{
                cursor: handleStatus(session?.status) ? 'default' : 'pointer',
                background:
                  session.id === selectedSessionData.id ? theme.palette.hover.default : null,
                padding: 8,
                '&:hover': {
                  background: theme.palette.hover.default,
                },
                '& .MuiListItem-root': {
                  padding: 0,
                },
              }}
              disabled={handleStatus(session?.status)}
              onClick={() => (handleStatus(session?.status) ? null : handleListItemClick(session))}
            >
              <Grid container justifyContent='space-between' alignItems='center'>
                <ListItemText primary={<Typography variant='h6'>{session.name}</Typography>} />
                <Chip
                  size='small'
                  variant='outlined'
                  sx={chipSX}
                  label={getFormattedSessionStatus(session.status)}
                  color={getSessionStatusColor(session.status)}
                />
              </Grid>
              <Grid container direction='column'>
                <Grid item xs={12} sx={{ pb: 2 }}>
                  <div onClick={() => handleCardClick(session)}>
                    <ClampLines
                      id='card_description'
                      text={
                        session?.desc?.length > 0
                          ? session?.desc
                          : 'In this description, you can find comprehensive information regarding the session.'
                      }
                      lines={2}
                      textVariant={'caption'}
                    />
                  </div>
                  {/* <Typography variant='caption'>{session.desc}</Typography> */}
                </Grid>
                <Grid container justifyContent={'space-between'} xs={12}>
                  <Grid>
                    <Chip
                      label='Edit'
                      icon={<EditIcon fontSize='small' />}
                      color='primary'
                      size='small'
                      sx={{ mr: 2 }}
                      disabled={handleStatus(session?.status)}
                      onClick={(event) => handleEditClick(event, session)}
                    />
                    <Chip
                      label='Canvas'
                      variant='outlined'
                      size='small'
                      disabled={handleButtonStatus(session?.status)}
                      deleteIcon={<LaunchIcon />}
                      onClick={(event) => handleCanvasClick(event, session.id)}
                      onDelete={(event) => handleCanvasClick(event, session.id)}
                    />
                    {session.status === 'active' ? null : (
                      <Chip
                        label='Delete'
                        variant='outlined'
                        size='small'
                        id='delete_session'
                        color='error'
                        sx={{ ml: 2 }}
                        disabled={handleButtonStatus(session?.status)}
                        icon={<DeleteIcon />}
                        onClick={(event) => {
                          handleDeleteClick(event, session);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </div>
        ))}
    </List>
  );
};

export default SessionList;
