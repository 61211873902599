import React from 'react';
//MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import MapIcon from '@mui/icons-material/Map';
import { Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

//Project Import
import RouteTable from './RouteTable';
import { RenderRoutesStopsInfo } from 'containers/layouts/Routes/DetailView/RenderStopsInfo';
import RenderRouteOpenStreetMap from 'containers/layouts/Routes/DetailView/RenderOpenStreetMap';
import { RenderStopsInfo } from 'containers/layouts/Trips/Components/RenderStopsInfo';
import { RenderOpenStreetMap } from 'containers/layouts/Trips/Components/RenderOpenStreetMap';

export default function Details({
  tripData,
  selectedRowData,
  listDetails,

  selectedTripRowData,
  settingData,
  selectedSchool,
  handleUrlBack,
}) {
  const theme = useTheme();

  const tableHeading = [
    'Route Number',
    'Route Type',
    'Time Zone',
    'Scheduled Start Time',
    'Driver',
    'Total Stops',
  ];

  const tableBody =
    selectedTripRowData === true
      ? [
          selectedRowData.route_info.school_route_number,
          selectedRowData.route_info.route_type,
          selectedRowData.route_info.time_zone,
          selectedRowData.route_info.scheduled_start_time,
          selectedRowData.route_info.driver_info.first_name === undefined
            ? '--'
            : selectedRowData.route_info.driver_info?.first_name +
              ' ' +
              selectedRowData.route_info.driver_info?.last_name,
          selectedRowData.route_info.route_graph.length,
        ]
      : [
          selectedRowData.school_route_number,
          selectedRowData.route_type,
          selectedRowData.time_zone,
          selectedRowData.scheduled_start_time,
          selectedRowData.driver_info.first_name === undefined
            ? '--'
            : selectedRowData.driver_info?.first_name +
              ' ' +
              selectedRowData.driver_info?.last_name,
          selectedRowData.stops_count,
        ];

  return (
    <Grid sx={{ backgroundColor: theme.palette.background.paper }} container p={3}>
      <Stack direction={'row'} alignItems='center' spacing={2}>
        {listDetails ? (
          <IconButton
            sx={{ bgcolor: theme.palette.background.paper, mb: 1 }}
            onClick={() => handleUrlBack()}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        ) : null}
        <MapIcon color={'primary'} />
        <Typography variant='h4'>Route Details</Typography>
      </Stack>
      <RouteTable tableHeading={tableHeading} tableBody={tableBody} />
      <Grid container xs={12}>
        <Grid
          sx={{ mt: 1 }}
          item
          xs={5}
          style={{ maxHeight: window.innerHeight * 0.7, overflow: 'auto' }}
        >
          {selectedTripRowData === true ? (
            <RenderStopsInfo localRouteGraph={selectedRowData.route_info.route_graph} />
          ) : tripData === undefined ? (
            <Skeleton sx={{ mt: 6 }} variant='rectangular' height={410} />
          ) : (
            <RenderRoutesStopsInfo localRouteGraph={tripData} />
          )}
        </Grid>
        <Grid sx={{ pt: 7 }} item xs={7}>
          {selectedTripRowData === true ? (
            <RenderOpenStreetMap
              routeGraph={selectedRowData}
              polylineData={selectedRowData.route_info.overview_polyline}
            />
          ) : tripData === undefined ? (
            <Skeleton variant='rectangular' height={410} />
          ) : (
            <RenderRouteOpenStreetMap
              school={{
                school_id: selectedSchool?.school_id,
                geo_location: {
                  latitude: selectedSchool?.geo_location.latitude,
                  longitude: selectedSchool?.geo_location.longitude,
                },
                address: { entity: selectedSchool?.school_name },
              }}
              routeGraph={tripData}
              overviewPolyline={selectedRowData.overview_polyline}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
