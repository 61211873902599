import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// APIs
import { getAllSchoolsPerAdminTransportationUsername } from 'clients/SpotBus';

// project imports
import { drawerWidth } from 'constants';
import { ConfigureStudents } from './ConfigureStudents';
import ConfigureRoutes from './ConfigureRoutes';
import DrawerTimeline from './DrawerTimeline';
import MainCard from 'components/MainCard';
import SchoolSelectionPage from './SchoolSelectionPage';

// ============================== SECOND PHASE LAYOUT - MAIN FILE ============================== //

function SecondPhase({
  guidedSchools,
  guidedWorkflowData,
  handleGuidedWorkflowDataUpdate,
  setPhase,
  isGuidedWorkflowCompleted,
  guidedRoutes,
  getAllRoutes,
  setlistLoader,
  listLoader,
}) {
  const [isLoading, setIsLoading] = useState(false);

  // school data specific states
  const [schoolsData, setSchoolsData] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [selectedSchoolData, setSelectedSchoolData] = useState();

  // active step state
  const [activeStep, setActiveStep] = useState(0);

  // For Button Hadling
  const [globalBtnRoutes, setGloablBtnRoutes] = useState(false);
  const [guidedButtonDisable, setGuideButtonDisable] = useState(false);

  const guidedData = guidedWorkflowData.steps_graph;

  const findingIndex = guidedData.findIndex((ele) => {
    return ele.name === 'CONFIGURE_SCHOOLS';
  });

  let configure_School_data = guidedWorkflowData.steps_graph[findingIndex].data.map((ele) => {
    return { shoolId: ele.school_id, is_setup_completed: ele.is_completed, section_name: ele.name };
  });

  const filterRoutes = configure_School_data.filter((ele) => {
    if (ele.section_name === 'ADD_STUDENTS') {
      return true;
    }
  });

  const getActiveStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ConfigureStudents
            selectedSchoolId={selectedSchoolId}
            handleGuidedWorkflowDataUpdate={handleGuidedWorkflowDataUpdate}
            guidedWorkflowData={guidedWorkflowData}
            setActiveStepforRoutes={setActiveStep}
            setRenderGPTwo={setSelectedSchoolId}
            globalBtnC={globalBtnRoutes}
            setGlobalBtnC={setGloablBtnRoutes}
            setSelectedSchoolId={setSelectedSchoolId}
          />
        );
      case 1:
        return (
          <ConfigureRoutes
            guidedRoutes={guidedRoutes}
            getAllRoutes={getAllRoutes}
            setlistLoader={setlistLoader}
            listLoader={listLoader}
            setSecondPhaseActiveStep={setActiveStep}
            selectedSchoolData={selectedSchoolData}
            selectedSchoolId={selectedSchoolId}
            setSelectedSchoolId={setSelectedSchoolId}
            guidedWorkflowData={guidedWorkflowData}
            setGloablBtnRoutes={setGloablBtnRoutes}
            globalBtnRoutes={globalBtnRoutes}
          />
        );
      default:
        return (
          <Typography color='error'>Error: No such step available, use valid step.</Typography>
        );
    }
  };

  const globalBtnContionforRoutes = (selectedSchool) => {
    filterRoutes.map((ele) => {
      if (
        ele.is_setup_completed === true &&
        // ele.section_name === 'ADD_ROUTES' &&
        ele.shoolId === selectedSchool
      ) {
        console.log('CONDITION OKAY');
        setGloablBtnRoutes(true);
      }
    });
  };

  const handleSchoolSelection = (schoolData) => {
    setSelectedSchoolId(schoolData.school_id);
    setSelectedSchoolData(schoolData);
    globalBtnContionforRoutes(schoolData.school_id);
    setActiveStep(0);
  };

  const loadAllSchools = async () => {
    if (guidedSchools && guidedSchools.length > 0) {
      setSchoolsData(guidedSchools);
      return;
    }
    setIsLoading(true);
    try {
      const result = await getAllSchoolsPerAdminTransportationUsername();
      setSchoolsData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAllSchools();
  }, []);

  // for all configure data
  let is_all_data_completed = configure_School_data.filter((data) => {
    if (
      data.section_name === 'ADD_STUDENTS' &&
      data.is_setup_completed === true
      // data.section_name === 'ADD_ROUTES' &&
      // data.is_setup_completed === true
    ) {
      return true;
    } else {
      return false;
    }
  });

  const studentSectionFilterFromConfigureSchoolData = configure_School_data.filter((data) => {
    if (data.section_name === 'ADD_STUDENTS') {
      return true;
    } else {
      return false;
    }
  });

  // To Check All Data
  const completedData = is_all_data_completed.length;
  const guidedDataLength = configure_School_data.length;

  // Guided Data Conditional Rendering
  const renderSectionBasedonGuidedData = () => {
    if (completedData === studentSectionFilterFromConfigureSchoolData.length) {
      setSelectedSchoolId(!selectedSchoolId);
      setGuideButtonDisable(true);
    }
  };

  useEffect(() => {
    renderSectionBasedonGuidedData();
  }, [guidedWorkflowData]);

  return (
    <>
      {!selectedSchoolId ? (
        <SchoolSelectionPage
          isLoading={isLoading}
          schoolsData={schoolsData}
          handleSchoolSelection={handleSchoolSelection}
          guidedWorkflowData={guidedWorkflowData}
          handleGuidedWorkflowDataUpdate={handleGuidedWorkflowDataUpdate}
          guidedButtonDisable={guidedButtonDisable}
          setPhase={setPhase}
        />
      ) : (
        <>
          <DrawerTimeline width={drawerWidth} activeStep={activeStep} />
          <Box sx={{ marginLeft: `${drawerWidth}px`, marginRight: 2 }}>
            <MainCard>{getActiveStepContent(activeStep)}</MainCard>
          </Box>
        </>
      )}
    </>
  );
}

SecondPhase.propTypes = {
  activeStep: PropTypes.number,
};

export default SecondPhase;
