import { languages } from 'utils';

export function arrayofNames(namesList, userID) {
  let arrayList = namesList?.map((ele) => {
    return {
      name: `${ele.first_name} ${ele.last_name}`,
      id: ele[userID],
    };
  });
  return arrayList;
}

export function languageDataBasedOnID(langCode) {
  const findLanguage = languages.find((ele) => ele.code === langCode);
  return findLanguage ? findLanguage : null;
}
