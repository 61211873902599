import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

// custom hooks & contexts
import { ColorModeProvider } from 'contexts';

// project imports
import IronManTheme from 'themes';
import AppWithAuth from './AppWithAuth';

// assets
import './index.css';

// Mux licensing
import { LicenseInfo } from '@mui/x-license';

// ============================== MAIN ENTRY POINT APP - THEME, LOCAL  ============================== //

LicenseInfo.setLicenseKey("3b119ce15209ef9f42aa55132443c35fTz05MDA4MixFPTE3NDY5NDQ5MzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const root = createRoot(document.getElementById('root'));

root.render(
  <ColorModeProvider>
    <IronManTheme>
      <AppWithAuth />
    </IronManTheme>
  </ColorModeProvider>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
