import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

//* ============================== QrButton ============================== //
const QrButton = ({
  imageFileName,
  heightWidth,
  imageUrl,
  isLoading,
  isSubmitting,
  label,
  ...props
}) => {
  // if loading is true then this jsx render in screen
  if (isLoading) {
    return (
      <Skeleton
        animation='wave'
        variant='rectangular'
        sx={{ width: heightWidth, height: heightWidth }}
      />
    );
  }

  // if loading is false the this jsx is returned
  return (
    <ImageButton
      focusRipple
      key={label}
      style={{
        width: `${heightWidth}px`,
        height: `${heightWidth}px`,
      }}
      disabled={isSubmitting}
      {...props}
    >
      <ImageSrc
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <ImageBackdrop className='MuiImageBackdrop-root' />
      <Image>
        <Box className='MuiImageMarked-root' component='span'>
          <a href={imageUrl} download={!isSubmitting && imageFileName}>
            {label}
          </a>
        </Box>
      </Image>
    </ImageButton>
  );
};

// default prop which is passed in second jsx
QrButton.defaultProps = {
  imageFileName: 'SpotBus',
  heightWidth: 120,
  imageUrl: '',
  isSubmitting: false,
  label: 'Download',
};

QrButton.propTypes = {
  buttonProps: PropTypes.object,
  imageFileName: PropTypes.string,
  heightWidth: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default QrButton;

// Styled Components ? This components overRide with Styled Components

const ImageButton = styled(ButtonBase)(({ theme }) => {
  return {
    position: 'relative',
    '& .MuiImageMarked-root': {
      transition: 'all .3s ease',
      opacity: 0,
    },
    '& .MuiImageMarked-root a': {
      transition: 'all .3s ease',
      textDecoration: 'none',
      color: 'inherit',
      padding: '.5rem 1rem',
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: '.2rem',
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 1,
        transition: 'all .3s ease',
      },
    },
  };
});

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  transition: 'all .3s ease 1ms',
  '&:hover, &.Mui-focusVisible': {
    transition: 'all .3s ease 1ms',
    backdropFilter: 'blur(3px)',
  },
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.secondary.dark,
  opacity: 0.1,
}));

// How to use This Components
/*
  this is Qr Button components for use any where in project 
    *how to use
    *1. import DefaultButton  from "/path"

    *2. use in JSX components

    * <QrButton
          imageUrl={`data:image/png;base64,${props?.qrImageObj}`}   
          imageFileName={props.rowData?.first_name + ' Spotbus qr'} 
          isSubmitting={false} 
          isLoading={false} 
          label='Download' 
    * />
  ? Props Explained
  * 1.    heightWidth={This is number pixel uses}
  * 
  * 2A.   imageUrl={`data:image/png;base64,${props?.qrImageObj}`} //this is take image url or image path (this case this is base64 data image)
  * 2B.   imageUrl={YourAnyImageLinks}
  * 
  * 3.    imageFileName={'you image saves this file name'}
  * 
  * 4.    isSubmitting={'it's a bool values when any api call this time may be need'} 
  * 
  * 
  * 5.    isLoading={'it's also loading state when api call this time going to true '} 
  * 
  * 6.    label='your Button Title'
  
*/
