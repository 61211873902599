import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  body: {
    fontSize: '0.75rem',
  },
}));

export default function RouteTable({ tableHeading, tableBody }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            {tableHeading.map((tableCell) => (
              <StyledTableCell align='center'>{tableCell}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {tableBody.map((tableBody) => (
              <StyledTableCell align='center'>{tableBody}</StyledTableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
