import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';

/**
 * A multi-select component using Material-UI.
 *
 * @param {Object} props - Component props
 * @param {string[]} props.menuList - List of options to display in the select menu.
 * @param {string} [props.label=''] - Label for the select input.
 * @param {Function} [props.handleClick=() => {}] - Function to handle click events on menu items.
 * @param {Object} [props.formControlSx] - Style object for the FormControl component.
 * @param {Object} [props.menuItemStyle] - Style object for the MenuItem component.
 * @param {Object} [props.selectStyle] - Style object for the Select component.
 * @param {'standard' | 'outlined' | 'filled'} [props.variant='outlined'] - Variant of the select component.
 * @param {'small' | 'medium' | 'large'} [props.size='medium'] - Size of the select component.
 *
 * @returns {JSX.Element} Multi-select component
 *
 * @example
 * const menuList = ['Option 1', 'Option 2', 'Option 3'];
 * const handleClick = (selectedValue) => console.log('Selected Value:', selectedValue);
 * <BaseXMultiSelect
 *   menuList={menuList}
 *   label="Select Options"
 *   handleClick={handleClick}
 *   formControlSx={{ minWidth: 120 }}
 *   menuItemStyle={{ justifyContent: 'center' }}
 *   selectStyle={{ textAlign: 'center' }}
 *   variant="standard"
 *   size="small"
 * />
 */
const BaseXMultiSelect = ({
  menuList = [],
  label = '',
  handleClick = () => {},
  formControlSx,
  menuItemStyle,
  selectStyle,
  variant = 'outlined',
  size = 'medium',
  handleChange = () => {},
  selectedValue,
}) => {
  return (
    <FormControl sx={formControlSx} size={size} variant={variant}>
      <Select
        labelId='muix-multiple-checkbox-label'
        id='muix-multiple-checkbox'
        multiple
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return `${label}`;
          }

          return selected
            .map((value) => menuList.find((item) => item.value === value)?.label)
            .join(', ');
        }}
        selectStyle={selectStyle}
      >
        {menuList.map((item) => (
          <MenuItem
            key={item.value}
            onClick={(e) => handleClick(encodeURIComponent)}
            value={item.value}
          >
            <Checkbox checked={selectedValue.indexOf(item.value) > -1} />
            <Box sx={menuItemStyle}>
              <ListItemText primary={item.label} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BaseXMultiSelect;
