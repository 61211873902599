import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

// project imports
import MainCard from 'components/MainCard';
import { Grid, Tooltip } from '@mui/material';

// ============================== CUSTOM CHIP ARRAY COMPONENT (Uses formik)  ============================== //
function CustomChipMultiSelect(props) {
  const { size, color, chipItems, helperText, children } = props;
  const { setFieldValue, errors, touched } = props.form;
  const { name, value = [] } = props.field;

  const isError = Boolean(errors[name] && touched[name]);
  const helpText = isError ? errors[name] : helperText;

  const handleChipClick = (clickedValue) => {
    let updatedValues;

    if (value.includes(clickedValue)) {
      // If the clicked value is already selected, remove it from the array
      updatedValues = value.filter((val) => val !== clickedValue);
    } else {
      // If the clicked value is not selected, add it to the array
      updatedValues = [...value, clickedValue];
    }

    setFieldValue(name, updatedValues);
  };

  return (
    <>
      <MainCard
        content={false}
        style={{
          padding: 9,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Grid container direction='row' alignItems='center' spacing={2}>
          {chipItems?.map((data, key) => {
            const isSelected = value.includes(data.value);
            return (
              <Grid item>
                <Tooltip title={data.phoneEmail}>
                  <Chip
                    key={key}
                    label={data.label}
                    size={size}
                    variant={isSelected ? 'default' : 'outlined'}
                    color={isSelected ? color : 'default'}
                    onClick={() => handleChipClick(data.value)}
                    id={data.label.toLowerCase()}
                    disabled={props.disabled}
                  />
                </Tooltip>
              </Grid>
            );
          })}
          {children}
        </Grid>
      </MainCard>
      <Typography variant='caption' color={isError ? 'error' : 'text.secondary'} ml={3}>
        {helpText}
      </Typography>
    </>
  );
}

CustomChipMultiSelect.defaultProps = {
  size: 'medium',
  color: 'primary',
  icon: true,
  children: null,
};

CustomChipMultiSelect.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  color: PropTypes.string,
  icon: PropTypes.bool,
  chipItems: PropTypes.array,
  label: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  children: PropTypes.node,
};

export default CustomChipMultiSelect;
