export const helperTexts = {
  publicRouteMainHelperText:
    'Public route dashboards are designed to help commuters, travelers, and other users make informed decisions about their journeys by providing them with accurate and up-to-date information about public transportation services. The dashboard offers users an overview of the current status of public routes, including information such as schedules, arrival and departure times, delays, route changes, service disruptions, and any other relevant updates. It may also include maps, interactive features, and additional data to enhance the user experience and provide a comprehensive view of the public transportation network.',
  GenerateDistrictLevelPublicRouteDashboardHelperText:
    'The district-level public route dashboard provide users with a specific link that they can either copy or view. By accessing this link, users will be able to see a page that displays all the schools within the district. This dashboard serves as a centralized platform where individuals can easily access information about schools in the district',
  GenerateSchoolLevelPublicRouteDashboardHelperText:
    'The school-level public route dashboard provide users with a specific link that they can either copy or view. By accessing this link, users will be able to see a page that displays all the information about a particular school, including the route and route trips associated with it.',
};


export function generateColorFromId(id) {
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  const hash = hashCode(id);
  const hue = Math.abs(hash) % 360;
  const saturation = 25;
  const lightness = 85;

  const rgb = hslToRgb(hue / 360, saturation / 100, lightness / 100);
  const hexCode = rgb.map((value) => value.toString(16).padStart(2, '0')).join('');

  const color = `#${hexCode}`;
  return color;
}
