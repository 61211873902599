import React, { forwardRef, useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// assets
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import PendingIcon from '@mui/icons-material/Pending';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { CircularProgress } from '@mui/material';
import { ContainerEditableMenu } from 'components/Menu/EditableMenu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// ============================== Status  ============================== //

// ? for auto complete
const DATA = {
  Completed: 'Completed',
  Failed: 'Failed',
  InProgress: 'InProgress',
  Pending: 'Pending',
  onRoad: 'OnRoad',
  BreakDown: 'BreakDown',
  AttentionRequired: 'Attention Required',
  Resolved: 'Resolved',
  InProcess: 'In Process',
};
// ? Static Data
const MenuItemsStatus = ['Completed', 'Failed', 'InProgress', 'Pending'];
const MenuItemsRoutTrip = ['Completed', 'OnRoad', 'BreakDown'];

function Status({
  handleEditClick,
  isIcon,
  label,
  isEditable,
  isLoading = false,
  isRouteTrip,
  isEditableFalseName,
  useDefiedLists,
  isEditIconShow = false,
  isListOpen = false,
  isShowDropDownIcon = false,
  ...props
}) {
  const { isSubmitting, sx } = props;
  const [isHovered, setIsHovered] = useState(isEditIconShow ? false : true);
  const [open, setOpen] = React.useState(isListOpen);
  const [value, setValues] = useState(null);

  const theme = useTheme();

  const handleChange = (event) => {
    setValues(event.target.value);
  };
  const fontSize = theme.typography.h4.fontSize;

  // _____________ color coding ___________
  const _handleTextColor = (data) => {
    switch (data) {
      case DATA.Completed: {
        const color = theme.palette.success.main;
        return {
          color,
          icon: <CheckCircleIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.Failed: {
        const color = theme.palette.error.main;

        return {
          color,
          icon: <CancelIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.InProgress: {
        const color = theme.palette.info.main;
        return {
          color,
          icon: <LoopIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.Pending: {
        const color = theme.palette.secondary.main;
        return {
          color,
          icon: <PendingIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.BreakDown: {
        const color = theme.palette.error.main;
        return {
          color,
          icon: <ReportProblemIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.onRoad: {
        const color = theme.palette.primary.main;
        return {
          color,
          icon: <AddRoadIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.AttentionRequired: {
        const color = theme.palette.error.main;

        return {
          color,
          icon: <CancelIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      case DATA.InProcess: {
        const color = theme.palette.primary.main;
        return {
          color,
          icon: <AddRoadIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
      default: {
        const color = theme.palette.success.main;
        return {
          color,
          icon: <CheckCircleIcon sx={{ color, fontSize: fontSize }} />,
        };
      }
    }
  };
  const MenuItems = !isEditable
    ? [isEditableFalseName]
    : isRouteTrip
    ? MenuItemsRoutTrip
    : useDefiedLists
    ? useDefiedLists
    : MenuItemsStatus;

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  function handleInitialState() {
    const fullList = [...MenuItemsRoutTrip, ...MenuItemsStatus, ...MenuItems];
    const matchList = fullList.indexOf(isEditableFalseName) !== -1;
    if (matchList) {
      setValues(isEditableFalseName);
    }
    return;
  }

  useEffect(() => {
    handleInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditableFalseName]);
  const dataLabel = MenuItems && MenuItems.find((item) => item === value);
  return (
    <ContainerEditableMenu flag='status' isEditable={isEditable || isShowDropDownIcon}>
      <FormControl
        variant='standard'
        sx={{
          position: 'relative',
          ...sx,
        }}
        onClick={(e) => {
          isEditable && e.stopPropagation();
        }}
        onMouseEnter={() => setIsHovered(false)}
        onMouseLeave={() => !isEditIconShow && setIsHovered(true)}
        {...props}
      >
        {label.length !== 0 && (
          <InputLabel id='status-simple-select-standard-label'>{label}</InputLabel>
        )}
        <Select
          disabled={isSubmitting} //? for submitting
          disableUnderline
          IconComponent={forwardRef((e) => {
            return isEditable || isShowDropDownIcon ? (
              <KeyboardArrowDownIcon
                sx={{ fill: _handleTextColor(value).color, px: 1 }}
                style={{ fontSize: '25px' }}
                {...e}
              />
            ) : null;
          })}
          id='status-simple-select-standard'
          labelId='status-simple-select-standard-label'
          onClose={handleClose}
          open={open}
          onOpen={handleOpen}
          onChange={handleChange}
          label='Status'
          value={value}
          sx={{
            color: _handleTextColor(value).color,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: !isEditable && 'none',
            border: dataLabel ? 1 : 0,
            borderRadius: 10,
            px: 3,
            '&:hover': {
              backgroundColor: theme.palette.hover.paper,
            },
          }}
          readOnly={!isEditable}
          startAdornment={
            isIcon && (
              <InputAdornment position='start'> {_handleTextColor(value).icon}</InputAdornment>
            )
          }
          //  In future if needed we will use the editable feature
          // endAdornment={
          //   <InputAdornment position='end'>
          //     {isEditable &&
          //       (isLoading ? (
          //         <CircularProgress
          //           size={18}
          //           style={{
          //             display: isHovered ? 'none' : '',
          //             position: 'absolute',
          //             cursor: 'pointer',
          //             right: '.5rem',
          //           }}
          //         />
          //       ) : (
          //         <EditIcon
          //           onClick={handleOpen}
          //           sx={{ fontSize: theme.typography.h4.fontSize }}
          //           style={{
          //             display: isHovered ? 'none' : '',
          //             position: 'absolute',
          //             cursor: 'pointer',
          //             right: '.5rem',
          //           }}
          //         />
          //       ))}
          //   </InputAdornment>
          // }
        >
          {MenuItems.map((d, i) => (
            <MenuItem
              key={i}
              onMouseLeave={() => !isEditIconShow && setIsHovered(true)}
              onClick={(e) => handleEditClick({ props, d, e })}
              value={d}
              sx={{ color: _handleTextColor(d).color, display: 'flex', alignItems: 'center' }}
            >
              {d}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ContainerEditableMenu>
  );
}

Status.defaultProps = {
  sx: { minWidth: '20px' },
  isIcon: true,
  icon: <AcUnitIcon sx={{ fontSize: '1.25rem' }} />,
  label: '',
  isEditable: false,
  isRouteTrip: false,
  isEditableFalseName: 'Completed',
  handleEditClick: () => {},
};
// ?______________________ Prop Validation ______________________
Status.propTypes = {
  handleEditClick: PropTypes.func,
  isIcon: PropTypes.bool,
  isEditable: PropTypes.bool,
  isRouteTrip: PropTypes.bool,
  isEditableFalseName: PropTypes.oneOf([...MenuItemsRoutTrip, ...MenuItemsStatus]),
  label: PropTypes.string,
  sx: PropTypes.object,
};

export default Status;

// ?How to use this Component
// 1. Import Status from "/path"
// 2. use In JSX Components
/**
 *@Status
 *   <Status
 *    isEditable={true}                 //isEditable true it's mean this you can edit table status
 *    isEditableFalseName={row.status} // if you false is Editable (isEditable=false) then you need to active this field other wise no need
 *    isIcon={false}                  // If you want to show Icon just true it
 *    label={'Status'}                // if you don't want to send label you can remove or label="" send empty string
 *    isRouteTrip={false}              // if you want to use in route sec you can easily use it
 *    handleEditClick={(d) => console.log(d,"Clicked_Data")} // any functionality you can trigger it
 *  />
 * */
