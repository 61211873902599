//Assets
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import TransgenderIcon from '@mui/icons-material/Transgender';

export function getgendericon(gender, theme) {
  switch (gender) {
    case 'male':
      return (
        <MaleIcon
          sx={{
            border: '2px solid' + theme.palette.primary.main,
            borderRadius: '10px',
            padding: '1px',
          }}
          fontSize='small'
          color='error'
        />
      );
    case 'female':
      return (
        <FemaleIcon
          sx={{
            border: '2px solid' + theme.palette.primary.main,
            borderRadius: '10px',
            padding: '1px',
          }}
          fontSize='small'
          color='error'
        />
      );
    case 'other':
      return (
        <TransgenderIcon
          sx={{
            border: '2px solid' + theme.palette.primary.main,
            borderRadius: '10px',
            padding: '1px',
          }}
          fontSize='small'
          color='error'
        />
      );
    default:
      return (
        <FemaleIcon
          sx={{
            border: '2px solid' + theme.palette.primary.main,
            borderRadius: '10px',
            padding: '1px',
          }}
          fontSize='small'
          color='error'
        />
      );
  }
}
