import React from 'react';

// MUI imports
import Typography from '@mui/material/Typography';

// ============================== Typography - Quick View Heading  ============================== //
function QuickViewHeading({ children, ...props }) {
  return <Typography {...props}>{children}</Typography>;
}

QuickViewHeading.defaultProps = {
  color: 'primary',
  gutterBottom: 'true',
  variant: 'subtitle1',
};

export default QuickViewHeading;
