import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';

// assets
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// ============================== STUDENT FORM - MAIN  ============================== //

function NextButton({ children, ...props }) {
  return (
    <Button {...props} endIcon={<ArrowForwardIcon />}>
      {children.toUpperCase()}
    </Button>
  );
}

NextButton.defaultProps = {
    variant: 'contained',
    size: 'large',
    color:'primary'
}
NextButton.propTypes = {
    variant: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.string
};

export default NextButton;
