import * as yup from 'yup';
import Validations from 'containers/layouts/Utils/Validations';
export default function validationSchemaForUser() {
  return yup.object().shape({
    first_name: Validations.first_name,
    last_name: Validations.last_name,
    phone_number: Validations.phone_number,
    phone_country_code: Validations.phone_country_code,
    email: Validations.email,
    gender: Validations.gender,
  });
}
