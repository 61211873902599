import React from 'react';
import { Typography } from '@mui/material';

export function SubHeading({ subTitle, color = 'text' }) {
  return (
    <Typography color={color} sx={{ fontSize: 24, fontWeight: 600 }}>
      {subTitle}
    </Typography>
  );
}
