import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// Material Ui Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';

// Global Components
import { DefaultButton } from 'components/Buttons/index';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import QuickViewActionItem from './QuickViewActionItem';

export default function QuickViewActionsTitle({
  isDataLoading,
  onEditClick,
  isSubmitting,
  onDeleteClick,
  hideEdit,
  hideDelete,
  heading,
  noWrap,
  defaultButtonTitle,
  isDefaultButton,
  defaultButtonstartIcon,
  onDefaultButtonClick,
  defaultButtonColor,
  defaultButtonVariant,
  tooltipContent,
  ...props
}) {
  const Skeltonfunctioncall = () => {
    return <Skeleton variant='h2' />;
  };
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      <Grid
        item
        xs={
          isDefaultButton && hideEdit && hideDelete
            ? 8
            : hideEdit && hideDelete
            ? 12
            : !hideEdit && hideDelete
            ? 9
            : !hideDelete && hideEdit
            ? 9
            : 6
        }
      >
        {!isDataLoading ? (
          <CustomTooltip title={heading} disableHoverListener={!noWrap ? true : false}>
            <Typography
              variant='h2'
              color='textPrimary'
              sx={{ textTransform: 'capitalize' }}
              noWrap={noWrap}
              align='left'
              {...props}
              id='heading_name'
            >
              {heading}
            </Typography>
          </CustomTooltip>
        ) : (
          <Skeltonfunctioncall />
        )}
      </Grid>
      {isDefaultButton && hideEdit && hideEdit && (
        <Grid item xs={4}>
          {!isDataLoading ? (
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <QuickViewActionItem
                id='parent_account_status'
                buttonText={defaultButtonTitle}
                color={'primary'}
                handleOnClick={onDefaultButtonClick}
                startIcon={<AddIcon />}
                disable={isSubmitting}
              />
            </Stack>
          ) : (
            <Skeltonfunctioncall />
          )}
        </Grid>
      )}

      {hideEdit && hideDelete ? null : !hideEdit || !hideDelete ? (
        <Grid item xs={!hideEdit && hideDelete ? 3 : !hideDelete && hideEdit ? 3 : 6}>
          {!isDataLoading ? (
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              {!hideEdit && (
                <DefaultButton
                  id='edit-button'
                  startIcon={<EditIcon />}
                  onClick={onEditClick}
                  disabled={isSubmitting}
                  color='secondary'
                >
                  Edit
                </DefaultButton>
              )}
              {!hideDelete && (
                <DefaultButton
                  id='delete-button'
                  startIcon={<DeleteIcon />}
                  onClick={onDeleteClick}
                  disabled={isSubmitting}
                  color='error'
                >
                  Delete
                </DefaultButton>
              )}
            </Stack>
          ) : (
            <Skeltonfunctioncall />
          )}
        </Grid>
      ) : null}
    </Grid>
  );
}

QuickViewActionsTitle.defaultProps = {
  heading: 'Heading',
  onEditClick: () => {},
  onDeleteClick: () => {},
  isSubmitting: false,
  hideEdit: false,
  hideDelete: false,
  isDataLoading: false,
  noWrap: true,
  isDefaultButton: false,
  defaultButtonTitle: 'Button Name',
  startIcon: <EditIcon />,
  onDefaultButtonClick: () => {},
  defaultButtonColor: 'primary',
  defaultButtonVariant: 'text',
};

QuickViewActionsTitle.propTypes = {
  heading: PropTypes.string,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  noWrap: PropTypes.bool,
  isDefaultButton: PropTypes.bool,
  defaultButtonTitle: PropTypes.bool,
  startIcon: PropTypes.node,
  onDefaultButtonClick: PropTypes.func,
  defaultButtonColor: PropTypes.string,
  defaultButtonVariant: PropTypes.string,
  tooltipContent: PropTypes.node,
};

// Readme.md

// We can import component as show in below

{
  /* <QuickViewActionsTitle
  isDataLoading={isDataLoading}
  onEditClick={handleEditClick}
  onDeleteClick={handleDeleteClick}
  isSubmitting={onlyGetRequestAllowed}
  heading={`Vehicle ${
    selectedVehicleData ? selectedVehicleData.vehicleTableRowNumber + 1 + vehicleSerial : ''
  }`}
/>; */
}

// We have feature of hiding button we can passing hideEdit Props
{
  /* <QuickViewActionsTitle
  isDataLoading={isDataLoading}
  onEditClick={handleEditClick}
  onDeleteClick={handleDeleteClick}
  isSubmitting={onlyGetRequestAllowed}
  heading={`Vehicle ${
    selectedVehicleData ? selectedVehicleData.vehicleTableRowNumber + 1 + vehicleSerial : ''
  }`}
  hideEdit={true}
/>; */
}

// If we want use Default Button Instead of Edit and Delete Button

// Example;

{
  /* <QuickViewActionsTitle
  hideEdit={true}
  hideDelete={true}
  defaultButtonstartIcon={<PersonIcon />}
  isDefaultButton={true}
  defaultButtonTitle={'Invite Parent'}
  isDataLoading={dataLoading}
  onDefaultButtonClick={() => setIsDialogOpen(true)}
  isSubmitting={onlyGetRequestAllowed}
/>; */
}

// Props Description
// heading: We have to pass heading
// onEditClick: Call Back function
// onDeleteClick:  Call Back function
// isSubmitting: When we are adding or delete ( Calling API ) that button should get disabled
// hideEdit: We can hide edit button by passing true prop
// hideDelete: We can hide delete button by passing true prop
// isDataLoading: For Skeleton we need to isDataloading is true or false
// noWrap: default it will be true we can make it false by passing prop
