export function getreadableroletype(role) {
  switch (role) {
    case 'ROLE_ADMIN':
      return 'Administrator';
    case 'ROLE_SCHOOL_LEVEL_ACCESS':
      return 'School Level Access';
    case 'ROLE_SCHOOL_LEVEL_READ_ONLY_ACCESS':
      return 'School Level Read Only Access';
    case 'ROLE_SUPER_ADMIN':
      return 'Super Administrator';
    default:
      return 'School Level Read Only Access';
  }
}
