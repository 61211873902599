import React, { useState } from 'react';
import { ActionButton, IcoButton } from 'components/Buttons';
// icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Loader from 'components/Loader';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonIcon from '@mui/icons-material/Person';
const ShiftHeader = ({
  setOpenDriverShifts,
  handleOpenDriverShiftForm,
  isReadOnlyUser,
  isEdit,
  loadUpdatedData,
  shiftTab,
  setShiftTab,
  driverData,
  handleShiftClick,
  openDriverShifts,
  selectedDriverUserId,
  setSelectedDriverUserId,
}) => {
  const handleShiftTabChange = (event, newValue) => {
    setShiftTab(newValue);
  };
  const handleDriverChange = (event) => {
    const value = event.target.value;
    handleShiftClick(event, value);
    setSelectedDriverUserId(value);
  };

  //   console.log(driverData, 'driver_data');Loader
  return (
    <Stack>
      {openDriverShifts.loading && (
        <Stack
          height={'100vh'}
          sx={{ background: '#0000001f' }}
          position={'fixed'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1000}
        >
          <Loader />
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Stack>
          <IcoButton
            icon={<ArrowBackIcon />}
            onClick={() =>
              setOpenDriverShifts((pre) => {
                return { ...pre, open: false };
              })
            }
          />
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Stack width={'10rem'}>
            <FormControl>
              <InputLabel id='select-driver-label'>Select Driver</InputLabel>
              <Select
                labelId='select-driver-label'
                id='select-driver-label'
                value={selectedDriverUserId}
                label='Select Driver'
                onChange={handleDriverChange}
                size='small'
                variant='standard'
                startAdornment={<PersonIcon />}
              >
                {driverData &&
                  driverData.map((data) => (
                    <MenuItem key={data.driver_username} value={data.driver_username}>
                      {data.first_name + ' ' + data.last_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
            <ActionButton
              id='driver-add-button'
              disabled={isReadOnlyUser || isEdit || loadUpdatedData}
              onClick={(e) => handleOpenDriverShiftForm(true)}
              startIcon={<PersonAddIcon />}
            >
              Add Shifts
            </ActionButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack mb={1}>
        <Tabs value={shiftTab} onChange={handleShiftTabChange} aria-label='driver shift tabs'>
          <Tab
            disabled={openDriverShifts.loading}
            label='Calendar'
            icon={<CalendarMonthIcon />}
            iconPosition='start'
          />
          <Tab
            disabled={openDriverShifts.loading}
            label='Shifts'
            icon={<FormatListBulletedIcon />}
            iconPosition='start'
          />
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default ShiftHeader;
