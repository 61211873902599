import { QuickViewItemCard } from 'components/cards';
import React from 'react';
import { Stack, Chip } from '@mui/material';

// icons
import MemoryIcon from '@mui/icons-material/Memory';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import TabletIcon from '@mui/icons-material/Tablet';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import SimCardIcon from '@mui/icons-material/SimCard';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

// Custom Component
import MainCard from 'components/MainCard';

// Helper Function
import { CARRIER, DEVICE_MODEL_LIST, DEVICE_TYPE, MANUFACTURER } from '../../helper';
import { formatPhoneNumber } from 'containers/layouts/Utils/FormatPhoneNumber';

function formatDeviceType(device) {
  switch (device) {
    case 'gps_tracker':
      return 'GPS Tracker';
    case 'tablet':
      return 'Tablet';
    case 'rfid':
      return 'RFID';
    default:
      return 'Unknown Device';
  }
}

const GetDeviceDetailsItems = ({ selectedDevicesData, isDataLoading }) => {
  if (!selectedDevicesData) return null;
  const { attendance, driverapp, gps_tracking } = selectedDevicesData.capabilities;

  // Get Device Model Name
  const getLabelValue = (id, DEVICE_MODEL_LIST) => {
    if (!id) {
      return '--';
    }
    const findSelectedLabel = DEVICE_MODEL_LIST?.find((ele) => ele.value === id);
    return findSelectedLabel && findSelectedLabel?.label;
  };

  return (
    <div>
      <QuickViewItemCard
        label='Device ID'
        icon={<SmartScreenIcon fontSize='large' />}
        id='device_id'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.device_id) ? selectedDevicesData.device_id : '--'}
      </QuickViewItemCard>
      <MainCard
        border
        content={false}
        sx={{
          p: 2,
          mt: 0,
        }}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Chip label='Driver App' color={driverapp ? 'primary' : 'secondary'} />
          <Chip label='Attendance' color={attendance ? 'primary' : 'secondary'} />
          {/* <Chip label='Gps Tracking' color={gps_tracking ? 'primary' : 'secondary'} /> */}
        </Stack>
      </MainCard>
      <QuickViewItemCard
        label='Device Type'
        icon={<PhonelinkIcon fontSize='large' />}
        id='device_type'
        isDataLoading={isDataLoading}
        sx={{ mt: 2 }}
      >
        {Boolean(selectedDevicesData.device_type)
          ? getLabelValue(selectedDevicesData.device_type, DEVICE_TYPE)
          : '--'}
      </QuickViewItemCard>
      <QuickViewItemCard
        label='Device Model'
        icon={<TabletIcon fontSize='large' />}
        id='device_model'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.device_model)
          ? getLabelValue(selectedDevicesData.device_model, DEVICE_MODEL_LIST)
          : '--'}
      </QuickViewItemCard>
      <QuickViewItemCard
        label='Manufacturer by'
        icon={<MemoryIcon fontSize='large' />}
        id='manufacturer_by'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.manufacturer)
          ? getLabelValue(selectedDevicesData.manufacturer, MANUFACTURER)
          : '--'}
      </QuickViewItemCard>
      <QuickViewItemCard
        label='Carrier'
        icon={<SignalCellularAltIcon fontSize='large' />}
        id='carrier'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.carrier)
          ? getLabelValue(selectedDevicesData.carrier, CARRIER)
          : '--'}
      </QuickViewItemCard>
      <QuickViewItemCard
        label='Sim Number'
        icon={<SimCardIcon fontSize='large' />}
        id='carrier'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.sim_number) ? selectedDevicesData.sim_number : '--'}
      </QuickViewItemCard>

      <QuickViewItemCard
        label='Phone Number'
        icon={<PhoneAndroidIcon fontSize='large' />}
        id='phone_number'
        isDataLoading={isDataLoading}
      >
        {Boolean(selectedDevicesData.phone_number)
          ? formatPhoneNumber(selectedDevicesData.phone_number?.number)
          : '--'}
      </QuickViewItemCard>
    </div>
  );
};

export default GetDeviceDetailsItems;
