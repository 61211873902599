import { API, Storage } from 'aws-amplify';

const API_ID = 'SpotBusAPI';

const API_DRIVER_SHIFT = '/dashboard-admin/drivers/';
// {{API_URL}}/dashboard-admin/drivers/a2a5670b-2d8d-4731-916b-0f55f1ab18d3/shifts/4589b245-8672-49fc-8051-2da9b87dbedc

/**
 * Registers a driver shift.
 *
 * @param {Object} body - The shift data.
 * @param {string} body.driver_id - The driver ID.
 * @returns {Promise} A promise that resolves with the result of the API call.
 */
export function registerDriverShift(body) {
  const id = body?.driver_id;
  return new Promise((resolve, reject) => {
    API.post(API_ID, API_DRIVER_SHIFT + id + '/shifts', {
      body: body,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

/**
 * Retrieves the shifts for a specific driver.
 *
 * @param {number} driver_id - The ID of the driver.
 * @return {Promise} A Promise that resolves with the shifts data or rejects with an error.
 */
export function getDriverShifts(driver_id) {
  return new Promise((resolve, reject) => {
    API.get(API_ID, API_DRIVER_SHIFT + driver_id + '/shifts')
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('Error ' + JSON.stringify(error.response));
        reject(error);
      });
  });
}

/**
 * Deletes a driver shift by driver ID and shift ID.
 *
 * @param {string} driver_id - The ID of the driver.
 * @param {string} id - The ID of the shift to delete.
 * @return {Promise} A Promise that resolves with the result of the deletion.
 */
export function deleteDriverShift(driver_id, id) {
  return new Promise((resolve, reject) => {
    API.del(API_ID, API_DRIVER_SHIFT + driver_id + '/shifts/' + id)
      .then((result) => {
        console.log(JSON.stringify(result));
        resolve(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        reject(error.response);
      });
  });
}

/**
 * Updates the driver's shift with the given body, driver ID, and shift ID.
 *
 * @param {object} body - The updated shift details.
 * @param {string} driver_id - The ID of the driver.
 * @param {string} id - The ID of the shift.
 * @return {Promise} A promise that resolves with the result of the API call, or rejects with an error response.
 */
export function updateDriverShift(body, driver_id, id) {
  return new Promise((resolve, reject) => {
    API.put(API_ID, API_DRIVER_SHIFT + driver_id + '/shifts/' + id, {
      body: body,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((error) => {
        console.log('Error from update api', error.response);
        reject(error.response);
      });
  });
}
