import React, { useState, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField as MuiTextField,
  Chip,
  Tooltip,
  Grid,
} from '@mui/material';
import { ClassificationHeading } from 'components/Headers';
import { inputVariantStudent } from 'constants';

// formik
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { CountrySelect, SelectField } from 'components/Formik';

// assets
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
import AddIcon from '@mui/icons-material/Add';
import StopIcon from '@mui/icons-material/Stop';
import Person2Icon from '@mui/icons-material/Person2';
import RemoveIcon from '@mui/icons-material/Remove';
import { truncateString } from 'utils/text';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { routeNumberFormatter } from 'utils/routeId';
import { IcoButton } from 'components/Buttons';

export const InputProps = (Icon) => ({
  startAdornment: (
    <InputAdornment position='start'>
      <Icon />
    </InputAdornment>
  ),
});

const fatherInfo = {
  labelText: 'Father',
  labelHelperText: 'Father label',
  labelValue: 'Father',
  labelNameId: 'father_label',

  first_name_label: 'Father First Name ',
  first_name_helperText: 'Enter father first name',
  first_name_nameId: 'father_first_name',
  first_name_icon: InputProps(PersonIcon),

  last_name_label: 'Father Last Name ',
  last_name_helperText: 'Enter father last name',
  last_name_nameId: 'father_last_name',
  last_name_icon: InputProps(PersonIcon),

  phone_code_label: 'Phone code ',
  phone_code_helperText: 'Phone code',
  phone_code_nameId: 'father_phone_code',

  phone_number_label: 'Phone Number ',
  phone_number_helperText: 'Enter phone number',
  phone_number_nameId: 'father_phone_number',
  phone_number_icon: InputProps(PhoneAndroidIcon),

  email_label: 'Email Address ',
  email_helperText: 'Enter email address',
  email_nameId: 'father_email',
  email_icon: InputProps(EmailIcon),
};

const motherInfo = {
  labelText: 'Mother ',
  labelHelperText: 'Mother label',
  labelValue: 'Mother',
  labelNameId: 'mother_label',

  first_name_label: 'Mother First Name ',
  first_name_helperText: 'Enter mother first name',
  first_name_nameId: 'mother_first_name',
  first_name_icon: InputProps(Person2Icon),

  last_name_label: 'Mother Last Name ',
  last_name_helperText: 'Enter mother last name',
  last_name_nameId: 'mother_last_name',
  last_name_icon: InputProps(Person2Icon),

  phone_code_label: 'Phone code ',
  phone_code_helperText: 'Phone code',
  phone_code_nameId: 'mother_phone_code',

  phone_number_label: 'Phone Number ',
  phone_number_helperText: 'Enter phone number',
  phone_number_nameId: 'mother_phone_number',
  phone_number_icon: InputProps(PhoneAndroidIcon),

  email_label: 'Email Address ',
  email_helperText: 'Enter email address',
  email_nameId: 'mother_email',
  email_icon: InputProps(EmailIcon),
};

const guardianInfo = {
  labelText: 'Guardian',
  labelHelperText: 'Guardian label',
  labelValue: 'Guardian',
  labelNameId: 'guardian_label',

  first_name_label: 'Guardian First Name',
  first_name_helperText: 'Enter guardian first name',
  first_name_nameId: 'guardian_first_name',
  first_name_icon: InputProps(PersonIcon),

  last_name_label: 'Guardian Last Name',
  last_name_helperText: 'Enter guardian last name',
  last_name_nameId: 'guardian_last_name',
  last_name_icon: InputProps(PersonIcon),

  phone_code_label: 'Phone code',
  phone_code_helperText: 'Phone code',
  phone_code_nameId: 'guardian_phone_code',

  phone_number_label: 'Phone Number',
  phone_number_helperText: 'Enter phone number',
  phone_number_nameId: 'guardian_phone_number',
  phone_number_icon: InputProps(PhoneAndroidIcon),

  email_label: 'Email Address',
  email_helperText: 'Enter email address',
  email_nameId: 'guardian_email',
  email_icon: InputProps(EmailIcon),
};
const ParentsAndOtherInformation = ({ formikProps, ...props }) => {
  console.log(formikProps.errors, 'formik_parent_error');
  return (
    <Stack spacing={3} direction={'column'} width={'100%'}>
      <ParentInfo {...formikProps} />
      <Route formikProps={formikProps} {...props} />
      <Medication formikProps={formikProps} />
      {/* <Notes /> */}
    </Stack>
  );
};

const ParentInfo = (formikProps) => {
  const { initialValues } = formikProps;
  const {
    guardian_email,
    guardian_first_name,
    guardian_last_name,
    guardian_phone_number,
  } = initialValues;

  const isGuardian =
    guardian_email || guardian_first_name || guardian_last_name || guardian_phone_number;
  const [openClose, setOpenClose] = useState(isGuardian);

  const handleAdd = () => {
    setOpenClose((pre) => {
      const { setValues } = formikProps;
      if (pre) {
        setValues((pre) => {
          return {
            ...pre,
            guardian_first_name: '',
            guardian_last_name: '',
            guardian_email: '',
            guardian_phone_number: '',
          };
        });
      }
      return !pre;
    });
  };
  const addGuardianButton = (
    <Tooltip title={`${!openClose ? 'Add' : 'Remove'} Guardian`}>
      <IconButton sx={{ width: '40px', height: '40px' }} color='primary' onClick={handleAdd}>
        {openClose ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
    </Tooltip>
  );
  return (
    <Stack spacing={3}>
      <ClassificationHeading>Parent Information</ClassificationHeading>
      <Stack spacing={3}>
        <FatherMotherGuardian formikProps={formikProps} {...fatherInfo} />
        <Stack direction={'row'} flexWrap={'noWrap'}>
          <FatherMotherGuardian
            formikProps={formikProps}
            {...motherInfo}
            btnLast={!openClose && addGuardianButton}
          />
        </Stack>
        {openClose && (
          <FatherMotherGuardian
            formikProps={formikProps}
            {...guardianInfo}
            btnLast={addGuardianButton}
          />
        )}
      </Stack>
    </Stack>
  );
};

const FatherMotherGuardian = (props) => {
  const {
    labelText,
    labelHelperText,
    labelValue,
    labelNameId,

    first_name_label,
    first_name_helperText,
    first_name_nameId,
    first_name_icon,

    last_name_label,
    last_name_helperText,
    last_name_nameId,
    last_name_icon,

    phone_code_label,
    phone_code_helperText,
    phone_code_nameId,

    phone_number_label,
    phone_number_helperText,
    phone_number_nameId,
    phone_number_icon,

    email_label,
    email_helperText,
    email_nameId,
    email_icon,
    formikProps,
    btnLast = '',
  } = props;

  return (
    <Stack spacing={3} direction={'row'} width={'100%'} flexWrap={''}>
      <Stack width={'12%'}>
        <Field
          component={TextField}
          value={labelValue}
          label={labelText}
          disabled
          name={labelNameId}
          id={labelNameId}
          variant={inputVariantStudent}
          // InputProps={lable}
          helperText={labelHelperText}
          size='small'
        />
      </Stack>
      <Stack width={'20%'}>
        <Field
          component={TextField}
          // value={'sdfsf'}
          type='text'
          label={first_name_label}
          name={first_name_nameId}
          id={first_name_nameId}
          variant={inputVariantStudent}
          InputProps={first_name_icon}
          helperText={first_name_helperText}
          size='small'
        />
      </Stack>
      <Stack width={'20%'}>
        <Field
          component={TextField}
          // value={}
          label={last_name_label}
          name={last_name_nameId}
          id={last_name_nameId}
          variant={inputVariantStudent}
          InputProps={last_name_icon}
          helperText={last_name_helperText}
          size='small'
        />
      </Stack>
      <Stack width={'10%'}>
        <CountrySelect
          name={phone_code_nameId}
          label={phone_code_label}
          id={phone_code_nameId}
          type='phone'
          countryId={phone_code_nameId}
          helperTextContent={phone_code_helperText}
          textFieldProps={{
            variant: inputVariantStudent,
            size: 'small',
          }}
          {...formikProps}
        />
      </Stack>
      <Stack width={'20%'}>
        <Field
          component={TextField}
          label={phone_number_label}
          name={phone_number_nameId}
          id={phone_number_nameId}
          variant={inputVariantStudent}
          InputProps={phone_number_icon}
          helperText={phone_number_helperText}
          type='number'
          size='small'
        />
      </Stack>
      <Stack direction={'row'} width={'20%'}>
        <Field
          component={TextField}
          label={email_label}
          name={email_nameId}
          id={email_nameId}
          helperText={email_helperText}
          variant={inputVariantStudent}
          InputProps={email_icon}
          size='small'
        />
        {btnLast}
      </Stack>
    </Stack>
  );
};

// As we are stop id is getting based on the index so we are adding 1 to show proper
// stop count number
function convertAndAddOne(input) {
  if (!input) {
    return '--';
  }
  let num = parseFloat(input);
  if (!isNaN(num)) {
    // If it's a number, add 1 to it
    num += 1;
    return num;
  } else {
    return input;
  }
}

const getRoutesItems = (routes, schoolsData) => {
  let routesItems = [
    {
      label: 'None',
      value: 'none',
    },
  ];

  const mappedRoutes =
    routes &&
    routes?.map((route) => {
      return {
        label: routeNumberFormatter(
          schoolsData,
          route?.school_id,
          route?.school_route_number,
          route?.route_type,
        ),
        value: route.route_id,
      };
    });

  return mappedRoutes && routesItems.concat(mappedRoutes);
};

const getStopItems = (routes, selectedRouteId) => {
  // Find the selected route
  const { route_graph: selectedRouteGraph } =
    routes?.find((route) => route.route_id === selectedRouteId) || {};

  let stopItems = [
    {
      label: 'None',
      value: 'none',
    },
  ];

  if (selectedRouteGraph) {
    const filteredStops = selectedRouteGraph
      .filter((stop) => stop.route_stop_id !== 'source' && stop.route_stop_id !== 'destination')
      .map((stop,index) => ({
        label: `Stop ${index+1} - ${stop.address}`,
        value: stop.route_stop_id,
      }));

    stopItems = stopItems.concat(filteredStops);
  }

  return stopItems;
};

const Route = (props) => {
  const { amRoutes, pmRoutes, formikProps, schoolsData } = props;
  const { values, setFieldValue } = formikProps;

  // Memorized functions which provide AM route and stop options
  const amRouteItems = useMemo(() => getRoutesItems(amRoutes, schoolsData), [amRoutes]);
  const amStopItems = useMemo(() => getStopItems(amRoutes, values.am_route), [
    amRoutes,
    values.am_route,
  ]);
  // Memorized functions which provide AM route and stop options
  const pmRouteItems = useMemo(() => getRoutesItems(pmRoutes, schoolsData), [pmRoutes]);
  const pmStopItems = useMemo(() => getStopItems(pmRoutes, values.pm_route), [
    pmRoutes,
    values.pm_route,
  ]);

  const sameAsOther = () => {
    if (values.am_route && values.am_stop) {
      let pmRouteItem = pmRouteItems.find(
        (item) => item.value.split('@')[0] === values.am_route.split('@')[0],
      );
      let pmRoute = pmRoutes.find((route) => route.route_id === pmRouteItem?.value);

      if (pmRouteItem && Object.keys(pmRouteItem).length > 0) {
        setFieldValue('pm_route', pmRouteItem.value);
      }

      if (
        pmRoute &&
        Object.keys(pmRoute).length > 0 &&
        pmRoute.route_graph.length >= parseInt(values.am_stop)
      ) {
        setFieldValue('pm_stop', values.am_stop);
      }
    }
  };
  const amStopLabel = amStopItems?.find((item) => item.value === values.am_stop)?.label || '';
  const pmStopLabel = pmStopItems?.find((item) => item.value === values.pm_stop)?.label || '';

  return (
    <Stack>
      <ClassificationHeading>Route Information</ClassificationHeading>

      <Grid container xs={12} sx={{ mt: 0 }} spacing={3}>
        <Grid item xs={11.5}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={3}>
            <Stack width={'30%'}>
              <Field
                fullWidth
                component={SelectField}
                label='AM Route'
                name='am_route'
                variant={inputVariantStudent}
                Icon={MapIcon}
                data={amRouteItems}
                async
                id='am_route'
                helperText='Select AM route'
                selectFieldProps={{
                  variant: inputVariantStudent,
                  size: 'small',
                  renderValue: (value) => {
                    const route_label = amRouteItems?.find((ele) => ele.value === value);
                    const route_label_value = Boolean(route_label?.label)
                      ? route_label?.label
                      : '--';
                    return (
                      <Tooltip title={route_label_value}>
                        {truncateString(route_label_value, 15)}
                      </Tooltip>
                    );
                  },
                }}
              />
            </Stack>
            <Stack width={'70%'}>
              <Field
                fullWidth
                component={SelectField}
                label='AM Stop'
                name='am_stop'
                variant={inputVariantStudent}
                Icon={StopIcon}
                data={amStopItems}
                id='am_stop'
                helperText='Select AM stop'
                selectFieldProps={{
                  variant: inputVariantStudent,
                  size: 'small',
                  renderValue: (value) => {
                    return (
                      <Tooltip title={amStopLabel}>{truncateString(amStopLabel, 800)}</Tooltip>
                    );
                  },
                }}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={0.5}>
          <Tooltip title='Copy AM Route, AM Stop to PM Route, PM Stop or vice versa.'>
            <IconButton
              color='primary'
              id='sync_btn'
              variant={inputVariantStudent}
              onClick={sameAsOther}
              fullWidth
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={11.5}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={3}>
            <Stack width={'30%'}>
              <Field
                fullWidth
                component={SelectField}
                label='PM Route'
                name='pm_route'
                variant={inputVariantStudent}
                Icon={MapIcon}
                data={pmRouteItems}
                async
                id='pm_route'
                helperText='Select PM route'
                selectFieldProps={{
                  variant: inputVariantStudent,
                  size: 'small',
                  renderValue: (value) => {
                    const route_label = pmRouteItems?.find((ele) => ele.value === value);
                    const route_label_value = Boolean(route_label?.label)
                      ? route_label?.label
                      : '--';
                    return (
                      <Tooltip title={route_label_value}>
                        {truncateString(route_label_value, 15)}
                      </Tooltip>
                    );
                  },
                }}
              />
            </Stack>

            <Stack width={'70%'}>
              <Field
                fullWidth
                component={SelectField}
                label='PM Stop'
                name='pm_stop'
                variant={inputVariantStudent}
                Icon={StopIcon}
                data={pmStopItems}
                id='pm_stop'
                helperText='Select PM stop'
                selectFieldProps={{
                  variant: inputVariantStudent,
                  size: 'small',
                  renderValue: (value) => {
                    return (
                      <Tooltip title={pmStopLabel}>{truncateString(pmStopLabel, 250)}</Tooltip>
                    );
                  },
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
const Medication = (props) => {
  const inputRef = useRef();
  const { formikProps } = props;
  const { setValues, values } = formikProps;
  const { medicines } = values;
  const [inputVal, setInputVal] = useState('');
  const handleDelete = (value) => {
    setValues((prev) => {
      const newList = prev.medicines.filter((item) => item !== value);
      return { ...prev, medicines: newList };
    });
  };
  const handleKeyDown = (e) => {
    const { value } = e.target;

    if (e.charCode === 13) {
      if (value.trim() === '') {
        return;
      }
      setValues((prev) => {
        if (prev.medicines.includes(value)) {
          return prev;
        }
        setInputVal('');
        return { ...prev, medicines: [...prev.medicines, value] };
      });
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setInputVal(value);
  };
  const handleAddMedication = () => {
    if (inputVal.trim() === '') {
      return;
    }
    setValues((prev) => {
      if (prev.medicines.includes(inputVal)) {
        return prev;
      }
      setInputVal('');
      return { ...prev, medicines: [...prev.medicines, inputVal] };
    });
  };

  return (
    <Stack spacing={2}>
      <ClassificationHeading>Medication</ClassificationHeading>
      <Stack>
        <FormControl>
          <Box border={1} sx={{ borderRadius: 2 }} display={'flex'} alignItems={'center'}>
            {medicines.map((list) => {
              return (
                <Chip
                  label={list}
                  onDelete={() => handleDelete(list)}
                  sx={{ ml: 1 }}
                  variant={inputVariantStudent}
                />
              );
            })}
            <MuiTextField
              value={inputVal}
              id='student_medication'
              placeholder='Medication'
              ref={inputRef}
              size='small'
              variant='standard'
              sx={{ m: 2 }}
              // onKeyDown={handleKeyDown}
              onChange={handleChange}
              onKeyPress={handleKeyDown}
              // onBlur={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={handleAddMedication}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <FormHelperText>Enter Medication</FormHelperText>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default ParentsAndOtherInformation;
