import React from 'react';

// Material UI
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';

// Formik
import { useFormikContext } from 'formik';

const TimePickerField = ({ field, variant, label, form, helperText, meta, ...other }) => {
  const { name, value } = field;
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();

  const handleTimeChange = (date) => {
    setFieldValue(name, date);
    setFieldTouched(name, true, false);
  };

  const isError = touched[name] && errors[name];
  const _value = value ? value : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        fullWidth
        id='time-picker'
        value={_value}
        onChange={handleTimeChange}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <TextField
            inputProps={inputProps}
            inputRef={inputRef}
            label={label}
            {...InputProps}
            error={isError}
            helperText={isError ? errors[name] : helperText}
            variant={variant}
            fullWidth
            InputLabelProps={{
              shrink: true,
              error: isError,
            }}
            InputProps={{
              endAdornment: <React.Fragment>{InputProps?.endAdornment}</React.Fragment>,
            }}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
};

export default TimePickerField;
