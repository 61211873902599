import React from 'react';
import { Button, Grid } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export default function LoaderButton(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid>
        <Button
          style={{
            color: theme.palette.primary.contrastText,
            backgroundColor:
              props.disable === false
                ? props.color === 'primary'
                  ? theme.palette.primary.main
                  : theme.palette.error.main
                : theme.palette.secondary.extraLight,
            '&:hover': {
              backgroundColor:
                props.disable === false
                  ? props.color === 'primary'
                    ? theme.palette.primary.main
                    : theme.palette.error.main
                  : theme.palette.secondary.extraLight,
            },
          }}
          id={props.id}
          variant={props.variant === undefined ? 'contained' : props.variant}
          type='submit'
          size={props.size === undefined ? 'large' : props.size}
          onClick={props.click === undefined ? null : props.click}
          disabled={props.disable === undefined ? null : props.disable}
          fullWidth={props.btnWidth === true ? true : false}
        >
          {props.buttonLoader === true ? (
            <CircularProgress size={22} className={classes.spinner} sx={{color: 'common.white'}} />
          ) : props.icon === 'save' ? (
            <SaveOutlinedIcon className={classes.spinner} />
          ) : props.icon === undefined ? null : (
            <DeleteIcon className={classes.spinner} />
          )}{' '}
          {props.buttonName}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

LoaderButton.propTypes = {
  buttonName: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  buttonLoader: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginRight: theme.spacing(2),
  },
}));
