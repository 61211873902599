// assets
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import PersonIcon from '@mui/icons-material/Person';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';

const chipItems = [
  { label: 'Draft', icon: <ArrowDropDownCircleIcon />, value: 'draft', color: 'secondary' },
  { label: 'Active', icon: <CheckCircleIcon />, value: 'active', color: 'success' },
  { label: 'Closed', icon: <CancelIcon />, value: 'closed', color: 'error' },
  { label: 'Testing', icon: <CancelIcon />, value: 'testing', color: 'secondary' },
];

const chipItemForGender = [
  { label: 'Male', icon: <ManIcon />, value: 'male', color: 'primary' },
  { label: 'Female', icon: <WomanIcon />, value: 'female', color: 'primary' },
  { label: 'Not Specific', icon: <PersonIcon />, value: 'na', color: 'primary' },
];

const getDefaultSelectedChip = () => 'pending';

export { chipItems, chipItemForGender, getDefaultSelectedChip };
