import React from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import NotesIcon from '@mui/icons-material/Notes';

// Formik
import { Field } from 'formik';
import { TextField } from 'formik-mui';

// Custom Component
import DateSelectorFormik from 'components/Formik/DateSelectorFormik';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Divider } from '@mui/material';

// icons
import PersonIcon from '@mui/icons-material/Person';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventIcon from '@mui/icons-material/Event';
import { DATE_OCCURRENCE } from './formInitialValuesDriverShifts';
import ShiftSelect from './components/ShiftSelect';
import Nonrepetitive from './components/NonRepetitive';
import MonthlySelection from './components/Monthly';
import WeeklySelection from './components/WeeklySelection';
// constant
const variant = 'outlined';

export const formFieldValues = [
  {
    day: 'Monday',
    checkBoxName: 'mondayCheckBox',
    startTimeName: 'mondayStartTime',
    endTimeName: 'mondayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeMonday',
  },
  {
    day: 'Tuesday',
    checkBoxName: 'tuesdayCheckBox',
    startTimeName: 'tuesdayStartTime',
    endTimeName: 'tuesdayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeTuesday',
  },
  {
    day: 'Wednesday',
    checkBoxName: 'wednesdayCheckBox',
    startTimeName: 'wednesdayStartTime',
    endTimeName: 'wednesdayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeWednesday',
  },
  {
    day: 'Thursday',
    checkBoxName: 'thursdayCheckBox',
    startTimeName: 'thursdayStartTime',
    endTimeName: 'thursdayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeThursday',
  },
  {
    day: 'Friday',
    checkBoxName: 'fridayCheckBox',
    startTimeName: 'fridayStartTime',
    endTimeName: 'fridayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeFriday',
  },
  {
    day: 'Saturday',
    checkBoxName: 'saturdayCheckBox',
    startTimeName: 'saturdayStartTime',
    endTimeName: 'saturdayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeSaturday',
  },
  {
    day: 'Sunday',
    checkBoxName: 'sundayCheckBox',
    startTimeName: 'sundayStartTime',
    endTimeName: 'sundayEndTime',
    allowEarlyClockInTime: 'allowEarlyClockInTimeSunday',
  },
];

export default function DriverShiftForm({
  formikProps,
  isUpdate,
  setOccurrenceType,
  driverData,
  selectedDriverUserId,
}) {
  /**
   * Handles the change event of the occurrence type.
   *
   * @param {Object} event - The event object.
   */
  const handleOccurrenceTypeChange = (event) => {
    const value = event.target.value;
    setOccurrenceType(value);
    formikProps.setFieldValue('dateOccurrence', event.target.value);

    /**
     * this comment is for future if you want to clear the fields on change
     */
    // if (value === DATE_OCCURRENCE.MONTHLY) {
    //   // if we select monthly then we need to clear the weekFieldName
    //   for (let i = 0; i < weekFieldName.length; i++) {
    //     const startData = weekFieldName[i].startTime;
    //     const endData = weekFieldName[i].endTime;
    //     formikProps.setFieldValue(startData, null);
    //     formikProps.setFieldValue(endData, null);
    //   }
    // }
    // if (value === DATE_OCCURRENCE.WEEKLY) {
    //   formikProps.setFieldValue('monthlyDate', monthlyOccurrenceInitialValues);
    // }
  };

  const handleOccurrenceType = (event) => {
    const value = event.target.value;
    formikProps.setFieldValue('occurenceType', event.target.value);
    if (value === DATE_OCCURRENCE.ADHOC) {
      formikProps.setFieldValue('dateOccurrence', event.target.value);
      setOccurrenceType(value);
    }
    if (value === DATE_OCCURRENCE.REGULAR) {
      formikProps.setFieldValue('dateOccurrence', DATE_OCCURRENCE.WEEKLY);
      setOccurrenceType(DATE_OCCURRENCE.WEEKLY);
    }
  };

  const driverName = driverData.find((driver) => driver.driver_username === selectedDriverUserId);
  return (
    <Stack sx={{ minHeight: '100%' }}>
      <Stack spacing={3}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <PersonIcon />
          <Typography variant='h5'>
            {driverName?.first_name + ' ' + driverName?.last_name}
          </Typography>
        </Stack>

        <Stack direction={'row'} justifyContent='space-between' spacing={2}>
          <Stack width={'25%'}>
            <Field
              variant={variant}
              name='shiftTitle'
              helperText='Enter Shift title'
              component={TextField}
              fullWidth
              label='Shift Title *'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <NotesIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack width={'25%'}>
            <Field
              variant={variant}
              name='startDate'
              helperText='Select start date'
              component={DateSelectorFormik}
              disabled={formikProps.isSubmitting}
              disablePast
              label='Start Date *'
            />
          </Stack>
          <Stack width={'25%'}>
            <Field
              variant={variant}
              disabled={formikProps.isSubmitting}
              name='endDate'
              helperText='Select end date'
              component={DateSelectorFormik}
              label='End Date *'
              disablePast
            />
          </Stack>
          <Stack width={'25%'}>
            <Field
              fullWidth
              component={TextField}
              name={'earlyClockInTime'}
              placeholder={'Allow Early Clock in time'}
              helperText={'Enter time'}
              variant={variant}
              type='number'
              label='Allow Early Clock in time *'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccessTimeIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Typography>{'Minutes'}</Typography>
                  </InputAdornment>
                ),

                inputProps: { min: 0, max: 15, step: 1 },
              }}
            />
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent='space-between' spacing={2} width={'100%'} pt={1}>
          <Stack width={'50%'}>
            <Field
              fullWidth
              component={ShiftSelect}
              label='Select Type *'
              name='occurenceType'
              variant={variant}
              Icon={EventIcon}
              data={[
                { label: 'Ad hock', value: DATE_OCCURRENCE.ADHOC },
                { label: 'Regular', value: DATE_OCCURRENCE.REGULAR },
              ]}
              // Icon={EventIcon}
              // helperText='Select Special needs level'
              async={false}
              id='occurenceType'
              selectFieldProps={{
                onChange: handleOccurrenceType,
                // variant: variant,
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                },
                // size: 'small',
                // marginTop: '10px',
              }}
              sx={{ width: '100%' }}
            />
          </Stack>

          <Stack width={'50%'}>
            {formikProps.values.occurenceType === 'regular' && (
              <Stack width={'100%'}>
                <Field
                  fullWidth
                  component={ShiftSelect}
                  label='Select Date Occurrence *'
                  name='dateOccurrence'
                  variant={variant}
                  Icon={EventRepeatIcon}
                  data={[
                    { label: 'Weekly', value: DATE_OCCURRENCE.WEEKLY },
                    { label: 'Monthly', value: DATE_OCCURRENCE.MONTHLY },
                  ]}
                  // helperText='Select Special needs level'
                  async={false}
                  id='dateOccurrence'
                  selectFieldProps={{
                    onChange: handleOccurrenceTypeChange,
                    // variant: variant,
                    sx: {
                      display: 'flex',
                      alignItems: 'center',
                    },
                    // size: 'small',
                    // marginTop: '10px',
                  }}
                  sx={{ width: '100%' }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack paddingTop={2}>
          <Divider />
        </Stack>
        <Stack>
          {formikProps.values.dateOccurrence === 'weekly' && (
            <WeeklySelection formikProps={formikProps} variant={variant} />
          )}
          {formikProps.values.dateOccurrence === 'monthly' && (
            <MonthlySelection formikProps={formikProps} variant={variant} />
          )}
          {formikProps.values.occurenceType === 'nonrepetitive' && (
            <Nonrepetitive formikProps={formikProps} variant={variant} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
