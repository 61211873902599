import React from 'react';
import { Grid, Button, TextField, InputAdornment, Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

// assets
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// project imports
import MyDropzone from './MyDropzone';
import LoaderButton from 'components/Loader/LoaderButton';

// Material ui
import Typography from '@mui/material/Typography';

// Custom Component
import BackButton from 'components/Buttons/BackButton';
import ActionButton from 'components/Buttons/ActionButton';
import { NextButton } from 'components/Buttons';
//Assets
import SaveIcon from '@mui/icons-material/Save';
export default function UploadCertificateForm(props) {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState('');
  const [checkError, setCheckError] = React.useState(false);

  const handleCancel = () => {
    props.setActiveStep(2);
    props.setFlag(false);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  // const handleSubmits = (event) => {
  //   event.preventDefault();
  //   setCheckError(props.enableCertificateForm === true ? true : false);
  //   if (!props.isEdit) {
  //     if (props.enableCertificateForm === true) {
  //       if (
  //         props.certificateName?.trim().length > 1 &&
  //         props.certificateImage &&
  //         props.certificateAuthority?.trim().length > 1
  //       ) {
  //         props.handleSubmitBasicForm();
  //         // props.setActiveStep(4);
  //       }
  //     } else {
  //       // props.setActiveStep(4);
  //       props.handleSubmitBasicForm();
  //     }
  //   } else {
  //     // props.setActiveStep(4);
  //     props.handleUpdateBasicForm();
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCheckError(props.enableCertificateForm === true ? true : false);
    if (!props.isEdit) {
      if (props.enableCertificateForm === true) {
        if (
          props.certificateName?.trim().length >= 1 &&
          props.certificateImage &&
          props.certificateAuthority?.trim().length >= 1
        ) {
          props.handleSubmitBasicForm();
        }
      } else {
        props.handleSubmitBasicForm();
      }
    } else {
      props.handleUpdateBasicForm();
    }
    if (props.isEdit) {
      if (props.profileImageData) {
        props.setProfileImageResponse(undefined);
      }
      if (props.licenseFrontImageData) {
        props.setLicenseFrontImageResponse(undefined);
      }
      if (props.licenseBackImageData) {
        props.setLicenseBackImageResponse(undefined);
      }
      if (props.certificateImageData) {
        props.setCertificateImageResponse(undefined);
      }
    }
    if (props.enableCertificateForm === true) {
      if (
        props.certificateName?.trim().length > 1 &&
        props.certificateImage &&
        props.certificateAuthority?.trim().length > 1
      ) {
        props.setFlag(true);
        props.setButtonLoader(true);
      }
    } else {
      props.setFlag(true);
      props.setButtonLoader(true);
    }
  };

  return (
    <Grid container xs={12} direction='column'>
      <form>
        <Grid container spacing={3} sx={{ height: 497 }}>
          <Grid
            container
            item
            direction='row'
            xs={12}
            sx={{ minHeight: props.isFromGuided ? 505 : 0 }}
          >
            <Grid
              container
              item
              // className={classes.image}
              sx={{ justifyContent: 'center', alignItems: 'center', mb: 2 }}
              xs={12}
            >
              <MyDropzone
                selectedFile={props.certificateImage}
                selectedFileData={props.certificateImageData}
                setSelectedFile={props.setCertificateImage}
                imageType={'certificate'}
                text={'Choose certificate image or Drag and drop here'}
                tooltipTitle={'Choose certificate image or Drag and drop here'}
                disabled={!props.enableCertificateForm}
                {...props}
              />
            </Grid>
            <Box sx={{ width: '100%' }}>
              {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
              {props.certificateImage === undefined && checkError === true && (
                <p style={{ color: 'red' }}>*Image Required</p>
              )}
            </Grid> */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                {Boolean(props.certificateImage === undefined && checkError === true) ? (
                  <Typography variant='body2' color='error'>
                    *Image Required
                  </Typography>
                ) : (
                  <Typography variant='body2' color='text.secondary'>
                    Upload image
                  </Typography>
                )}
              </Grid>

              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  {props.enableCertificateForm === true ? (
                    <TextField
                      fullWidth
                      id='certificateName'
                      label='Name of Certificate'
                      variant='standard'
                      name='certificateName'
                      value={props.certificateName}
                      onChange={(event) => props.setCertificateName(event.target.value)}
                      error={checkError && props.certificateName?.trim().length < 1}
                      helperText={
                        checkError && props.certificateName?.trim().length < 1
                          ? 'Name of Certificate is required'
                          : 'Enter certificate name'
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <WorkspacePremiumIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      variant='standard'
                      label='Name of Certificate'
                      disabled
                      value={''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <WorkspacePremiumIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {props.enableCertificateForm === true ? (
                    <TextField
                      fullWidth
                      id='certificateAuthority'
                      label='Name of Certification Providing Authority'
                      variant='standard'
                      name='certificateAuthority'
                      value={props.certificateAuthority}
                      onChange={(event) => {
                        props.setCertificateAuthority(event.target.value);
                      }}
                      error={checkError && props.certificateAuthority?.trim().length <= 1}
                      helperText={
                        checkError && props.certificateAuthority?.trim().length <= 1
                          ? 'Minimum 2 characters required'
                          : 'Enter certification providing authority name '
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <WorkspacePremiumIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label='Name of Certification Provider Authority'
                      variant='standard'
                      disabled
                      value={''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <WorkspacePremiumIcon color='secondary' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  autoFocus
                  select
                  id='certificate_option'
                  label='Certificate'
                  variant='standard'
                  name='certificate'
                  open={menuOpen}
                  onClose={handleMenuClose}
                  onOpen={handleMenuOpen}
                  fullWidth
                  value={props.enableCertificateForm}
                  defaultValue={props.enableCertificateForm}
                  onChange={(event) => {
                    props.setEnableCertificateForm(event.target.value);
                  }}
                  helperText={'Would you like to submit school bus driving certificate'}
                  disabled={
                    !props.isEdit
                      ? false
                      : props.selectedDriverData.certificate_authority ||
                        props.selectedDriverData.certificate_name ||
                        props.selectedDriverData.certificate_image
                      ? true
                      : false
                  }
                >
                  <MenuItem id='yes' value={true}>
                    Yes
                  </MenuItem>
                  <MenuItem id='no' value={false}>
                    No
                  </MenuItem>
                </TextField>
              </Grid>
            </Box>
          </Grid>
          <Grid xs={12} container justifyContent='space-between' alignItems='flex-end'>
            <Grid item sx={{ ml: 3 }}>
              <BackButton
                size='medium'
                id='back_button'
                onClick={handleCancel}
                disabled={props.buttonLoader}
              >
                BACK
              </BackButton>
              {/* <Button
              className={classes.button}
              variant='contained'
              onClick={handleCancel}
              color={'secondary'}
              sx={{ backgroundColor: 'secondary.light' }}
              startIcon={<ArrowBackOutlinedIcon />}
            >
              BACK
            </Button> */}
            </Grid>
            <Grid item>
              {/* <NextButton
                onClick={(a) => handleSubmit(a)}
                id='driver_next_button'
                size='medium'
                type='submit'
              >
                Next
              </NextButton> */}
              {/* Code Depricated */}
              {/* <LoaderButton
                id={'submit_certificate_form'}
                buttonName={props.buttonText}
                buttonId={'saveDriver'}
                variant={'contained'}
                icon={'save'}
                buttonLoader={props.buttonLoader}
                color={'primary'}
                disable={props.buttonLoader}
                click={(a) => handleSubmit(a)}
                size={'medium'}
              /> */}
              <ActionButton
                loaderButton
                isLoading={props.buttonLoader}
                onClick={(a) => handleSubmit(a)}
                disable={props.buttonLoader}
                id={'submit_driver_shift'}
                buttonId={'saveDriver'}
                icon={'save'}
                startIcon={<SaveIcon />}
                size={'medium'}
              >
                {props.buttonText}
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveButton: {
    spacing: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  spinner: {
    marginRight: theme.spacing(2),
  },
}));
