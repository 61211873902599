import React, { useEffect } from 'react';

// material ui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';

// project imports
import BasicDriverForm from './Forms/BasicDriverForm';
import UploadLicenseForm from './Forms/UploadLicenseForm';
import DriverAddressForm from './Forms/DriverAddressForm';
import UploadCertificateForm from './Forms/UploadCertificateForm';
import { FormHeader } from 'components/SectionForms';
import DriverShifts from './Forms/DriverShifts';
// icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermissionDriver from './Forms/PermissionDriver';
import { Stack } from '@mui/material';
function GetForm(props) {
  switch (props.activeStep) {
    case 0:
      return <BasicDriverForm {...props} />;
    case 1:
      return <PermissionDriver {...props} />;
    case 2:
      return <DriverAddressForm {...props} />;
    case 3:
      return <UploadLicenseForm {...props} />;
    case 4:
      return <UploadCertificateForm {...props} />;
    default:
      return 'Unknown stepIndex';
  }
}

function getFormTitle() {
  return [
    'Basic Information',
    'Permissions',
    'Address Information',
    'License Information',
    'Certificate Information',
    // 'Driver Shifts',
  ];
}

const formTitle = getFormTitle();

const styles = (theme) => ({
  stepIcon: {
    color: theme.palette.secondary.main,
  },
});

function DriverFormWrapper(props) {
  const { classes } = props;
  const handleCancel = () => {
    props.clearAllFormStates();
    props.closeAddDriverForm();
    props.setImageValidation(false);
  };
  useEffect(() => {
    props.setActiveStep(0);
  }, [props.onEditActiveStep]);

  return (
    <div>
      {!props.isFromGuided ? (
        <Dialog
          open={props.addDriverFormOpen}
          aria-labelledby='form-dialog-title'
          fullWidth='md'
          maxWidth='md'
        >
          <DialogTitle sx={{ p: 0, m: 0 }}>
            <FormHeader
              title={props.isEdit ? 'Edit User' : 'Add User'}
              id={'close_Driver_form'}
              onClose={handleCancel}
              icon={props.isEdit ? <ManageAccountsIcon /> : <PersonAddIcon />}
              disableClose={props.buttonLoader}
            />
          </DialogTitle>
          <DialogContent sx={{ height: 620 }} dividers>
            <div>
              <div
                style={{ marginBottom: props.activeStep !== 1 ? '30px' : '5px', marginTop: '10px' }}
              >
                <Stepper activeStep={props.activeStep} alternativeLabel>
                  {formTitle.map((title) => (
                    <Step key={title}>
                      <StepLabel StepIconProps={{ classes: { root: classes.stepIcon } }}>
                        {title}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <Stack>
                <GetForm activeStep={props.activeStep} {...props} />
              </Stack>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div style={{ marginBottom: '10px', marginTop: '10px' }}>
            <Stepper activeStep={props.activeStep} alternativeLabel>
              {formTitle.map((title) => (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <Grid container>
            <GetForm activeStep={props.activeStep} {...props} />
          </Grid>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(DriverFormWrapper);
