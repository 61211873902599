import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

/**
 * A custom select component using Material-UI.
 *
 * @component
 * @example
 * const options = [
 *   { label: 'Option 1', value: 'option1', color: 'primary' },
 *   { label: 'Option 2', value: 'option2', color: 'secondary' },
 * ];
 * const setFilterValue = (value) => console.log('Selected Value:', value);
 * <BaseXSelect
 *   setFilterValue={setFilterValue}
 *   value={'option1'}
 *   optionValue={options}
 *   label="Select Option"
 *   sx={{ minWidth: 120 }}
 *   boxStyle={{ justifyContent: 'center' }}
 *   isColor={true}
 * />
 *
 * @param {Object} props - Component props
 * @param {Function} props.setFilterValue - Function to handle the change event and update the selected value.
 * @param {string} props.value - The currently selected value.
 * @param {Object[]} props.optionValue - List of options to display in the select menu.
 * @param {string} props.optionValue[].label - The label to display for each option.
 * @param {string} props.optionValue[].value - The value associated with each option.
 * @param {string} [props.optionValue[].color] - The color of the label (if isColor is true).
 * @param {string} props.label - Label for the select input.
 * @param {Object} [props.sx] - Style object for the FormControl component.
 * @param {Object} [props.boxStyle] - Style object for the Box component.
 * @param {boolean} [props.isColor=false] - Whether to display the label with color.
 * @returns {JSX.Element} Custom select component
 */
export function BaseXSelect({
  setFilterValue,
  value,
  optionValue,
  label,
  sx,
  boxStyle,
  isColor = false,
}) {
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };
  return (
    <FormControl sx={sx} size='small'>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select id='partner-filter' value={value} label='partner' onChange={handleChange}>
        {optionValue.map((ele) => {
          return (
            <MenuItem key={ele.value} value={ele.value}>
              {isColor ? (
                <Typography color={ele?.color || 'inherit'}>{ele.label}</Typography>
              ) : (
                <Box sx={boxStyle}>{ele.label}</Box>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
