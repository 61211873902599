import React from 'react';

// material-ui
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

export function FormHeader({
  id = 'close',
  title = 'Form Title',
  onClose = () => console.log('No callback function assigned'),
  disableClose = false,
  icon = '',
  typProps,
}) {
  return (
    <Stack direction={'row'} justifyContent='space-between' alignItems='center' px={2}>
      <Stack>
        <Typography
          component='h4'
          variant='h4'
          color='primary'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          {...typProps}
        >
          <Stack mr={2}>{icon}</Stack>
          <div>{title}</div>
        </Typography>
      </Stack>
      <Stack>
        <IconButton
          aria-label='close'
          id={id}
          onClick={onClose}
          size='large'
          color='secondary'
          disabled={disableClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
