import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';

// assets
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';

// Avatar styling
const avatarSx = {
  width: '120px',
  height: '120px',
};

// Badge button styling
const badgeButtonSx = {
  // backgroundColor: 'white !important',
  backgroundColor: 'background.paper',
  border: '2px solid',
  borderColor: 'primary',
  '&:hover': {
    backgroundColor: 'background.paper',
  },
};

// Badge content component
const BadgeContent = ({ name, values, setFieldValue, setFieldTouched }) => {
  const onClickHandler = (event) => {
    setFieldTouched(name, true);
    if (event.target.files[0]) {
      setFieldValue(name, event.target.files[0]);
    }
  };
  return (
    <label htmlFor='buttonImage'>
      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='buttonImage'
        type='file'
        onChange={(event) => onClickHandler(event)}
      />
      <IconButton
        color='primary'
        aria-label='upload picture'
        component='span'
        id='uploadImage'
        size='small'
        sx={badgeButtonSx}
      >
        {values[name] ? <EditIcon /> : <PhotoCameraIcon />}
      </IconButton>
    </label>
  );
};

// ============================== CUSTOM IMAGE COMPONENT (Uses formik)  ============================== //

function CustomImageInput(props) {
  // props extraction
  const { name } = props.field;
  const { values, touched, errors, setFieldValue, setFieldTouched } = props.form;
  const { helperText } = props;

  // generate preview image
  const generatePreviewImage = (imageFile) => {
    if (imageFile && typeof imageFile === 'object') {
      let tempURL = URL.createObjectURL(imageFile);
      return tempURL;
    }
    return imageFile;
  };

  return (
    <FormControl sx={{ alignItems: 'center' }}>
      <Badge
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <BadgeContent
            name={name}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        }
      >
        <Avatar
          src={generatePreviewImage(values.student_image)}
          sx={avatarSx}
          variant={props.avatarVariant}
        />
      </Badge>
      {touched[name] && Boolean(errors[name]) ? (
        <FormHelperText id='video-upload-helper-text' error={true}>
          {errors[name]}
        </FormHelperText>
      ) : (
        <FormHelperText id='video-upload-helper-text'>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

CustomImageInput.defaultProps = {
  avatarVariant: 'circular',
};

CustomImageInput.propTypes = {
  avatarVariant: PropTypes.oneOf(['circular', 'square', 'rounded']),
  field: PropTypes.object,
  form: PropTypes.object,
};

{
  /*
* TODO: Component can further be extended to support more props few examples
  Avatar fallback cases
  Avatar shape and badge improvements
  Delete image functionality either on hover on badge button click 
*/
}

export default CustomImageInput;
