import * as Yup from 'yup';
import Validations from 'containers/layouts/Utils/Validations';

const stringRequired = (message) => Yup.string().required(message);
const stringOptional = (message) => Yup.string('Must be a string');
export const validationSchema = [
  Yup.object().shape({
    student_image: Yup.mixed().test(
      'fileSize',
      'The file is too large, allowed maximum size 4MB.',
      (value) => {
        if (value instanceof Object) {
          return value && value.size <= 4194304;
        } else {
          return true;
        }
      },
    ),
    first_name: Validations.first_name,
    last_name: Validations.last_name,
    // birth_date: Validations.birth_date,
    // grade: Yup.number('Grade should be a number')
    //   .required('Grade is required')
    //   .min(1, 'Grade must be at least 1')
    //   .max(12, 'Grade must be at most 12'),
    gender: Validations.gender,
    lang: Validations.lang,
    // Student Contact info
    student_phone_code: Yup.object().optional(),
    student_phone_number: Yup.string()
      .optional()
      .matches(/^\d+$/, 'Mobile number must contain only numbers')
      .min(10, 'Mobile number must be at least 10 characters')
      .max(10, 'Mobile number must be at most 10 characters'),

    student_email: Yup.string().email('Please enter a valid email address'),

    // Special Needs
    special_needs_level: Yup.string().when('special_needs', {
      is: true,
      then: Yup.string().required('Special needs level is required'),
      otherwise: Yup.string(),
    }),
    special_needs: Yup.boolean().required('Special needs is required'),
  }),

  Yup.object().shape({
    // father
    // father_label: stringRequired('Label is required'),
    father_first_name: stringOptional(),
    father_last_name: stringOptional(),
    // father_phone_code: Validations.phone_country_code,
    father_phone_number: Validations.phone_number_not_required,
    father_email: Yup.string().email('Email is invalid'),

    // mother
    // mother_label: stringRequired('Label is required'),
    mother_first_name: stringOptional(),
    mother_last_name: stringOptional(),
    // mother_phone_code: Validations.phone_country_code,
    mother_phone_number: Validations.phone_number_not_required,
    mother_email: Yup.string().email('Email is invalid'),

    guardian_first_name: stringOptional(),
    guardian_last_name: stringOptional(),
    guardian_phone_number: Validations.phone_number_not_required,
    guardian_email: Yup.string().email('Email is invalid'),

    am_route: Validations.am_route,
    am_stop: Validations.am_stop,
    pm_route: Validations.am_route,
    pm_stop: Validations.am_stop,
  }),

  Yup.object().shape({
    // Student Address
    address_label: stringRequired('Label is required'),
    address_route_type: stringRequired('Route type is required'),
    address_search: stringRequired('Search is required'),

    // If in future need validation for address search
    // address_search_optional: Yup.string(),
    // address_label_optional: Yup.string().when('address_search_optional', {
    //   is: (address_search_optional) => !!address_search_optional,
    //   then: Yup.string().required('Address Label is required when Address Search is filled'),
    // }),
  }),

  Yup.object().shape({
    // Student Program
    student_program_name: Yup.string(),
    student_type: Yup.string(),
    neighborhood_code: Yup.string(),
    student_neighborhood_code_description: Yup.string()
      .min(16, 'Neighborhood Code Description must be at least 16 characters')
      .max(128, 'Neighborhood Code Description must be at most 128 characters'),

    // school Notes
    school_notes: Yup.string(),
    driver_notes: Yup.string(),
  }),
];
