export const schoolIdToSchoolName = (schoolIds = [], schoolsData = []) => {
  if (!schoolIds) return [];

  const schoolNames = [];
  for (let i = 0; i < schoolsData.length; i++) {
    if (schoolIds.includes(schoolsData[i].school_id)) {
      schoolNames.push(schoolsData[i].school_name);
    }
  }
  return schoolNames;
};

/**
 * Checks if any element in arr1 exists in arr2.
 *
 * @param {Array} arr1 - The first array to be checked.
 * @param {Array} arr2 - The second array to be checked.
 * @return {Object} An object with two properties: check (boolean) and value (any).
 *                  check is true if any element in arr1 exists in arr2, otherwise false.
 *                  value is the matching element if check is true, otherwise null.
 *
 * @example
 * const arr1 = [1, 2, 3];
 * const arr2 = [2, 4, 6];
 * const result = checkArrayMatch(arr1, arr2);
 * console.log(result); // { check: true, value: 2 }
 *
 * @example
 * const arr1 = [7, 8, 9];
 * const arr2 = [4, 5, 6];
 * const result = checkArrayMatch(arr1, arr2);
 * console.log(result); // { check: false, value: null }
 */
export function checkArrayMatch(arr1, arr2) {
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      return { check: true, value: arr1[i] };
    }
  }
  return { check: false, value: null };
}

/**
 * Converts a value to a string representation.
 *
 * @param {any} value - The value to be converted.
 * @return {string} The string representation of the value.
 *
 * @example
 * toStr('Hello'); // Returns: "Hello"
 * toStr({ name: 'John', age: 25 }); // Returns: "{"name":"John","age":25}"
 */
export function toStr(value) {
  return typeof value === 'string' ? value : JSON.stringify(value);
}

/**
 * Converts an object to an array of its values.
 *
 * @param {Object} obj - The object to be converted.
 * @return {Array} An array containing the values of the object.
 *
 * @example
 * const myObj = { a: 1, b: 2, c: 3 };
 * const myArray = objToArray(myObj);
 * console.log(myArray); // Output: [1, 2, 3]
 */
export function objToArray(obj) {
  if (!obj) return [];
  return Object.keys(obj).map((key) => obj[key]);
}

/**
 * Converts an object to an array of arrays.
 *
 * @param {Object} obj - The object to be converted.
 * @return {Array} An array of arrays, where each inner array contains a key-value pair from the original object.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * const result = objToArrOfArray(obj);
 * console.log(result); // Output: [['a', 1], ['b', 2], ['c', 3]]
 */
export function objToArrOfArray(obj) {
  if (!obj) return [];
  return Object.keys(obj).map((key) => [key, obj[key]]);
}

/**
 * Converts a string representation of a boolean value to a boolean value.
 *
 * @param {Object|string} obj - The object or string to be converted.
 * @return {Object|boolean} - The converted object or boolean value.
 *
 * @example
 * const obj = {
 *   key1: 'true',
 *   key2: 'false',
 *   nestedObj: {
 *     nestedKey: 'true'
 *   }
 * };
 *
 * convertStringBoolToBool(obj);
 * // Returns:
 * // {
 * //   key1: true,
 * //   key2: false,
 * //   nestedObj: {
 * //     nestedKey: true
 * //   }
 * // }
 */

export function convertStringBoolToBool(obj) {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      // If the input is an array, apply the function to each element.
      return obj.map((item) => convertStringBoolToBool(item));
    }

    // If the input is an object, apply the function to each property.
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = convertStringBoolToBool(obj[key]);
      }
    }
    return result;
  } else if (typeof obj === 'string') {
    if (obj === 'true') {
      return true;
    } else if (obj === 'false') {
      return false;
    }
  }
  return obj;
}

/**
 * Retrieves the full name of a driver based on their driver ID.
 *
 * @param {Array} driverData - An array of driver data objects.
 * @param {string} driverID - The unique identifier of the driver.
 * @returns {string} - The full name of the driver if found, otherwise '-'.
 */
export const getDriverNameByDriverId = (driverData, driverID) => {
  // Check if either driverData or driverID is falsy, and return '-' if so
  if (!Boolean(driverData) || !Boolean(driverID)) {
    return '-';
  }

  // Find the driver with the specified driverID in the driverData array
  const foundDriver = driverData.find((driver) => driver.driver_username === driverID);

  // If the driver is found, extract first_name and last_name and return the full name
  if (foundDriver) {
    const { first_name, last_name } = foundDriver;
    return `${first_name} ${last_name}`;
  } else {
    // If the driver is not found, return '-'
    return '-';
  }
};

export class DateAndTimeFormatter {
  static formatDate(inputDate) {
    // Array of month abbreviations
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Parse the input date string
    const date = new Date(inputDate);

    // Extract date components
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to 12-hour format
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date string
    const formattedDate = `${day} ${month} ${year} ${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${ampm}`;

    return formattedDate;
  }

  static addMinutesLabel(inputDate) {
    return `${inputDate} min`;
  }

  static formatSecondsIntoTime = (seconds) =>
    `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')} min`;

  static getMonthDateYear(inputDate) {
    if (!inputDate) {
      return '--';
    }
    const dd = new Date(inputDate).getDate();
    const mm = new Date(inputDate).getMonth() + 1;
    const yy = new Date(inputDate).getFullYear();

    return `${mm}-${dd}-${yy}`;
  }
}
