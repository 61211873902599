import React from 'react';

import DriverStepperHeader from './Components/DriverStepperHeader';
import {
  validateBulkDriverImport,
  getDriversBulkImportStatus,
  cancelDriversBulkImport,
  getBulkImportTemplateFile,
} from '../../../../clients/SpotBus';

import { getErrorMessage } from '../../Utils/ErrorHandling';
import AbortAlert from './Components/AbortAlert';
import { Divider, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function DriverImport(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = React.useState('');
  const [data, setData] = React.useState(undefined);
  const [driverValidationFilter, setDriverValidationFilter] = React.useState('all_drivers');
  const [activeStep, setActiveStep] = React.useState(0);
  const [uploadButton, setUploadButton] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const [abortAlertValue, setAbortAlertValue] = React.useState(false);

  React.useEffect(() => {
    return () => {
      closeSnackbar();
    };
  }, []);

  // Function called from driverStepperHeader
  function handleFileUpload() {
    setIsButtonLoading(true);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(selectedFile, 'UTF-8');
      fileReader.onload = (e) => {
        validateBulkDriverImport(props.username, e.target.result.replace(/\r/g, ''))
          .then((result) => {
            // console.log('from validation');
            // console.log(result);
            setData(result);
            if (result.status === 'VALIDATION_FAILED') {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep + 2);
              handleValidatedFileUpload(result);
            }
            console.log(JSON.stringify(result, null, 2));
            setIsButtonLoading(false);
            resolve();
          })
          .catch((error) => {
            console.log('Error while getting validated data' + JSON.stringify(error, null, 2));
            setIsButtonLoading(false);
            enqueueSnackbar(getErrorMessage(error), {
              variant: 'error',
            });
            reject();
          });
      };
    });
  }

  const handleValidatedFileUpload = async (prevData) => {
    // const timer = setInterval(async () => {
    //   const result = await getDriversBulkImportStatus(
    //     props.username,
    //     prevData.jobId
    //   );

    //   console.log('from handle validated file Upload');
    //   console.log(result);

    //   if (result.completed_items == prevData.total_items) {
    //     stopTimer();
    //   }

    //   setProgress(
    //     Math.round((result.completed_items / result.total_items) * 100)
    //   );
    // }, 1000);
    const result = await getDriversBulkImportStatus(props.username, prevData.jobId);
    const timer = setInterval(async () => {
      if (result.completed_items == prevData.total_items) {
        stopTimer();
      }

      setProgress(Math.round((result.completed_items / result.total_items) * 100));
      result.completed_items += 1;
    }, 1000);

    function stopTimer() {
      enqueueSnackbar('Driver bulk import successfull', {
        variant: 'success',
      });
      if (props.isFromGuided) {
        props.getAllDrivers();
      }
      setActiveStep(3);
    }
    if (props.isFromGuided) {
      props.handleUpdateGuidedWorkflowData(prevData.total_items);
    }
  };

  function handleAbortProcess() {
    setIsButtonLoading(true);
    return new Promise((resolve, reject) => {
      cancelDriversBulkImport(props.username, data.jobId).then(
        (result) => {
          console.log(JSON.stringify(result, null, 2));
          setSelectedFile('');
          setData(undefined);
          setActiveStep(0);
          setAbortAlertValue(false);
          setIsButtonLoading(false);
          setDriverValidationFilter('all_drivers');
          resolve();
        },
        (error) => {
          console.log(JSON.stringify(error, null, 2));
          setSelectedFile('');
          setData(undefined);
          setActiveStep(0);
          setAbortAlertValue(false);
          setIsButtonLoading(false);
          reject();
        },
      );
    });
  }

  function handleFilterDrivers() {
    if (data && data.validation_result && driverValidationFilter === 'failed_drivers') {
      let failedDrivers = data.validation_result.filter((driver) => {
        return driver.is_valid === false;
      });

      if (failedDrivers.length === 0) {
        return { ...data, validation_result: [] };
      }

      return { ...data, validation_result: failedDrivers };
    }
    if (data && data.validation_result && driverValidationFilter === 'passed_drivers') {
      let passedDrivers = data.validation_result.filter((driver) => driver.is_valid === true);
      if (passedDrivers.length === 0) {
        return { ...data, validation_result: [] };
      }
      return { ...data, validation_result: passedDrivers };
    }
    return data;
  }

  function templateFileDownload() {
    const newWindow = window.open(
      'https://sb-static-data-102707762542.s3.amazonaws.com/bulk-import-drivers-sample.csv',
      '_blank',
      'noopener, noreferrer',
    );
    if (newWindow) newWindow.opener = null;
    // props.setIsLoading(true);
    // return new Promise((resolve, reject) => {
    //     getBulkImportTemplateFile("bulk-import-drivers-sample").then(
    //         (result) => {
    //             const newWindow = window.open(result.url, '_blank', 'noopener, noreferrer')
    //             if(newWindow) newWindow.opener = null
    //             props.setIsLoading(false);
    //             resolve();
    //         },
    //         (error) => {
    //             console.log(JSON.stringify(error, null, 2));
    //             props.setErrorMessage(getErrorMessage(error));
    //             props.setIsLoading(false);
    //             props.setShowErrorSnackBar(true);
    //             setTimeout(function () {
    //                 props.setShowErrorSnackBar(false);
    //             }, 4500);
    //             reject();
    //         }
    //     );
    // });
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DriverStepperHeader
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleFileUpload={handleFileUpload}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            data={handleFilterDrivers()}
            totalDriversData={data}
            driverValidationFilter={driverValidationFilter}
            setDriverValidationFilter={setDriverValidationFilter}
            uploadButton={uploadButton}
            progress={progress}
            setAbortAlertValue={setAbortAlertValue}
            templateFileDownload={templateFileDownload}
            isButtonLoading={isButtonLoading}
            {...props}
          />
        </Grid>
      </Grid>
      <AbortAlert
        open={abortAlertValue}
        setClose={() => setAbortAlertValue(false)}
        handleAbortProcess={handleAbortProcess}
        isButtonLoading={isButtonLoading}
        setIsButtonLoading={setIsButtonLoading}
      />
    </div>
  );
}
