import React, { useState, useEffect } from 'react';

// material ui
import { Grid } from '@mui/material';

// third party
import { useSnackbar } from 'notistack';

// api imports
import {
  registerDashboardUser,
  registerUserPermsissions,
  updateUserPermsissions,
  deleteUser,
  deleteUserPermission,
  updateGuidedWorkFlow,
} from 'clients/SpotBus';

// project imports
import { getErrorMessage } from 'containers/layouts/Utils/ErrorHandling';
import AddUser from './AddUser';
import UserList from '../../List/UserList/index';
import MainCard from 'components/MainCard';
import BackForward from '../../BackForward';
import formInitialValuesForUser from './FormModel/formInitialValuesForUser';
import validationSchemaForUser from './FormModel/validationSchemaForUser';

// Formik
import { Formik, Form } from 'formik';


export default function SchoolForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');

  const [permissionType, setPermissionType] = useState(null);
  const [permittedSchools, setPermittedSchools] = useState([]);

  const [buttonLoader, setButtonLoader] = useState(false);
  const [updatedGuidedWorkflowData, setUpdatedGuidedWorkflowData] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [isUserAdded, setIsUserAdded] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const handleStatesOnSave = () => {
    setIsEdit(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setGender('');
    setPermissionType(null);
    setPermittedSchools([]);
    setButtonLoader(false);
    setProceed(false);
  };

  // getting index for update guided workflow data on register or delete
  const getIndex = (req) => {
    for (let i = 0; i < req.steps_graph.length; i++) {
      if (req.steps_graph[i].name === 'ADD_USERS') {
        return i;
      }
    }
  };

  // update guided workflow data on register or delete
  const handleUpdateGuidedWorkflowData = async (count = 1) => {
    let req = props.guidedWorkflowData;
    let i = getIndex(props.guidedWorkflowData);
    req.steps_graph[i].data[0].available_items_count =
      req.steps_graph[i].data[0].available_items_count + count;
    if (
      req.steps_graph[i].data[0].min_items_required <=
      req.steps_graph[i].data[0].available_items_count
    ) {
      req.steps_graph[i].data[0].is_completed = true;
    } else {
      req.steps_graph[i].data[0].is_completed = false;
    }

    try {
      const result = await updateGuidedWorkFlow(req);
      props.handleGuidedWorkflowDataUpdate(result);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDeleteUser() {
    setDeleteButtonLoader(true);
    if (
      !selectedUserData.dashboard_user_username ||
      !selectedUserData._organization_admin_username
    ) {
      return;
    }
    try {
      await deleteUser(
        selectedUserData.dashboard_user_username,
        selectedUserData._organization_admin_username,
      );
      enqueueSnackbar('User successfully deleted', {
        variant: 'success',
      });
      await handleDeleteUserPermission();
      handleUpdateGuidedWorkflowData(-1);
    } catch (error) {
      console.log('Deleting user Error', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
      setDeleteButtonLoader(false);
      setOpenDeleteAlert(false);
    }
  }

  async function handleDeleteUserPermission() {
    try {
      await deleteUserPermission(
        selectedUserData.dashboard_user_username,
        selectedUserData._organization_admin_username,
      );
      enqueueSnackbar('User with permission successfully deleted', {
        variant: 'success',
      });
      props.getAllUsers();
    } catch (error) {
      console.log('Deleting Permission Error', error);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    } finally {
      setDeleteButtonLoader(false);
      setOpenDeleteAlert(false);
    }
  }

  const handleOnDeleteClick = (user) => {
    setSelectedUserData(user);
    handleStatesOnSave();
  };

  const handleEdit = (user) => {
    console.log(user);
    setIsEdit(true);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setPhoneNumber(
      user.phone_number.includes('+1') ? user.phone_number.slice(2, 12) : user.phone_number,
    );
    setGender(user.gender);
    const roleType = user.user_permissions.role_type;
    setPermissionType(roleType);
    setSelectedUserData(user);
    if (roleType === 'ROLE_ADMIN') {
      setPermittedSchools(props.guidedSchools.map((school) => school.school_id));
    } else {
      setPermittedSchools(user.user_permissions.schools);
    }
    setProceed(false);
  };

  async function createUserHandler(formikProps) {
    const values = formikProps.values
    const phoneNumber = values?.phone_country_code.phone + values?.phone_number;
    props.setloadingData(true);
    setButtonLoader(true);
    try {
      const result = await registerDashboardUser(
        props.username,
        values.first_name,
        values.last_name,
        phoneNumber,
        values.email,
        values.gender,
      );
      handleCreateUserPermissions(result);
      handleUpdateGuidedWorkflowData(1);
      props.setloadingData(false);
      setIsEdit(false);
      formikProps.resetForm();
    } catch (error) {
      props.setloadingData(false);
      console.log('Error from create User', error);
      setButtonLoader(false);
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    }
  }

  function getPayload(createdUserData) {
    let username = createdUserData.dashboard_user_username;
    let data = {
      username: username,
      role_type: permissionType,
      schools: permittedSchools,
    };
    if (permissionType === 'ROLE_ADMIN') {
      data = { ...data, schools: [] };
    }
    return data;
  }

  async function handleCreateUserPermissions(createdUserData) {
    props.setloadingData(true);
    setButtonLoader(true);
    console.log('Create Called');
    try {
      let tempData = getPayload(createdUserData);
      let result = await registerUserPermsissions(props.username, tempData.username, tempData);
      console.log('Created permission', result);
      handleStatesOnSave();
      props.getAllUsers();
      props.setloadingData(false);
      setIsEdit(false);
      enqueueSnackbar('User added successfully', {
        variant: 'success',
      });
    } catch (error) {
      props.setloadingData(false);
      console.log('Creating User Permission Error', error);
      enqueueSnackbar('Something went wrong, unable to generate permissions', {
        variant: 'error',
      });
    } finally {
      setButtonLoader(false);
    }
  }

  async function handleUpdateUserPermissions() {
    props.setloadingData(true);
    setButtonLoader(true);
    console.log('Update Called');
    try {
      let tempData = getPayload(selectedUserData);
      let result = await updateUserPermsissions(props.username, tempData.username, tempData);
      console.log('Updated permission', result);
      props.getAllUsers();
      handleStatesOnSave();
      props.setloadingData(false);
      setIsEdit(false);
      enqueueSnackbar('User updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      props.setloadingData(false);
      console.log('Update User Permission Error', error);
      enqueueSnackbar('Something went wrong, unable to update permissions', {
        variant: 'error',
      });
    } finally {
      setButtonLoader(false);
    }
  }

  useEffect(() => {
    return closeSnackbar();
  }, []);

  const handleUserFormSubmit = (formikProps) => {
    createUserHandler(formikProps);
  };
  return (
    <Grid sx={{ mx: '2vw' }}>
      <MainCard>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Formik
              initialValues={formInitialValuesForUser(isEdit, selectedUserData)}
              validationSchema={validationSchemaForUser}
              enableReinitialize
            >
              {(formikProps) => (
                <AddUser
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  gender={gender}
                  setGender={setGender}
                  permissionType={permissionType}
                  setPermissionType={setPermissionType}
                  permittedSchools={permittedSchools}
                  setPermittedSchools={setPermittedSchools}
                  proceed={proceed}
                  setProceed={setProceed}
                  buttonLoader={buttonLoader}
                  isEdit={isEdit}
                  isUserAdded={isUserAdded}
                  guidedSchools={props.guidedSchools}
                  handleStatesOnSave={handleStatesOnSave}
                  isFromGuided={true}
                  createUserHandler={createUserHandler}
                  handleUpdateUserPermissions={handleUpdateUserPermissions}
                  formikProps={formikProps}
                  handleUserFormSubmit={handleUserFormSubmit}
                  {...props}
                />
              )}
            </Formik>
            <BackForward
              disableButton={props.guidedWorkflowData?.steps_graph[1].data[0].is_completed}
              {...props}
            />
          </Grid>
          <Grid item xs={4}>
            <UserList
              guidedSchools={props.guidedSchools}
              guidedUsers={props.guidedUsers}
              handleEdit={handleEdit}
              handleOnDeleteClick={handleOnDeleteClick}
              handleDeleteUser={handleDeleteUser}
              deleteButtonLoader={deleteButtonLoader}
              openDeleteAlert={openDeleteAlert}
              setOpenDeleteAlert={setOpenDeleteAlert}
              loadingData={props.loadingData}
            />
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
