import { toStr } from 'pages/Communication/utils/utils';

export const req_factory_device = ({ values, selectedDevicesData = {}, isEditing = false }) => {
  let phone_number_value = {
    country_code: values?.device_phone_code?.phone,
    number: String(values?.device_phone_number),
  };
  const req_body = {
    device_id: values.device_id,
    device_model: values.device_model,
    device_type: values.device_type,
    manufacturer: values.manufacturer,
    status: values.status ? 'active' : 'inactive',
    carrier: Boolean(values.carrier) ? values.carrier : null,
    sim_number: Boolean(values.sim_number) ? values.sim_number : null,
    phone_number: Boolean(values?.device_phone_number) ? phone_number_value : null,
    capabilities: {
      driverapp: values.driverapp,
      gps_tracking: values.gps_tracking,
      attendance: values.attendance,
    },
  };

  return isEditing ? { ...selectedDevicesData, ...req_body } : req_body;
};
