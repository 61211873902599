import React from 'react';

// material ui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

// project imports
import DriverInfo from './DriverInfo';
import DialogImage from './DialogImage';
import DialogLicenseFront from './DialogLicenseFront';

// Custom Component
import QuickViewItemAccordion from 'components/accordions/QuickViewItemAccordion';

export default function DriverLicense(
  { licenseClass, licenseNumber, licenseExpiryDate, licenseIssueDate, licenseImage, dataLoading },
  props,
) {
  const theme = useTheme();
  const [openAddImageDialog, setOpenImageDialog] = React.useState(false);

  const [openAddFrontImageDialog, setOpenFrontImageDialog] = React.useState(false);

  return (
    <React.Fragment>
      <QuickViewItemAccordion
        startIcon={<DescriptionIcon fontSize='large' />}
        label={'License Information'}
        accordionSummary={licenseNumber ? licenseNumber : '---'}
        isLoading={dataLoading}
        isExpandable={licenseNumber ? licenseNumber : false}
      >
        <DriverInfo
          heading={'License numbers'}
          details={licenseNumber}
          id='license_number'
          headingWidth={4}
          bodyWidth={8}
          dataLoading={dataLoading}
        />
        <DriverInfo
          heading={'License class'}
          details={licenseClass}
          id='license_class'
          headingWidth={4}
          bodyWidth={8}
          dataLoading={dataLoading}
        />
        <DriverInfo
          heading={'License Issue Date'}
          details={licenseIssueDate}
          id='license_iss_date'
          headingWidth={4}
          bodyWidth={8}
          dataLoading={dataLoading}
        />
        <DriverInfo
          heading={'License Expiry Date'}
          details={licenseExpiryDate}
          id='license_exp_date'
          headingWidth={4}
          bodyWidth={8}
          dataLoading={dataLoading}
        />

        {!dataLoading ? (
          <Button
            disabled={Boolean(licenseImage?.license_front_image?.image_id) ? false : true}
            onClick={(a) => setOpenFrontImageDialog(true)}
          >
            {Boolean(licenseImage?.license_front_image?.image_id) ? (
              <img
                style={{ width: '100%' }}
                alt='license front'
                src={
                  licenseImage?.license_front_image?.image_id
                    ? licenseImage?.license_front_image?.url
                    : ''
                }
                key={
                  licenseImage?.license_front_image?.image_id
                    ? licenseImage?.license_front_image?.url
                    : ''
                }
              ></img>
            ) : (
              <Typography color='text.secondary'>Image not available</Typography>
            )}
          </Button>
        ) : (
          <Skeleton variant='rectangular' width={'100%'} height={140} />
        )}
        {!dataLoading ? (
          <Button
            disabled={Boolean(licenseImage?.license_back_image?.image_id) ? false : true}
            onClick={(a) => setOpenImageDialog(true)}
          >
            {Boolean(licenseImage?.license_back_image?.image_id) ? (
              <img
                style={{ width: '100%' }}
                alt='license back'
                src={
                  licenseImage?.license_back_image?.image_id
                    ? licenseImage?.license_back_image?.url
                    : ''
                }
                key={
                  licenseImage?.license_back_image?.image_id
                    ? licenseImage?.license_back_image?.url
                    : ''
                }
              ></img>
            ) : (
              <Typography color='text.secondary'>Image not available</Typography>
            )}
          </Button>
        ) : (
          <Skeleton variant='rectangular' width={'100%'} height={140} style={{ marginTop: 8 }} />
        )}
        <DialogImage
          openDialog={openAddImageDialog}
          closeDialog={() => setOpenImageDialog(false)}
          title={'License Back'}
          image={
            licenseImage?.license_back_image?.image_id ? licenseImage?.license_back_image?.url : ''
          }
        />

        <DialogLicenseFront
          openFrontDialog={openAddFrontImageDialog}
          closeFrontDialog={() => setOpenFrontImageDialog(false)}
          title={'License Front'}
          image={
            licenseImage?.license_front_image?.image_id
              ? licenseImage?.license_front_image?.url
              : ''
          }
        />
      </QuickViewItemAccordion>
      {/* old code (deprecated) */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' id='show_school_info' />}>
          <Grid container alignItems='center'>
            <DescriptionOutlinedIcon color='primary' />
            <Typography style={{ marginLeft: 8 }} color='primary'>
              License Information
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                alignContent: 'space-between',
              }}
            >
              <Grid item>
                <Grid item xs={12}>
                  <DriverInfo
                    heading={'License numbers'}
                    details={licenseNumber}
                    id='license_number'
                    headingWidth={4}
                    bodyWidth={8}
                    dataLoading={dataLoading}
                  />
                  <DriverInfo
                    heading={'License class'}
                    details={licenseClass}
                    id='license_class'
                    headingWidth={4}
                    bodyWidth={8}
                    dataLoading={dataLoading}
                  />
                  <DriverInfo
                    heading={'License Issue Date'}
                    details={licenseIssueDate}
                    id='license_iss_date'
                    headingWidth={4}
                    bodyWidth={8}
                    dataLoading={dataLoading}
                  />
                  <DriverInfo
                    heading={'License Expiry Date'}
                    details={licenseExpiryDate}
                    id='license_exp_date'
                    headingWidth={4}
                    bodyWidth={8}
                    dataLoading={dataLoading}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <Grid item xs={12}>
                  {!dataLoading ? (
                    <Button onClick={(a) => setOpenFrontImageDialog(true)}>
                      <img
                        style={{ width: '100%' }}
                        alt='license front'
                        src={
                          licenseImage?.license_front_image?.image_id
                            ? licenseImage?.license_front_image?.url
                            : ''
                        }
                        key={
                          licenseImage?.license_front_image?.image_id
                            ? licenseImage?.license_front_image?.url
                            : ''
                        }
                      ></img>
                    </Button>
                  ) : (
                    <Skeleton variant='rectangular' width={'100%'} height={140} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!dataLoading ? (
                    <Button onClick={(a) => setOpenImageDialog(true)}>
                      <img
                        style={{ width: '100%' }}
                        alt='license back'
                        src={
                          licenseImage?.license_back_image?.image_id
                            ? licenseImage?.license_back_image?.url
                            : ''
                        }
                        key={
                          licenseImage?.license_back_image?.image_id
                            ? licenseImage?.license_back_image?.url
                            : ''
                        }
                      ></img>
                    </Button>
                  ) : (
                    <Skeleton
                      variant='rectangular'
                      width={'100%'}
                      height={140}
                      style={{ marginTop: 8 }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion> */}
    </React.Fragment>
  );
}
