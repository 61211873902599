// third-party
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducers
import {
  searchReducer,
  filterListReducer,
  searchInputValueReducer,
} from './reducers/searchReducers';
import { savedSearchFormDialogReducer, savedSearchDataReducer } from './reducers/savedReducers';
import { menu } from 'reducers/menuReducers';
import { breadcrumbsDataReducer } from 'reducers/breadcrumbsReducer';
import { globalReducers } from 'reducers/globalReducers';

const reducer = combineReducers({
  breadcrumbs: breadcrumbsDataReducer,
  filterListReducer: filterListReducer,
  globals: globalReducers,
  menu: menu,
  searchInputValue: searchInputValueReducer,
  searchReducer: searchReducer,
  savedSearchFormDialogReducer: savedSearchFormDialogReducer,
  savedSearchDataReducer: savedSearchDataReducer,
});

const middleware = [thunk];

const initialState = {
  isPremiumUser: false,
  breadcrumbs: localStorage.getItem('breadcrumbsData')
    ? { breadcrumbsData: JSON.parse(localStorage.getItem('breadcrumbsData')) }
    : {},
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
