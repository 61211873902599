import React from 'react';
import PropTypes from 'prop-types';

// material ui
import { Grid, Divider } from '@mui/material';
import BackButton from 'components/Buttons/BackButton';
import NextButton from 'components/Buttons/NextButton';

const buttonName = [
  'Back',
  'Add Schools',
  'Add Users',
  'Add Session',
  'Add Drivers',
  'Add Vehicles',
  'Second Phase',
];

const BackForward = (props) => {
  return (
    <React.Fragment>
      <Divider sx={{ mt: 0.5}} variant='middle' />
      <Grid
        container
        justifyContent={props.hideBack ? 'flex-end' : 'space-between'}
        sx={{ mt: 2, px: 2 }}
      >
        {!props.hideBack ? (
          <BackButton
            size='large'
            disabled={props.activeGuidedStep === 0}
            onClick={() => props.decreaseActiveStep()}
          >
            {buttonName[props.activeGuidedStep]}
          </BackButton>
        ) : null}
        <NextButton
          size='large'
          onClick={() =>
            props.activeGuidedStep === 4 ? props.setPhase(1) : props.increaseActiveStep()
          }
          disabled={!props.disableButton}
        >
          {buttonName[props.activeGuidedStep + 2]}
        </NextButton>
      </Grid>
    </React.Fragment>
  );
};

export default BackForward;
