import React from 'react';

// material-ui
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';

// assets
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteAlert({
  buttonLoader,
  setClose,
  deleteItemName,
  handleDelete,
  open,
}) {
  const theme = useTheme();

  const handleClose = () => {
    setClose();
  };

  return (
    <div>
      <Dialog aria-labelledby='customized-dialog-title' open={open}>
        <MuiDialogTitle sx={{ m: 0, padding: theme.spacing(4) }}>
          <Grid container alignItems={'center'}>
            <ErrorIcon severity='error' />
            <Typography variant='h3' sx={{ ml: 1 }}>
              Attention!
            </Typography>
          </Grid>
          <IconButton
            aria-label='close'
            sx={{
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
            onClick={handleClose}
            size='large'
            disabled={buttonLoader}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent sx={{ margin: 0, padding: theme.spacing(4) }} dividers>
          <Alert severity='error'>
            <AlertTitle>
              <strong>Delete {deleteItemName} ?</strong>
            </AlertTitle>
            Once Deleted, this action cannot be reversed.
          </Alert>
        </MuiDialogContent>
        <MuiDialogActions sx={{ margin: 0, padding: theme.spacing(4) }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={buttonLoader}
            loadingPosition='start'
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            color='error'
            id='confirm_delete'
          >
            Delete
          </LoadingButton>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}

export const DeleteAlertV2 = ({ buttonLoader, setClose, deleteItemName, handleDelete, open }) => {
  const theme = useTheme();

  const handleClose = () => {
    setClose();
  };

  return (
    <div>
      <Dialog aria-labelledby='customized-dialog-title' open={open}>
        <MuiDialogTitle sx={{ m: 0, p: 0 }}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Grid container alignItems={'center'}>
              <ErrorIcon severity='error' sx={{ ml: 1 }} />
              <Typography variant='h3' sx={{ ml: 1 }}>
                Attention!
              </Typography>
            </Grid>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              size='large'
              disabled={buttonLoader}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </MuiDialogTitle>
        <MuiDialogContent sx={{ margin: 0, padding: theme.spacing(2) }} dividers>
          <Alert severity='error'>
            <AlertTitle>
              <strong>{deleteItemName}</strong>
            </AlertTitle>
            Once deleted, this action cannot be reversed.
          </Alert>
        </MuiDialogContent>
        <MuiDialogActions sx={{ margin: 0, padding: theme.spacing(2) }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={buttonLoader}
            loadingPosition='start'
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            color='error'
            id='confirm_delete'
          >
            Delete
          </LoadingButton>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};

// Use case for delete alert
// <DeleteAlert
//   deleteItemName={'Session'}
//   open={openDeleteSessionAlert}
//   setOpen={() => setOpenDeleteSessionAlert(true)}
//   setClose={() => setOpenDeleteSessionAlert(false)}
//   handleDelete={handleDeleteSession}
//   buttonLoader={buttonLoader}
// />
