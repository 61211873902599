import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';

// material-ui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { MenuItem, FormControl } from '@mui/material';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';

export default function NamedAvatar({
  children,
  isEditable,
  sx,
  handleEditClick,
  userList,
  minWidth,
  color,
  variant,
  isLoading,
  isSubmitting,
  ...props
}) {
  const [isHovered, setIsHovered] = useState(true);
  const [open, setOpen] = useState(false);
  const [value, setValues] = useState(null);
  const handleChange = (event) => {
    setValues(event.target.value);
  };

  useEffect(() => {
    setValues(children);
  }, [children]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const skeletonCall = () => {
    return <Skeleton variant='rectangular' width={80} height={20} />;
  };

  // Resusable Component
  function AvatarComponent({ children }) {
    return (
      <Stack direction='row' justifyContent='left' alignItems='center' spacing={1}>
        {isLoading ? (
          skeletonCall()
        ) : (
          <>
            <Avatar
              sx={{
                bgcolor: 'secondary.main',
                width: 22,
                height: 22,
                fontSize: 12,
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize',
                ...sx,
              }}
              alt={children}
              src='#'
              {...props}
            />
            <Typography variant={variant} color={color} sx={{ textTransform: 'capitalize' }}>
              {children}
            </Typography>
          </>
        )}
      </Stack>
    );
  }

  return isEditable ? (
    <FormControl
      onMouseEnter={() => setIsHovered(false)}
      onMouseLeave={() => setIsHovered(true)}
      variant='standard'
      onClick={(e) => e.stopPropagation()}
      sx={{ minWidth: minWidth, position: 'relative', bgColor: 'transparent' }}
    >
      {isLoading ? (
        skeletonCall()
      ) : (
        <Select
          onChange={handleChange}
          value={value}
          id='filled-select-NamedAvatar'
          select
          size='small'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          variant='standard'
          disableUnderline
          IconComponent={forwardRef(() => null)}
          disabled={isSubmitting}
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgColor: 'transparent',
          }}
          endAdornment={
            isEditable && (
              <InputAdornment position='end'>
                <EditIcon
                  onClick={handleOpen}
                  sx={{ fontSize: '18px' }}
                  style={{
                    display: isHovered ? 'none' : '',
                    position: 'absolute',
                    right: '6px',
                    cursor: 'pointer',
                  }}
                />
              </InputAdornment>
            )
          }
        >
          {userList.map((item, index) => (
            <MenuItem
              value={item.name}
              key={index}
              sx={{ pl: 2, mt: 1, mb: 1, justifyContent: 'flex-start' }}
              onClick={() => handleEditClick(item)}
              onMouseLeave={() => setIsHovered(true)}
            >
              <AvatarComponent children={item.name} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  ) : (
    <AvatarComponent children={children} />
  );
}

NamedAvatar.defaultProps = {
  children: 'Avatar Name',
  color: 'text.secondary',
  gutterBottom: true,
  userList: [],
  isEditable: false,
  minWidth: 'auto',
  variant: 'body1',
  handleEditClick: function () {
    return;
  },
  isLoading: false,
  isSubmitting: false,
};

NamedAvatar.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  gutterBottom: PropTypes.bool,
  handleEditClick: PropTypes.func,
  userList: PropTypes.array,
  isEditable: PropTypes.bool,
  minWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

// Readme.md

// All Default props are set

// We can pass all default Avatar props

// We need to import as below

// When isEditable false that is default function
{
  /* <NamedAvatar>children</NamedAvatar>; */
}

// When EdiTable is True Then

// We Need userList for that we have to import one function component to create array

// userList={arrayofNames(userName, row.organization_admin_username)}

// Import arrayofNames function and pass ( complete user List and user Id as an argument)

// Example

{
  /* <NamedAvatar
  handleEditClick={(item) => console.log(item, 'userData__')}
  isEditable={true}
  userList={arrayofNames(userName, row.organization_admin_username)}
>
  {filterUser(row.organization_admin_username)}
</NamedAvatar>; */
}

// handleEditClick will get active we have to pass callback function to get the selected value

// we can change value using these props (Avatar Value)
// avatarImageUrl: '#',
// userList: [],
// bgcolor: 'secondary.main',
// width: 22,
// height: 22,
// fontSize: 14,
// isLoading : for showing skelton
