import * as React from 'react';

//material ui
import Typography from '@mui/material/Typography';
import { Grid, Avatar } from '@mui/material';

//assets
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//project imports
import MainCard from 'components/MainCard';
import { CardHeading } from 'components/Headers/CardHeading';
import { Heading } from 'components/Headers/Heading';

export default function SchoolCard(props) {
  return (
    <Grid mt={4}>
      <MainCard>
        <Grid container mb={-4} xs={12}>
          <Grid item xs={3}>
            {/* <img src={props.school.image?.url} width='100px' height='100px'/> */}
            <Avatar
              src={
                props.school.image
                  ? props.school.image?.url
                  : 'https://i0.wp.com/teamtouche.com/wp-content/uploads/2016/09/CollegeTrackGrayIcon-1.png?w=1060'
              }
              sx={{
                width: 100,
                height: 100,
                border: '1px dotted black',
                mt: -1,
              }}
              // variant= 'square'
            />
          </Grid>
          <Grid item xs={9} sx={{ pl: 4 }}>
            <Grid>
              <CardHeading  title={props.school.school_name} />
            </Grid>
            <Grid sx={{ pl: 1 }}>
              <Typography variant='body1' color='secondary.main'>
                {props.school.address.apt_plot +
                  ', ' +
                  props.school.address.street +
                  ',' +
                  props.school.address.city +
                  ', ' +
                  props.school.address.state +
                  ', ' +
                  props.school.address.zip_code}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end', borderWidth: '2px' }}
            >
              <IconButton
                aria-label='delete'
                color='secondary'
                onClick={() => props.handleEdit(props.school)}
                disabled={props.loadingData}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => {
                  props.setOpen();
                  props.setDeleteSchoolId(props.school.school_id);
                }}
                disabled={props.loadingData}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}
